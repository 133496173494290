import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { computeFordModel } from '../../utils/forecastHelper'
import { getMeta } from '../../utils/metaUtils'
import { json } from 'd3'



export function AvgSensByBrandByFuelType({ payload }) {
    const { prodsToRun, modelConfig, modelInfo, attCondMetaTableName, attCondMetaLookupName, modelName, atts, filter, filetype, isPickup } = payload

    const study = useSelector(state => state.study)
    const auth = useSelector(state => state.auth)
    const [running, setRunning] = useState(false)
    const [buttonClicked, setButtonClicked] = useState(false)
    const [conditions, setConditions] = useState(null)
    const [attLookups, setAttLookups] = useState(null)
    const [progress, setProgress] = useState(null)

    let prods_copy = JSON.parse(JSON.stringify(prodsToRun))
    let basecase_copy = JSON.parse(JSON.stringify(modelConfig?.base))


    const getConditions = async (metakey) => {
        try {
            const res = await getMeta(study.uid, auth, metakey)
            return res || null
        } catch (error) {
            console.error('Error fetching meta:', error)
            return null
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const fetchedConditions = await getMeta(study.uid, auth, attCondMetaTableName)
            const fetchedAttLookups = await getMeta(study.uid, auth, attCondMetaLookupName)
            setConditions(fetchedConditions)
            setAttLookups(fetchedAttLookups)
        }

        fetchData()
    }, [attCondMetaTableName, attCondMetaLookupName, study.uid, auth])

    function applyConditions(curr_product, atts, rules, lookups) {
        let updatedAtts = atts.map(att => ({ ...att, readOnly: false }))

        rules?.forEach(rule => {
            rule.values.forEach(val => {
                if (curr_product[rule.attribute] === val.value) {
                    val.conditions.forEach(cond => {
                        const targetAttribute = updatedAtts.find(att => att.name === cond.attribute)
                        if (targetAttribute) {
                            targetAttribute.readOnly = cond.readOnly
                        }
                    })
                }
            })
        })

        function updateLevels(attributeName, levels) {
            return updatedAtts.map(att => {
                if (att.name === attributeName) {
                    const newLevels = levels.map((level, index) => ({
                        value: index + 1,
                        label: level
                    }))
                    return { ...att, levels: newLevels }
                }
                return att
            })
        }

        function applyLookups(currProduct, lookupData) {
            for (const key in lookupData) {
                const value = currProduct[key]
                if (lookupData[key][value]) {
                    const data = lookupData[key][value]
                    for (const attr in data) {
                        if (Array.isArray(data[attr])) {
                            updatedAtts = updateLevels(attr, data[attr])
                        } else if (typeof data[attr] === 'object') {
                            if (currProduct[attr] !== undefined) {
                                applyLookups(currProduct, data[attr])
                            }
                        }
                    }
                }
            }
        }

        if (lookups) {
            for (const lookupKey in lookups) {
                applyLookups(curr_product, { [lookupKey]: lookups[lookupKey] })
            }
        }

        return updatedAtts
    }

    const runForecasts = async (prod, prodatts) => {
        let counter = 1
        let resultsCollection = []
        const fuelTypes = prodatts.find(f => f.name === 'fuel')?.levels        

        let originalState = []

        // Iterate over each fuel type
        for (let fuelType of fuelTypes) {

            let new_prod = prods_copy.find(f => f.uid === prod.uid)
             if (!new_prod) {
                throw new Error(`Product with uid ${prod.uid} not found in prods_copy`)
            }
            new_prod['fuel'] = fuelType?.value

            // Apply initial conditions to prodatts        
            prodatts = applyConditions(new_prod, prodatts, conditions.rules, attLookups)

            let isFullsize = isPickup ? (prodatts.find(f => f.name === 'towmax')?.levels[0]?.label === '5,000 lbs' ? true : false) : null

            // Check if there is already a product with the same brandmodel and fuel type already in the market that is not my custom bo_prod
            let prodExists = prods_copy.find(p => p['brandmodel'] === new_prod['brandmodel'] && p['fuel'] === fuelType?.value && p.available === 1 && p.uid !== 'bo_prod')
            console.log('new_prod - does this exist?', new_prod)
            console.log('existingProd', prodExists)

            if (prodExists) {
                new_prod.available = 0
                // prodExists.available = 1

                // Remove any same brandmodel prods but different fuel type from the market
                prods_copy.forEach(p => {
                    if (p['brandmodel'] === new_prod['brandmodel'] && p.uid !== prodExists.uid && p.available === 1) {
                        originalState.push({ uid: p.uid, available: p.available })
                        p.available = 0
                    }
                })
            }
            else {
                new_prod.available = 1

                if (fuelType?.value !== 1) {//not gas
                    updateProductForFuelType(new_prod, fuelType?.value, isPickup, isFullsize)
                } else {
                    // Assign new_prod to any product in the market that is the same brandmodel and make it fuel type of 1 and available
                    let sameBrandModelProd = prods_copy.find(p => p['brandmodel'] === new_prod['brandmodel'] && p.uid !== 'bo_prod')
                    if (sameBrandModelProd) {
                        sameBrandModelProd['fuel'] = 1
                        sameBrandModelProd.available = 1
                        new_prod.available = 0
                        new_prod = sameBrandModelProd
                    }
                }

                // Remove any same brandmodel prods but different fuel type from the market
                prods_copy.forEach(p => {
                    if (p['brandmodel'] === new_prod['brandmodel'] && p['fuel'] !== fuelType?.value) {
                        originalState.push({ uid: p.uid, available: p.available })
                        p.available = 0
                    }
                })
            }
                
            console.log('ORIGINal State; restore products back: ', originalState);
            console.log('PRODS_COPY AFTER CONFIG CHANGE FUEL!!!', prods_copy)

            // Compute the forecast
            const result = await computeFordModel(study.uid, auth, {
                config: prods_copy,
                filter,
                modelInfo,
                brandvalues: [],
                playHash: modelConfig.playHash
            })

            let share = result?.[prodExists?.uid || new_prod?.uid]?.share
            console.log('Forecast happened', counter)
            counter++

            resultsCollection.push({
                id: prodExists ? prodExists?.uid : new_prod.uid,
                label: prodExists ? prodExists?.label : new_prod.label,
                fueltype: fuelType?.label,
                result: share,
            })

            // Restore original availability
            originalState.forEach(state => {
                let product = prods_copy.find(p => p.uid === state.uid)
                if (product) {
                    product.available = state.available
                }
            })

             // Clear the originalState array for the next fuelType loop
            originalState = []
        
        }

        return resultsCollection
    }


   

    const updateProductForFuelType = (prod, fuelType, isPickup, isFullsize) => {

        // Update the current product based on fuel type and vehicle type
        if (!isPickup) {
            switch (fuelType) {
                case 2: //'hybrid'
                    prod['fuel'] = 2
                    prod['drive'] = 3
                    prod['engine'] = 1
                    prod['mpg'] = 6
                    prod['timerefuelgas'] = 1
                    prod['accelgas'] = 2
                    prod['numseats'] = 3
                    prod['rowseats'] = 2
                    prod['foldflat'] = 1
                    prod['gesture'] = 2
                    prod['cinema'] = 2
                    prod['towing'] = 1
                    prod['maint'] = 6
                    prod['resale'] = 4
                    prod['batteryreplace'] = 2
                    prod['msrp'] = 3
                    break
                case 3: //'ev'
                    prod['fuel'] = 3
                    prod['drive'] = 2
                    prod['rangeev'] = 3
                    prod['savings'] = 4
                    prod['timerefuelev'] = 5
                    prod['accelev'] = 3
                    prod['numseats'] = 3
                    prod['rowseats'] = 2
                    prod['foldflat'] = 1
                    prod['gesture'] = 1
                    prod['cinema'] = 1
                    prod['towing'] = 2
                    prod['maint'] = 3
                    prod['addcost'] = 3
                    prod['resale'] = 2
                    prod['batteryreplace'] = 5
                    prod['msrp'] = 5
                    prod['incentive'] = 1

                    break
                case 4: //'phev'
                    prod['fuel'] = 4
                    prod['drive'] = 2
                    prod['engine'] = 1
                    prod['mpg'] = 5
                    prod['rangephev'] = 2
                    prod['savings'] = 4
                    prod['timerefuelgas'] = 1
                    prod['accelev'] = 4
                    prod['numseats'] = 3
                    prod['rowseats'] = 2
                    prod['foldflat'] = 1
                    prod['gesture'] = 2
                    prod['cinema'] = 2
                    prod['towing'] = 1
                    prod['maint'] = 6
                    prod['addcost'] = 2
                    prod['resale'] = 4
                    prod['batteryreplace'] = 3
                    prod['msrp'] = 3
                    prod['incentive'] = 1
                    break
                case 5: //'eRev'
                    prod['fuel'] = 5
                    prod['drive'] = 2
                    prod['rangeerev'] = 2
                    prod['savings'] = 4
                    prod['timerefuelev'] = 1
                    prod['gascharger'] = 2
                    prod['accelev'] = 3
                    prod['numseats'] = 3
                    prod['rowseats'] = 2
                    prod['foldflat'] = 1
                    prod['gesture'] = 1
                    prod['cinema'] = 1
                    prod['towing'] = 2
                    prod['maint'] = 6
                    prod['addcost'] = 3
                    prod['resale'] = 2
                    prod['batteryreplace'] = 5
                    prod['msrp'] = 5
                    prod['incentive'] = 1
                    break
            }
        }
        else if (isPickup && isFullsize) {

            // Update for fullsize pickup
            switch (fuelType) {
                case 2:
                    prod['drive'] = 4
                    prod['engine'] = 1
                    prod['transmission'] = 2
                    prod['mpg'] = 3
                    prod['timerefuelgas'] = 1
                    prod['towrange'] = 1
                    prod['accelgas'] = 1
                    prod['bidirect'] = 2
                    prod['pano'] = 2
                    prod['bed'] = 2
                    prod['cab'] = 3
                    prod['towmax'] = 3
                    prod['payload'] = 1
                    prod['torque'] = 1
                    prod['maint'] = 6
                    prod['resale'] = 4
                    prod['batteryreplace'] = 2
                    prod['msrp'] = 3
                    break
                case 3:
                    prod['drive'] = 2
                    prod['rangeev'] = 1
                    prod['savings'] = 3
                    prod['timerefuelev'] = 4
                    prod['towrange'] = 2
                    prod['accelev'] = 3
                    prod['bidirect'] = 1
                    prod['pano'] = 1
                    prod['bed'] = 2
                    prod['cab'] = 1
                    prod['frunk'] = 4
                    prod['towmax'] = 1
                    prod['payload'] = 2
                    prod['torque'] = 3
                    prod['maint'] = 3
                    prod['addcost'] = 3
                    prod['resale'] = 2
                    prod['batteryreplace'] = 4
                    prod['msrp'] = 3
                    prod['incentive'] = 4
                    break
                case 4:
                    prod['drive'] = 2
                    prod['engine'] = 1
                    prod['transmission'] = 2
                    prod['mpg'] = 6
                    prod['rangephev'] = 1//??
                    prod['timerefuelgas'] = 1
                    prod['savings'] = 4//??
                    prod['towrange'] = 3
                    prod['accelev'] = 4
                    prod['bidirect'] = 2
                    prod['pano'] = 1
                    prod['bed'] = 3
                    prod['cab'] = 3
                    prod['towmax'] = 2
                    prod['payload'] = 2
                    prod['torque'] = 1
                    prod['maint'] = 6
                    prod['addcost'] = 1//??
                    prod['resale'] = 4
                    prod['batteryreplace'] = 3
                    prod['msrp'] = 4
                    prod['incentive'] = 1//??
                    break
                case 5:
                    prod['drive'] = 2
                    prod['rangeerev'] = 3
                    prod['savings'] = 3
                    prod['timerefuelev'] = 1
                    prod['gascharger'] = 1
                    prod['towrange'] = 3
                    prod['accelev'] = 3
                    prod['bidirect'] = 1
                    prod['pano'] = 1
                    prod['bed'] = 2
                    prod['cab'] = 1
                    prod['frunk'] = 2
                    prod['towmax'] = 2
                    prod['payload'] = 2
                    prod['torque'] = 3
                    prod['maint'] = 3
                    prod['addcost'] = 3
                    prod['resale'] = 2
                    prod['batteryreplace'] = 5
                    prod['msrp'] = 4
                    prod['incentive'] = 4
                    break
            }
        }
        else if (isPickup && !isFullsize) {

            // Update for non-fullsize pickup
            switch (fuelType) {
                case 2:
                    prod['drive'] = 3
                    prod['engine'] = 1
                    prod['transmission'] = 1
                    prod['mpg'] = 6
                    prod['timerefuelgas'] = 1
                    prod['towrange'] = 1
                    prod['accelgas'] = 2
                    prod['bidirect'] = 2
                    prod['pano'] = 1
                    prod['bed'] = 1
                    prod['cab'] = 1
                    prod['towmax'] = 2
                    prod['payload'] = 3
                    prod['torque'] = 1
                    prod['maint'] = 6
                    prod['resale'] = 4
                    prod['batteryreplace'] = 2
                    prod['msrp'] = 3
                    break
                case 3: //ev
                    prod['drive'] = 2
                    prod['rangeev'] = 2
                    prod['savings'] = 3
                    prod['timerefuelev'] = 6
                    prod['towrange'] = 3
                    prod['accelev'] = 4
                    prod['bidirect'] = 2
                    prod['pano'] = 2
                    prod['bed'] = 2
                    prod['cab'] = 1
                    prod['frunk'] = 3
                    prod['towmax'] = 1
                    prod['payload'] = 3
                    prod['torque'] = 2
                    prod['maint'] = 3
                    prod['addcost'] = 3
                    prod['resale'] = 3
                    prod['batteryreplace'] = 4
                    prod['msrp'] = 3
                    prod['incentive'] = 1
                    break
                case 4:
                    prod['drive'] = 2
                    prod['engine'] = 3
                    prod['transmission'] = 2
                    prod['mpg'] = 6
                    prod['rangephev'] = 4
                    prod['savings'] = 3
                    prod['timerefuelgas'] = 2
                    prod['towrange'] = 3
                    prod['accelev'] = 4
                    prod['bidirect'] = 2
                    prod['pano'] = 2
                    prod['bed'] = 1
                    prod['cab'] = 1
                    prod['towmax'] = 1
                    prod['payload'] = 3
                    prod['torque'] = 2
                    prod['maint'] = 6
                    prod['addcost'] = 3
                    prod['resale'] = 3
                    prod['batteryreplace'] = 4
                    prod['msrp'] = 3
                    prod['incentive'] = 1
                    break
                case 5: //eRev
                    prod['drive'] = 2
                    prod['rangeerev'] = 2
                    prod['savings'] = 3
                    prod['timerefuelev'] = 1
                    prod['gascharger'] = 1
                    prod['towrange'] = 3
                    prod['accelev'] = 4
                    prod['bidirect'] = 2
                    prod['pano'] = 2
                    prod['bed'] = 1
                    prod['cab'] = 1
                    prod['frunk'] = 2
                    prod['towmax'] = 1
                    prod['payload'] = 3
                    prod['torque'] = 2
                    prod['maint'] = 6
                    prod['addcost'] = 3
                    prod['resale'] = 3
                    prod['batteryreplace'] = 4
                    prod['msrp'] = 3
                    prod['incentive'] = 1
                    break
            }
        }
    }   

    const processProduct = async (prod) => {

        // Find and temporarily modify the availability of products in modelConfig.base with the same brand model as prod
        let basecase = JSON.parse(JSON.stringify(basecase_copy))
        let identicalProdsInBase = basecase.filter(p => p['brandmodel'] === prod['brandmodel'])

        //for identical products in basecase, change first prod to gas, remove all others (dups) from market
        identicalProdsInBase.forEach((p, idx) => {
            if (idx === 0) {
                p['fuel'] = 1 //gas
                p.available = 1
            }
            else {
                p.available = 0
            }
        })

        // let baseCollection = []
        const baseResult = await computeFordModel(study.uid, auth, {
            config: basecase,
            filter,
            modelInfo,
            brandvalues: [],
            playHash: modelConfig.playHash
        })

        console.log('baseResult', baseResult);

        const baseVal = baseResult[identicalProdsInBase[0]?.uid]?.share       
        const forecastCollection = await runForecasts(prod, atts)

        console.log('forecastCollection',forecastCollection)

        // const groupedByFuel = forecastCollection.reduce((acc, item) => {
        //     acc[item.fuelType] = acc[item.fuelType] || []
        //     acc[item.fuelType].push(item)
        //     return acc
        // }, {})

        // console.log('groupedByFuel', groupedByFuel);

        

        // let data = {}
        // Object.keys(forecastCollection)?.forEach(fc => { //was groupedByFuel

            //itm = {id, label, fueltype, result}
            const diff_from_base = forecastCollection.map(itm => ({
                ...itm,
                result: itm.result - baseVal,
                color: '#5383f2'
            })).sort((a, b) => a.result - b.result)

            console.log('diff_from_base', diff_from_base);

            // const finalres = sortAndReproportion(diff_from_base)
            let data = diff_from_base.sort((a, b) => a.result - b.result) //finalres.sort((a, b) => a.result - b.result)
            
            console.log('data', data);
            // const avgResult = finalres.reduce((acc, itm) => acc + itm.result, 0) / finalres.length

            // data[fuelType] = {
            //     average: avgResult,
            //     details: finalres.sort((a, b) => a.levelValue - b.levelValue)
            // }
        // })

        return data
    }

    const sortAndReproportion = (data) => {
            let minValue = data[0].result

            if (minValue < 0) {
                const reproportionedData = data.map(item => ({
                    ...item,
                    result: Math.abs(item.result - minValue)
                }))
                return reproportionedData
            } else {
                return data
            }
        }

    const averageByFuelType = (allResults) => {
        const aggregated = {}

        allResults.forEach(prodResultsArray => {
            prodResultsArray.forEach(prodResult => {
                const { fueltype, result } = prodResult
                if (!aggregated[fueltype]) {
                    aggregated[fueltype] = { totalResult: 0, count: 0, examples: [] }
                }
                aggregated[fueltype].totalResult += result
                aggregated[fueltype].count += 1
                aggregated[fueltype].examples.push(prodResult)
            })
        })

        const averagedResults = Object.keys(aggregated).map(fueltype => {
            const avgResult = aggregated[fueltype].totalResult / aggregated[fueltype].count
            const example = aggregated[fueltype].examples[0]
            return {
                id: example.id,
                label: example.label,
                fueltype: fueltype,
                result: avgResult,
                color: example.color
            }
        })

    return averagedResults
}

    const saveToJsonFile = (data, filename) => {
        const jsonStr = JSON.stringify(data, null, 2)
        const blob = new Blob([jsonStr], { type: 'application/json' })
        const link = document.createElement('a')
        link.download = filename
        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const saveToExcelFile = (d, filename) => {
        const headers = ["FuelType", "Result"]
        const rows = []
        
        d.forEach(item => {
            rows.push([
                item.fueltype,
                item.result,
            ])
        })
        

        const csvContent = [
            headers.join(","),
            ...rows.map(row => row.join(","))
        ].join("\n")

        const blob = new Blob([csvContent], { type: 'text/csvcharset=utf-8' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }


    const runForecaster = async () => {
        setButtonClicked(true)
        setRunning(true)

        let allResults = []
        let brandModelAttr = atts.find(att => att.name === 'brandmodel')
        
        if (!brandModelAttr) {
            throw new Error("Brandmodel attribute not found")
        }

        //add new product to iterate over brandmodels
        let newProd = {
            ...prods_copy[0], //acura mdx
            uid: 'bo_prod',           
            available: 1
        }
        prods_copy.unshift(newProd)
        // console.log('BRAND NEW PRODUCT CONFIG', newProd);

        let prodCounter = 1
        let levelCounter = 0
        for (const level of brandModelAttr.levels) { 
            
            if(level.label.includes('Rivian') || level.label.includes('Tesla')) continue
            // for testing
            // if (level.value !== 2) continue
            // if (levelCounter >= 4) break

            // Ensure no duplicate brand models are available
            // let identicalProds = prods_copy.filter(p => p[brandModelAttr.name] === level.value && p.uid !== 'bo_prod')
            // let originalState = identicalProds.map(p => ({ uid: p.uid, available: p.available }))
            // identicalProds.forEach(p => p.available = 0)

            // console.log('identicalProds', identicalProds);
            let new_prod = prods_copy.find(f => f.uid === 'bo_prod')//make sure reference to prods_copy
            new_prod[brandModelAttr.name] = level.value
            new_prod.label = level.label
        // console.log('NEW_PROD RIGHT BEFORE PASSING TO PROCCESSPRODUCT() #1', new_prod);
        // console.log('prods_copy RIGHT BEFORE PASSING TO PROCCESSPRODUCT() #1', prods_copy);

            const prodResults = await processProduct(new_prod)

            setProgress(Math.round((prodCounter++ / brandModelAttr.levels.length) * 100), 0)
            allResults.push(prodResults)

            console.log('allResults', allResults);

            // Restore original availability
            // originalState.forEach(p => {
            //     let product = prods_copy.find(bp => bp.uid === p.uid)
            //     if (product) {
            //         product.available = p.available
            //     }
            // })

            levelCounter++
        }

        // const averagedResults = averageByFuelType(allResults)
        const avg = averageByFuelType(allResults)
        const averagedResults = sortAndReproportion(avg)

        console.log('averagedResults', averagedResults);

        if (filetype.toLowerCase() === 'excel') {
            saveToExcelFile(averagedResults, `average_sensitivities_${isPickup ? 'pickup' : 'suv'}.csv`)
        } else if (averagedResults && filetype.toLowerCase() === 'chart') {
            setUseChart(true)
        }
        else {
            saveToJsonFile(averagedResults, `average_sensitivities_ ${isPickup ? 'pickup' : 'suv'}.json`)            
        }
        setRunning(false)
    }

    const handleClick = async () => {
        await runForecaster()
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ fontSize: 12, color: '#808080', marginTop: 40, marginBottom: 15 }}>
                Generates Average Sensitivities data in an Excel or Json file
            </div>
            <div>
                <button className='btn border' onClick={handleClick}>Run Avg Sensitivities</button>
            </div>
            {
                running ?
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ marginTop: 30, marginBottom: 15 }}>
                            <i className='fad fa-truck-monster fa-spin' style={{ fontSize: 28, color: '#0d47a1' }}></i>
                        </div>
                        <div>
                            <span style={{ fontSize: 18 }}>{progress} %</span>
                        </div>
                    </div>
                    :
                    buttonClicked && <div style={{ marginTop: 30, fontSize: 18, color: '#006400', fontWeight: 700, letterSpacing: 3, textTransform: 'uppercase' }}>Finished!</div>
                
            }
            
            
        </div>
    )
}