import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as selectionHelper from '../../utils/selectionHelper';
import { CoreWidget1 } from './CoreWidget1';
import './RealTimeNewsWidget.scss';

// const series = [
//     { key: 'count', label: 'Mentions', dot: false, stroke: '#808080', strokeDasharray: [3,3] },
//     { key: 'rolling_count', label: 'Rolling Mentions', dot: false, stroke: '#bb59e3', strokeWidth: 2 }
// ];


export const RealTimeNewsWidget = (props) => {
    
    const study = useSelector(state => state.study);
    const selections = useSelector(state => state.selections);
    const selection = selectionHelper.getSelection(props, study, selections);

    const wordPath = [selection?.label];

    const series = props.series || [];

    const renderContent = (cache, getJSONData) => {

        let data = cache || [];

        if( props.kgFormat ){
            data = data.map(entry => {
                return {
                    datetime: entry.datetime,
                    image: entry.contentImage,
                    source: entry.url,
                    text: entry.articleBody,
                    title: name
                };
            })
        }

        //console.log('data', data);
        data = data.sort((a, b) => {
            const aDate = new Date(a.datetime);
            const bDate = new Date(b.datetime);
            return aDate < bDate ? 1 : aDate > bDate ? -1 : 0;
        });
        if (getJSONData && typeof getJSONData === 'function')
            getJSONData(data)

        return <div className='content1 realtime-news-content'>
            {cache && cache.length == 0 ? <div className='no-entries'>
                no entries
            </div> : null}
            {data.map((entry, idx) => {
                return <div className='entry' key={idx}>
                    {entry.image ?
                        <div className='image'>
                            <img src={entry.image} alt="news image" />
                        </div>
                        : null
                    }
                    <div className='title'>
                        {entry.title}
                    </div>
                    <div className='text'>
                        {entry.text}
                    </div>
                    
                    <div className='timestamp'>
                        {entry.source ? <a className='source-link' href={entry.source} target="_blank">Continue Reading</a> : null}
                        {moment.utc(entry.datetime).local().fromNow()}
                    </div>
                    
                </div>
            })}
        </div>;
    }

    const useBigData = props.useBigData ? {
        path: selection ? props.useBigData.path.replace("{{wordpath}}", encodeURI(wordPath.join('|'))) : null,
        body: {
            returnsArray: true
        }
    } : {};
    
    return <CoreWidget1 {...props}
        showSelection={true}
        render={renderContent}
        useBigData={useBigData}
        debug
        // {
        //     path: selection ? `GET:graph-timeseries?rolling_window=3&search_terms=${encodeURI(wordPath.join('|'))}` : null
        // }
        />

}

