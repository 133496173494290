import * as React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators as cardActionCreators } from '../../store/Cards.ts';
import { actionCreators as channelsActionCreators } from '../../store/Channels.ts';
import { actionCreators as storyActionCreators } from '../../store/Stories.ts';
import { NewStoryBox } from '../story/NewStoryBox';
import './StoriesSubMenu.scss';

export class StoriesSubMenu extends React.Component {

    handleNavClicked = () => {
        window.topBar.hideMenu();
    }

    componentDidMount(){
        this.checkData();
    }

    componentDidUpdate(){
        this.checkData();
    }

    checkData = () => {
        const channels = this.props.channels;

        if (channels && !channels.data && !channels.loading && !channels.error) {
            this.props.loadChannels();
        }
        if (!this.props.stories.drafts) {
            this.props.loadStories();
        }
    }


    newStory = () => {
        
        window.topBar.hideMenu();

        const box = <NewStoryBox
            closeRequested={() => window.topBar.hideMenu()}
            okayClicked={(data, callback) => {

                // create story
                const storyData = {
                    label: data.label || 'My story',
                    cards: [],
                    question: null,
                    headline: null,
                    content: null
                };
                this.props.createStory(storyData, (newStory) => {

                    // navigate to the new story
                    this.props.history.push(`/study/${this.props.studyId}/edit-story/${newStory.uid}`)
                    window.topBar.hideMenu();

                    if (callback) {
                        callback();
                    }

                })

            }}
        />
        window.topBar.openDynamicMenu(box);

    }

   

    render() {

        const studyId = this.props.studyId;

        const channels = this.props.channels;
        if(!channels) return null;


        let readChannels = channels.data || [];
        

        // let readChannels = (channels.read || [])
        //     .filter(channel => channel !== 'Personal');
        const storyDict = this.props.stories.dict || {};
        const drafts = (this.props.stories.drafts || []).map(draft_uid => storyDict[draft_uid]).filter(x => x.draft);

        
        return <div className='stories-submenu'>

            <h3>Story Channels</h3>

            <NavLink key='all-channels' className='m-item' activeClassName='sel' exact={true} to={`/study/${studyId}/feed`} onClick={this.handleNavClicked}>
                (All Channels)
            </NavLink>

            {readChannels.map(channel => {
                const link = encodeURI(channel.key);
                return <NavLink key={channel.key} className='m-item' activeClassName='sel' to={`/study/${studyId}/feed/${link}`} onClick={this.handleNavClicked}>
                    {channel.label}
                </NavLink>
            })}

            <h3>Create New Story</h3>

            <div className='m-item' onClick={this.newStory}>
                New story...
            </div>

            <h3>My Story Drafts</h3>

            {drafts.map(draft => <Link key={draft.uid} className='m-item' to={`/study/${studyId}/edit-story/${draft.uid}`} onClick={this.handleNavClicked}>
                {draft.label || 'unnamed story'}
            </Link>)}

            {drafts.length === 0 ? <div className='m-item disabled'>
                (none)
            </div> : null}

        </div>;
    }
}

StoriesSubMenu = withRouter(
    connect(
        state => ({ 
            channels: state.channels,
            stories: state.stories
        }),
        dispatch => bindActionCreators({...storyActionCreators, ...cardActionCreators, ...channelsActionCreators}, dispatch)
    )(StoriesSubMenu)
);
