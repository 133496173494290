import * as globals from "../globals"
import { guid } from "../utils/guid"
import { mapObject } from "../utils/mapObject"
import {
	DCM_CLONE_PRODUCT,
	DCM_DELETE_PRODUCT,
	DCM_RESET,
	DCM_SET_ETAG,
	DCM_RESET_BRAND_EQUITY_VALUE,
	DCM_APPLY_CONDITIONS,
	DCM_RESET_TO_BASECASE,
	DCM_RESET_PRODUCT,
	DCM_SET_ATT_LOCKED,
	DCM_SET_ATT_VALUE,
	DCM_SET_BASE,
	DCM_SET_BRAND_EQUITY_VALUE,
	DCM_SET_PRODUCT_LABEL,
	STUDY_PIN_BRAND,
	DCM_SET_LEVEL_ALLOWED,
	DCM_SET_MESSAGE_ALLOWED,
	DCM_SET_MISC_VALUE,
	DCM_SET_OPTIMIZATION_METRIC,
	DCM_TOGGLE_OPTIMIZATION_SELECTION,
	DCM_ADD_OPTIMIZATION_SELECTION,
	DCM_REMOVE_OPTIMIZATION_SELECTION,
	DCM_SET_PRODUCT_AVAILABLE,
	DCM_SET_PRODUCT_LOCKED,
	DCM_SET_PRODUCT_PINNED,
	DCM_SET_SEGMENTATION,
    DCM_SET_VIEW,
    DCM_CONFIG_INIT,
	DCM_UPDATE_CONFIG,
    DCM_NEED_UPDATE,
    DCM_SET_ACTIVE_CONFIG,
	NOTIF_ADD,
	NOTIF_MARK_COMPLETE
    
	// @ts-ignore
} from "./action-types"
import * as apiService from "../utils/apiService"
import { OPTIM_SET_RUNLIST_DATA, OPTIM_SET_RUNLIST_LOADING } from "./action-types"
import * as Scenario from "./Scenario";
import { applyConditions } from '../utils/dcmUtils'
import { getMeta } from  '../utils/metaUtils'

const isNull = (val: any, fallback: any) => (val === undefined || val === null) ? fallback : val

export interface ConfigDetails {
  assumptionsId: string
  baseCaseId: string
  brandEquities: any
  type: string
  etag: string
  showHints: boolean
  brandEquitiesDef: any // Consider moving to study
  optim: any
  dirty: boolean
  def: string
}
export interface configs {
	uid: string
	cloneOf?: string
	label: string
	image?: string
	play: any
    base: any    
}

const initialState: DCMState = {
	activeConfig: '',
	configModels: [],	
	configs: {}, 	
	productDefs: {},
	selectorFilterItems:''
}

export interface DCMState {
    activeConfig: string
	configModels: any
	configs: any
	productDefs: any 
	selectorFilterItems: any
	
}


const timeout = (ms: any) => new Promise((res: any) => setTimeout(res, ms))

export const actionCreators = {

	forecast: (play_base: string, configs: any) => async (dispatch: any, getState: any) => {
		dispatch({
			type: DCM_UPDATE_CONFIG,
            play_base,
            // configs: , 
            configModels: configs
			// productSlots: getState()?.study?.config?.productSlots
		})
	},

	setAvailable: (productUid: any, available: any, configModel: any) => async (dispatch: any, getState: any) => {
		dispatch({
			type: DCM_SET_PRODUCT_AVAILABLE,
			productUid,
			value: available,
			activeConfig:configModel
		})
		dispatch({ type: DCM_NEED_UPDATE, id: 'play', configModel })
		
	},

	applyConditions: () => async (dispatch: any, getState: any) => {
		
		const state = getState()
		const metaConditions = await getMeta(state.study.uid, state.auth, 'conditions')
		
		const dcm = getState().dcm
		const activeConfig = dcm.activeConfig
		const productDef = dcm.productDefs['default']
		const curr_prodId = state.selections.product?.uid
		const curr_product = dcm.configs[activeConfig]?.play.find(f => f.uid === curr_prodId)		

		// Apply the conditions to the products
		let updatedAtts = []
		if (metaConditions) {
			// console.log('rules', rules);
			updatedAtts = applyConditions(curr_product, productDef?.atts, metaConditions.rules)

			// Dispatch an action to update the products in the state
			dispatch({type: DCM_APPLY_CONDITIONS, updatedAtts})
		}

		return updatedAtts
	},

	applyMultiBasecases: () => async (dispatch: any, getState: any) => {

		const state = getState()
		const configModels = state.dcm.configModels
		const activeConfig = state.dcm.activeConfig
		const productDef = state.dcm.productDefs.default

		for (let cm of configModels) {

			let metaBasecase = await getMeta(state.study.uid, state.auth, `basecase${cm}`)

			if (metaBasecase) {
				const base = JSON.parse(JSON.stringify(state.dcm.configs[cm].base))

				if (base) {
					for (let prod of base) {
						let basecaseData = metaBasecase.find(mb => mb.productname === prod.uid)

						if (basecaseData) {
							for (let att of productDef.atts) {
								if (basecaseData.hasOwnProperty(att.name)) {
									prod[att.name] = basecaseData[att.name]
								}
							}
						}
					}
				}
			
				dispatch({ type: DCM_SET_BASE, activeConfig: cm, basecase: base, resetPlay: true })
			}
		}			
	},

	cloneProduct: (productUid: any) => async (dispatch: any, getState: any) => {
		dispatch({
			type: DCM_CLONE_PRODUCT,	
			productUid,
		})
		dispatch({ type: DCM_NEED_UPDATE, id: 'play' })
		// dispatch({
		// 	type: DCM_UPDATE_CONFIG, id: 'play',
		// 	productSlots: getState()?.study?.config?.productSlots
		// })
	},

	deleteProduct: (productUid: any) => async (dispatch: any, getState: any) => {
		dispatch({
			type: DCM_DELETE_PRODUCT,
			productUid,
		})
		dispatch({ type: DCM_NEED_UPDATE, id: 'play' })
		// dispatch({
		// 	type: DCM_UPDATE_CONFIG, id: 'play',
		// 	productSlots: getState()?.study?.config?.productSlots
		// })
	},

	setProductPinned: (productUid: any, pinned = true) => async (dispatch: any,	getState: any) =>
		dispatch({
			type: DCM_SET_PRODUCT_PINNED,
			productUid,
			value: pinned,
		}),

	setProductLabel: (productUid: any, label: any) => async (dispatch: any,	getState: any) => {
		dispatch({
			type: DCM_SET_PRODUCT_LABEL,
			productUid,
			label,
		})
		// dispatch({
		// 	type: DCM_UPDATE_CONFIG, id: 'play', productUid,
		// 	productSlots: getState()?.study?.config?.productSlots
		// })
	},

	// todo: move setBrandPinned out of config reducer. doesn't belong here
	setBrandPinned: (brandUid: any, pinned: any = true) => async (dispatch: any,getState: any) =>
		dispatch({
			type: STUDY_PIN_BRAND,
			brandUid,
			value: pinned,
		}),

	// this was experimental, to lock a product slot (i think), and is not being used anymore.
	setLocked: (productUid: any, locked: any) => async (
		dispatch: any,
		getState: any,
	) =>
		dispatch({
			type: DCM_SET_PRODUCT_LOCKED,
			productUid,
			value: locked,
		}),

	setPlay: (configModel: string, productUid: any, attName: any, value: any) => async (dispatch: any, getState: any) => {
		dispatch({
			type: DCM_SET_ATT_VALUE,
			configModel,
			productUid,
			attName,
			value,
		})
		dispatch({ type: DCM_SET_ACTIVE_CONFIG, activeConfig: configModel })
		// dispatch({ type: DCM_NEED_UPDATE, id: 'play' })
		
	},

	resetToBasecase: () => (dispatch: any) => {
		dispatch({ type: DCM_RESET_TO_BASECASE })
		//dispatch({ type: DCM_UPDATE_CONFIG, id: 'play' })
    },

	setBase: () => (dispatch: any) => {
		dispatch({ type: DCM_SET_BASE })
		//dispatch({ type: DCM_UPDATE_CONFIG, id: 'base' })
    },
    
	setActiveConfig: (activeConfig: any) => async (dispatch: any, getState: any) => {
        dispatch({
            type: DCM_SET_ACTIVE_CONFIG,
            activeConfig
        })
    },

	resetPlay: (productUid: any) => (dispatch: any, getState: any) => {
		dispatch({ type: DCM_RESET_PRODUCT, productUid })
		dispatch({ type: DCM_NEED_UPDATE, id: 'play' })
		// dispatch({
		// 	type: DCM_UPDATE_CONFIG, id: 'play', productUid,
		// 	productSlots: getState()?.study?.config?.productSlots
		// })
	},

	// resetBrandEquities: item => (dispatch) =>
	//     dispatch({ type: DCM_RESET_BRAND_EQUITIES, item }),

	resetConfig: () => (dispatch: any, getState: any) => {
		dispatch({ type: DCM_RESET })
		dispatch({ type: DCM_NEED_UPDATE, id: 'play' })
		// dispatch({
		// 	type: DCM_UPDATE_CONFIG, id: 'play',
		// 	productSlots: getState()?.study?.config?.productSlots
		// })
	},

	setView: (view: any) => (dispatch: any) =>
		dispatch({ type: DCM_SET_VIEW, value: view }),

	set: (key: any, value: any) => (dispatch: any, getState: any) => {
		dispatch({ type: DCM_SET_MISC_VALUE, key, value })
		dispatch({ type: DCM_NEED_UPDATE, id: 'play' })
		// dispatch({
		// 	type: DCM_UPDATE_CONFIG, id: 'play',
		// 	productSlots: getState()?.study?.config?.productSlots
		// })
	},

	// setMessageValue: (item, name, value) => (dispatch) =>
	//     dispatch({ type: DCM_SET_MESSAGE_VALUE, item, name, value }),

	// setPerceptionValue: (item, name, value) => (dispatch) =>
	//     dispatch({ type: DCM_SET_PERCEPTION_VALUE, item, name, value }),

	setBrandEquityValue: (item: any, name: any, value: any) => (dispatch: any) => {
		dispatch({ type: DCM_SET_BRAND_EQUITY_VALUE, item, name, value })
		dispatch({ type: DCM_NEED_UPDATE, id: 'play' })
		//dispatch({ type: DCM_UPDATE_CONFIG, id: 'play', brandEquityItem: item, brandEquityName: name })
	},

	resetBrandEquityValue: (item: any, name: any, value: any) => (dispatch: any	) => {
		dispatch({ type: DCM_RESET_BRAND_EQUITY_VALUE, item, name, value })
		dispatch({ type: DCM_NEED_UPDATE, id: 'play' })
		//dispatch({ type: DCM_UPDATE_CONFIG, id: 'play', brandEquityItem: item, brandEquityName: name })
	},

	// for optimizer. if att is locked, optimizer will leave it set at attValue
	setAttLock: (slotUid: any, attName: any, locked: any) => async (dispatch: any) =>
		dispatch({
			type: DCM_SET_ATT_LOCKED,
			slotUid,
			attName,
			locked,
		}),

	// for optimizer. if level is allowed, optimizer will consider it as possible value
	setLevelAllow: (slotUid: any,attName: any,levelValue: any,allowed: any) => async (dispatch: any) =>
		dispatch({
			type: DCM_SET_LEVEL_ALLOWED,
			slotUid,
			attName,
			levelValue,
			allowed,
		}),

	setMessageAllow: (key: any, name: any, allowed: any) => async (dispatch: any) =>
		dispatch({
			type: DCM_SET_MESSAGE_ALLOWED,
			key,
			name,
			allowed,
		}),

	setOptimizationMetric: (metric: any) => (dispatch: any) =>
		dispatch({ type: DCM_SET_OPTIMIZATION_METRIC, value: metric }),

	toggleOptimizationSelection: (selection: any, callback: any) => (dispatch: any,	getState: any) => {
		dispatch({ type: DCM_TOGGLE_OPTIMIZATION_SELECTION, selection })
		if (callback) {
			const state = getState()
			const newSelections = state.study.config.optim.selections
			callback(newSelections)
		}
	},

	addOptimizationSelection: (selection: any, callback: any) => (dispatch: any,getState: any) => {
		dispatch({ type: DCM_ADD_OPTIMIZATION_SELECTION, selection })
		if (callback) {
			const state = getState()
			const newSelections = state.study.config.optim.selections
			callback(newSelections)
		}
	},

	removeOptimizationSelection: (selection: any, callback: any) => (dispatch: any,getState: any) => {
		dispatch({ type: DCM_REMOVE_OPTIMIZATION_SELECTION, selection })
		if (callback) {
			const state = getState()
			const newSelections = state.study.config.optim.selections
			callback(newSelections)
		}
	},

	setSegmentation: (id: any) => (dispatch: any) => dispatch({ type: DCM_SET_SEGMENTATION, value: id }),

	runOptimizer: () => async (dispatch: any, getState: any) => {
		var state: any = getState()

		// create a UID for this optimization run
		//var runId = 'testrun1'// guid()
		var runId: any = guid()

		// send a notification
		// dispatch({
		// 	type: NOTIF_ADD,
		// 	//value: `Optimization run ${runId} started`,
		// 	value: `Optimization is running (${runId})`,
		// 	running: true,
		// 	runId,
		// })

		//await timeout(15000)

		const optim: any = state.study.config.optim || {}
		const metric: any = optim.metric || globals.default_optimizer_metric
		const filterobj: any =
			state.filter.selectedFilter || globals.getDefaultFilter(state?.study)
		//const filter = (state.study.selectedFilter || {}).syntax || '1==1'
		//const filterLabel = (state.study.selectedFilter ||
		const config: any = state.study.config

		const targetProducts: any = (optim.selections || []).map(
			//(sel: any) => sel.uid,
			(sel: any) => ({ uid: sel.uid, label: sel.label }),
		)
		// console.log('targetProducts', targetProducts)

		// construct a label
		let label = "Maximize " + globals.metrics.find(m => m.key == metric)?.label + " of "
		if (targetProducts.length == 1) {
			label += `'${targetProducts[0]?.label}'`
		}
		else {
			label += `'${targetProducts[0]?.label}'`
            for (let i = 1; i < targetProducts.length-1; i++){
				label += `, '${targetProducts[i]?.label}'`
			}
			label += ` and '${targetProducts[targetProducts.length - 1]?.label}'`
		}
		label += " for '" + filterobj?.label + "'"
		label += " started " + new Date().toLocaleString()

		var query: any = {
			studyId: state.study.uid,
			uid: runId,
			label,
			goal: {
				targetMeasure: metric,
				targetFilter: filterobj.syntax,
				targetFilterLabel: filterobj.label,
				targetProducts: targetProducts,
			},
			config: {
				products: state.study.config.productSlots.map((slot: any) => {
					const def = state.study.config.productDefs[slot.def || "default"]
					let p: any = {
						uid: slot.uid,
						label: slot.label, // optimizer seems to need this but i want to delete
						available: slot.play.available,
					}
					const optim = slot.optim || {}
					def.atts.forEach((att: any) => {

						// NOTE: it's possible that the start value doesn't exist in allowed values. figure out where and how that should be handled!!

						const notTargetSlot = !targetProducts.find((p1: any) => p1.uid == slot.uid)
						const isReadOnly = att.readOnly
						const isLocked = optim.locks?.includes(att.name)

						// multiselect is a bit different than discrete or contin
						if (att.type == "multiselect") {

							// loop through options
							att.options.forEach((option: any) => {
								
								const currentVal = slot.play[option.name] || att.defaultValue

								if (notTargetSlot || isReadOnly || isLocked) {
									p[option.name] = currentVal
								}
								else {
									p[option.name] = {
										startValue: currentVal,
										allowedLevels: [isNull(att.yesValue,1), isNull(att.noValue,0)]
									}
								}

							})

						}
						else {
							// discrete or contin

							let currentVal = slot.play[att.name] || att.defaultValue

							if (notTargetSlot || isReadOnly || isLocked) {
								p[att.name] = currentVal
							}
							else {
								
								if (att.type == "discrete") {
									const disallowedLevels = (optim.disallow ? optim.disallow[att.name] : null) || []
									p[att.name] = {
										startValue: currentVal,
										allowedLevels: att.levels.filter((level: any) => !disallowedLevels.includes(level.value)).map((level: any) => level.value)
									}
								}
								else if (att.type == "contin") {
									p[att.name] = {
										startValue: currentVal,
										allowedRange: [att.min, att.max]
										// todo: step???
									}
								}
								
							}
						}

						
					})
					return p
				}),
				equity_drivers: {
					level: state.study.config.brandEquitiesDef.level,
					//data: config.brandEquities
					data: Object.keys(config.brandEquities).reduce(
						(accum: any, key: any) => {
							// convert key to level-less format, e.g. 'model:5' to '5'
							const newKey: any = key.replace(
								state.study.config.brandEquitiesDef.level + ":",
								"",
							)
							accum[newKey] = Object.keys(config.brandEquities[key]).reduce(
								(accum2: any, key2: any) => {
									accum2[key2] = config.brandEquities[key][key2].play
									return accum2
								},
								{},
							)
							return accum
						},
						{},
					),
				},
				brand_equities_to_optimize: {
					level: state.study.config.brandEquitiesDef.level,
					perceptions: Object.keys(config.brandEquities).reduce(
						(accum: any, key: any) => {
							// convert key to level-less format, e.g. 'model:5' to '5'
							const newKey: any = key.replace(
								state.study.config.brandEquitiesDef.level + ":",
								"",
							)
							const arr: any = Object.keys(config.brandEquities[key]).reduce(
								(accum2, key2) => {
									if (config.brandEquities[key][key2].allowed) {
										accum2.push(key2)
									}
									return accum2
								},
								[],
							)
							if (arr.length > 0) accum[newKey] = arr
							return accum
						},
						{},
					),
				},
			},
			assumptions: {
				// this attaches on the backend server i think. don't delete without adjusting backend, because backend assumes it exists.
			},
		}
		// console.log("optimizer query", query)
		
		

		const url: string = `${globals.apiRoot}/optimize/start`
		try {
			const response: any = await apiService.aPost(state.auth, url, query)

			if (response.status === 202) {
				const value: any = await response.json()
				// console.log("optimization accepted", value)

				// todo: reload the optinfo stuff
				// load the optruns
				{
					const f = async () => {

						dispatch({ type: OPTIM_SET_RUNLIST_LOADING, value: true })

						//setLoadingItems(true)
						try {
							const response = await apiService.aGet(state.auth, `${globals.apiRoot}/study/${state.study.uid}/optruns`)
							if( response.ok ){
								const runs = await response.json()
								//setItems(runs)
								//console.log('runs', runs)

								dispatch({ type: OPTIM_SET_RUNLIST_DATA, listData: runs })
								dispatch({ type: OPTIM_SET_RUNLIST_LOADING, value: false })
							}
						}
						catch (err) {
							console.error(err)
						}
						//setLoadingItems(false)
						
					}
					f()

				}







				// if( value.status === 'error' ){

				//     dispatch({
				//         type: NOTIF_MARK_COMPLETE, // 'COMPLETE_RUNNING_NOTIFICATION',
				//         runId,
				//         success: false,
				//         message: 'Optimization run failed. ' + value.message
				//     })

				// }
				// else{

				//     dispatch({
				//         type: NOTIF_MARK_COMPLETE, // 'COMPLETE_RUNNING_NOTIFICATION',
				//         runId,
				//         success: true,
				//         data: value,
				//         message: 'Optimization run completed. Click to view results',
				//         showOptButtons: true
				//     })

				// }
			} else {
				console.error("Optimization failed to start.", response.statusText)
				// dispatch({
				// 	type: NOTIF_MARK_COMPLETE, // 'COMPLETE_RUNNING_NOTIFICATION',
				// 	runId,
				// 	success: false,
				// 	message: "Optimization failed to start. " + response.statusText,
				// })
			}
		} catch (err) {
			console.error("Optimizer error", err)

			// dispatch({
			// 	type: NOTIF_MARK_COMPLETE, //'COMPLETE_RUNNING_NOTIFICATION',
			// 	runId,
			// 	success: false,
			// 	message: "Optimization run failed: " + JSON.stringify(err),
			// })
		}
	},
}

export const dirtyCheckSlot = (play: any, base: any, pdef: any): boolean => {
	
	if (play?.available != base.available) return true // eslint-disable-line eqeqeq
	for (let i in pdef.atts) {
		let att = pdef.atts[i]
		// this is hacky replace "1" on the next line with the attribute's default value
		if ((play[att.name] || 1) !== (base[att.name] || 1)) {
			return true
		}
	}
	return false
}

const reHash = (config: DCMState): DCMState => {
	let obj: any = {
		...config,
		etag: undefined,
	}

	//let etag = hash(obj) // taking too long! could minimize the data being hashed
	let etag = guid() // sorry redux rules, hashing is taking too long fix this later
	// breaking the rules of redux because a reducer should have a predictable outcome. this random guid is not predictable
	// I don't think it matters though, due to how I'm using the etag
	// maybe i should just think of a better way to do this, avoiding the etag
	// i'm not just using object refs, because i want to compare config against cached results.
	// maybe there's a way to do this using object refs

	return {
		...config,
		etag,
	}
}

const toArray = (obj: any) => {
	return Object.entries(obj).map((entry) => ({
		key: entry[0],
		value: entry[1],
	}))
}

const toNestedArray = (obj: any) => {
	return Object.entries(obj).map((entry) => ({
		key: entry[0],
		value: toArray(entry[1]),
	}))
}

export const reducer = (state: DCMState, action: any): DCMState => {

    state = state || initialState    
    // console.log('state', state);
	
	// offload to scenario reducer instead
	// if (action.type.startsWith(Scenario.scenarioActionType + ":")) {
	// 	return Scenario.reducer(state, action)
	// }

	switch (action.type) {
		case DCM_RESET:
            return initialState
        
		case DCM_NEED_UPDATE: { 

            const configId: string = action.id //play or base
            return {
				...state,
				configs: {
					...state.configs,
					[action.configModel]: {
						...state.configs[action.configModel],
						dirty: true
					}
				}
            }
        }
            
        case DCM_CONFIG_INIT: {

            // const { configs, configModels, productDefs } = action
            const data = action.data
            const configModels = action.configModels
            const configs = data.config.productSlots
            const productDefs = data.config.productDefs
			
			// console.log('study', study);

            const configTypes = ['play', 'base']
            let updatedConfigs = {...state.configs } //{}

            //defaults for config details
            const config_details: ConfigDetails = {
                assumptionsId: '', 
                baseCaseId:'todo',
                brandEquities: null,
                brandEquitiesDef: {},
                dirty: false,
                etag: guid(),
                optim: null,
                showHints: false,
				type: 'multi',
				def: configs?.[0]?.def || 'default'
            }

            configModels.forEach((cm: string) => {
                // Update the configs state with the modified config model
                updatedConfigs[cm] = {
                    ...config_details,
                    play: configs.map((prod: any) => {
                        return {
                            uid: prod.uid,
                            label: prod.label, 
							image: prod.image,
							dirty: false,
							cloneOf: prod.cloneOf,
                            ...prod['play']
                        }
                    }), 
                    base: configs.map((prod: any) => {
                        return {
                            uid: prod.uid,
                            label: prod.label, 
                            image: prod.image,
							cloneOf: prod.cloneOf,
                            ...prod['base']
                        }
                    })
                }
            })

            return {
				...state,
				configModels: configModels,
                activeConfig: configModels[0],
                configs: updatedConfigs,
				productDefs,				
            }
        }
            
        case DCM_SET_ACTIVE_CONFIG: {

			const { activeConfig } = action

            return {
                ...state,
                activeConfig,
            }
        }

        
            
        case DCM_UPDATE_CONFIG: { }

            // return reHash({
			// 	...state,
			// 	configs: state.configs.map((prod: any) =>
			// 		prod.uid !== action.productUid
			// 			? prod
			// 			: {
			// 					...prod,
			// 					play: {
			// 						...prod.play,
			// 						[action.attName]: action.value,
			// 						dirty: dirtyCheckSlot(
			// 							{
			// 								...prod,
			// 								play: { ...prod.play, [action.attName]: action.value },
			// 							},
			// 							state.productDefs.default,
			// 						),
			// 					},
			// 			  },
			// 	),
			// })

        //     const { play_base, configModels } = action

        //     // console.log('DCM_UPDATE_CONFIG', play_base, configs, configModels);

        //     // Initialize configs if null
        //     const updatedConfigs = state.configs ? { ...state.configs } : {}

        //     configModels.forEach((cm: string) => {
        //         // Initialize the config model array if it doesn't exist
        //         updatedConfigs[cm] = updatedConfigs[cm] || []

        //         // Create a new array for the config model, updating existing configs and adding new ones
        //         const updatedModelConfigs = configs.map((newConfig) => {
        //             const existingConfigIndex = updatedConfigs[cm].findIndex(c => c.uid === newConfig.uid)
        //             if (existingConfigIndex > -1) {
        //                 // Update existing config
        //                 const updatedConfig = { ...updatedConfigs[cm][existingConfigIndex] }
        //                 updatedConfig[play_base] = { ...newConfig[play_base] }
        //                 return updatedConfig
        //             } else {
        //                 // Return new config with play/base initialized as needed
        //                 return {
        //                     ...newConfig,
        //                     play: play_base === 'play' ? { ...newConfig.play } : {},
        //                     base: play_base === 'base' ? { ...newConfig.base } : {},
        //                 }
        //             }
        //         })

        //         // Update the configs for the current model
        //         updatedConfigs[cm] = updatedModelConfigs
        //     })

        //     return {
        //         ...state,
        //         configs: updatedConfigs,
        //         productDefs: productDefs ? productDefs : 
        //     }
        // }
		
		case DCM_APPLY_CONDITIONS: {
			// console.log('Updated atts readOnly for rangephev:', action.updatedAtts.find(f => f.name === 'rangephev')?.readOnly);
			// console.log('Updated atts readOnly for rangeerev:', action.updatedAtts.find(f => f.name === 'rangeerev')?.readOnly);

			
			return {
				...state,
				productDefs: {
					...state.productDefs,
					default: {
						...state.productDefs.default,
						atts: action.updatedAtts  // Updating the atts under default
					}
				}
			}
		}

        

		case DCM_CLONE_PRODUCT: {
			let active_config = state.activeConfig
			console.log('product uid', action.productUid);
			const prod = state.configs[active_config]?.play.find(
				(p: any) => p.uid === action.productUid,
			)

			console.log('product found', prod);
			if (!prod) return state
			const newProd: configs = {
				...prod,
				uid: guid(),
				label: prod.label + " clone",
				cloneOf: prod.uid,
				play: { ...prod.play },
				base: {
					available: 0,
				},
			}
			return reHash({
				...state,
				configs: {
					...state.configs,
					[active_config]: {
						...state.configs[active_config],
						play: [
							...state.configs[active_config].play,
							newProd,
						]
					}
				} 
			})
		}

		case DCM_DELETE_PRODUCT: {
			return reHash({
				...state,
				configs: state.configs.filter(
					(slot: any) => slot.uid !== action.productUid,
				),
			})
		}

		case DCM_SET_PRODUCT_AVAILABLE: {

			let play = state.configs[action.activeConfig].base.find(p => p.uid === action.productUid)
			let base = state.configs[action.activeConfig].base.find(p => p.uid === action.productUid)

			return reHash({
				...state,
				configs: {
					...state.configs,
					[action.activeConfig]: {
						...state.configs[action.activeConfig],
						play: state.configs[action.activeConfig].play.map(prod =>
							prod.uid !== action.productUid
								? prod
								: {
									...prod,
									available: action.value ? 1 : 0,
									dirty: dirtyCheckSlot(
										play,
										base,
										state.productDefs.default,
									),
								}
						),
					}
				}
			})
		}

		case DCM_SET_PRODUCT_PINNED:
			return {
				...state,
				configs: state.configs.map((slot: any) =>
					slot.uid !== action.productUid
						? slot
						: { ...slot, pinned: action.value },
				),
			}

		// // broken! this should maybe belong to study?
		// case DCM_SET_BRAND_PINNED:
		//     return {
		//         ...state,
		//         brands: state.brands.map(brand => brand.uid === action.brandUid ?
		//             { ...brand, pinned: action.value }
		//             : brand
		//         )
		//     }

		case DCM_SET_PRODUCT_LOCKED:
			return {
				...state,
				configs: state.configs.map((product: any) =>
					product.uid !== action.productUid
						? product
						: {
								...product,
								play: {
									...product.play,
									locked: action.value ? true : false,
								},
						  },
				),
			}
		
		case DCM_SET_ETAG: {
			const { activeConfig } = action
			return {
				...state,
				configs: {
					...state.configs,
					[activeConfig]: {
						...state.configs[activeConfig],
						etag: guid(),
						play: [...state.configs[activeConfig].play || [] ]
					},
				}
			}
		}


		case DCM_SET_ATT_VALUE: {
			
			const { configModel, productUid, attName, value } = action

			// Check if the config model exists
			if (!state.configs.hasOwnProperty(configModel)) {
				console.error(`Config model ${configModel} not found.`)
				return state // Return current state if the specified configModel does not exist
			}

			// Find the index of the product to update in the play array
			const playIndex = state.configs[configModel].play.findIndex(p => p.uid === productUid)
			const base = state.configs[configModel].base.find(p => p.uid === productUid)

			// Ensure the product was found before attempting to update
			if (playIndex !== -1) {
				const dirty = dirtyCheckSlot(
								state.configs[configModel].play[playIndex],
								base,
								state.productDefs.default,
							)
				// Create a new updated product object with the new attribute value
				const updatedProd = {
					...state.configs[configModel].play[playIndex],
					[attName]: value,	
					dirty: dirty
				}

				// Create a new play array with the updated product
				const updatedPlay = [
					...state.configs[configModel].play.slice(0, playIndex),
					updatedProd,
					...state.configs[configModel].play.slice(playIndex + 1),					
				]

				// Update the state immutably
				return {
					...state,
					configs: {
						...state.configs,
						[configModel]: {
							...state.configs[configModel],	
							etag: guid(),
							play: updatedPlay,							
						},
					},
				}
			} else {
				console.error(`Product with UID ${productUid} not found in config model ${configModel}.`)
				return state // Return current state if the product to update does not exist
			}
		}

			
			// return reHash({
			// 	...state,
			// 	configs: state.configs[action.configModel].map((prod: any) =>
			// 		prod.uid !== action.productUid
			// 			? prod
			// 			: {
			// 					...prod,
			// 					play: {
			// 						...prod.play,
			// 						[action.attName]: action.value,
			// 						dirty: dirtyCheckSlot(
			// 							{
			// 								...prod,
			// 								play: { ...prod.play, [action.attName]: action.value },
			// 							},
			// 							state.productDefs.default,
			// 						),
			// 					},
			// 			  },
			// 	),
			// })

		case DCM_SET_PRODUCT_LABEL:
			return reHash({
				...state,
				configs: state.configs.map((slot: any) =>
					slot.uid !== action.productUid
						? slot
						: {
								...slot,
								label: action.label,
						  },
				),
			})

		case DCM_SET_BASE: {
			const { activeConfig, basecase, resetPlay } = action
			// console.log('basecase', basecase);
			// console.log('resetPlay', resetPlay);

			return {
				...state,
				configs: {
					...state.configs,
					[activeConfig]: {
						...state.configs[activeConfig],
						play: resetPlay ? basecase : state.configs[activeConfig].play,
						base: basecase,
					},
				}				
			}
			
		}
			
			
		
		
		case DCM_RESET_TO_BASECASE:

			let activeConfig = state.activeConfig;
			// Ensure that the active config and base are available
			if (!state.configs[activeConfig] || !state.configs[activeConfig].base) {
				console.error('Base configuration not found');
				return state // Return current state if base is not defined
			}

			return {
				...state,
				configs: {
					...state.configs,
					[activeConfig]: {
						...state.configs[activeConfig], // Spread all existing properties of the active config
						play: [...state.configs[activeConfig].base] // Overwrite play with a clone of base
					}
				}
		}


		case DCM_RESET_PRODUCT:
			let active_config = state.activeConfig
			return reHash({
				...state,
				configs: state.configs[active_config].map((prod: any) =>
					prod.uid !== action.productUid
						? prod
						: {
							...prod,
							play: { ...prod.base, dirty: false },
						  },
				),
			})

		// case DCM_RESET_MESSAGES:
		//     return reHash({
		//         ...state,
		//         messages: state.messages.map(m => ({
		//             ...m,
		//             values: {
		//                 ...m.values,
		//                 [action.item]: {
		//                     play: m.values[action.item].base,
		//                     base: m.values[action.item].base
		//                 }
		//             }
		//         }))
		//     })

		// case DCM_RESET_PERCEPTIONS:
		//     return reHash({
		//         ...state,
		//         perceptions: state.perceptions.map(m => ({
		//             ...m,
		//             values: {
		//                 ...m.values,
		//                 [action.item]: {
		//                     play: m.values[action.item].base,
		//                     base: m.values[action.item].base
		//                 }
		//             }
		//         }))
		//     })

		case DCM_RESET:
			// todo: simplify the brandEquities structure to make resetting it easier than all this junk:
			const newBrandEquities: any = mapObject(state.brandEquities, (i: any) =>
				mapObject(i, (j: any) => ({
					...j,
					play: j.base,
					dirty: false,
				})),
			)

			// const toArray = (obj) => Object.entries(obj).map(entry => ({
			//     key: entry[0],
			//     value: {
			//         ...entry[1],
			//         play: entry[1].base,
			//         dirty: false
			//     }
			// }))

			// const reduceArray = (arr) => arr.reduce((accum, val) => {
			//     accum[val.key] = val.value
			//     return accum
			// }, {})

			// const brandEquitiesAsArray = Object.entries(state.brandEquities).map(entry => ({
			//     key: entry[0],
			//     value: reduceArray(toArray(entry[1]))
			// }))

			// const newBrandEquities = reduceArray(brandEquitiesAsArray)

			return reHash({
				...state,
				configs: state.configs.map((product: any) => ({
					...product,
					play: { ...product.base, dirty: false },
				})),
				brandEquities: newBrandEquities,
			})

		case DCM_SET_VIEW:
			return state
		// return { // not currently used
		//     ...state,
		//     view: action.value
		// }

		case DCM_SET_MISC_VALUE:
			return {
				// not sure if we should rehash here
				...state,
				[action.key]: action.value,
			}

		case DCM_RESET_BRAND_EQUITY_VALUE: {
			alert("todo")
			return state
			return reHash({
				...state,
				brandEquities: {
					...state.brandEquities,
					[action.item]: {
						...state.brandEquities[action.item],
						[action.name]: {
							play: state.brandEquities[action.item][action.name].base,
							base: state.brandEquities[action.item][action.name].base,
						},
					},
				},
			})
		}

		case DCM_SET_BRAND_EQUITY_VALUE: {
			return reHash({
				...state,
				brandEquities: {
					...state.brandEquities,
					[action.item]: {
						...state.brandEquities[action.item],
						[action.name]: {
							play: action.value,
							base: state.brandEquities[action.item][action.name].base,
							dirty:
								action.value !=
								state.brandEquities[action.item][action.name].base,
						},
					},
				},
			})
		}

		case DCM_SET_ATT_LOCKED:
			let _slot: any = state.configs.find(
				(slot: any) => slot.uid === action.slotUid,
			)
			let _optim: any = _slot ? _slot.optim : {}
			let _locks: any = (_optim ? _optim.locks : null) || []
			let new_locks: any = _locks.filter((el: any) => el !== action.attName)

			if (action.locked) {
				new_locks.push(action.attName)
			}

			return {
				...state,
				//etag: guid(), // not changing the config because the value it is locked on is stored in constrants instead of config
				configs: state.configs.map((slot: any) =>
					slot.uid !== action.slotUid
						? slot
						: {
								...slot,
								optim: {
									...slot.optim,
									locks: new_locks,
								},
						  },
				),
			}

		case DCM_SET_LEVEL_ALLOWED:
			let _slot2: any = state.configs.find(
				(slot: any) => slot.uid === action.slotUid,
			)
			let new_optim: any = _slot2 ? { ..._slot2.optim } : {}
			new_optim.disallow = { ...new_optim.disallow } // make sure disallow exists
			new_optim.disallow[action.attName] =
				new_optim.disallow[action.attName] || [] // make sure disallow[attName] array exists

			// remove existing value
			new_optim.disallow[action.attName] = new_optim.disallow[
				action.attName
			].filter((el: any) => el !== action.levelValue)

			if (!action.allowed) {
				new_optim.disallow[action.attName].push(action.levelValue)
			}

			// remove array if empty
			if (new_optim.disallow[action.attName].length === 0) {
				delete new_optim.disallow[action.attName]
			}

			// remove disallow object if empty
			if (Object.keys(new_optim.disallow).length == 0) {
				delete new_optim.disallow
			}

			return {
				...state,
				//etag: guid(), doesn't represent a change in config
				configs: state.configs.map((slot: any) =>
					slot.uid !== action.slotUid
						? slot
						: {
								...slot,
								optim: new_optim,
						  },
				),
			}

		case DCM_SET_MESSAGE_ALLOWED:
			return {
				...state,
				brandEquities: {
					...state.brandEquities,
					[action.key]: {
						...state.brandEquities[action.key],
						[action.name]: {
							...state.brandEquities[action.key][action.name],
							allowed: action.allowed ? true : false,
						},
					},
				},
			}

		case DCM_SET_OPTIMIZATION_METRIC:
			return {
				...state,
				optim: {
					...state.optim,
					metric: action.value,
				},
			}

		case DCM_TOGGLE_OPTIMIZATION_SELECTION: {
			const prev: any = (state.optim || {}).selections || []
			const add: any = !prev.find((x: any) => x.uid === action.selection.uid)

			const newList: any = add
				? [...prev, action.selection]
				: prev.filter((x: any) => x.uid !== action.selection.uid)
			return {
				...state,
				optim: {
					...state.optim,
					selections: newList,
				},
			}
		}

		case DCM_ADD_OPTIMIZATION_SELECTION: {
			const prevList: any = (state.optim || {}).selections || []
			const found: any = prevList.find(
				(x: any) => x.uid === action.selection.uid,
			)

			const newList: any = found ? prevList : [...prevList, action.selection]

			return {
				...state,
				optim: {
					...state.optim,
					selections: newList,
				},
			}
		}

		case DCM_REMOVE_OPTIMIZATION_SELECTION: {
			const prevList: any = (state.optim || {}).selections || []
			const newList: any = prevList.filter(
				(x: any) => x.uid !== action.selection.uid,
			)

			return {
				...state,
				optim: {
					...state.optim,
					selections: newList,
				},
			}
		}

		case DCM_SET_SEGMENTATION: {
			return state
			// return reHash({ // don't remember what this is for (maybe the soon to be obsolete choose segments page.)
			//     ...state,
			//     segmentation: {
			//         ...state.segmentation,
			//         play: action.value
			//     }
			// })
		}

		default:
			return state
	}

	

	
	
}
