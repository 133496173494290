import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CardFlipper } from './CardFlipper';
import { CardSwipe } from './CardSwipe';
import './StoryContent.scss';

export class StoryContent extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            selectedIndex : 0,
            direction: ""
        }
    }

    handleSelectedIndexChanged = (selectedIndex) => {
        this.setState({ 
            selectedIndex,
            direction: this.state.selectedIndex > selectedIndex ? "swipe-left" : "swipe-right"
        });
    }


    render() {

        const cards = this.props.cards && this.props.cards.length > 0 ? this.props.cards : [
            <div></div>
        ];

        const safeSelectedIndex = Math.max(0, Math.min(cards.length - 1, this.state.selectedIndex));

        return <div className={`${this.props.className} ${this.state.direction} story-content`}>
            <CardSwipe card={cards[safeSelectedIndex]} nbItems={cards.length}  selectedIndex={safeSelectedIndex} selectedIndexChanged={this.handleSelectedIndexChanged}/>
            <CardFlipper className='card-flipper' nbItems={cards.length} selectedIndex={safeSelectedIndex} selectedIndexChanged={this.handleSelectedIndexChanged}/>

        </div>;

    }


}

StoryContent = withRouter(
    connect(
        null,
        null
    )(StoryContent)
);
