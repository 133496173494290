import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Compare.ts';
import './CompareDrawer.scss';
import * as debounce from '../../utils/debounce';


export class CompareDrawer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedTabIndex: 0,
            selectedCategory: null,
            searchTerm: null,
            isSearching: false
        };
        this.searchNode = React.createRef();

        this.iconCollapsed = 'fal fa-folder';
        this.iconCollapsed2 = 'fa fa-folder';
        this.iconExpanded = 'fal fa-folder-open';
        this.iconExpanded2 = 'fa fa-folder-open';



        this.selIndicator = '●'; // <i className='fal fa-asterisk' style={{fontSize: '7pt'}} />;

        window.filterDrawer = this;
    }

    selectCategory(cat) {
        this.setState({ selectedCategory: cat });
    }

    getNbGroupsSelected(folder) {
        let nb = 0;
        for (var i in folder.groups) {
            if (folder.groups[i].isSelected) nb++;
        }
        return nb++;
    }

    clearFolderSelections(folder) {
        this.props.deselectFolder(folder.id);
    }

    beginSearch = debounce.debounce(() =>
        this.props.expandOnlySearchedFolder(this.state.searchTerm),
        debounce.forecastDelay
    );

    render() {
        
        let folders = this.props.folders || [];
        let isOpen = this.props.isOpen;

        // obtain list of filtergroup categories
        let categories = [];
        let defaultCategory = folders.length > 1 ? folders[1].category : 'General';
        folders.forEach(folder => {
            let category = folder.category;
            if (category && !categories.includes(category)) {
                categories.push(category);
            }
        });
        const categories2 = categories.filter(c => c);

        // compute the number of user-selected filters in each category
        let categoryCounts = {};
        folders.forEach(folder => {
            let category = folder.category;// || defaultCategory;
            let nb = this.getNbGroupsSelected(folder);
            categoryCounts[category] = (categoryCounts[category] || 0) + nb;
        });

        //let selectedFilter = this.props.selectedFilter || {};
        let selection = this.props.selection;
        let selCategory = this.state.selectedCategory || (categories ? categories[0] : null);

        // get elements for scroll list
        let elements1 = null;
        let elements2 = null;
        
        if (this.props.isLoading) {
            elements = <div>loading...</div>;
        } else if (folders == null) {
            elements = <div>error</div>;
        } else {
            let selFolders = this.state.isSearching
                ? folders
                : folders.filter(folder => folder.category === selCategory);

            //console.log('folders', folders);
            let totalFolder = folders[0];
            let totalGroup = totalFolder ? totalFolder.groups[0] : null;

            elements1 = [
                totalGroup ? 
                    <div
                        key={'group_' + (totalGroup.id || totalGroup.syntax)}
                        className={'filter-item' + (totalGroup.isSelected ? ' selected' : '')}
                        onClick={() =>
                            totalGroup.isSelected
                                ? this.props.deselectGroup(totalFolder.id, totalGroup.id || totalGroup.syntax)
                                : this.props.selectGroup(totalFolder.id, totalGroup.id || totalGroup.syntax)
                        }
                    >
                        {totalGroup.label}
                    </div>
                    : null
            ]

            elements2 = selFolders.map(folder => {
                let nb = this.getNbGroupsSelected(folder);
                let groups = <div
                    className={'group-item' + (nb > 0 ? ' has-selections' : '')}
                    onClick={() =>
                        folder.isExpanded
                            ? this.props.collapseFolder(folder.id)
                            : this.props.expandFolder(folder.id)
                    }
                >
                    <i className={folder.isExpanded ? (nb > 0 ? this.iconExpanded2 : this.iconExpanded)
                        : (nb > 0 ? this.iconCollapsed2 : this.iconCollapsed)}
                    />
                    &nbsp; {folder.label}
                    {nb > 0 ? <span className="sel-indicator"> {this.selIndicator}</span> : null}
                    {nb > 0 ? (
                        <button
                            className="clear-selections"
                            title="Clear selection(s)"
                            onClick={ev => {
                                this.clearFolderSelections(folder);
                                ev.preventDefault();
                                ev.stopPropagation();
                            }}
                        >
                            <i className="fal fa-times" />
                        </button>
                    ) : null}
                </div>;

                return (
                    <div key={'folder_' + folder.id} className={'filter-group'}>
                        {this.state.isSearching ? folder.matchedGroup ? groups : '' : groups}
                        {folder.isExpanded
                            ? folder.groups.map(group => {
                                let item = <div
                                    key={'group_' + (group.id || group.syntax)}
                                    className={'filter-item' + (group.isSelected ? ' selected' : '')}
                                    onClick={() =>
                                        group.isSelected
                                            ? this.props.deselectGroup(folder.id, group.id || group.syntax)
                                            : this.props.selectGroup(folder.id, group.id || group.syntax)
                                    }
                                >
                                    {group.label}
                                </div>
                                return this.state.isSearching ? group.matched ? item : '' : item;
                            })
                            : null}
                    </div>
                );
            });
        }

        // main render
        return (
            <div className={'compare-drawer' + (isOpen ? ' open' : '')}>

                <div className="pane-title">
                <i className="fal fa-male" /><i className="fal fa-male" /><i className="fal fa-male" /> Compare
                    <button style={{ float: 'right', cursor: 'pointer' }} onClick={() => this.props.closeRequested()} role='button' aria-label='compare'>
                        <i className='fal fa-times'></i>
                    </button>
                    <button className="right clear-all-btn" onClick={() => this.props.deselectAll()} role='button' aria-label='reset groups'>
                        <i className='fal fa-eraser'/> reset groups
                    </button>
                </div>

                {/* filter label section */}
                <div className="section filter-label">
                    <div className="title">Label</div>
                    <div className="row">
                        <button
                            className={
                                'toggle-btn' + (selection.useCustomLabel ? ' selected' : '')
                            }
                            title="Customize label"
                            onClick={() => this.props.setUseCustomLabel(!selection.useCustomLabel)}
                        >
                           <i className="fal fa-edit"></i>
                        </button>
                        {selection.useCustomLabel ? (
                            <input type="text" placeholder="custom filter label" value={selection.customLabel || ""}
                                onChange={(ev) => this.props.setCustomLabel(ev.target.value)}
                            />
                        ) : (
                                <div className="auto-label" title={selection.label}>
                                    {selection.label} (todo: smarter label)
                                </div>
                            )}
                    </div>
                </div>

                {/* subgroups head section (title, tabs, search) */}
                <div className="section bottom-border subgroups-head">

                    {/* title */}
                    <div className="title">
                        Subgroup(s)
                    </div>

                    {/* search box */}
                    <div className="filter-search">
                        <input ref={this.searchNode} type="text" placeholder="search" role='input' aria-label='search' onChange={(ev) => {
                            if (ev.target.value.trim() !== '') {
                                this.setState({ searchTerm: ev.target.value.trim(), isSearching: true });
                                this.beginSearch();
                            } else {
                                this.setState({ isSearching: false });
                                this.props.collapseAllGroups();
                            }
                        }} />
                        <button className="clear" title="Clear search" role='button' aria-label='clear search' onClick={() => {
                            this.searchNode.current.value = '';
                            this.setState({ searchTerm: '', isSearching: false });
                            this.props.collapseAllGroups();
                        }}>
                            <i className="fal fa-times" />
                        </button>
                    </div>

                    

                    {/* category tabs */}
                    {!this.state.isSearching
                        ? <div className="tab-header">
                            {categories2.map(cat => (
                                <div
                                    key={'tab_' + cat}
                                    className={
                                        'tab' +
                                        ((this.state.selectedCategory || defaultCategory) === cat
                                            ? ' selected'
                                            : '') +
                                        (categoryCounts[cat] > 0 ? ' has-selections' : '')
                                    }
                                    onClick={() => this.selectCategory(cat)}
                                >
                                    {cat}
                                    {categoryCounts[cat] > 0 ? (
                                        <span className="sel-indicator"> {this.selIndicator}</span>
                                    ) : null}
                                </div>
                            ))}
                        </div> : null
                    }
                </div>

                {/* scrolling list of elements */}
                <div className="scroller">
                    {elements1}
                    {elements2}
                </div>


                {/* close button */}
                {/* <div className='bottom-fixed'>
                    <span className='btn' onClick={() => this.props.closeRequested()}>Close
                    </span>
                </div> */}

            </div>
        );
    }
}

CompareDrawer = withRouter(
    connect(
        state => state.compare,
        dispatch => bindActionCreators(actionCreators, dispatch)
    )(CompareDrawer)
);
