import * as globals from '../globals';
import { 
    ANALYTICS_RECEIVE, 
    ANALYTICS_REQUEST, 
    STUDY_RESET
    // @ts-ignore
} from './action-types';


export interface AnalyticsState{

        [key: string]: {

            loading: boolean;

            loaded: boolean;

            error: any;

            value: any;

        }

}

const initialState: AnalyticsState = {
};

export const actionCreators = {

    // addScenario: (key, label, cache) => async (dispatch: any, getState: any) => {
    //     dispatch({ type: 'ADD_SCENARIO', key, label, cache });
    // }

    loadAnalytics: (path: string, key: string, body: any, callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();
        let studyId = state && state.study ? state.study.uid : null;
        if( !studyId ) return;

        // if( state.cache && !cacheHelper.needsRefresh2(state.cache, path) ){
        //     return;
        // }

        dispatch({ type: ANALYTICS_REQUEST, path, key });

        try {
            const url = `${globals.apiRoot}/study/${studyId}/analytics/misc?path=${encodeURIComponent(path)}`;
            //console.log(url);
            const response = await fetch(url, {
                method: body ? 'post' : 'get',
                body: body ? JSON.stringify(body) : undefined,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + state.auth.token
                }
            });

            const value = await response.json();

            dispatch({ type: ANALYTICS_RECEIVE, path, key, value });

            if( callback ){
                callback();
            }

        } catch (error) {
            console.error('loadAnalytics error', error);
            dispatch({ type: ANALYTICS_RECEIVE, path, key, value: null, error });
        }

    },

    loadYak: (path: string, key: string, query: any, callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();
        let studyId = state && state.study ? state.study.uid : null;
        if( !studyId ) return;

        //console.log('loadYak query', query);

        // if( state.cache && !cacheHelper.needsRefresh2(state.cache, path) ){
        //     return;
        // }

        dispatch({ type: ANALYTICS_REQUEST, path, key });

        try {
            const url = `${globals.apiRoot}/study/${studyId}/compute/yak`;
            //console.log(url);
            //console.log('yak query', query);
            const response = await fetch(url, {
                method: query ? 'post' : 'get',
                body: query ? JSON.stringify(query) : undefined,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + state.auth.token
                }
            });

            let value = await response.json();
            if( value && value.success ){
                value = value.results;
                dispatch({ type: ANALYTICS_RECEIVE, path, key, value });
            }
            else{
                console.error('loadYak error', value.errorMessage);
                dispatch({ type: ANALYTICS_RECEIVE, path, key, value: null, error: value.errorMessage });
            }

            

            if( callback ){
                callback();
            }

        } catch (error) {
            console.error('loadYak error', error);
            dispatch({ type: ANALYTICS_RECEIVE, path, key, value: null, error });
        }

    },
    

};


export const reducer = (state: AnalyticsState, action: any): AnalyticsState => {

    state = state || initialState;

    switch( action.type ){

        case STUDY_RESET : return initialState;
        
        case ANALYTICS_REQUEST : {

            return {
                ...state,
                [action.key || action.path]: {
                    loading: true,
                    loaded: false
                }
            }

        }
        case ANALYTICS_RECEIVE : {

            return {
                ...state,
                [action.key || action.path]: {
                    loading: false,
                    loaded: true,
                    error: action.error,
                    value: action.value
                }
            }

        }
    }

    return state;


};
