import * as apiService from './apiService';
import * as globals from '../globals';

export const loadSavedCard = async (studyId, auth, cardId) => {
    
    // load the card
    if( !cardId ) return;
    const url = `${globals.apiRoot}/study/${studyId}/card/${cardId}`; //cards db table
    let card = null;
    try {
        const res = await apiService.aGet(auth, url);
        if (!res.ok) throw res.responseError; // syntax?
        card = await res.json();
    }
    catch (err) { 
        console.error('error loading card', studyId, cardId);
    }

    if (!card) {
        return null;
    }
    else if (card.is_dynamic) {
        // is dynamic

        // hmmmmm....
        // we have everything we need?
                
    }
    else {
        // is old-style widget

        const url = `${globals.apiRoot}/study/${studyId}/datacard/${card.widget_uid}`;
        const res = await apiService.aGet(auth, url);
        const widget = await res.json();

        // add widget data?
        // add description
        // add tags
        // add sources?

    }

    return card;

}

export const saveCard = async (studyId, auth, cardId, saveInfo) => {
    
    // saveInfo has is_dynamic, dynamic_item, widget_uid, state
    console.log('saveCard.saveInfo', saveInfo);
    const body = {
        uid: cardId,
        is_dynamic: saveInfo.is_dynamic,
        dynamic_item: saveInfo.dynamic_item,
        widget_uid: saveInfo.widget_uid,
        state: saveInfo.state,
        comment: saveInfo.comment,
        tags: saveInfo.tags,
    };

    try {
        const url = `${globals.apiRoot}/study/${studyId}/card`;
        const response = cardId ? await apiService.aPatch(auth, `${url}/${cardId}`, body) :
            await apiService.aPost(auth, url, body);
        
        let _cardId = cardId;
        if( !_cardId ){
            const _r = await response.json();
            console.log('returning newCardId', _r?.uid)
            return _r?.uid; // return the new card Id
        }
        
    } catch (err) {
        console.error('saveCard error', err);
    }


}