import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../store/Scenario'
import { actionCreators as FordActionCreator } from '../../store/FordForecast'
import { dateFormat } from '../../utils/dateFormat'
import './OpenSaveScenario.scss'

export class OpenSaveScenario extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentFolderId: null, // folder currently being viewed is affected by drilling down into a folder or clicking 'up'
            selectedId: null, // selected file
            copiedId: null, // if copying a file, contains the id of the source file
            filename: '', // user-provided filename. is also populated when user clicks a file

            error: null, // if present, an error message will be presented over files (hmm maybe this should not overlay, but insert)
            success: null,

            prompt: null, // message
            promptInput: null, // e.g., null, text
            promptAction: null, // label for action button, e.g., Overwrite, Rename
            promptActionCallback: null, // called when prompt action button is clicked

            expandFolders: false,
            tabs_remove: ['summary', 'save_scn', 'open_scn']

        }
        this.refresh = this.refresh.bind(this)
    }

    componentDidMount() {
        // Call refresh when the component mounts
        this.refresh()
    }

    filenameChanged(event) {
        this.setState({ filename: event.target.value })
    }

    promptInputChanged(event) {
        this.setState({ promptInputValue: event.target.value })
    }

    findFile(id, list) {
        let files = list || (this.props.scenarios || {}).files
        for (let f in files) {
            let file = files[f]

            if (file.id === id) return file
            if (file.files) {
                let x = this.findFile(id, file.files)
                if (x) return x
            }
        }
        return null
    }

    findParentOf(id, list) {
        let files = list || this.props.scenarios.files
        for (let f in files) {
            let file = files[f]

            //if (file.id === id) return file
            if (file.files) {
                for (let cf in file.files) {
                    let cfile = file.files[cf]

                    if (cfile.id === id) return file // found child return the parent
                }

                let x = this.findParentOf(id, file.files) // search in children
                if (x) return x
            }
        }
        return null
    }

    toggleExpandFolders() {
        this.setState({
            expandFolders: !this.state.expandFolders
        })
    }

    clicked(id) {

        let file = this.findFile(id)
        if (file == null) return

        if (this.state.selectedId === id) {
            // this is a double click (not a true double click, because there could be much time between clicks)

            // if this is a folder, drill into it
            if (file.type === 'folder') {
                this.setState({
                    currentFolderId: id
                })
                return

            } 
            else {//if (this.props.mode === 'open') {
                // this is a file, and we're in the open file dialog

                // open the scenario
                this.open(id)
            }

        } 
        else {
            // this is not a double click. it's the first time item was clicked.

            // select
            if (file.type === 'folder') {
                // it's a folder, so don't change the filename textbox, just the selection
                this.setState({
                    selectedId: id
                })
            }
            else {
                // it's a file, so change the filename textbox and the selection
                this.setState({
                    selectedId: id,
                    filename: file.label
                })
            }
        }
    }

    up() {
        // get current folder
        if (this.state.currentFolderId == null) return

        let parent = this.findParentOf(this.state.currentFolderId)
        this.setState({
            currentFolderId: (parent || {}).id,
            selectedId: this.state.currentFolderId
        })
    }

    open(id) {
        // clear errors
        this.setState({ error: null, success: null })

        if (!id) {
            id = this.state.selectedId
        }
        if (id == null) {
            this.setState({ error: 'Please select a scenario file to open' })
        }

        let file = this.findFile(id)
        if (file == null) return

        // todo: might want to exclude drill in here because it's not super clean

        //have to clear out forecast store
        this.props.clearFordForecasts()

        if (file.type === 'folder') {
            // drill into folder
            this.setState({
                currentFolderId: id,
                selectedId: null
            })
        }
        else {
            
            //loadScenario found in store/Scenario.ts
            this.props.loadScenario(this.state.selectedId,  (success, message) => {
                if (success) {
                    this.setState({success: `Scenario config(s) changed successfully`})
                }
                else {
                    this.setState({ error: message })
                }
            })
        }
    }

    save(id) {
        // clear errors
        this.setState({ error: null, success: null })

        let filename = this.state.filename
        if (filename == null || filename === '') return

        //called directly from store/Scenario.ts actionCreator
        this.props.saveScenario(
            this.state.currentFolderId,
            this.state.filename,
            false,
            (success, message, file_exists) => {
                // callback
                if (success) { // file saved okay
                    this.setState({ success: 'Scenario saved successfully!' })
                    this.refresh()
                    // this.close()

                }
                else if (file_exists) { // file not saved because it already exists

                    // prompt for overwrite
                    this.setState({
                        prompt: 'File exists. Overwrite?',
                        promptInput: null,
                        promptAction: 'Overwrite',
                        promptActionCallback: () => {
                            // user said it's okay to overwrite
                            this.props.saveScenario( this.state.currentFolderId, this.state.filename, true, (success2, message2) => {
                                    
                                if (success2) { // file saved okay
                                    //this.close()
                                    this.refresh()
                                    // this.setState({success: 'Scenario saved successfully!'})
                                }
                                else { // file not saved. print error
                                    this.setState({ error: message2 })
                                }
                            })
                        }
                    })

                } else {

                    // file not saved. print error
                    this.setState({ error: message })
                }
            }
        )
    }

    copy(id) {
        if (!id) { id = this.state.selectedId }
        if (id == null) return

        this.setState({ copiedId: id })
    }

    paste() {
        let id = this.state.copiedId
        if (id == null) return
        alert('paste ' + id)
    }

    delete_clicked(id) {
        if (!id) {
            id = this.state.selectedId
        }
        if (id == null) return

        let file = this.findFile(id)
        if (file == null) return

        // prompt for delete
        this.setState({
            prompt: 'Delete ' + file.label + '?',
            promptInput: null,
            promptAction: 'Delete',
            promptActionCallback: () => {
                this.delete_continue((file.type === 'folder' ? 'f:' : 's:') + id, false)
            }
        })
    }

    delete_continue(id, overwrite) {
        // delete the file
        this.props.deleteFile(id, overwrite, (success, message, folder_recurse_prompt) => {
                if (success) {
                    // deleted ok
                    this.refresh()
                }
                else if (folder_recurse_prompt) {
                    // received folder-not-empty error. create prompt.
                    this.setState({
                        prompt: 'Folder is not empty. Delete anyway?',
                        promptAction: 'Delete all',
                        promptActionCallback: () => {
                            // prompt delete anyway was clicked, delete with overwrite
                            this.delete_continue(id, true)
                        },
                        promptInput: null
                    })
                }
                else {
                    // some other error
                    this.setState({ error: message })
                }
            }
        )
    }

    clearPrompt() {
        this.setState({
            prompt: null,
            promptInput: null,
            promptAction: null,
            promptActionCallback: null
        })
    }

    promptActionClicked() {
        this.clearPrompt()

        let inputValue =
            this.state.promptInput === 'text' ? this.state.promptInputValue : null

        if (this.state.promptActionCallback) {
            this.state.promptActionCallback(inputValue)
        }
    }

    rename(id) {
        if (!id) {
            id = this.state.selectedId
        }
        if (id == null) return

        let file = this.findFile(id)

        // create a prompt
        this.setState({
            prompt: 'Specify the new name:',
            promptAction: 'Rename',
            promptInput: 'text',
            promptInputValue: file.label,
            promptActionCallback: inputValue => {
                this.props.renameFile((file.type === 'folder' ? 'f:' : 's:') + id, inputValue, (success, message) => {
                    if (success) {
                        this.refresh()
                    } else {
                        this.setState({ error: message })
                    }
                })
            }
        })
    }

    newFolder(parent_id) {
        if (!parent_id) {
            parent_id = this.state.currentFolderId
        }

        // create a prompt
        this.setState({
            prompt: 'Specify new folder name:',
            promptAction: 'Create',
            promptInput: 'text',
            promptActionCallback: inputValue => {
                this.props.createFolder(
                    parent_id,
                    inputValue,
                    (success, message, newFolderId) => {
                        if (success) {
                            // todo: select the new folder
                            this.refresh()
                        } else {
                            this.setState({ error: message })
                        }
                    }
                )
            }
        })
    }

    refresh() {
        this.setState({ error: null, success: null })
        this.props.loadFiles()
    }

    close() {
        this.clearPrompt()
        this.setState({ error: null })
        this.props.closeRequested()
    }

    expandFiles(files) {
        let arr = []

        files.forEach(file => {
            console.log('processing file. file=', file)
            if (file.type !== 'folder') {
                arr.push(file)
                console.log('pushed it. arr=', arr)
            }
            if (file.files) {
                console.log('processing children for file', file)
                let subfiles = this.expandFiles(file.files)
                console.log('got subfiles', subfiles)
                arr = [...arr, ...subfiles]
            }
        })

        return arr
    }

    render() {

        let currentFolder = null

        let scenarios = this.props.scenarios || {}
        let files = scenarios.files

        // console.log('files', files);

        if (this.state.currentFolderId != null) {
            currentFolder = this.findFile(this.state.currentFolderId)
        }

        if (currentFolder != null) {
            files = currentFolder.files
        }

        if (scenarios.loadingFiles) {
            files = []
        }

        if (files == null) files = []

        let selectedPath = null
        //let selectedFile = null
        //let selectedFilename = null
        if (this.state.selectedId != null) {
            //selectedFile = this.findFile(this.state.selectedId)
            //selectedFilename = selectedFile != null ? selectedFile.label : ''
        }

        let hfolders = []
        let folderPointer = currentFolder
        while (folderPointer != null) {
            hfolders = [folderPointer.label, ...hfolders]
            folderPointer = this.findParentOf(folderPointer.id)
        }

        //selectedPath = (hfolders.length > 0 ? '/ ' : '') + hfolders.join(' / ') + ' / ' // + (selectedFile != null ? selectedFile.label : '')
        selectedPath = '/ ' + hfolders.join(' / ')

        // let mode = this.props.mode

        if (this.state.expandFolders) {
            files = this.expandFiles(files)
        }


        return (
            <div className="open-scenario-pane">
                <div className="title">
                   Scenario Manager
                </div>

                <div className="filename">
                    {/* <div className="save-scenario">
                        Save a Scenario
                    </div> */}
                    <div className="label">Scenario Name:</div>
                    {/* {mode === 'open' ? (
                        <div className="text">{this.state.filename}</div>
                    ) : null} */}
                    
                    <input
                        type="text"
                        id="filename"
                        placeholder=""
                        value={this.state.filename}
                        onChange={this.filenameChanged.bind(this)}
                    />                    
                </div>

                <div className="bottom">                                        
                    <button className="btn drk submit-btn" onClick={() => this.save()}>Save</button>
                    {/* <button className="submit-btn" onClick={() => this.close()}>Cancel</button> */}
                </div>

                <div className="actions">
                    {/* {mode === 'open' ? (
                        <div className={'button' + (this.state.selectedId == null ? ' disabled' : '')} onClick={() => this.open(null)}>
                            <div className="icon">
                                <i className="fal fa-cloud-download" />
                            </div>
                            <div className="text">Open</div>
                        </div>
                    ) : null}

                    {mode === 'save' ? (
                        <div className={'button' + (this.state.selectedId == null ? ' disabled' : '')} onClick={() => this.save(null)}>
                            <div className="icon">
                                <i className="fal fa-cloud-upload" />
                            </div>
                            <div className="text">Save</div>
                        </div>
                    ) : null} */}

                    <div className="button" onClick={() => this.newFolder()}>
                        <div className="icon">
                            <i className="fal fa-folder" />
                        </div>
                        <div className="text">New Folder</div>
                    </div>

                    {/* <div className={'button' + (this.state.expandFolders ? ' sel' : '')} onClick={() => this.toggleExpandFolders()}>
                        <div className="icon">
                            <i className="fal fa-plus-circle" />
                        </div>
                        <div className="text">Expand</div>
                    </div> */}

                    <div className="separator" />

                    {/* <div className={'button' + (this.state.selectedId == null ? ' disabled' : '')} onClick={() => this.copy()}>
                        <div className="icon">
                            <i className="fal fa-copy" />
                        </div>
                        <div className="text">Copy</div>
                    </div>

                    <div className={'button' + (this.state.copiedId == null ? ' disabled' : '')} onClick={() => this.paste()}>
                        <div className="icon">
                            <i className="fal fa-paste" />
                        </div>
                        <div className="text">Paste</div>
                    </div> */}

                    {/* <div className="separator" /> */}

                    <div className={'button' + (this.state.selectedId == null ? ' disabled' : '')} onClick={() => this.rename()}>
                        <div className="icon">
                            <i className="fal fa-pencil" />
                        </div>
                        <div className="text">Rename</div>
                    </div>

                    <div className="separator" />

                    <div className={'button' + (this.state.selectedId == null ? ' disabled' : '')} onClick={() => this.delete_clicked()}>
                        <div className="icon">
                            <i className="fal fa-times" />
                        </div>
                        <div className="text">Delete</div>
                    </div>

                    <div className="separator" />

                    <div className={'button'} onClick={() => this.refresh()}>
                        <div className="icon">
                            <i className="fal fa-redo" />
                        </div>
                        <div className="text">Refresh</div>
                    </div>
                </div>

                <div className="path">                   
                    <div className="buttons">
                        <span title="Back" className="disabled">
                            <i className="fal fa-arrow-left" />
                        </span>
                        <span title="Forward" className="disabled">
                            <i className="fal fa-arrow-right" />
                        </span>
                        <span title="Up" className={ this.state.currentFolderId == null ? 'disabled' : ''} onClick={() => this.up()}>
                            <i className="fal fa-arrow-up" />
                        </span>
                    </div>

                    <div className="path">
                        {this.state.expandFolders ? selectedPath + ' (expanded)' : selectedPath}
                    </div>

                    {/*
                    <div className='search'>
                    </div>
                    */}
                </div>

                <div className="main">
                    <div className='files'>

                    {this.state.prompt != null ? (
                        <div className="prompt-cover">
                            <div className="prompt">
                                <div className="message">{this.state.prompt}</div>
                                {this.state.promptInput === 'text' ? (
                                    <div className="textbox">
                                        <input
                                            type="text"
                                            id="prompt_textbox"
                                            value={this.state.promptInputValue}
                                            onChange={this.promptInputChanged.bind(this)}
                                        />
                                    </div>
                                ) : null}
                                <div className="buttons">
                                    <button className="btn" onClick={this.promptActionClicked.bind(this)}>
                                        {this.state.promptAction}
                                    </button>
                                    <button className="btn" onClick={this.clearPrompt.bind(this)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {this.props.working ? (
                        <div className="working">{this.props.working}</div>
                    ) : null}
                    {this.state.error ? (
                        <div className="error">{this.state.error}</div>
                    ) : null}
                    {this.state.success ? (
                        <div className="success">{this.state.success}</div>
                    ) : null}
                    <table className="file-table">
                        <thead>
                            <tr>
                                <th className="name" colSpan="2">
                                    Name
                                </th>
                                <th className='author'>Author</th>
                                <th className="modified">Modified</th>
                               {/* <th className="changes">Config Changed</th>
                                 <th className="metric" title="Consideration">
                                Consideration
                                </th>
                                <th className="metric" title="Share">
                                Share
                                </th>
                                <th className="metric" title="Volume">
                                Volume
                                </th>
                                <th className="metric" title="Revenue">
                                Revenue
                                </th>
                                <th className="metric" title="Profit">
                                Profit
                                </th>
                                <th className="metric" title="Awareness">
                                Awareness
                                </th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.loadingFiles ? (
                                <tr className="loading-note">
                                    <td colSpan="10" className="loading-note">
                                        loading<span className="blink_cursor">_</span>
                                    </td>
                                </tr>
                            ) : null}
                            {this.props.loadFilesError ? (
                                <tr className="error-note">
                                    <td colSpan="10" className="error-note">
                                        Error loading files
                                    </td>
                                </tr>
                            ) : null}
                            {!this.props.loadingFiles && !this.props.loadFilesError && files.length === 0 ? (
                                <tr className="empty-note">
                                    <td colSpan="10" className="empty-note">
                                        folder is empty
                                    </td>
                                </tr>
                            ) : null}

                            {files.map((scn, idx) => (
                                <tr key={idx} className={'' + scn.type + (scn.id === this.state.selectedId ? ' selected' : '')} onClick={() => this.clicked(scn.id)}>
                                    <td className="icon">
                                        {scn.type === 'folder' ? (
                                            <i className="fas fa-folder foldericon" />
                                        ) : null}
                                    </td>

                                    <td className="name">{scn.label}</td>

                                    <td className='author'>{scn.ownerName}</td>

                                    <td className="modified">
                                        {scn.modified ? dateFormat(scn.modified, 'mm/dd/yyyy hh:MM TT') : ''}
                                    </td>

                                    {/* <td className="changes">
                                        {scn.type !== 'folder' ? (
                                            <>
                                                { 
                                                    this.props.configModels.map(cfg => {
                                                        if (scn.areasChanged === cfg) {
                                                            return <span key={cfg} >{`${cfg}`}</span>
                                                        }
                                                    })
                                                }
                                            </>
                                            
                                        ) : null}
                                    </td> */}
                                    {/* <td className="metric">
                                        {s.type === 'folder'
                                        ? ''
                                        : formatValue(s.consideration, '0.0%')}
                                    </td>
                                    <td className="metric">
                                        {s.type === 'folder' ? '' : formatValue(s.share, '0.0%')}
                                    </td>
                                    <td className="metric">
                                        {s.type === 'folder'
                                        ? ''
                                        : formatValue(s.volume, '#,##0.0M')}
                                    </td>
                                    <td className="metric">
                                        {s.type === 'folder'
                                        ? ''
                                        : formatValue(s.revenue, '$#,##0.0M')}
                                    </td>
                                    <td className="metric">
                                        {s.type === 'folder'
                                        ? ''
                                        : formatValue(s.profit, '$#,##0.0M')}
                                    </td>
                                    <td className="metric">
                                        {s.type === 'folder'
                                        ? ''
                                        : formatValue(s.awareness, '0.0%')}
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                    <div className='open-btns'>
                       <button className="btn drk submit-btn" onClick={() => this.open()}>Open</button>       
                    </div>
                </div>

                
            </div>
        )
    }
}


const combinedActionCreators = {
    ...actionCreators,
    ...FordActionCreator
}

OpenSaveScenario = withRouter(
    connect(
        state => state.model,        
        dispatch => bindActionCreators(combinedActionCreators, dispatch)
    )(OpenSaveScenario)
)
