import * as React from 'react';
import './PromptBox.scss';

export class PromptBox extends React.Component{

    handleButtonClicked = (button) => {
        if( button.onClick ){
            button.onClick();
        }
        
    }

    render() {

        const buttons = this.props.buttons || [];

        return <div className="prompt-box">
            <div className="prompt">
                <div className="message">{this.props.prompt || 'Missing prompt'}</div>
                {
                    this.props?.buttons
                        ? <div className="buttons">
                            {buttons.map((button, idx) => 
                                <span key={idx} className="action-button inline-block" onClick={() => this.handleButtonClicked(button)}>
                                    {button.label}
                                </span>
                            )}
                        </div> : null
                }
                
            </div>
        </div>;

    }

}