import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { ContentLoader } from './ContentLoader';
import './Explore.scss';
import { getRepo } from '../widgets/_widgetRepo';
import { useSelector } from 'react-redux';
import { EditModeWrapper } from './EditModeWrapper';
import { CardLoading } from '../card/CardLoading';



export const Explore = (props) => {

    let { id, studyId } = useParams();
    const [layoutReady, setLayoutReady] = useState(false);

    const refLoader = useRef();
    // const [loadingElements, setLoadingElements] = useState([]);

    // const pushLoadingElement = (id) => {
    //     setLoadingElements([...loadingElements, id]);
    //     setLayoutReady(loadingElements?.length == 0);
    // }
    // const popLoadingElement = (id) => {
    //     setLoadingElements(loadingElements.filter(el => el != id));
    //     setLayoutReady(loadingElements?.length == 0);
    // }

    useEffect(() => { 

        refLoader.current.style.position = 'absolute' 
        refLoader.current.style.inset = 0 
        refLoader.current.style.top = '2px' 
        refLoader.current.style.backgroundColor = '#fff'
        refLoader.current.style.zIndex = 9999 
        refLoader.current.style.display = 'flex' 
        refLoader.current.style.justifyContent = 'center' 
        refLoader.current.style.alignItems = 'center' 

    },[])

    useEffect(() => {
        // setLoadingElements([]);
        setLayoutReady(false);
    }, [id])

    
    const auth = useSelector(state => state.auth);

    const [editMode, setEditMode] = useState(false);
    const edit = auth.user.isSuperAdmin ? { editMode, setEditMode } : null;
    
    const [restoreMode, setRestoreMode] = useState(false);
    const restore = auth.user.isSuperAdmin ? { restoreMode, setRestoreMode } : null;
    
    
    const footerId = (id || 'explore') === 'explore' ? 'footer' : 'footer_mini';

    if (layoutReady) {
        // setTimeout(() => {
            refLoader.current.style.display = 'none'
        // }, 5000)
        
    }

    return <>
        <CardLoading loading={true} reference={refLoader} />
        <ContentLoader 
            id={id || 'explore'}
            key={id || 'explore'}
            studyId={studyId}
            asPage={true}
            edit={edit}
            restore={restore}
            pageLevel={0}
            footer={footerId}
            setLayoutReady={setLayoutReady}
            layoutReady={layoutReady}
            // pushLoadingElement={pushLoadingElement}
            // popLoadingElement={popLoadingElement}
        />
     </>          
}


