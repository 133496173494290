import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/Study.ts";
import "./FilterChooser.scss";

export class FilterChooser extends React.Component {
	render() {
		let filter = this.props.selectedFilter || {
			label: "error: filter not set",
		};

		return (
			<div
				className={
					"filter-chooser" +
					(this.props.mini ? " mini" : "") +
					(this.props.disabled ? " disabled" : "")
				}
				style={this.props.style}>
				{true || this.props.hideWave ? null : (
					<div
						className={"wave-cell " + (this.props.disabled ? " disabled" : "")}>
						<i className="fal fa-calendar-alt glyph" />
						<span className="label">Q1 2019–Q1 2020</span>
					</div>
				)}
				<div
					className={
						"filter-cell text-truncate " +
						(this.props.disabled ? " disabled" : "") +
						(true || this.props.hideWave ? " nosep" : "")
					}
					onClick={() => {
						if (!this.props.disabled) {
							window.topBar.openMenu("filter", { datasetId: this.props.datasetId });
						}
					}}
					title={filter.label}>
					<i className="fal fa-filter glyph" />
					<span className="label">{filter.label}</span>
				</div>

				{false && filter.n ? (
					<div className="sample-cell">
						<i className="fal fa-male xglyph" />
						<span className="label">
							{filter.n !== null ? filter.n : "..."}
						</span>
					</div>
				) : null}
			</div>
		);
	}
}

FilterChooser = withRouter(
	connect(
		(state, ownProps) =>
			ownProps.selection
				? { selectedFilter: ownProps.selection }
				: state.filter,
		(dispatch) => bindActionCreators(actionCreators, dispatch),
	)(FilterChooser),
);
