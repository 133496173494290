import * as React from 'react';
import { useState, useEffect } from 'react';
import './Slideshow.scss';
import { EditModeWrapper } from './EditModeWrapper';
import { ContentLoader } from './ContentLoader';

export const Slideshow = (props) => {

    //**********TO USE: Need to add new Dcard for each label.length = icon.length *************/

    const item = props.item //dcard
    const [selIndex, setSelIndex] = useState(0)
	
    if (!item) return null;
    
    let button_icons = item.data?.buttonIcons || []
    let button_labels = item.data?.buttonLabels || [] 
    let dcards = item.contents
    const position = item.data?.position || 'top'
    const buttonStyle = item.data?.buttonStyle || 'blue-buttons'


    if (button_icons?.length == null ) { //|| (dcards?.length > button_icons?.length
        button_icons.push("fab fa-earlybirds")
    }
    
    if (button_labels?.length == null ) {// || (dcards?.length > button_labels?.length)
        button_labels.push("new card")
    }
   

    // const button_labels = []  ||  ? item.data?.buttonLabels.push("new card") : item.data?.buttonLabels;
    // const font_color = item.data?.fontColor;
    const background_color = item.data?.backgroundColor;	

    const style = {
        ...item.data?.style,
    }


    const classes = ['slideshow']
    if( !item.data?.wide ){
        classes.push('page-width');
        // classes.push('maxWidth')
	}

    const card = item.contents?.[selIndex];

	useEffect(() => {
		if (!card) {
			props.setLayoutReady?.(true);
		}
    }, [item])
    
    useEffect(() => {        
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        }
        
  }, [])

	const setLayoutReady = (val) => {
		//console.log('carousel layout ready');
		props.setLayoutReady?.(val);
    }

	
    const handleKeyPress = (event) => {
        
        // if (event.key === 'ArrowLeft') {
        //     setSelIndex(prevIndex => (prevIndex - 1 < 0 ? item.contents.length - 1 : prevIndex - 1))
        // } else if (event.key === 'ArrowRight') {
        //     setSelIndex(prevIndex => (prevIndex + 1 >= item.contents.length ? 0 : prevIndex + 1))
        // }
    }
    
	    
    const showCard = (card_index) => {
        setSelIndex(card_index)
    }


	return <EditModeWrapper className='has-children' item={item}
		edit={props.edit} restoredItem={props?.restoredItem}
		reloadItem={props.reloadItem} reloadParent={props.reloadParentItem} reloadParentItem={props.reloadParentItem}
		pageLevel={props.pageLevel}
	>
        <div className={classes.join(' ')} style={{display:'flex', flexDirection:'column',  ...style}}>
			
            {!item.contents ? 'add dcard(s) to slideshow' : null}
            {position == 'top' ?
                <div className={buttonStyle === 'tabs' ? 'tab-container' : 'bar margintop'} style={background_color ? { backgroundColor: background_color } : null}>
                    {
                        item.contents && item.contents.map((itm, index) => (
                        buttonStyle === 'tabs' ?
                        <div key={index} className={`tabs ${selIndex === index ? 'active' : ''}`}  onClick={() => showCard(index)}>
                            {button_labels[index]}
                        </div>
                        :
                        <div key={index} className={`${buttonStyle} ${selIndex === index ? 'active' : ''}`}  onClick={() => showCard(index)}>
                            <i className={`icon ${button_icons[index]}`} />
                            {button_labels[index]}
                        </div>
                        
                    ))}
                </div>
                :
                null
            }

			{card ? <ContentLoader id={card.id} pageLevel={props.pageLevel}
				restoredItem={card?.deleted} reloadParentItem={props.reloadItem}
				edit={props.edit} restore={props.restore} instanceContext={props.instanceContext}
				setLayoutReady={setLayoutReady} classes={['animate_card']}
            /> : null}        

            {position == 'bottom' ?
                <div className={buttonStyle === 'tabs' ? 'tab-container' : 'bar marginbottom'} style={background_color ? { backgroundColor: background_color } : null}>
                    {
                        item.contents && item.contents.map((itm, index) => (
                        buttonStyle === 'tabs' ?
                        <div key={index} className={`tabs ${selIndex === index ? 'active' : ''}`}  onClick={() => showCard(index)}>
                            {button_labels[index]}
                        </div>
                        :
                        <div key={index} className={`${buttonStyle} ${selIndex === index ? 'active' : ''}`}  onClick={() => showCard(index)}>
                            <i className={`icon ${button_icons[index]}`} />
                            {button_labels[index]}
                        </div>
                        
                    ))}
                </div>
                :
                null
            }
        </div>
    </EditModeWrapper>
}

