import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { computeFordModel } from '../../utils/forecastHelper'
import { getMeta } from '../../utils/metaUtils'
import { json } from 'd3'
import * as ReCharts from "recharts";


export function AvgSensitivitiesOutToFile({ payload }) {
    const { prodsToRun, modelConfig, modelInfo, attCondMetaTableName, attCondMetaLookupName, atts, filter, filetype, colors } = payload

    const study = useSelector(state => state.study)
    const auth = useSelector(state => state.auth)
    const [running, setRunning] = useState(false)
    const [buttonClicked, setButtonClicked] = useState(false)
    const [conditions, setConditions] = useState(null)
    const [attLookups, setAttLookups] = useState(null)
    const [progress, setProgress] = useState(null)
    const [useChart, setUseChart] = useState(false)
    const [averagedResults, setAveragedResults] = useState(null)


    let base_copy = JSON.parse(JSON.stringify(modelConfig?.play))
    const isPickup = modelInfo?.name.includes('truck') ? 'pickup' : 'suv'

    const getConditions = async (metakey) => {
        try {
            const res = await getMeta(study.uid, auth, metakey)
            return res || null
        } catch (error) {
            console.error('Error fetching meta:', error)
            return null
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const fetchedConditions = await getMeta(study.uid, auth, attCondMetaTableName)
            const fetchedAttLookups = await getMeta(study.uid, auth, attCondMetaLookupName)
            setConditions(fetchedConditions)
            setAttLookups(fetchedAttLookups)
        }

        fetchData()
    }, [attCondMetaTableName, attCondMetaLookupName, study.uid, auth])

      useEffect(() => {
          if (averagedResults) {
              console.log('inside avg results');
        console.log('averagedResults', averagedResults);
              
            if (filetype.toLowerCase() === 'excel') {
                saveToExcelFile(averagedResults, `average_sensitivities_${isPickup}.csv`)
            } else if (filetype.toLowerCase() === 'chart') {
                setUseChart(true)
            } else {
                saveToJsonFile(averagedResults, `average_sensitivities_${isPickup}.json`)
            }
        }
    }, [averagedResults, filetype])


    function applyConditions(curr_product, atts, rules, lookups) {
        let updatedAtts = atts.map(att => ({ ...att, readOnly: false }))

        rules?.forEach(rule => {
            rule.values.forEach(val => {
                if (curr_product[rule.attribute] === val.value) {
                    val.conditions.forEach(cond => {
                        const targetAttribute = updatedAtts.find(att => att.name === cond.attribute)
                        if (targetAttribute) {
                            targetAttribute.readOnly = cond.readOnly
                        }
                    })
                }
            })
        })

        function updateLevels(attributeName, levels) {
            return updatedAtts.map(att => {
                if (att.name === attributeName) {
                    const newLevels = levels.map((level, index) => ({
                        value: index + 1,
                        label: level
                    }))
                    return { ...att, levels: newLevels }
                }
                return att
            })
        }

        function applyLookups(currProduct, lookupData) {
            for (const key in lookupData) {
                const value = currProduct[key]
                if (lookupData[key][value]) {
                    const data = lookupData[key][value]
                    for (const attr in data) {
                        if (Array.isArray(data[attr])) {
                            updatedAtts = updateLevels(attr, data[attr])
                        } else if (typeof data[attr] === 'object') {
                            if (currProduct[attr] !== undefined) {
                                applyLookups(currProduct, data[attr])
                            }
                        }
                    }
                }
            }
        }

        if (lookups) {
            for (const lookupKey in lookups) {
                applyLookups(curr_product, { [lookupKey]: lookups[lookupKey] })
            }
        }

        return updatedAtts
    }

    const runForecasts = async (prod, prodatts) => {
        let counter = 1
        let resultsCollection = []

        prodatts = applyConditions(prod, prodatts, conditions.rules, attLookups)

        for (let att of prodatts) {
            if (att.type === 'discrete' && !att.readOnly) {
                let originalValue = prod[att.name]

                prod[att.name] = 1

                for (let level of att.levels) {
                    prod[att.name] = level.value

                    const result = await computeFordModel(study.uid, auth, {    
                        config: base_copy,
                        filter,
                        modelInfo,
                        brandvalues: [], 
                        playHash: modelConfig.playHash
                    })
                    let share = result?.[prod.uid]?.share
                    console.log('Forecast happened', counter)
                    counter++

                    resultsCollection.push({
                        id: prod.uid,
                        label: prod.label,
                        att: att.name,
                        levelLabel: level.label,
                        levelValue: level.value,
                        result: share,
                    })
                }

                prod[att.name] = originalValue
            }
        }
        return resultsCollection
    }

    const sortAndReproportion = (data) => {
        const minValue = data[0].result

        if (minValue < 0) {
            const reproportionedData = data.map(item => ({
                ...item,
                result: Math.abs(item.result - minValue)
            }))
            return reproportionedData
        } else {
            return data
        }
    }

    const processProduct = async (prod) => {
        const baseResult = await computeFordModel(study.uid, auth, {
            config: modelConfig?.base,
            filter,
            modelInfo,
            brandvalues: [],
            playHash: modelConfig.playHash
        })

        const baseVal = baseResult[prod.uid]?.share

        const forecastCollection = await runForecasts(prod, atts)

        const groupedByAttribute = forecastCollection.reduce((acc, item) => {
            acc[item.att] = acc[item.att] || []
            acc[item.att].push(item)
            return acc
        }, {})

        let data = {}
        Object.keys(groupedByAttribute).forEach((att, idx) => {
            const diff_from_base = groupedByAttribute[att].map(itm => ({
                ...itm,
                result: itm.result - baseVal,
                color: colors[idx % colors.length]?.bg || colors[idx % colors.length]
            }))?.sort((a, b) => a.result - b.result)

            const finalres = sortAndReproportion(diff_from_base)

            data[att] = finalres.sort((a, b) => a.levelValue - b.levelValue)
        })

        return data
    }

    const aggregateResults = (allResults) => {
        const aggregated = {}
        
        allResults.forEach(prodResults => {
            Object.keys(prodResults).forEach(att => {
                if (!aggregated[att]) {
                    aggregated[att] = {}
                }
                prodResults[att].forEach(item => {
                    if (!aggregated[att][item.levelValue]) {
                        aggregated[att][item.levelValue] = { ...item, count: 0, totalResult: 0 }
                    }
                    aggregated[att][item.levelValue].totalResult += item.result
                    aggregated[att][item.levelValue].count += 1
                })
            })
        })

        console.log('aggregated results step 1', aggregated)

        const averaged = {}
        Object.keys(aggregated).forEach(att => {
            averaged[att] = Object.values(aggregated[att]).map(item => ({
                id: item.id,
                label: item.label,
                att: item.att,
                levelLabel: item.levelLabel,
                levelValue: item.levelValue,
                result: item.totalResult / item.count,
                color: item.color
            }))
        })

        return averaged
    }

    const saveToJsonFile = (data, filename) => {
        const jsonStr = JSON.stringify(data, null, 2)
        const blob = new Blob([jsonStr], { type: 'application/json' })
        const link = document.createElement('a')
        link.download = filename
        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const saveToExcelFile = (d, filename) => {
        const headers = ["Label","Attribute", "Level", "Share", "Color"]
        const rows = []

        Object.keys(d).forEach(key => {
            d[key].forEach(item => {
                rows.push([ 
                    item.levelLabel.replace(/,/g, ""),
                    item.att,
                    item.levelValue,
                    item.result,
                    item.color
                ])
            })
        })

        const csvContent = [
            headers.join(","),
            ...rows.map(row => row.join(","))
        ].join("\n")

        const blob = new Blob([csvContent], { type: 'text/csvcharset=utf-8' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const runForecaster = async () => {
        setButtonClicked(true)
        setRunning(true)

        let allResults = []
        
        let prodCounter = 0
        for (const p of prodsToRun) {

            let prod = base_copy.find(f => f.uid === p.uid)
            const prodResults = await processProduct(prod)
            setProgress(Math.round((prodCounter++ / base_copy.length) * 100),0)
            
            allResults.push(prodResults)
            console.log('allresults', allResults);
        }

        if (filetype.toLowerCase() === 'chart') {
            setAveragedResults(allResults[0]) //single product
        }
        else {
             setAveragedResults(aggregateResults(allResults))
        }

         
        // console.log('avg', avg);
        // if (avg) {
        //     console.log('here');        
        //     setAveragedResults(avg)            
        // }

        // console.log('averagedResults', averagedResults);
        // console.log('filetype', filetype);

        // if (filetype.toLowerCase() === 'excel') {
        //     saveToExcelFile(averagedResults, 'average_sensitivities.csv')
        // }
        // else if (averagedResults && filetype.toLowerCase() === 'chart') {
        //     console.log('here usechart');
        //     setUseChart(true)
        // }
        // else {
        //     saveToJsonFile(averagedResults, 'average_sensitivities.json')
        // }
        setRunning(false)
    }



    const handleClick = async () => {
        await runForecaster()
    }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection:'column',  alignItems:'center', marginLeft: 15, marginRight:15}}>
                {
                    filetype.toLowerCase() !== 'chart' ?
                        <div style={{ fontSize: 12, color: '#808080', marginTop: 40, marginBottom: 15 }}>
                            Generates Average Sensitivities data in an Excel or Json file
                        </div>
                        :
                        <div style={{ fontSize: 12, color: '#808080', marginTop: 40, marginBottom: 15 }}>
                            Click to Generate Sensitivities for current product
                        </div>
                }
                <div style={{ marginBottom: 25}}>
                    <button className='btn border' onClick={handleClick}>
                        {filetype.toLowerCase() !== 'chart' ? 'Run Avg Sensitivities' : 'Run Sensitivities'}
                    </button>
                </div>
                {
                    running ?
                        <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                            <div style={{marginTop:30}}>
                                <i className='fad fa-truck-monster fa-spin' style={{fontSize:28, color:'#0d47a1'}}></i>
                            </div>
                            { filetype.toLowerCase() !== 'chart' ? <div>
                                    <span style={{fontSize:18}}>{progress} %</span>
                                </div>
                                : null    
                            }
                        </div>
                    : null
                }
            </div>
                <div style={{display:'flex', flexDirection: 'column'}}>                 
                    {
                        useChart && averagedResults ?
                        Object.keys(averagedResults).map(dKey => (                            
                                <div key={dKey} style={{ marginBottom: 20 }}>
                                    <div style={{marginLeft:50, marginBottom: 10, fontSize:16, fontWeight:'500', textTransform:'uppercase' }}>
                                        {atts.find(f => f.name === averagedResults[dKey][0].att)?.label} 
                                    </div>                
                                    <ReCharts.ResponsiveContainer width="100%" height={averagedResults[dKey].length * 40}>
                                        <ReCharts.BarChart data={averagedResults[dKey]} layout="vertical" margin={{ left: 20, right: 40, bottom: 20, top: 10 }} barSize={30} barGap={5} barCategoryGap={10}>
                                            <ReCharts.XAxis dataKey="result" type="number" tickFormatter={val => `${(val * 100).toFixed(2)}%`} domain={[0, 'auto']} hide={true} />
                                            <ReCharts.YAxis dataKey="levelLabel" type="category" width={170} />
                                            <ReCharts.Tooltip formatter={(value) => `${(value * 100).toFixed(2)}%`} />
                                            <ReCharts.Bar dataKey="result" >
                                                {
                                                    averagedResults[dKey].map((d, i) => (
                                                        <ReCharts.Cell key={i} fill={d.highlight ? d.highlight : d.color} />
                                                ))}
                                                <ReCharts.LabelList	dataKey="result" position="right" formatter={(val) => {  return val ? `${(val * 100).toFixed(2)}%` : "0%" }}/>
                                            </ReCharts.Bar>
                                        </ReCharts.BarChart>
                                    </ReCharts.ResponsiveContainer>
                                </div>
                        )) 
                        : null    
                    }
                </div>
            
        </div>
    )    
}

               // buttonClicked && <div style={{marginTop:30, fontSize:18, color:'#006400', fontWeight:700, letterSpacing:3, textTransform:'uppercase' }}>Finished!</div>
