import React, { useState } from 'react';


export default function Info(props) {

    const card = {
        position: 'relative',
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }

    const cardAbsolute = {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
       
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }

    const header = {
        paddingTop: 30,
        paddingBottom: 30,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: '1px solid #ddd',
        backgroundColor: "#eee"
    }

    const body = {
        fontSize: 12,
        overflowY: 'auto'
    }

    const closeButton = {
        position: 'absolute',
        top: 1,
        right: 1,
        height: 30,
        width: 30
    }


    return (
        <div style={props?.useAbsolute ? cardAbsolute : card}>
            <button className="btn" style={closeButton} role='button' aria-label='card info -details' onClick={() => props.changeInfo(false)}>
                <i className='fal fa-times'/>
            </button>
            <div style={header}>
                <h2 style={{fontWeight: 700, fontSize: 15, marginBottom:3}}><i className="fas fa-info-circle"></i> Card Information</h2>
            </div>
            <div style={body} dangerouslySetInnerHTML={{ __html: props.htmlText }}></div>
        </div>
    )
    
}