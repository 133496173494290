import * as signalR from '@microsoft/signalr'
import "@babel/polyfill"
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { matchPath, useHistory } from 'react-router-dom'
import "./App.scss"
import { SignIn } from './components/account/SignIn'
import { Layout } from './components/Layout'
import { actionCreators } from './store/Auth.ts'
import { ThemeContext, themes } from './theme'
import { gaUserSet, gaInit, gaPageView } from './utils/react-ga'
import { MsalProvider } from '@azure/msal-react'
import { UrlString } from '@azure/msal-common'
import { msalInstance } from './globals'

const INACTIVITY_TIME_LIMIT = 59 * 60 * 1000 // 59 minutes 

export default function App() {
    const [isTimedOut, setIsTimedOut] = useState(false)
    const [theme, setTheme] = useState(themes.default)
    const [themeIndex, setThemeIndex] = useState(0)

    const history = useHistory()
    const dispatch = useDispatch()

    const timeoutRef = useRef(null)
    const auth = useSelector(state => state.auth)
    let authenticated = auth && auth?.token
    const user = useSelector(state => state?.auth?.user)
    const location = useSelector(state => state?.router?.location) // Assuming you're using connected-react-router
    
    window.app = <App />

    useEffect(() => {
        const resetTimer = () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
            timeoutRef.current = setTimeout(() => {
                setIsTimedOut(true)
            }, INACTIVITY_TIME_LIMIT)
        }

        resetTimer()

        window.addEventListener('load', resetTimer)
        document.addEventListener('mousemove', resetTimer)
        document.addEventListener('keypress', resetTimer)
        document.addEventListener('click', resetTimer)
        document.addEventListener('scroll', resetTimer)

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
            window.removeEventListener('load', resetTimer)
            document.removeEventListener('mousemove', resetTimer)
            document.removeEventListener('keypress', resetTimer)
            document.removeEventListener('click', resetTimer)
            document.removeEventListener('scroll', resetTimer)
        }
    }, [])


    const getUrlRoot = () => {
        const match = matchPath(history.location.pathname, {
            path: '/:a',
            strict: false
        })
        return match !== null ? match.params.a : null
    }


    // User logged out or timed out
    useEffect(() => {
        if ((!authenticated && getUrlRoot() === 'sign-out') || isTimedOut) {
            console.log('signing out!!')
            localStorage.removeItem('auth')
            localStorage.clear()
            dispatch(actionCreators.logoutUser())
            authenticated = false
            gaInit()

            setIsTimedOut(false)

            return history.push('/') //<Redirect to={'/'} push />
        }

    }, [authenticated, isTimedOut])

    // Successfully logged in
    useEffect(() => {
        if (authenticated && user?.id) {
            gaUserSet(user.id)
        }
        if (location?.pathname) {
            gaPageView(location.pathname)
        }
    }, [authenticated, user, location])

    const toggleTheme = () => {
        let keys = Object.keys(themes)
        let nextThemeIndex = (themeIndex + 1) % keys.length
        setTheme(themes[keys[nextThemeIndex]])
        setThemeIndex(nextThemeIndex)
    }

    // Function to check if the app is running inside an authentication popup
    const isAuthPopup = () => {
        const hash = window.location.hash
        return UrlString.hashContainsKnownProperties(hash)
    }

    // If there is a hash in the URL, show a loading message
    if (window.location.hash !== '') {
        console.log('hash found: ' + window.location.hash)

        return <div>Loading...</div>
    }

    // Check if the current window is the authentication popup
    if (isAuthPopup()) {
        return (
            <div>
                <h1>Authenticating...</h1>
            </div>
        )
    }

    // Render the app normally if not in an authentication popup
    return (
        <MsalProvider instance={msalInstance}>
            {authenticated ? <Layout /> : <SignIn />}
        </MsalProvider>
    )
}


