import * as globals from "../globals";
import { guid } from "../utils/guid";
import { mapObject } from "../utils/mapObject";
import {
	STUDY_RESET,
	CONFIG_CLONE_PRODUCT,
	CONFIG_DELETE_PRODUCT,
	CONFIG_RESET,
	CONFIG_RESET_BRAND_EQUITY_VALUE,
	//CONFIG_RESET_MESSAGES,
	//CONFIG_RESET_PERCEPTIONS,
	CONFIG_RESET_PRODUCT,
	CONFIG_SET_ATT_LOCKED,
	CONFIG_SET_ATT_VALUE,
	CONFIG_SET_BASE,
	CONFIG_SET_BRAND_EQUITY_VALUE,
	CONFIG_SET_PRODUCT_LABEL,
	STUDY_PIN_BRAND,
	CONFIG_SET_LEVEL_ALLOWED,
	CONFIG_SET_MESSAGE_ALLOWED,
	CONFIG_SET_MISC_VALUE,
	CONFIG_SET_OPTIMIZATION_METRIC,
	CONFIG_TOGGLE_OPTIMIZATION_SELECTION,
	CONFIG_ADD_OPTIMIZATION_SELECTION,
	CONFIG_REMOVE_OPTIMIZATION_SELECTION,
	CONFIG_SET_PRODUCT_AVAILABLE,
	CONFIG_SET_PRODUCT_LOCKED,
	CONFIG_SET_PRODUCT_PINNED,
	CONFIG_SET_SEGMENTATION,
	CONFIG_SET_VIEW,
	DCM_UPDATE_CONFIG,
	DCM_NEED_UPDATE,
	NOTIF_ADD,
	NOTIF_MARK_COMPLETE,
	// @ts-ignore
} from "./action-types";
import * as apiService from "../utils/apiService";
import { OPTIM_SET_RUNLIST_DATA, OPTIM_SET_RUNLIST_LOADING } from "./action-types";


const isNull = (val: any, fallback: any) => (val === undefined || val === null) ? fallback : val;
	
const initialState: ConfigState = {
	assumptionsId: null,
	baseCaseId: null,
	productSlots: null,
	brandEquities: null,
	type: "multi",
	etag: null,

	productDefs: null, // move me
	brandEquitiesDef: null, // move me
	optim: null, // move me
};

export interface ConfigState {
	/** points to the assumptions set to use */
	assumptionsId: string;

	/** not sure how this is used yet */
	baseCaseId: string;

	/** multi or single. multi holds both play and base */
	type: string;

	productSlots: ProductSlot[];

	brandEquities: any;

	/** guid that changes when config changes. Ideally this would be a hash, but ill-performance is preventing that for now. */
	etag: string;

	// move these

	/** move me to study */
	productDefs: any;

	/** move me to study */
	brandEquitiesDef: any;

	/** move me to whatif or somewhere else */
	optim: any;
}

export interface ProductSlot {
	uid: string;

	cloneOf?: string;

	label: string;

	image?: string;

	play: any;

	base: any;
}

const timeout = (ms: any) => new Promise((res: any) => setTimeout(res, ms));

export const actionCreators = {

	forecast: (configId: string) => async (dispatch: any, getState: any) => {
		dispatch({
			type: DCM_UPDATE_CONFIG,
			id: configId,
			productSlots: getState()?.study?.config?.productSlots
		});
	},

	setAvailable: (productUid: any, available: any) => async (
		dispatch: any,
		getState: any,
	) => {
		dispatch({
			type: CONFIG_SET_PRODUCT_AVAILABLE,
			productUid,
			value: available,
		});
		dispatch({ type: DCM_NEED_UPDATE, id: 'play' });

		// dispatch({
		// 	type: DCM_UPDATE_CONFIG, id: 'play', productUid,
		// 	productSlots: getState()?.study?.config?.productSlots
		// });
	},

	cloneProduct: (productUid: any) => async (dispatch: any, getState: any) => {
		dispatch({
			type: CONFIG_CLONE_PRODUCT,
			productUid,
		})
		dispatch({ type: DCM_NEED_UPDATE, id: 'play' });
		// dispatch({
		// 	type: DCM_UPDATE_CONFIG, id: 'play',
		// 	productSlots: getState()?.study?.config?.productSlots
		// });
	},

	deleteProduct: (productUid: any) => async (dispatch: any, getState: any) => {
		dispatch({
			type: CONFIG_DELETE_PRODUCT,
			productUid,
		})
		dispatch({ type: DCM_NEED_UPDATE, id: 'play' });
		// dispatch({
		// 	type: DCM_UPDATE_CONFIG, id: 'play',
		// 	productSlots: getState()?.study?.config?.productSlots
		// });
	},

	setProductPinned: (productUid: any, pinned = true) => async (
		dispatch: any,
		getState: any,
	) =>
		dispatch({
			type: CONFIG_SET_PRODUCT_PINNED,
			productUid,
			value: pinned,
		}),

	setProductLabel: (productUid: any, label: any) => async (
		dispatch: any,
		getState: any,
	) => {
		dispatch({
			type: CONFIG_SET_PRODUCT_LABEL,
			productUid,
			label,
		});
		// dispatch({
		// 	type: DCM_UPDATE_CONFIG, id: 'play', productUid,
		// 	productSlots: getState()?.study?.config?.productSlots
		// });
	},

	// todo: move setBrandPinned out of config reducer. doesn't belong here
	setBrandPinned: (brandUid: any, pinned: any = true) => async (
		dispatch: any,
		getState: any,
	) =>
		dispatch({
			type: STUDY_PIN_BRAND,
			brandUid,
			value: pinned,
		}),

	// this was experimental, to lock a product slot (i think), and is not being used anymore.
	setLocked: (productUid: any, locked: any) => async (
		dispatch: any,
		getState: any,
	) =>
		dispatch({
			type: CONFIG_SET_PRODUCT_LOCKED,
			productUid,
			value: locked,
		}),

	setPlay: (productUid: any, attName: any, value: any) => async (
		dispatch: any,
		getState: any,
	) => {
		dispatch({
			type: CONFIG_SET_ATT_VALUE,
			productUid,
			attName,
			value,
		});
		dispatch({ type: DCM_NEED_UPDATE, id: 'play' });
		// dispatch({
		// 	type: DCM_UPDATE_CONFIG, id: 'play', productUid,
		// 	productSlots: getState()?.study?.config?.productSlots
		// });
	},

	setBase: () => (dispatch: any) => {
		dispatch({ type: CONFIG_SET_BASE })
		//dispatch({ type: DCM_UPDATE_CONFIG, id: 'base' });
	},

	resetPlay: (productUid: any) => (dispatch: any, getState: any) => {
		dispatch({ type: CONFIG_RESET_PRODUCT, productUid })
		dispatch({ type: DCM_NEED_UPDATE, id: 'play' });
		// dispatch({
		// 	type: DCM_UPDATE_CONFIG, id: 'play', productUid,
		// 	productSlots: getState()?.study?.config?.productSlots
		// });
	},

	// resetBrandEquities: item => (dispatch) =>
	//     dispatch({ type: CONFIG_RESET_BRAND_EQUITIES, item }),

	resetConfig: () => (dispatch: any, getState: any) => {
		dispatch({ type: CONFIG_RESET })
		dispatch({ type: DCM_NEED_UPDATE, id: 'play' });
		// dispatch({
		// 	type: DCM_UPDATE_CONFIG, id: 'play',
		// 	productSlots: getState()?.study?.config?.productSlots
		// });
	},

	setView: (view: any) => (dispatch: any) =>
		dispatch({ type: CONFIG_SET_VIEW, value: view }),

	set: (key: any, value: any) => (dispatch: any, getState: any) => {
		dispatch({ type: CONFIG_SET_MISC_VALUE, key, value })
		dispatch({ type: DCM_NEED_UPDATE, id: 'play' });
		// dispatch({
		// 	type: DCM_UPDATE_CONFIG, id: 'play',
		// 	productSlots: getState()?.study?.config?.productSlots
		// });
	},

	// setMessageValue: (item, name, value) => (dispatch) =>
	//     dispatch({ type: CONFIG_SET_MESSAGE_VALUE, item, name, value }),

	// setPerceptionValue: (item, name, value) => (dispatch) =>
	//     dispatch({ type: CONFIG_SET_PERCEPTION_VALUE, item, name, value }),

	setBrandEquityValue: (item: any, name: any, value: any) => (dispatch: any) => {
		dispatch({ type: CONFIG_SET_BRAND_EQUITY_VALUE, item, name, value })
		dispatch({ type: DCM_NEED_UPDATE, id: 'play' });
		//dispatch({ type: DCM_UPDATE_CONFIG, id: 'play', brandEquityItem: item, brandEquityName: name });
	},

	resetBrandEquityValue: (item: any, name: any, value: any) => (
		dispatch: any,
	) => {
		dispatch({ type: CONFIG_RESET_BRAND_EQUITY_VALUE, item, name, value })
		dispatch({ type: DCM_NEED_UPDATE, id: 'play' });
		//dispatch({ type: DCM_UPDATE_CONFIG, id: 'play', brandEquityItem: item, brandEquityName: name });
	},

	// for optimizer. if att is locked, optimizer will leave it set at attValue
	setAttLock: (slotUid: any, attName: any, locked: any) => async (
		dispatch: any,
	) =>
		dispatch({
			type: CONFIG_SET_ATT_LOCKED,
			slotUid,
			attName,
			locked,
		}),

	// for optimizer. if level is allowed, optimizer will consider it as possible value
	setLevelAllow: (
		slotUid: any,
		attName: any,
		levelValue: any,
		allowed: any,
	) => async (dispatch: any) =>
		dispatch({
			type: CONFIG_SET_LEVEL_ALLOWED,
			slotUid,
			attName,
			levelValue,
			allowed,
		}),

	setMessageAllow: (key: any, name: any, allowed: any) => async (
		dispatch: any,
	) =>
		dispatch({
			type: CONFIG_SET_MESSAGE_ALLOWED,
			key,
			name,
			allowed,
		}),

	setOptimizationMetric: (metric: any) => (dispatch: any) =>
		dispatch({ type: CONFIG_SET_OPTIMIZATION_METRIC, value: metric }),

	toggleOptimizationSelection: (selection: any, callback: any) => (
		dispatch: any,
		getState: any,
	) => {
		dispatch({ type: CONFIG_TOGGLE_OPTIMIZATION_SELECTION, selection });
		if (callback) {
			const state = getState();
			const newSelections = state.study.config.optim.selections;
			callback(newSelections);
		}
	},

	addOptimizationSelection: (selection: any, callback: any) => (
		dispatch: any,
		getState: any,
	) => {
		dispatch({ type: CONFIG_ADD_OPTIMIZATION_SELECTION, selection });
		if (callback) {
			const state = getState();
			const newSelections = state.study.config.optim.selections;
			callback(newSelections);
		}
	},

	removeOptimizationSelection: (selection: any, callback: any) => (
		dispatch: any,
		getState: any,
	) => {
		dispatch({ type: CONFIG_REMOVE_OPTIMIZATION_SELECTION, selection });
		if (callback) {
			const state = getState();
			const newSelections = state.study.config.optim.selections;
			callback(newSelections);
		}
	},

	setSegmentation: (id: any) => (
		dispatch: any, // still using this?
	) => dispatch({ type: CONFIG_SET_SEGMENTATION, value: id }),

	runOptimizer: () => async (dispatch: any, getState: any) => {
		var state: any = getState();

		// create a UID for this optimization run
		//var runId = 'testrun1';// guid();
		var runId: any = guid();

		// send a notification
		// dispatch({
		// 	type: NOTIF_ADD,
		// 	//value: `Optimization run ${runId} started`,
		// 	value: `Optimization is running (${runId})`,
		// 	running: true,
		// 	runId,
		// });

		//await timeout(15000)

		const optim: any = state.study.config.optim || {};
		const metric: any = optim.metric || globals.default_optimizer_metric;
		const filterobj: any =
			state.filter.selectedFilter || globals.getDefaultFilter(state?.study);
		//const filter = (state.study.selectedFilter || {}).syntax || '1==1';
		//const filterLabel = (state.study.selectedFilter ||
		const config: any = state.study.config;

		const targetProducts: any = (optim.selections || []).map(
			//(sel: any) => sel.uid,
			(sel: any) => ({ uid: sel.uid, label: sel.label }),
		);
		console.log('targetProducts', targetProducts);

		// construct a label
		let label = "Maximize " + globals.metrics.find(m => m.key == metric)?.label + " of ";
		if (targetProducts.length == 1) {
			label += `'${targetProducts[0]?.label}'`;
		}
		else {
			label += `'${targetProducts[0]?.label}'`;
			for (let i = 1; i < targetProducts.length-1; i++){
				label += `, '${targetProducts[i]?.label}'`;
			}
			label += ` and '${targetProducts[targetProducts.length - 1]?.label}'`;
		}
		label += " for '" + filterobj?.label + "'";
		label += " started " + new Date().toLocaleString();

		var query: any = {
			studyId: state.study.uid,
			uid: runId,
			label,
			goal: {
				targetMeasure: metric,
				targetFilter: filterobj.syntax,
				targetFilterLabel: filterobj.label,
				targetProducts: targetProducts,
			},
			config: {
				products: state.study.config.productSlots.map((slot: any) => {
					const def = state.study.config.productDefs[slot.def || "default"];
					let p: any = {
						uid: slot.uid,
						label: slot.label, // optimizer seems to need this but i want to delete
						available: slot.play.available,
					};
					const optim = slot.optim || {};
					def.atts.forEach((att: any) => {

						// NOTE: it's possible that the start value doesn't exist in allowed values. figure out where and how that should be handled!!

						const notTargetSlot = !targetProducts.find((p1: any) => p1.uid == slot.uid);
						const isReadOnly = att.readOnly;
						const isLocked = optim.locks?.includes(att.name);

						// multiselect is a bit different than discrete or contin
						if (att.type == "multiselect") {

							// loop through options
							att.options.forEach((option: any) => {
								
								const currentVal = slot.play[option.name] || att.defaultValue;

								if (notTargetSlot || isReadOnly || isLocked) {
									p[option.name] = currentVal;
								}
								else {
									p[option.name] = {
										startValue: currentVal,
										allowedLevels: [isNull(att.yesValue,1), isNull(att.noValue,0)]
									}
								}

							})

						}
						else {
							// discrete or contin

							let currentVal = slot.play[att.name] || att.defaultValue;

							if (notTargetSlot || isReadOnly || isLocked) {
								p[att.name] = currentVal;
							}
							else {
								
								if (att.type == "discrete") {
									const disallowedLevels = (optim.disallow ? optim.disallow[att.name] : null) || [];
									p[att.name] = {
										startValue: currentVal,
										allowedLevels: att.levels.filter((level: any) => !disallowedLevels.includes(level.value)).map((level: any) => level.value)
									};
								}
								else if (att.type == "contin") {
									p[att.name] = {
										startValue: currentVal,
										allowedRange: [att.min, att.max]
										// todo: step???
									};
								}
								
							}
						}

						
					});
					return p;
				}),
				equity_drivers: {
					level: state.study.config.brandEquitiesDef.level,
					//data: config.brandEquities
					data: Object.keys(config.brandEquities).reduce(
						(accum: any, key: any) => {
							// convert key to level-less format, e.g. 'model:5' to '5'
							const newKey: any = key.replace(
								state.study.config.brandEquitiesDef.level + ":",
								"",
							);
							accum[newKey] = Object.keys(config.brandEquities[key]).reduce(
								(accum2: any, key2: any) => {
									accum2[key2] = config.brandEquities[key][key2].play;
									return accum2;
								},
								{},
							);
							return accum;
						},
						{},
					),
				},
				brand_equities_to_optimize: {
					level: state.study.config.brandEquitiesDef.level,
					perceptions: Object.keys(config.brandEquities).reduce(
						(accum: any, key: any) => {
							// convert key to level-less format, e.g. 'model:5' to '5'
							const newKey: any = key.replace(
								state.study.config.brandEquitiesDef.level + ":",
								"",
							);
							const arr: any = Object.keys(config.brandEquities[key]).reduce(
								(accum2, key2) => {
									if (config.brandEquities[key][key2].allowed) {
										accum2.push(key2);
									}
									return accum2;
								},
								[],
							);
							if (arr.length > 0) accum[newKey] = arr;
							return accum;
						},
						{},
					),
				},
			},
			assumptions: {
				// this attaches on the backend server i think. don't delete without adjusting backend, because backend assumes it exists.
			},
		};
		console.log("optimizer query", query);
		
		

		const url: string = `${globals.apiRoot}/optimize/start`;
		try {
			const response: any = await apiService.aPost(state.auth, url, query);

			if (response.status === 202) {
				const value: any = await response.json();
				console.log("optimization accepted", value);

				// todo: reload the optinfo stuff
				// load the optruns
				{
					const f = async () => {

						dispatch({ type: OPTIM_SET_RUNLIST_LOADING, value: true });

						//setLoadingItems(true);
						try {
							const response = await apiService.aGet(state.auth, `${globals.apiRoot}/study/${state.study.uid}/optruns`);
							if( response.ok ){
								const runs = await response.json();
								//setItems(runs);
								//console.log('runs', runs);

								dispatch({ type: OPTIM_SET_RUNLIST_DATA, listData: runs });
								dispatch({ type: OPTIM_SET_RUNLIST_LOADING, value: false });
							}
						}
						catch (err) {
							console.error(err);
						}
						//setLoadingItems(false);
						
					}
					f();

				}







				// if( value.status === 'error' ){

				//     dispatch({
				//         type: NOTIF_MARK_COMPLETE, // 'COMPLETE_RUNNING_NOTIFICATION',
				//         runId,
				//         success: false,
				//         message: 'Optimization run failed. ' + value.message
				//     });

				// }
				// else{

				//     dispatch({
				//         type: NOTIF_MARK_COMPLETE, // 'COMPLETE_RUNNING_NOTIFICATION',
				//         runId,
				//         success: true,
				//         data: value,
				//         message: 'Optimization run completed. Click to view results',
				//         showOptButtons: true
				//     });

				// }
			} else {
				console.error("Optimization failed to start.", response.statusText);
				// dispatch({
				// 	type: NOTIF_MARK_COMPLETE, // 'COMPLETE_RUNNING_NOTIFICATION',
				// 	runId,
				// 	success: false,
				// 	message: "Optimization failed to start. " + response.statusText,
				// });
			}
		} catch (err) {
			console.error("Optimizer error", err);

			// dispatch({
			// 	type: NOTIF_MARK_COMPLETE, //'COMPLETE_RUNNING_NOTIFICATION',
			// 	runId,
			// 	success: false,
			// 	message: "Optimization run failed: " + JSON.stringify(err),
			// });
		}
	},
};

export const dirtyCheckSlot = (slot: any, pdef: any): boolean => {
	if (slot.play.available != slot.base.available) return true; // eslint-disable-line eqeqeq
	for (let i in pdef.atts) {
		let att = pdef.atts[i];
		// this is hacky replace "1" on the next line with the attribute's default value
		if ((slot.play[att.name] || 1) !== (slot.base[att.name] || 1)) {
			return true;
		}
	}
	return false;
};

const reHash = (config: ConfigState): ConfigState => {
	let obj: any = {
		...config,
		etag: undefined,
	};

	//let etag = hash(obj); // taking too long! could minimize the data being hashed
	let etag = guid(); // sorry redux rules, hashing is taking too long; fix this later;
	// breaking the rules of redux because a reducer should have a predictable outcome. this random guid is not predictable
	// I don't think it matters though, due to how I'm using the etag
	// maybe i should just think of a better way to do this, avoiding the etag
	// i'm not just using object refs, because i want to compare config against cached results.
	// maybe there's a way to do this using object refs

	return {
		...config,
		etag,
	};
};

const toArray = (obj: any) => {
	return Object.entries(obj).map((entry) => ({
		key: entry[0],
		value: entry[1],
	}));
};

const toNestedArray = (obj: any) => {
	return Object.entries(obj).map((entry) => ({
		key: entry[0],
		value: toArray(entry[1]),
	}));
};

export const reducer = (state: ConfigState, action: any): ConfigState => {
	state = state || initialState;

	switch (action.type) {
		case STUDY_RESET:
			return initialState;

		case CONFIG_CLONE_PRODUCT: {
			const slot = state.productSlots?.find(
				(slot: any) => slot.uid === action.productUid,
			);
			if (!slot) return state;
			const newSlot: ProductSlot = {
				...slot,
				uid: guid(),
				label: slot.label + " clone",
				cloneOf: slot.uid,
				play: { ...slot.play },
				base: {
					available: 0,
				},
			};
			return reHash({
				...state,
				productSlots: [...state.productSlots, newSlot],
			});
		}

		case CONFIG_DELETE_PRODUCT: {
			return reHash({
				...state,
				productSlots: state.productSlots.filter(
					(slot: any) => slot.uid !== action.productUid,
				),
			});
		}

		case CONFIG_SET_PRODUCT_AVAILABLE:
			return reHash({
				...state,
				productSlots: state.productSlots.map((slot: any) =>
					slot.uid !== action.productUid
						? slot
						: {
								...slot,
								play: {
									...slot.play,
									available: action.value ? 1 : 0,
									dirty: dirtyCheckSlot(
										{
											...slot,
											play: { ...slot.play, available: action.value ? 1 : 0 },
										},
										state.productDefs.default,
									),
								},
						  },
				),
			});

		case CONFIG_SET_PRODUCT_PINNED:
			return {
				...state,
				productSlots: state.productSlots.map((slot: any) =>
					slot.uid !== action.productUid
						? slot
						: { ...slot, pinned: action.value },
				),
			};

		// // broken! this should maybe belong to study?
		// case CONFIG_SET_BRAND_PINNED:
		//     return {
		//         ...state,
		//         brands: state.brands.map(brand => brand.uid === action.brandUid ?
		//             { ...brand, pinned: action.value }
		//             : brand
		//         )
		//     };

		case CONFIG_SET_PRODUCT_LOCKED:
			return {
				...state,
				productSlots: state.productSlots.map((product: any) =>
					product.uid !== action.productUid
						? product
						: {
								...product,
								play: {
									...product.play,
									locked: action.value ? true : false,
								},
						  },
				),
			};

		case CONFIG_SET_ATT_VALUE:
			return reHash({
				...state,
				productSlots: state.productSlots.map((slot: any) =>
					slot.uid !== action.productUid
						? slot
						: {
								...slot,
								play: {
									...slot.play,
									[action.attName]: action.value,
									dirty: dirtyCheckSlot(
										{
											...slot,
											play: { ...slot.play, [action.attName]: action.value },
										},
										state.productDefs.default,
									),
								},
						  },
				),
			});

		case CONFIG_SET_PRODUCT_LABEL:
			return reHash({
				...state,
				productSlots: state.productSlots.map((slot: any) =>
					slot.uid !== action.productUid
						? slot
						: {
								...slot,
								label: action.label,
						  },
				),
			});

		case CONFIG_SET_BASE:
			alert("todo: CONFIG_SET_BASE");
			return state;
		// return reHash({
		//     ...state,
		//     productSlots: state.productSlots.map((slot: any) => ({
		//         ...slot,
		//         play: {...slot.play, dirty: undefined},
		//         base: {...slot.play, dirty: undefined},

		//     })),
		//     messages: state.messages.map((message: any) => {
		//         let newMessage: any = {
		//             name: message.name,
		//             category: message.category,
		//             label: message.label,
		//             values: {}
		//         };
		//         Object.entries(message.values).forEach((entry: any) => {
		//             newMessage.values[entry[0]] = {
		//                 play: entry[1].play,
		//                 base: entry[1].play
		//             };
		//         })
		//         return newMessage;
		//     }),
		//     perceptions: state.perceptions.map((perception: any) => {
		//         let newPerception: any = {
		//             name: perception.name,
		//             category: perception.category,
		//             label: perception.label,
		//             values: {}
		//         };
		//         Object.entries(perception.values).forEach((entry: any) => {
		//             newPerception.values[entry[0]] = {
		//                 play: entry[1].play,
		//                 base: entry[1].play
		//             };
		//         })
		//         return newPerception;
		//     }),
		//     segmentation: { // what is this?
		//         ...state.segmentation,
		//         base: (state.segmentation || {}).play
		//     }
		// })

		case CONFIG_RESET_PRODUCT:
			return reHash({
				...state,
				productSlots: state.productSlots.map((slot: any) =>
					slot.uid !== action.productUid
						? slot
						: {
								...slot,
								play: { ...slot.base, dirty: false },
						  },
				),
			});

		// case CONFIG_RESET_MESSAGES:
		//     return reHash({
		//         ...state,
		//         messages: state.messages.map(m => ({
		//             ...m,
		//             values: {
		//                 ...m.values,
		//                 [action.item]: {
		//                     play: m.values[action.item].base,
		//                     base: m.values[action.item].base
		//                 }
		//             }
		//         }))
		//     });

		// case CONFIG_RESET_PERCEPTIONS:
		//     return reHash({
		//         ...state,
		//         perceptions: state.perceptions.map(m => ({
		//             ...m,
		//             values: {
		//                 ...m.values,
		//                 [action.item]: {
		//                     play: m.values[action.item].base,
		//                     base: m.values[action.item].base
		//                 }
		//             }
		//         }))
		//     });

		case CONFIG_RESET:
			// todo: simplify the brandEquities structure to make resetting it easier than all this junk:
			const newBrandEquities: any = mapObject(state.brandEquities, (i: any) =>
				mapObject(i, (j: any) => ({
					...j,
					play: j.base,
					dirty: false,
				})),
			);

			// const toArray = (obj) => Object.entries(obj).map(entry => ({
			//     key: entry[0],
			//     value: {
			//         ...entry[1],
			//         play: entry[1].base,
			//         dirty: false
			//     }
			// }));

			// const reduceArray = (arr) => arr.reduce((accum, val) => {
			//     accum[val.key] = val.value;
			//     return accum;
			// }, {})

			// const brandEquitiesAsArray = Object.entries(state.brandEquities).map(entry => ({
			//     key: entry[0],
			//     value: reduceArray(toArray(entry[1]))
			// }));

			// const newBrandEquities = reduceArray(brandEquitiesAsArray);

			return reHash({
				...state,
				productSlots: state.productSlots.map((product: any) => ({
					...product,
					play: { ...product.base, dirty: false },
				})),
				brandEquities: newBrandEquities,
			});

		case CONFIG_SET_VIEW:
			return state;
		// return { // not currently used
		//     ...state,
		//     view: action.value
		// };

		case CONFIG_SET_MISC_VALUE:
			return {
				// not sure if we should rehash here
				...state,
				[action.key]: action.value,
			};

		case CONFIG_RESET_BRAND_EQUITY_VALUE: {
			alert("todo");
			return state;
			return reHash({
				...state,
				brandEquities: {
					...state.brandEquities,
					[action.item]: {
						...state.brandEquities[action.item],
						[action.name]: {
							play: state.brandEquities[action.item][action.name].base,
							base: state.brandEquities[action.item][action.name].base,
						},
					},
				},
			});
		}

		case CONFIG_SET_BRAND_EQUITY_VALUE: {
			return reHash({
				...state,
				brandEquities: {
					...state.brandEquities,
					[action.item]: {
						...state.brandEquities[action.item],
						[action.name]: {
							play: action.value,
							base: state.brandEquities[action.item][action.name].base,
							dirty:
								action.value !=
								state.brandEquities[action.item][action.name].base,
						},
					},
				},
			});
		}

		case CONFIG_SET_ATT_LOCKED:
			let _slot: any = state.productSlots.find(
				(slot: any) => slot.uid === action.slotUid,
			);
			let _optim: any = _slot ? _slot.optim : {};
			let _locks: any = (_optim ? _optim.locks : null) || [];
			let new_locks: any = _locks.filter((el: any) => el !== action.attName);

			if (action.locked) {
				new_locks.push(action.attName);
			}

			return {
				...state,
				//etag: guid(), // not changing the config because the value it is locked on is stored in constrants instead of config
				productSlots: state.productSlots.map((slot: any) =>
					slot.uid !== action.slotUid
						? slot
						: {
								...slot,
								optim: {
									...slot.optim,
									locks: new_locks,
								},
						  },
				),
			};

		case CONFIG_SET_LEVEL_ALLOWED:
			let _slot2: any = state.productSlots.find(
				(slot: any) => slot.uid === action.slotUid,
			);
			let new_optim: any = _slot2 ? { ..._slot2.optim } : {};
			new_optim.disallow = { ...new_optim.disallow }; // make sure disallow exists
			new_optim.disallow[action.attName] =
				new_optim.disallow[action.attName] || []; // make sure disallow[attName] array exists

			// remove existing value
			new_optim.disallow[action.attName] = new_optim.disallow[
				action.attName
			].filter((el: any) => el !== action.levelValue);

			if (!action.allowed) {
				new_optim.disallow[action.attName].push(action.levelValue);
			}

			// remove array if empty
			if (new_optim.disallow[action.attName].length === 0) {
				delete new_optim.disallow[action.attName];
			}

			// remove disallow object if empty
			if (Object.keys(new_optim.disallow).length == 0) {
				delete new_optim.disallow;
			}

			return {
				...state,
				//etag: guid(), doesn't represent a change in config
				productSlots: state.productSlots.map((slot: any) =>
					slot.uid !== action.slotUid
						? slot
						: {
								...slot,
								optim: new_optim,
						  },
				),
			};

		case CONFIG_SET_MESSAGE_ALLOWED:
			return {
				...state,
				brandEquities: {
					...state.brandEquities,
					[action.key]: {
						...state.brandEquities[action.key],
						[action.name]: {
							...state.brandEquities[action.key][action.name],
							allowed: action.allowed ? true : false,
						},
					},
				},
			};

		case CONFIG_SET_OPTIMIZATION_METRIC:
			return {
				...state,
				optim: {
					...state.optim,
					metric: action.value,
				},
			};

		case CONFIG_TOGGLE_OPTIMIZATION_SELECTION: {
			const prev: any = (state.optim || {}).selections || [];
			const add: any = !prev.find((x: any) => x.uid === action.selection.uid);

			const newList: any = add
				? [...prev, action.selection]
				: prev.filter((x: any) => x.uid !== action.selection.uid);
			return {
				...state,
				optim: {
					...state.optim,
					selections: newList,
				},
			};
		}

		case CONFIG_ADD_OPTIMIZATION_SELECTION: {
			const prevList: any = (state.optim || {}).selections || [];
			const found: any = prevList.find(
				(x: any) => x.uid === action.selection.uid,
			);

			const newList: any = found ? prevList : [...prevList, action.selection];

			return {
				...state,
				optim: {
					...state.optim,
					selections: newList,
				},
			};
		}

		case CONFIG_REMOVE_OPTIMIZATION_SELECTION: {
			const prevList: any = (state.optim || {}).selections || [];
			const newList: any = prevList.filter(
				(x: any) => x.uid !== action.selection.uid,
			);

			return {
				...state,
				optim: {
					...state.optim,
					selections: newList,
				},
			};
		}

		case CONFIG_SET_SEGMENTATION: {
			return state;
			// return reHash({ // don't remember what this is for (maybe the soon to be obsolete choose segments page.)
			//     ...state,
			//     segmentation: {
			//         ...state.segmentation,
			//         play: action.value
			//     }
			// });
		}

		default:
			return state;
	}
};
