import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { actionCreators as modelActionCreators } from '../../store/Model'
import { actionCreators as GlobalStoreCreator } from '../../store/General'
import { applyConditions } from '../../utils/dcmUtils'
import { getMeta } from '../../utils/metaUtils'
import { ToggleSwitch } from '../common/ToggleSwitch'
import { AttributeBox } from './AttributeBox'

import { debounce } from '../../utils/debounce'

export const EditAreaAtts2 = (props) => {

  // console.log("EditAreaAtts2.props", props);
  const { area, prodId, configModel, monitor_atts } = props
//  console.log('prodId::::::::', prodId);
//  console.log('configModel', configModel);
//  console.log('monitor_atts', monitor_atts);

  if(!prodId || !configModel) return null

  const [label, setLabel] = useState(prod?.label)
  const [ meta, setMeta ] = useState(null)
  const [ metaLookups, setMetaLookups ] = useState(null)

  const { study, auth, model, general } = useSelector(state => ({
    study: state.study,
    auth: state.auth,
    model: state.model,
    general: state.general
  }))

// console.log('activeconfig', configModel);
  
  const dispatch = useDispatch()
  const cfgModel = model[configModel];
  const play = (cfgModel || {}).config?.play;

  // console.log('configModel', configModel);
  // console.log('cnfModel', cfgModel);

  const prod = play.find(s => s.uid === prodId) 
  const productDef = JSON.parse(JSON.stringify(cfgModel?.config?.productDef))
    
  if (!productDef) return null

    
  const debouncedSetLabel = debounce(
    (productUid, label) => dispatch(modelActionCreators.setProductLabel(productUid, label)),
    2000
  )

  useEffect(() => {
    if (prod) {
      setLabel(prod.label)
    }
  }, [prod])

  useEffect(() => {
        const fetchMeta = async () => {
            let m = await getMeta(study.uid, auth, cfgModel?.modelInfo.attConditionsTable)
            let lookups = await getMeta(study.uid, auth, cfgModel?.modelInfo.attConditionsLookups)
            if (m) {
                setMeta(m)
            }
            if (lookups) {
              setMetaLookups(lookups)
            }
          
        }

        // Call the async function
        fetchMeta()
    }, [cfgModel])

//  console.log('metaLookups',metaLookups);

  //set readOnly atts
  const updatedAtts = applyConditions(prod, productDef?.atts, meta?.rules, metaLookups)
  // console.log("prod", prod)
  // console.log("updatedAtts", updatedAtts)

  const editAtts = updatedAtts
    .filter(att => !att.readOnly)
    // .filter(att => att.area === area) 
    .sort((a, b) => a.displayIndex - b.displayIndex)
    .filter(att => att.showIf ? att.showIf(play) : true)
  // console.log("editAtts", editAtts);
  
  const handleToggle = (newVal) => {
    // console.log('newval', newVal);
        dispatch(modelActionCreators.setAvailable(prod?.uid, newVal, configModel))
        dispatch(GlobalStoreCreator.setGlobalStore('forecast_needed', newVal ? true : false))
    }
    
  
  return (
    <div className="att-area-editor">

      {/* {prod?.cloneOf && (
        <div className='atts-container no-pad'>
          <div className='attribute-box'>
            <div className='title'>
              <label>{productLabel} Name:</label>
              <span 
                className='del-product-btn'
                role='button' 
                aria-label='delete'
                onClick={() => modelActionCreators.deleteProduct(prod?.uid)}
              >
                Delete
              </span>
            </div>

            <input
              type="text"
              className="edit-product-label"
              value={label}
              onChange={handleLabelChange} 
            />
          </div>
        </div>
      )} */}

      <div className='availability-section'>
        <ToggleSwitch
          className='mini'
          on={Boolean(prod?.available)}
          onChange={handleToggle}
        >
          Available in Market
        </ToggleSwitch>
      </div>

      {Boolean(prod?.available) && (
        <div className='atts-container'>
          {editAtts.filter(f => f.type !== 'string').map(att => (
              <AttributeBox 
                key={att.name}
                prod={prod}
                att={att}
                configModel={configModel}
                monitor_atts={monitor_atts}
              />
            ))}
        </div>
      )}

    </div>
  )

}


