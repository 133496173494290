import * as React from 'react';
import './TabStrip.scss';

export class TabStrip extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: props.selectedIndex || 0
        };
    }

    handleTabClick = (selectedIndex, selectedValue) => {
        this.setState({
            selectedIndex
        }, () => {
            // callback
            if( this.props.onSelectedIndexChanged ){
                this.props.onSelectedIndexChanged(selectedIndex, selectedValue);
            }
        });

    }

    render() {
        return <div className='tabstrip' style={this.props.style}>
            {React.Children.map(this.props.children, (child, index) => {
                return child ? React.cloneElement(child, {
                    onClick: this.handleTabClick,
                    tabIndex: index,
                    selected: (index === this.state.selectedIndex)
                }) : null;
            })}
        </div>
    }

}


export const TabStrip2 = (props) => {

    const selectedIndex = props.selectedIndex;

    return <div className={'tabstrip' + (props.forPage ? ' for-page' : '')} style={props.style}>
        {React.Children.map(props.children, (child, index) => {
            return child ? React.cloneElement(child, {
                onClick: props.selectedIndexChanged,
                tabIndex: index,
                selected: (index === selectedIndex)
            }) : null;
        })}
    </div>
    
}

export class Tab extends React.Component{

    render() {
        return <div className={'tab' + (this.props.selected ? ' sel' : '')}
            onClick={() => this.props.onClick ? this.props.onClick(this.props.tabIndex, this.props.value) : null}
        >
            {this.props.children}
        </div>
    }

}

export class Tab_buttons extends React.Component{

    render() {
        return <div className={'tab-buttons ' + (this.props.selected ? ' sel' : '')} onClick={() => this.props.onClick ? this.props.onClick(this.props.tabIndex, this.props.value) : null} >
            {this.props.children}
        </div>
    }

}


