import { format as d3Format } from "d3-format";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as analyticsActionCreators } from "../../store/Analytics.ts";
import * as debounce from "../../utils/debounce";
import { CardLoading } from "../card/CardLoading";
import { FilterChooser } from "../filter/FilterChooser";
import { ProductBlockMini } from "../product-drawer/ProductBlockMini";
import { TableFred } from "../vis/TableFred";
import "./SurveyTableViewer.scss";

const _fmt1 = d3Format("0.2f");
const _fmtn = d3Format(",");

const fmt1 = (val) => (val !== null ? _fmt1(val) : ".");
const fmtn = (val) => (val !== null ? _fmtn(val) : ".");

export class SurveyTableViewer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			sortCol: -1,
			sortDir: "desc",
		};

		// Allow card to be saved as insight card. Not the greatest pattern.
		this.props.stateContext.getSaveState = this.getSaveState;
	}

	componentDidMount() {
		this.refreshIfNeeded();
	}

	componentDidUpdate(prevProps) {
		this.refreshIfNeeded();
		if (
			this.props.sortCol != prevProps.sortCol ||
			this.props.sortDir != prevProps.sortDir
		) {
			this.setState({
				sortCol: this.props.sortCol,
				sortDir: this.props.sortDir,
			});
		}
	}

	refreshIfNeeded = debounce.debounce(() => {
		// cancel if nothing selected (what about props.needsSelection!?)
		const selection = this.props.selection;
		if (!selection) return;
		// if( this.props.needsSelection && !this.props.selection ) return;

		// cancel if insight card
		if (this.props.inInsightCard) return;

		// cancel if cached data already exists
		if (this.props.cache) return; // insights card

		if (!this.props.cache) {
			const rootPath = this.props.path;
			const filter = this.props.filter;
			const path = `${rootPath}:${selection.uid}`;
			const key = `${rootPath}:${selection.uid}:${filter.syntax}`;
			const query = this.props.getQuery?.(selection, filter);
			this.props.loadYak(path, key, query);
		}
	}, debounce.basicDelay);

	static inheritOptions = [];

	getSaveState = () => {
		const s = {
			selection: this.props.selection,
			cache: this.props.cache,
			filter: this.props.filter,
			sortCol: this.state.sortCol,
			sortDir: this.state.sortDir,
		};
		//console.log('save state', s);
		return s;
	};

	toggleSort = (sortCol) => {
		if (this.state.sortCol === sortCol) {
			if (this.state.sortDir === "desc") {
				this.setState({ sortDir: "asc" });
			} else if (this.state.sortDir === "asc") {
				this.setState({ sortDir: null, sortCol: -1 });
			} else {
				// shouldn't get here
			}
		} else {
			this.setState({ sortDir: "desc", sortCol });
		}
	};

	sort = (sortCol, sortDir, element1, element2) => {
		// fix key when additional columns are added
		const key = sortCol === 0 ? "label" : sortCol === 1 ? "a" : "b";
		const type = sortCol === 0 ? "str" : "num";

		if (type === "str") {
			const multiplier = sortDir === "asc" ? -1 : 1;
			return element1[key] < element2[key]
				? -1 * multiplier
				: element2[key] < element1[key]
				? 1 * multiplier
				: 0;
		} else {
			if (sortDir === "asc") {
				return element1[key].value - element2[key].value;
			} else {
				return element2[key].value - element1[key].value;
			}
		}
	};

	getSortIndicator = (sortCol) => {
		if (this.state.sortCol !== sortCol) return null;
		return (
			<span className="sort-indicator">
				{this.state.sortDir === "desc" ? (
					<i className="fal fa-chevron-down" />
				) : (
					<i className="fal fa-chevron-up" />
				)}
			</span>
		);
	};

	render() {
		const study = this.props.study;
		if (!study) return;

		let cache = this.props.cache;
		let selection = this.props.selection;
		if (!selection) return "no selection"; // && this.needsSelection ) return 'no selection';

		const selectionLevel = this.props.selectionLevel;

		//const model = this.props.selection ? (this.props.selection || {}).model : null;
		const data = cache ? cache.value : null;

		//console.log('here', data, this.props.getResult, this.props.entries, selection);
		let data2 =
			data && this.props.getResult
				? this.props.entries.map((entry) =>
						this.props.getResult(data, entry, this.props.filter, selection),
				  )
				: null || [];

		const isGroupSet =
			this.props.filter.syntax !=
			globals.getDefaultFilter(this.props.study).syntax;

		const columns = [
			{
				key: "label",
				type: "string",
				label: this.props.label,
				className: "label",
			},
			{
				key: "a",
				type: "cell",
				label: globals.getDefaultFilter(this.props.study).label,
				className: "data",
				format: fmt1,
			},
			{
				key: "b",
				type: "cell",
				label: isGroupSet ? this.props.filter.label : "[not set]",
				className: "data",
				format: fmt1,
			},
		];

		const cFilter = isGroupSet
			? this.props.filter
			: {
					syntax: "1==0",
					label: "[select filter]",
			  };

		let rowData = (data2 || []).map((row, rowIndex) => ({
			...row,
			//a: row.a ? row.a.value : null,
			//b: isGroupSet ? (row.b ? row.b.value : null) : null,
			b: isGroupSet ? row.b : { value: null, n: 0 },
		}));

		// highlight min and max cell(s) in column
		{
			const aValues = rowData
				.map((row) => row?.a?.value)
				.filter((val) => val !== null);
			const bValues = rowData
				.map((row) => row?.b?.value)
				.filter((val) => val !== null);

			const aStats = {
				min: Math.min(...aValues),
				max: Math.max(...aValues),
			};

			const bStats = {
				min: Math.min(...bValues),
				max: Math.max(...bValues),
			};

			rowData.forEach((row) => {
				if (row.a?.value !== null) {
					if (row.a?.value <= aStats.min) {
						row.a.className = "min-cell";
					}
					if (row.a?.value >= aStats.max) {
						row.a.className = "max-cell";
					}
				}
				if (row.b?.value !== null) {
					if (row.b?.value <= bStats.min) {
						row.b.className = "min-cell";
					}
					if (row.b?.value >= bStats.max) {
						row.b.className = "max-cell";
					}
				}
			});
		}

		const content = !cache ? null : cache.loading ? null : (
			<div className="table-container">
				<TableFred
					getExportData={this.props.getExportData}
					getJSONData={this.props.getJSONData}
					columns={columns}
					data={rowData}
					debug={true}
				/>
			</div>
		);

		if (
			cache &&
			!cache.loading &&
			this.props.loaded &&
			typeof this.props.loaded === "function"
		)
			this.props.loaded(!cache.loading);
		if (
			this.props.getJSONData &&
			typeof this.props.getJSONData === "function" &&
			columns &&
			rowData
		)
			this.props.getJSONData({ columns: [...columns], data: [...rowData] });

		return (
			<div className="widget survey-table-viewer">
				<div className="widget-header">
					<div className="title">
						{this.props.getTitle
							? this.props.getTitle(this.props.selection)
							: this.props.title}
					</div>
					<div className="selection">
						<ProductBlockMini
							{...selection}
							disabled={this.props.inInsightCard}
							allowChange={[selectionLevel]}
						/>
					</div>
					<div className="filter-etc">
						<FilterChooser
							mini={true}
							disabled={this.props.inInsightCard}
							selection={cFilter}
						/>
					</div>
				</div>
				<div className="widget-body vscroll hscroll">{content}</div>
				<CardLoading loading={cache && cache.loading} />
			</div>
		);
	}
}

SurveyTableViewer = connect(
	(state, ownProps) => {
		const filter =
			state.filter?.selectedFilter || globals.getDefaultFilter(state.study);
		const selectionLevel = ownProps.selectionLevel || "product";
		const selection = state.selections?.[selectionLevel];

		return {
			study: state.study,
			selectionLevel,
			entries: ownProps.entries || [],
			...(ownProps.inDataCard
				? {
						filter,
						selection,
						cache:
							state.cache.analytics[
								`${ownProps.path}:${selection?.uid}:${filter.syntax}`
							],
				  }
				: null),
		};
	},
	(dispatch) => bindActionCreators(analyticsActionCreators, dispatch),
)(SurveyTableViewer);
