//import * as signalR from '@aspnet/signalr';
import { HubConnectionBuilder } from '@microsoft/signalr';
import * as globals from '../globals';
import React, { Component, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Study.ts';
//import { ThemeContext } from '../theme';
//import { Assumptions } from './assumptions/Assumptions';
import { CardPage } from './card/CardPage';
import { WhatIf } from './config/WhatIf';
import { Explore } from './explore/Explore'; // this is the component, not the store
import { EditStory } from './feed/EditStory';
import { Feed } from './feed/Feed';
import { Game } from './game/Game';
import { BottomBar } from './leftbar/BottomBar';
import { LeftBar } from './leftbar/LeftBar';
import { EditMetaPage } from './meta/EditMetaPage';
import { TasksPage } from './meta/TasksPage';
import { Optimize } from './optimize/Optimize';
import { QuestionPage } from './question/QuestionPage';
import { ResearchScenarios } from './scenarios/ResearchScenarios';
import { MAINHUB_MESSAGE_RECEIVED } from '../store/action-types.ts';
import * as metaUtils from '../utils/metaUtils';
import { actionCreators as selectionActionCreators } from '../store/Selection.ts';
import { CardLoading } from './card/CardLoading';

export let Study = (props) => {

    const studyId = props.uid;
    const study = useSelector(state => state.study);
    const auth = useSelector(state => state.auth);
    const [selection, setSelection] = useState();
    const dispatch = useDispatch();

    //very important, finally sets study uid 
    useEffect(() => {
        if (studyId != study.uid) {
            props.loadStudy(studyId);
        }
    }, [studyId])   


    useEffect(() => {

        const connection = new HubConnectionBuilder()
            .withUrl(`${globals.appRoot}/mainHub`)
            .withAutomaticReconnect()
            .build();

        connection.on('message', payload => {
            console.log('%cmainHub message', 'color: orangered; font-weight: bold;', payload);

            // wee little hack until json protocol is working
            let p = { ...payload };
            if (payload.dataJson) {
                p.data = JSON.parse(payload.dataJson);
                delete (p.dataJson);
            }

            dispatch({ type: MAINHUB_MESSAGE_RECEIVED, payload: p })
        });

        connection.on('strMessage', payload => {
            console.log('%cmainHub strMessage', 'color: orangered; font-weight: bold;', payload);
        });
        
        connection.start()
            .then(result => {
                console.log('%cConnected to mainHub', 'background-color: yellow; font-weight: bold; color: black');
                connection.invoke("BroadcastStrMessage", "Signalr Connection Running!");
            })
            .catch(e => console.log('Connection failed: ', e));
    }, []);

    useEffect(() => {
        const func = async () => {

            //pull query string params
            const params = new URLSearchParams(props?.location?.search);
            const selectorNames = params.get('sel')?.split('|');
            const selectorIds = params.get('selId')?.split('|');

            // console.log('params', params);
            // console.log('selectorNames', selectorNames);
            // console.log('selectorIds', selectorIds);

            let tableNames = []; 

            try {
                let metaSelectionTypes = await metaUtils.getMeta(studyId, auth, 'selectionTypes'); 

                    selectorNames?.forEach((sn, idx) => {
                        tableNames.push({origname: sn, tablename: metaSelectionTypes?.[sn]?.meta, value: selectorIds[idx] });
                    })
                    
                
                    let metaTables = await metaUtils.getMetas(studyId, auth, tableNames.map(m => m.tablename));
                    //update/change data
                    tableNames?.forEach((table, idx) => {
                        
                        let metaObj = metaTables?.[table.tablename];
                        let selValue = table.value;
                        
                        //set defaults
                        let sel = metaObj.find(f => f.id == selValue);
                        setSelection(sel);

                        selectionActionCreators?.setSelection(table.origname, sel,() => {})(dispatch, () => state);
                })


            }
            catch (err) {
                console.error(err);
            }
            
        }
    func();

    }, selection)



    //const webclient_version = useSelector(state => state.auth?.webclient_version);
    //const studies = useSelector(state => state.auth?.studies);
    //const general = useSelector(state => state.general);

    if (study?.error) {
        return <div className="blank-screen">ERROR: ( {study.error} ) LOADING STUDY</div>;
    }

    // get studyId from url
    // lookup study, make sure user has access
    //let study = studies.find(study => study.uid === studyId);

    if (study == null) {
        return (
            <div className="blank-screen">
                Study doesn't exist or you do not have access
            </div>
        );
    }

    if (study == null || study?.isLoading) {
        return <div className='blank-screen'></div>
    }
    
    // <div className="blank-screen">loading study...</div>;
    
    let wideLeftMenu = false;// (this.props.general || {}).wideLeftMenu;
    //let theme = this.context;
    let hasLeftBar = false; // theme.leftBar === 'collapsible';

                
    // study exists
    return (

        <React.Fragment>
            {/* <BottomBar /> */}
        
            <div className={ 'main ' + (hasLeftBar ? ' has-left-bar' : '') + (wideLeftMenu ? ' wide-left-menu' : '') } >
                {hasLeftBar ? <LeftBar /> : null}

                <div className={'content theme-default'}>
                    <Switch>
                            
                        <Route
                            exact
                            path="/study/:studyId/meta"
                            component={EditMetaPage}
                        />

                        <Route
                            exact
                            path="/study/:studyId/tasks"
                            component={TasksPage}
                        />

                        <Route
                            exact
                            path="/study/:studyId/feed/:channelId/post/:postId/story/:storyId/card/:cardId"
                            component={CardPage}
                        />
                        
                        <Route
                            exact
                            path="/study/:studyId/feed/post/:postId/story/:storyId/card/:cardId"
                            component={CardPage}
                        />

                        <Route
                            exact
                            path="/study/:studyId/feed/:channelId/post/:postId/comments"
                            render={props => <EditStory key={props.match.params.postId} postId={props.match.params.postId} tab='comments' />}
                        />

                        <Route
                            exact
                            path="/study/:studyId/feed/:channelId/post/:postId"
                            render={props => <EditStory key={props.match.params.postId} postId={props.match.params.postId} />}
                        />

                        <Route
                            exact
                            path="/study/:studyId/feed/post/:postId/comments"
                            render={props => <EditStory key={props.match.params.postId} postId={props.match.params.postId} tab='comments' />}
                        />

                        <Route
                            exact
                            path="/study/:studyId/feed/post/:postId"
                            render={props => <EditStory key={props.match.params.postId} postId={props.match.params.postId} />}
                        />

                        <Route
                            exact
                            path="/study/:studyId/edit-story/:storyId/card/:cardId"
                            render={(props) => <CardPage key={props.match.params.cardId} match={props.match} cardId={props.match.params.cardId} editMode={true} storyId={props.match.params.storyId} />}
                        />

                        <Route
                            exact
                            path="/study/:studyId/edit-story/:storyId"
                            render={(props) => <EditStory key={props.match.params.storyId} storyId={props.match.params.storyId} editing={true} />}
                        />

                        <Route
                            exact
                            path="/study/:studyId/feed/:channelId" component={Feed}
                        />

                        <Route path="/study/:studyId/feed" component={Feed} />

                        <Route
                            path="/study/:studyId/optrun/:runId"
                            render={(props) => <ResearchScenarios runId={props.match.params.runId} />}
                        />

                        {/* <Route
                            path="/study/:studyId/research-scenarios"
                            component={ResearchScenarios}
                        /> */}

                        <Route
                            path="/study/:studyId/what-if/:sectionId/card/:widgetId"
                            component={CardPage}
                            exact={true}
                        />

                        <Route path="/study/:studyId/what-if/:sectionId" component={WhatIf}/>
                        <Route path="/study/:studyId/what-if" component={WhatIf} /> 

                        <Route
                            path="/study/:studyId/optimize"
                            component={Optimize}
                        />

                        <Route
                            path="/study/:studyId/game"
                            component={Game}
                        />

                        
                        {/*****key={document.location.href} is needed to cause re-render in Explore.js for cover page loading icon ******/}
                        <Route path="/study/:studyId/explore/:parent3id/:parent2id/:parentid/:id/card/:widgetId" component={CardPage} exact={true} key={document.location.href}/>
                        <Route path="/study/:studyId/explore/:parent2id/:parentid/:id/card/:widgetId" component={CardPage} exact={true} key={document.location.href}/>
                        <Route path="/study/:studyId/explore/:parentid/:id/card/:widgetId" component={CardPage} exact={true} key={document.location.href}/>
                        <Route path="/study/:studyId/explore/:id/card/:widgetId" component={CardPage} exact={true} key={document.location.href}/>
                        <Route path="/study/:studyId/explore/card/:widgetId" component={CardPage} exact={true} key={document.location.href}/>

                        <Route path="/study/:studyId/explore/:parent3id/:parent2id/:parentid/:id" component={Explore} exact={true} key={document.location.href}/>
                        <Route path="/study/:studyId/explore/:parent2id/:parentid/:id" component={Explore} exact={true} key={document.location.href}/>
                        <Route path="/study/:studyId/explore/:parentid/:id" component={Explore} exact={true} key={document.location.href}/>
                        <Route path="/study/:studyId/explore/:id" component={Explore} exact={true} key={document.location.href}/>
                        <Route path="/study/:studyId/explore" component={Explore} exact={true} key={document.location.href}/>

                        <Route path="/study/:studyId/question/:qid" component={QuestionPage} exact={true}/>

                        {/* <Route
                            path="/study/:studyId/assumptions"
                            render={props => <Assumptions />}
                        /> */}

                        {/* can i redirect to feed? */}
                        <Route exact path="/study/:studyId/" component={Explore} key={document.location.href} />
                    </Switch>
                </div>
            
            </div>
        </React.Fragment>
    );
}


//Study.contextType = ThemeContext;

Study = withRouter(
    connect(
        state => ({
            // webclient_version: state.auth?.webclient_version,
            // studies: state.auth.studies,
            // study: state.study,
            // general: state.general
        }),
        dispatch => bindActionCreators(actionCreators, dispatch)
    )(Study)
);
