import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import * as globals from '../../globals';
import * as apiService from '../../utils/apiService';
import { actionCreators } from '../../store/Study.ts';
import './TopBar.scss';
import { bindActionCreators } from 'redux';

export class UserMenu extends React.Component {

  sendEmail = (which) => {
    let auth = this.props.auth;
    apiService.aGet(auth, `${globals.appRoot}/email/test/${which}`)
      .then((res) => {
        console.log(res);
        alert(`Sent: [${res?.statusText}]. Check console log.`);
      })
  }

  render() {
    let study = this.props.study;
    let user = this.props || {};

    // console.log('props', user, study)

    let onStudySelectPage = this.props.match?.path == '/' && this.props.match?.isExact;
    
    return (
      <div className="basic-menu">
        <div className="avatar">
          <img src={`${globals.appRoot}/avatar/${user.id}`} alt="User Avatar" />
        </div>

        <Link className="menu-item sign-out" role='link' aria-label='Sign Out' onClick={() => window.topBar.hideMenu() } to={'/sign-out'}>
          Sign Out
          <i className="icon fas fa-sign-out" />
        </Link>

        {!onStudySelectPage ?
          <>
            <div className="separator-with-text">Selected Subscription</div>

            {study?.uid ?
              <NavLink to={`/`} className={'menu-item'} onClick={this.props.closeRequested} role='link' aria-label='change studies'>
                <i className='icon fal fa-exchange' />
                {study?.label}
              </NavLink>
              :
              <NavLink to={`/`} className={'menu-item'} onClick={this.props.closeRequested} role='link' aria-label='change studies'>
                <i className='icon fal fa-exchange' />
                Select study...
              </NavLink>
            }

            {/* note: maybe list a few recent studies */}

            {false && this.props.studies != null
              ? this.props.studies.map(s => (
                  <NavLink
                    key={s.uid}
                    to={`/study/${s.uid}/`}
                    className={'menu-item'}
                    activeClassName="selected"
                    onClick={this.props.closeRequested}
                  >
                    {s.label}
                  </NavLink>
                ))
              : null}
            
          </>
          : null
        }

        <div className="separator-with-text">Manage</div>

        <Link
          className="menu-item"
          to={'/profile/edit'}
          onClick={this.props.closeRequested}
          role='link' aria-label='edit profile'
        >
          Edit Profile
        </Link>

        <Link
          className="menu-item"
          to={'/account/change-password'}
          onClick={this.props.closeRequested}
          role='link' aria-label='change password'
        >
          Change Password
          <i className="icon fal fa-key" />
        </Link>
        {
          study.isStudyAdmin ? <>
          <Link
            className="menu-item"
            to={`/study/${this.props.study?.uid}/explore/license settings`}
              onClick={this.props.closeRequested}
              role='link' aria-label='license settings'
          >
            License Settings
            <i className="icon fal fa-sliders-v-square" />
            </Link>
          </> : null
        }

        <a className="menu-item"
            role='link' aria-label='set user defaults'
              onClick={(ev,idx) => {
                this.props.storeUserDefaults();
                this.props.closeRequested();
                window.topBar.enqueueSnack({
                      id: 'user_' + idx,
                      message: 'Defaults Saved',
                      to: 3000,
                      success: true
                    })
              }}
            >
              Set User Defaults
                <i className="icon fal fa-tasks" />
        </a>
        
        <a className="menu-item"
          role='link' aria-label='delete user defaults'
              onClick={(ev,idx) => {
                this.props.deleteUserDefaults();
                this.props.closeRequested();
                window.topBar.enqueueSnack({
                      id: 'user_' + idx,
                      message: 'Defaults Deleted',
                      to: 3000,
                      success: true
                    })
              }}
            >
              Delete User Defaults
                <i className="icon fal fa-trash-alt" />
            </a>

        {
          this.props.user.isSuperAdmin ? <>
            <div className="separator-with-text">
              Super Administration <i className="fa fa-lock-alt" />
            </div>

            <Link
              className="menu-item"
              to={'/account/manage'}
              onClick={this.props.closeRequested}
              role='link' aria-label='users and accounts'
            >
              Users and Accounts
               <i className="icon fal fa-user-chart" />
            </Link>
            <Link
              className="menu-item"
              to={'/account/manage-users'}
              onClick={this.props.closeRequested}
              role='link' aria-label='manage users'
            >
              Manage Users
               <i className="icon fal fa-users-cog" />
            </Link>

             {/* <Link
              className="menu-item"
              to={'/account/study-approval'}
              onClick={this.props.closeRequested}
              role='link' aria-label='assign licenses'
            >
              Assign Licenses to Users
               <i className="icon fal fa-user-check" />
            </Link> */}


            <div className="separator-with-text">
              Email testing <i className="fa fa-lock-alt" />
            </div>

            {[
              { key: "registration" },
              { key: "welcome" },
              { key: "passwordReset" },
              { key: "signupRequest" },
            ].map(entry => <a className="menu-item" key={entry.key}
                  onClick={() => {
                    this.sendEmail(entry.key);
                    this.props.closeRequested();
                  }}
                >
              Send test email: {entry.key} <i className="icon fal fa-paper-plane" />
            </a>)}

            {this.props.study?.uid ?
              
              <>
                <div className="separator-with-text">
                  Dev Administration <i className="fa fa-lock-alt" />
                </div>


                <Link
                  className="menu-item"
                  to={`/study/${this.props.study?.uid}/meta`}
                  onClick={this.props.closeRequested}
                  role='link' aria-label='edit meta'
                >
                  Edit Study Meta [{this.props.study?.folder}]
                  {/* <i className="icon fal fa-brackets-curly" /> */}
                </Link> 

                <Link
                  className="menu-item"
                  to={`/study/${this.props.study?.uid}/tasks`}
                  onClick={this.props.closeRequested}
                  role='link' aria-label='view study tasks'
                >
                  View Study Tasks [{this.props.study?.folder}]
                  {/* <i className="icon fal fa-brackets-curly" /> */}
                </Link> 

                <a className="menu-item"
                  role='link' aria-label='set license defaults'
                  onClick={() => {
                    this.props.storeDefaults();
                    this.props.closeRequested();
                  }}
                >
                  Set License Defaults ( {`All ${this.props.study?.label}`})
                   {/* <i className="icon fal fa-save" /> */}
                </a>

                <a className="menu-item"
                  role='link' aria-label='recycle license'
                  onClick={() => {
                    this.props.recycleStudy(this.props.study?.uid);
                    this.props.closeRequested();
                  }}
                >
                  Recycle License ({this.props.study?.label})
                  <i className="icon fal fa-recycle" />
                </a>

              </>
              : null}
          </> : null
        }

        {/* <Link
          className="menu-item"
          to={'/admin/studies'}
          onClick={this.props.closeRequested}
        >
          Studies Admin
        </Link>

        <Link
          className="menu-item"
          to={'/admin/channels'}
          onClick={this.props.closeRequested}
        >
          Channel Admin
        </Link> */}

        <div className="separator" />
      </div>
    );
  }
}

const mapStateToProps = state => {
  let studies = undefined;
  let user = undefined;
  if (state.auth != null) {
    studies = state.auth.studies;
    user = state.auth.user;
  }

  return {
    studies,
    user,
    auth: state.auth
  };
};

//const mapDispatchToProps = dispatch => ({});
const mapDispatchToProps = dispatch => bindActionCreators(actionCreators, dispatch)

UserMenu = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserMenu)
);
