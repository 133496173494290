/** @format */
/**
 * Any new action type should be added to the set of exports below, with the
 * value mirroring its exported name.
 *
 * Please keep this list alphabetized.
 *
 * Action types are considered global such that any state subtree's reducer
 * can react to any action types dispatched through the system.
 *
 * Please suffix the verb so that all actions within the same domain
 * scope are in relative proximity. For example, rather than naming your actions
 * FETCH_POSTS AND RECEIVE_POSTS, you should name them POSTS_FETCH AND POSTS_RECEIVE.
 *
 * Here's an example of the guidelines we are following:
 *  - https://wpcalypso.wordpress.com/devdocs/docs/our-approach-to-data.md#actions
 *  - https://github.com/Automattic/wp-calypso/blob/master/client/state/action-types.js
 */

export const ACCOUNT_LIST_USERS: string = 'ACCOUNT_LIST_USERS';

export const ANALYTICS_RECEIVE: string = 'ANALYTICS_RECEIVE';
export const ANALYTICS_REQUEST: string = 'ANALYTICS_REQUEST';

export const ASSUMPTIONS_RECEIVE: string = 'ASSUMPTIONS_RECEIVE';

export const AUTH_CLEAR_MESSAGES: string = 'AUTH_CLEAR_MESSAGES';
export const AUTH_CLEAR_TOKEN: string = 'AUTH_CLEAR_TOKEN';
export const AUTH_RENEW_TOKEN: string = 'AUTH_RENEW_TOKEN';
export const AUTH_REQUEST_RESET: string = 'AUTH_REQUEST_RESET';
export const AUTH_REQUEST_TOKEN: string = 'AUTH_REQUEST_TOKEN';
export const AUTH_STORE_TOKEN: string = 'AUTH_STORE_TOKEN';
export const AUTH_LOGOUT: string = 'AUTH_LOGOUT';
export const AUTH_STORE_SCROLL_POS: string = 'AUTH_STORE_SCROLL_POS';
export const AUTH_UPDATE_SCROLL_POS: string = 'AUTH_UPDATE_SCROLL_POS';

export const BIG_DATA_REQUEST: string = 'BIG_DATA_REQUEST';
export const BIG_DATA_RECEIVE: string = 'BIG_DATA_RECEIVE';

export const CARDS_CARD_INSIGHTS_PATCH: string = 'CARDS_CARD_INSIGHTS_PATCH';
export const CARDS_UPDATE_TEXTCARD: string = 'CARDS_UPDATE_TEXTCARD';
export const CARDS_CARD_RECEIVE: string = 'CARDS_CARD_RECEIVE';
export const CARDS_CARD_REQUEST: string = 'CARDS_CARD_REQUEST';
export const CARDS_RECEIVE: string = 'CARDS_RECEIVE';
export const CARDS_REQUEST: string = 'CARDS_REQUEST';
export const CARDS_DELETED: string = 'CARDS_DELETED';
export const CARDS_SELECT: string = 'CARDS_SELECT';

export const CHANNELS_RECEIVE: string = 'CHANNELS_RECEIVE';
export const CHANNELS_REQUEST: string = 'CHANNELS_REQUEST';

export const COMPARE_ALL_DESELECT    : string = 'COMPARE_ALL_DESELECT';
export const COMPARE_FOLDER_COLLAPSE : string = 'COMPARE_FOLDER_COLLAPSE';
export const COMPARE_FOLDER_DESELECT : string = 'COMPARE_FOLDER_DESELECT';
export const COMPARE_FOLDER_EXPAND   : string = 'COMPARE_FOLDER_EXPAND';
export const COMPARE_FOLDER_SELECT   : string = 'COMPARE_FOLDER_SELECT';
export const COMPARE_GROUP_COLLAPSE_ALL: string = 'COMPARE_GROUP_COLLAPSE_ALL';
export const COMPARE_GROUP_DESELECT  : string = 'COMPARE_GROUP_DESELECT';
export const COMPARE_GROUP_EXPAND_ONLY_SEARCHED_TERM: string = 'COMPARE_GROUP_EXPAND_ONLY_SEARCHED_TERM';
export const COMPARE_GROUP_SELECT    : string = 'COMPARE_GROUP_SELECT';
export const COMPARE_GROUPS_RECEIVE  : string = 'COMPARE_GROUPS_RECEIVE';
export const COMPARE_GROUPS_REQEST   : string = 'COMPARE_GROUPS_REQEST';
export const COMPARE_SET             : string = 'COMPARE_SET';
export const COMPARE_SET_CTYPE       : string = 'COMPARE_SET_CTYPE';

export const COMPUTE_LEVEL_HINTS_RECEIVE: string = 'COMPUTE_LEVEL_HINTS_RECEIVE';
export const COMPUTE_LEVEL_HINTS_REQUEST: string = 'COMPUTE_LEVELS_HINTS_REQUEST';
export const COMPUTE_SEGMENT_PROFILES_RECEIVE: string = 'COMPUTE_SEGMENT_PROFILES_RECEIVE';
export const COMPUTE_SEGMENT_PROFILES_REQUEST: string = 'COMPUTE_SEGMENT_PROFILES_REQUEST';
export const COMPUTE_SUMMARY_RECEIVE: string = 'COMPUTE_SUMMARY_RECEIVE';
export const COMPUTE_SUMMARY_REQUEST: string = 'COMPUTE_SUMMARY_REQUEST';
export const COMPUTE_VAN_WESTENDORP_RECEIVE: string = 'COMPUTE_VAN_WESTENDORP_RECEIVE';
export const COMPUTE_VAN_WESTENDORP_REQUEST: string = 'COMPUTE_VAN_WESTENDORP_REQUEST';

export const CONFIG_CLONE_PRODUCT: string = 'CONFIG_CLONE_PRODUCT';
export const CONFIG_DELETE_PRODUCT: string = 'CONFIG_DELETE_PRODUCT';
export const CONFIG_RESET: string = 'CONFIG_RESET';
export const CONFIG_RESET_BRAND_EQUITY_VALUE: string = 'CONFIG_RESET_BRAND_EQUITY_VALUE';
export const CONFIG_RESET_MESSAGES: string = 'CONFIG_RESET_MESSAGES';
export const CONFIG_RESET_PERCEPTIONS: string = 'CONFIG_RESET_PERCEPTIONS';
export const CONFIG_RESET_PRODUCT: string = 'CONFIG_RESET_PRODUCT';
export const CONFIG_SET_ATT_LOCKED: string = 'CONFIG_SET_ATT_LOCKED';
export const CONFIG_SET_ATT_VALUE: string = 'CONFIG_SET_ATT_VALUE';
export const CONFIG_SET_YEARS_ATT_VALUE: string = 'CONFIG_SET_YEARS_ATT_VALUE';
export const CONFIG_SET_BASE: string = 'CONFIG_SET_BASE';
export const CONFIG_SET_BRAND_EQUITY_VALUE: string = 'CONFIG_SET_BRAND_EQUITY_VALUE';
export const CONFIG_SET_LEVEL_ALLOWED: string = 'CONFIG_SET_LEVEL_ALLOWED';
export const CONFIG_SET_MESSAGE_ALLOWED: string = 'CONFIG_SET_MESSAGE_ALLOWED';
export const CONFIG_SET_MISC_VALUE: string = 'CONFIG_SET_MISC_VALUE';
export const CONFIG_SET_OPTIMIZATION_METRIC: string = 'CONFIG_SET_OPTIMIZATION_METRIC';
export const CONFIG_SET_PRODUCT_LABEL: string = 'CONFIG_SET_PRODUCT_LABEL';
export const CONFIG_TOGGLE_OPTIMIZATION_SELECTION: string = 'CONFIG_TOGGLE_OPTIMIZATION_SELECTION';
export const CONFIG_ADD_OPTIMIZATION_SELECTION: string = 'CONFIG_ADD_OPTIMIZATION_SELECTION';
export const CONFIG_REMOVE_OPTIMIZATION_SELECTION: string = 'CONFIG_REMOVE_OPTIMIZATION_SELECTION';
export const CONFIG_SET_PRODUCT_AVAILABLE: string = 'CONFIG_SET_PRODUCT_AVAILABLE';
export const CONFIG_SET_PRODUCT_LOCKED: string = 'CONFIG_SET_PRODUCT_LOCKED';
export const CONFIG_SET_PRODUCT_PINNED: string = 'CONFIG_SET_PRODUCT_PINNED';
export const CONFIG_SET_SEGMENTATION: string = 'CONFIG_SET_SEGMENTATION';
export const CONFIG_SET_VIEW: string = 'CONFIG_SET_VIEW';


export const DCM_SET_ACTIVE_CONFIG: string = 'DCM_SET_ACTIVE_CONFIG'
export const DCM_CONFIG_INIT: string = 'DCM_CONFIG_INIT' 
export const DCM_NEED_UPDATE: string = 'DCM_NEED_UPDATE';
export const DCM_APPLY_CONDITIONS: string = 'DCM_APPLY_CONDITIONS';
export const DCM_UPDATE_CONFIG: string = 'DCM_UPDATE_CONFIG';
export const DCM_CLONE_PRODUCT: string = 'DCM_CLONE_PRODUCT';   
export const DCM_DELETE_PRODUCT: string = 'DCM_DELETE_PRODUCT';
export const DCM_RESET: string = 'DCM_RESET';
export const DCM_SET_ETAG: string = 'DCM_SET_ETAG';
export const DCM_RESET_BRAND_EQUITY_VALUE: string = 'DCM_RESET_BRAND_EQUITY_VALUE';
export const DCM_RESET_MESSAGES: string = 'DCM_RESET_MESSAGES';
export const DCM_RESET_PERCEPTIONS: string = 'DCM_RESET_PERCEPTIONS';
export const DCM_RESET_PRODUCT: string = 'DCM_RESET_PRODUCT';
export const DCM_RESET_TO_BASECASE: string = 'DCM_RESET_TO_BASECASE';
export const DCM_SET_ATT_LOCKED: string = 'DCM_SET_ATT_LOCKED';
export const DCM_SET_ATT_VALUE: string = 'DCM_SET_ATT_VALUE';
export const DCM_SET_YEARS_ATT_VALUE: string = 'DCM_SET_YEARS_ATT_VALUE';
export const DCM_SET_BASE: string = 'DCM_SET_BASE';
export const DCM_SET_BRAND_EQUITY_VALUE: string = 'DCM_SET_BRAND_EQUITY_VALUE';
export const DCM_SET_LEVEL_ALLOWED: string = 'DCM_SET_LEVEL_ALLOWED';
export const DCM_SET_MESSAGE_ALLOWED: string = 'DCM_SET_MESSAGE_ALLOWED';
export const DCM_SET_MISC_VALUE: string = 'DCM_SET_MISC_VALUE';
export const DCM_SET_OPTIMIZATION_METRIC: string = 'DCM_SET_OPTIMIZATION_METRIC';
export const DCM_SET_PRODUCT_LABEL: string = 'DCM_SET_PRODUCT_LABEL';
export const DCM_TOGGLE_OPTIMIZATION_SELECTION: string = 'DCM_TOGGLE_OPTIMIZATION_SELECTION';
export const DCM_ADD_OPTIMIZATION_SELECTION: string = 'DCM_ADD_OPTIMIZATION_SELECTION';
export const DCM_REMOVE_OPTIMIZATION_SELECTION: string = 'DCM_REMOVE_OPTIMIZATION_SELECTION';
export const DCM_SET_PRODUCT_AVAILABLE: string = 'DCM_SET_PRODUCT_AVAILABLE';
export const DCM_SET_PRODUCT_LOCKED: string = 'DCM_SET_PRODUCT_LOCKED';
export const DCM_SET_PRODUCT_PINNED: string = 'DCM_SET_PRODUCT_PINNED';
export const DCM_SET_SEGMENTATION: string = 'DCM_SET_SEGMENTATION';
export const DCM_SET_VIEW: string = 'DCM_SET_VIEW';


export const MODEL_SET_ACTIVE_CONFIG: string = 'MODEL_SET_ACTIVE_CONFIG'
export const MODEL_CONFIG_INIT: string = 'MODEL_CONFIG_INIT' 
export const MODEL_NEED_UPDATE: string = 'MODEL_NEED_UPDATE';
export const MODEL_APPLY_CONDITIONS: string = 'MODEL_APPLY_CONDITIONS';
export const MODEL_UPDATE_CONFIG: string = 'MODEL_UPDATE_CONFIG';
export const MODEL_CLONE_PRODUCT: string = 'MODEL_CLONE_PRODUCT';   
export const MODEL_DELETE_PRODUCT: string = 'MODEL_DELETE_PRODUCT';
//export const MODEL_RESET: string = 'MODEL_RESET';
export const MODEL_SET_ETAG: string = 'MODEL_SET_ETAG';
export const MODEL_RESET_BRAND_EQUITY_VALUE: string = 'MODEL_RESET_BRAND_EQUITY_VALUE';
export const MODEL_RESET_MESSAGES: string = 'MODEL_RESET_MESSAGES';
export const MODEL_RESET_PERCEPTIONS: string = 'MODEL_RESET_PERCEPTIONS';
export const MODEL_RESET_PRODUCT: string = 'MODEL_RESET_PRODUCT';
export const MODEL_RESET_TO_BASECASE: string = 'MODEL_RESET_TO_BASECASE';
export const MODEL_SET_ATT_LOCKED: string = 'MODEL_SET_ATT_LOCKED';
export const MODEL_SET_ATT_VALUE: string = 'MODEL_SET_ATT_VALUE';
export const MODEL_SET_YEARS_ATT_VALUE: string = 'MODEL_SET_YEARS_ATT_VALUE';
export const MODEL_SET_BASE: string = 'MODEL_SET_BASE';
export const MODEL_SET_BRAND_EQUITY_VALUE: string = 'MODEL_SET_BRAND_EQUITY_VALUE';
export const MODEL_SET_LEVEL_ALLOWED: string = 'MODEL_SET_LEVEL_ALLOWED';
export const MODEL_SET_MESSAGE_ALLOWED: string = 'MODEL_SET_MESSAGE_ALLOWED';
export const MODEL_SET_MISC_VALUE: string = 'MODEL_SET_MISC_VALUE';
export const MODEL_SET_OPTIMIZATION_METRIC: string = 'MODEL_SET_OPTIMIZATION_METRIC';
export const MODEL_SET_PRODUCT_LABEL: string = 'MODEL_SET_PRODUCT_LABEL';
export const MODEL_TOGGLE_OPTIMIZATION_SELECTION: string = 'MODEL_TOGGLE_OPTIMIZATION_SELECTION';
export const MODEL_ADD_OPTIMIZATION_SELECTION: string = 'MODEL_ADD_OPTIMIZATION_SELECTION';
export const MODEL_REMOVE_OPTIMIZATION_SELECTION: string = 'MODEL_REMOVE_OPTIMIZATION_SELECTION';
export const MODEL_SET_PRODUCT_AVAILABLE: string = 'MODEL_SET_PRODUCT_AVAILABLE';
export const MODEL_SET_PRODUCT_LOCKED: string = 'MODEL_SET_PRODUCT_LOCKED';
export const MODEL_SET_PRODUCT_PINNED: string = 'MODEL_SET_PRODUCT_PINNED';
export const MODEL_SET_SEGMENTATION: string = 'MODEL_SET_SEGMENTATION';
export const MODEL_SET_VIEW: string = 'MODEL_SET_VIEW';

export const EXPLORE_SET_SELECTION: string = 'EXPLORE_SET_SELECTION';
export const EXPLORE_SET_COMPETITOR: string = 'EXPLORE_SET_COMPETITOR';
export const EXPLORE_TOGGLE_COMPETITOR: string = 'EXPLORE_TOGGLE_COMPETITOR';
export const EXPLORE_OPTIONS_VISIBLE_SET: string = 'EXPLORE_OPTIONS_VISIBLE_SET';

export const FEED_CHANNELS_REQUEST: string = 'FEED_CHANNELS_REQUEST';
export const FEED_CHANNELS_RECEIVE: string = 'FEED_CHANNELS_RECEIVE';
export const FEED_CHANNEL_SELECT: string = 'FEED_CHANNEL_SELECT';
export const FEED_CHANNEL_DESELECT: string = 'FEED_CHANNEL_DESELECT';
export const FEED_REQUEST: string = 'FEED_REQUEST';
export const FEED_RECEIVE: string = 'FEED_RECEIVE';
export const FEED_POST_REQUEST: string = 'FEED_POST_REQUEST';
export const FEED_POST_RECEIVE: string = 'FEED_POST_RECEIVE';
export const FEED_ITEM_SELECT: string = 'FEED_ITEM_SELECT';
export const FEED_ITEM_REACTION_SET_VALUE: string = 'FEED_ITEM_REACTION_SET_VALUE';
export const FEED_ARCHIVED_SET_VALUE: string = 'FEED_ARCHIVED_SET_VALUE';
export const FEED_SORT_SET: string = 'FEED_SORT_SET';
export const FEED_POST_DELETE_REQUEST: string = 'FEED_POST_DELETE_REQUEST';
export const FEED_POST_COMMENTS: string = 'FEED_POST_COMMENTS';
export const FEED_POST_DELETE_COMMENT: string = 'FEED_POST_DELETE_COMMENT';
export const FEED_POST_EDIT_COMMENT: string = 'FEED_POST_EDIT_COMMENT';

export const FILTER_DESELECT: string = 'FILTER_DESELECT';
export const FILTER_DESELECT_ALL: string = 'FILTER_DESELECT_ALL';
export const FILTER_GROUP_COLLAPSE: string = 'FILTER_GROUP_COLLAPSE';
export const FILTER_GROUP_COLLAPSE_ALL: string = 'FILTER_GROUP_COLLAPSE_ALL';
export const FILTER_GROUP_DESELECT: string = 'FILTERGROUP_DESELECT';
export const FILTER_GROUP_EXPAND: string = 'FILTER_GROUP_EXPAND';
export const FILTER_GROUP_EXPAND_ONLY_SEARCHED_TERM: string = 'FILTER_GROUP_EXPAND_ONLY_SEARCHED_TERM';
export const FILTER_GROUPS_RECEIVE: string = 'FILTER_GROUPS_RECEIVE';
export const FILTER_SAMPLE_SIZES_RECEIVE: string = 'FILTER_SAMPLE_SIZES_RECEIVE';
export const FILTER_SAMPLE_SIZES_REQUEST: string = 'FILTER_SAMPLE_SIZES_REQUEST';
export const FILTER_SELECT: string = 'FILTER_SELECT';
export const FILTER_SET: string = 'FILTER_SET';
export const FILTER_SET_CUSTOM_LABEL: string = 'SET_CUSTOM_LABEL';
export const FILTER_SET_USE_CUSTOM_LABEL: string = 'SET_USE_CUSTOM_LABEL';

export const FORECAST_AUX_RECEIVE: string = 'FORECAST_AUX_RECEIVE';
export const FORECAST_AUX_REQUEST: string = 'FORECAST_AUX_REQUEST';
export const FORECAST_LEVEL_HINTS_RECEIVE: string = 'FORECAST_LEVEL_HINTS_RECEIVE';
export const FORECAST_LEVEL_HINTS_REQUEST: string = 'FORECAST_LEVEL_HINTS_REQUEST';
export const FORECAST_MESSAGE_HINTS_RECEIVE: string = 'FORECAST_RECEIVE_MESSAGE_HINTS';
export const FORECAST_MESSAGE_HINTS_REQUEST: string = 'FORECAST_REQUEST_MESSAGE_HINTS';
export const FORECAST_RECEIVE: string = 'FORECAST_RECEIVE';
export const FORECAST_REQUEST: string = 'FORECAST_REQUEST';

export const FORECAST_SEGMENT_PROFILES_RECEIVE: string = 'FORECAST_SEGMENT_PROFILES_RECEIVE';
export const FORECAST_SEGMENT_PROFILES_REQUEST: string = 'FORECAST_SEGMENT_PROFILES_REQUEST';


export const FORD_FORECAST: string = 'FORD_FORECAST';
export const FORD_FORECAST_REQUEST: string = 'FORD_FORECAST_REQUEST';
export const FORD_UPDATE_FORECAST: string = 'FORD_UPDATE_FORECAST';
export const CLEAR_FORECASTS: string = 'CLEAR_FORECASTS';

export const GENERAL_CARD_INFO_VISIBLE_SET: string = 'GENERAL_CARD_INFO_VISIBLE_SET';
export const GENERAL_SET_CUSTOM_VALUE: string = 'GENERAL_SET_CUSTOM_VALUE';
export const GENERAL_SET_CUSTOM_VALUE2: string = 'GENERAL_SET_CUSTOM_VALUE2';
export const GENERAL_SET_SCROLL_POSITION: string = 'GENERAL_SET_SCROLL_POSITION';

export const MAINHUB_MESSAGE_RECEIVED: string = 'MAINHUB_MESSAGE_RECEIVED';

export const NOTIF_ADD: string = 'NOTIF_ADD';
export const NOTIF_ADD_MULTI: string = 'NOTIF_ADD_MULTI';
export const NOTIF_CLEAR: string = 'NOTIF_CLEAR';
export const NOTIF_MARK_COMPLETE: string = 'NOTIF_MARK_COMPLETE';
export const NOTIF_MARK_READ: string = 'NOTIF_MARK_READ';
export const NOTIF_RECEIVED_RUN_STATUS: string = 'NOTIF_RECEIVED_RUN_STATUS';
export const NOTIFS_CLEAR: string = 'NOTIFS_CLEAR';

export const OPTIM_SET_RUNLIST: string = 'OPTIM_SET_RUNLIST';
export const OPTIM_SET_RUNLIST_DATA: string = 'OPTIM_SET_RUNLIST_DATA';
export const OPTIM_SET_RUNLIST_LOADING: string = 'OPTIM_SET_RUNLIST_LOADING';
export const OPTIM_UPDATE_RUNITEM: string = 'OPTIM_UPDATE_RUNITEM';

export const OPTIMIZE_OPTRUN_RECEIVE: string = 'OPTIMIZE_OPTRUN_RECEIVE';
export const OPTIMIZE_OPTRUN_REQUEST: string = 'OPTIMIZE_OPTRUN_REQUEST';
export const OPTIMIZE_OPTRUN_SCENARIO_RECEIVE: string = 'OPTIMIZE_OPTRUN_SCENARIO_RECEIVE';
export const OPTIMIZE_OPTRUN_SCENARIO_REQUEST: string = 'OPTIMIZE_OPTRUN_SCENARIO_REQUEST';

export const POSTS_POST_CREATED: string = 'POSTS_POST_CREATED';
export const POSTS_POST_DELETED: string = 'POSTS_POST_DELETED';
export const POSTS_POST_RECEIVE: string = 'POSTS_POST_RECEIVE';
export const POSTS_POST_REQUEST: string = 'POSTS_POST_REQUEST';
export const POSTS_RECEIVE: string = 'POSTS_RECEIVE';
export const POSTS_REQUEST: string = 'POSTS_REQUEST';

export const SELECTION_SET: string = 'SELECTION_SET';
export const SELECTION_SET_MULTI: string = 'SELECTION_SET_MULTI';
export const SELECTION_TOGGLE_COMPETITOR: string = 'SELECTION_TOGGLE_COMPETITOR';

export const SELECTION_SELECT: string = 'SELECTION_SELECT';
export const SELECTION_ADD: string = 'SELECTION_ADD';
export const SELECTION_MOVE: string = 'SELECTION_MOVE';
export const SELECTION_REMOVE: string = 'SELECTION_REMOVE';
export const SELECTION_REMOVE_ALL: string = 'SELECTION_REMOVE_ALL';
export const SELECTION_SET_MULTIPLE: string = 'SELECTION_SET_MULTIPLE';

export const STORIES_ADD_TEXTCARD: string = 'STORIES_ADD_TEXTCARD';
export const STORIES_ADD_CARD: string = 'STORIES_ADD_CARD';
export const STORIES_RECEIVE: string = 'STORIES_RECEIVE';
export const STORIES_REMOVE_CARD: string = 'STORIES_REMOVE_CARD';
export const STORIES_REQUEST: string = 'STORIES_REQUEST';
export const STORIES_SELECT: string = 'STORIES_SELECT';
export const STORIES_STORY_CARDS_ADDED: string = 'STORIES_STORY_CARDS_ADDED';
export const STORIES_STORY_CARDS_REMOVED: string = 'STORIES_STORY_CARDS_REMOVED';
export const STORIES_STORY_CARDS_MOVED: string = 'STORIES_STORY_CARDS_MOVED';
export const STORIES_STORY_COMMENT_ADDED: string = 'STORIES_STORY_COMMENT_ADDED';
export const STORIES_STORY_COMMENT_DELETED: string = 'STORIES_STORY_COMMENT_DELETED';
export const STORIES_STORY_COMMENT_PATCHED: string = 'STORIES_STORY_COMMENT_PATCHED';
export const STORIES_STORY_CREATED: string = 'STORIES_STORY_CREATED';
export const STORIES_STORY_DELETED: string = 'STORIES_STORY_DELETED';
export const STORIES_STORY_POSTED: string = 'STORIES_STORY_POSTED';
export const STORIES_STORY_REACTION_SET_VALUE: string = 'STORIES_STORY_REACTION_SET_VALUE';
export const STORIES_STORY_RECEIVE: string = 'STORIES_STORY_RECEIVE';
export const STORIES_STORY_REQUEST: string = 'STORIES_STORY_REQUEST';

export const STUDY_CHANNEL_USERS_RECEIVE: string = 'STUDY_CHANNEL_USERS_RECEIVE';
export const STUDY_CHANNEL_USERS_REQUEST: string = 'STUDY_CHANNEL_USERS_REQUEST';
export const STUDY_PIN_BRAND: string = 'STUDY_PIN_BRAND';
export const STUDY_RECEIVE: string = 'STUDY_RECEIVE';
export const STUDY_REQUEST: string = 'STUDY_REQUEST';
export const STUDY_SET_DEFAULTS: string = 'STUDY_SET_DEFAULTS';
export const STUDY_SET_PRIMARY_SELECTION: string = 'STUDY_SET_PRIMARY_SELECTION';
export const STUDY_SET_WIDGET: string = 'STUDY_SET_WIDGET';
export const STUDY_RESET: string = 'STUDY_RESET';

export const USER_DISABLED: string = 'USER_DISABLED';
export const USER_ENABLE_DISABLE: string = 'USER_ENABLE_DISABLE'; // what is this?
export const USER_ENABLED: string = 'USER_ENABLED';

