import { format } from 'd3-format';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../store/Config.ts';
import * as debounce from '../../../utils/debounce';
import './ContinBox.scss';


export class ContinBox extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            playValue: props.playValue,
            baseValue: props.baseValue,
            error: null
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if( this.props.playValue != nextProps.playValue || this.props.baseValue != nextProps.baseValue ){
            this.setState({
                playValue: nextProps.playValue,
                baseValue: nextProps.baseValue
            })
        }
    }
    
    handleChanged = (ev) => {
        this.setValue(ev.target.value);
    }

    setValue = (newVal) => {
        this.setState({
            playValue: newVal,
            error: this.checkValue(newVal)
        }, () => {
            this.triggerCallback();
        })
    }

    checkValue = (val) => {
        const checkVal = parseFloat(val);
        if(isNaN(checkVal)) return "not a number";
        if( checkVal < this.props.att.min ) return "too low";
        if( checkVal > this.props.att.max ) return "too high";
        return null;
    }

    triggerCallback = debounce.debounce(() => {

        // don't call onChange unless playValue is a valid value, within the correct range
        if(this.checkValue(this.state.playValue)) return;

        if( this.props.onChange ){
            this.props.onChange(this.state.playValue);
        }
    }, debounce.sliderDelay);

    render() {

        let att = this.props.att;

        let attFormat = att.format || '$,.2f';
        let ff = format(attFormat);
        
        return (
            <div className={'contin-box-div' + (this.props.base ? ' base' : '')}>
                <input type="text" className={'contin-box' + (this.state.error ? ' has-error' : '')}
                    disabled={this.props.base}
                    value={this.state.playValue}
                    onChange={this.handleChanged}
                />
                {' '}
                <span className='range'>
                    [Range:{' '} 
                    <span className='value'>{ff(att.min)}</span>
                    &nbsp;...&nbsp;
                    <span className='value'>{ff(att.max)}</span>
                    ]
                </span>
                <span className='base'>
                    {' '}(base = {ff(this.state.baseValue)})
                </span>
                {this.state.error ? <span className='error-message'>{this.state.error}</span> : null}

            </div>
        );
  }
}

ContinBox = connect(
    () => ({}),
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ContinBox);
