import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { Link, withRouter } from 'react-router-dom';
// import { bindActionCreators } from 'redux';
// import { actionCreators as cardActionCreators } from '../../store/Cards.ts';
// import { actionCreators as storiesActionCreators } from '../../store/Stories.ts';
import { CardMenuButton } from '../common/CardMenuButton';
import { NewStoryBox } from '../story/NewStoryBox';
import { exportSVG, exportTable, copyToClipboard } from '../../utils/exportSvg';
import * as _ from 'lodash'
import * as apiService from '../../utils/apiService';
import './Card.scss';
import fileDownload from 'js-file-download';
import * as globals from '../../globals';
import domtoimage from 'dom-to-image-more';
import { saveAs } from 'file-saver';
import * as cardUtil from '../../utils/cardUtil';
// import Info from '../vis/InfoSidePanel'
import Info from '../vis/Info'
import { ContentLoader } from '../explore/ContentLoader';
import { Modal } from '../../components/common/Modal';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';


export function DataCard(props) {

    const study = useSelector(state => state.study)

    const widgetNode = React.createRef();

    const [loaded, setLoaded] = useState(false)
    const [showExportButton, setShowExportButton] = useState(true)
    const [tableLoaded, setTableLoaded] = useState(false)

    // const modalcontent = useRef()

    const [jsonD, setJsonD] = useState({})
    const [hideCopyBtn, setHideCopyBtn] = useState(false)
    const [showTable, setShowTable] = useState(false)
    const [hasTable, setHasTable] = useState(false)
    const [asTable, setAsTable] = useState(false)
    const [showJson, setShowJson] = useState(false)
    const [showInfo, setShowInfo] = useState(false)
    const [canDomExport, setCanDomExport] = useState(false)
    const [hasInfo, setHasInfo] = useState(false)
    // const [modalOpen, setModalOpen] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [resizable, setResizable] = useState(props.repoEntry?.props?.resizable || false)
    const [showAsInsightCard, setShowAsInsightCard] = useState(false)


    const exportHandler = useRef({
        hasTable: false,
        asTable: null,
        hasInfo: false,
        refresh: () => {

            const entry = props.repoEntry;
            const instanceId = props.instanceIndex || 'x';
            const rootId = `data-card-${entry?.uid}-${instanceId}`;

            let disableDomExport = props?.data?.disableDOMExport ? true : false;

            // force disable if showing as table
            if (props.data?.visual === 'asTable') {
                disableDomExport = true;
            }

            // force disable if viewing a table
            if (showTable) {
                disableDomExport = true;
            }
                
            setHasTable(exportHandler.hasTable ? true : false)
            setAsTable(exportHandler.asTable ? true : false)
            setCanDomExport(!disableDomExport && document.getElementById(rootId)?.querySelector('#export-root') ? true : false)
            setHasInfo(exportHandler.hasInfo)
                
        }
    }).current

     useEffect(() => {
        exportHandler.refresh()
    }, [props, showTable, exportHandler.hasTable, exportHandler.asTable, exportHandler.hasInfo])


    if (props.optionsContext) {
        props.optionsContext.renderOptions = renderOptionsCaller;
    }

    // useEffect(() => { 
    //     modalcontent.current =
    //                 <ContentLoader
    //                     key={entry?.uid}
    //                     id={entry?.uid}
    //                     reloadParentItem={false}
    //                     edit={true}
    //                     restore={false}
    //                     restoredItem={false}
    //                     pageLevel={true}
    //                 />
    // },[])

           
    const renderOptionsCaller = () => {
        if (renderOptions) {
            return renderOptions();
        }
        else
            return <div>card doesn't support options</div>;

    }

    // used by render
    // returns a state object that contains:
    //  saveState from the widget if any
    //  props that are 'relevant' meaning that they exist in widget.inheritOptions
    const getRelevantStateInfo = () => {
        let state = {};

        let saveInfo = (null);
        if (!saveInfo) {
            const saveState = (null) || {};
            saveInfo = {
                state: saveState
            };
        }
        
        // save settings and props
        props.repoEntry?.widget?.inheritOptions?.forEach(key => {
            state[key] = props[key] || saveInfo?.state?.[key];
        });

        return saveInfo;
    }

    const getScenario = () => {
        const config = study.config;
        let scenario = {
            ...config
        }
        delete (scenario.productDefs);
        delete (scenario.brandEquitiesDef);
        return scenario;
    }

    // used to create state that is sent to API .saveCard function
    const getSaveInfoAndOptionsForSaving = () => {

        // get state from widget entry if any
        let saveInfo = (null);
        if (!saveInfo) {
            const saveState = (null) || {};
            saveInfo = {
                state: saveState
            };
        }
        if (props.widgetId) {
            saveInfo.widget_uid = props.widgetId;
        }

        if (!saveInfo.state) {
            saveInfo.state = {};
        }

        // get scenario from widget entry if any
        if (props.repoEntry?.widget?.scenarioDriven) {
            saveInfo.scenario = getScenario();
            //console.log('state1.scenario', state1.scenario);
        }
        
        // get inherited props that are 'relevant' meaning that they exist in widget.inheritOptions
        props.repoEntry?.widget?.inheritOptions?.forEach(key => {
            saveInfo.state[key] = props[key];
        });

        //console.log('state for saving', state1);
        //return state1;
        return saveInfo;
    }

   



    const addToNewStory = () => {

        const studyId = props.studyId || props.match?.params?.studyId;
        const auth = props.auth;

        const box = <NewStoryBox
            closeRequested={() => window.topBar.hideMenu()}
            okayClicked={async (data) => {

                // get card's save state
                const saveInfo = getSaveInfoAndOptionsForSaving();
                if (!saveInfo) {
                    console.error('saveInfo is null');
                    return;
                }

                const newCardId = await cardUtil.saveCard(studyId, auth, null, saveInfo);
                if (!newCardId) {
                    console.error('newCardId not returned');
                    return;
                }

                // create a new story with that card
                const storyData = {
                    label: data.label || 'My story',
                    headline: null,
                    content: null,
                    question: null,
                    cards: [newCardId]
                }
                props.createStory(storyData, (newStory) => {

                    // success
                    // navigate to the story                    
                    props.history.push(`/study/${studyId}/edit-story/${newStory.uid}`);
                    window.topBar.hideMenu();
                });

            }}
        />

        window.topBar.openDynamicMenu(box);

    }


    const addToStory = async (storyId) => {

        const studyId = props.studyId || props.match?.params?.studyId;
        let auth = props.auth;

        let snackId = parseInt(+new Date().getMilliseconds() + Math.random().toFixed())
        topBar.enqueueSnack({
            id: snackId,
            message: 'Adding Card',
            to: 'never',
            success: false
        })

        // get card's save state
        const saveInfo = getSaveInfoAndOptionsForSaving();
        if (!saveInfo) {
            console.error('saveInfo is null');
            return;
        }

        const newCardId = await cardUtil.saveCard(studyId, auth, null, saveInfo);
        if (!newCardId) {
            console.error('newCardId not returned');
            return;
        }

        props.addCardsToStory(storyId, [newCardId], _ => {
            topBar.updateSnack(snackId, {
                message: 'Card added',
                success: true,
                to: 10000,
                button: {
                    label: 'Go to story',
                    onClick: _ => {
                        // navigate to the story
                        props.history.push(`/study/${studyId}/edit-story/${storyId}`);
                        topBar.dequeueSnack(snackId)
                    }
                },
            })
        });
        
    }

    // BAD NOT USED
    const exportSvg = _ => {
        let fileName = widgetNode.current.querySelector('.widget-header')
        let content = exportSVG(widgetNode.current)
        let url = `data:image/svg+xml,${encodeURIComponent(content)}`
        let link = document.createElement('a')
        link.href = url
        link.download = `${fileName.querySelector('.title').innerText}.svg`
        link.click()
        link.remove()
    }

    const exportAsExcel = _ => {
        exportTable(jsonD, widgetNode)
    }

    const copyToClipboard = _ => {
        copyToClipboard(jsonD)
    }

    const exportAsTable = () => {
        // get table data
        if (exportHandler.asTable) {
            
            const body = exportHandler.asTable();

            apiService.aPost(props.auth, `${globals.apiRoot}/study/${studyId}/export/table`, body)
                .then((response) => {
                    if (!response.ok) {
                        console.error(response);
                        throw "export failed";
                    }
                    return response.arrayBuffer();
                    // const buf = response.arrayBuffer();
                    // return buf;
                }).then((result) => {
                    const outputFilename = 'BO Table Export.xlsx';
                    fileDownload(result, outputFilename);
                })
                .catch(function (error) {
                    console.error(error);
                    alert('Problem with export. See console log.');
                });
        }
        else {
            alert('No table data to export')
        }
        
    }

    const getLastNestedQuerySelector = (node, query, fallback = null) => {
        let child = node?.querySelector(query);
        if (child) return getLastNestedQuerySelector(child, query, child);
        return fallback;
    }

    const exportDOMNode = (rootId, type) => {

        let parentDOM = document.getElementById(rootId);
        let node = getLastNestedQuerySelector(parentDOM, '.widget-body')
        
        if (!node) {
            alert('nothing to export');
            return null;
        }

        if (type === 'png') {
            domtoimage.toBlob(node, {
                style: {
                    'background-color': '#ffffff',
                    'transform': 'scale(2.0)',
                    'transform-origin': '0 0'
                },
                filter: (el) => !(el.dataset && el.dataset['hideexport'] === '1'),
                width: node.scrollWidth * 2,
                height: node.scrollHeight * 2
            })
                .then(function (blob) {
                    saveAs(blob, 'BO card export.png');
                });
        }
        if (type === 'jpg') {
            domtoimage.toJpeg(node, {
                style: {
                    'background-color': '#ffffff',
                    'transform': 'scale(2.0)',
                    'transform-origin': '0 0'
                },
                filter: (el) => !(el.dataset && el.dataset['hideexport'] === '1'),
                width: node.scrollWidth * 2,
                height: node.scrollHeight * 2
            })
                .then(function (blob) {
                    console.log('blob', blob);
                    saveAs(blob, 'BO card export.jpg');
                });
        }
        else if (type === 'svg') {
            domtoimage.toSvg(node, {
                style: {
                    'background-color': '#ffffff',
                    'transform': 'scale(2.0)',
                    'transform-origin': '0 0'
                },
                filter: (el) => !(el.dataset && el.dataset['hideexport'] === '1'),
                width: node.scrollWidth * 2,
                height: node.scrollHeight * 2
            })
                .then(function (blob) {
                    saveAs(blob, 'BO card export.svg');
                });
        }

    }

    // exportHandler;
    
    // repo entry: describes what widget to use and what props to give the widget
    const entry = props.repoEntry;
    const hide_header = entry?.props?.data?.hideHeader;
    //show_footer = if card is carousel and you select useArrowIcons or if you tell dcard to not hidefooter, footer will show.
    const remove_borders = entry.props.data?.removeBorders;
    const border_radius = entry.props.data?.borderRadius;
    const hide_menu = entry.props.data?.hideMenu;
    const add_shadow = entry?.props?.data?.shadow;
    const useArrows = props?.carouselInfo?.useArrowIcons;

    // whether or not this card is being shown isolated and enlarged, on the card page
    const isolated = props.isolated;

    // user's stories and drafts, to be used in the "Add card to..." dropdown menu.
    const userStories = props.stories || {};
    const storyDict = userStories.dict || {};
    const drafts = (userStories.drafts || []).map(draftId => storyDict[draftId]);


    // widget gets inherited state via props
    // widget gets remaining state from stateContext (won't overwrite state from props)
    // widget gets props from repo entry props

    const stateInfo = getRelevantStateInfo();

    const widgetContext = {
        ...(stateInfo?.state),
        ...(entry.props),
        debug: props.debug
    };


    // the react widget to use
    const Widget = entry.widget;
    const studyId = props.studyId || props.match?.params?.studyId;
    const expandRoot = props.expandRoot || `/study/${studyId}/`;
    const expandPath = props.expandPath ? '?explore_path=' + props.expandPath : '';

    let exportOptions = [];
    if (asTable) {
        exportOptions.push({ label: 'Excel table', onClick: exportAsTable })
    }

    const instanceId = props.instanceIndex || 'x';
    const rootId = `data-card-${entry?.uid}-${instanceId}`;
    if (canDomExport) {
        exportOptions.push({ label: 'Image (PNG)', onClick: () => exportDOMNode(rootId, 'png') });
        exportOptions.push({ label: 'Image (JPEG)', onClick: () => exportDOMNode(rootId, 'jpg') });
        exportOptions.push({ label: 'Scalable Vector Graphics (SVG)', onClick: () => exportDOMNode(rootId, 'svg') });
    }

    const feedModule = props.modules?.find(m => m.name === 'feed');
    const allowStory = feedModule && !feedModule.disabled;
    const hideAddMenu = props.data?.hideAddMenu;

    let carouselContent = null;
    if (props.carouselInfo) {
        const ci = props.carouselInfo;
        let dots = [];
            
        for (let i = 0; i < ci.nbItems; i++) {
            const dotSelected = ci.selIndex == i;
            if (dotSelected) {
                dots.push(<i key={i} className='fas fa-circle dot' />);
            }
            else {
                dots.push(<i key={i} className='fal fa-circle dot has-link' onClick={() => ci.setSelIndex(i)} />);
            }
        }

        carouselContent = <span className='carousel'>
            {dots}
        </span>
    }

    const infoClickHandler = () => {
        setShowInfo(!showInfo)
        setShowTable(false)
    }
    
    const carouselArrowHandler = (direction) => {
        let ci = props.carouselInfo
        
        if (direction === 'right') {
            ci.arrowDirection(1)
            // props.carouselInfo.setSelIndex(prevIndex => (prevIndex + 1 >= ci.nbItems.length ? 0 : prevIndex + 1))
        }
        else {
            ci.arrowDirection(-1)
            //props.carouselInfo.setSelIndex(prevIndex => (prevIndex - 1 < 0 ? ci.nbItems.length - 1 : prevIndex - 1))
            // props.carouselInfo.setSelIndex(ci?.selIndex - 1)
        }
            
    }

                   
        

    const getCarouselOnly = () => {
        return <div className='caption-bar'>
            {props.hideControls ? null :
                <span className='buttons'>
                    {!props.carouselInfo.useArrowIcons ? carouselContent : null}
                </span>
            }
        </div>
    }

    const getFooter = () => {
        return <div className='footer' style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button className='btn' role='button' aria-label='arrow left' onClick={() => carouselArrowHandler('left')} >
                    <i className='fas fa-arrow-left' style={{ fontSize: 12 }} />
                </button>

                <button className='btn' role='button' aria-label='arrow right' onClick={() => carouselArrowHandler('right')}>
                    <i className='fas fa-arrow-right' style={{ fontSize: 12 }} />
                </button>
            </div>
        </div>
    }
        

    const getCaptionBar = (hideopenIcon = false) => {
        return <div className='caption-bar'>
            {props.hideControls ? null :
                <span className='buttons'>

                    {!props.carouselInfo?.useArrowIcons ? carouselContent : null}

                    {hasInfo ?
                        <button className='btn' title='View card info' role='button' aria-label='view card info' onClick={() => infoClickHandler()}>
                            {
                                showInfo ? <i className="fas fa-info" style={{ color: '#000000', fontSize: 12 }} />
                                    : <i className="fas fa-info" style={{ xcolor: '#afafaf', fontSize: 12 }} />
                            }
                        </button> : null}

                    {props.editMode ? <button className='btn' title='View as insight card' role='button' aria-label='view an insight card' onClick={() => setShowAsInsightCard(!showAsInsightCard)}>
                        {showAsInsightCard ? <i className="fas fa-book-open" style={{ color: '#000000' }} />
                            : <i className="fal fa-book" style={{ xcolor: '#afafaf' }} />}
                    </button> : null}

                    {props.editMode ? <button className='btn' title='View json' onClick={() => { setShowJson(!showJson); setShowTable(false); }}>
                        {showJson ? <i className="fal fa-brackets-curly" style={{ color: '#000000' }} />
                            : <i className="fal fa-brackets-curly" style={{ xcolor: '#afafaf' }} />}
                    </button> : null}

                    {hasTable ? <button className='btn' title='Show as table' role='button' aria-label='show data as tabular' onClick={() => { setShowTable(!showTable); setShowJson(false); }}>
                        {showTable ? <i className="fal fa-table" style={{ color: '#000000' }} />
                            : <i className="fal fa-table" style={{ xcolor: '#afafaf' }} />}
                                
                    </button> : null}

                    {exportOptions.length > 0 ? <CardMenuButton caption={<i className='fal fa-arrow-to-bottom' />} title={'Export'}
                        items={[
                            { type: 'section', label: 'Export as:' },
                            ...exportOptions.map(x => x)
                            //{ label: 'Excel table', onClick: exportAsTable },
                            // { label: 'PNG', onClick: () => null },
                            // { type: 'section', label: 'Share card:' },
                            // { label: 'Copy link', onClick: () => null },
                        ]}
                    /> : null}

                    {/* {
                                loaded && modalOpen
                                    ? <button className='btn' title='Export as SVG' onClick={exportSvg}>
                                        <i className="fal fa-file-export"></i>
                                    </button> : ''
                            }
                            {
                                tableLoaded && modalOpen
                                    ? <button className='btn' title='Export as Excel Sheet' onClick={exportAsExcel}>
                                        <i className="fal fa-file-export"></i>
                                    </button> : ''
                            }
                            {
                                modalOpen && !hideCopyBtn
                                    ? <button className='btn' title='Copy to clipboard' onClick={copyToClipboard}>
                                        <i className="fal fa-copy"></i>
                                    </button> : ''
                            } */}

                    {hideAddMenu ? null :
                        <CardMenuButton caption={<i className='far fa-plus' />} title={'Add to...'}
                            items={[
                                { type: 'section', label: 'Add card to:' },
                                { label: 'New story...', onClick: addToNewStory, disabled: !allowStory },
                                { type: 'separator' },
                                { type: 'section', label: 'Story drafts:', delete: (drafts.length === 0) },
                                ...drafts.map(story => {
                                    const storyId = story?.uid;
                                    return {
                                        label: story?.label || 'unnamed story',
                                        onClick: () => addToStory(storyId)
                                    };
                                })
                            ].filter(x => !x.delete)}
                        />}
                            

                    {
                        //modal
                        hideopenIcon ? null :
                            <i className="btn fal fa-expand" onClick={() => setShowModal(true)} role='button' aria-label='open modal'></i>
                    }
                </span>
            }
        </div>
    }

    const getContent = (modalopen = null) => {
            
        return <div className='content' ref={widgetNode}>                        
            {(showInfo && props.className === 'in-div auto-height') ? <Info htmlText={entry?.props?.description} changeInfo={setInfoState} />
                :

                <Widget
                    loaded={(e, hide = false) => {
                        if (e) {
                            // sometimes widgetNode.current is null,
                            // so, i need to set it's timeout of 500ms to show the button.
                            // console.log(widgetNode.current)
                            setTimeout(() => {
                                if (widgetNode.current?.querySelector('.widget-body').querySelector('svg') && !hide)
                                    setLoaded(true)
                                if (widgetNode.current?.querySelector('.widget-body').querySelector('table'))
                                    setTableLoaded(true)
                            }, 500)
                        }
                    }}
                    getJSONData={(data, hide = false) => {
                        setJsonD(data)
                        if (hideCopyBtn !== hide)
                            setHideCopyBtn(hide)
                    }}
                    exportHandler={exportHandler}
                    getExportData={fn => exportFn = fn}
                    stateContext={DataCard}
                    {...widgetContext}
                    inDataCard={true}
                    entry={entry}
                    showTable={showTable}
                    showJson={showJson}
                    showAsInsightCard={showAsInsightCard}
                    location={props.location}
                    expandLink={`${expandRoot}/card/${props.widgetId}${expandPath}`}
                    instanceContext={props.instanceContext}
                    showLoadingIcon={props.showLoadingIcon}
                    modalOpen={modalopen}
                />
            }
        </div>
    }
    
    const OpenModal = () => {
        const thecontent = <div id={rootId} className={(hide_header ? 'card hideheader mode-data' : 'card mode-data ') + (showInfo && heightAuto ? 'no-grid ' : '') + ' open ' + (props.relatedCard ? ' related-card ' : '') + (props.className ? props.className : '')}>
            {hide_menu ? null : getCaptionBar(true)}
            {getContent(true)}
        </div>
        return <Modal close={CloseModal} content={thecontent} />
    }

    const CloseModal = () => {
        setShowModal(false)
    }

    const setInfoState = (val) => {
        setShowInfo(val)
    }
    
    const getResizeIcon = () => {
        return <span className={resizable ? 'resizeIcon' : ''}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36">
                <circle cx="12" cy="24" r="1.5" class="clr-i-outline clr-i-outline-path-1" fill="#afafaf" />
                <circle cx="18" cy="24" r="1.5" class="clr-i-outline clr-i-outline-path-2" fill="#afafaf" />
                <circle cx="18" cy="18" r="1.5" class="clr-i-outline clr-i-outline-path-3" fill="#afafaf" />
                <circle cx="24" cy="12" r="1.5" class="clr-i-outline clr-i-outline-path-4" fill="#afafaf" />
                <circle cx="24" cy="24" r="1.5" class="clr-i-outline clr-i-outline-path-5" fill="#afafaf" />
                <circle cx="24" cy="18" r="1.5" class="clr-i-outline clr-i-outline-path-6" fill="#afafaf" />
            </svg >
        </span>
    }
    

        
    let heightAuto = props.className === 'in-div auto-height';

    return heightAuto ? <>
            {showModal ? OpenModal() : null}
            <div id={rootId} className={(hide_header ? 'card hideheader mode-data' : 'card mode-data') + (useArrows ? ' hasFooter': '') + (border_radius ? ' borderRadius ': '') + (add_shadow ? ' shadow ' : '') + (remove_borders ? ' remove-borders ' : '') + (showInfo && heightAuto ? ' no-grid ' : '') + (showModal ? ' open ' : '') + (props.relatedCard ? ' related-card' : '') + (props.className ? ' ' + props.className : '')}>
                {(showInfo && heightAuto) ? '' : (hide_menu && props.carouselInfo) ? getCarouselOnly() : hide_menu ? null : getCaptionBar()}
                {getContent()}
                { useArrows ? getFooter() : null}
                {resizable ? getResizeIcon() : ''}
            </div>
        </>
        :
        <>
            {showModal ? OpenModal() : null}
            <div className={'thecard ' + props.className} >
                <div id={rootId} className={(hide_header ? 'card hideheader mode-data' : 'card mode-data') + (border_radius ? ' borderRadius ': '') + (useArrows ? ' hasFooter': '') + (add_shadow ? ' shadow ' : '') + (remove_borders ? ' remove-borders ' : '') + (showInfo ? 'flip-front ' : '') + ' card-front ' + (showModal ? ' open ' : '') + (props.relatedCard ? ' related-card' : '') + (props.className ? ' ' + props.className : '')}>
                    {(hide_menu && props.carouselInfo) ? getCarouselOnly() : hide_menu ? null : getCaptionBar()}
                    {getContent()}
                    { useArrows ? getFooter() : null}
                </div>
                <div id={rootId} className={(hide_header ? 'card hideheader mode-data' : 'card mode-data') +  (border_radius ? ' borderRadius ': '') + (showInfo ? ' flip-back ' : '') + ' card-back ' + (remove_borders ? ' remove-borders ' : '') + (showModal ? ' open ' : '') + (showInfo ? ' no-grid ' : '') + (props.relatedCard ? ' related-card' : '') + (props.className ? ' ' + props.className : '')}>
                    <Info htmlText={entry?.props?.description} changeInfo={setInfoState} useAbsolute='true' />
                </div>
                {resizable ? getResizeIcon() : ''}
            </div>
        </>
}







//BLUE SIDEBAR CODE            
//     return <>
//                 {showModal ? OpenModal() : null}
//                     <div id={rootId}  className={(hide_header ? 'card hideheader mode-data' : 'card mode-data ') + (showInfo && hasInfo ? '' : ' no-sidebar ') + (add_shadow ? ' shadow ':'') + (remove_borders ? ' remove-borders ' : '') +  (showModal ? 'open ' : 'not-open') + (props.relatedCard ? ' related-card' : '') + (props.className ? ' ' + props.className : '')}>
//                     {(hide_menu && props.carouselInfo) ? getCarouselOnly() : hide_menu ? null : getCaptionBar()}
//                     {showInfo && hasInfo ?
//                         <div className='sidebar'>
//                             <Info htmlText={entry?.props?.description} changeInfo={setInfoState} useAbsolute='true' />
//                         </div>
//                         : null
//                     }
//                         {getContent()}
//                         { props.carouselInfo?.useArrowIcons ? getFooter() : null}
//                     </div>
//             </>
        
    

// }




