import * as globals from '../globals';
import * as apiService from './apiService';
import { getMeta } from '../utils/metaUtils'
import { guid } from "../utils/guid"


//need to update - study to dcm.configs...
export const aggregate = (data, level, study) => {
    if( !data ) return null;

    const config = study.config;

    // find next higher
    const aggLevels = [
        ...study.selectionHierarchy,
        { name: 'product', label: study.productLabel }
    ];
    const sectionName = aggLevels[0].name;
    
    if( level === 'product' ){
        return config.productSlots.map(slot => ({
            uid: slot.uid,
            product: slot.uid,
            label: slot.label,
            section: slot.play[sectionName],
            data: data[slot.uid]
        }));
    }

    // find level att
    const att = config.productDefs.default.atts.find(a => a.name === level);
    if( !att ) return null;

    const levels = att.levels;
    if( !levels ) return null;

    let rows = levels.map(lev => {

        // find products that match the selections level
        const aggProducts = config.productSlots.filter(slot => slot.play[level] == lev.value);

        let slot = aggProducts.length > 0 ? aggProducts[0] : null;
        
        let retVal = {
            uid: `${att.name}:${lev.value}`,
            [att.name]: lev.value,
            label: lev.label,
            section: slot ? slot.play[sectionName] : null,
            data: { play: {}, base: {} }
        };
        globals.metrics.forEach(metric => {
            let play_accum = null;
            let base_accum = null;
            aggProducts.forEach(p => {
                let play_val = data[p.uid] && data[p.uid].play ? data[p.uid].play[metric.key] : null;
                let base_val = data[p.uid] && data[p.uid].base ? data[p.uid].base[metric.key] : null;
                if( metric.aggMethod === 'sum' ){
                    if( play_val ) play_accum = (play_accum || 0) + play_val;
                    if( base_val ) base_accum = (base_accum || 0) + base_val;
                }
                else if( metric.aggMethod === 'max' ){
                    if( play_val ) play_accum = play_accum ? Math.max(play_accum, play_val) : play_val;
                    if( base_val ) base_accum = base_accum ? Math.max(base_accum, base_val) : base_val;
                }
            })
            retVal.data.play[metric.key] = play_accum;
            retVal.data.base[metric.key] = base_accum;
        });
        return retVal;


    });
    return rows;
    
}


export const computeModel = async (studyId, auth, payload, etag) => {
    
     const config = payload?.config    
    const filter = payload?.filter || "1==1"
    const brandvalues = payload.brandvalues || []
    const activeConfig = payload?.activeConfig 


    const query = {
        studyId,            
        uFilter: filter.syntax, 
	    uFilterName: filter.name, 
        config,   
        modelInfo: payload?.modelInfo,
        brandvalues,
        //etag: etag ?? guid()
        playHash: etag
    }

    // console.log('FILTER SYNTAX', query.uFilter);
    // console.log('query', query);

    const url = `${globals.apiRoot}/compute/model`
    try {
        const response = await apiService.aPost(auth, url, query)
        // console.log('response', response)
        if (!response.ok) {
            console.error(response)
            return null
        }
        const results = await response.json()
        //return results as 2024:{ suv:{prod1:{}}
        
        return {
            [activeConfig]: {
                ...results
            }
        }
        //return value
    } catch (err) {
        console.error("computeModel error", err)
        return null
    }
}

export const computeFordModel = async (studyId, auth, payload) => {
    
    //payload needs to have playHash
    const config = payload?.config
    // const filter = payload?.filter || "1==1"
    const brandvalues = payload?.brandvalues || []
    // const activeConfig = payload?.activeConfig

    let modelFilters = payload?.filter
    if (!Array.isArray(modelFilters)) {
        modelFilters = [modelFilters]
    }

    // console.log('modelfilters', modelFilters);

    // const selfilter = filterDatasetObject//filterDatasetId ? filterStore.selected?.[filterDatasetId] : filterStore.selectedFilter
    const filterName = payload?.filter?.name
    // console.log('filterName', filterName);

    const _query = {
        studyId,
        // uFilter: filter.syntax, 
        uFilterName: filterName,
        config,
        modelInfo: payload?.modelInfo,
        brandvalues,
        playHash: payload?.playHash// ?? guid()
    }

    // console.log('FILTER SYNTAX', query.uFilter);
    //console.log('query', query);

    const url = `${globals.apiRoot}/compute/model`

    try {

        let modelData = null

        for (let modelFilter of modelFilters) {

            const query = {
                ..._query,
                uFilter: modelFilter.syntax
            }

            const response = await apiService.aPost(auth, url, query)
            let data = await response.json()

            data = data?.[filterName]

            if (data && modelFilter.productIds?.length) {
                const newData = {}
                for (let uid of modelFilter.productIds) {
                    newData[uid] = data[uid]
                }
                data = newData
            }

            if (data) {
                modelData = {
                    ...modelData,
                    ...data
                }
            }
            // console.log('forecast response:', resp);			
			return modelData	
        }
    }
    catch (err) {
        console.error("Error during forecast computation:", err)
        return null
    }
}



// try {
//         const response = await apiService.aPost(auth, url, query)
//         // console.log('response', response)
//         if (!response.ok) {
//             console.error(response)
//             return null
//         }
//         const results = await response.json()
//         //return results as 2024:{ suv:{prod1:{}}
        
//         return {
//             [activeConfig]: {
//                 ...results
//             }
//         }
//         //return value
//     } catch (err) {
//         console.error("computeModel error", err)
//         return null
//     }