import * as apiService from './apiService';
import * as globals from '../globals';


export const constructAutoSelect = (arr, selectionTypes) => {

    // convert all entries into the form { from: ..., to: ... }
    let autoSelect = arr?.map(e => parseAutoSelectEntry(e)) || [];

    // add meta name to each
    autoSelect = autoSelect.map(e => {
        return {
            ...e,
            meta: selectionTypes[e?.to]?.meta
        }
    })

    return autoSelect;

}

const parseAutoSelectEntry = (entry) => {
    if (typeof entry === 'string')
        return { from: entry, to: entry };
    else
        return entry;
}



export const getMeta = async (studyId, auth, key) => {

    let res = await apiService.aGet(auth, `${globals.apiRoot}/study/${studyId}/meta/key/${key}`);
    if (res.ok) {
        res = await res.json()
    }
    else {
        return null
        // throw `error getting meta [${key}]: ` + res.statusText
    }
    if (!res) //throw `meta [${key}] response blank`;
        return null

    if (res.type === 'json') {
        return res.value;
    }
    else {
        throw `unknown type for meta [${key}]: ` + res.type
    }
}

export const getAllMeta = async (studyId, auth) => {
    let res = await apiService.aGet(auth, `${globals.apiRoot}/study/${studyId}/meta/all`);
    if (res.ok) {
        return  await res.json();

    }
    else {
        throw `error getting meta [${key}]: ` + res.statusText
    }
    if (!res) throw `meta [${key}] response blank`;

    if (res.type === 'json') {
        return res.value;
    }
    else {
        throw `unknown type for meta [${key}]: ` + res.type
    }

    
}

export const getMetas = async (studyId, auth, _keys) => {
    let keys = _keys.filter(x => x); // remove null or undefined
    let obj = {};
    for (let i in keys) {
        const key = keys[i]
        obj[key] = await getMeta(studyId, auth, key);
    }
    return obj;
}

export const setMeta = async (studyId, auth, key, value) => {
            const body = {
                        licValue: value
                    };
            const url = `${globals.apiRoot}/study/${studyId}/lic-meta/key/${key}`;
            const res = await apiService.aPost(auth, url, body);
            return res?.ok ? true : false;
}