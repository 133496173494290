import * as React from "react";
import { useEffect } from 'react';
import { useSelector } from "react-redux";
import "./FilterBlock.scss";

export const FilterBlock = (props) => {
	const filters = useSelector((state) => state.filter);
	const filter = props.selection || filters.selectedFilter;


	useEffect(() => {
            props?.setLayoutReady?.(true);
    }, []);
	// let filter = props.selectedFilter || {
	//     label: 'error: filter not set'
	// };

	return (
		<div
			className={
				"filter-block standalone" + (props.disabled ? " disabled" : "")
			}
			style={props.style}
			onClick={(ev) => {
				if (!props.disabled) {
					window.topBar.openMenu("filter");
				}
			}}>
			<div className="logo-container">
				<i className="fal fa-users glyph" />
			</div>

			<div className="text-container" title={filter.label}>
				<span className="text1">{filter.label}</span>
				<span className="text2">Filter</span>
			</div>
		</div>
	);
};

// FilterChooser = withRouter(
//     connect(
//         (state, ownProps) => ownProps.selection ? { selectedFilter: ownProps.selection } : state.filter,
//         dispatch =>
//             bindActionCreators(actionCreators, dispatch)
//     )(FilterChooser)
// );
