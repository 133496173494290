import * as React from 'react';
import { format as d3Format } from 'd3-format';
import { Cell, Label, LabelList, Text, Legend, ResponsiveContainer, Pie, PieChart, Tooltip } from 'recharts';
import CustomLegend from "../common/CustomLegend";

const DPieChart = ({ compute, renderConfig }) => {

  const colors = renderConfig?.colors || [
    "#4473c5", "#ed7d30", "#a5a5a5", "#fec001", "#5b9cd6", "#70ad45", "#264477", "#9d480f",
    "#636363", "#987300", "#255f90", "#44682a", "#688fd0", "#f19659", "#b7b7b7", "#ffcc33",
    "#7bafde", "#8cc168", "#3359a0", "#d16011", "#838383", "#cc9901", "#317dc2", "#5a8939",
    "#8fabdd", "#f4b184", "#c9c9c9", "#fed965", "#9cc3e7", "#a9d08c", "#203864", "#833b0c",
    "#525252", "#7e6000", "#1f4e78", "#375624", "#7c9cd5", "#f2a370", "#c0c0c0", "#fdd44d",
    "#8bb9e2", "#9ac97a", "#2b4f88", "#b85310", "#747474"
  ];

  return (
    <ResponsiveContainer {...renderConfig?.container}>
      <PieChart>
        <Pie
          isAnimationActive={renderConfig?.pie?.animate || true}
          data={compute?.data}
          dataKey={renderConfig?.pie?.dataKey || 'value'}
          innerRadius={renderConfig?.pie?.innerRadius || 90}
          outerRadius={renderConfig?.pie?.outerRadius || 120}
          label
          {...renderConfig?.pie?.props}
        >
          {renderConfig?.centerLabel?.value && (
            <Label value={renderConfig?.label?.value} fontSize={renderConfig?.label?.fontSize} position={renderConfig?.label?.position} />
          )}
          {compute?.data?.map((_, index) => (<Cell key={index} fill={colors[index % colors.length]} />))}
        </Pie>
        {renderConfig?.legend && (
          <Legend {...renderConfig?.legend} content={<CustomLegend />} />
        )}
        <Tooltip {...renderConfig?.tooltip} />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default DPieChart