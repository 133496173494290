import * as React from 'react';
import pptxgen  from "pptxgenjs";


export function PowerPointCreator(props) {

    const section = props?.sectionName || '';
    const style = props?.buttonStyle;
    const lineData = props?.series;

    return <button className='btn rounded inline-block' style={style} onClick={() => DOMParsingWork(section)}>
                Create Powerpoint
           </button>

}


const DOMParsingWork = ((section, series) => {

    //Create a new Presentation
    let pres = new pptxgen();

    //get all slides
    const slides = document.querySelectorAll('.slide')

    slides.forEach((slide, i) => 
    {
        // console.log('slide: ', i + 1)
        let newSlide = pres?.addSlide();

        //add background image
        const bg = slide.querySelector('.backgroundImage')
        newSlide.addImage({ path: bg?.getAttribute('src'), h:'100%', w:'100%' });

        //add images
        const images = slide.querySelectorAll('.image');

        images?.forEach(img => {
          let style = img?.getAttribute('data-style')

          if(style){
            const imgStyle =  JSON.parse(style)
            newSlide.addImage({ path: img.getAttribute('src'), ...imgStyle });
          }
        })


        //add textboxes
        const tboxes = slide.querySelectorAll('.textbox');

        tboxes?.forEach(tbox => {
          let style = tbox?.getAttribute('data-style')

          if(style){
            const tboxStyle = JSON.parse( style)
            console.log('tboxStyle', tboxStyle)
            newSlide.addText(tbox?.innerHTML, {...tboxStyle});
          }
        })


        //add shapes
        const shapes = slide.querySelectorAll('.shape');

        shapes?.forEach(shape => 
        {
          let style = shape?.getAttribute('data-style')
          let datatype = shape?.getAttribute('data-type') 

          if(style && datatype){
            const shapeStyle = JSON.parse( style)
            const presShape = datatype === 'line' ?  pres.ShapeType.line : pres.shapeType.line

            if(presShape)
              newSlide.addShape(presShape, shapeStyle);
          }
        })


        //add charts
        const charts = slide.querySelectorAll('.chart');

        charts?.forEach((chart,idx) => {
          let style = chart?.getAttribute('data-style')
          let chartType = chart?.getAttribute('data-type')

          if(style && chartType){
            const chartStyle = JSON.parse(style)

        //   console.log('chartStyle', chartStyle)
        //   console.log('chartType', chartType)

            createBarWithLineChart(cache?.series[idx], pres, newSlide, chartStyle, chartType)
          }

        })

    })

   //Save the Presentation
    pres.writeFile({ fileName: `Blueowl_${section}` });


})

const createBarWithLineChart = (series_data, presentation, slidename, chart_style, chart_type) => {
    const maxval = Math.max.apply(null,series_data.line_values);

  // console.log('chart style', chart_style)

		let opts = {
			x: chart_style.x,
			y: chart_style.y,
			w: chart_style.w,
			h: chart_style.h,

			catAxisLabelColor: "404040",
			catAxisLabelFontFace: "Roboto",
			catAxisLabelFontSize: 9,
			catAxisOrientation: "minMax",
      
			showLegend: false,
			showTitle: false,
      
      lineDataSymbol:'circle',
      lineDataSymbolSize: 5,
      lineSize: 1,

      valAxes: [
				{
					showValAxisTitle: false,
          valAxisHidden: true,
          valAxisMaxVal: 1,
          valAxisMinVal: 0,
          valAxisLabelFormatCode: "#%",
          valGridLine: { style: "none" },
				},
				{
					showValAxisTitle: false,
          valAxisHidden: true,
					valGridLine: { style: "none" },
          valAxisMajorTickMark: 'none',
          valAxisMinorTickMark: 'none',
          valAxisLineColor:'ffffff'
				},
        {
					showValAxisTitle: false,
          valAxisHidden: true,
					valGridLine: { style: "none" },
          valAxisMajorTickMark: 'none',
          valAxisMinorTickMark: 'none',
          valAxisLineColor:'ffffff'

				},
        {
					showValAxisTitle: false,
          valAxisHidden: true,
					valGridLine: { style: "none" },
          valAxisMajorTickMark: 'none',
          valAxisMinorTickMark: 'none',
          valAxisLineColor:'ffffff'

				},
			],

      catAxes: [
				{
					catAxisHidden: false,
          catAxisLabelFontSize: 7
				},
				{
					catAxisHidden: true,
				},
        {
					catAxisHidden: true,
				},
        {
					catAxisHidden: true,
				}
			],    
      
		};


		let chartTypes = [
			{
				type: presentation.charts.BAR,
				data: [
                {
                  name: series_data?.name,
                  labels: series_data?.labels,
                  values: series_data?.values,
                }
				],

				options: {
                  barDir: "col",
                  barGrouping: "standard",
                  chartColors: ["dab200"],                 
                  showLabel: true,
                  showValue: true,
                  dataLabelColor: "404040",
                  dataLabelFontSize: 7,          
                  dataLabelPostition: 'outEnd',
                  dataLabelFormatCode:'0.0%',
                  barGapWidthPct: 40

				}
			},
			{
				type: presentation.charts.LINE,
				data: [
					{
						name: series_data?.linemax_name,
						labels: series_data?.line_labels,
						values: series_data?.linemax_values,
					},
				],
				options: {
            chartColors: ["569CD6"],
            lineDash: 'sysDot',
            lineDataSymbolLineColor: "569CD6",
            barGrouping: "standard",
            secondaryValAxis: !!opts.valAxes,
					  secondaryCatAxis: !!opts.catAxes,        
				},
			},
      {
				type: presentation.charts.LINE,
				data: [
					{
						name: series_data?.line_name,
						labels: series_data?.line_labels,
						values: series_data?.line_values,
					},
				],
				options: {
            chartColors: ["dab200"],
            showLabel: true,
            showValue: true,
            dataLabelColor: "404040",
            lineDataSymbolLineColor: "dab200",
            dataLabelFontSize: 7,  
            dataLabelFormatCode: '$#0.0 "M"',            
            barGrouping: "standard",
            secondaryValAxis: !!opts.valAxes,
					  secondaryCatAxis: !!opts.catAxes,        
				},
			},
      {
				type: presentation.charts.LINE,
				data: [
					{
						name: series_data?.linemin_name,
						labels: series_data?.line_labels,
						values: series_data?.linemin_values,
					},
				],
				options: {
            chartColors: ["569CD6"],
            lineDash: 'sysDot',
            barGrouping: "standard",
            secondaryValAxis: !!opts.valAxes,
					  secondaryCatAxis: !!opts.catAxes,        
				},
			}
		];
   
		slidename?.addChart(chartTypes, opts);
	}