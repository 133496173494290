import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Textarea from 'react-expanding-textarea';
import * as debounce from '../../utils/debounce';
import { Markdown } from '../common/Markdown';
import { getRepo } from '../widgets/_widgetRepo';
import './Card.scss';
import { CardError } from './CardError';
import * as globals from '../../globals';
import * as apiService from '../../utils/apiService';
import * as cardUtil from '../../utils/cardUtil';
import { DynamicWidget } from '../widgets/DynamicWidget';
import { ExecutiveSummary } from '../widgets/ExecutiveSummary';
import { actionCreators as cardActionCreators } from '../../store/Cards.ts'

const saveCardInsights = async (studyId, auth, cardId, insights) => {
    
    const body = {
        comment: insights
    };

    try {
        const url = `${globals.apiRoot}/study/${studyId}/card/${cardId}/insights`;
        const response = await apiService.aPatch(auth, url, body);
        if (!response.ok) {
            console.error('saveCardInsights not ok', response.statusText);    
        }
    } catch (err) {
        console.error('saveCardInsights error', err);
    }
}

const triggerCallback = debounce.debounce((studyId, auth, cardId, insights) => saveCardInsights(studyId, auth, cardId, insights), debounce.insightsTextDelay);

export const InsightCard = (props) => {

    const state = useSelector(state => state);
    const studyId = state?.study?.uid;//useSelector(state => state.study?.uid);
    const auth = useSelector(state => state.auth);
    const [card, setCard] = useState();
    const [insights, setInsights] = useState(props.insights); // load here?
    const [loadingCard, setLoadingCard] = useState();
    const dispatch = useDispatch();

    const cardId = props.cardId; // is that right!?!?!?



    // load card
    useEffect(() => {

        (async () => {

            setLoadingCard(true);

            const xcard = await cardUtil.loadSavedCard(studyId, auth, cardId);
           
            setCard(xcard);
            setInsights(xcard?.comment);

            setLoadingCard(false);
        })()
        

    }, [cardId])

    if (loadingCard) {
        return <div style={{margin: 10}}>loading...</div>;
    }
    // if (!card) return null;

    const handleInsightsChange = (ev) => {
        setInsights(ev.target.value);
        triggerCallback(studyId, auth, cardId, ev.target.value);
    }


    const widgetRepo = getRepo(studyId);
    const entry =
        card?.widget_uid ? widgetRepo.find(e => e.uid === card.widget_uid) :
            card?.is_dynamic ? {
                uid: null,
                props: {
                    title: card.dynamic_item?.title,
                    //subTitle: 'asdf',//subTitle,// card.dynamic_item?.subTitle,
                    item: card.dynamic_item, // ???
                    data: card.dynamic_item?.data,
                    instanceContext: card.state?.instance
                },
                widget: DynamicWidget
            }
            : null;

   
    // widget DOES NOT get any inherited state
    // widget gets state from card (from this.props)
    // widget gets props from repo entry props
    
    const wState = entry ? {
        //...this.getRelevantState(),
        ...card?.state,
        ...entry.props
    } : null;

    const Widget = entry ? entry.widget : null;
    const editMode = props.editMode;
    const isTextCard = card?.state?.headline ? true : false;

    const stateContext = {}; // i'd prefer that this could be null, but DCMColumnsWidget or something like that crashes because it tries to assign getSaveState to it
    return <div className={'card mode-insight ' + (props.inFeed ? ' in-feed' : '') + (props.expanded ? ' expanded' : '')}>
        <div className='content'>
            { isTextCard ? 
                <ExecutiveSummary key={cardId}
                    editMode={editMode}
                    headline={card?.state?.headline}
                    content={card?.state?.content}
                    onChange={(curr) => {
                        
                    cardActionCreators?.updateTextCard(cardId, studyId, curr.headline, curr.content, () => {
                            console.log('saved');
                        })(dispatch, () => state);
                    }}
                />   : null 
        
        }
            {Widget ? 
                <Widget
                    stateContext={stateContext}
                    {...wState}
                    inInsightCard={true}
                    entry={entry}
                    expanded={props.expanded} />
                :  null // 'widget not loaded'
            }
            {card && card.error ? <CardError message={card.error} /> : null}
        </div>
        <div className='footer'>
            {!isTextCard && editMode ? 
                <div className='insights'>
                    <Textarea
                        className='insights-input'
                        placeholder='Type insights here'
                        value={insights || ''} 
                        onChange={handleInsightsChange}
                    />
                </div> :
                insights ? 
                    <div className='insights'>
                        <Markdown data={insights} />
                    </div>
                : null
            }
        </div>
    </div>;

    

}

