import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { actionCreators as configActionCreators } from '../../store/Config.ts';
import { actionCreators as selectionsActionCreators } from '../../store/Selections.ts';
import './ProductBlockMini.scss';


export class ProductBlockMini extends React.Component {

    // product (uid)
    // image
    // label
    // available

    handleClick = () => {
        if( this.props.onClick ){
            this.props.onClick(this);
        }

        if( this.props.allowChange && !this.props.disabled ){
            const selection = this.props;
            const callback = (sel) => {
                this.props.setSelection(sel.type, sel);
                window.topBar.hideMenu();
            };
            const options = {
                allow: this.props.allowChange,
                multiSelect: false
            }
            window.topBar.openProducts(selection, callback, options);
                
        }
    }

    commaJoin = (elements) => {
        if( !elements ) return null;
        if( elements.length === 1 )
            return elements[0];
        const subElements = elements.slice(0, elements.length - 1);
        return subElements.join(', ') + ' or ' + elements[elements.length - 1];
    }

    render() {

        const props = this.props;
        const studyId = (props.study || {}).uid;

        let selTypes = [];
        if( props.study && props.study.selectionHierarchy ){
            props.study.selectionHierarchy
                .filter(att => !props.allow || props.allow.includes(att.name))
                .forEach(att => selTypes.push(att.label));
            //selTypes.push(props.study.productLabel);
        }
        if( !props.allow || props.allow.includes('product') ){
            selTypes.push(props.study.productLabel);
        }
        const selLabel = this.commaJoin(selTypes);

        const dirty = props.dirty;
        //console.log(props);

        return <div 
            className={'product-block-mini'
                + (props.standalone ? ' standalone' : '')
                + (props.selected ? ' sel' : '')
                + (props.image ? ' has-image' : '')
                + ((props.product && !props.available) ? ' not-available' : '')
                + (props.disabled ? ' disabled' : '')
            }
            style={this.props.style}
            onClick={this.handleClick}
        >
            
            <div className='label-container'>
                {props.label ?
                    <div className="text1 text-truncate" title={props.label}>
                        {props.label}
                    </div>
                :
                    <div className='select-message'>
                        {`Click to select ${selLabel}`}
                    </div>
                }
                <div className="text2">
                    {dirty ? <span className='dirty-bit' title='Reset' onClick={(ev) => {
                        this.props.resetPlay(props.product);
                        ev.stopPropagation();
                    }}><i className='fas fa-circle' /></span>: null}
                </div>
            </div>

            <div className={'type-container' + (props.typeLabel ? '' : ' blank')}>
                {props.typeLabel}
            </div>

            <div className={'type-container2' + (props.typeLabel ? '' : ' blank')}>
                {props.typeLabel}
            </div>

        </div>;

    }
}

ProductBlockMini = withRouter(
    connect(
        state => ({ study: state.study }),
        dispatch => bindActionCreators({
            resetPlay: configActionCreators.resetPlay,
            setSelection: selectionsActionCreators.setSelection
        }, dispatch)
    )(ProductBlockMini)
);
  