import * as React from 'react';
import { useSelector } from 'react-redux';
import * as selectionHelper from '../../utils/selectionHelper';
import ScatterPlot from '../vis/ScatterPlot2';
import { CoreWidget1 } from './CoreWidget1';

export const ScatterPlotWidget = (props) => {

    const study = useSelector(state => state.study);
    const selections = useSelector(state => state.selections);
    const selection = selectionHelper.getSelection(props, study, selections);
    const path = [selection?.label];

    const highlight = [
        {
            "label": selection?.label,
        }
    ];

    const renderContent = (cache, getJSONData) => {
        if (getJSONData && typeof getJSONData === 'function')
            getJSONData(cache?.data)

        return (
            <ScatterPlot
                data={cache?.data}
                x={props.x}
                y={props.y}
                highlight={highlight}
                average={"Industry Average"}
            />
        )
    }

    const useBigData = props.useBigData ? {
        path: selection ? props.useBigData.path.replace("{{path}}", encodeURI(path.join('|'))) : null
    } : {};

    return <CoreWidget1 {...props}
        showSelection={true}
        render={renderContent}
        useBigData={useBigData}
        />


}

