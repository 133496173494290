import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators as configActionCreators } from '../../store/Config.ts';
import { AttributeBox } from '../config/AttributeBox';
import './EditConstraints.scss';

export class EditConstraints extends React.Component {

    setAttLocks = (slot, writeableAtts, newVal) => {
        writeableAtts.forEach(att => {
            this.props.setAttLock(slot.uid, att.name, newVal, slot.play[att.name] || att.defaultValue)
        });
    }

    render() {
        let def = this.props.config.productDefs.default;
        if (!def) return null;

        let selection = this.props.sel;
        if (!selection) return null;

        let slot = this.props.config.productSlots.find(slot => slot.uid === selection.uid) || {};
        if (!slot) return null;

        let writeableAtts = def.atts.filter(
            att => att.readOnly !== true && att.area == this.props.area // eslint-disable-line eqeqeq
        );
        
        

        return <>
            <div className='optimize-product'>
                <span className='all-btn' onClick={() => this.setAttLocks(slot, writeableAtts, false)} role='button' aria-label='allow all'>allow all</span>
                <span className='all-btn' onClick={() => this.setAttLocks(slot, writeableAtts, true)}  role='button' aria-label='disallow all'>disallow all</span>
            </div>
            <div className="constraints-area">
                <div className="att-editor">
                    {writeableAtts.map(att => <AttributeBox key={att.name} prod={slot} att={att} allowOptimize={true} />)}
                </div>
            </div>
        </>;
    }
}

EditConstraints = withRouter(
    connect(
        state => state.study,
        dispatch => bindActionCreators(configActionCreators, dispatch)
    )(EditConstraints)
);
