import React from 'react';
import { useState } from "react";
import { useSelector } from "react-redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { actionCreators as configActionCreators } from '../../store/Config.ts';
import { actionCreators as selectionsActionCreators } from '../../store/Selections.ts';
import './SelectionBlockImage.scss';


export const SelectionBlockImage = (props) => {

    const selectionTypes = useSelector(state => state.study?.selectionTypes);
    const selectionType = selectionTypes?.[props.name];
    const selLabel = selectionType?.label;
    const nbSelect = props.flavor === null ? 1 : props.flavor;
    //console.log('nbSelect', nbSelect, props.flavor);
    const typeLabelMulti = selectionType?.labelMulti || selectionType?.label + 's';

    const handleClick = () => {

        if( !props.disabled ){
            
            const callback = (sel) => {
                window.topBar.hideMenu();
                if (props.onSelect) {
                    props.onSelect(sel);
                }
            };

            const options = {
                selectionType,
                // todo: existing selection
                selectionName: null, // callback will receive the selected value
                nbSelect
            }

            window.topBar.openSelection(null, callback, options);                
                
        }
    }

    const selectedItem = props.selectedItem;
    const selectedItems = props.selectedItems;
    const allSelectedItems = [props.selectedItem, ...(props.selectedItems || [])]
        .filter(x => x) // remove missing entries
        .reduce((acc, el) => {
            if (!acc.find(i => i?.id === el?.id)) {
                acc.push(el);
            }
            return acc;
        }, [])
        ;

    const selectedItemsLabel = props.flavor == '2' ? allSelectedItems?.[0]?.label + ' vs. ' + allSelectedItems?.[1]?.label
        : //props.flavor == '*'
            `(${allSelectedItems?.length || 0} ${typeLabelMulti} selected)`

    const selectionTypeLabel = selectedItem?.selectionTypeLabel || selectionType?.label;
    
    return <div 
        className={'selection-block-image'
            + (props.standalone ? ' standalone' : '')
            + (props.selected ? ' sel' : '')
            + (props.image ? ' has-image' : '')
            + (props.disabled ? ' disabled' : '')
        }
        style={props.style}
        onClick={handleClick}
    >
        
        <div className='label-container'>
            {props.src ?
                <div className='image-block'>
                    <img className='image1' src={props.src} alt=""/>
                </div>
                :
                <div className='select-message'>
                    {props.emptyText}
                </div>
            }
        </div>

    </div>;
}
