import { useEffect, useState } from 'react';
import * as globals from "../../globals";
import * as apiService from "../../utils/apiService";
import { useSelector } from "react-redux";
 

export function TypeForm(formId, params) {

    const [typeform_data, setTypeformData] = useState('')

    const auth = useSelector(state => state.auth)
    let path = `${globals.apiRoot}/typeform/${formId}`
    if (params)
        path = `${path}?${params}`

    // console.log('typeform endpoint', path)  
    
    useEffect(() => {
        const fetchData = async () => {
        try {
            // Make asynchronous API call here
            const res1 = await apiService.aGet(auth, path);
            const res2 = await res1.json();
            setTypeformData(res2)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        }

    fetchData();
  }, [])
    
    
    return typeform_data
    
}