// import * as React from 'react'
// import './ToggleSwitch.scss'

import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import './ToggleSwitch.scss'
import { actionCreators as GlobalStoreCreator } from "../../store/General"

export const ToggleSwitch = ({ on, onChange, className, style, children }) => {
    const [isOn, setIsOn] = useState(on)
    const dispatch = useDispatch()

    useEffect(() => {
        setIsOn(on)
    }, [on])

    const handleClick = () => {
        if (onChange) {
            onChange(!isOn)
        }
    }

    return (
        <div
            className={`toggle-switch${isOn ? ' on' : ' off'} ${className}`}
            onClick={handleClick}
            style={style}
        >
            <div className={`switch${isOn ? ' on' : ' off'}`}>
                <div className={`thumb${isOn ? ' right' : ' left'}`}>&nbsp;</div>
                {isOn ? (
                    <div className='icon-left'>
                        <i className='fal fa-check' />
                    </div>
                ) : (
                    <div className='icon-right'>
                        <i className='fal fa-times' />
                    </div>
                )}
            </div>
            <div className='label'>
                {children}
            </div>
        </div>
    )
}


