import * as selectionHelper from '../utils/selectionHelper';
// @ts-ignore
import {
    SELECTION_SELECT,
    SELECTION_ADD,
    SELECTION_REMOVE,
    SELECTION_MOVE,
    SELECTION_SET_MULTIPLE,
    SELECTION_REMOVE_ALL,
    STUDY_RECEIVE
} from './action-types';

export interface SelectionState{
}

export const initialState: SelectionState = {
};

export const actionCreators = {

    setSelection: (key: any, value: any, callback: any = null) => async (dispatch: any, getState: any) => {

        dispatch({ type: SELECTION_SELECT, key, value });
        
        if(callback){
            callback();
        }
            
    },

};


export const reducer = (state: any, action: any): SelectionState => {
    state = state || initialState;

    switch (action.type) {

        case STUDY_RECEIVE : {
            return {
                ...state,
            };
        }

        case SELECTION_SELECT: {
            let retVal = {
                ...state,
                [action.key]: action.value
            }

            // autoselect is just additional selections to be made with main selection
            if (action.autoSelect) {
                retVal = {
                    ...retVal,
                    ...action.autoSelect
                }
            }

            return retVal;
        }
            
        case SELECTION_ADD: {
            let newlist: any = [...(state?.[action.key] || [])];

            // replace existing items if exist
            if (action.value?.id) {
                newlist = newlist.filter((i: any) => i.id != action.value.id);
                newlist.push(action.value);
            }

            // replace existing items if exist
            if (action.values) {
                action.values.forEach((val:any) => {
                    newlist = newlist.filter((i: any) => i.id != val.id);
                    newlist.push(val);
                })
            }

            return {
                ...state,
                [action.key]: newlist
            }
        }
            
        case SELECTION_MOVE: {

            let newlist: any = [...(state?.[action.key] || [])];
            let idx = action.newIndex;
            console.log('newlist', newlist);

            // replace existing items if exist
            if (action.value?.id) {
                newlist = newlist.filter((i: any) => i.id != action.value.id);
                newlist.splice(idx, 0, action.value);
            }

            // replace existing items if exist
            if (action.values) {
                action.values.forEach((val: any) => {
                    newlist = newlist.filter((i: any) => i.id != val.id);
                    newlist.splice(idx++, 0, val);
                })
            }

            return {
                ...state,
                [action.key]: newlist
            }
        }
            
        case SELECTION_REMOVE: {
            const prevlist: any = state?.[action.key] || [];
            return {
                ...state,
                [action.key]: prevlist.filter((x:any) => x.id != action.value?.id)
            }
        }
            
        case SELECTION_REMOVE_ALL: {
            return {
                ...state,
                [action.key]: []
            }
        }

        case SELECTION_SET_MULTIPLE: {
            return {
                ...state,
                ...action.setObj
            }
        }
            
        default:
            return state;
    }
};

