import * as React from 'react';
import './ContentViewer.scss';
import { EditModeWrapper } from './EditModeWrapper';
import { ContentLoader } from './ContentLoader';
import { useState } from 'react';

const isNull = (x) => x === null || x === undefined;

const ifNull = (x, fallback) => isNull(x) ? fallback : x;

export const SectionElement = (props) => {

    const item = props.item;

    const [layoutsReady, setLayoutsReady] = useState({});

    const allLayoutsReady = () => !item.contents || item.contents.filter(c => layoutsReady[c.id]).length == item.contents.length;

    const setChildLayoutReady = (id, val) => {
        //console.log(`section ${item?.id} setChildLayoutReady ${id}:${val}`);
        setLayoutsReady({
            ...layoutsReady,
            [id]: val
        })
    }

    React.useEffect(() => {
        if (item) {
            //console.log(`section ${item.id} layoutsReady`, layoutsReady, item.contents, allLayoutsReady());
            props.setLayoutReady?.(allLayoutsReady())
        }
    }, [item, layoutsReady])

    if (!item) return null;
    


    let style = {
        border: item.data?.border,
        
        margin: ifNull(item.data?.margin, '0 0 10px 0'),
        //maxHeight: item.data?.maxHeight || 300,
        backgroundColor: item.data?.backgroundColor,
        padding: item.data?.padding,
        //opacity: allLayoutsReady() ? 1.0 : 0
    }

    if (!isNull(item.data?.borderBottom)) {
        style.borderBottom = item.data?.borderBottom;
    }

    if (item.data?.sticky) {
        style.position = "sticky";
        style.top = Number(item.data?.stickyFromTop) || 0;
        style.zIndex = 100;
    }

    const allowHeading = !props.asTab;
    const showHeading = allowHeading && (item.title || item.data?.subTitle);


    return <EditModeWrapper className='has-children'
        item={item} edit={props.edit} restoredItem={props?.restoredItem} reloadItem={props.reloadItem} reloadParent={props.reloadParentItem}
        pageLevel={props.pageLevel}
    >
        <div id={item.id} className='section-element ' style={style}>
            {showHeading ? <div className='section-element-heading page-width'>
                {item.title ?
                    <div className='title' style={item.data?.titleStyle}>
                        {item.title}
                    </div>
                    : null
                }
                {item.data?.subTitle ?
                    <div className='subtitle' style={item.data?.subTitleStyle}>
                        {item.data?.subTitle}
                    </div>
                    : null
                }
                </div>
            : null}
            <div className='section-element-content'>
                {item.contents?.map(child => <ContentLoader
                    key={child.id}
                    id={child.id}
                    reloadParentItem={props.reloadItem}
                    edit={props.edit}
                    restore={props.restore}
                    restoredItem={child?.deleted}
                    pageLevel={props.pageLevel}
                    setLayoutReady={(val) => setChildLayoutReady(child.id, val)} />)}
                {(item.contents?.length > 0 || showHeading) ? null : (props.edit?.editMode ? `empty` : null)}
            </div>
        </div>
    </EditModeWrapper>;
}
