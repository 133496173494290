// @ts-ignore
import { scenarioActionType } from './Scenario';
import { 
    GENERAL_CARD_INFO_VISIBLE_SET,
    GENERAL_SET_CUSTOM_VALUE,
    GENERAL_SET_CUSTOM_VALUE2,
    GENERAL_SET_SCROLL_POSITION,
    MODEL_SET_ACTIVE_CONFIG
 } from './action-types'


export interface GeneralState {

    /** Whether or not the right-side card info pane is visible */
    cardInfoVisible: boolean;

    /** Remembers user scroll positions */
    scrollPos: {};

    custom: any;

}


const initialState: GeneralState = {
    cardInfoVisible: true,
    scrollPos: {x:0, y:0},
    custom: {}
};

export const actionCreators = {

    setCardInfoVisible: (visible:any) => async (dispatch: any, getState: any) => {
        dispatch({ type: GENERAL_CARD_INFO_VISIBLE_SET, visible });
    },

    setGlobalStore: (storeItem:any, value: any) => async (dispatch: any, getState: any) => {
        dispatch({ type: GENERAL_SET_CUSTOM_VALUE, key: storeItem, value: value});
    }
}

export const reducer = (state: GeneralState, action: any): GeneralState => {
    state = state || initialState;

    switch( action.type ){
        case GENERAL_CARD_INFO_VISIBLE_SET : {
            return {
                ...state,
                cardInfoVisible: action.visible,
            };
        }
        case GENERAL_SET_CUSTOM_VALUE : {
            return {
                ...state,
                custom: {
                    ...state.custom,
                    [action.key]: action.value
                }
            }
        }
        case GENERAL_SET_CUSTOM_VALUE2 : {
            return {
                ...state,
                custom: {
                    ...state.custom,
                    [action.key1]: {
                        ...state.custom?.[action.key1],
                        [action.key2]: action.value
                    }
                }
            }
        }
        case GENERAL_SET_SCROLL_POSITION : {
            return {
                ...state,
                scrollPos: action.value
            }
        }
            
        case MODEL_SET_ACTIVE_CONFIG: {

			const { activeConfig } = action

			return {
				...state,
                custom: {
                    ...state.custom,
                    activeModel: activeConfig
                }
			}
        }
            
        // // hack for ford
        // // CONSIDER REMOVING IT SINCE THE CONFIGURATOR DOESN'T NOTICE IT ANYWAY??
        // case `${scenarioActionType}:LOAD_SCENARIO_RESULT`: {

        //     //console.log("got here!", action);

        //     // this is a hack for ford to get the
        //     // activeConfig populating into custom so the cards will notice
            
        //     if (action.value?.config?.activeConfig) {
        //         return {
        //             ...state,
        //             custom: {
        //                 ...state.custom,
        //                 activeModel: action.value.config.activeConfig
        //             }
        //         }
        //     }
        //     else return state;
        // }
            
        default:
            return state;
    }

};
