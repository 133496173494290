import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as apiService from '../../utils/apiService';
import * as globals from '../../globals';
import './ManageUsers.scss'
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from "@azure/msal-browser";


export function ManageUsers() {

    const auth = useSelector(state => state.auth);
    const { instance } = useMsal()

    const [users, setUsers] = useState([])
    const [studies, setStudies] = useState([])
    const [selectedStudyIds, setSelectedStudyIds] = useState([])
    const [selectedChannelsRead, setSelectedChannelsRead] = useState([])
    const [selectedChannelsWrite, setSelectedChannelsWrite] = useState([])
    const [isStudyAdmin, setIsStudyAdmin] = useState(false)
    const [sendEmail, setSendEmail] = useState(true)
    const [newUser, setNewUser] = useState({
        firstname: '',
        lastname: '',
        email: '',
        company: ''
    })


    useEffect(() => {

        getStudies()
        getUsers()
        
    }, [])

   

    const getStudies = async () => {

            apiService.aGet(auth, `${globals.apiRoot}/study/allstudies`)
            .then((res) => res.json())
            .then((res) => {
                setStudies(res);
            })
            .catch((err) => console.error(err));
        }       

    const getUsers = async () => {

            apiService.aGet(auth, `${globals.apiRoot}/account/list-users`)
            .then((res) => res.json())
            .then((res) => {
                setUsers(res);
            })
            .catch((err) => console.error(err));
        }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setNewUser(prev => ({ ...prev, [name]: value }))
    }

    function generateMailNickname(name) {
        return name.toLowerCase().replace(/[^a-z0-9]/g, '').substring(0, 63);
    }

    const reset = () => {
        setUsers([])
        setSelectedStudyIds([])
        setSelectedChannelsRead([])
        setSelectedChannelsWrite([])
        setIsStudyAdmin(false)
        setSendEmail(true)
        setNewUser({
            firstname: '',
            lastname: '',
            email: '',
            company: ''
        })
    }

    const addBlueOwlUser = async () => {
         let tokenResponse;
        let token;

        try {
                tokenResponse = await instance.acquireTokenSilent({
                    scopes: ["User.Invite.All", "User.ReadWrite.All"],
                    account: instance.getAllAccounts()[0]
                })
            token = tokenResponse.accessToken;
        
            const response = await fetch(`https://graph.microsoft.com/v1.0/users/${newUser.email}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Ensure token is valid and has necessary permissions
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error("Error response from Graph API:", errorData);
                return null; // Handle error appropriately
            }

            const userData = await response.json()
            if (userData) {
                const body = {
                    email: newUser.email, 
                    name: newUser.firstname + ' ' +  newUser.lastname, 
                    company: newUser.company, 
                    uid: userData.id, 
                    studyIds: selectedStudyIds,
                    isStudyAdmin: isStudyAdmin ? 1 : 0
                    // user_permissions: userPermissions
                }
                // console.log('create user', body);

                //add a new user to the table
                apiService.aPost(auth, `${globals.apiRoot}/account/create-account`, body)
                    .then((res) => {
                        if (res.ok) {
                            topBar.enqueueSnack({
                                id: parseInt(+new Date().getMilliseconds() + Math.random().toFixed()),
                                message: `User has been added!`,
                                to: 3000,
                                success: true
                            })
                            reset()
                        }
                        else {
                            topBar.enqueueSnack({
                                id: parseInt(+new Date().getMilliseconds() + Math.random().toFixed()),
                                message: `User failed to add!`,
                                to: 3000,
                                success: false
                            })
                        }
                })
            }
            
        } catch (error) {
            console.error("Failed to fetch user details:", error);
            return null; // Handle error appropriately
        }
    }



    const addGuestUser = async () => {
        let tokenResponse;
        let token;

        try {
                tokenResponse = await instance.acquireTokenSilent({
                    scopes: ["User.Invite.All", "User.ReadWrite.All"],
                    account: instance.getAllAccounts()[0]
                })
            token = tokenResponse.accessToken;
            
            // console.log('token', token);
                
                // Add guest user without sending an invitation email
                const response = await fetch('https://graph.microsoft.com/v1.0/invitations', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "invitedUserEmailAddress": newUser.email,
                        "invitedUserDisplayName": newUser.firstname + ' ' + newUser.lastname,
                        "inviteRedirectUrl": "https://app.blueowlai.com",
                        "sendInvitationMessage": sendEmail,
                        "invitedUserMessageInfo": {
                            "messageLanguage": "en-US",
                            "customizedMessageBody": `Hi ${newUser.firstname},

                            Welcome to Blue Owl! This email is generated by Microsoft as part of our dual-factor authentication system. When you are ready to go to the Blue Owl application, just click on “Accept invitation” below. From then on, you can access from app.blueowlai.com.

                            If you are logged into your Ford Microsoft account, you should be authenticated automatically. At least, that's the dream. Microsoft has been having some glitches lately, so if an extra authentication screen comes up, close it and hit LOGIN again, and if that doesn't work, click on "Log out," then log back in. They should get all that kind of thing straightened out soon.

                            We hope you’ll have a great experience using Blue Owl. Let us know if you have any issues or you'd like to see improvements. We're always improving our software and love to automate away tedium and pain.

                            Also, there is a brief introduction to the Ford Bo tool on the Home page. Taking a quick look at it is ten minutes well spent.

                            Again, welcome to Blue Owl!

                            The Bo Team
                            
                            `

                        }
                    }) //reminder template strings will keep formatting, spacing as above
            })

            if (!response.ok) {
                const errorData = await response.json();
                console.error("Error response from Graph API:", errorData);
                return;
            }
            const data = await response.json();
            // console.log('data', data.invitedUser.id);

            // let userPermissions = {
            //     studyIds: selectedStudyIds,
            //     channels_read: selectedChannelsRead,
            //     channels_write: selectedChannelsWrite,
            //     is_study_admin: isStudyAdmin
            // }

            if (data) {
                 let newUid = data.invitedUser.id
                const body = {
                    email: newUser.email, 
                    name: newUser.firstname + ' ' + newUser.lastname, 
                    company: newUser.company, 
                    uid: newUid, 
                    studyIds: selectedStudyIds,
                    isStudyAdmin: isStudyAdmin ? 1 : 0
                    // user_permissions: userPermissions
                }
                // console.log('create user', body);

                //add a new user to the table
                apiService.aPost(auth, `${globals.apiRoot}/account/create-account`, body)
                    .then((res) => {
                        if (res.ok) {
                            topBar.enqueueSnack({
                                id: parseInt(+new Date().getMilliseconds() + Math.random().toFixed()),
                                message: `User has been added!`,
                                to: 3000,
                                success: true
                            })
                            reset()
                        }
                        else {
                            topBar.enqueueSnack({
                                id: parseInt(+new Date().getMilliseconds() + Math.random().toFixed()),
                                message: `User failed to add!`,
                                to: 3000,
                                success: false
                            })
                        }
                })
            }

        }
        catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                tokenResponse = await instance.acquireTokenPopup({
                    scopes: ["User.Invite.All", "User.ReadWrite.All"],
                    account: instance.getAllAccounts()[0]
                })
            
            } else {
                 topBar.enqueueSnack({
                            id: parseInt(+new Date().getMilliseconds() + Math.random().toFixed()),
                            message: `User failed to add!`,
                            to: 3000,
                            success: false
                 })
                reset()
                console.error("Other error:", error)
                return
            } 
        }
          
    }



    const handleCheckboxChange = () => {
        setSendEmail(!sendEmail)
    }

     const handleChannelRead = () => {
        setSelectedChannelsRead(!selectedChannelsRead)
    }

     const handleChannelWrite = () => {
        setSelectedChannelsWrite(!selectedChannelsWrite)
    }

    const handleStudyChange = (id, event) => {
       const isChecked = event.target.checked;

        setSelectedStudyIds(prev => {
            // If the checkbox is checked and the id is not in the array, add it
            if (isChecked && !prev.includes(id)) {
                return [...prev, id]
            }
            // If the checkbox is unchecked and the id is in the array, remove it
            else if (!isChecked && prev.includes(id)) {
                return prev.filter(existingId => existingId !== id)
            }
            return prev // return the current state if no changes are needed
        })
    }   
       
useEffect(() => {
    console.log('selectedStudies', selectedStudyIds);
}, [selectedStudyIds]);
    
    
    const deleteUser = async (user_delete) => {
        if (confirm(`This will delete user ${user_delete.name}, are you sure?`)) {
            try {
                const response = await apiService.aPost(auth, `${globals.apiRoot}/account/delete_user`, user_delete.uid)
                    .then((res) => {
                        if (res.ok) {
                            getUsers()

                            topBar.enqueueSnack({
                                id: parseInt(+new Date().getMilliseconds() + Math.random().toFixed()),
                                message: `User ${user_delete.name} has been deleted!`,
                                to: 3000,
                                success: true
                            })
                        }
                        else {
                            topBar.enqueueSnack({
                                id: parseInt(+new Date().getMilliseconds() + Math.random().toFixed()),
                                message: `User failed to add!`,
                                to: 3000,
                                success: false
                            })
                        }
                    })
            }
            catch (error) {
                console.error('Error calling the API:', error);
            }
        }
    }
    
    



    return (
        <div style={{ padding: '20px 50px', marginTop: 45, width: '100%', height: '100vh', overflowY:'auto' }}>
            <div style={{display:'flex', justifyContent:'center', marginTop:10}}>
                <h2 style={{fontSize:20, marginBottom:50, color:'#333'}}>Manage Users</h2>
            </div>
            <section className='msSection'>
                <h2 className='msHeader'>Edit Users</h2>
                <div className='user-container'>
                    {users?.map(user => (
                        <div key={user.email} className="user-row" >
                                <span className="user-name">{user.name}</span>
                            <span className="user-email">{user.email}</span> 
                            <div className="button-container">
                                {/* <button className="btn drk border btn-edit">Edit</button> */}
                                <button className="btn drk border btn-delete" onClick={() => deleteUser(user)}>Delete</button>
                            </div>                           
                        </div>
                    ))}
                </div>
            </section>

            <section className='msSection'>
                <h2 className='msHeader'>Add Users</h2>
                <div className='div-input' style={{marginTop:20}}>
                    <label className='msLabel'>First Name</label>
                    <input type="text" name="firstname" className='msInputs' onChange={handleInputChange} />
                </div>
                 <div className='div-input'>
                    <label className='msLabel'>Last Name</label>
                    <input type="text" name="lastname" className='msInputs' onChange={handleInputChange} />
                </div>
                <div className='div-input'>
                    <label className='msLabel'>Email</label>
                    <input type="email" name="email" className='msInputs' onChange={handleInputChange} />
                </div>
                <div className='div-input'>
                    <label className='msLabel'>Company</label>
                    <input type="text" name="company" className='msInputs' onChange={handleInputChange} />
                </div>
                <div>
                    <div style={{display:'flex'}}>
                        <label className='msLabel'>Studies</label>
                        <div style={{ height:145, width:335, overflowY: 'auto', backgroundColor:'#fff', border:'1px solid #ccc'}}>
                            {studies.map(study => (                                
                                <div key={study.uid} >
                                    <input 
                                        type="checkbox" 
                                        id={study.uid}
                                        name={study.uid}
                                        onChange={(e) => handleStudyChange(study.id, e)}
                                    />
                                    <label htmlFor={study.uid}>{study.label}</label>
                                </div>
                            ))}
                        </div>
                        
                    </div>
                    {/* <div>
                        <label className='msLabel'>Channels:</label>
                        <input 
                            type='checkbox'
                            id='channel-read'
                            checked={false}
                            onChange={() => handleChannelRead()}
                        />
                        <label htmlFor='channel-read'>Read</label>

                        <input 
                            type='checkbox'
                            id='channel-write'
                            checked={false}
                            onChange={() => handleChannelWrite()}
                        />
                        <label htmlFor='channel-write'>Write</label>
                    </div>  */}
                    <div className='div-input' style={{height:30, marginTop:10}}>
                        <label className='msLabel'>Make a Study Admin</label>
                        <input type='checkbox' onChange={() => setIsStudyAdmin(!isStudyAdmin)} style={{verticalAlign:'middle', marginLeft:0}} />
                    </div>                    
                </div>
                <div className='div-input'>
                    <label className='msLabel'>Send user an email</label>
                    <input 
                            type="checkbox" 
                            id="sendEmail" 
                            checked={sendEmail} 
                            onChange={handleCheckboxChange} 
                            style={{verticalAlign:'middle', marginLeft:0}}
                        />                        
                    </div>   
                <div>
                    <button className='btn drk border' style={{ marginTop: 15, width:120 }} onClick={addGuestUser}>Create Guest User</button>                                    
                    <button className='btn drk border' style={{ marginTop: 15, width:120, marginLeft:10 }} onClick={addBlueOwlUser}>Create BlueOwl User</button>                                    
                </div>
            </section>
        </div>
    )
}


