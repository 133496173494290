import { format as d3Format } from 'd3-format';
import * as React from 'react';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { dateFormat } from '../../utils/dateFormat';
import { CoreWidget1 } from './CoreWidget1';
import CustomLegend from "../common/CustomLegend";
import CustomizedTick from "../common/CustomizedTick";

const pct_format = d3Format('.0%');

//const date_format = (ticks) => dateFormat(new Date(ticks), "mmm 'yy");
const date_format = (ticks) => dateFormat(new Date(ticks), "yyyy mmm");

const metrics = [
        { name: "aided_awareness", label: "Awareness (aided)", stroke: "#fe9e52" },
        { name: "interest",  label: "Interest", stroke: "#ab91c1" },
        { name: "consider",  label: "Consideration", stroke: "#66b063" },
        { name: "intent",    label: "Intent", stroke: "#da5e67" },
        { name: "own",       label: "Ownership", stroke: "#a5837d" },
        //{ name: "aided_loyalty",   label: "Loyalty" },
        { name: "advocacy",  label: "Advocacy", stroke: "#6892ad" },
    ]

export const FunnelWidgetTrended = (props) => {

    const renderContent = (cache, getJSONData) => {

        if( !cache ) return null;

        let data = cache.map(entry => ({ ...entry,
            yyyy: Math.floor(entry.timestamp / 100),
            yyyymm: entry.timestamp,
            mm: entry.timestamp - (Math.floor(entry.timestamp / 100) * 100),
        }));
        data = data.map(entry => ({ ...entry,
            timestamp: new Date(entry.yyyy + '-' + entry.mm + '-1'),
        }));
        data = data.map(entry => ({ ...entry,
            ticks: entry.timestamp.getTime()
        }));
    
        if (getJSONData && typeof getJSONData === 'function')
            getJSONData(data)
        return <ResponsiveContainer width='100%' height='100%'>
            <LineChart data={data} >
                <XAxis dataKey="ticks" type="number" domain={['dataMin', 'dataMax']} tickFormatter={date_format} />
                <YAxis width={35} tick={<CustomizedTick tickFormatter={pct_format} />} />
                {/* <Legend verticalAlign="top" layout="vertical" align="right" iconType="line" wrapperStyle={{ border: '1px solid red', marginLeft: 10}}/> */}
                <Legend iconType="line" content={<CustomLegend />} />
                <Tooltip formatter={pct_format} labelFormatter={date_format} />
                    {metrics.map(metric => <Line key={metric.name} name={metric.label} type="linear" dataKey={metric.name} stroke={metric.stroke || "#000000"} activeDot={{ r: 4 }} >
                </Line>)}
            </LineChart>
        </ResponsiveContainer>;
    }

    // 
    return <CoreWidget1 {...props} useFilter={false} useSelection={true} useScenario={true} render={renderContent} 
        useAnalytics={{ path: "/waves-timeseries?selectionId={selectionId}&timeframe=month", method: "get" }}
    />
}
