import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BreadCrumb } from '../common/BreadCrumb';
import { PageHeader2 } from '../common/PageHeader';
import * as apiService from "../../utils/apiService";
import * as globals from "../../globals";

export const getStack = (params) => {
    if( !params ) return [];
    const { id, parentid, parent2id, parent3id, parent4id } = params;
    const stack = [parent4id, parent3id, parent2id, parentid, id].filter(x => x);
    return stack;
}

export const getChildLink = (params, id, study_uid) => {
    const stack = getStack(params);
    const root = `/study/${study_uid}/explore` + (stack.length > 0 ? '/' + stack.join('/') : '');
    return id ? root + '/' + id : root;
}

export const getStudyLink = (id, study_uid) => {
    const root = `/study/${study_uid}`;
    return id ? root + '/' + id : root;
}

export const getContentLink = (params, id, study_uid) => {
    const stack = getStack(params);
    const root = `/study/${study_uid}/explore`;
    return id ? root + '/' + id : root;
}

export const getParentLink = (params, study_uid) => {
    const stack1 = getStack(params);
    const stack = stack1.slice(0, stack1.length-1);
    const root = `/study/${study_uid}/explore` + (stack.length > 0 ? '/' + stack.join('/') : '');
    return root;
}

export const getBreadCrumbElements = (item, study_uid, params) => {

    const siblings = item.siblings;

    let breadCrumbElements = [];
    const stack = getStack(params);
    //console.log('stack is ', stack);
    breadCrumbElements.push({ label: 'Explore', link: stack.length > 0 ? `/study/${study_uid}/explore` : null });
    for(let i = 0; i < stack.length; i++ ){
        const arr = stack.slice(0,i+1);
        const link = i < stack.length-1 ? `/study/${study_uid}/explore/` + arr.join('/') : null;
        let el = { label_id: stack[i], link };
        if( siblings && i == stack.length - 1 ){
            if( siblings.prev ){
                let arr2 = stack.slice(0,i);
                arr2.push(siblings.prev.id)
                el.prev = { link: `/study/${study_uid}/explore/` + arr2.join('/'), label: siblings.prev.title };
            }
            if( siblings.next ){
                let arr3 = stack.slice(0,i);
                arr3.push(siblings.next.id)
                el.next = { link: `/study/${study_uid}/explore/` + arr3.join('/'), label: siblings.next.title };
            }
            el.index = siblings.index;
            el.count = siblings.count;
        }
        breadCrumbElements.push(el);
    }

    return breadCrumbElements;

}

// export const getPageHeaderOld = (item, study_uid, params, edit, restore) => {

//     const breadCrumbElements = getBreadCrumbElements(item, study_uid, params);

//     return <PageHeader2 title={<BreadCrumb elements={breadCrumbElements} edit={edit} restore={restore}/>} fullWidth={true}/>;
// }

export const getPageHeader = (item, study_uid, params, edit, restore) => {

    return <PageHeaderLoader study_uid={study_uid} content_id={item?.id} edit={edit} restore={restore} hidePaging={item?.data?.hidePaging} />;

}

export const PageHeaderLoader = (props) => {

    const [pageInfo, setPageInfo] = useState(null);

    const auth = useSelector((state) => state.auth);
    const study_uid = props.study_uid;// useSelector((state) => state.study?.uid);
    const content_id = props.content_id;

    useEffect(() => {
        const f = async () => {
            const response = await apiService.aGet(
                auth,
                `${globals.apiRoot}/study/${study_uid}/content-breadcrumb/${content_id}`,
            );
            if (response.ok) {
                var x = await response.json();
                setPageInfo(x);
            } else {
                console.error(response);
                setPageInfo(null);
            }
        };
        f();
    }, [props.study_uid, props.content_id])

    const wrapWithLink = (x) => {
        return {
            ...x,
            link: `./${x.id}`
        };
    }

    let elements = [];
    let i = pageInfo;
    while (i != null) {
        let x = {
            label: i?.title,
            link: `./${i.id}`,
        };
        if (i.siblings?.length > 0) {
            x.index = i.siblingIndex;
            x.count = i.siblings?.length;
            x.prev = x.index > 0 ? wrapWithLink(i.siblings[i.siblingIndex - 1]) : null;
            x.next = x.index < x.count - 1 ? wrapWithLink(i.siblings[i.siblingIndex + 1]) : null;
        }
        elements.push(x);
        i = i.child;
    };
    if (elements.length > 0) {
        elements[elements.length - 1].link = null;
    }

    return <PageHeader2 title={<BreadCrumb elements={elements} edit={props.edit} restore={props.restore} fullWidth={false} hidePaging={props.hidePaging} />} fullWidth={false} />;


}

