import { format as d3Format } from "d3-format";
import * as React from "react";
import { useState } from "react";
import {
	Bar,
	BarChart,
	LabelList,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import * as globals from "../../globals";
import { CoreWidget1 } from "./CoreWidget1";
import { useSelector } from "react-redux";
import CustomizedTick from "../common/CustomizedTick";

//const format = d3Format('.1%');

export const CustomWidget1 = (props) => {
	const id = props.var;
	const [selectedAtt, setSelectedAtt] = useState(
		props.selectedAtt || props.att,
	);
	//const filterGroups = useSelector(state => state.filter?.filterGroups)
	const selectedFilter = useSelector((state) => state.filter?.selectedFilter);
	const pdef = useSelector((state) => state.study?.config.productDefs.default);
	const studyProductLabel = useSelector((state) => state.study?.productLabel);
	//const filterGroup = filterGroups?.find(fg => fg.id === id);
	//if( !filterGroup ) return `meta not found for [${id}]`;

	//const filters = filterGroup?.filters;

	const visualization = props.visualization;
	//console.log('viz', visualization);
	const att = pdef.atts.find((a) => a.name === selectedAtt); // props.att);
	const linear_att = pdef.atts.find((a) => a.name === props.linear_att);

	const tContext = {
		att,
		linear_att,
		filterSyntax: selectedFilter?.syntax || "1==1",
	};

	const renderOptions = () => {
		return (
			<div className="filter-etc" style={{ marginTop: 5 }}>
				{props.inDataCard && (
					<select
						value={selectedAtt || ""}
						onChange={(ev) => setSelectedAtt(ev.target.value)}>
						{props.atts?.map((attName) => {
							const selAtt = pdef.atts.find((a) => a.name === attName);
							return (
								<option key={attName} value={attName}>
									{selAtt.label}
								</option>
							);
						})}
					</select>
				)}
				{props.inInsightCard && (
					<label className="ml-1">
						{pdef.atts.find((a) => a.name === selectedAtt).label}
					</label>
				)}
			</div>
		);
	};

	// const renderContent = (cache) => "hi" + visualization;
	// console.log('props', props);
	const renderContent = visualization
		? (cache, getJSONData) => {
				if (!cache) return null;

				let data = cache?.[selectedAtt];
				if (!data) return null;

				//  filters.map(f => {
				//     const cell = cache?.results?.[f.id || f.syntax];
				//     return {
				//         value: cell?.value,
				//         label: f.label
				//     }
				// });

				if (getJSONData && typeof getJSONData === "function")
					getJSONData(null, true);

				const format = d3Format(props.format || cache?.linear_att?.format);

				switch (visualization) {
					case "bar":
						return (
							<ResponsiveContainer width="100%" height="100%">
								<BarChart data={data} layout="vertical" margin={{ right: 50 }}>
									<XAxis
										dataKey="value"
										type="number"
										tickFormatter={format}
										domain={["auto", (dataMax) => dataMax * 1.2]}
										{...props?.barChart?.xAxis}
									/>
									<YAxis
										dataKey="label"
										type="category"
										width={125}
										tick={<CustomizedTick wordBreakLimit={15} />}
									/>
									<Tooltip formatter={format} />
									<Bar
										dataKey="value"
										name={props.valueLabel || cache?.linear_att?.label}
										fill={globals.colors.primary1}
										isAnimationActive={false}>
										<LabelList
											dataKey="value"
											position="right"
											formatter={format}
										/>
									</Bar>
								</BarChart>
							</ResponsiveContainer>
						);
					default:
						return null; // to show json
				}
		  }
		: undefined;

	let extra = {};
	if (true) {
		extra.fakeSelectionLabel = `All Market Simulation ${studyProductLabel}s`;
	}

	return (
		<CoreWidget1
			{...props}
			{...extra}
			options={renderOptions}
			render={renderContent}
			getSaveState={() => {
				return {
					selectedAtt,
				};
			}}
			transformContext={tContext}
		/>
	);
};
