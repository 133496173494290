import { format as d3Format } from 'd3-format';
import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip, Label } from 'recharts';
import { CoreWidget1 } from './CoreWidget1';
import './PieWidget2.scss';


const format_slice = d3Format('.0%');
const format_slice_tooltip = d3Format('.1%');

const piecolors = [
    '#5b91fe',
    '#cfcfcf'
];

export const PieWidget2 = (props) => {
    
    const format = props.fformat || d3Format(props.format || '.1%');
    const metric = props.metric;

    const selections = useSelector(state => state.selections);
    const selection = selections?.product;

    // console.log('selections', selections);
    // console.log('selection', selection);

        useEffect(() => {
            props?.setLayoutReady?.(true);
    }, []);

    const renderContent = (cache, getJSONData) => {

        let data = [];
        let dataValue = null;

        if( props.useAux ){
            dataValue = cache?.[metric];
        }
        else{
            dataValue = cache?.[selection?.uid]?.play?.[metric];
        }
        dataValue = isNaN(dataValue) ? null : dataValue;
        if( props.divideByOneHundred ){
            dataValue = dataValue ? dataValue / 100.0 : null;
        }
        if( cache && dataValue ){
            data = [
                { value: dataValue, label: metric },
                { value: 1.0 - dataValue, label: 'Other' },
            ]
        }

        if (getJSONData && typeof getJSONData === 'function')
            getJSONData(data)

        return data ? <div className='content1'>
            <ResponsiveContainer>
                <PieChart >
                    <Pie isAnimationActive={true}
                        data={data}
                        dataKey='value'
                        innerRadius={80}
                        outerRadius={110}
                        xlabel={(obj) => `${obj.payload.label} ${format_slice(obj.payload.value)}` }
                    >
                       <Label value={dataValue !== null ? format(dataValue) : null} fontSize="30pt" position="center" />
                    {
                        data.map((entry, index) => 
                            <Cell key={index} fill={piecolors[index % piecolors.length]}
                            />
                        )
                    }
                    </Pie>
                    <Tooltip formatter={format_slice_tooltip}/>
                    
                </PieChart>
            </ResponsiveContainer>
        </div> : null;
    }
    
    return <CoreWidget1 {...props}  render={renderContent} />

}

