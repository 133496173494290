import * as d3 from 'd3';
import * as React from 'react';
import { useSelector } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import ReactWordcloud from "react-wordcloud";
import * as selectionHelper from '../../utils/selectionHelper';
import { CoreWidget1 } from './CoreWidget1';
import { format as d3Format } from 'd3-format';
import './WordCloud.scss';
const format_sentiment = d3Format('+0.2f');
const linearScale = d3.scaleLinear().domain([-0.5, 0, +0.5]).range(['#980000', '#cfcfcf', '#11759d']);
const getWordColor = ({ sentiment }) => linearScale(sentiment);
const getWordTooltip = ({ value, sentiment }) => `mentions: ${value}; sentiment: ${format_sentiment(sentiment)}`

let renderCounter = 0;

function Content1({ cacheData, scale }) {

    const node = React.useRef();
    if( !cacheData ) return null;

    let words = cacheData.map((entry, idx) => ({
        text: entry.word,
        value: entry.count,
        sentiment: entry.sentiment
    }))

    React.useEffect(() => {
        var xScale = d3
            .scaleLinear()
            .domain([-10, 40])
            .range([0, 600]);

        var legendScale = d3
            .scaleLinear()
            .domain([-10, 0, 10])
            .range(['#980000', '#cfcfcf', '#11759d']);

        d3.select(node.current)
            .select('#wrapper')
            .selectAll("rect")
            .data([-10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
            .enter()
            .append("rect")
            .attr("width", 11)
            .attr("height", 11)
            .attr("x", d => xScale(d))
            .style("fill", d => legendScale(d));

        d3.select(node.current)
            .select('#axis')
            .selectAll('text')
            .data(['Negative', 'Neutral', 'Positive'])
            .enter()
            .append("text")
            .text(d => d)
            .attr('transform', (d, i) =>
                i == 0 ? 'translate(-13, 28)' : i == 1
                    ? 'translate(105, 28)' : 'translate(220, 28)'
            )
            .style('font-size', '12px')
    }, [node.current])

    return (<>
        <ReactResizeDetector handleWidth handleHeight
            refreshMode="debounce"
            refreshRate={1000}
        >
            {({width, height}) => {
                if( !width || !height ) return <span/>;
                //console.log('wordcloud render', args);
                
                return <ReactWordcloud words={words} key={'render' + renderCounter++} size={[width, `${height>500 ? 500 : height-30}`]} minSize={[140, 140]}
                    options={{
                        deterministic: true,
                        fontSizes: [20, 40],
                        rotations: 2,
                        rotationAngles: [0, 90],
                        scale: scale, // linear, log, sqrt
                        padding:2
                        //size: ['100%', '100%']
                    }}
                    callbacks={{
                        getWordColor,
                        getWordTooltip
                    }}
                />;
            }}
        </ReactResizeDetector>
        <div className='legend'>
            <svg width='290px' height='30' ref={node}>
                <g id='wrapper' transform='translate(20, 0)'></g>
                <g id='axis' transform='translate(20, -4)'></g>
            </svg>
        </div>
    </>);
}

const MemoizedContent = React.memo(Content1);

export const WordCloud = (props) => {

    const [scale, setScale] = React.useState(props.scale || 'log')
    const study = useSelector(state => state.study);
    const selections = useSelector(state => state.selections);

    const selection = selectionHelper.getSelection(props, study, selections);

    // const q = (props.location?.search ? queryString.parse(props.location.search) : null) || {};
    // const q_wordPath = (q.path ? q.path.split('|') : null) || [];
    // console.log('q_wordPath', q_wordPath);
    // todo: add drill-down word path

    const wordPath = [selection?.label];

    const renderOptions = () => {
        return <>
            <p style={{ margin: 0, display: 'inline-block' }}>Scale:</p>
            {props.inDataCard && (
                <select value={scale} onChange={e => setScale(e.target.value)} style={{ margin: '0 8px' }}>
                    <option value='log'>log</option>
                    <option value='linear'>linear</option>
                    <option value='sqrt'>sqrt</option>
                </select>
            )}
            {props.inInsightCard && (<label className='ml-1'>{'  '}{scale}</label>)}
        </>
    }

    const renderContent = (cache, getJSONData) => {
        if (getJSONData && typeof getJSONData === 'function')
                getJSONData(cache?.data)
        return !cache ? null :
            <div className='wordCloud-head'>
                <MemoizedContent scale={scale} cacheData={cache?.data}/>
            </div>;
    }

    return <CoreWidget1 {...props}
        showSelection={true}
        options={renderOptions}
        render={renderContent}
        useBigData={{
            path: selection ? `GET:co-occurring-words-pagination-suv?pagination_iterator=100&search_terms=${encodeURI(wordPath.join('|'))}` : null
        }}
        getSaveState={_ => ({ scale })}
    />

}
