import { format as d3Format } from "d3-format";
import * as React from "react";
import {
	Bar,
	BarChart,
	LabelList,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import * as globals from "../../globals";
import { CoreWidget1 } from "./CoreWidget1";
import { useSelector } from "react-redux";
import CustomizedTick from "../common/CustomizedTick";

const format = d3Format(".1%");

export const DemogWidget = (props) => {
	const id = props.var;
	const filterGroups = useSelector((state) => state.filter?.filterGroups);
	const selectedFilter = useSelector((state) => state.filter?.selectedFilter);
	const study = useSelector((state) => state.study);
	const filterGroup = filterGroups?.find((fg) => fg.id === id);
	if (!filterGroup) return `meta not found for [${id}]`;

	const filters = filterGroup?.filters;

	const renderContent = (cache, getJSONData) => {
		if (!cache) return null;
		//console.log('cache', cache);

		const data = filters.map((f) => {
			const cell = cache?.results?.[f.id || f.syntax];
			return {
				label: f.label,
				value: cell?.value,
			};
		});

		if (getJSONData && typeof getJSONData === "function") getJSONData(data);

		return (
			<ResponsiveContainer width="100%" height="100%">
				<BarChart data={data} layout="vertical">
					<XAxis
						dataKey="value"
						type="number"
						tickFormatter={format}
						domain={[0, (dataMax) => dataMax * 1.2]}
					/>
					<YAxis
						dataKey="label"
						type="category"
						width={125}
						tick={<CustomizedTick wordBreakLimit="20" />}
					/>
					<Tooltip formatter={format} />
					<Bar
						dataKey="value"
						fill={globals.colors.primary1}
						isAnimationActive={false}>
						<LabelList dataKey="value" position="right" formatter={format} />
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		);
	};

	const query = {
		filters: [(selectedFilter || globals.getDefaultFilter(study))?.syntax],
		measures: filters.map((f) => {
			return {
				id: f.id || f.syntax,
				syntax: `pct(${f.syntax})`,
			};
		}),
	};

	return (
		<CoreWidget1
			{...props}
			useFilter={true}
			render={renderContent}
			useYak={{ query }}
		/>
	);
};
