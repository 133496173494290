import * as global from '../globals';
import { 
    ACCOUNT_LIST_USERS,
    USER_DISABLED, 
    USER_ENABLED, 
    USER_ENABLE_DISABLE 
    // @ts-ignore
} from './action-types';
import * as apiService from '../utils/apiService';

export interface AccountState{

    users: any;

}

const initialState: AccountState = {
    users: null,
};

export const actionCreators = {
    getStudies: (callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();

        try {
            const response = await apiService.aGet(state.auth, `${global.apiRoot}/study/allstudies`);

            const data = await response.json();

            if (callback) callback(data);
        } catch (err) {
            if (callback) callback(null);
        }

    },

    getStudyUsers: (studyId: string, stats: any, callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();
        console.log('stats', stats);

        try {
            const response = await apiService.aGet(state.auth, `${global.apiRoot}/study/${studyId}/users?include_stats=${stats}`);            
            const data = await response.json();

            if (callback) callback(data);
        } catch (err) {
            if (callback) callback(null);
        }

    },

    listUsers: () => async (dispatch: any, getState: any) => {

        const state = getState();

        try {
            const response = await apiService.aGet(state.auth, `${global.apiRoot}/account/list-users`);

            const data = await response.json();

            dispatch({ type: ACCOUNT_LIST_USERS, value: data });
        } catch (err) {
            dispatch({
                type: ACCOUNT_LIST_USERS,
                //value: { uid: uid },
                error: 'error fetching data'
            });
        }
    },

    setUserEnabled: (uid: string, isEnabled: boolean, callback: any) => (dispatch: any, getState: any) => {

        const state = getState();

        try {

            if (isEnabled) {
                apiService.aGet(state.auth, `${global.apiRoot}/user/${uid}/enable`)
                .then((res) => res.text())
	            .then(() => {
                    if (callback) callback();
                })
	            .catch((error) => {
		            console.log(error)
	            });
    
                dispatch({ type: USER_ENABLED });

                
            } else {
                apiService.aGet(state.auth, `${global.apiRoot}/user/${uid}/disable`)
                .then((res) => res.text())
	            .then(() => {
                    if (callback) callback();
                })
	            .catch((error) => {
		            console.log(error)
	            });
    
                dispatch({ type: USER_DISABLED });
            }
            
        } catch (err) {
            console.log(err);
            // dispatch({
            //     type: USER_ENABLE_DISABLE,
            //     value: { uid },
            //     error: 'error fetching data'
            // });
        }
    }
};

export const reducer = (state: AccountState, action: any): AccountState => {
    state = state || initialState;

    switch (action.type) {

        case ACCOUNT_LIST_USERS: 
            return {
                ...state,
                users: action.value
            }

        case USER_ENABLED:
        case USER_DISABLED:
            return {
                ...state,
                ...action.value
            }

        // case USER_ENABLE_DISABLE:
        //     return {
        //         ...state,
        //         value: action.value
        //     }

        default:
            return state;

    }
};
