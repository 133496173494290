import * as d3 from 'd3';
import * as React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import './DotPlot.scss';

class DotPlot extends React.Component {

    componentDidMount(){
        this.updateSvg();
    }

    componentDidUpdate(){
        this.updateSvg();
    }

    node;

    originX = 0;
    originY = 0;
    zoom = 1.0;
    in_out = '';

    margin = {
        top: 0,
        bottom: 10,
        left: 10,
        right: 100
    }

    svgHeight = 0;
    svgWidth = 0;

    
    updateSvg = () => {

        let data = this.props.data;
        if( !data ) return;

        data = [ ...data ] // copy?

        
        //console.log('updateSvg with data', data);

        // data = d3.csvParse(
        //     await FileAttachment("us-population-state-age.csv").text(),
        //     (d, i, columns) => (
        //         d3.autoType(d),
        //         d.total = d3.sum(columns, c => d[c]),
        //         columns.slice(1).forEach(c => d[c] /= d.total),
        //         d
        //     )
        // )
        
        try{

            const domNode = this.node;
            if( !domNode ) return;

            const width = domNode.clientWidth;
            const height = domNode.clientHeight;
            if( !width || !height ) return;

            const margin = this.margin;

            // this.svgWidth = width;
            // this.svgHeight = height;

            const names = data.map(d => d.name);
            //const keys = data.columns.slice(1);
            const keys = ["<10","10-19","20-29","30-39","40-49","50-59","60-69","70-79","≥80"];

            const color = d3.scaleOrdinal()
                .unknown("#ccc")
                .domain(keys)
                .range(d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), keys.length).reverse());

            const legend = svg => {
                
                const g = svg
                    .attr("font-family", "sans-serif")
                    .attr("font-size", 10)
                    .attr("text-anchor", "end")
                    .attr("transform", `translate(${width - 10},${margin.top})`)
                .selectAll("g")
                .data(keys.slice().reverse())
                .join("g")
                    .attr("transform", (d, i) => `translate(0,${i * 20})`);
            
                g.append("rect")
                    .attr("x", -19)
                    .attr("width", 19)
                    .attr("height", 19)
                    .attr("fill", color);
            
                g.append("text")
                    .attr("x", -24)
                    .attr("y", 12.5)
                    .text(d => d);
            }

            const x = d3.scaleLinear()
                .domain([0, d3.max(data, d => d3.max(keys, k => d[k]))])
                .rangeRound([margin.left, width - margin.right]);

            const y = d3.scalePoint()
                .domain(names)
                .rangeRound([margin.top, height - margin.bottom])
                .padding(1);

            const xAxis = g => g
                .attr("transform", `translate(0,${margin.top})`)
                .call(d3.axisTop(x).ticks(null, "%"))
                .call(g => g.selectAll(".tick line").clone().attr("stroke-opacity", 0.1).attr("y2", height - margin.bottom))
                .call(g => g.selectAll(".domain").remove());

            const svg = d3.select(this.node);

            //svg.remove();
            svg.selectAll("*").remove();

            svg.append("g")
                .call(xAxis);

            const g = svg.append("g")
                .attr("text-anchor", "end")
                .style("font", "10px sans-serif")
                .selectAll("g")
                .data(data)
                .join("g")
                .attr("note", (d) => d.name)
                .attr("transform", (d, i) => `translate(0,${y(d.name)})`);


            g.append("line")
                .attr("stroke", "#aaa")
                .attr("x1", d => x(d3.min(keys, k => d[k])))
                .attr("x2", d => x(d3.max(keys, k => d[k])));

            g.append("g")
                .selectAll("circle")
                .data(d => d3.cross(keys, [d]))
                .join("circle")
                .attr("cx", ([k, d]) => x(d[k]))
                .attr("fill", ([k]) => color(k))
                .attr("r", 3.5);

            g.append("text")
                .attr("dy", "0.35em")
                .attr("x", d => x(d3.min(keys, k => d[k])) - 6)
                .text((d, i) => d.name);

            svg.append("g")
                .call(legend);

            function update(primary) {
                // Compute the desired order based on the selected age group.
                y.domain(d3.permute(names, d3.range(data.length)
                    .sort((i, j) => data[j][primary] - data[i][primary])));

                // Transition the columns to their new position.
                g.transition()
                    .delay((d, i) => i * 10)
                    .attr("transform", d => `translate(0,${y(d.name)})`)
            }

            Object.assign(svg.node(), {update});


        }
        catch(err){
            console.error(err);
        }

    }

   

    render(){

        return <ReactResizeDetector handleWidth handleHeight>
            {({ width, height }) => {
                //console.log('resize render', width, height)
                this.svgWidth = width;
                this.svgHeight = height;
                this.updateSvg(width, height);
                return <svg id='svg1' className='dotplot' ref={(node) => this.node = node}>
                </svg>
            }
            }
        </ReactResizeDetector>

    }
}
      
export default DotPlot;