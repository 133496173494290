import * as React from 'react';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as globals from '../../globals';
import * as apiService from '../../utils/apiService';
import './ContentViewer.scss';
import { ContentViewer } from './ContentViewer';
import * as jsCompiler from '../../utils/jsCompiler';

const isNull = (x) => x === null || x === undefined;

/** Loads an item fully (its title, description, props, etc, and a list of the ids of its children).
 * Then once loaded, presents the item using ContentViewer
*/
export const ContentLoader = (props) => {
    
    const id = props.id;

    if (props.edit?.editMode) {
        // show footers
    }
    else{
        if ((id === 'footer' || id === 'footer_mini') && !props.allowFooter) return null;
        if (id === 'main-menu' && !props.allowMainMenu) return null;
    }

    const [item, setItem] = useState(null);
    const [reloadItemCounter, setReloadItemCounter] = useState(0);
    const pageLevel = isNull(props.pageLevel) ? 0 : props.pageLevel + 1;

    const [layoutReadyIntercept, setLayoutReadyIntercept] = useState(false);
    
    const setLayoutReady = (val) => {
        setLayoutReadyIntercept(val)
        props.setLayoutReady?.(val)
    }

    const reloadItem = () => {
        setReloadItemCounter(reloadItemCounter + 1)
    }

    const reloadParentItem = () => {
        props.reloadParentItem?.()
        reloadItem()
    }

    const auth = useSelector(state => state.auth);
    const study_uid = useSelector(state => state.study?.uid);
    const NO_ITEM = {};

    // get content item
    useEffect(() => {
        let mounted = true;
        if (!study_uid) return;
        if (!id) return;

        var queryparams = [];
        if (props?.restore?.restoreMode) {
            queryparams.push("showDeleted=true");
        }
        if (props.edit?.editMode) {
            queryparams.push("editMode=true");
        }
        
        apiService.aGet(auth, `${globals.apiRoot}/study/${study_uid}/content3/${id}${queryparams.length > 0 ?  '?' + queryparams.join('&') : ''}`)
            .then(res => {
                return res.ok ? res.json() : NO_ITEM;
            })
            .then(item => {
                item = jsCompiler.compileItem(item);
                if (mounted) {
                    setItem(item);
                }
            }).catch(err => {
                console.error(`handled contentloader error loading [${id}]`, err)
                props?.setLayoutReady?.(true);
            });

        return () => mounted = false;

        //.catch(err => console.error(err))
    }, [id, study_uid, reloadItemCounter, props?.restore?.restoreMode]);

    const hide = !props.edit?.editMode && item?.data?.hide;

    const wrapme = (c) => {
        if (c == false)
            return (
                    <div>[{item.id}({item.type})]        
                        :
                        {
                            layoutReadyIntercept ?
                                <span style={{ color: 'green' }}>READY</span>
                                :
                                <span style={{ color: 'red' }}>NOT READY</span>
                        }
                        {c}
                    </div>
            ) 
        else
            return c
    }
    
    return item === NO_ITEM || item === null ? null :
        item && hide ? <Hidden item={item} setLayoutReady={props.setLayoutReady}/> :
            wrapme(<ContentViewer
                    item={item}
                    key={reloadItemCounter}
                    pageLevel={pageLevel}
                    rkey={reloadItemCounter}
                    asPage={props.asPage}
                    carouselInfo={props.carouselInfo}
                    footer={props.footer}
                    edit={props.edit}
                    restore={props?.restore}
                    reloadItem={reloadItem}
                    restoredItem={props?.restoredItem}
                    reloadParentItem={reloadParentItem}
                    instanceContext={props.instanceContext}
                    layoutReady={props.asPage ? props.layoutReady : true}
                    setLayoutReady={setLayoutReady}
                    parentItem={props?.parentItem}
                    style={props?.style}
                    classes = {props?.classes}
                />)                

}

const Hidden = (props) => {

    useEffect(() => {
        props.setLayoutReady?.(true);
    }, [props.item])

    return null;
}

// return item === NO_ITEM || item === null ? null :
//         item && hide ? <Hidden item={item} setLayoutReady={props.setLayoutReady}/> :
//             item ? wrapme(<ContentViewer
//                     item={item}
//                     key={reloadItemCounter}
//                     pageLevel={pageLevel}
//                     rkey={reloadItemCounter}
//                     asPage={props.asPage}
//                     carouselInfo={props.carouselInfo}
//                     footer={props.footer}
//                     edit={props.edit}
//                     restore={props?.restore}
//                     reloadItem={reloadItem}
//                     restoredItem={props?.restoredItem}
//                     reloadParentItem={reloadParentItem}
//                     instanceContext={props.instanceContext}
//                     layoutReady={props.asPage ? props.layoutReady : true}
//                     setLayoutReady={setLayoutReady}
//                     parentItem={props?.parentItem}
//                 />)
                
//             : <div key={reloadItemCounter} className='content-loader'>loading...</div>;