import { format } from 'd3-format';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import { actionCreators } from '../../store/BigData';
import * as selectionHelper from '../../utils/selectionHelper';
import { ProductBlockMini } from '../product-drawer/ProductBlockMini';
import './DCMConfigWidget.scss';

export class DCMConfigWidget extends React.Component{

    constructor(props){
        super(props);
        this.state = {
        };

        // Allow card to be saved as insight card. Not the greatest pattern.
        this.props.stateContext.getSaveState = this.getSaveState;
    }

    static inheritOptions = ['configEtag'];

    static scenarioDriven = true;

    getSaveState = () => {

        return {
            selectedProductId: this.props.selectedProductId,
            config: this.props.config
        }

    }

    render() {

        const study = this.props.study;
        if( !study?.config ) return null;

        const config = this.props.config;
        if( !config ) return "no config";

        const selectedProductId = this.props.selectedProductId;
        const selectedProduct = selectedProductId ? selectionHelper.getSelectionInfo('product', selectedProductId, this.props.study, this.props.selections?.hierarchy) : null;

        const slot = config ? config.productSlots.find(s => s.uid === selectedProductId) : null;
        //if( !slot ) return "no product";

        const pdef = slot ? study.config.productDefs[slot.def] : null; // get this from study, because a saved config shouldn't have productDefs. It does right now, but it shouldn't.
        //if( !pdef ) return "no pdef " + slot.def;

        if (this.props.getJSONData && typeof this.props.getJSONData === 'function')
            this.props.getJSONData((pdef?.atts || []).filter(i => !i.readOnly).map(att => {
                const v = slot.play[att.name] || att.defaultValue
                return {
                    label: att.label,
                    levels: att.type === 'contin' ? format(att.format)(v) : // todo format it using the att's format
                    att.type === 'discrete' ? (att.levels.find(l => l.value === v) || {}).label :
                    v,
                }
            }))
        
        const content = config.loading ? 'loading...' :
            <div className='table-container' style={{ fontFamily: "consolas 'courier new' monospace"  }}>
                <table>
                    <tbody>
                        {(pdef?.atts || [])
                            .filter(att => !att.readOnly)
                            .map(att => {
                                const v = slot.play[att.name] || att.defaultValue;
                                const bv = slot.base[att.name] || att.defaultValue;
                                const dirty = v != bv;
                                return <tr key={att.name}>
                                    <td className={'label' + (dirty ? ' dirty' : '')}>
                                        {att.label}
                                    </td>
                                    <td className={'value' + (dirty ? ' dirty' : '')}>
                                        {
                                            att.type === 'contin' ? format(att.format)(v) : // todo format it using the att's format
                                            att.type === 'discrete' ? (att.levels.find(l => l.value === v) || {}).label :
                                            v
                                        }
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>;
        if ( this.props.loaded && typeof this.props.loaded === 'function')
            this.props.loaded(true)

        return <div className='widget dcm-config-widget'>
            <div className='widget-header'>
                <div className='title'>
                    {this.props.title || 'CONFIGURATION WHAT-IF'}
                </div>
                <div className='selection'>
                    <ProductBlockMini {...selectedProduct} disabled={this.props.inInsightCard} allowChange={['product']}/>                    
                </div>
            </div>
            <div className='widget-body vscroll'>
                {content}
            </div>
            
        </div>;

    }

}

DCMConfigWidget = connect(
    (state, ownProps) => ({
        study: state.study,
        selections: state.selections,
        ...(ownProps.inDataCard ? {
            config: (state.study || {}).config,
            selectedProductId: state.selections?.product?.uid
        } : null)
    })
    //,dispatch => bindActionCreators(actionCreators, dispatch)
)(DCMConfigWidget)
