import * as React from 'react';
import { useSelector } from 'react-redux';
import * as selectionHelper from '../../utils/selectionHelper';
import ScatterPlot from '../vis/ScatterPlot2';
import { CoreWidget1 } from './CoreWidget1';
import './CorrespondenceMap.scss';

const axisItems = [
    { key: "1", label: "Dim1" },
    { key: "2", label: "Dim2" },
    { key: "3", label: "Dim3" },
    { key: "4", label: "Dim4" },
    { key: "5", label: "Dim5" },
    { key: "6", label: "Dim6" },
    { key: "7", label: "Dim7" },
    //{ key: "8", label: "Dim8" },
]

export const CorrespondenceMap = (props) => {

    const study = useSelector(state => state.study);
    const selections = useSelector(state => state.selections);
    const selection = selectionHelper.getSelection(props, study, selections);
    
    const [x, setX] = React.useState(props.x || axisItems[0].key);
    const [y, setY] = React.useState(props.y || axisItems[1].key);

    const renderOptions = () => {
        return <div>
            {props.inDataCard && (
                <>
                    <select
                        value={y}
                        onChange={ev => setY(ev.target.value)}>
                        {axisItems.map(i => <option key={i.key} value={i.key}>{i.label}</option>)}
                    </select>
                    <select
                        value={x}
                        onChange={ev => setX(ev.target.value)}>
                        {axisItems.map(i => <option key={i.key} value={i.key}>{i.label}</option>)}
                    </select>
                </>
            )}
            {props.inInsightCard && (
                <>
                    <label className='ml-1'>{axisItems.filter(i => i.key === y)[0]?.label}</label>{' '}<label>{axisItems.filter(i => i.key === x)[0]?.label}</label>
                </>
            )}
        </div>
    }

    const path = props.path || 'correspondence-analysis';
    const kind = props.kind || 'brand'; //'brand' 'core-motive' 'emotion' 'brand-promise'

    const renderContent = (cache, getJSONData) => {
        //const data = cache; // todo: transform it
        
        const data = cache?.map(e => ({
            ...e,
            color: e.kind === 'brand' ? '#4287fa' : '#ffc000'
        }))
            //.filter((x, idx) => idx < 60);
        ;
        
        const highlight = [
            {
                label: selection?.label
            }
        ]
        if (getJSONData && typeof getJSONData === 'function')
            getJSONData(data)
        return <ScatterPlot data={data} highlight={highlight} hideAxis={true} x={{ key: 'x'}} y={{ key: 'y' }} />;
    }

    let useProp = props.useAux ? {
        useAux: {
            ...props.useAux,
            body: {
                ...props.useAux?.body,
                coordinates: {
                    x: x,
                    y: y
                }
            },
            key: x + ',' + y
        }
    } : {
        useAnalytics: {
            path: `${path}?x=${x}&y=${y}&kind=${kind}`,
            method: 'get' 
        }
    }
    //console.log('useProp', useProp, props);

    return <CoreWidget1 {...props}
        useSelection={true}
        options={renderOptions}
        getSaveState={() => ({ x, y })}
        render={renderContent} 
        showFilter
        {...useProp}
        //useAnalytics={{ path: `${path}?x=${x}&y=${y}&kind=${kind}`, method: 'get' }} 
        />;

    
}
