import * as React from 'react';
import { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as globals from '../../globals';
import { actionCreators as forecastActionCreators } from '../../store/Forecast.ts';
import * as debounce from '../../utils/debounce';
import { CardLoading } from '../card/CardLoading';
import { FilterChooser } from '../filter/FilterChooser';
import { TableFred } from '../vis/TableFred';
import './DCMProductsTable.scss';
import * as forecastHelper from '../../utils/forecastHelper';
import { CoreWidget1 } from './CoreWidget1';

export const DCMProductsTableB = (props) => {

    const config = useSelector(state => state.study?.config);
    const [selMetric, setSelMetric] = useState(props.selMetric || "share")
    const metric = globals.metrics.find(metric => metric.key === selMetric);
    const [selAggLevel, setSelAggLevel] = useState(props.selAggLevel || 'product');

    const study = useSelector(state => state?.study);
    if( !study ) return null;

    const aggLevels = [
        ...study.selectionHierarchy,
        { name: 'product', label: study.productLabel }
    ];
    const _selAggLevel = aggLevels.find(l => l.name === selAggLevel);

    const columns = [
        { 
            key: 'label', 
            type: 'string', 
            className: 'label sticky', 
            label: _selAggLevel?.label
        },
        {
            key: 'playResult',
            type: 'cell',
            label: globals.configs2.play.label,
            format: (val) => val === null ? '.' : metric.fformat(val),
            className: 'data right-align ' + (metric.key === 'consider' ? ' consideration' : ''),
        },
        {
            key: 'baseResult',
            type: 'cell',
            label: globals.configs2.base.label,
            format: (val) => val === null ? '.' : metric.fformat(val),
            className: 'data right-align ' + (metric.key === 'consider' ? ' consideration' : ''),
        },
        {
            key: 'gapResult',
            type: 'cell',
            label: 'Gap',
            format: (val) => val === null ? '.' : metric.fformat(val),
            className: 'data right-align ' + (metric.key === 'consider' ? ' consideration' : ''),
        }
    ];

    
       
    const renderContent = (cache, getJSONData) => {
        if( !cache ) return null;
        if( !config ) return null;

        // aggregate
        const acache = forecastHelper.aggregate(cache, selAggLevel, study);

        // sort
        acache.sort((a, b) => a.label.localeCompare(b.label));
        
        let rowData = acache.map(entry => {
            const result = entry.data;
            const playResult = { value: result?.play?.[selMetric], n: undefined };
            const baseResult = { value: result?.base?.[selMetric], n: undefined };
            const gapResult = { value: playResult?.value - baseResult?.value, n: undefined };
        
            return {
                label: entry.label,
                playResult,
                baseResult,
                gapResult
            }
        });

        if (getJSONData && typeof getJSONData === 'function' && columns && rowData)
            getJSONData({ columns: [...columns], data: [...rowData], metric: selMetric })
        
        return <TableFred getJSONData={getJSONData} columns={columns} data={rowData}/>
    };

    const renderOptions = () => {
        //return //props.inInsightCard ? null :
        return <div className='filter-etc'>
            {props.inInsightCard ? <>
                Type: <label className='ml-1' style={{ marginRight: '1rem' }}>{aggLevels.find(i => selAggLevel === i.name).label}</label>
                Metric: <label className='ml-1'>{globals.metrics.filter(i => i.key === selMetric)[0].label}</label>
            </> : <div className='agg-level inline'>
                    {aggLevels.map(aggLevel => <span key={aggLevel.name} onClick={() => setSelAggLevel(aggLevel.name)} 
                    className={'btn' + (selAggLevel === aggLevel.name ? ' sel' : '')}>
                        {aggLevel.label}
                    </span>)}
                </div>
                }
                <div className='sel-metric'>
                    {props.inDataCard && (
                        <select value={selMetric} onChange={(ev) => setSelMetric(ev.target.value)}>
                            {globals.metrics.map(metric => 
                                <option value={metric.key} key={metric.key}>{metric.label}</option>
                            )}
                        </select>
                    )}
                </div>
            </div>
    }

    return <CoreWidget1 {...props} 
        class1='dcm-products-table-c'
        class2="vscroll hscroll"
        showSelection={false}
        useScenario={true}
        useFilter={true}
        options={renderOptions}
        // options={renderOptions}
        render={renderContent}
        getSaveState={() => {
            return {
                selAggLevel,
                selMetric
            }
        }}
        useForecast={{
            //eTag: eTag + "",
        }}
        />




}

