import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators as storiesActionCreators } from '../../store/Stories.ts';
import { Avatar } from '../common/Avatar';
import { Markdown } from '../common/Markdown';
import './StoryComments.scss';

export class StoryComments extends React.Component {
    
    constructor(props){
        super(props);
        window.moment = moment;
        
        this.state = {
            collapseComments: this.props.collapseComments || false,
            edit: {
                isEditing: false,
                commentId: ''
            },
            showOption: false,
            user_comment_action_popup: ""
        }
        this.userAction = React.createRef();
        this.commentBox = React.createRef();
    }

    componentDidMount(){
        this.checkData();
        this.commentScroll();
    }

    componentDidUpdate(){
        this.checkData();
    }

    checkData = () => {
        // todo: make sure the story is loaded
        // can skip this for now, because all components that use this component will trigger the story load if needed
    }

    handleFavoriteClicked = () => {
        this.toggleReaction('isFavorite');
    }

    toggleReaction = (reaction) => {
        const story = this.props.story;
        if( !story ) return;
        this.props.react(story.uid, reaction, !this.props[reaction]);
    }

    handleCollapseCommentsClicked = () => {
        this.setState({
            collapseComments: !this.state.collapseComments
        })
    }

    postComment(event) {
        const storyId = this.props.storyId;

        if (event.key === 'Enter' && !event.shiftKey && event.target.value !== '') {
            if (!this.state.edit.isEditing) {
                this.props.postComment(storyId, event.target.value, () => {
                    this.commentScroll();
                });
            } else {
                let commentId = this.state.edit.commentId;
                let newContent = event.target.value;
                this.props.editComment(storyId, commentId, newContent, () => {
                    this.setState({
                        edit: {
                            isEditing: false,
                            commentId: ''
                        }
                    });
                });
            }
            event.target.value = '';
        }
    }

    deleteComment(commentId) {
        this.props.deleteComment(this.props.storyId, commentId, () => {
                this.setState({ 
                    user_comment_action_popup: ""
                });
            }
        );
    }

    editComment(content, commentId) {
        this.commentBox.current.value = content;
        this.commentBox.current.focus();
        this.setState({
            edit: {
                isEditing: true,
                commentId
            },
            user_comment_action_popup: ""
        });
    }
  

    actionSpanPopup(commentId){
        this.setState({
            user_comment_action_popup: this.state.user_comment_action_popup !== commentId ? commentId : ""
        });
    }

    commentScroll(){
        var comments = document.getElementsByClassName('commentBody');
        for (var i = 0; i < comments.length; i++) {
            comments[i].scrollTop = comments[i].scrollHeight - comments[i].clientHeight;
        }
    }

    render() {

        const story = this.props.story;
        if( !story ) return null;

        const studyId = this.props.match.params.studyId;

        const user = this.props.user;

        const maxNbComments = this.props.maxNbComments || 0;


        const comments = this.props.comments || [];
        const sortedComments = comments.sort((a, b) => a.created < b.created ? -1 : a.created > b.created ? 1 : 0);
        const limitedComments = maxNbComments ? sortedComments.filter((x,i) => i >= sortedComments.length - maxNbComments) : sortedComments;


        const commentElements = limitedComments.map((comment, idx) => {
            var isAuthor = user.id === comment.authorUid;
            return (
                <li key={idx} className={"comment-content" + (isAuthor ? " own-comment" : "")}>
                    <Avatar uid={comment.authorUid} tiny={true} />
                    <div className='speach-bubble'>
                        <div className="author">
                            <div className="author-info">
                                <a href="javascript:void(0);">
                                    <b>{ comment.authorName }</b>
                                </a>
                                <span className="comment-time">
                                {
                                    comment.modified
                                    ? moment().isBefore(comment.modified)
                                        ? 'just now' : moment.utc(comment.modified).local().fromNow() :
                                            moment().isBefore(comment.created) ? 'just now' : moment.utc(comment.created).local().fromNow()
                                }
                                </span>
                            </div>
                            <div className="user-comment"><Markdown data={comment.content} /></div>
                        </div>
                        <div className="user-action">
                            <a onClick={(e) => this.actionSpanPopup(comment.uid)} className={this.state.user_comment_action_popup == comment.uid ? "show" : ""}><i className="fas fa-ellipsis-h">&nbsp;</i></a>
                            <ul className={"user-action-list " + (this.state.user_comment_action_popup == comment.uid ? "show" : "")}>
                                <li onClick={(e) => this.editComment(comment.content, comment.uid)}>Edit</li>
                                <li onClick={(e) => this.deleteComment(comment.uid)}>Delete</li>
                            </ul>
                        </div>
                    </div>
                </li>
            );
        });

        const nbComments = comments.length;


        return <div className={`${this.props.className} story-comments ${(maxNbComments ? 'limit-comments' : '')}`}>

            <div className="engagement-summary">
                <div className="likes" onClick={this.handleFavoriteClicked}>
                    <span className={"glyph" + (this.props.isFavorite ? " sel" : "")}>
                        <i className={this.props.isFavorite ? "fas fa-heart fav" : "fal fa-heart"}/>
                    </span>
                    <span className="text">Favorite</span>
                </div>

                <div className="comments" onClick={this.handleCollapseCommentsClicked} >
                    <span className="glyph">
                        <i className="fal fa-comments"/>
                    </span>
                    <span className="text">
                    { nbComments === 1 ? '1 Comment' : `${nbComments} Comments` }
                    </span>
                </div>
            </div>

            {!maxNbComments || !this.state.collapseComments ? <>
                {
                    comments.length > limitedComments.length ?
                        <div className="show-more">
                            <Link className="show_more" to={`/study/${studyId}/feed/post/${this.props.postId}/comments`}>Show more comments</Link>
                        </div> : null
                }

                <ul className={`comments commentBody ${(maxNbComments ? ' top-comments' : ' all-comments')}`}>
                    { 
                        commentElements.length > 0 ? commentElements : null //<li className="comment-content no-comment">No comments</li> 
                    }
                </ul>

                <div className={'post-box'}>
                    <Avatar uid={user.id} mini={true}/>
                    <textarea ref={this.commentBox} type="text" className="comment-input" placeholder="write a comment..." onKeyUp={(e) => { this.postComment(e); }} />
                </div>
            </>
            : null}
        </div>
    }


}

StoryComments = withRouter(
    connect(
        (state, ownProps) => { 
            const story = state.stories && state.stories.dict ? state.stories.dict[ownProps.storyId] : null;
            return {
                user: state.auth.user,
                story,
                isFavorite: story && story.isFavorite,
                comments: story && story.comments
            }
        },
        dispatch => bindActionCreators(storiesActionCreators, dispatch)
    )(StoryComments)
);
