import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as forecastActionCreators } from "../../store/Forecast.ts";

const barStyle = {
	fontSize: "8pt",
	fontFamily: "monospace",
	backgroundColor: "#efefef",
	padding: 4,
	borderBottom: "1px solid #990000",
	marginBottom: 5,
};

const keyStyle = {
	fontWeight: "bold",
	color: "#808080",
	marginRight: 5,
};

const valueStyle = {
	color: "#afafaf",
};

const idStyle = {
	color: "#0064c8",
};

const upToDateStyle = {
	backgroundColor: "#009900",
	color: "#ffffff",
	padding: "0px 4px",
};

const needsUpdateStyle = {
	backgroundColor: "#990000",
	color: "#ffffff",
	padding: "0px 4px",
};

const errorStyle = {
	backgroundColor: "purple",
	color: "#ffffff",
	padding: "0px 4px",
	cursor: "pointer",
};

const loadingStyle = {
	backgroundColor: "#0064c8",
	color: "#ffffff",
	padding: "0px 4px",
};

const separatorStyle = {
	marginRight: 10,
};

const btnStyle = {
	color: "#990000",
	marginRight: 10,
	fontWeight: "bold",
	cursor: "pointer",
};

const rowStyle = {
	marginBottom: 5,
};

export class ScenarioWidgetDebugBar extends React.Component {
	refresh = () => {
		this.props.requestForecast();
	};

	debug = () => {
		console.log("props", this.props);
	};

	debug2 = () => {
		const ss = this.props.stateContext.getSaveState
			? this.props.stateContext.getSaveState()
			: null;
		console.log("saveState", ss);
	};

	render() {
		if (!globals.debugCards) return null;

		{
			let cache = this.props.cache;
			console.log("debug cache", cache);
			let filter =
				this.props.filter || globals.getDefaultFilter(this.props.study);

			let etag = this.props.etag
				? this.props.etag.substring(1, 6) + "..."
				: null;
			let cacheStatus = !cache ? (
				<span style={needsUpdateStyle}>no data</span>
			) : cache.loading ? (
				<span style={loadingStyle}>loading</span>
			) : cache.etag !== this.props.etag ? (
				<span style={needsUpdateStyle}>needs update</span>
			) : cache.error ? (
				<span style={errorStyle} title={cache.error}>
					error <i className="fal fa-question-circle" />
				</span>
			) : (
				<span style={upToDateStyle}>up-to-date</span>
			);

			return (
				<div style={barStyle}>
					<div style={rowStyle}>
						<span style={keyStyle}>widget:</span>
						<span style={idStyle}>{this.props.entry.uid}</span>
						<span style={separatorStyle}>;</span>
						<span style={keyStyle}>type:</span>
						<span style={valueStyle}>{this.props.entry.type}</span>
					</div>
					{this.props.inDataCard ? (
						<div style={rowStyle}>
							<a style={btnStyle} onClick={() => this.refresh()}>
								<i className="fas fa-sync" />
							</a>

							<span style={keyStyle}>etag:</span>
							<span style={valueStyle}>{etag}</span>
							<span style={separatorStyle}>;</span>
							<span style={keyStyle}>cache-status:</span>
							{cacheStatus}
							<span style={separatorStyle}>;</span>
						</div>
					) : null}
					<div style={rowStyle}>
						<span style={keyStyle}>filter:</span>
						<span style={valueStyle}>{filter.syntax}</span>
					</div>
					<div style={rowStyle}>
						<span style={keyStyle}>inheritOptions:</span>
						<span style={valueStyle}>
							{JSON.stringify(this.props.entry.widget.inheritOptions)}
						</span>
						<span style={separatorStyle}>;</span>
						{this.props.inDataCard ? (
							<a
								style={btnStyle}
								onClick={() => this.debug()}
								title="log props">
								<i className="fas fa-toolbox" />
							</a>
						) : this.props.inInsightCard ? (
							<a
								style={btnStyle}
								onClick={() => this.debug2()}
								title="log saveState">
								<i className="fas fa-rocket" />
							</a>
						) : null}
					</div>
				</div>
			);
		}

		return <div>context missing for debug bar</div>;
	}
}

ScenarioWidgetDebugBar = connect(
	(state) => ({
		study: state.study,
		etag: ((state.study || {}).config || {}).etag,
		//cache: (state.cache || {}).forecast,
		filter: (state.study || {}).selectedFilter,
	}),
	(dispatch) => bindActionCreators(forecastActionCreators, dispatch),
)(ScenarioWidgetDebugBar);
