import { format as d3Format } from "d3-format";
import * as React from "react";
import { connect } from "react-redux";
import {
	Bar,
	BarChart,
	CartesianGrid,
	LabelList,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as analyticsActionCreators } from "../../store/Analytics.ts";
import { CardLoading } from "../card/CardLoading";
import { FilterChooser } from "../filter/FilterChooser";
import { ProductBlockMini } from "../product-drawer/ProductBlockMini";
import CustomLegend from "../common/CustomLegend";
import CustomizedTick from "../common/CustomizedTick";

class CustomizedAxisTick1 extends React.Component {
	render() {
		//console.log('props', this.props);
		const {
			x,
			y,
			payload,
			width,
			maxChars,
			lineHeight,
			fontSize,
			fill,
		} = this.props;
		//return <Text x={x} y={y} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>;
		//return ( <Text x={x} y={y} textAnchor="end" verticalAnchor="start" angle={-45} fill="#333">{payload.value}</Text> );
		//return ( <g transform={`translate(${x},${y})`}><Text width={100} scaleToFit textAnchor="end" verticalAnchor="start" angle={-45} fill="#333">{payload.value}</Text></g> );
		//return ( <g transform={`translate(${x},${y})`}><Text width={50} scaleToFit textAnchor="middle" verticalAnchor="start" angle={0} fill="#333">{payload.value}</Text></g> );
		//console.log('payload', payload);
		const rx = new RegExp(`.{1,${maxChars}}`, "g");
		const chunks = payload.value
			.replace(/-/g, " ")
			.split(" ")
			.map((s) => s.match(rx))
			.flat();
		//console.log('chunks', chunks);
		const tspans = chunks.map((s, i) => (
			<tspan key={i} x={0} y={lineHeight} dy={i * lineHeight}>
				{s}
			</tspan>
		));
		return (
			<g transform={`translate(${x},${y})`}>
				<text
					width={width}
					height="auto"
					textAnchor="middle"
					fontSize={fontSize}
					fill={fill}>
					{tspans}
				</text>
			</g>
		);
	}
}

CustomizedAxisTick1.defaultProps = {
	//width: 50,
	maxChars: 10,
	fontSize: 12,
	lineHeight: 14,
	fill: "#333",
};

export class SurveyBarViewer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};

		// Allow card to be saved as insight card. Not the greatest pattern.
		this.props.stateContext.getSaveState = this.getSaveState;
	}

	componentDidMount() {
		this.refreshIfNeeded();
	}

	componentDidUpdate() {
		if (this.props.cache && !this.props.cache.loading) {
			if (this.props.loaded && typeof this.props.loaded === "function")
				this.props.loaded(!this.props.cache.loading);
		}
		this.refreshIfNeeded();
	}

	refreshIfNeeded = () => {
		// cancel if nothing selected (what about props.needsSelection!?)
		const selection = this.props.selection;
		if (!selection) return;

		// cancel if insight card
		if (this.props.inInsightCard) return;

		// cancel if cached data already exists
		if (this.props.cache) return; // insights card

		if (!this.props.cache) {
			const rootPath = this.props.path;
			const filter = this.props.filter;
			const path = `${rootPath}:${this.props.selection.uid}`;
			const key = `${rootPath}:${this.props.selection.uid}:${filter.syntax}`;
			const query = this.props.getQuery(selection, filter);
			this.props.loadYak(path, key, query);
		}
	};

	static inheritOptions = [];

	getSaveState = () => {
		return {
			selection: this.props.selection,
			cache: this.props.cache,
			filter: this.props.filter,
			var: this.props.var,
		};
	};

	render() {
		const study = this.props.study;
		if (!study) return;

		const cache = this.props.cache;
		const selection = this.props.selection;
		if (!selection) return "no selection";

		const selectionLevel = this.props.selectionLevel;

		const data = cache ? cache.value : null;

		const series = this.props.series;

		const data2 = data
			? series.map((ser) => {
					const d = this.props.getResult(data, ser, this.props.categories);
					return d;
			  })
			: //.sort((a, b) => b.value - a.value) // ??
			  [];

		const view = this.props.view || "line-nps-only";

		const categories = this.props.categories || [];

		const fmt = d3Format(".1%");

		if (this.props.getJSONData && typeof this.props.getJSONData === "function")
			this.props.getJSONData(data2);

		const content = !cache ? null : cache.loading ? null : view === "bar" ? (
			<ResponsiveContainer height="100%" width="100%">
				<BarChart
					data={data2}
					layout="vertical"
					margin={{ top: 0, right: 0, left: 0, bottom: 10 }}
					barGap={0}>
					{/* <CartesianGrid strokeDasharray="3 3"/> */}
					<XAxis
						type="number"
						tickFormatter={fmt}
						domain={["dataMin", "dataMax + 0.1"]}
					/>
					<YAxis
						type="category"
						dataKey="label"
						width={this.props.yAxisWidth || 145}
						tick={<CustomizedTick />}
					/>
					<Tooltip formatter={fmt} />
					<Legend content={<CustomLegend />} />
					{categories.map((cat) => (
						<Bar
							key={cat.key}
							dataKey={`${cat.key}_value`}
							fill={cat.fill || "#5d99d0"}
							name={cat.label.replace("[sel]", this.props.selection.label)}>
							<LabelList
								dataKey={`${cat.key}_value`}
								position="right"
								formatter={fmt}
							/>
						</Bar>
					))}
				</BarChart>
			</ResponsiveContainer>
		) : view === "column" ? (
			<ResponsiveContainer>
				<BarChart
					data={data2}
					layout="horizontal"
					margin={{ top: 0, right: 0, left: 0, bottom: 10 }}
					barGap={0}>
					<CartesianGrid strokeDasharray="3 3" />
					<YAxis type="number" tickFormatter={fmt} />
					<XAxis
						type="category"
						tick={<CustomizedAxisTick1 />}
						dataKey="label"
						interval={0}
						height={100}
					/>
					<Tooltip formatter={fmt} />
					<Legend />
					{categories.map((cat) => (
						<Bar
							key={cat.key}
							dataKey={`${cat.key}_value`}
							fill={cat.fill || "#5d99d0"}
							name={cat.label.replace(
								"[sel]",
								this.props.selection.label,
							)}></Bar>
					))}
				</BarChart>
			</ResponsiveContainer>
		) : null;

		return (
			<div className="widget">
				<div className="widget-header">
					<div className="title">{this.props.title}</div>
					<div className="selection">
						<ProductBlockMini
							{...selection}
							disabled={this.props.inInsightCard}
							allowChange={[selectionLevel]}
						/>
					</div>
					<div className="filter-etc">
						<FilterChooser
							mini={true}
							disabled={this.props.inInsightCard}
							selection={this.props.filter}
						/>
					</div>
				</div>
				<div className="widget-body vscroll">{content}</div>
				<CardLoading loading={cache && cache.loading} />
			</div>
		);
	}
}

SurveyBarViewer = connect(
	(state, ownProps) => {
		const filter =
			state.filter?.selectedFilter || globals.getDefaultFilter(state.study);
		const selectionLevel = ownProps.selectionLevel || "product";
		const selection = state.selections?.[selectionLevel];

		return {
			study: state.study,
			selectionLevel,
			...(ownProps.inDataCard
				? {
						filter,
						selection,
						cache:
							state.cache.analytics[
								`${ownProps.path}:${selection?.uid}:${filter.syntax}`
							],
				  }
				: null),
		};
	},
	(dispatch) => bindActionCreators(analyticsActionCreators, dispatch),
)(SurveyBarViewer);
