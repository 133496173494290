import React from 'react'
import { LineChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis, CartesianGrid, Legend, } from 'recharts';
import {useSelector} from 'react-redux'
import { FilterChooser } from '../filter/FilterChooser'

export const CovidWidget2 = (props) => {
//   const [state, setState] = useState({
// 		diff: [],
// 		step2Arr: [],
// 		whatIf: [0.137, 0.114, 0.695, 0.528, 0.492, 0.075, 0.023, 0.279, 0.074, 0.052, 0.041, 0.462, 2.324, 2.445, 0.288, 3.114, 4.287, 8.584, 6.939, 6.829, 0.172, 0.374, 0.195, 0.294, 0.181, 0.141, 0.244, 0.445, 0]
//     })
	const filter = props.filter || useSelector(state => state.filter.selectedFilter)
  const baseline = [0.193, 0.121, 0.719, 0.574, 0.511, 0.057, 0.021, 0.316, 0.075, 0.04, 0.021, 0.424, 2.292, 2.386, 0.281, 3.038, 4.299, 8.851, 7.296, 7.23, 0.231, 0.413, 0.148, 0.298, 0.18, 0.136, 0.225, 0.482, 3.101];

  const waves = {
        'General Population': [
            ['Wave 1', 'Wave 2', 'Wave 3', 'Wave 5', 'Wave 7', 'Wave 9'],
            [0.21,0.199,0.199,0.182,0.151,0.137],
						[0.089,0.107,0.127,0.13,0.113,0.114],
						[0.68,0.706,0.734,0.718,0.74,0.695],
						[0.544,0.562,0.589,0.57,0.574,0.528],
						[0.257,0.45,0.538,0.545,0.558,0.492],
						[0.071,0.045,0.062,0.065,0.062,0.075],
						[0.034,0.018,0.024,0.02,0.015,0.023],
						[0.354,0.318,0.331,0.299,0.297,0.279],
						[0.124,0.078,0.079,0.067,0.081,0.074],
						[0,0.026,0.041,0.052,0.03,0.052],
						[0,0.015,0.021,0.027,0.034,0.041],
						[0.386,0.393,0.444,0.436,0.457,0.462],
						[2.23,2.266,2.327,2.283,2.316,2.324],
						[2.616,2.489,2.385,2.285,2.323,2.445],
						[0.269,0.27,0.298,0.274,0.331,0.288],
						[2.949,2.98,3.073,3.061,3.039,3.114],
						[4.179,4.252,4.373,4.273,4.298,4.287],
						[8.697,8.838,9.013,8.703,8.677,8.584],
						[7.205,7.205,7.659,7.025,6.833,6.939],
						[7.416,7.233,7.5,6.958,6.83,6.829],
						[0.308,0.226,0.268,0.198,0.166,0.172],
						[0.457,0.4,0.444,0.396,0.379,0.374],
						[0.142,0.139,0.143,0.163,0.183,0.195],
						[0.265,0.272,0.288,0.335,0.328,0.294],
						[0.196,0.177,0.172,0.19,0.202,0.181],
						[0.125,0.137,0.132,0.138,0.127,0.141],
						[0.195,0.229,0.216,0.231,0.206,0.244],
						[0.516,0.498,0.504,0.445,0.466,0.445],
						[3.115,3.01,3.216,3.078,3.053,3.267],
				],
        'Toyota Owner': [
            ['Wave 1', 'Wave 2', 'Wave 3', 'Wave 4', 'Wave 5', 'Wave 6', 'Wave 7', 'Wave 8', 'Wave 9'],
[0.18,0.146,0.147,0.15,0.148,0.12,0.098,0.105,0.135],
[0.068,0.075,0.094,0.091,0.11,0.078,0.072,0.073,0.075],
[0.721,0.74,0.742,0.818,0.723,0.741,0.748,0.78,0.768],
[0.658,0.637,0.646,0.664,0.601,0.645,0.676,0.683,0.646],
[0.274,0.523,0.589,0.594,0.519,0.572,0.545,0.532,0.557],
[0.047,0.051,0.07,0.091,0.108,0.07,0.086,0.102,0.085],
[0.011,0.021,0.019,0.012,0.026,0.012,0.012,0.013,0.017],
[0.445,0.358,0.391,0.401,0.42,0.378,0.402,0.38,0.345],
[0.037,0.039,0.037,0.033,0.049,0.025,0.047,0.032,0.026],
[0,0.023,0.022,0.014,0.019,0.015,0.018,0.015,0.014],
[0,0.033,0.032,0.038,0.049,0.044,0.059,0.05,0.027],
[0.47,0.475,0.463,0.511,0.516,0.514,0.574,0.569,0.559],
[2.182,2.266,2.211,2.208,2.244,2.147,2.115,2.184,2.191],
[2.738,2.522,2.347,2.306,2.254,2.234,2.398,2.396,2.318],
[0.357,0.36,0.337,0.373,0.31,0.355,0.359,0.384,0.311],
[3.189,3.201,3.244,3.295,3.414,3.343,3.346,3.371,3.261],
[4.382,4.394,4.499,4.501,4.581,4.52,4.453,4.483,4.456],
[8.896,9.032,9.281,9.148,9.087,9.005,8.781,9.037,8.973],
[7.249,7.249,7.651,7.217,7.34,6.969,6.611,6.914,6.915],
[7.33,7.193,7.53,7.148,7.3,6.789,6.535,6.599,6.701],
[0.215,0.157,0.23,0.146,0.157,0.117,0.117,0.121,0.135],
[0.445,0.4,0.448,0.403,0.38,0.375,0.381,0.394,0.366],
[0.095,0.1,0.109,0.114,0.124,0.117,0.129,0.105,0.144],
[0.183,0.221,0.237,0.303,0.268,0.258,0.256,0.27,0.239],
[0.131,0.115,0.154,0.134,0.157,0.126,0.137,0.152,0.157],
[0.132,0.145,0.157,0.127,0.141,0.134,0.148,0.174,0.156],
[0.253,0.269,0.252,0.291,0.3,0.23,0.291,0.21,0.229],
[0.586,0.556,0.542,0.489,0.474,0.537,0.455,0.511,0.503],
[3.162,3.058,3.14,3.089,3.181,2.997,2.909,3.011,3.062],
        ],
        'Lexus Owner': [
            ['Wave 1', 'Wave 2', 'Wave 3', 'Wave 4', 'Wave 5', 'Wave 6', 'Wave 7', 'Wave 8', 'Wave 9'],
						[0.163,0.125,0.127,0.116,0.157,0.13,0.137,0.083,0.098],
						[0.061,0.051,0.06,0.059,0.094,0.076,0.098,0.064,0.064],
						[0.761,0.762,0.784,0.797,0.742,0.796,0.701,0.767,0.758],
						[0.71,0.679,0.691,0.71,0.721,0.695,0.63,0.696,0.687],
						[0.327,0.54,0.65,0.672,0.599,0.586,0.476,0.558,0.525],
						[0.057,0.061,0.075,0.077,0.094,0.091,0.098,0.112,0.098],
						[0.017,0.02,0.015,0.013,0.014,0.01,0.014,0.014,0.01],
						[0.365,0.332,0.374,0.303,0.382,0.356,0.362,0.37,0.35],
						[0.044,0.019,0.029,0.02,0.021,0.016,0.055,0.027,0.034],
						[0,0.02,0.016,0.026,0.018,0.025,0.02,0.016,0.01],
						[0,0.014,0.025,0.022,0.032,0.033,0.057,0.037,0.036],
						[0.565,0.566,0.579,0.585,0.65,0.671,0.575,0.669,0.645],
						[2.157,2.186,2.234,2.225,2.184,2.074,2.141,2.136,2.095],
						[2.591,2.389,2.237,2.192,2.166,2.152,2.239,2.26,2.279],
						[0.37,0.313,0.394,0.351,0.334,0.346,0.395,0.337,0.335],
						[3.147,3.144,3.208,3.247,3.379,3.416,3.423,3.382,3.389],
						[4.51,4.588,4.585,4.592,4.687,4.667,4.622,4.623,4.597],
						[9.148,9.199,9.372,9.314,9.2,9.216,8.796,9.05,8.946],
						[7.363,7.363,7.749,7.474,7.224,7.214,7.043,6.996,6.91],
						[7.59,7.534,7.674,7.362,7.341,6.994,6.771,6.663,6.651],
						[0.216,0.17,0.216,0.179,0.161,0.146,0.112,0.095,0.129],
						[0.42,0.403,0.454,0.393,0.41,0.372,0.38,0.345,0.365],
						[0.07,0.091,0.107,0.1,0.115,0.101,0.102,0.103,0.11],
						[0.208,0.256,0.232,0.279,0.293,0.307,0.292,0.3,0.264],
						[0.114,0.084,0.117,0.122,0.131,0.123,0.129,0.101,0.13],
						[0.132,0.108,0.136,0.1,0.122,0.132,0.151,0.136,0.193],
						[0.253,0.256,0.27,0.28,0.267,0.263,0.286,0.283,0.247],
						[0.58,0.537,0.564,0.537,0.507,0.521,0.438,0.504,0.504],
						[3.239,3.096,3.297,3.23,3.138,3.052,3.055,2.92,3.082],
        ]
    };
	
	const sumProductArray = (diff) => {
		const items = [
			[0.04348108,0,0.07620252,0.06446444,0.3187806,0.11505748,0.09909518,0.14706804,0.11229488,0.0770676,0.06705138,0,0.1085156,0.17372186],
			[0.04348108,0,0.07620252,0.06446444,0.3187806,0.11505748,0.09909518,0.14706804,0.11229488,0.0770676,0.06705138,0,0.1085156,0.17372186],
			[-0.00647716,0.449082667,0.330945067,0.162919173,0.22237248,0.337975467,0.264378747,0.289608533,0.259328213,0,0.17368712,0,0.403524267,0],
			[-0.00647716,0.449082667,0.330945067,0.162919173,0.22237248,0.337975467,0.264378747,0.289608533,0.259328213,0,0.17368712,0,0.403524267,0],
			[-0.00647716,0.449082667,0.330945067,0.162919173,0.22237248,0.337975467,0.264378747,0.289608533,0.259328213,0,0.17368712,0,0.403524267,0],
			[0.12488544,0,0.19644816,0.5419188,0.39965316,0.23992404,0.04062348,0,0,0.04560628,0.06761488,0,0.0410938,0.08443048],
			[0.12488544,0,0.19644816,0.5419188,0.39965316,0.23992404,0.04062348,0,0,0.04560628,0.06761488,0,0.0410938,0.08443048],
			[0.27280708,0,0,0,0.09840696,0.11332048,0.22290784,0,0.1151794,0.09621796,0.1702378,0.1926262,0.13936484,0.07459164],
			[0.27280708,0,0,0,0.09840696,0.11332048,0.22290784,0,0.1151794,0.09621796,0.1702378,0.1926262,0.13936484,0.07459164],
			[0.12895728,0,0.023736908,0.25340376,0.27676056,0.05894372,0.1064818,0,0,0.095081,0.076165,-0.019116324,-0.02069404,0.04892096],
			[0.12895728,0,0.023736908,0.25340376,0.27676056,0.05894372,0.1064818,0,0,0.095081,0.076165,-0.019116324,-0.02069404,0.04892096],
			[0.34754536,0,0,0,0,0,0,0,0.35819848,0,0.38454552,0.40575912,0.1064108,0],
			[0,0,0,0,0,0.01914832,0,0,0,0,0,-0.02479005,0.08228087,-0.001411895],
			[0.03372842,0,0,0,0,-0.01537614,0.01303766,-0.02989812,-0.05365946,0,0.06810733,0,-0.04301143,0],
			[0.07468439,0,0,0,0,-0.02669577,0.08630674,0,0,-0.02302209,0,0,0.04590014,-0.0252456],
			[0,0,0,0,0,0,0,0,0,0,0,0,0,0.11074805],
			[0.2001006,0,0,0,0,0.0183449,0.03153855,0.005084817,-0.01592201,0,0,0,0.05413818,0.10509075],
			[0,0,0,0,0,0.03172857,0,0,0,0,0,0,0,0],
			[0.03099847,0,0,0,0,0.2421153,0.1122642,0,0,-0.03933495,0,0.002442424,0,0.01906225],
			[0,0,0,0,0,0.1151146,0.09430438,0,0,0.02414152,0,-0.01317857,0,0.04458503],
			[-0.0585816,0,0,0,0,0,0.6401238,0,0,0.357785,0.2895554,0,0,0.05929148],
			[0,0,0,0,0,0,0,0,0,0.4300598,0,-0.407404,0,0.02318248],
			[0,0,0,0,0,0,0,0,0,0.3866266,0,0,0,0],
			[0,0,0,0,0,0,0,0,0,0.15006826,0,0,0,0],
			[-0.006576238,0,0,0,0,0,0,0,0,0,0,0,0,0.08674446],
			[0.0340613,0,0,0,0,0,0,0,0,0,0,0,0,0],
			[0,0,0,0,0,0,0,0,0,0,0,0,0,-0.011101201],
			[0,0,0,0,0,0,0,0,0,0,0,0,0,0.0101012],
			[0,0,0,0,0,0,0,0,0,0,0,0,0,0],
		]
		
		var length = items[0].length
		var sumProduct = []
		
		for (let i = 0; i < length; i++) {
			var result = diff.map((j, idx) => j * items[idx][i])
			.reduce((acc, curVal) => {
				return acc + curVal
			}, 0)
			sumProduct.push(result)
		}
		
		return sumProduct
	}

	props.stateContext.getSaveState = () => {
		return { filter };
	};
		
	const sumDiff = diff => {
		var step1 = sumProductArray(diff)
		step1 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,...step1]
	
		var diffStep2 = diff.map((i, idx) => i + step1[idx])
		var step2 = sumProductArray(diffStep2)
	
		return step2.slice(step2.length-1)[0] + step1.slice(step1.length-1)[0] + baseline.slice(baseline.length-1)[0]
	}

		var wavesGPLength = waves['General Population'][0].length,
		wavesTOLength = waves['Toyota Owner'][0].length,
		wavesLOLength = waves['Lexus Owner'][0].length,
		step2ArrGP = {};
		
		for (let k=0;k<wavesGPLength;k++) {
            var diff = baseline.map((i, idx) => waves['General Population'][idx+1][k] - i)
            if (!step2ArrGP[waves['General Population'][0][k]]) {
                step2ArrGP[waves['General Population'][0][k]] = {}
            }
            step2ArrGP[waves['General Population'][0][k]]['General Population'] = sumDiff(diff)
		}
		for (let k=0;k<wavesTOLength;k++) {
			var diff = baseline.map((i, idx) => waves['Toyota Owner'][idx+1][k] - i)
            if (!step2ArrGP[waves['Toyota Owner'][0][k]]) {
                step2ArrGP[waves['Toyota Owner'][0][k]] = {}
            }
            step2ArrGP[waves['Toyota Owner'][0][k]]['Toyota Owner'] = sumDiff(diff)
		}
		for (let k=0;k<wavesLOLength;k++) {
			var diff = baseline.map((i, idx) => waves['Lexus Owner'][idx+1][k] - i)
            if (!step2ArrGP[waves['Lexus Owner'][0][k]]) {
                step2ArrGP[waves['Lexus Owner'][0][k]] = {}
            }
            step2ArrGP[waves['Lexus Owner'][0][k]]['Lexus Owner'] = sumDiff(diff)
        }

        var data = []

        data = Object.keys(step2ArrGP).map(step => ({
            name: step, ...step2ArrGP[step]
        })).sort((a,b) => a.name < b.name ? -1 : 1)
	
  return (
    <div className='widget'>
      <div className='widget-header'>
        <div className='title'>{props.title}</div>
        <div className='filter-etc'>
			<FilterChooser selection={filter} disabled={props.inInsightCard} mini={true} />
        </div>
      </div>
      <div className='widget-body vscroll hscroll no-pad'>
        {/* {!props.expanded && ( */}
					<div style={{height: '100%'}}>
							<ResponsiveContainer>
                                <LineChart
                                    width={500}
                                    height={300}
                                    data={data}
                                    margin={{
                                    top: 5, right: 30, left: 20, bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" domain={['dataMin', 'dataMax']} />
                                    <YAxis domain={['dataMin - 0.05', 'dataMax + 0.05']} />
                                    <Tooltip />
                                    <Legend />
                                    {/* <Line type="monotone" dataKey="General Population" stroke="#8884d8" /> */}
                                    <Line isAnimationActive={false} type="monotone" dataKey="Toyota Owner" stroke="#82ca9d" />
                                    <Line isAnimationActive={false} type="monotone" dataKey="Lexus Owner" stroke="#333" />
                                    <Line isAnimationActive={false} type="monotone" dataKey="General Population" stroke="#8884d8" fill="#8884d8" />
                                </LineChart>
							</ResponsiveContainer>
					</div>
      </div>
    </div>
  )
}
