import React from 'react'
import {useSelector} from 'react-redux'
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
	Line,
	ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { FilterChooser } from '../filter/FilterChooser'

export const CovidWidget3 = (props) => {
	const filter = props.filter || useSelector(state=>state.filter.selectedFilter)
	props.stateContext.getSaveState = () => {
		return { filter };
	};
	const data = [
		{
			name: 'Toyota Owner',
			fill: '#5296c5',
			data: [
				{ category: 'Wave 1', value: 3.162 },
				{ category: 'Wave 2', value: 3.058 },
				{ category: 'Wave 3', value: 3.140 },
				{ category: 'Wave 4', value: 3.089 },
				{ category: 'Wave 5', value: 3.181 },
				{ category: 'Wave 6', value: 2.997 },
				{ category: 'Wave 7', value: 2.909 },
				{ category: 'Wave 8', value: 3.011 },
				{ category: 'Wave 9', value: 3.062 },
				{ category: 'Wave 10', value: 2.844 },
			],
			predict: [
				{ category: 'Wave 7', value: 3.021, temperature: [3, 3.2] },
				{ category: 'Wave 8', value: 2.987, temperature: [3, 3.3] },
				{ category: 'Wave 9', value: 2.948, temperature: [3, 3.1] },
				{ category: 'Wave 10', value: 2.905, temperature: [3.4, 3.10] },
				{ category: 'Wave 11', value: 2.858, temperature: [3.4, 3.11] },
				{ category: 'Wave 12', value: 2.806, temperature: [3.4, 3.12] },
				{ category: 'Wave 13', value: 2.750, temperature: [3.4, 3.13] },
				{ category: 'Wave 14', value: 2.689, temperature: [3.4, 3.14] }
			]
		},
		{
			name: 'Lexus Owner',
			fill: '#ff9539',
			data: [
				{ category: 'Wave 1', value: 3.239 },
				{ category: 'Wave 2', value: 3.096 },
				{ category: 'Wave 3', value: 3.297 },
				{ category: 'Wave 4', value: 3.230 },
				{ category: 'Wave 5', value: 3.138 },
				{ category: 'Wave 6', value: 3.052 },
				{ category: 'Wave 7', value: 3.055 },
				{ category: 'Wave 8', value: 2.920 },
				{ category: 'Wave 9', value: 3.082 },
				{ category: 'Wave 10', value: 2.937 },
			],
			predict: [
				{ category: 'Wave 7', value: 3.065, temperature: [3, 3.3] },
				{ category: 'Wave 8', value: 3.027, temperature: [3, 3.32] },
				{ category: 'Wave 9', value: 2.985,  temperature: [2, 3.33] },
				{ category: 'Wave 10', value: 2.940,  temperature: [2, 3.33] },
				{ category: 'Wave 11', value: 2.891,  temperature: [2, 3.34] },
				{ category: 'Wave 12', value: 2.840,  temperature: [2, 3.35] },
				{ category: 'Wave 13', value: 2.785,  temperature: [2, 3.36] },
				{ category: 'Wave 14', value: 2.727,  temperature: [2, 3.37] },
			]
		},
		{
			name: 'General Population',
			fill: '#58b458',
			data: [
				{ category: 'Wave 1', value: 3.115 },
				{ category: 'Wave 2', value: 3.010 },
				{ category: 'Wave 3', value: 3.216 },
				{ category: 'Wave 5', value: 3.078 },
				{ category: 'Wave 7', value: 3.053 },
				{ category: 'Wave 9', value: 3.267 }
			],
			predict: [
				{ category: 'Wave 7', value: 3.073, temperature: [2.9, 3.2] },
				{ category: 'Wave 8', value: 3.049, temperature: [2.850, 3.350] },
				{ category: 'Wave 9', value: 3.018, temperature: [3, 3.110] },
				{ category: 'Wave 10', value: 2.979, temperature: [2, 3.115] },
				{ category: 'Wave 11', value: 2.933, temperature: [2, 3.116] },
				{ category: 'Wave 12', value: 2.879, temperature: [2, 3.117] },
				{ category: 'Wave 13', value: 2.818, temperature: [2, 3.118] },
				{ category: 'Wave 14', value: 2.750, temperature: [2, 3.119] },
			]
		}
	];	

  return (
    <div className='widget'>
      <div className='widget-header'>
        <div className='title'>{props.title}</div>
        <div className='filter-etc'>
			<FilterChooser selection={filter} disabled={props.inInsightCard} mini={true} />
        </div>
      </div>
      <div className='widget-body vscroll hscroll no-pad'>
				<ResponsiveContainer>
					<ComposedChart
						data={data}
						margin={{
							top: 20,
							right: 20,
							bottom: 20,
							left: 20
						}}
					>
						<CartesianGrid stroke='#f5f5f5' />
						<XAxis
							dataKey='category'
							type='category'
							allowDuplicatedCategory={false}
						/>
						<YAxis domain={[1.5, 6]} />
						<Tooltip />
						<Legend payload={[{ value: 'Toyota Owner', type: 'line', id: 'to', color: '#5296c5' }, { value: 'Lexus Owner', type: 'line', id: 'lo', color: '#ff9539' }, { value: 'General Population', type: 'line', id: 'gp', color: '#58b458' }]} />
						
						{data.map((s, idx) => (
							<Line key={idx} isAnimationActive={false} dataKey='value' data={s.data} name={s.name} key={s.name} type='monotone' stroke={s.fill} />
						))}
						{data.filter(i => Array.isArray(i.predict)).map((s, idx) => (
							<Line key={idx} isAnimationActive={false} dataKey='value' type='monotone' data={s.predict} stroke={s.fill} strokeDasharray='5 5' />
						))}
						{data.map(s => (
							<Area isAnimationActive={false} type='monotone' dataKey='temperature' data={s.predict} fill={s.fill} />
						))}
					</ComposedChart>
				</ResponsiveContainer>
      </div>
    </div>
  )
}
