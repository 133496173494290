import * as React from 'react';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators as selectionsActionCreators } from '../../store/Selections.ts';
import { FilterBlock } from '../filter/FilterBlock';
import { ProductBlock } from '../product-drawer/ProductBlock';
import './ContentViewer.scss';


export const AllSelections = (props) => {

    const study_uid = useSelector(state => state.study?.uid);
    const selections = useSelector(state => state.selections);
    const explore = useSelector(state => state.explore);
    const selectionLevel = explore?.selectionLevel;   
    const selection = selections?.[selectionLevel];
    const dispatch = useDispatch();

    const [item, setItem] = useState(props.item);
    const wide = item?.data?.wide === 'true';

     useEffect(() => {
            props?.setLayoutReady?.(true);
    }, []);

    return <div className={'content-section all-selections ' + (wide ? 'wide-width' : 'page-width')}>
        
        <span className='selection-block'>
            <ProductBlock studyId={study_uid} {...selection}
                standalone={true}
                allow={[selectionLevel]}
                style={{ width: '100%', maxWidth: 400 }}
                onClick={() => window.topBar.openProducts(selection,
                    (sel) => {
                        dispatch(selectionsActionCreators.setSelection(selectionLevel, sel))
                        window.topBar.hideMenu();
                        
                    },{
                        allow: [selectionLevel],
                        multiSelect: false
                    }
            )} />
        </span>
        <span className='selection-block'>
                <FilterBlock/>
        </span>
        
    </div>; 
    
}



