import React from 'react';

export const themes = {

    default: {
        css_name: 'default', // for css
        label: 'Default',
        leftBar: 'collapsible', // { collapsible, static, hidden }
        topBar:{
            //logo: '/images/logo-concept.png',
            //logo: '/images/old/logo-reverse-sm.png',
            //logo: '/images/BO_WhiteBG.png',
            logo: '/images/blueowl whitebg 4.png',
            showTabs: false, // { true, false } if false, will show tagline instead
            style: 'dark'
        },
    },
    concept1: {
        css_name: 'concept1', // for css
        label: 'Concept 1 (top menu)',
        leftBar: 'hidden', // { collapsible, static, hidden }
        topBar:{
            logo: '/images/logo-concept.png',
            //logo: '/images/old/logo-reverse-sm.png',
            showTabs: true, // { true, false } if false, will show tagline instead
            style: 'light'
        },
    },

};

export const ThemeContext = React.createContext();