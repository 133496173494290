import { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'  
import { bindActionCreators } from 'redux'
import { actionCreators as dcmActionCreators } from '../../store/DCM'
import { actionCreators as fordForecastActionCreators } from '../../store/FordForecast'
import { actionCreators as selectionsActionCreators } from '../../store/Selections'
import { actionCreators as GlobalStoreCreator } from "../../store/General"
import { Tab_buttons, TabStrip2 } from '../common/TabStrip.js'
import { ToggleSwitch } from '../common/ToggleSwitch.js'
import { EditConstraints } from '../optimize/EditConstraints.js'
import { EditSliderConstraints } from '../optimize/EditSliderConstraints.js'
import { ProductBlock } from '../product-drawer/ProductBlock.js'
import { EditAreaAtts2 } from './EditAreaAtts2.js'
import { EditAreaSliders } from './EditAreaSliders.js'
import './ScenarioEditor.scss'
import { ScenarioSummary } from './ScenarioSummary.js'
import { OpenSaveScenario } from '../scenarios/OpenSaveScenario.js'
import { SelectionBlockElement } from '../explore/SelectionBlockElement.js'
import { FilterChooser } from '../filter/FilterChooser'
import { debounce } from '../../utils/debounce'
import { DCM_SET_ETAG } from '../../store/action-types'
import { computeFordModel } from '../../utils/forecastHelper'
import { active } from 'd3-transition'
import { guid } from '../../utils/guid.js'


export const ScenarioEditorMultiConfigs = (props) => {

    const [selectedTabValue, setSelectedTabValue] = useState('')
    const [summaryChangesOnly, setSummaryChangesOnly] = useState(true)
    const [successForecast, setSuccessForecast] = useState(false)
    const [forecastRunning, setForecastRunning] = useState(false)
    // const [fordSelector, setFordSelector] = useState('false')
    // const [configModels, setConfigModels] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    // const history = useHistory()
    const dispatch = useDispatch()

     const { study, auth, dcm, filter, selection, general } = useSelector(state => ({
        study: state.study,
        auth: state.auth,
        dcm: state.dcm,
        // selections: state.selections,
        selection: state.selection,
        filter: state.filter,
        general: state.general
    }))
    
    const { activeConfig, configModels, productDefs, configs } = dcm

    const suvTruck = useSelector(state => state.selection.suvTruck)
    
    //using SelectionBlockElement now, name of selectorType in meta
    const fordSelector =  suvTruck?.name === 'suv' ? selection?.fordsuv : selection?.fordtruck    



    // console.log('config play', configs[activeConfig]?.play);
    



    useEffect(() => {
        if (configModels && configModels.length > 0) {
            setSelectedTabValue(configModels[0])
            dispatch(dcmActionCreators.setActiveConfig(configModels[0]))
        }      
    
    }, [])

    
    // Handle product selection based on SUV filter
    useEffect(() => {  
        if (selectedTabValue) {

            const newProductId = suvTruck.id === 1 ? 'prod16' : 'prod21';  // Assuming '1' is SUV, '2' is Pickup
            const selProduct = configs[selectedTabValue]?.play.find(prod => prod.uid === (suvTruck ? newProductId : selection.product?.uid))

            if (suvTruck && selProduct) {
                //dispatch(selectionsActionCreators.setSelection('product', selProduct, false))
                dispatch(GlobalStoreCreator.setGlobalStore('forecast_needed', true))
            }
            // dispatch({type:DCM_SET_ETAG, activeConfig })
        }
        
    }, [suvTruck, selectedTabValue, dispatch])
    
    if (!configModels || !selectedTabValue || !configs || !productDefs.default || !configs) return null

  
    const studyId = study.uid    
    let activeTabs = configModels?.map((cm) => ({
            name: cm,
            label: cm,
            icon: "fa-truck-pickup"
    }))   

    
    // console.log('selectedProduct',selectedProduct);
    // console.log('selectedProduct',fordSelector?.uid);

    const available = fordSelector?.available ?? false // fordSelector ? fordSelector.available : false
    const beqLevel = (configs[selectedTabValue]?.brandEquitiesDef || {}).level || 'product'

    const productSelection = fordSelector
    const beqSelection = null// selections[beqLevel] //fix
    const summarySelectedItem = fordSelector

    const tab = selectedTabValue
    const format = props.format || 'config'

    const selTabIndex = activeTabs.findIndex(f => f.name === selectedTabValue)

    const truckFilter = {
		item: {
				data: {
					type: 'suvTruck', 
					imageProperty: 'image',
					imageFit: 'contain', 
					largeImageForm: false,
					largeImageHeight: 15,
					largeImagePadding: 0,
                    imageStyle: { "border": "0px solid white", ":hover": { "cursor": "pointer", "transform": "scale(1.2)", "transition": "all ease 500ms" }, "height":"auto", "width":"30px", "marginLeft":"auto"},
					hideLabel: false,
                    hideType: true,
                    style: { "height": 35, "border": "0px solid white", "textTransform": "uppercase" }

				}
			}
    }   
    
    const fordTruckSelector = {
		item: {
				data: {
					type: 'fordtruck', 
					imageProperty: 'image',
					imageFit: 'contain', 
					largeImageForm: false,
					largeImageHeight: 40,
					largeImagePadding: 0,
					imageStyle: {"border":"0px solid #ccc",":hover":{"cursor":"pointer","transform":"scale(1.1)","transition":"all ease 500ms"}},
					hideLabel: false,
					hideType: false,
					style: {"border":"1px solid #ccc"}

				}
			}
    }
    
    const fordSuvSelector = {
		item: {
				data: {
					type: 'fordsuv', 
					imageProperty: 'image',
					imageFit: 'contain', 
					largeImageForm: false,
					largeImageHeight: 40,
					largeImagePadding: 0,
					imageStyle: {"border":"0px solid #ccc",":hover":{"cursor":"pointer","transform":"scale(1.1)","transition":"all ease 500ms"}},
					hideLabel: false,
					hideType: false,
					style: {"border":"1px solid #ccc"}

				}
			}
	}


    const HandleTabChange = (tabIndex, tabValue) => {
        setSelectedTabValue(tabValue)

        if (configModels && configModels.findIndex(f => f === tabValue) !== -1) {
            dispatch(dcmActionCreators.setActiveConfig(tabValue))
        }
    }


    const forecast = async () => {
        
        // setForecastRunning(true)
        dispatch(GlobalStoreCreator.setGlobalStore('forecast_needed', false))
        dispatch(GlobalStoreCreator.setGlobalStore('forecast_running', true))

        let selfilter = filter.selectedFilter
        let resultsCollection = {}
        for (let i = 0; i < selection?.suvTruck.atts.length; i++) {
            
            const fordfilter = {
                ...selection?.suvTruck.atts[i],
                type: selection.suvTruck.name
            }

            const play = configs?.[activeConfig]?.play
            const filteredProducts = play?.filter(prod => 'model' + prod['model'] === selection?.suvTruck.atts[i].name)

            // console.log('FILTER NAME', selfilter.name);

            let payload = {
                fordfilter,
                activeConfig,
                config: play,
                filter: selfilter,
                brandvalues: [],
            }
            
            //forecast
            let results = null;// await computeFordModel(study.uid, auth, 'dcmModel', payload)

            if (results) {
                // console.log('results', results);
                const prodResults = results[activeConfig][selfilter.name];
                const filteredResults = {};

                filteredProducts.forEach(prod => {
                    if (prodResults[prod.uid]) {
                        filteredResults[prod.uid] = prodResults[prod.uid]
                    }
                })

                // Merge filteredResults into resultsCollection
                resultsCollection = { ...resultsCollection, ...filteredResults }
            }
        }

        if (resultsCollection) {
            let mergedresults = {                
                [selfilter.name]: {
                    ...resultsCollection
                }                
            }
            // console.log('mergedresults', mergedresults);
			await dispatch(fordForecastActionCreators.updateForecaster( mergedresults, activeConfig ))

        }
        dispatch(GlobalStoreCreator.setGlobalStore('forecast_running', false))

        // setForecastRunning(false)
    }

    const debouncedForecast = debounce(forecast, debounce.forecastDelay)

    const ResetToBasecase = () => {
        dispatch(dcmActionCreators.resetToBasecase())
        dispatch(GlobalStoreCreator.setGlobalStore('forecast_needed', true))        
    }    	

  
    return (
        <div className="scenario-editor" style={props.style}> 
            <div className="tab-section">
                <div style={{ position: 'relative', display: 'flex', justifyContent:'space-between',  gap:5, marginBottom: 5,  }}>
                    <SelectionBlockElement key={'selblock1'} {...truckFilter} width={"32%"} />
                    <button className={`btn inline-block`} style={{width:'32%'}}  role="button" aria-label="RESET TO BASECASE" onClick={ResetToBasecase} >
                       <i className='fal fa-undo' style={{marginRight:5}}></i> {` RESET ${activeConfig} BASE`}
                    </button>
                    <button className={`btn inline-block`} style={{width:'32%'}}  role="button" aria-label="apply changes" onClick={debouncedForecast} disabled={forecastRunning}>
                        <i className={`fal fa-rocket-launch`} style={{marginRight:5}}></i> {forecastRunning ? ` RUNNING ${activeConfig} FORECAST` : ` FORECAST ${activeConfig}` } 
                    </button>
                </div>
               
                <TabStrip2 selectedIndex={selTabIndex} selectedIndexChanged={HandleTabChange} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    {
                        activeTabs.map(t => {
                            if (format !== 'optimizer') {
                                return (
                                    <Tab_buttons key={t.name} value={t.name} icon={t.icon}>{t.label}</Tab_buttons>
                                )
                            } else {
                                return (
                                    <Tab_buttons key="summary" value="summary" icon={t.icon}>Summary</Tab_buttons>
                                )
                            }
                        })
                    }                    

                </TabStrip2>
            </div>
            {format === 'optimizer' ? null : configModels.includes(tab) || tab === 'price' ? (
                <div className="product-selector" style={{ display: 'flex', flexDirection: 'column' }}> 
                    {
                        suvTruck.name === 'suv' ?
                        < SelectionBlockElement {...fordSuvSelector} />
                        :
                        <SelectionBlockElement {...fordTruckSelector} />
                
                    }
                    {/* <ProductBlock
                        studyId={studyId}
                        {...fordSelector}  //{...productSelection}
                        available={available}
                        standalone={true}
                        productsOnly={true}
                        dirty={(fordSelector || {}).dirty}
                        allow={['product']}
                        style={{ width: '100%' }}
                        onClick={() => {
                            window.topBar.openProducts(fordSelector, (sel) => {
                                console.log('Product was selected', sel)
                                dispatch(selectionsActionCreators.setSelection('product', sel, false))
                                let val = general.custom['product_changed'] || true
                                dispatch(GlobalStoreCreator.setGlobalStore('product_changed', !val))   
                                window.topBar.hideMenu()
                            }, {
                                allow: ['product'],
                                multiSelect: false
                            })
                        }}
                    /> */}
                    <div style={{marginTop:15}}>
                        <span style={{display:'block', fontSize:12, fontWeight:500, height:15, textTransform:'uppercase'}}>Filters</span>
                        <FilterChooser mini={false} selection={filter.selectedFilter} style={{ width: '100%', margin:0 }} /> 
                    </div>
                </div>
                
            ) : tab === 'message' || tab === 'make' ? (
                <div className="product-selector">
                    <ProductBlock
                        studyId={studyId}
                        {...beqSelection}
                        standalone={true}
                        allow={[beqLevel]}
                        style={{ width: '100%' }}
                        onClick={() =>
                            window.topBar.openProducts(beqSelection, (sel) => {
                                dispatch(selectionsActionCreators.setSelection(beqLevel, sel))
                                window.topBar.hideMenu()
                            }, {
                                allow: [beqLevel]
                            })
                        }
                    />
                </div>
            ) : tab === 'summary' ? (
                <div className="product-selector">
                    <ToggleSwitch
                        className="mini"
                        on={summaryChangesOnly}
                        onChange={(newVal) => setSummaryChangesOnly(newVal)}
                    >
                        Show Changes Only
                    </ToggleSwitch>
                    {!summaryChangesOnly ? (
                        <ProductBlock
                            studyId={studyId}
                            {...summarySelectedItem}
                            standalone={true}
                            allow={'product'} //[selections.hierarchy]
                            style={{ width: '100%', marginTop: 10 }}
                            onClick={() =>
                                window.topBar.openProducts(beqSelection, (sel) => {
                                    dispatch(dcmActionCreators.set('summarySelectedItem', sel))
                                    window.topBar.hideMenu()
                                }, {
                                    allow: 'product'//[selections.hierarchy]
                                })
                            }
                        />
                    ) : null}
                </div>
                ) : null}
            {format === 'optimizer' ? (
                <div className="editor-content">
                    {tab === 'product' ? <EditConstraints /> : null}
                    {tab === 'price' ? <EditConstraints area="price" /> : null}
                    {tab === 'message' ? <EditSliderConstraints area="message" /> : null}
                    {tab === 'make' ? <EditSliderConstraints area="make" /> : null}
                    {tab === 'scenarios' ? <EditSliderConstraints area="make" /> : null}
                </div>
            ) : (   
                <div className="editor-content">
                    {tab === selectedTabValue ? <EditAreaAtts2 prodId={(fordSelector || {}).uid} key={(fordSelector || {}).uid} configModel={selectedTabValue}  /> : null}
                    {tab === 'price' ? <EditAreaAtts2 area="price" prodId={(fordSelector || {}).uid} key={(fordSelector || {}).uid} configModel={selectedTabValue} /> : null}
                    {tab === 'message' ? <EditAreaSliders area="message" selection={(beqSelection || {}).uid} selectionLabel={(beqSelection || {}).label} /> : null}
                    {tab === 'make' ? <EditAreaSliders area="make" selection={(beqSelection || {}).uid} selectionLabel={(beqSelection || {}).label} configModel={selectedTabValue}  /> : null}
                    {/* {tab === 'summary' ? <ScenarioSummary filter={summarySelectedItem} changesOnly={summaryChangesOnly} /> : null} */}
                    {/* {tab === 'scenarios' ? <OpenSaveScenario configModels={configModels} /> : null} */}
                </div>
            )}
        </div>
    )

}