import * as React from 'react';
import './CardCover.scss';

export class CardCover extends React.Component{

    buttonClicked = () => {
        if( this.props.buttonClicked ){
            this.props.buttonClicked();
        }
    }

    render() {
        return <div className='card-cover'>
            {this.props.message ?
                <div className='message'>
                    {this.props.message}
                </div>
            : null}
            {this.props.button ? 
                <span className='button' onClick={() => this.buttonClicked()}>
                    [click to update]
                </span>
            : null}
        </div>;
    }

}