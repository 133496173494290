import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { BubbleChart } from "../vis/BubbleChart";
//import { Tab, TabStrip, TabStrip2 } from "../common/TabStrip";
import { ContentLoader } from "./ContentLoader";
import "./ContentViewer.scss";
import { EditModeWrapper } from "./EditModeWrapper";
import { wrapAsPage } from "./PageContentViewer";
import { SelectionBlockElement } from "./SelectionBlockElement";
// import { ScrollPositionContext } from "../contexts/ScrollPositionContext";
// import { PowerPointCreator } from "./PowerPointCreator";


export const NavElement = (props) => {

    // const [scrollPosition, setScrollPosition] = useContext(ScrollPositionContext)
	const [selNav, setSelNav] = useState();
	
	const history = useHistory();
	const study_uid = useSelector((state) => state.study?.uid);
	const item = props.item;
	const data = item?.data;

	
	//properties from editor
	const selectorData = data?.selectorDataToUse;
	const selectorPositioning = data?.selectorPosition || 'start';
	// const hasPowerPoint = data?.hasPowerPointCreator || true;
	// const pptStyle = data?.powerpointStyle || { height: 35, textTransform: 'uppercase', fontSize:16, fontWeight:500 };
	// const pptSectionName = data?.pptSectionName || '';
	const bgColor = data?.backgroundColor?.toString() || 'rgba(255,255,255,.9)';
	const activeColor = data?.activeColor?.toString() || '#099cf4'
	const navItemStyle = data?.navItemStyle || {color: '#444', fontSize:'12pt', fontWeight:500};
	const sticky = data?.sticky || false;
	const stickyFromTop = data?.stickyFromTop || 0;
	const scrollHighlight = data?.scrollHighlight || true;
	const navItemsPosition = data?.navItemsPosition || 'left';
	const navItems = data?.navItems || [{ "id":"nav1", "label":"Executive Summary", "anchorId":"exec_sum"}, { "id":"nav2", "label":"Data Analysis", "anchorId":"data_analysis"}];// JSON.parse(data?.content) ||  JSON.parse('[{ "id":"nav1", "label":"Executive Summary", "anchorId":"exec_sum", "active": true}, { "id":"nav2", "label":"Data Analysis", "anchorId":"data_analysis"}]')
	const z_Index = data?.zIndex || 9999;	
	// const wide = item?.data?.wide || '100%';
	const navHeight = data?.navHeight || 50;


	if (!selNav && navItems && navItems.length > 0)
		setSelNav(navItems[0]?.id)
	
	if (selectorData) {
		props = {
			...props,
			item: {
				...props.item,
				data: {
					...props.data,
					type: selectorData,
					imageProperty: 'image',
					imageFit: 'contain', 
					largeImageForm: false,
					largeImageHeight: navHeight - 10,
					largeImagePadding: 0,
					imageStyle: {"border":"0px solid white",":hover":{"cursor":"pointer","transform":"scale(1.1)","transition":"all ease 500ms"}},
					hideLabel: true,
					hideType: true,
					style: {"border":"0px solid white"}

				}
			}
		}
	}

	// if (scrollHighlight) {

	// 	useEffect(() => {

	// 		navItems.forEach(ni => {
	// 			let elem = document.getElementById(ni.anchorId)//section

	// 			if (elem && elem?.offsetTop - scrollPosition.y < 200) {
	// 				const activeTabs = document.querySelectorAll('.navElement > ul li')
	// 				activeTabs.forEach(tab => {
	// 					tab.style.color = '#444'
	// 				})

	// 				let active = document.querySelector(`.${ni.anchorId}`)
	// 				active.style.color = activeColor;
	// 			}
	// 		})
	// 	}, [scrollPosition])
	// }
	
			
	const setLayoutReady = (val) => {
		props.setLayoutReady?.(val);
	}

	useEffect(() => {
		if (!item) {
			setLayoutReady(true);
		}
	}, [item,selectorPositioning, bgColor, activeColor, navItemStyle,sticky, stickyFromTop, navItems, navItemsPosition, z_Index, navHeight ])


	//styles
	const nav_container = {
		position: sticky ? 'sticky' : 'unset',
		top: sticky ? Number(stickyFromTop) : 0,
		left: 0,
		backgroundColor: bgColor,
		boxSizing:'box-model',
		margin: 0,
		borderBottom: '1px solid #dfdfdf', 
		zIndex: z_Index, 
		width:  '100%',
		transition: '0.6s'
	}


	const ul = {
		margin: 0,
		height: Number(navHeight),
		display: 'flex', 
		justifyContent: navItemsPosition === 'left' ? 'start' : navItemsPosition === 'center' ? 'center' : 'end',
		alignItems: 'center',
		backgroundColor: `${navItemStyle?.backgroundColor ? navItemStyle?.backgroundColor : bgColor}`
	}

	const defaultNavStyles = {
		letterSpacing: '-.5px',
		textTransform: 'uppercase',
		fontSmooth: 'antialiased',
		paddingRight: 15,
		textDecoration: 'none', 
		listStyle: 'none',
		display: 'inline-block', 
		padding: '10px 8px'
	}

	const li = {
		...defaultNavStyles,
		...navItemStyle, //overide defaults
		// backgroundColor: 'inherit'
	}
		

	// const scrollTo = (anchorId, navId, isTop) => {
	// 	setSelNav(navId)
	// 	let elem = document.getElementById(anchorId)
			
	// 	document.querySelector(`#${scrollPosition.scrollElementId}`).scrollTo({
	// 		behavior: 'smooth', 
	// 		left: 0, 
	// 		top: isTop ? Number(navHeight) : elem.offsetTop - 60
	// 	})
		
	// }

	// href={`${location.pathname}#${nav?.anchorId}`}
// .page.explore-page > .content
	
	const contents = (
		<EditModeWrapper pageLevel={props.pageLevel} key={item.id} className="has-children"	item={item}	reloadParentItem={props.reloadParentItem} edit={props.edit}	restoredItem={props?.restoredItem}>
			<div className='navElement' style={nav_container}>
				<ul style={ul}>
					{
						selectorData && selectorPositioning === 'start' ? 
							 <SelectionBlockElement {...props }	/>							
							: null 
					}
					{
						navItems?.map((nav, idx) => {
							return <li className={nav.anchorId}
										style={{ ...li, color: `${selNav === nav.id ? activeColor : navItemStyle?.color}`, cursor: 'pointer' }}
										key={nav.id}
										onClick={() => { scrollTo(nav.anchorId, nav.id, idx == 0 ? true : false) }}
									> 
									{nav?.label}
								</li>
						})
					}
					{
						selectorData && selectorPositioning === 'end' ? 
							 <SelectionBlockElement {...props }	/>							
							: null
					}
				</ul>
			</div>
			
		</EditModeWrapper>
	)

	return props.asPage
		? wrapAsPage(
			props.item,
			content,
			null,
			study_uid,
			params,
			props.edit,
			props.restore,
			reloadSelf,
		)
		: contents;
};

// : hasPowerPoint ? <PowerPointCreator sectionName={pptSectionName} buttonStyle={pptStyle} />
