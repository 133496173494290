import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators as configActionCreators } from '../../store/Config.ts';
import { ToggleSwitch } from '../common/ToggleSwitch';
import './EditConstraints.scss';

const _ = obj => obj || {}; // since javascript doesn't have elvis operator

export class EditSliderConstraints extends React.Component {

    render() {
        let def = this.props.config.productDefs.default;
        if (def == null) return null; // eslint-disable-line eqeqeq

        const selection = this.props.sel;
        if( !selection ) return null;

        let selectedSlot = this.props.config.productSlots.find(slot => slot.uid === selection.uid) || {};
        let selectedProduct = (selectedSlot || {}).play;
        
        if (selectedProduct == null) return null; // eslint-disable-line eqeqeq


        let brandEquitiesDef = this.props.config.brandEquitiesDef;
        let level = brandEquitiesDef.level;

        let selItem = this.props.config.selectedItem;
        let selItemLabel = null;

        if( level === 'product' ){
        
            //selItemLabel = selSlot.label;
            // leave null so we know not to print the message
            
        }
        else{
            // adjust selItem to reflect the level and value

            const val = selectedSlot.play[level];
            selItem = `${level}:${val}`
            const att = this.props.config.productDefs[selectedSlot.def].atts.find(att => att.name === level);
            const att_level = att ? att.levels.find(level => level.value === val) : null;
            selItemLabel = att_level ? att_level.label : 'error';
        }

        




        
        let messages = brandEquitiesDef.entries.filter(entry => entry.area === this.props.area);
        //console.log('messages', messages);

        // let writeableAtts = def.atts.filter(
        //     att => att.readOnly !== true && att.area == this.props.area // eslint-disable-line eqeqeq
        // );

        // writeableAtts = writeableAtts.filter(att => !att.type || att.type === 'discrete');

        //let cfg_play = globals.configs.find(cfg => cfg.key === 'play');
        

        return (
            <div className="constraints-area">

                {selItemLabel != null ? <div className='sliders-note'>
                    Note: Any change below will apply to all products where <b>{level}</b> is <b>{selItemLabel}</b>.
                    Note that it's incredibly difficult to move associations more than 1-2 points.
                </div> : null}

                {messages.map((message, messageIdx) => {

                    let instance = this.props.config.brandEquities[selItem][message.name];
                    let allowed = instance.allowed;

                    return (
                        <div className='slider-constraint-box' key={message.name}>
                            <ToggleSwitch on={allowed}
                                onChange={(newVal) => this.props.setMessageAllow(selItem, message.name, newVal)}
                            >{message.label}</ToggleSwitch>
                        </div>
                    );
                })}

                    
            </div>
        );
    }
}

EditSliderConstraints = withRouter(
    connect(
        state => state.study,
        dispatch => bindActionCreators(configActionCreators, dispatch)
    )(EditSliderConstraints)
);
