import { format as d3Format } from "d3-format";
import * as React from "react";
import {
	Bar,
	BarChart,
	LabelList,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
	Pie,
	PieChart,
	Cell,
} from "recharts";
import * as globals from "../../globals";
import { CoreWidget1 } from "./CoreWidget1";
import { useSelector } from "react-redux";

const format = d3Format(".1%");

const piecolors = ["#5b91fe", "#cfcfcf"];

const format_slice = d3Format(".0%");
const format_slice_tooltip = d3Format(".1%");

export const DemogWidget2 = (props) => {
	//const id = props.var;
	//const filterGroups = useSelector(state => state.filter?.filterGroups)
	const selectedFilter = useSelector((state) => state.filter?.selectedFilter);
	// const filterGroup = filterGroups?.find(fg => fg.id === id);
	// if( !filterGroup ) return `meta not found for [${id}]`;
	//const filters = filterGroup?.filters;
	const study = useSelector((state) => state.study);

	const filter = selectedFilter || globals.getDefaultFilter(study);

	const renderContent = (cache, getJSONData) => {
		if (!cache) return null;
		//console.log('cache', cache);

		const data = [
			{ label: filter?.label, value: cache.results?.segsize?.value },
			{
				label: "Other",
				value: isNaN(cache.results?.segsize?.value)
					? null
					: 1.0 - cache.results?.segsize?.value,
			},
		];

		if (getJSONData && typeof getJSONData === "function") getJSONData(data);
		return (
			<ResponsiveContainer width="100%" height="100%">
				{/* <BarChart data={data} layout="vertical">
                <XAxis dataKey="value" type="number" tickFormatter={format}
                    domain={['auto', dataMax => (dataMax * 1.2)]}
                />
                <YAxis dataKey="label" type='category' width={125}/>
                <Tooltip formatter={format} />
                <Bar dataKey="value" fill={globals.colors.primary1} >
                    <LabelList dataKey="value" position="right" formatter={format}/>
                </Bar>
            </BarChart> */}
				<PieChart>
					<Pie
						isAnimationActive={false}
						data={data}
						dataKey="value"
						innerRadius={80}
						outerRadius={110}
						xlabel={(obj) =>
							`${obj.payload.label} ${format_slice(obj.payload.value)}`
						}>
						{data.map((entry, index) => (
							<Cell key={index} fill={piecolors[index % piecolors.length]} />
						))}
					</Pie>
					<Tooltip
						labelFormatter={() => "hi"}
						formatter={format_slice_tooltip}
					/>
				</PieChart>
			</ResponsiveContainer>
		);
	};

	const syntax1 = filter?.syntax;
	const syntax2 = syntax1 === "1==1" ? "pct(uid==uid)" : `pct(${syntax1})`;

	const query = {
		measures: [{ syntax: syntax2, id: "segsize" }],
	};
	//console.log('query', query);

	return (
		<CoreWidget1
			{...props}
			useFilter={true}
			render={renderContent}
			useYak={{ query }}
		/>
	);
};
