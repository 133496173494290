import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators as configActionCreators } from '../../store/Config.ts';
import { actionCreators as selectionsActionCreators } from '../../store/Selections.ts';
import { Tab, TabStrip2 } from '../common/TabStrip';
import { ToggleSwitch } from '../common/ToggleSwitch';
import { EditConstraints } from '../optimize/EditConstraints';
import { EditSliderConstraints } from '../optimize/EditSliderConstraints';
import { ProductBlock } from '../product-drawer/ProductBlock';
import { EditAreaAtts2 } from './EditAreaAtts2';
import { EditAreaSliders } from './EditAreaSliders';
import './ScenarioEditor.scss';
import { ScenarioSummary } from './ScenarioSummary';
import { SelectionBlockElement } from '../explore/SelectionBlockElement.js';


export class ScenarioEditor extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            selectedTabValue: props.selectedTabValue || 'product',
            summaryChangesOnly: true
        };
    }

    render() {

        const format = this.props.format || 'config';

        const study = this.props.study;        
        const config = study?.config;
        if(!config) return null;
        const def = config?.productDefs?.default;
        if (!def) return null; // eslint-disable-line eqeqeq
        
        // if (!this.props.selections.product)
        //     this.props.setSelection('product', study.selections.product, false);
        
        const selections = this.props.selections; //this is redux state.selections
        if (!selections) {
             this.props.setSelection('product', study.selections.product, false);
            //return null; // this selection is not the selected product. it's the object that contains all selections.
        }
        const studyId = study.uid;
        const beqLevel = (config.brandEquitiesDef || {}).level || 'product';

        const selectedSlot = config.productSlots.find(slot => slot.uid === (selections.product || {}).uid) || {};
        const selectedProduct = (selectedSlot || {}).play; //play is current config? (atts)
        

        //const selectedArea = config.selectedArea;
        const available = selectedProduct ? selectedProduct.available : false;
        
        const productSelection = selections.product; // config.selectedItem;
        const beqSelection = selections[beqLevel]; // config.selectedItemP;
        const summarySelectedItem = selections.product; //config.summarySelectedItem;

        const tab = this.state.selectedTabValue;

        // console.log('product selection', productSelection)
        
        
        if( beqLevel !== 'product' ){
            // plevel doesn't equal product, so let's check dirty bit status of selectionP, for the productblock
            // todo: (or maybe do this in productdrawer)
        }

        const selTabIndex = ['product', 'price', 'message', 'make', 'summary'].indexOf(this.state.selectedTabValue);
        const selectorData = {
			item: {
				data: {
					type: 'make', //'product'
					imageProperty: 'imageLogo',
					imageFit: 'contain', 
					largeImageForm: false,
					largeImageHeight: 10,
					largeImagePadding: 0,
					imageStyle: {"border":"0px solid white", "maxHeight": 80, "maxWidth": 200,":hover":{"cursor":"pointer","transform":"scale(1.1)","transition":"all ease 500ms"}},
					hideLabel: true,
					hideType: true,
					style: {"border":"0px solid white", "height": 80}

				}
			}
		}

return (
    <div className="scenario-editor" style={props.style}>
        <div className="tab-section">
            <div style={{ marginBottom: 5 }}>
                <span
                    className={`btn border drk inline-block ${!dirty ? 'disabled' : ''}`}
                    role="button"
                    aria-label="apply changes"
                    onClick={() => dispatch(configActionCreators.apply('play'))}
                >
                    Forecast
                </span>
            </div>
            <TabStrip2
                selectedIndex={selTabIndex}
                selectedIndexChanged={(selectedTabIndex, selectedTabValue) =>
                    setSelectedTabValue(selectedTabValue)
                }
            >
                <Tab value="product">Product</Tab>
                <Tab value="price">Price</Tab>
                <Tab value="message">Message</Tab>
                <Tab value="make">Make</Tab>
                {format !== 'optimizer' ? <Tab value="summary">Summary</Tab> : <i />}
            </TabStrip2>
        </div>
        {format === 'optimizer' ? null : tab === 'product' || tab === 'price' ? (
            <div className="product-selector">
                <ProductBlock
                    studyId={studyId}
                    {...productSelection}
                    available={available}
                    standalone={true}
                    productsOnly={true}
                    dirty={(selectedProduct || {}).dirty}
                    allow={['product']}
                    style={{ width: '100%' }}
                    onClick={() => {
                        window.topBar.openProducts(productSelection, (sel) => {
                            console.log('(SCEN EDITOR) sel', sel);
                            dispatch(selectionsActionCreators.setSelection('product', sel, false));
                            window.topBar.hideMenu();
                        }, {
                            allow: ['product'],
                            multiSelect: false
                        });
                    }}
                />
            </div>
        ) : tab === 'message' || tab === 'make' ? (
            <div className="product-selector">
                <ProductBlock
                    studyId={studyId}
                    {...beqSelection}
                    standalone={true}
                    allow={[beqLevel]}
                    style={{ width: '100%' }}
                    onClick={() =>
                        window.topBar.openProducts(beqSelection, (sel) => {
                            dispatch(selectionsActionCreators.setSelection(beqLevel, sel));
                            window.topBar.hideMenu();
                        }, {
                            allow: [beqLevel]
                        })
                    }
                />
            </div>
        ) : tab === 'summary' ? (
            <div className="product-selector">
                <ToggleSwitch
                    className="mini"
                    on={summaryChangesOnly}
                    onChange={(newVal) => setSummaryChangesOnly(newVal)}
                >
                    Show Changes Only
                </ToggleSwitch>
                {!summaryChangesOnly ? (
                    <ProductBlock
                        studyId={studyId}
                        {...summarySelectedItem}
                        standalone={true}
                        allow={[selections.hierarchy]}
                        style={{ width: '100%', marginTop: 10 }}
                        onClick={() =>
                            window.topBar.openProducts(beqSelection, (sel) => {
                                dispatch(configActionCreators.set('summarySelectedItem', sel));
                                window.topBar.hideMenu();
                            }, {
                                allow: [selections.hierarchy]
                            })
                        }
                    />
                ) : null}
            </div>
        ) : null}
        {format === 'optimizer' ? (
            <div className="editor-content">
                {tab === 'product' ? <EditConstraints /> : null}
                {tab === 'price' ? <EditConstraints area="price" /> : null}
                {tab === 'message' ? <EditSliderConstraints area="message" /> : null}
                {tab === 'make' ? <EditSliderConstraints area="make" /> : null}
            </div>
        ) : (
            <div className="editor-content">
                {tab === 'product' ? <EditAreaAtts2 slotId={(productSelection || {}).uid} key={(productSelection || {}).uid} /> : null}
                {tab === 'price' ? <EditAreaAtts2 area="price" slotId={(productSelection || {}).uid} key={(productSelection || {}).uid} /> : null}
                {tab === 'message' ? <EditAreaSliders area="message" selection={(beqSelection || {}).uid} selectionLabel={(beqSelection || {}).label} /> : null}
                {tab === 'make' ? <EditAreaSliders area="make" selection={(beqSelection || {}).uid} selectionLabel={(beqSelection || {}).label} /> : null}
                {tab === 'summary' ? <ScenarioSummary filter={summarySelectedItem} changesOnly={summaryChangesOnly} /> : null}
            </div>
        )}
    </div>
)

    }
}

ScenarioEditor = withRouter(
    connect(
        state => ({
            study: state.study,
            selections: state.selections,
            selection: state.selection,
            dirty: state.configs?.dirty
        }),
        dispatch =>
            bindActionCreators({ ...configActionCreators, ...selectionsActionCreators }, dispatch)
    )(ScenarioEditor)
);
