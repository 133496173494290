
// analytics.js
import ReactGA from "react-ga4"

export const gaInit = _ => {
  ReactGA.initialize('G-SMN6DH5SZL') // Measurement ID
}

export const gaPageView  = (pageURL) => {
  ReactGA.send({ hitType: 'pageview', page: pageURL });
}

export const gaEvent = (eventName, eventParams) => {
  ReactGA.event(eventName, eventParams);
}

export const gaUserSet = (uid) => {
  
    ReactGA.set({ userId: uid })
}



// export const gaEvent = (categoryType, study_id, info) => {
//   ReactGA.event({
//     category: categoryType,
//     action: study_id,
//     label: info
//   });
// }

// export const gaCommonEvent = (dimensionValue) => {
//     ReactGA.set({ dimension1: dimensionValue })
// }
