import xlsx from 'xlsx'
import { tsvFormat } from 'd3';
import { metrics } from '../globals';
import { sortBy, minBy, maxBy } from 'lodash';

export const exportSVG = node => {
    var isCssMediaRule = cssRule => cssRule.type === cssRule.MEDIA_RULE
    var isCssStyleRule = cssRule => cssRule.type === cssRule.STYLE_RULE
    var slice = Function.call.bind(Array.prototype.slice)

    var cssRules = slice(document.styleSheets)
        .reduce((rules, styleSheet) => rules.concat(slice(styleSheet.cssRules)), [])
        .filter(isCssStyleRule)
    cssRules = cssRules
        .concat(
            slice(cssRules.filter(isCssMediaRule))
                .reduce((rules, mediaRule) => rules.concat(slice(mediaRule.cssRules)), [])
        )
        .map(i => i.cssText)

    function walk(node) {
        var cl = [],
            treeWalker = document.createTreeWalker(node, NodeFilter.SHOW_ELEMENT)

        while (treeWalker.nextNode()) {
            var l = [...new Set(treeWalker.currentNode.classList)]
            if (l.length)
                cl.push(...l)
        }

        return [...new Set(cl)]
    }

    function parseCss(text) {
        let tokenizer = /([\s\S]+?)\{([\s\S]*?)\}/gi,
            rules = [],
            rule, token;
        text = text.replace(/\/\*[\s\S]*?\*\//g, '');
        while ((token = tokenizer.exec(text))) {
            var style = parseRule(token[2].trim());
            style.cssText = stringifyRule(style);
            rule = {
                selectorText: token[1].trim().replace(/\s*\,\s*/, ', '),
                style: style
            };
            rule.cssText = `${rule.selectorText} { ${rule.style.cssText} }`;
            rules.push(rule);
        }
        return rules;
    }

    function parseRule(css) {
        let tokenizer = /\s*([a-z\-]+)\s*:\s*((?:[^;]*url\(.*?\)[^;]*|[^;]*)*)\s*(?:;|$)/gi,
            obj = {}, token;
        while ((token = tokenizer.exec(css)))
            obj[token[1].toLowerCase()] = token[2];

        return obj;
    }

    function stringifyRule(style) {
        let text = '',
            keys = Object.keys(style).sort();
        for (let i = 0; i < keys.length; i++)
            text += ` ${keys[i]}: ${style[keys[i]]};`;

        return text.substring(1);
    }

    let widgetBody = node.querySelector('.widget-body');

    let _svgs = Array.from(widgetBody.querySelectorAll('svg')).map(i => ({ ...i.getBoundingClientRect().toJSON(), node: i }))
    _svgs = sortBy(_svgs, ['x', 'y'])
    let minX = minBy(_svgs, 'x').x,
        minY = minBy(_svgs, 'y').y,
        maxRight = maxBy(_svgs, 'right').right,
        maxBottom = maxBy(_svgs, 'bottom').bottom;
    _svgs = _svgs.map(i => ({ ...i, x: i.x - minX, y: i.y - minY, maxRight, maxBottom }))

    var classes = walk(widgetBody), styles = ''
    if (classes.length)
        styles = classes.map(i => cssRules.filter(j => j.includes(`.${i}`)).join('')).filter(i => i)

    let parsedCSS = typeof styles === 'string' ? [] : parseCss(styles.join(' '))
    parsedCSS = [...new Set([...new Set(parsedCSS.filter(i => i.selectorText.startsWith(`.${classes[0]}`)))].map(i => i.cssText))]
    styles = `* { font-family: 'Open Sans', 'Helvetica Neue', sans-serif }\n${parsedCSS.join('\n')}`

    let content = `<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1" id="${+new Date()}"
  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="${_svgs.length === 1 ? _svgs[0].node.getAttribute('viewBox') ? _svgs[0].node.getAttribute('viewBox') : `0 0 ${maxRight-minX} ${maxBottom - minY}` : `0 0 ${maxRight-minX} ${maxBottom - minY}`}"
  style="enable-background:new 0 0 ${maxRight - minX} ${maxBottom - minY};" xml:space="preserve" class="${classes[0]}">
  <style>
    ${styles}
  </style>
  ${_svgs.map(item => _svgs.length > 1 ? `<g class='${[item.node.classList].join(' ')}' transform='translate(${item.x}, ${item.y})'>${item.node.innerHTML.replace(/&nbsp;/g, ' ')}</g>`
        : `${item.node.innerHTML.replace(/&nbsp;/g, ' ')}`).join('\n')}
</svg>
`;
    return content;
}

export const exportTable = (jsonData, node) => {
    console.log(jsonData)
    if (jsonData !== '' && !_.isEmpty(jsonData)) {
        let { columns, data, metric } = jsonData
        let rows = [], cols = []
        var workbook = null
        let cardName = node.current.querySelector('.title').innerText.replace(/\(.*\)/g, '').trim(),
            modelName = node.current.querySelector('.label-container .text1') ? node.current.querySelector('.label-container .text1').innerText.trim() : ''

        function manipulateJson(columns, data, makeWorksheet = false) {
            cols = [...columns].map(i => i.columns ? [...i.columns] : i).flat(Infinity)
            data = Object.assign({}, {rows: _.concat([], data || [])}).rows
            data.forEach(row => {
                var r = {}
                cols.map(col => {
                    if (typeof row[col.key] === 'object') {
                        r = Object.assign({}, {...row, [col.key]: { ...row[col.key] } })
                    } else r = row
                    return r
                })
                rows.push(r)
                r = {}
            })
            rows = rows.map(row => {
                cols.forEach(col => {
                    let val = col.type === 'cell' ? row[col.key]?.value : row[col.key]
                    if (typeof row[col.key] === 'object') {
                        let key = metric ? metric : col.key
                        row[col.key] = metrics.filter(i => i.key === key).length
                            ? metrics.filter(i => i.key === key)[0].export_fformat(val)
                            : col.format
                                ? col.format(val)
                                : val
                    }
                })
                return row
            })

            var Rows = []
            rows.forEach(row => {
                var r = {}
                cols.map(col => r = Object.assign({}, r, { [col.label]: row[col.key] }))
                Rows.push(r)
                r = {}
            })

            return makeWorksheet ? xlsx.utils.json_to_sheet(Rows) : {
                SheetNames: [`${modelName}`],
                Sheets: {
                    [`${modelName}`]: xlsx.utils.json_to_sheet(Rows)
                }
            }
        }

        if (columns && data) {
            workbook = manipulateJson(columns, data)
            // xlsx.writeFile(sh, `${cardName} ${modelName}.xlsx`)
        } else if (Array.isArray(jsonData)) {
            let ws = [], ws1 = null, rowLimit = 0
            jsonData.forEach((item, idx) => {
                let { columns, rows } = item

                if (columns && rows) {
                    ws.push(manipulateJson(columns, rows, true))
                    ws1 = ws[0]
                    let row = [{label: columns[0].label, deviation: columns[1].label}, ...rows]

                    if (idx >= 1 && idx < jsonData.length) {
                        rowLimit += rows.length + 3
                        xlsx.utils.sheet_add_json(ws1, row, { origin: `A${rowLimit}`, skipHeader: true })
                    }
                }
            })
            if (ws1) {
                workbook = {
                    SheetNames: [`${modelName}`],
                    Sheets: {
                        [`${modelName}`]: ws1
                    }
                }
            } else {
                workbook = {
                    SheetNames: [`${modelName}`],
                    Sheets: {
                        [`${modelName}`]: xlsx.utils.json_to_sheet(jsonData)
                    }
                }
            }
        } else {
            var elt = node.current.querySelector('table')
            workbook = xlsx.utils.table_to_book(elt, { sheet: `${modelName}` })
        }
        if (workbook)
            xlsx.writeFile(workbook, `${cardName} ${modelName}.xlsx`)
    }
    // let widgetBody = node.querySelector('.widget-body'), table = null;

    // var uri = 'data:application/vnd.ms-excel;base64,',
    //     template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
    //     base64 = s => window.btoa(unescape(encodeURIComponent(s))),
    //     format = (s, c) => s.replace(/{(\w+)}/g, function(m, p) { return c[p]; })

    // table = widgetBody.querySelector('table')
    // var ctx = {
    //     worksheet: (name || 'Worksheet'),
    //     table: table.innerHTML,
    // }
    // // window.location.href = uri + base64(format(template, ctx))
    // let cardName = name.querySelector('.title').innerText.replace(/\(.*\)/g, '').trim(),
    // modelName = name.querySelector('.label-container .text1') ? name.querySelector('.label-container .text1').innerText.trim() : ''

    // let link = document.createElement('a')
    // link.href = uri + base64(format(template, ctx))
    // link.download = `${cardName} ${modelName}.xlsx`
    // link.click()
    // link.remove()
}

export const copyToClipboard = (jsonData) => {
    function fallbackCopyTextToClipboard(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'Data Copied' : 'Unable to copy. Please try again.';
            let snackId = parseInt(+new Date().getMilliseconds() + Math.random().toFixed())
            topBar.enqueueSnack({
                id: snackId,
                message: msg,
                to: 3000,
                success: false
            })
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
            topBar.enqueueSnack({
                id: snackId,
                message: 'Unable to copy. Please try again later.',
                to: 3000,
                success: false
            })
        }

        document.body.removeChild(textArea);
    }

    if (jsonData !== '' || !Object.keys(jsonData).length) {
        if (jsonData !== '' && !_.isEmpty(jsonData)) {
            let { columns, data, metric } = jsonData
            let csv = '', rows = [], cols = []
            if (columns && data) {
                cols = [...columns].map(i => i.columns ? [...i.columns] : i).flat(Infinity)
                data = Object.assign({}, {rows: _.concat([], data || [])}).rows
                data.forEach(row => {
                    var r = {}
                    cols.map(col => {
                        if (typeof row[col.key] === 'object')
                            r = { ...row, [col.key]: { ...row[col.key] } }
                        return r
                    })
                    rows.push(_.isEmpty(r) ? row : r)
                    r = {}
                })
                rows = rows.map(row => {
                    cols.forEach(col => {
                        let val = col.type === 'cell' ? row[col.key]?.value : row[col.key]
                        if (typeof row[col.key] === 'object') {
                            let key = metric ? metric : col.key
                            row[col.key] = metrics.filter(i => i.key === key).length
                                ? metrics.filter(i => i.key === key)[0].export_fformat(val)
                                : col.format
                                    ? col.format(val)
                                    : val
                        }
                    })
                    return row
                })
                console.log(cols, rows)
                csv = `${cols.map(i => i.label).join('\t')}\n${tsvFormat(rows, cols.map(i => i.key)).split('\n').slice(1).join('\n')}`
            }

            else if (data) {
                csv = `${tsvFormat(data, Object.keys(data[0]))}`
            } else {
                cols = Object.keys(jsonData[0] || {})
                rows = (jsonData || []).map(row => {
                    cols.forEach(col => {
                        if (typeof row[col] === 'object')
                            row[col] = !_.isEmpty(row[col]) && row[col].value ? row[col].value : null
                    })
                    return row
                })
                console.log(cols, rows)
                csv = `${tsvFormat(jsonData, cols)}`
            }
            console.log(csv)
            fallbackCopyTextToClipboard(csv)
        }
    }
}
