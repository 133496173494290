import * as d3 from "d3";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as analyticsActionCreators } from "../../store/Analytics.ts";
import * as debounce from "../../utils/debounce";
import { CardLoading } from "../card/CardLoading";
import { FilterChooser } from "../filter/FilterChooser";
import { ProductBlockMini } from "../product-drawer/ProductBlockMini";
import "./USAViewer.scss";

export const states = [
	{ syntax: "state==0", label: "AK", pos: "00", description: "Alaska" },
	{ syntax: "state==1", label: "AL", pos: "67", description: "Alabama" },
	{ syntax: "state==2", label: "AR", pos: "55", description: "Arkansas" },
	{ syntax: "state==3", label: "AZ", pos: "52", description: "Arizona" },
	{ syntax: "state==4", label: "CA", pos: "41", description: "California" },
	{ syntax: "state==5", label: "CO", pos: "43", description: "Colorado" },
	{ syntax: "state==6", label: "CT", pos: "3A", description: "Connecticut" },
	{ syntax: "state==7", label: "DE", pos: "4A", description: "Delaware" },
	{ syntax: "state==8", label: "FL", pos: "79", description: "Florida" },
	{ syntax: "state==9", label: "GA", pos: "68", description: "Georgia" },
	{ syntax: "state==10", label: "HI", pos: "70", description: "Hawaii" },
	{ syntax: "state==11", label: "IA", pos: "35", description: "Iowa" },
	{ syntax: "state==12", label: "ID", pos: "22", description: "Idaho" },
	{ syntax: "state==13", label: "IL", pos: "26", description: "Illinois" },
	{ syntax: "state==14", label: "IN", pos: "36", description: "Indiana" },
	{ syntax: "state==15", label: "KS", pos: "54", description: "Kansas" },
	{ syntax: "state==16", label: "KY", pos: "46", description: "Kentucky" },
	{ syntax: "state==17", label: "LA", pos: "65", description: "Louisiana" },
	{ syntax: "state==18", label: "MA", pos: "2B", description: "Massachussets" },
	{ syntax: "state==19", label: "MD", pos: "49", description: "Maryland" },
	{ syntax: "state==20", label: "ME", pos: "0B", description: "Maine" },
	{ syntax: "state==21", label: "MI", pos: "28", description: "Michigan" },
	{ syntax: "state==22", label: "MN", pos: "25", description: "Minnesota" },
	{ syntax: "state==23", label: "MO", pos: "45", description: "Missouri" },
	{ syntax: "state==24", label: "MS", pos: "66", description: "Mississippi" },
	{ syntax: "state==25", label: "MT", pos: "23", description: "Montana" },
	{ syntax: "state==26", label: "NC", pos: "57", description: "North Carolia" },
	{ syntax: "state==27", label: "ND", pos: "24", description: "North Dakota" },
	{ syntax: "state==28", label: "NE", pos: "44", description: "Nebraska" },
	{ syntax: "state==29", label: "NH", pos: "1B", description: "New Hapshire" },
	{ syntax: "state==30", label: "NJ", pos: "39", description: "New Jersey" },
	{ syntax: "state==31", label: "NM", pos: "53", description: "New Mexico" },
	{ syntax: "state==32", label: "NV", pos: "42", description: "Nevada" },
	{ syntax: "state==33", label: "NY", pos: "29", description: "New York" },
	{ syntax: "state==34", label: "OH", pos: "37", description: "Ohio" },
	{ syntax: "state==35", label: "OK", pos: "64", description: "Oklahoma" },
	{ syntax: "state==36", label: "OR", pos: "31", description: "Oregon" },
	{ syntax: "state==37", label: "PA", pos: "38", description: "Pennsylvania" },
	{ syntax: "state==38", label: "RI", pos: "2A", description: "Rhode Island" },
	{
		syntax: "state==39",
		label: "SC",
		pos: "58",
		description: "South Carolina",
	},
	{ syntax: "state==40", label: "SD", pos: "34", description: "South Dakota" },
	{ syntax: "state==41", label: "TN", pos: "56", description: "Tennessee" },
	{ syntax: "state==42", label: "TX", pos: "74", description: "Texas" },
	{ syntax: "state==43", label: "UT", pos: "32", description: "Utah" },
	// vermont??
	{ syntax: "state==45", label: "VA", pos: "48", description: "Virginia" },
	{ syntax: "state==46", label: "WA", pos: "21", description: "Washington" },
	{ syntax: "state==47", label: "WI", pos: "27", description: "Wisconsin" },
	{ syntax: "state==48", label: "WV", pos: "47", description: "West Virginia" },
	{ syntax: "state==49", label: "WY", pos: "33", description: "Wyoming" },
];

export class USAViewer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};

		// Allow card to be saved as insight card. Not the greatest pattern.
		this.props.stateContext.getSaveState = this.getSaveState;
	}

	componentDidMount() {
		this.refreshIfNeeded();
	}

	componentDidUpdate() {
		this.refreshIfNeeded();
		if (this.props.cache && this.props.cache.loaded)
			this.props.loaded(this.props.cache.loaded);
	}

	refreshIfNeeded = debounce.debounce(() => {
		// cancel if no selection
		const selection = this.props.selection;
		if (!selection) return;

		// cancel if insight card
		if (this.props.inInsightCard) return;

		// cancel if no query defined
		if (!this.props.getQuery) return;

		if (!this.props.cache) {
			const filter = this.props.filter;
			const rootPath = this.props.path;
			const path = `${rootPath}:${this.props.selection.uid}`;
			const key = `${filter.syntax}:${path}`;
			const query = this.props.getQuery(this.props.selection, filter);
			this.props.loadYak(path, key, query);
		}
	}, debounce.basicDelay);

	static inheritOptions = [];

	getSaveState = () => {
		return {
			selection: this.props.selection,
			var: this.props.var || this.state.var, // do i need this?
			cache: this.props.cache,
			filter: this.props.filter,
		};
	};

	render() {
		const study = this.props.study;
		if (!study) return;

		let cache = this.props.cache;
		let selection = this.props.selection;
		if (!selection) return "no selection";

		const selectionLevel = this.props.selectionLevel;

		// if( !cache ){
		//     const fullCache = this.props.fullCache;
		//     const rootPath = this.props.path;
		//     const v = this.props.var || this.state.var;
		//     const path = `${rootPath}/${v}`
		//     cache = fullCache ? fullCache[path] : null;
		// }

		const model = selection?.[selectionLevel];

		const data = cache ? cache.value : null;
		const data2 =
			data && model
				? this.props.entries.map((entry) => {
						const topbox = ((data[`${model}:topbox`] || {})[entry.syntax] || {})
							.value;
						const botbox = ((data[`${model}:botbox`] || {})[entry.syntax] || {})
							.value;
						const midbox =
							isNaN(topbox) || isNaN(botbox) ? null : 1.0 - topbox - botbox;
						const nps =
							isNaN(topbox) || isNaN(botbox) ? null : (topbox - botbox) * 1.0;
						const nps2 =
							isNaN(topbox) || isNaN(botbox) ? null : (topbox - botbox) * 100.0;
						const avg_nps = (data[`*:nps`] || {})[entry.syntax];
						const avg_nps2 = avg_nps * 100.0;
						return {
							...entry,
							topbox,
							botbox,
							midbox,
							nps,
							nps2,
							avg_nps,
							avg_nps2,
							n: ((data[`${model}:topbox`] || {})[entry.syntax] || {}).n,
						};
				  })
				: [];
		const data3 = data2.filter((f, i) => i < 10);
		//console.log(data3);

		//console.log('minVal', minVal);
		const view = this.props.view || "line-nps-only";

		let content = null;

		const bg_interpolator = d3
			.scaleLinear()
			.domain([0, 10])
			.range(["#b7dbf5", "#265b83"]);

		const fg_interpolator = d3
			.scaleLinear()
			.domain([0, 7, 7.1, 10])
			.range(["#000000", "#000000", "#ffffff", "#ffffff"]);

		//const fg_color = d3.scaleOrdinal(d3.quantize(fg_interpolator, data.children.length + 1))

		if (cache && !cache.loading) {
			let means = cache && cache.value ? cache.value.mean : null;

			let data_points = states.map((state) => {
				let cell = means ? means[state.syntax] : null;
				let val = cell ? cell.value : null;
				let n = cell ? cell.n : null;
				return cell
					? {
							...state,
							val,
							n,
					  }
					: state;
			});
			//console.log('data_points', data_points);

			let allBoxes = "01234567".split("").map((row) => {
				return (
					//<div className='boxrow' key={row}>
					"0123456789AB".split("").map((col) => {
						let state = data_points.find((state) => state.pos === row + col);

						let bgColor = "#efefef";
						let fColor = "#000000";
						if (state && state.val) {
							//bgColor = 'rgba(0,0,0,' + (state.val / 10.0) + ')';
							bgColor = bg_interpolator(state.val);
							fColor = fg_interpolator(state.val);
						}

						return state ? (
							<span
								key={col}
								className="box data"
								title={state.description + " " + (state.val ? state.val : "")}
								style={{ backgroundColor: bgColor, color: fColor }}>
								{state.label}
							</span>
						) : (
							<span key={col} className="box blank"></span>
						);
					})
				); //</div>
			});
			content = (
				<div className="container1">
					<div className="usa-map">{allBoxes}</div>
					<div className="legend">
						{[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val) => (
							<span
								key={val}
								className="swatch"
								style={{
									backgroundColor: bg_interpolator(val),
									color: fg_interpolator(val),
								}}>
								{val}
							</span>
						))}
					</div>
				</div>
			);
		}

		if (
			cache &&
			cache.loaded &&
			this.props.loaded &&
			typeof this.props.loaded === "function"
		)
			this.props.loaded(cache.loaded);

		return (
			<div className="widget usa-viewer">
				<div className="widget-header">
					<div className="title">{this.props.title}</div>
					<div className="selection">
						<ProductBlockMini
							{...selection}
							disabled={this.props.inInsightCard}
							allowChange={[selectionLevel]}
						/>
					</div>
					<div className="filter-etc">
						<FilterChooser
							mini={true}
							disabled={this.props.inInsightCard}
							selection={this.props.filter}
						/>
					</div>
				</div>
				<div className="widget-body">{content}</div>
				<CardLoading loading={cache && cache.loading} />
			</div>
		);
	}
}

USAViewer = connect(
	(state, ownProps) => {
		const filter =
			state.filter?.selectedFilter || globals.getDefaultFilter(state.study);
		const selectionLevel = ownProps.selectionLevel || "product";
		const selection = state.selections?.[selectionLevel];

		return {
			study: state.study,
			selectionLevel,
			...(ownProps.inDataCard
				? {
						filter,
						selection,
						cache:
							state.cache.analytics[
								`${filter.syntax}:${ownProps.path}:${selection?.uid}`
							],
				  }
				: {}),
		};
	},
	(dispatch) => bindActionCreators(analyticsActionCreators, dispatch),
)(USAViewer);
