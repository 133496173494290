import * as d3 from "d3";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as forecastActionCreators } from "../../store/Forecast.ts";
import * as debounce from "../../utils/debounce";
import { CardLoading } from "../card/CardLoading";
import { FilterChooser } from "../filter/FilterChooser";
import "./DCMSunburstWidget.scss";

const gcolors = {
	background1: "#f4f4f4",
	background2: "#ffffff",
	pageBackground: "#ffffff",

	primary1: "#00517d", // dark blue
	primary2: "#0078bf", // mid blue

	secondary1: "#82c341", // green
	accent1: "#565a5c", // dark gray
	accent2: "#83908f", // light gray

	highlight1: "#ec7a08", // orange
	highlight2: "#f0ab00", // gold
	highlight3: "#822433", // plum

	//define color
	color1: "#bd4008", // dark red
	color2: "#f78026", // orange
	color3: "#faa41b", // yellow
	color4: "#fdbe11",
	color5: "#d2cf20",
	color6: "#9fcf21",
	color7: "#79cf22",
	color8: "#44c721",
	color9: "#1fbf51",
	color10: "#1cad86",
	color11: "#2991b6",
	color12: "#147b9e",
	color13: "#025395",
	color14: "#1c3585",
};

const width = 900;

export class DCMTreeMapWidget extends React.Component {
	constructor(props) {
		super(props);
		this.createChart = this.createChart.bind(this);

		// local widget settings
		this.state = {
			agg: props.agg || "product",
		};

		this.props.stateContext.getSaveState = this.getSaveState;
	}

	node;
	root = null;

	componentDidMount() {
		this.refreshIfNeeded();
		this.createChart();
	}

	componentDidUpdate() {
		this.refreshIfNeeded();
		this.createChart();
	}

	getData = () => {
		const config = this.props.config;
		if (!config) return null;

		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);

		const cache = this.props.cache;
		let filterCache = (cache || {})[filter.syntax];

		if (this.props.inInsightCard) {
			filterCache = this.props.filterCache;
		}
		if (!filterCache) return null;

		//const metric = globals.metrics.find((m) => m.key === "share");
		const metric = globals.get_sunburst_metric();

		let sunburst = {
			name: "product",
			children: config.productSlots.map((slot) => {
				let slotCache = filterCache[slot.uid];
				let value =
					slotCache && slotCache.play ? slotCache.play[metric.key] : null;
				return {
					name: slot.label,
					label2: slot.label + " " + value,
					labelNote: "click here to go back",
					value,
					aggValue: value,
					etc: slot.play,
				};
			}),
		};
		sunburst = this.groupBy(
			sunburst,
			config.productDefs.default.atts.find((att) => att.name === "model"),
		);
		sunburst = this.groupBy(
			sunburst,
			config.productDefs.default.atts.find((att) => att.name === "brand"),
		);
		sunburst.labelNote = "click slice to explore";

		sunburst.aggValue = sunburst.children.reduce((accum, el) => {
			return accum + el.aggValue;
		}, 0);

		this.addLabel2(sunburst, metric);
		//sunburst.label = "Total Revenue";
		sunburst.label = "Total " + metric.label;
		return sunburst;
	};

	addLabel2 = (node, metric) => {
		node.label = node.name;
		node.label2 = metric.fformat(node.aggValue);
		node.valueLabel = metric.fformat(node.aggValue);
		if (node.children) {
			node.children.forEach((ch) => this.addLabel2(ch, metric));
		}
	};

	groupBy = (sunburst, att) => {
		// get distinct values
		let newSunburst = {
			name: att.name,
			children: [],
		};

		sunburst.children.forEach((child) => {
			const x = child.etc[att.name];
			let newChild = newSunburst.children.find((ch) => ch.key == x);
			if (!newChild) {
				const xLabel = ((att.levels || []).find((l) => l.value == x) || {})
					.label;
				newChild = {
					key: x,
					name: xLabel,
					labelNote: "click here to go back",
					etc: child.etc, // hacky
					aggValue: 0,
					children: [],
				};
				newSunburst.children.push(newChild);
			}
			newChild.children.push(child);

			newChild.aggValue += child.aggValue;
		});

		return newSunburst;
	};

	createChart = async () => {
		const node = this.node;
		const data = this.getData();
		if (!data) return;

		const interpolator1 = d3
			.scaleLinear()
			.domain([
				0,
				0.07,
				0.14,
				0.21,
				0.28,
				0.35,
				0.42,
				0.49,
				0.56,
				0.63,
				0.7,
				0.77,
				0.84,
				1,
			])
			.range([
				gcolors.color13,
				gcolors.color12,
				gcolors.color11,
				gcolors.color10,
				gcolors.color9,
				gcolors.color8,
				gcolors.color7,
				gcolors.color6,
				gcolors.color5,
				gcolors.color4,
				gcolors.color3,
				gcolors.color2,
				gcolors.color1,
			]);

		const color = d3.scaleOrdinal(
			d3.quantize(interpolator1, data.children.length + 1),
		);

		this.root = this.treemap(data);
		const root = this.root;
		root.each((d) => (d.current = d));

		const svg = d3
			.select(node) //DOM.svg(width, width))
			.attr("viewBox", [0, 0, 900, 900])
			.style("font", "10px sans-serif");

		svg.selectAll("*").remove();
		svg
			.style("width", "100%")
			.style("height", "auto")
			.style("position", "absolute")
			.style("top", 0)
			.style("bottom", 0)
			.style("font", "10px sans-serif");

		const leaf = svg
			.selectAll("g")
			.data(root.leaves())
			.join("g")
			.attr("transform", (d) => `translate(${d.x0},${d.y0})`);

		leaf.append("title").text(
			(d) =>
				`${d
					.ancestors()
					.map((d) => d.data.name)
					.reverse()
					.join("/")}\n${d.data.valueLabel}`,
		);

		leaf
			.append("rect")
			.attr("id", (d, i) => `rect-${i}`)
			.attr("fill", (d) => {
				while (d.depth > 1) d = d.parent;
				return color(d.data.name);
			})
			.attr("fill-opacity", 0.6)
			.attr("width", (d) => d.x1 - d.x0)
			.attr("height", (d) => d.y1 - d.y0);

		leaf
			.append("clipPath")
			.attr("id", (d, i) => `clip-${i}`)
			.append("use")
			.attr("xlink:href", (d, i) => `#rect-${i}`);

		leaf
			.append("text")
			.attr("clip-path", (d, i) => `url(#clip-${i})`)
			.selectAll("tspan")
			.data((d) => d.data.name.split(/(?=[A-Z][^A-Z])/g))
			.join("tspan")
			.attr("x", 3)
			.attr(
				"y",
				(d, i, nodes) => `${(i === nodes.length - 1) * 0.3 + 1.1 + i * 0.9}em`,
			)
			.attr("fill-opacity", (d, i, nodes) =>
				i === nodes.length - 1 ? 0.7 : null,
			)
			.text((d) => d);
	};

	treemap = (data) =>
		d3
			.treemap()
			.tile(d3["treemapSquarify"])
			.size([900, 900])
			.padding(1)
			.round(true)(
			d3
				.hierarchy(data)
				.sum((d) => d.value)
				.sort((a, b) => b.value - a.value),
		);

	refreshIfNeeded = debounce.debounce(() => {
		//if (!this.props.selection) return;

		// cancel if insight card
		if (this.props.inInsightCard) return;

		// cancel if config isn't ready
		if (!this.props.etag) return;

		// cancel if cached data already exists, with matching filter and etag
		const cache = this.props.cache;
		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);
		const filterCache = (cache || {})[filter.syntax];
		if (filterCache && filterCache.error) return;

		//const productCache = (filterCache || {})[this.props.selection.product];
		if (filterCache && filterCache.etag === this.props.etag) return;

		// cancel if cached data is loading
		if (filterCache && filterCache.loading) return;

		// refresh is needed. request it.
		this.props.requestForecast();
	}, debounce.forecastDelay);

	// these are needed from parent state
	static inheritOptions = ["configEtag"];
	static scenarioDriven = true;

	getSaveState = () => {
		const config = this.props.config;
		if (!config) return null;

		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);

		// get the cache
		const cache = this.props.cache;

		const filterCache = (cache || {})[filter.syntax];

		return {
			filterCache: filterCache,
			filter,
		};
	};

	render() {
		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);

		const cache = this.props.cache;
		let filterCache = (cache || {})[filter.syntax];

		if (this.props.inInsightCard) {
			filterCache = this.props.filterCache;
		}
		if (filterCache && !filterCache.loading) {
			if (this.props.loaded && typeof this.props.loaded === "function")
				this.props.loaded(!filterCache.loading);
		}

		if (this.props.getJSONData && typeof this.props.getJSONData === "function")
			this.props.getJSONData(null, true);

		const content = !filterCache ? null : (
			<div className="middle-content">
				{
					<svg
						key="singleton"
						ref={(node) => (this.node = node)}
						style={{
							border: "0px",
							width: 400,
							height: 900,
							...this.props.style,
						}}
						viewBox={"0 0 " + width + " " + width}
						preserveAspectRatio="xMidYMid meet"
					/>
				}
			</div>
		);

		//const selection = this.props.selection;

		return (
			<div className="widget dcm-sunburst-widget treemap">
				<div className="widget-header">
					{/* <div className='selection'>
                    <ProductBlockMini {...selection} disabled={this.props.inInsightCard} />
                </div> */}
					{this.props.title ? (
						<div className="title">{this.props.title}</div>
					) : null}
					<div className="filter-etc">
						<FilterChooser
							mini={true}
							disabled={this.props.inInsightCard}
							selection={filter}
						/>
					</div>
				</div>
				<div className="widget-body">{content}</div>
				<CardLoading loading={filterCache && filterCache.loading} />
			</div>
		);
	}
}

DCMTreeMapWidget = connect(
	(state, ownProps) => ({
		studyId: state.study?.uid,
		study: state.study,
		config: state.study?.config,
		...(ownProps.inDataCard
			? {
					etag: ((state.study || {}).config || {}).etag,
					filter: (state.filter || {}).selectedFilter,
					cache: (state.cache || {}).forecast,
			  }
			: {}),
	}),
	(dispatch) => bindActionCreators(forecastActionCreators, dispatch),
)(DCMTreeMapWidget);
