import * as React from 'react';
import { useSelector } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import './ContentViewer.scss';
import { getChildLink } from './ExploreUtils';



export const QuestionContainer = (props) => {

    const {id, title, contents, data} = props.item;

    const study_uid = useSelector(state => state.study?.uid);

    const area = props.area;
    const params = useParams();

    const wide = data?.wide === 'true';

    const content = <div className={'content-section ' + (wide ? 'wide-width' : 'page-width')} >
        <div className='title'>
            {title}
        </div>
        <div className='question-container margin-bottom'>
            {contents?.map(q => {
                
                return <Link className='question' key={q.id}
                    to={getChildLink(params, props.asPage ? q.id : `${id}/${q.id}`, study_uid)}
                >
                    {q.title}
                </Link>
            })}

        </div>
    </div>;

    // todo: add reloadSelf to component and send to wrapAsPage
    return props.asPage ? wrapAsPage(props.item, content, null) : content;

}


