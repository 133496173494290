import * as React from 'react';
import './ComboBoxCustom.scss';

export class ComboBoxCustom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };

        this.closeTimer = null;
    }


    softCloseMenu = () => {
        //console.log('softCloseMenu');
        if( this.closeTimer ){
            clearTimeout(this.closeTimer);
        }
        this.closeTimer = setTimeout(this.closeMenu, 1);
    }

    cancelSoftClose = () => {
        //console.log('cancelSoftClose');
        if( this.closeTimer ){
            clearTimeout(this.closeTimer);
        }
    }

    closeMenu = () => {
        //console.log('closeMenu');
        this.closeTimer = null;
        this.setState({ isOpen: false });
    }

    toggleOpen = () => {

        const newState = !this.state.isOpen;

        this.setState({ isOpen: newState });
        if( newState ){
            // set the focus
            this.menu.focus();
        }

    }

    itemClicked = (item, checked) => {
        if( this.props.multiSelect ){
            if( this.props.itemChecked ){
                this.props.itemChecked(item, checked);
            }
        }
        else{
            if( this.props.selectionChanged ){
                this.props.selectionChanged(item);
            }
            this.closeMenu();
        }
    }

  render() {
    const items = this.props.items || [];
    const multiSelect = this.props.multiSelect;
    const menuWidth = this.props.menuWidth || '150px';

    let btnStyle = {};
    if( this.props.width ){
        btnStyle.width = this.props.width;
    }


    return (
      <div className={"combo-box-custom " + (this.props.className ? this.props.className : '')}
        style={this.props.style}
        onBlur={() => this.softCloseMenu()}
        onFocus={() => this.cancelSoftClose()}
      >
        <div onClick={this.toggleOpen} tabIndex="0" >
            {this.props.button}
        </div>

        <div className={"menu" + (this.state.isOpen ? ' open' : ' closed')} tabIndex="1" 
            style={{ minWidth: menuWidth }} 
            
            ref={(el) => {this.menu = el}}
            
            >
          {items.map((item, idx) =>
              item.type === 'separator' ? (
                <div key={idx} className="separator" />
              ) : (
                <button key={idx} className="item" onClick={() => this.itemClicked(item, multiSelect ? !item.checked : null)}>
                    {multiSelect ? 
                        <span className='checkbox'>
                            <i className={item.checked ? 'fal fa-check-square' : 'fal fa-square'}/>
                        </span>
                        : null
                    }
                    {item.icon ? <span className='checkbox'>
                        {item.icon}
                    </span> : null}
                    {item.label}
                </button>
              )
            // todo: add icon
          )}
        </div>
      </div>
    );
  }
}
