import './EditPalette.scss';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as apiService from '../../utils/apiService';
import * as globals from '../../globals';
import ReactJson from 'react-json-view';

export const EditPalette = (props) => {

    const pal = props.pal || {
        main: { bg: "#ffc000", fg: "black" },
        overlap: { bg: "#000000", fg: "white" },
        comps: [
            { bg: "#800080", fg: "white" },
            { bg: "#934bc9", fg: "white" },
            { bg: "#cc66ff", fg: "black" },
            { bg: "#ccccff", fg: "black" },
            { bg: "#66ffff", fg: "black" },
        ] 
    }

    const entries = Object.entries(pal);
    return <div className='edit-palette'>
        {entries.map(entry => <div key={entry[0]} className='palette-row'>
            {renderEntry(`.${entry[0]}`, entry[1])}
        </div>)}
    </div>;

}

const renderEntry = (name, val) => {
    if (typeof val == "object") {
        if (Array.isArray(val)) {
            return <EditSwatches key={name} name={name} entries={val} />;
        }
        else {
            return <EditSwatch key={name} name={name} entry={val} />
        }
    }
    // else ignore
    return null;
}

export const EditSwatches = (props) => {
    const entries = props.entries || [];
    return <div className='edit-swatches'>
        <div className='name'>{props.name}</div>
        <div className='list'>
            {entries.map((entry, i) => renderEntry(`[${i}]`, entry))}
        </div>
    </div>
}

export const EditSwatch = (props) => {
    const val = props.entry;
    return <div className='edit-swatch'>
        <div className='name'>{props.name}</div>
        <div className='square' style={{ backgroundColor: val?.bg, color: val?.fg }}>
            <div>bg: {val?.bg}</div>
            <div>fg: {val?.fg}</div>
        </div>
    </div>;
}