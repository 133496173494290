import * as React from "react";
import { connect } from "react-redux";
import {
	Bar,
	BarChart,
	LabelList,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
	Legend,
} from "recharts";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as forecastActionCreators } from "../../store/Forecast.ts";
import * as debounce from "../../utils/debounce";
import * as selectionHelper from "../../utils/selectionHelper";
import { CardLoading } from "../card/CardLoading";
import { FilterChooser } from "../filter/FilterChooser";
import { ProductBlockMini } from "../product-drawer/ProductBlockMini";
import CustomLegend from "../common/CustomLegend";
import "./DCMColumnsWidget.scss";

export class DCMColumnsWidget extends React.Component {
	constructor(props) {
		super(props);

		// local widget settings
		this.state = {
			agg: props.agg || "product",
		};

		this.props.stateContext.getSaveState = this.getSaveState;
	}

	componentDidMount() {
		this.refreshIfNeeded();
	}

	componentDidUpdate() {
		this.refreshIfNeeded();
	}

	refreshIfNeeded = debounce.debounce(() => {
		//if( !this.props.selection ) return;

		// cancel if insight card
		if (this.props.inInsightCard) return;

		// cancel if config isn't ready
		if (!this.props.etag) return;

		// cancel if cached data already exists, with matching filter and etag
		const cache = this.props.cache;
		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);
		const filterCache = (cache || {})[filter.syntax];
		if (filterCache && filterCache.error) return;

		const selectedProduct = this.props.selectedProduct;
		if (!selectedProduct) return;

		const productCache = (filterCache || {})[selectedProduct.uid];
		if (productCache && filterCache.etag === this.props.etag) return;

		// cancel if cached data is loading
		if (filterCache && filterCache.loading) return;

		// refresh is needed. request it.
		this.props.requestForecast();
	}, debounce.forecastDelay);

	// these are needed from parent state
	static inheritOptions = ["configEtag"];

	static scenarioDriven = true;

	// returns widget's local settings (for save state)
	getSaveState = () => {
		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);

		// get the cache
		const cache = this.props.cache;

		//const selectedProductId = this.props.selections?.product?.uid;

		const filterCache = (cache || {})[filter.syntax];
		const productCache = this.props.inInsightCard
			? this.props.productCache
			: this.props.selection
			? (filterCache || {})[selectedProductId]
			: null;
		//const brandCache = null;

		return {
			//selection: this.props.selection, // might not need to be here, since inheritOptions includes it

			selectedProductId: this.props.selectedProduct?.uid,

			filter: {
				label: filter.label,
				syntax: filter.syntax,
			},

			// local settings
			agg: this.state.agg,

			// cached results
			//productCache: productCache,
			//brandCache: brandCache
			filterCache: filterCache,
		};
	};

	isLoading = () => {
		let fCache = (this.props.cache || {})[this.props.filter.syntax] || {};
		return fCache.loading; // || fCache.etag !== this.props.etag;
	};

	aggregate = (data, level, selection) => {
		if (!data || !selection) return null;

		if (level === "product") {
			return data[selection.product];
		}

		const study = this.props.study;
		const config = study.config;

		// find level att
		const att = config.productDefs.default.atts.find((a) => a.name == [level]);
		if (!att) return null;

		// find selections level value
		const selectedProduct = config.productSlots.find(
			(slot) => slot.uid === selection.product,
		);
		if (!selectedProduct) return null;

		const selValue = selectedProduct.play[level];

		// find products that match the selections level
		const aggProducts = config.productSlots.filter(
			(slot) => slot.play[level] == selValue,
		);

		let retVal = {
			play: {},
			base: {},
		};
		globals.metrics.forEach((metric) => {
			let play_accum = null;
			let base_accum = null;
			aggProducts.forEach((p) => {
				let play_val =
					data[p.uid] && data[p.uid].play ? data[p.uid].play[metric.key] : null;
				let base_val =
					data[p.uid] && data[p.uid].base ? data[p.uid].base[metric.key] : null;
				if (metric.aggMethod === "sum") {
					if (play_val) play_accum = (play_accum || 0) + play_val;
					if (base_val) base_accum = (base_accum || 0) + base_val;
				} else if (metric.aggMethod === "max") {
					if (play_val)
						play_accum = play_accum ? Math.max(play_accum, play_val) : play_val;
					if (base_val)
						base_accum = base_accum ? Math.max(base_accum, base_val) : base_val;
				}
			});
			retVal.play[metric.key] = play_accum;
			retVal.base[metric.key] = base_accum;
		});

		return retVal;
	};

	render() {
		const study = this.props.study;
		if (!study) return;

		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);

		const cache = this.props.cache;
		let filterCache = (cache || {})[filter.syntax];
		//let productCache = this.props.selection ? (filterCache || {})[this.props.selection.product] : null;
		if (filterCache && !filterCache.loading)
			this.props.loaded(!filterCache.loading);

		if (this.props.inInsightCard) {
			//filter = this.props.filter;
			filterCache = this.props.filterCache;
			// TODO!!!
			// what is todo?
		}

		const selectedProductId = this.props.inInsightCard
			? this.props.selectedProductId
			: this.props.selectedProduct?.uid;
		const selectedProduct = selectionHelper.getSelectionInfo(
			"product",
			selectedProductId,
			study,
			this.props.selections.hierarchy,
		);

		let aggregatedCache = this.aggregate(
			filterCache,
			this.state.agg,
			selectedProduct,
		);

		// hack for backwards compatibility
		if (
			this.props.inInsightCard &&
			!this.props.filterCache &&
			this.props.productCache
		) {
			aggregatedCache = this.props.productCache;
		}

		const expired = this.props.etag !== (filterCache || {}).etag;

		//const selectedProduct = this.props.selectedProducts?this.props.selectedProducts[0]:{};
		// const colors = this.state.agg === 'product' ? [globals.configs2.play.color, globals.configs2.base.color] :
		//     ['red', 'gray'];
		const colors = [globals.configs2.play.color, globals.configs2.base.color];
		if (this.props.getJSONData && typeof this.props.getJSONData === "function")
			this.props.getJSONData(null, true);

		const content = !aggregatedCache ? (
			filterCache && filterCache.error ? (
				filterCache.error
			) : null
		) : (
			<div className="middle-content">
				{globals.metrics.map((metric) => {
					let data = [
						{
							name: metric.label,
							play: aggregatedCache ? aggregatedCache.play[metric.key] : null,
							base: aggregatedCache ? aggregatedCache.base[metric.key] : null,
						},
					];

					const diffNum = data[0].play - data[0].base;

					return (
						<div className="cell" key={metric.key}>
							<div className="bar-chart">
								<ResponsiveContainer>
									<BarChart
										key={metric.key}
										data={data}
										style={{ display: "inline-block" }}>
										<XAxis dataKey="name" />
										<YAxis padding={{ top: 20 }} hide={true} />
										<Tooltip formatter={metric.dformat} />
										<Bar
											isAnimationActive={false}
											dataKey="base"
											name={globals.configs2.base.label}
											fill={colors[1]}>
											<LabelList
												dataKey="base"
												position="top"
												formatter={metric.fformat}></LabelList>
										</Bar>
										<Bar
											isAnimationActive={false}
											dataKey="play"
											name={globals.configs2.play.label}
											fill={colors[0]}>
											<LabelList
												dataKey="play"
												position="top"
												formatter={metric.fformat}></LabelList>
										</Bar>
									</BarChart>
								</ResponsiveContainer>
							</div>
							<div className="diff">
								<i
									className={
										"far " +
										(diffNum < 0
											? "fa-minus red"
											: diffNum > 0
											? "fa-plus green"
											: null)
									}></i>
								&nbsp; {metric.fformat(Math.abs(diffNum))}
							</div>
						</div>
					);
				})}
			</div>
		);

		const aggLevels = [
			...study.selectionHierarchy,
			{ name: "product", label: study.productLabel },
		];

		let titleSelection = this.state.agg === "product" ? selectedProduct : null;
		if (this.state.agg !== "product") {
			let agg = this.state.agg;
			let selSlot = selectedProductId
				? study.config.productSlots.find(
						(slot) => slot.uid == selectedProductId,
				  )
				: null;
			let att =
				study.config?.productDefs?.default?.atts.find((a) => a.name === agg) || {};
			let level = (att?.levels || []).find((l) => l.value == selSlot?.play[agg]);
			titleSelection = {
				available: 1,
				label: level ? level.label : "error",
				type: agg,
				typeLabel: att.label,
			};
		}
		if (
			filterCache &&
			!filterCache.loading &&
			this.props.loaded &&
			typeof this.props.loaded
		)
			this.props.loaded(!filterCache.loading);

		return (
			<div className="widget dcm-columns-widget">
				<div className="widget-header">
					{this.props.title ? (
						<div className="title">{this.props.title}</div>
					) : null}
					<div className="selection">
						<ProductBlockMini
							{...titleSelection}
							disabled={this.props.inInsightCard}
							allowChange={[this.state.agg]}
						/>
					</div>
					<div className="filter-etc">
						<FilterChooser
							mini={true}
							disabled={this.props.inInsightCard}
							selection={filter}
						/>
					</div>
					{this.props.inInsightCard ? null : (
						<div className="agg-level">
							{aggLevels.map((aggLevel) => (
								<span
									key={aggLevel.name}
									onClick={() => this.setState({ agg: aggLevel.name })}
									className={
										"btn" + (this.state.agg === aggLevel.name ? " sel" : "")
									}>
									{aggLevel.label}
								</span>
							))}
						</div>
					)}
					{this.props.inInsightCard && (
						<>
							Type:{" "}
							<label className="ml-1" style={{ marginRight: "1rem" }}>
								{aggLevels.find((i) => this.state.agg === i.name).label}
							</label>
						</>
					)}
				</div>
				<div className="widget-body">{content}</div>
				<CardLoading loading={filterCache && filterCache.loading} />
			</div>
		);
	}
}

DCMColumnsWidget = connect(
	(state, ownProps) => ({
		studyId: (state.study || {}).uid,
		study: state.study,
		config: (state.study || {}).config,
		selections: state.selections,
		...(ownProps.inDataCard
			? {
					etag: ((state.study || {}).config || {}).etag,
					filter: (state.filter || {}).selectedFilter,
					cache: (state.cache || {}).forecast,
					selectedProduct: state.selections?.product,
			  }
			: {}),
	}),
	(dispatch) => bindActionCreators(forecastActionCreators, dispatch),
)(DCMColumnsWidget);
