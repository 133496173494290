import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';
import { COMPARE_ALL_DESELECT, COMPARE_GROUP_SELECT, FILTER_DESELECT_ALL, FILTER_SELECT } from '../../store/action-types.ts';
import { Tile } from '../common/Tile';
import { TileContainer } from '../common/TileContainer';
import './ContentViewer.scss';
import { getChildLink } from './ExploreUtils';

export const FilterContainer = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const {id, title, data} = props.item;

    const tileBackground = data?.tileBackground;
    const tagFilter = data?.tagFilter;
    const study_uid = useSelector(state => state.study?.uid);

    const filterGroups = useSelector(state => state.filter?.filterGroups);
    const matchingFilters = [];
    if( tagFilter && filterGroups ){
        filterGroups.forEach(group => {
            group.filters.forEach(filter => {
                if(filter.tags?.includes(tagFilter)){
                    matchingFilters.push({ group, filter });
                }
            })
        });
    }

        useEffect(() => {
            props?.setLayoutReady?.(true);
        }, []);
    
    const wide = data?.wide === 'true';

    const content = <div className={'content-section ' + (wide ? 'wide-width' : 'page-width')}>
        {!props.asPage ? <div className='title'>
            {title}
        </div> : null}
        <TileContainer>
            {matchingFilters ? matchingFilters.map(entry => {
                const { group, filter } = entry;
                return <Tile key={filter.id || filter.syntax} label={filter.label} icon={'fal fa-filter'} tileBackground={tileBackground}
                    onClick={(ev) => {
                        //alert(JSON.stringify(filter))
                        
                        // set filter
                        dispatch({ type: FILTER_DESELECT_ALL });
                        dispatch({ type: FILTER_SELECT, groupId: group.id, filterId: filter.id || filter.syntax });

                        // set compare
                        dispatch({ type: COMPARE_ALL_DESELECT });
                        dispatch({ type: COMPARE_GROUP_SELECT, folderId: "total", groupId: "1==1", selected: true })
                        dispatch({ type: COMPARE_GROUP_SELECT, folderId: group.id, groupId: filter.id || filter.syntax, selected: true })

                        // navigate somewhere?
                        const to = getChildLink(params, `segment-profile`, study_uid)
                        history.push(to);

                    }}
                />
            }) : null}
        </TileContainer>
    </div>;

    // todo: add reloadSelf to component and send to wrapAsPage
    return props.asPage ? wrapAsPage(props.item, content, null) : content;

}

