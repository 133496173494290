import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as forecastActionCreators } from "../../store/Forecast.ts";
import * as debounce from "../../utils/debounce";
import { CompareChooser } from "../filter/CompareChooser";
import { FilterChooser } from "../filter/FilterChooser";
import { TableFred } from "../vis/TableFred";
import "./DCMProductsTableC.scss";

const getFilterSyntax = (filter, group) => {
	return `(${filter.syntax}) and (${group.syntax})`;
};

export class DCMProductsTableC extends React.Component {
	constructor(props) {
		super(props);

		// local widget settings
		this.state = {
			agg: props.agg || "product",
			metric: props.metric || "share", // globals.metrics[0].key
		};

		this.props.stateContext.getSaveState = this.getSaveState;
	}

	componentDidMount() {
		this.refreshIfNeeded();
	}

	componentDidUpdate(prevProps, prevState) {
		this.refreshIfNeeded();
	}

	refreshIfNeeded = debounce.debounce(() => {
		// cancel if insight card
		if (this.props.inInsightCard) return;

		// cancel if config isn't ready
		if (!this.props.etag) return;

		// cancel if cached data already exists, with matching filter and etag
		const cache = this.props.cache;
		const filter = this.props.filter;

		const cgroups = this.props.cgroups;
		if (!cgroups) return;

		cgroups.forEach((group) => {
			let fullFilterSyntax = getFilterSyntax(filter, group);

			const filterCache = cache?.[fullFilterSyntax];
			if (filterCache && filterCache.error) return;

			//const productCache = (filterCache || {})[this.props.selection.product];
			if (filterCache && filterCache.etag === this.props.etag) return;

			// cancel if cached data is loading
			if (filterCache && filterCache.loading) return;

			// refresh is needed. request it.
			this.props.requestForecast(fullFilterSyntax);
		});
	}, debounce.forecastDelay);

	// these are needed from parent state
	static inheritOptions = ["configEtag"];

	static scenarioDriven = true;

	// returns widget's local settings (for save state)
	getSaveState = () => {
		return {
			// note: where is scenario added?? is it added later? i don't remember
			agg: this.state.agg,
			metric: this.state.metric,
			filter: this.props.filter,
			compare: this.props.compare,
			cgroups: this.props.cgroups,
			cache: this.props.cache,
		};
	};

	aggregate = (groupsData, filter, groups, level, selection) => {
		if (!groupsData) return null;

		const study = this.props.study;
		const config = study.config;

		// find next higher
		const aggLevels = [
			...study.selectionHierarchy,
			{ name: "product", label: study.productLabel },
		];
		const sectionName = aggLevels[0].name;

		if (level === "product") {
			let pRetVal = {};

			// let storeData = { play: {}, base: {} };
			// retVal[`data:${group.syntax}`] = storeData;

			return config.productSlots.map((slot) => {
				let r1 = {
					uid: slot.uid,
					product: slot.uid,
					label: slot.label,
					section: slot.play[sectionName],
					//data: data[slot.uid]
				};

				groups.forEach((group) => {
					let fullFilterSyntax = getFilterSyntax(filter, group);
					let data = groupsData[fullFilterSyntax] || {};
					r1[`data:${group.syntax}`] = data[slot.uid];
				});

				// return {
				//     uid: slot.uid,
				//     product: slot.uid,
				//     label: slot.label,
				//     section: slot.play[sectionName],
				//     data: data[slot.uid]
				// }

				return r1;
			});
		}

		// find level att
		const att = config.productDefs.default.atts.find((a) => a.name === level);
		if (!att) return null;

		const levels = att.levels;
		if (!levels) return null;

		let rows = levels.map((lev) => {
			// find products that match the selections level
			const aggProducts = config.productSlots.filter(
				(slot) => slot.play[level] == lev.value,
			);

			let slot = aggProducts.length > 0 ? aggProducts[0] : null;

			let retVal = {
				uid: `${lev.name}:${lev.value}`,
				[lev.name]: lev.value,
				label: lev.label,
				section: slot ? slot.play[sectionName] : null,
				//data: { play: {}, base: {} }
			};
			groups.forEach((group) => {
				let fullFilterSyntax = getFilterSyntax(filter, group);
				let data = groupsData[fullFilterSyntax] || {};

				let storeData = { play: {}, base: {} };
				retVal[`data:${group.syntax}`] = storeData;

				globals.metrics.forEach((metric) => {
					let play_accum = null;
					let base_accum = null;
					aggProducts.forEach((p) => {
						let play_val =
							data[p.uid] && data[p.uid].play
								? data[p.uid].play[metric.key]
								: null;
						let base_val =
							data[p.uid] && data[p.uid].base
								? data[p.uid].base[metric.key]
								: null;
						if (metric.aggMethod === "sum") {
							if (play_val) play_accum = (play_accum || 0) + play_val;
							if (base_val) base_accum = (base_accum || 0) + base_val;
						} else if (metric.aggMethod === "max") {
							if (play_val)
								play_accum = play_accum
									? Math.max(play_accum, play_val)
									: play_val;
							if (base_val)
								base_accum = base_accum
									? Math.max(base_accum, base_val)
									: base_val;
						}
					});
					storeData.play[metric.key] = play_accum;
					storeData.base[metric.key] = base_accum;
				});
			});
			return retVal;
		});
		return rows;
	};

	render() {
		const study = this.props.study;
		
		if (!study) return null;

		const config = study.config;
		if (!config) return null;

		const filter =	this.props.filter || globals.getDefaultFilter(this.props.study);
		//const compare = this.props.cgroups || globals.defaultCompare;

		const cache = this.props.cache;
		//let filterCache = (cache || {})[filter.syntax];
		//let productCache = this.props.selection ? (filterCache || {})[this.props.selection.product] : null;

		const cgroups = this.props.cgroups;
		// let filterGroupsCache = {};
		// if( cache ){
		//     cgroups.forEach(group => {
		//         let fullFilterSyntax = this.getFilterSyntax(filter, group);
		//         let filterCache = cache[fullFilterSyntax];
		//         if( filterCache ){
		//             filterGroupsCache[fullFilterSyntax] = filterCache;
		//         }
		//     })
		// }

		// if( this.props.inInsightCard ){
		//     filterGroupsCache = this.props.filterGroupsCache;
		// }

		//let aggregatedCache = this.aggregate(filterGroupsCache, filter, cgroups, this.state.agg);
		let aggregatedCache = this.aggregate(
			cache,
			filter,
			cgroups,
			this.state.agg,
		);
		//console.log('aggregatedCache', aggregatedCache);

		// let aggregatedCache = {};
		// if( filterGroupsCache ){
		//     groups.forEach(group => {
		//         let fullFilterSyntax = this.getFilterSyntax(filter, group);
		//         let filterCache = filterGroupsCache[fullFilterSyntax];
		//         if( filterCache ){
		//             aggregatedCache[fullFilterSyntax] = this.aggregate(filterCache, this.state.agg);
		//         }
		//     })
		// }

		// todo: sort

		const aggLevels = [
			...study.selectionHierarchy,
			{ name: "product", label: study.productLabel },
		];
		const selAggLevel = aggLevels.find((l) => l.name === this.state.agg) || {};

		const selMetric = globals.metrics.find(
			(metric) => metric.key === this.state.metric,
		);

		const columns = [
			{
				key: "label",
				label: selAggLevel.label,
				className: "label",
				type: "string",
			},
			...cgroups.map((group) => ({
				key: group.syntax,
				label: group.label,
				type: "cell",
				className: "data " + (selMetric.fixedWidth ? "" : " right-align"),
				format: (val) => (val === null ? "." : selMetric.fformat(val)),
			})),
		];

		const rowData = aggregatedCache?.map((row) => {
			let r = {
				uid: row.uid,
				label: row.label,
			};
			cgroups.forEach((group) => {
				let src = row[`data:${group.syntax}`]?.play;
				let cell = {
					value: src ? src[this.state.metric] : null,
					n: null,
				};
				if (!src) {
					cell.loading = true;
				}
				r[group.syntax] = cell;
			});
			return r;
		});
		//console.log('rowData', rowData);
		if (this.props.getJSONData && typeof this.props.getJSONData === "function" && columns && rowData) {
			
			this.props.getJSONData({
				columns: [...columns],
				data: [...rowData],
				metric: this.state.metric,
			});
		}

		const content = !cache ? null : (
			<div className="middle-content">
				<TableFred
					metrics={this.state.metric}
					getExportData={this.props.getExportData}
					getJSONData={this.props.getJSONData}
					columns={columns}
					data={rowData}
				/>
			</div>
		);

		if (
			cache &&
			!cache.loading &&
			this.props.loaded &&
			typeof this.props.loaded === "function"
		)
			this.props.loaded(!cache.loading);	
		

		return (
			<div className="widget dcm-products-table-c">
				<div className="widget-header">
					{this.props.title ? (
						<div className="title">
							{this.props.title}
							{/* by subgroups */}
						</div>
					) : null}
					<div className="filter-etc">
						<FilterChooser
							mini={true}
							disabled={this.props.inInsightCard}
							selection={this.props.filter}
						/>
					</div>
					<div className="filter-etc">
						<CompareChooser
							mini={true}
							disabled={this.props.inInsightCard}
							selection={this.props.compare}
						/>
					</div>
					<div className="agg-level">
						{this.props.inDataCard && (
							<span>
								{aggLevels.map((aggLevel) => (
									<span
										key={aggLevel.name}
										onClick={() => this.setState({ agg: aggLevel.name })}
										className={
											"btn" + (this.state.agg === aggLevel.name ? " sel" : "")
										}>
										{aggLevel.label}
									</span>
								))}
							</span>
						)}

						{this.props.inDataCard && (
							<select
								value={this.state.metric}
								onChange={(ev) => this.setState({ metric: ev.target.value })}>
								{globals.metrics.map((metric) => (
									<option key={metric.key} value={metric.key}>
										{metric.label}
									</option>
								))}
							</select>
						)}
						{this.props.inInsightCard && (
							<>
								Type:{" "}
								<label className="ml-1" style={{ marginRight: "1rem" }}>
									{aggLevels.find((i) => this.state.agg === i.name).label}
								</label>
								Metric:{" "}
								<label className="ml-1">
									{
										globals.metrics.filter(
											(i) => i.key === this.state.metric,
										)[0].label
									}
								</label>
							</>
						)}
					</div>
				</div>
				<div className="widget-body vscroll hscroll">{content}</div>
				{/* <CardLoading loading={cache && cache.loading}/> not using this because the individual columns load independently */}
			</div>
		);
	}
}

DCMProductsTableC = connect(
	(state, ownProps) => {
		const filter =
			state.filter?.selectedFilter || globals.getDefaultFilter(state?.study);
		let compare = state.compare?.selection || globals.defaultCompare;
		let cgroups = compare?.groups || [];
		if (cgroups.length == 0) {
			cgroups = [globals.getDefaultFilter(state?.study)];
		}

		const cache = {}; //state.cache?.forecast?.[filter.syntax];
		cgroups.forEach((cgroup) => {
			const key = getFilterSyntax(filter, cgroup);
			cache[key] = state.cache?.forecast?.[key];
		});

		return {
			studyId: state.study?.uid,
			study: state.study,
			config: state.study?.config,
			...(ownProps.inDataCard
				? {
						etag: state.study?.config?.etag,
						filter,
						compare,
						cgroups,
						cache,
				  }
				: {}),
		};
	},
	(dispatch) => bindActionCreators(forecastActionCreators, dispatch),
)(DCMProductsTableC);
