import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as forecastActionCreators } from "../../store/Forecast.ts";
import * as debounce from "../../utils/debounce";
import { CardLoading } from "../card/CardLoading";
import { FilterChooser } from "../filter/FilterChooser";
import { TableFred } from "../vis/TableFred";
import "./DCMProductsTable.scss";

export class DCMProductsTable extends React.Component {
	constructor(props) {
		super(props);

		// local widget settings
		this.state = {
			agg: props.agg || "product",
		};

		this.props.stateContext.getSaveState = this.getSaveState;
	}

	node;

	componentDidMount() {
		this.refreshIfNeeded();
	}

	componentDidUpdate() {
		this.refreshIfNeeded();
	}

	refreshIfNeeded = debounce.debounce(() => {
		//if( !this.props.selection ) return;

		// cancel if insight card
		if (this.props.inInsightCard) return;

		// cancel if config isn't ready
		if (!this.props.etag) return;

		// cancel if cached data already exists, with matching filter and etag
		const cache = this.props.cache;
		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);
		const filterCache = (cache || {})[filter.syntax];
		if (filterCache && filterCache.error) return;

		//const productCache = (filterCache || {})[this.props.selection.product];
		if (filterCache && filterCache.etag === this.props.etag) return;

		// cancel if cached data is loading
		if (filterCache && filterCache.loading) return;

		// refresh is needed. request it.
		this.props.requestForecast();
	}, debounce.forecastDelay);

	// these are needed from parent state
	static inheritOptions = ["configEtag"];

	static scenarioDriven = true;

	// returns widget's local settings (for save state)
	getSaveState = () => {
		const config = this.props.config;
		if (!config) return null;

		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);

		// get the cache
		const cache = this.props.cache;

		const filterCache = (cache || {})[filter.syntax];
		// const productCache = this.props.inInsightCard ? this.props.productCache :
		//     this.props.selection ? (filterCache || {})[this.props.selection.product] :
		//     null;
		//const brandCache = null;

		return {
			//selection: this.props.selection, // might not need to be here, since inheritOptions includes it

			// filter: {
			//     label: filter.label,
			//     syntax: filter.syntax
			// },

			// local settings
			agg: this.state.agg,

			// note: scenario is added later ?? or is it?
			// config: {
			//     etag: config.etag,
			//     //scenarioVersion: 'todo',
			//     assumptionsId: config.assumptionsId,
			//     type: config.type,
			//     productSlots: config.productSlots,
			//     brandEquities: config.brandEquities
			// },

			// cached results
			filterCache: filterCache,
			filter,
			//brandCache: brandCache
		};
	};

	isLoading = () => {
		let fCache = (this.props.cache || {})[this.props.filter.syntax] || {};
		return fCache.loading; // || fCache.etag !== this.props.etag;
	};

	aggregate = (data, level, selection) => {
		if (!data) return null;

		const study = this.props.study;
		const config = study.config;

		// find next higher
		const aggLevels = [
			...study.selectionHierarchy,
			{ name: "product", label: study.productLabel },
		];
		const sectionName = aggLevels[0].name;

		if (level === "product") {
			return config.productSlots.map((slot) => ({
				uid: slot.uid,
				product: slot.uid,
				label: slot.label,
				section: slot.play[sectionName],
				data: data[slot.uid],
			}));
		}

		// find level att
		const att = config.productDefs.default.atts.find((a) => a.name === level);
		if (!att) return null;

		const levels = att.levels;
		if (!levels) return null;

		let rows = levels.map((lev) => {
			// find products that match the selections level
			const aggProducts = config.productSlots.filter(
				(slot) => slot.play[level] == lev.value,
			);

			let slot = aggProducts.length > 0 ? aggProducts[0] : null;

			let retVal = {
				uid: `${lev.name}:${lev.value}`,
				[lev.name]: lev.value,
				label: lev.label,
				section: slot ? slot.play[sectionName] : null,
				data: { play: {}, base: {} },
			};
			globals.metrics.forEach((metric) => {
				let play_accum = null;
				let base_accum = null;
				aggProducts.forEach((p) => {
					let play_val =
						data[p.uid] && data[p.uid].play
							? data[p.uid].play[metric.key]
							: null;
					let base_val =
						data[p.uid] && data[p.uid].base
							? data[p.uid].base[metric.key]
							: null;
					if (metric.aggMethod === "sum") {
						if (play_val) play_accum = (play_accum || 0) + play_val;
						if (base_val) base_accum = (base_accum || 0) + base_val;
					} else if (metric.aggMethod === "max") {
						if (play_val)
							play_accum = play_accum
								? Math.max(play_accum, play_val)
								: play_val;
						if (base_val)
							base_accum = base_accum
								? Math.max(base_accum, base_val)
								: base_val;
					}
				});
				retVal.data.play[metric.key] = play_accum;
				retVal.data.base[metric.key] = base_accum;
			});
			return retVal;
		});
		return rows;
	};

	render() {
		const study = this.props.study;
		if (!study) return null;

		const config = study.config;
		if (!config) return null;

		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);

		const cache = this.props.cache;
		let filterCache = (cache || {})[filter.syntax];
		//let productCache = this.props.selection ? (filterCache || {})[this.props.selection.product] : null;

		if (this.props.inInsightCard) {
			//filter = this.props.filter;
			filterCache = this.props.filterCache;
			// TODO!!!
		}

		let aggregatedCache = this.aggregate(filterCache, this.state.agg);

		// hack for backwards compatibility
		if (
			this.props.inInsightCard &&
			!this.props.filterCache &&
			this.props.productCache
		) {
			aggregatedCache = this.props.productCache;
		}

		// todo: sort
		const aggLevels = [
			...study.selectionHierarchy,
			{ name: "product", label: study.productLabel },
		];
		const selAggLevel = aggLevels.find((l) => l.name === this.state.agg) || {};

		const columns = [
			{
				key: "label",
				type: "string",
				className: "label sticky",
				label: selAggLevel.label,
			},
			...globals.metrics.map((metric) => ({
				key: metric.key,
				label: metric.label,
				className:
					"data right-align " +
					(metric.key === "consider" ? " consideration" : ""),
				format: (val) => (val === null ? "." : metric.fformat(val)),
				type: "cell",
			})),
		];

		const rowData = aggregatedCache?.map((row) => {
			let r = {
				...row,
			};
			globals.metrics.forEach((metric) => {
				let val = row.data?.play ? row.data.play[metric.key] : null;

				let cell = {
					value: val,
					n: null,
				};
				r[metric.key] = cell;
			});
			delete r.data;
			return r;
		});
		if (
			this.props.getJSONData &&
			typeof this.props.getJSONData === "function" &&
			columns &&
			rowData
		)
			this.props.getJSONData({ columns: [...columns], data: [...rowData] });

		const content = !filterCache ? null : (
			<div className="middle-content">
				<TableFred
					getJSONData={this.props.getJSONData}
					columns={columns}
					data={rowData}
				/>

				{false ? (
					<table>
						<thead>
							<tr>
								<th className="label">{selAggLevel.label}</th>
								{globals.metrics.map((metric) => (
									<th className="data" key={metric.key}>
										{metric.label}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{aggregatedCache.map((row, rowIndex) => {
								const firstInSection = false; // rowIndex > 0 && aggregatedCache.findIndex(r => r.section == row.section) == rowIndex;
								return (
									<tr
										key={row.uid}
										className={
											"row1" + (firstInSection ? " first-in-section" : "")
										}>
										<td className="label">{row.label}</td>
										{globals.metrics.map((metric) => (
											<td className="data" key={metric.key}>
												{row.data &&
												row.data.play &&
												(row.data.play[metric.key] ||
													row.data.play[metric.key] === 0)
													? metric.fformat(row.data.play[metric.key])
													: "."}
											</td>
										))}
									</tr>
								);
							})}
						</tbody>
					</table>
				) : null}
			</div>
		);

		if (
			filterCache &&
			!filterCache.loading &&
			this.props.loaded &&
			typeof this.props.loaded === "function"
		)
			this.props.loaded(!filterCache.loading);

		return (
			<div className="widget dcm-products-table">
				<div className="widget-header">
					{this.props.title ? (
						<div className="title">
							{this.props.title}
							{/* by {selAggLevel.label} */}
						</div>
					) : null}
					<div className="filter-etc">
						<FilterChooser
							mini={true}
							disabled={this.props.inInsightCard}
							selection={filter}
						/>
					</div>
					{this.props.inInsightCard ? (
						<>
							Type:{" "}
							<label className="ml-1" style={{ marginRight: "1rem" }}>
								{aggLevels.find((i) => this.state.agg === i.name).label}
							</label>
						</>
					) : (
						<div className="agg-level">
							{aggLevels.map((aggLevel) => (
								<span
									key={aggLevel.name}
									onClick={() => this.setState({ agg: aggLevel.name })}
									className={
										"btn" + (this.state.agg === aggLevel.name ? " sel" : "")
									}>
									{aggLevel.label}
								</span>
							))}
						</div>
					)}
				</div>
				<div className="widget-body vscroll hscroll">{content}</div>
				<CardLoading loading={filterCache && filterCache.loading} />
			</div>
		);
	}
}

DCMProductsTable = connect(
	(state, ownProps) => ({
		studyId: (state.study || {}).uid,
		study: state.study,
		config: (state.study || {}).config,
		...(ownProps.inDataCard
			? {
					etag: ((state.study || {}).config || {}).etag,
					filter: (state.filter || {}).selectedFilter,
					cache: (state.cache || {}).forecast,
			  }
			: {}),
	}),
	(dispatch) => bindActionCreators(forecastActionCreators, dispatch),
)(DCMProductsTable);
