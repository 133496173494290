import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators as configActionCreators } from '../../store/Config.ts';
import { Tab, TabStrip } from '../common/TabStrip';
import { ProductBlock } from '../product-drawer/ProductBlock';
import { EditConstraints } from './EditConstraints';
import { EditSliderConstraints } from './EditSliderConstraints';
import './OptimizeEditorMulti.scss';

export class OptimizeEditorMulti extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            selectedTabValue: 'product'
        };
    }

    addClicked = () => {
        const selections = [];
        window.topBar.openProducts(selections,
            (sel) => {
                this.props.addOptimizationSelection(sel, (newSelections) => {
                    window.topBar.hideMenu();
                    //window.productDrawer.setState( { sels: newSelections });
                });
            },
            {
                allow: ['product'],
                multiSelect: false
            }
        )
    }

    deleteClicked = (sel) => {
        this.props.removeOptimizationSelection(sel);
    }

    editClicked = () => {
        // called when productblock of an already exist product is clicked
        // todo:
    }


    render() {

        const studyId = this.props.match.params.studyId;

        const config = this.props.config;
        if( !config ) return null;

        const optim = config.optim || {};

        const selections = optim.selections || [];
        if( !selections ) return null;

        const add_sel = {
            uid: '!add!'
        };

        const list = [...selections, add_sel]
        console.log('list', list);

        const tab = this.state.selectedTabValue;

        return (
            <div className="optimize-editor-multi" style={this.props.style}>

                <div className="tab-section">

                    <h3>Select target {this.props.productLabel}s and constraints:</h3>

                    <TabStrip onSelectedIndexChanged={(selectedTabIndex, selectedTabValue) => this.setState({ selectedTabValue })}>
                        <Tab value='product'>Product</Tab>
                        <Tab value='price'>Price</Tab>
                        <Tab value='message'>Message</Tab>
                        <Tab value='brand'>Brand</Tab>
                    </TabStrip>

                </div>

                <div className='product-grid-container'>
                    <div className='product-grid1'>
                        {list.map(sel => 
                            <div className='product-cell1' key={sel.uid}>
                                {(sel === add_sel) ? <ProductBlock
                                            allow={['product']}
                                            onClick={this.addClicked}
                                            className='sticky-top'
                                            label={`Click to add target ${this.props.productLabel}`}
                                        />
                                    :
                                    <>
                                        <ProductBlock {...sel}
                                            allow={['product']}
                                            allowDelete={true}
                                            onClick={this.editClicked}
                                            onDelete={() => { 
                                                this.deleteClicked(sel);
                                            }}
                                            className='sticky-top'
                                        />

                                        {tab === 'product' ? <EditConstraints sel={sel}/> : null}
                                        {tab === 'price' ? <EditConstraints sel={sel} area="price"/> : null}
                                        {tab === 'message' ? <EditSliderConstraints sel={sel} area="message"/> : null}
                                        {tab === 'brand' ? <EditSliderConstraints sel={sel} area="brand"/> : null}
                                    </>
                                }
                            </div>
                        )}
                    </div>
                </div>

                

            </div>
        );
    }
}

OptimizeEditorMulti = withRouter(
    connect(
        state => state.study,
        dispatch =>
            bindActionCreators(configActionCreators, dispatch)
    )(OptimizeEditorMulti)
);
