import * as React from 'react';
import { useSelector } from 'react-redux';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { dateFormat } from '../../utils/dateFormat';
import * as selectionHelper from '../../utils/selectionHelper';
import { CoreWidget1 } from './CoreWidget1';
import CustomLegend from "../common/CustomLegend";
import CustomizedTick from "../common/CustomizedTick";

export const LineChartWidget2 = (props) => {
    
    const study = useSelector(state => state.study);
    const selections = useSelector(state => state.selections);
    const selection = selectionHelper.getSelection(props, study, selections);

    const wordPath = [selection?.label];

    const series = props.series || [];

    let yAxisProps = {
        width: 40,
        ...props.yAxis
    };

    const renderContent = (cache, getJSONData) => {

        let data = cache?.timeseries || [];
        data.map(series => Object.assign(series, {sentiment:  Number.isNaN(parseInt(series.sentiment)) ? '': series.sentiment} ))

        if (getJSONData && typeof getJSONData === 'function')
            getJSONData(data)
        return <div className='content1'>
            <ResponsiveContainer width={'100%'} height={'100%'} >
                <LineChart data={data} margin={{ right: 10, bottom: 20 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={'date'} tickFormatter={(val) => (val === 'auto' ? null : dateFormat(new Date(val), "mmm dd"))} /> 
                    <YAxis {...yAxisProps} tick={<CustomizedTick />} />
                    <Tooltip formatter={ value => Number.isNaN(parseInt(value)) ? '.' : value === 0 ? value : value.toFixed(3)} 
                    labelFormatter={value => dateFormat(new Date(value), "mmm dd") } />
                    {
                        series.map((ser, idx) => 
                            <Line 
                                key={idx} 
                                name={ser.label} 
                                dataKey={ser.key} 
                                type="monotone" 
                                {...ser}
                                animationDuration={400}
                            />
                        )
                    }
                    <Legend iconType="line" content={<CustomLegend />} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    }

    const useBigData = props.useBigData ? {
        path: selection ? props.useBigData.path.replace("{{wordpath}}", encodeURI(wordPath.join('|'))) : null
    } : {};
    
    return <CoreWidget1 {...props}
        showSelection={true}
        options={() => <div>Trendline with 3-day smoothing parameter</div>}
        render={renderContent}    
        useBigData={useBigData}
        // {
        //     path: selection ? `GET:graph-timeseries?rolling_window=3&search_terms=${encodeURI(wordPath.join('|'))}` : null
        // }
        />

}

