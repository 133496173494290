import * as React from "react";
import { connect } from "react-redux";
import {
	Bar,
	BarChart,
	LabelList,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as forecastActionCreators } from "../../store/Forecast.ts";
import * as debounce from "../../utils/debounce";
import * as selectionHelper from "../../utils/selectionHelper";
import { CardCover } from "../card/CardCover";
import { CardLoading } from "../card/CardLoading";
import { FilterChooser } from "../filter/FilterChooser";
import { ProductBlockMini } from "../product-drawer/ProductBlockMini";
import CustomizedTick from "../common/CustomizedTick";
import "./AllElseEqualsWidget.scss";

const metrics = globals.metrics;

export class AllElseEqualsWidget2 extends React.Component {
	constructor(props) {
		super(props);

		// local widget settings
		this.state = {
			metric: this.props.metric || "share",
		};

		// Allow card to be saved as insight card. Not the greatest pattern.
		this.props.stateContext.getSaveState = this.getSaveState;
	}

	componentDidMount() {
		//this.refreshIfNeeded();
	}

	componentDidUpdate() {
		//this.refreshIfNeeded();
	}

	refresh = () => {
		this.refreshIfNeeded();
	};

	refreshIfNeeded = debounce.debounce(() => {
		// cancel if insight card
		if (this.props.inInsightCard) return;

		// cancel if no selection
		const selectedProductId = this.props.selectedProductId;
		if (!selectedProductId) return;

		// cancel if config isn't ready
		if (!this.props.etag) return;

		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);

		// cancel if cached data already exists, with matching filter and etag
		let cache = this.props.cache;
		if (!cache) {
			const fullCache = this.props.fullCache;
			const key = `${filter.syntax}:LH:${selectedProductId}:*`;
			cache = (fullCache || {})[key];
		}

		if (cache && cache.etag === this.props.etag) return;

		// cancel if cached data is loading
		if (cache && cache.loading) return;

		// refresh is needed. request it.
		this.props.requestLevelHints(filter.syntax, selectedProductId, "*");
	}, debounce.forecastDelay);

	// these are needed from parent state (OBSOLETE?)
	static inheritOptions = ["configEtag"];

	static scenarioDriven = true;

	// returns widget's local settings (for save state)
	getSaveState = () => {
		const selectedProductId = this.props.selectedProductId;
		if (!selectedProductId) return null;

		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);

		// get the cache
		const fullCache = this.props.fullCache;
		const key = `${filter.syntax}:LH:${selectedProductId}:*`;
		const cache = fullCache?.[key];

		return {
			selectedProductId,
			filter: {
				label: filter.label,
				syntax: filter.syntax,
			},
			metric: this.props.metric || this.state.metric,
			cache: cache,
		};
	};

	render() {
		const selectedProductId = this.props.selectedProductId;
		//if( !selectedProductId ) return <CardCover message="no selection" />;

		const selectedProduct = selectedProductId
			? selectionHelper.getSelectionInfo(
					"product",
					selectedProductId,
					this.props.study,
					this.props.selections?.hierarchy,
			  )
			: null;

		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);

		let cache = this.props.inInsightCard ? this.props.cache : null;
		if (!cache && selectedProductId) {
			const fullCache = this.props.fullCache;
			const key = `${filter.syntax}:LH:${selectedProductId}:*`;
			cache = fullCache?.[key];
		}
		let cacheValue = cache?.value;

		const selMetric = metrics.find((m) => m.key === this.state.metric);

		const expired = this.props.inDataCard && this.props.etag !== cache?.etag;
		const loading = cache && cache.loading;
		if (this.props.loaded && typeof this.props.loaded === "function")
			this.props.loaded(!loading);

		const format = selMetric.fformat;

		const study = this.props.study;
		if (!study) return;

		const config = study.config;
		if (!config) return;

		const pdef = config.productDefs.default;
		const atts = pdef.atts.filter(
			(att) => att.type === "discrete" && !att.readOnly,
		);
		//.filter((att, idx) => idx === 1);

		// find domain of data values
		let minValue = 0;
		let maxValue = 0;
		if (cacheValue) {
			atts.forEach((att) => {
				att.levels.forEach((level) => {
					const res1 = cacheValue.result?.[att.name + ":" + level.value];
					const value = res1 ? res1[selMetric.key] : null;
					minValue = Math.min(minValue, value);
					maxValue = Math.max(maxValue, value);
				});
			});
		}
		if (this.props.getJSONData && typeof this.props.getJSONData === "function")
			this.props.getJSONData(null, true);

		const content = !cacheValue ? null : (
			<div>
				{atts.map((att) => {
					const data = att.levels.map((level) => ({
						label: level.label,
						value:
							cacheValue.result?.[att.name + ":" + level.value]?.[
								selMetric.key
							],
						//...cacheValue.result[att.name + ':' + level.value]
					}));

					return (
						<div className="att-chart" key={att.name}>
							<ResponsiveContainer
								width="100%"
								maxWidth="500"
								height={50 + att.levels.length * 30}>
								<BarChart data={data} layout="vertical" margin={{ right: 20 }}>
									<XAxis
										dataKey="value"
										type="number"
										tickFormatter={format}
										//domain={['auto', maxValue * 1.2]}
										domain={[minValue, maxValue * 1.2]}
									/>
									<YAxis
										dataKey="label"
										type="category"
										width={170}
										tick={<CustomizedTick />}
									/>
									<Tooltip formatter={format} />
									<Bar dataKey="value" fill={globals.colors.primary1}>
										<LabelList
											dataKey="value"
											position="right"
											formatter={format}
										/>
									</Bar>
								</BarChart>
							</ResponsiveContainer>
							<svg width="100%" height="20px">
								<text x="50%" y="10">
									{att.label}
								</text>
							</svg>
						</div>
					);
				})}
			</div>
		);

		return (
			<div className="widget all-else-equals-widget">
				<div className="widget-header">
					{this.props.title ? (
						<div className="title">{this.props.title}</div>
					) : null}
					<div className="selection">
						<ProductBlockMini
							{...selectedProduct}
							disabled={this.props.inInsightCard}
							allowChange={["product"]}
						/>
					</div>
					<div className="filter-etc">
						<FilterChooser
							mini={true}
							disabled={this.props.inInsightCard}
							selection={filter}
						/>
					</div>
					{this.props.inDataCard && (
						<select
							value={this.state.metric}
							onChange={(ev) => this.setState({ metric: ev.target.value })}>
							{metrics.map((m) => (
								<option key={m.key} value={m.key}>
									{m.label}
								</option>
							))}
						</select>
					)}
					{this.props.inInsightCard && (
						<label className="ml-1">
							{metrics.filter((i) => i.key === this.state.metric)[0].label}
						</label>
					)}
				</div>
				<div className="widget-body vscroll">
					{content}
					{!selectedProductId ? (
						<CardCover message="no selection" />
					) : loading ? null : expired ? (
						<CardCover button="update" buttonClicked={() => this.refresh()} />
					) : null}
				</div>
				<CardLoading loading={loading} />
			</div>
		);
	}
}

AllElseEqualsWidget2 = connect(
	(state, ownProps) => ({
		etag: state.study?.config?.etag,
		study: state.study,
		selections: state.selections,
		...(ownProps.inDataCard
			? {
					filter: state.filter?.selectedFilter,
					fullCache: state.cache?.forecast,
					selectedProductId: state.selections?.product?.uid,
			  }
			: {}),
	}),
	(dispatch) => bindActionCreators(forecastActionCreators, dispatch),
)(AllElseEqualsWidget2);
