import { format } from "d3-format";
import { PublicClientApplication } from "@azure/msal-browser";

export const debugCards = false;
export const chatapiKey = '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' 

export const cdnUrl = "https://blueowl-storage.azureedge.net";
export const avatarCdnUrl = "https://blueowl-avatars.azureedge.net";

export const appRoot = "https://api.blueowlai.com";
//export const appRoot = "https://blueowl-api-test.azurewebsites.net";
// export const appRoot = "https://localhost:63919";

export const apiRoot = `${appRoot}/api`;

//msal = Microsoft Authentication Library
const msalUrl = 'https://app.blueowlai.com/'
// const msalUrl = 'http://localhost:9000'

 const msalConfig = {
	auth: {
		clientId: 'd5b6b736-f1b8-4098-b09e-adfa953b6ad3',
		authority: 'https://login.microsoftonline.com//8ee961a0-02d9-4103-9025-fb669faae140', //single tenant- use id, use 'common' for multi-tenant
		redirectUrl: msalUrl, 
		postLogoutRedirectUri: msalUrl
	}, 
	cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: true
	 },
	  system: {
        navigateToLoginRequestUrl: false
    }
}

export const msalInstance = new PublicClientApplication(msalConfig)


export const colors = {
	primary1: "#3969D9",
	primary1b: "#4287fa",
};

export const npsColors = {
	promoter: "#3fac39",
	promoterFg: "#ffffff",
	neutral: "#e8ea1b",
	neutralFg: "#000000",
	detractor: "#a61b1b",
	detractorFg: "#ffffff",
	nps: "#202020",
	npsFg: "#efefef",
	gap: "#808080",
	gapFg: "#efefef",
};

export const switchableColors = {
	lost: "#818181",
	lostFg: "#ffffff",
	atRisk: "#a71b1e",
	atRiskFg: "#ffffff",
	prospect: "#3fac39",
	prospectFg: "#000000",
	loyal: "#008000",
	loyalFg: "#ffffff",
};


export const getDefaultFilter = (state) => {
	return {
		customLabel: null,
		autoLabel: null,
		useCustomLabel: false,
		syntax: "1==1",
		name: "total",
		label: state?.defaultFilterLabel || "Total Population",
	};
};

export const defaultCompare = {
	label: "None",
	groups: [],
};

export const metrics = [
	{
		key: "consider",
		label: "Consideration",
		aggMethod: "max",
		format: ".1%",
		fformat: (val) => format(".1%")(val),
		toolTipformat: (val) => format("%")(val),
		dformat: (val) => format(".3%")(val),
		export_format: ".5%",
		export_fformat: (val) => format(".5%")(val),
		export_dformat: (val) => format(".3%")(val),
		color: "black",
		fixedWidth: true,
	},
	{
		key: "share",
		label: "Share",
		aggMethod: "sum",
		format: ".1%",
		fformat: (val) => format(".1%")(val),
		toolTipformat: (val) => format("%")(val),
		dformat: (val) => format(".3%")(val),
		export_format: ".5%",
		export_fformat: (val) => format(".5%")(val),
		export_dformat: (val) => format(".3%")(val),
		color: "orangered",
		fixedWidth: true,
	},
	{
		key: "volume",
		label: "Volume",
		aggMethod: "sum",
		format: ".1f",
		fformat: (val) => hFormat(val, ",.1f"),
		toolTipformat: (val) => format(",")(val),
		dformat: (val) => hFormat(val, ",.3f"),
		export_format: ".1f",
		export_fformat: (val) => format(",.2d")(val),
		export_dformat: (val) => hFormat(val, ",.3f"),
		color: "purple",
	},
	{
		key: "revenue",
		label: "Revenue",
		aggMethod: "sum",
		format: "$,.1f",
		fformat: (val) => hFormat(val, "$,.1f"),
		toolTipformat: (val) => format("$,")(val),
		dformat: (val) => hFormat(val, "$,.3f"),
		export_format: "$,.2d",
		export_fformat: (val) => format("$,.2d")(val),
		export_dformat: (val) => hFormat(val, "$,.3f"),
		color: "blue",
	},
	{
		key: "contrib",
		label: "Contribution",
		aggMethod: "sum",
		format: "$,.1f",
		fformat: (val) => hFormat(val, "$,.1f"),
		toolTipformat: (val) => format("$,")(val),
		dformat: (val) => hFormat(val, "$,.3f"),
		export_format: "$,.2d",
		export_fformat: (val) => format("$,.2d")(val),
		export_dformat: (val) => hFormat(val, "$,.3f"),
		color: "grey",
	},
	{
		key: "profit",
		label: "Profit",
		aggMethod: "sum",
		format: "$,.1f",
		fformat: (val) => hFormat(val, "$,.1f"),
		toolTipformat: (val) => format("$,")(val),
		dformat: (val) => hFormat(val, "$,.3f"),
		export_format: "$,.2d",
		export_fformat: (val) => format("$,.2d")(val),
		export_dformat: (val) => hFormat(val, "$,.3f"),
		color: "green",
	},
]
	//.filter(entry => entry.key === "share" || entry.key === "volume");

export const changeCategories = {
	brand: true,
	message: true,
	price: true,
	product: true,
	// people: true
};

export const default_optimizer_metric = "share";

export const get_sunburst_metric = () => {
	let m = metrics.find(m => m.key === 'revenue');
	if (m) return m;
	return metrics.find(m => m.key === 'share');
}

export const configs = [
	{ key: "base", label: "Base case", color: "#030303" },
	{ key: "play", label: "What-if", color: "#3969D9" },
];

export const configs2 = {
	base: { label: "Base case", color: "#030303" },
	play: { label: "What-if", color: "#3969D9" },
};

export const levelHints = {
	color: "#404040",
};

// convert to a more human readable format, e.g., 1.6M, 75.2K
export const hFormat = (val, fmt) => {
	if (val > 1000000000) return format(fmt)(val / 1000000000) + "B";
	else if (val > 1000000) return format(fmt)(val / 1000000) + "M";
	else if (val > 1000) return format(fmt)(val / 1000) + "K";
	else if (val < -1000000000) return format(fmt)(val / 1000000000) + "B";
	else if (val < -1000000) return format(fmt)(val / 1000000) + "M";
	else if (val < -1000) return format(fmt)(val / 1000) + "K";
	else return format(fmt)(val);
};

export const bigDataFilterLabel = "Total Population";
