import * as React from 'react'
import { useState, useEffect, useRef, useContext } from "react"
import { useSelector, useDispatch} from "react-redux"
import { Link, useParams } from 'react-router-dom'
import * as globals from '../../globals'
import * as apiService from '../../utils/apiService'
import * as debounce from '../../utils/debounce'
import { Markdown } from '../common/Markdown'
import { ReactSortable } from '../common/Sortable'
import { ScenarioEditorMultiConfigs } from '../config/ScenarioEditorMultiConfigs'
import { ContentLoader } from './ContentLoader'
import './ContentViewer.scss'
import { EditModeWrapper } from './EditModeWrapper'
import { getStack } from './ExploreUtils'



export function wrapAsPage(item, body, customStyle, info, config, study_uid, params, edit, restore, reload, footer, layoutReady){
    const [hide, setHide] = useState(false)
    const {editMode, setEditMode} = edit || {}
    const {restoreMode, setRestoreMode} = edit || {}
    const [reloadCounter, setReloadCounter] = useState(0)

    const divRef = useRef()
    const dispatch = useDispatch()

    const cover = <div className={'explore-page-cover' + (layoutReady ? ' hidden' : '')} >
        <img style={{width:450}} src='/images/loader.gif' alt="Blue Owl logo" />
        {/* <img src='/images/bo55px.png' className='coin'/> */}
    </div>

     
 
    const body1 = 
        <div className={"explore-page-body" + (layoutReady ? "" : " not-ready")} >
            {body}
            {footer ? 
                <ContentLoader id={footer} key="footer" allowFooter={true} />
            : null}
        </div>
    
    //don't delete
    //{getPageHeader(item, study_uid, params, edit, restore)}

    
    return item?.data?.disable === 1 ? <div className='disabled-page'>{item?.data?.tooltip ? item?.data?.tooltip : 'Page Disabled'}</div> :
        
        <div className="page explore-page" key={reloadCounter}>
            <div className='explore_editmode'>
                {edit ? (
					<>
						<span className={"explore-edit-toggle " + (edit.editMode ? "on" : "off")} onClick={() => edit.setEditMode(!edit.editMode)} >
                            <i className="fal fa-pencil" />
                            <div>edit</div>
						</span>
					</>
				) : null}
				{edit?.editMode ? (
                    <span
                        className={"explore-edit-toggle restore-items " + (restore.restoreMode ? "on" : "off")}
						title="restore elements"
                        onClick={() => restore.setRestoreMode(!restore.restoreMode)}
                    >
                        <i className={"fal fa-trash-restore"} />
                        <div>restore</div>
					</span>
				) : null}
            </div>
            
            {/*SCROLLING BAR happens within the '.content' div - body1(above) */}
            <div id='page_body' className={'content ' + (info ? 'explore-info' : '') + (config ? 'explore-config' : '')} style={customStyle} ref={divRef} >
                {config ? 
                    <div className={'prod-config'}>{config}</div>
                : null}
                {body1}
                {cover}
                {info ?
                    <>
                        <div className="card-info-toggle">
                            <button className="card-toggle-btn toggle-btn" type="button" role='button' aria-label='card info' onClick={() => setHide(!hide)}>
                                <i className={"far " + (!hide ? "fa-chevron-right" : "fa-chevron-left")}>&nbsp</i>
                            </button>
                        </div>
                        <div className={'info ' + (hide ? 'hide-info' : ' ')}>{info}</div>
                    </>
                : null}
            </div>
        </div>
}





const updateContentItem = debounce.debounce((auth, study_uid, id, body) => {
    const response = apiService.aPatch(auth, `${globals.apiRoot}/study/${study_uid}/content/${id}`, body)
        .then(() => {
            console.log('updated')
        })
        .catch((err) => {
            console.error(response)
        })
}, 1000)




export const PageContentViewer = (props) => {

    const item = props.item
    let children = item?.contents

    let _questions = (children?.find(m => m.type === 'questions') || []).contents
    const [questions, setQuestions] = useState(_questions || [])
    
    const customStyle = item?.data?.style

    const params = useParams()
    const stack = getStack(params)
    const auth = useSelector(state => state.auth)
    const study_uid = useSelector(state => state.study?.uid)
    const rootLink = `/study/${study_uid}/explore` + (stack.length > 0 ? '/' + stack.join('/') : '')

    const {editMode, setEditMode} = props.edit || {} // useState(false)
    const [title, setTitle] = useState('')//item.title)
    const [description, setDescription] = useState(item.description)
    //const [dataJSON, setDataJSON] = useState(JSON.stringify(item.data || {}))

    
    const [layoutsReady, setLayoutsReady] = useState({})

    const allLayoutsReady = () => !item.contents || item.contents.filter(c => layoutsReady[c.id]).length == item.contents.length || study_uid !== 'video-games-demo'

    const setChildLayoutReady = (id, val) => {
        //console.log(`page ${item?.id} setChildLayoutReady ${id}:${val}`)
        setLayoutsReady({
            ...layoutsReady,
            [id]: val
        })
    }

    
    useEffect(() => {
        //console.log('pageContentViewer layoutsReady', layoutsReady, item.contents)
        props.setLayoutReady?.(allLayoutsReady()) 
    }, [item, layoutsReady])
    
    const reloadPage = props.reloadItem

    //const history = useHistory()
    const showInfo = item.showInfo || item.description
    const showConfig = item.data?.showConfig

    const titleChanged = (ev) => {
        setTitle(ev.target.value)
        updateContentItem(auth, study_uid, item.id, { title: ev.target.value })
    }

    const descriptionChanged = (ev) => {
        setDescription(ev.target.value)
        updateContentItem(auth, study_uid, item.id, { description: ev.target.value })
    }

    // const dataJSONChanged = (ev) => {
    //     try{
    //         setDataJSON(ev.target.value)
    //         const obj = JSON.parse(ev.target.value)
    //         if( obj ){
    //             updateContentItem(auth, study_uid, item.id, { data: obj })
    //         }
    //     }
    //     catch(err){
    //         //console.error(err)
    //     }
        
    // }
    
    const addQuestion = (ev) => {
        apiService.aPost(auth, `${globals.apiRoot}/study/${study_uid}/content/${item.id}/question`)
            .then(() => window.location.reload())
            .catch((err) => console.error(err))
    }

    // const deleteThisPage = (ev) => {
    //     apiService.aDelete(auth, `${globals.apiRoot}/study/${study_uid}/content/${item.id}`)
    //         .then(() => history.push(getParentLink(params, study_uid)))
    //         .catch((err) => console.error(err))
    // }

    

    
   
    const nocontent = <div>
        <span style={{ padding: 15, color: '#afafaf' }}>[no content]</span>
    </div>

    const renderQuestion = (item) => {

        let target = `${rootLink}/${item.id}`
        if( !editMode && item.data?.redirect ){
            target = item.data?.redirect
            target = target.replace("~study/", `/study/${study_uid}/`)
        }

        return <Link key={item.id} className='question' to={target}>
            {/* <span className='glyph'>
                <i className="far fa-question-circle"/>
            </span> */}
            <span className='label'>
                {item.title}
            </span>
        </Link>
    }

    const orderChildren = (parent_id, order) => {
        apiService.aPatch(auth, `${globals.apiRoot}/study/${study_uid}/content/${parent_id}/order-children`, { order })
            .then(() => console.log('ordered'))
            .catch((err) => console.error(err))
    }

    const body = <EditModeWrapper className='has-children' item={item} edit={props.edit} reloadItem={reloadPage} restoredItem={props?.restoredItem}
        pageLevel={props.pageLevel}
    >
        {children ? children.map(child => 
            <ContentLoader id={child.id} key={child.id} edit={props.edit} restoredItem={child?.deleted} restore={props.restore} reloadParentItem={reloadPage} pageLevel={props.pageLevel}
                setLayoutReady={(val) => setChildLayoutReady(child.id, val)}
                parentItem={item} />
            ) : nocontent}
        </EditModeWrapper>

    //const info = null
    const info = showInfo ? 
        <>
            <div className='top'>
                <div className='title' >
                    <h2>{editMode ? <input type="text" className='title-edit' value={title} onChange={titleChanged}/> : title}
                        {/* <span className='btn-toggle' onClick={() => setEditMode(!editMode)}>
                            {auth?.user?.isSuperAdmin ? (editMode ? <i className='fal fa-times'/> : <i className='fal fa-pencil'/>) : null}
                        </span> */}
                    </h2>
                </div>
                {item.description ?
                    <div className='section description'>
                        {editMode ? 
                            <textarea value={description} onChange={descriptionChanged} className='description-edit' />
                            :
                            <Markdown data={description} allowComponents={true} />
                        }
                    </div>
                    : null
                }
                
                {questions ?
                    <div className='section questions'>
                        {questions.length > 0 ?
                            <div>
                            <h3>Top Questions:</h3>
                        </div> : null}
                        {
                            editMode
                                ? <ReactSortable
                                    list={questions || []}
                                    reorder
                                    setList={items => {
                                        setQuestions(items)

                                        // get parentid (assuming all elements have same parent)
                                        if( items.length < 1 ) return
                                        const parent_id = items[0].parent_id

                                        // call the api to update the ordering in db.
                                        orderChildren(parent_id, items.map(i => i.id))
                                    }}
                                >
                                    {questions.map(question => renderQuestion(question))}
                                </ReactSortable> : questions.map(question => renderQuestion(question))
                        }
                    </div>
                    : null
                }
                {editMode ? <div>
                    <span className='btn-superadmin-action' onClick={addQuestion}>add question</span>
                </div> : null}
            </div>
                
        </>
        : null
    
    const config = showConfig ?
            <>
                <div className='top'>
                    <div className='title' >
                        <h2 style={{marginBottom:15, color:'#0064c8', fontWeight:500}}>{editMode ? <input type="text" className='title-edit' value={title} onChange={titleChanged}/> : title}
                        </h2>
                    </div>
                </div>
                <div className='main'>
                    <ScenarioEditorMultiConfigs key={item.id} />
                </div>
                
            </>: null
                    
                
    
    return wrapAsPage(item, body, customStyle, info, config, study_uid, params, props.edit, props?.restore, props.reloadItem, props.footer, props.layoutReady)
      

}






