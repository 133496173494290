import * as React from 'react';
// import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { CardFlipper } from './CardFlipper';
import './CardSwipe.scss';


export class CardSwipe extends CardFlipper {

    getPosition(event) {
        const { pageX} = event.touches[0] || event.changedTouches[0] || {};
          return { x: pageX};
    }

    initTouch = (event) => {
        this.startX = this.getPosition(event).x;
    }

    checkSwipe = (event) => {
        this.endX = this.getPosition(event).x;

        if(this.endX - this.startX - 50 > 0){
            //swipe right, show previous card
            this.handleLeftClick();
        }

        else if(this.startX - this.endX - 50 > 0){
            //swipe Left, show next card
            this.handleRightClick();
        }

    }

    render() {
        return ( 
             <TransitionGroup className="card-content">
                 <CSSTransition  
                    timeout={500}
                    classNames="swipe"
                 >
                    <div key={this.props.selectedIndex} className='fliper-card' onTouchStart={(e) =>this.initTouch(e)} onTouchEnd={(e) => this.checkSwipe(e)}>
                        {this.props.card}
                    </div>
                </CSSTransition>
            </TransitionGroup>
    )
    }


}

