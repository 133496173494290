import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DataCard } from '../card/DataCard';
import './DataCardContainer.scss';

export class DataCardContainer extends React.Component {

    constructor(props){
        super(props);
    }

    render() {

        let study = this.props.study;
        if( !study ) return null;

        let scrollerStyle = {
            //gridTemplateColumns: "repeat(auto-fill,minmax(950px,1fr))"
        }

        const widgetRepo = this.props.repo;
        let pageState = this.props.state;

        return <div className="data-card-container">

            <div className='scroller' style={scrollerStyle}>


                {widgetRepo.filter((f,i) => i < 3).map((entry,idx) => {
                    const WidgetComponent = entry.widget;

                    // console.log("widgetcomponent", WidgetComponent)

                    // data card gets portion of state from page (inherited state) as props
                    // data card gets props from repo entry if any
                    // data card contains widget
                    // data cards state is injected into widget via props
                    // widget may modify its parent data card state via stateContext
                    //      this would cause widget to receive new props and redraw
                    //      it's the best method i could think of right now with short notice
                    
                    // grab relevant state items from the page
                    // this is for the inherited state items that come from the page, that probably won't be adjusted in card
                    //let pageState = this.props.state;
                    // let inheritOptions = WidgetComponent.inheritOptions || [];
                    // inheritOptions.forEach(stateKey => pageState[stateKey] = this.state[stateKey]);

                    return <DataCard
                        key={entry.uid}
                        studyId={study.uid}
                        widgetId={entry.uid}
                        repoEntry={entry}
                        expandRoot={this.props.expandRoot}
                        {...pageState}
                        className="old_data"
                        />

                })}
                

            </div>

        </div>;


    }

}

DataCardContainer = withRouter(
    connect(
        state => state,
        null
    )(DataCardContainer)
);



