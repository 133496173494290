import * as React from 'react';
import { format as d3Format } from 'd3-format';
import { Bar, BarChart, LabelList, CartesianGrid, Text, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CustomizedTick from "../common/CustomizedTick";
import CustomLegend from "../common/CustomLegend";

const DColumnChart = ({ compute, renderConfig }) => {

  let xAxisProps = renderConfig?.xAxis?.props || {};
  let yAxisProps = renderConfig?.yAxis?.props || {};

  if (renderConfig?.xAxis?.tickFormatter) {
    xAxisProps = { ...xAxisProps, tickFormatter: d3Format('.0%') }
  }

  if (renderConfig?.yAxis?.tickFormatter) {
    yAxisProps = { ...yAxisProps, tickFormatter: d3Format(renderConfig?.yAxis?.tickFormatter || "") }
  }

  const tooltipProps = renderConfig?.tooltip?.props || {};
  const tooltipFormatter = d3Format(renderConfig?.tooltip?.formatter || "");

  return <ResponsiveContainer {...renderConfig?.container}>
    <BarChart data={compute} layout="horizontal" {...renderConfig?.chartConfig}>
      {renderConfig?.cartesian && (
        <CartesianGrid strokeDasharray="3 3" {...renderConfig?.cartesian?.props}/>
      )}
      <XAxis type={!renderConfig?.xAxis?.tickFormatter ? "category" : "number"} {...xAxisProps} />
      <YAxis type={!renderConfig?.yAxis?.tickFormatter ? "category" : "number"} tick={<CustomizedTick tickFormatter={d3Format(renderConfig?.yAxis?.tickFormatter || "")} />} {...yAxisProps}/>
      <Tooltip formatter={tooltipFormatter} {...tooltipProps} />
      {renderConfig?.legend && (
        <Legend {...renderConfig?.legend} content={<CustomLegend />} />
      )}
      {renderConfig?.series?.map((config, idx) => (
        <Bar isAnimationActive={false} dataKey={config.dataKey} key={idx} {...config?.props}></Bar>
      ))}
    </BarChart>
  </ResponsiveContainer>
}

export default DColumnChart