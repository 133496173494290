import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as globals from "../../globals";
import * as apiService from "../../utils/apiService";
import * as debounce from "../../utils/debounce";
import "./SearchBox.scss";

class SearchBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: "",
      suggestions: [],
      content: [],
      loadResult: false,
    };
  }

  textChanged = (ev) => {
    this.setState({ loadResult: false });
    this.setState({ searchTerm: ev.target.value });
    this.triggerFetch();
  };

  triggerFetch = debounce.debounce(() => {
    this.fetchSearchResults();
  }, debounce.sliderDelay);

  fetchSearchResults = async () => {
    const searchText = this.state.searchTerm?.trim();
    if (!searchText) {
      this.setState({
        content: [],
        suggestions: [],
      });
      return;
    }

    const studyId = this.props.study?.uid;
    const url = `${globals.apiRoot}/study/${studyId}/dcard-search?search=${encodeURIComponent(searchText)}`;
    const response = await apiService.aGet(this.props.auth, url);
    if (response.ok) {
      const res = await response.json();

      this.setState({
        content: res?.content || [],
        suggestions: res?.suggestions|| [],
      });
      this.setState({ loadResult: true });
    } else {
      this.setState({
        content: [],
        suggestions: [],
      });
    }
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = (e) => {
    if (!this.searchNode.contains(e.target)) {
      this.hideSearchBox();
    }
  };

  hideSearchBox = () => {
    this.setState({ searchTerm: "" });
  };

  render() {
    let suggestionWithoutDuplicate = [
      ...new Set(this.state.suggestions.map((i) => i["@search.text"])),
    ]; //remove duplicate entries
    const suggestions = suggestionWithoutDuplicate.filter(
      (i) => i.trim().toLowerCase() !== this.state.searchTerm?.trim()
    );
    const results = this.state.content;
    const studyId = this.props.study?.uid;

    const sections = [
      // { label: "Questions", types: ["question"] },
      // {
      //   label: "Stories & Insights",
      //   types: ["story", "story-comment", "insight-card"],
      // },
      { label: "Data Cards", types: ["dcard"] },
      { label: "Pages", types: ["page", "tile", "section"] },
      // { label: "Deep Dive", types: ["page", "tile", "section"] },
    ];

    const disable = true; //currently not working

    return (
      <div
        className={
          "search-box " +
          (this.props.shift ? "search-shift" : "") +
          (this.state.searchTerm !== "" ? " search-active" : " ")
        }
        ref={(searchNode) => (this.searchNode = searchNode)}
      >
        <input
          // disabled={disable}
          className={"input-search " + (disable ? "disabled" : "")}
          placeholder={"Search " + (disable ? "" : "")}
          value={this.state.searchTerm}
          onChange={(ev) => this.textChanged(ev)}
          autoComplete="off"
        />
        <button className="btn1 no-hover search" role='button' aria-label='perform search'>
          {this.state.searchTerm === "" ? (
            <i className="fal fa-search" />
          ) : (
            <i className="fal fa-times" onClick={this.hideSearchBox} />
          )}
        </button>
        {this.state.searchTerm ? (
          <>
            <div className="search-result-box">
              {suggestions.length > 0 ? (
                <div className="section suggestions">
                  <div className="section-title">Suggestions</div>

                  <ul
                    className="list-item"
                    onClick={(e) => {
                      this.setState({ searchTerm: e.target.innerText });
                      this.triggerFetch();
                    }}
                  >
                    {suggestions.map((item, idx) => (
                      <li key={idx}>
                        <span className="fal fa-search">&nbsp;</span> {item}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}

              {
                sections.map((section) => {
                  const sectionResults = results.filter((result) => section.types.includes(result.type)).filter((r, i) => i < 8);
                
                return sectionResults.length > 0 ? (
                  <div className="section" key={section.label}>
                    <div className="section-title">
                      <span>{section.label}</span>
                      <span className="section-label">
                        {sectionResults.length} results
                      </span>
                    </div>
                    <ul className="list-item" onClick={(e) => null}>
                      {
                        sectionResults.map((item, idx) => {
                          const channelId = "*";
                          let url = "#";
                          switch (item.type) {
                            case "story":
                              url =
                                channelId !== "*"
                                  ? `/study/${studyId}/feed/${channelId}/post/${item.info.postId}`
                                  : `/study/${studyId}/feed/post/${item.info.postId}`;
                              break;
                            case "insight-card":
                              url = `/study/${studyId}/edit-story/${item.parent_id}/card/${item.id}`;
                              break;
                            case "dcard":
                              url = `/study/${studyId}/explore/${item.parentId}`;
                              break;
                            case "page":
                              url = `/study/${studyId}/explore/${item.id}`;
                              break;
                            
                            case "tile":
                              url = !item.title.includes("All")
                                ? `/study/${studyId}/explore/area/${item.title
                                    .toLowerCase()
                                    .trim()}`
                                : `/study/${studyId}/explore/area/all`;
                              break;
                            default:
                              url = `#`;
                          }
                        let title = item.title ? item.title : item.id ? item.id : item.description;
                        // todo: cap at 50 chars max

                        return (
                          <li key={idx}>
                            <Link to={url} onClick={this.hideSearchBox}>
                              <span className="label" title={title}>
                                {title}
                              </span>
                              <span
                                className="type text-truncate"
                                title={item.type}
                              >
                                {item.type}
                              </span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : null;
              })}

              {!this.state.loadResult ? (
                <div className="searching">Searching....</div>
              ) : suggestions.length === 0 && results.length === 0 ? (
                <div className="no-result">No Results</div>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

SearchBox = withRouter(
  connect(
    (state) => ({ auth: state.auth, study: state.study }),
    (dispatch) => ({})
  )(SearchBox)
);

export { SearchBox };
