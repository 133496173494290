import * as React from 'react';
import * as globals from '../../globals';
import './Avatar.scss';

export class Avatar extends React.Component {
    render() {
        return <div className='avatar'>
            <img className={'tiny-avatar' + 
                (this.props.mini ? ' mini' : '') +
                (this.props.tiny ? ' tiny' : '')
                } src={`${globals.appRoot}/avatar/${this.props.uid}`} alt='User Avatar' />
        </div>;
    }
}