import { Children, cloneElement, Component, createElement, createRef } from "react";
import Sortable from "sortablejs";

/*
 * Reorder the given array based on old and new index.
 * @params
 *   list: The array to rearrange.
 *   oldIndex: The old index of the dragged element in the array.
 *   newIndex: The new index after dropping the dragged element.
 * 
 * @return
 *   The new array after rearranging.
 * 
 * Example:
 * 
 * reorderArray([0,1,2,3,4,5,6,7,8,9], 3, 0)  // [3,0,1,2,4,5,6,7,8,9]
 * reorderArray([0,1,2,3,4,5,6,7,8,9], 3, 6)  // [0,1,2,4,5,6,3,7,8,9]
 */
const reorderArr = (list, oldIndex, newIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(oldIndex, 1);
    result.splice(newIndex, 0, removed);

    return result;
}

/*
 * Example to use this class.
 * 
 * import { ReactSortable } from './src/components/common/Sortable'
 * ...
 * const list = [0,1,2,3,4,5,6,7,8,9]
 * 
 * <ReactSortable
 *     list={list}
 *     reorder
 *     setList={newList => {}}
 *     tag='div'
 *     className='container'
 * >
 *     {list.map(i => (<span key={i}>{i}</span>))}
 * </ReactSortable>
 * 
 * @props
 *   list: The array to arrange.
 *   reorder: A boolean value, it will use reorderArr function to return updated array after reordering.
 *   setList: A callback function, (newArray) => {}. This callback function will contain only
 *            one parameter, this parameter (newArray) will contain the updated array, after
 *            reaordering the elements if and only if you provide reorder prop, otherwise it
 *            will contain an object which was personalised for reordering Insights cards in
 *            EditStory page.
 *   tag: If you are reordering the elements that is defined inside a container, then you can
 *        provide the html tag for that container. Otherwise we create a div tag and render the
 *        provided children inside of it.
 *   className: The class name of that container you just provided.
 *   
 */
export class ReactSortable extends Component {
    constructor(props) {
        super(props);
        this.ref = createRef();
    }

    componentDidMount() {
        if (this.ref.current === null)
            return;        
            
            this.props?.setLayoutReady?.(true);
   

        // create sortablejs instance.
        // For more info on how to use SortableJS, visit
        // https://www.npmjs.com/package/sortablejs
        Sortable.create(this.ref.current, {
            filter: '.filtered', // 'filtered' class is not draggable
            animation: 150,
            ghostClass: 'ghost',
            preventOnFilter: false,
            onEnd: (ev) => {
                // This method is called when element dragging ended.
                const { setList, reorder, list } = this.props;
                let move = ((ev.newIndex - 2) - (ev.oldIndex - 2));
                let cardId = ev.item.getAttribute('id')
                reorder
                    ? setList(reorderArr(list, ev.oldIndex, ev.newIndex))
                    : setList({ move, cardId });
            },
            onMove: (evt) =>
                evt.related.className.indexOf('filtered') === -1
        });
    }

    render() {
        const { tag, className, id } = this.props;
        const newTag = !tag || tag === null ? "div" : tag;

        return createElement(newTag, Object.assign({ ref: this.ref }, { className, id }), this.getChildren());
    }

    getChildren() {
        const { children } = this.props;

        // if no children, don't do anything.
        if (!children || children == null)
            return null;

        return Children.map(children, child => cloneElement(child, {
            'data-id': child.key,
        }));
    }

    get sortable() {
        const el = this.ref.current;
        if (el === null)
            return null;

        const key = Object.keys(el).find(k => k.includes("Sortable"));
        if (!key)
            return null;

        return el[key];
    }
}

ReactSortable.defaultProps = {
    clone: item => item
};
