import * as React from 'react';
//import { RouteComponentProps } from 'react-router';
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Config.ts';
import { ProductBlock } from './ProductBlock';
import './MultiSelectionDrawer.scss';
import * as globals from '../../globals';
import * as metaUtils from '../../utils/metaUtils';
import { SELECTION_SELECT, SELECTION_ADD, SELECTION_MOVE, SELECTION_REMOVE, SELECTION_REMOVE_ALL } from '../../store/action-types.ts';
import { SelectionBlock } from './SelectionBlock';


const NOT_SET = '[not-set]';

export const MultiSelectionDrawer = (props) => {

    const [searchText, setSearchText] = useState('');
    const [multiSelect, setMultiSelect] = useState(false);
    const [items, setItems] = useState(null);
    const [groupingItems, setGroupingItems] = useState(null);
    const [loadingItems, setLoadingItems] = useState(false);
    const [loadingGroupingItems, setLoadingGroupingItems] = useState(false);
    const [selectedGrouping, setSelectedGrouping] = useState(null);

    

    //const selectedBoxRef = React.useRef(null);

    const studyId = useSelector(state => state.study?.uid);
    const studyFolder = useSelector(state => state.study?.folder);
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const selectionTypes = useSelector(state => state.study?.selectionTypes);

    const selectionType = props.settings?.selectionType;
    const selectionName = props.settings?.selectionName;
    const selectionNameMulti = selectionType?.nameMulti || selectionName + 's';

    const selection = useSelector(state => state.selection);

    const primarySelection = selection?.[selectionName];

    useEffect(() => {
        window.selectionDrawer = this;
    }, [])

    // useEffect(() => {
    //     if (selectedBoxRef.current) {
    //         console.log('scrollIntoView');
    //         selectedBoxRef.scrollIntoView({
    //             behavior: "smooth"
    //         });
    //     }
    // }, [])

    // load the meta
    useEffect(() => {

        const f = async () => {

            const metaKey = selectionType?.meta;
            if (!metaKey) return;

            setLoadingItems(true);
            try {
                let i = await metaUtils.getMeta(studyId, auth, metaKey);
                setItems(i);

                // check selected grouping
                if (!selectedGrouping || selectionType?.groupings?.includes(selectedGrouping)) {
                    //console.log('selected grouping okay')
                }
                else {
                    //console.log('selected grouping not found for type')
                    setSelectedGrouping(null);
                }
            }
            catch (err) {
                console.error(err);
            }
            setLoadingItems(false);
            
        }
        f();

    }, [selectionType])

    //const selectedGroupingName = selectionTypes?.[selectedGrouping] ? selectedGrouping : null;

    // load the selected grouping
    useEffect(() => {

        const f = async () => {

            const groupingMeta = selectionTypes?.[selectedGrouping];
            
            const metaKey = groupingMeta?.meta;
            if (!metaKey) return;

            setLoadingGroupingItems(true);
            try {
                let i = await metaUtils.getMeta(studyId, auth, metaKey);
                setGroupingItems(i);
            }
            catch (err) {
                console.error(err);
            }
            setLoadingGroupingItems(false);
            
        }
        f();

    }, [selectedGrouping])

    const handlePrimaryClick = (item) => {

        // select the freaking item
        dispatch({
            type: SELECTION_SELECT,
            key: selectionName,
            value: item
        })

        if (props.onSelect) {
            props.onSelect(item);
        }
    }

    const handleTopCompClick = (item) => {

        dispatch({
            type: SELECTION_MOVE,
            key: selectionNameMulti,
            value: item,
            newIndex: 0
        })

        if (props.onSelect) {
            props.onSelect();
        }
    }

    const handleAddClick = (item) => {

        dispatch({
            type: SELECTION_ADD,
            key: selectionNameMulti,
            value: item
        })

        if (props.onSelect) {
            props.onSelect();
        }
    }

    const handleAddGroupingClick = (grouping) => {

        dispatch({
            type: SELECTION_ADD,
            key: selectionNameMulti,
            values: grouping?.items
        })

        if (props.onSelect) {
            props.onSelect();
        }
    }

    const handleRemoveClick = (item) => {

        dispatch({
            type: SELECTION_REMOVE,
            key: selectionNameMulti,
            value: item
        })

        if (props.onSelect) {
            props.onSelect();
        }
    }

    const handleRemoveAllClick = () => {

        dispatch({
            type: SELECTION_REMOVE_ALL,
            key: selectionNameMulti
        })

        if (props.onSelect) {
            props.onSelect();
        }

    }


    const isMatch = (slot, searchItems) => {
        let str = slot.label.toLocaleLowerCase();
        for (let i in searchItems) {
            if (str.indexOf(searchItems[i]) === -1)
                return false;
        }
        return true;
    }

    let displayItems = [...(items || [])]
    displayItems = displayItems?.sort((a, b) => {
        if (a.displayIndex !== null && a.displayIndex !== undefined)
            return a.displayIndex - b.displayIndex;
        return a.label.localeCompare(b.label);
    }) || [];
    
    if (searchText !== '') {
        const searchItems = searchText.toLowerCase().split(' ');
        displayItems = displayItems.filter(item => isMatch(item, searchItems));
    }

    let groupings = [null, ...(selectionType?.groupings || [])];
    

    // populate selectedItems and remove them from displayItems
    let selectedItems = (selection?.[selectionNameMulti] || []);

    selectedItems.forEach(selItem => {
        const find = displayItems.find(x => x.id == selItem.id);
        if (find) {
            const idx = displayItems.indexOf(find);
            displayItems.splice(idx, 1);
        }
    });


    let displayGroups = selectedGrouping ? groupingItems?.map(gitem => {
        return {
            label: gitem?.label,
            items: displayItems?.filter(dItem => dItem?.[selectedGrouping] == gitem.id)
        }
    })
    : [{
        label: "All",
        items: displayItems,
        all: true
    }];

    
     const CloseSelector = () => {
        setSearchText('');
        window.topBar.hideMenu();
    }
    
    
    return (
        <div className={"multi-selection-drawer" + (props.isOpen ? ' open' : ' closed')}>

            <div className='top'>

                <div className='top-sections'>

                    <div className='left-section'>

                        <div className='h3'>
                            Choose {selectionType?.labelMulti || selectionType?.label + 's'}
                        </div>

                        <div className='search'>
                            <div className='input-container'>
                                <input type="text" placeholder="search" value={searchText} onChange={(ev) => setSearchText( ev.target.value )}/>
                            </div>
                            <span className={'btn-cancel-search ' + (searchText !== '' ? ' enabled' : '')}
                                onClick={() => setSearchText('')}
                            >
                                <i className='fas fa-times-circle'/>
                            </span>
                        </div>

                        {groupings.length > 1 ? <div className='grouping-selector'>
                            <span className='label1'>
                                Group by:
                            </span>
                            {groupings.map(groupingName => {
                                return <span key={groupingName + '.'} className={'grouping-button' + (groupingName == selectedGrouping ? ' sel' : '')} onClick={() => {
                                    setSelectedGrouping(groupingName);
                                }}>
                                    {groupingName ? groupingName : "All"}
                                </span>
                            })}
                        </div> : null}

                    </div>

                    <div className='right-section'>

                        <div className='h3'>
                            Selected {selectionType?.labelMulti || selectionType?.label + 's'}
                            <button style={{ float: 'right', cursor: 'pointer' }} role='button' aria-label='close' onClick={CloseSelector}>
                                <i className='fal fa-times'></i>
                            </button>
                        </div>

                        <div className='clear-selections'>
                            <span className='clear-button' onClick={handleRemoveAllClick}>
                                Remove All
                            </span>
                        </div>

                    </div>
                    
                </div>


            </div>

            <div className="split-view">
                <div className="choose-items">
                    {loadingItems || loadingGroupingItems ? <div className='loading'>loading...</div> :
                        displayGroups
                            ?.filter(dgroup => dgroup.items?.length > 0)
                            ?.map((dgroup, dgroupIndex) => 
                            <React.Fragment key={dgroup.label + '.' + dgroupIndex}>
                                {dgroup.all ? null : <div className="group-label">
                                        {dgroup.label}
                                        <span className='group-button' onClick={() => {
                                            handleAddGroupingClick(dgroup);
                                            setSearchText('');
                                        }}>
                                            + Add all from {selectionTypes?.[selectedGrouping]?.label}
                                        </span>
                                </div>}
                                <div className="items">
                                    {dgroup.items?.map(item => {
                                        const selected = selection?.[selectionName]?.id == item.id;
                                        //: (this.state.sel && item.uid === this.state.sel.uid);
                                        const isPrimary = false;// this.state.multiSelect && this.state.sel?.uid === item.uid;
                                        const showPrimary = false; //this.state.multiSelect && this.state.includePrimary;
                                        return (
                                            <SelectionBlock
                                                key={item.id}
                                                study_folder={studyFolder}
                                                {...item}
                                                typeLabel={selectionType?.label}
                                                clickText={"+ Add"}
                                                onClick={() => {
                                                    let x = item;
                                                    handleAddClick(x);
                                                    setSearchText('');
                                                }}
                                            />
                                        );
                                    })}
                                </div>
                            </React.Fragment>
                        )
                    }
                </div>
                <div className="selected-items">
                    <div className="primary">
                        <div className='section-label'>
                            Primary {selectionType?.label}
                        </div>
                        <SelectionBlock
                            key={primarySelection?.id}
                            study_folder={studyFolder}
                            {...primarySelection}
                            typeLabel={primarySelection?.selectionTypeLabel || selectionType?.label}
                            selected={false}
                            //ref={selected ? selectedBoxRef : null}
                            showImage={selectionType?.showImage}
                        />
                    </div>
                    <div className="comps">
                        <div className='section-label'>
                            Comparison {selectionType?.labelMulti || selectionType?.label + 's'}
                        </div>
                        {selectedItems?.map((item, itemIndex) => {
                            const selected = selection?.[selectionName]?.id == item.id;
                            //: (this.state.sel && item.uid === this.state.sel.uid);
                            
                            const showPrimary = false; //this.state.multiSelect && this.state.includePrimary;

                            const alsoPrimary = item.id === primarySelection?.id;

                            return (
                                <SelectionBlock
                                    fade={alsoPrimary}
                                    key={item.id}
                                    study_folder={studyFolder}
                                    {...item}
                                    typeLabel={selectionType?.label}
                                    clickText={"Remove"}
                                    xclickText2={<i className='fal fa-arrow-up'/>}
                                    onClick={() => {
                                        let x = item;
                                        handleRemoveClick(x);
                                    }}
                                    xonClick2={() => {
                                        // todo: set primary
                                        let x = item;
                                        handlePrimaryClick(x);

                                    }}
                                    menu2={[
                                        {
                                            label: "Set as primary", onClick: () => {
                                                let x = item;
                                                handlePrimaryClick(x);
                                            }
                                        }
                                        ,
                                        ...(itemIndex > 0 ? [
                                            {
                                                label: "Set as first comparison", onClick: () => {
                                                    let x = item;
                                                    handleTopCompClick(x);
                                                }
                                            }
                                        ] : []
                                        )
                                    ]}
                                />
                            );
                        })}
                    </div>
                    
                </div>
            </div>


            <div className='bottom text-center' >
                <button  className="action-button" role='button' aria-label='close' onClick={CloseSelector}>close</button>
            </div>

        </div>
    );
}

