import React from 'react'
import { useEffect, useRef, useState } from 'react'
import { format as d3Format } from 'd3-format'
import { dateFormat } from './dateFormat'
import * as globals from '../globals'
import * as Babel from '@babel/standalone'
import ScatterPlot from "../components/vis/ScatterPlot2.js"
import BoxPlot from "../components/vis/BoxPlot"
import DotPlot from "../components/vis/DotPlot"
import DWordCloud from "../components/dwidgets/DWordCloud"
import { TableFred } from "../components/vis/TableFred"
import { SimpleTable } from '../components/vis/SimpleTable'
import moment from 'moment'
import Media from 'react-media'
import pptxgen  from "pptxgenjs"
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import { Multiselect } from 'multiselect-react-dropdown'
import exporting from 'highcharts/modules/exporting'
import * as wordcloud from 'highcharts/modules/wordcloud'
import venn from 'highcharts/modules/venn'
import { Vega, VegaLite } from 'react-vega'
import * as apiService from '../utils/apiService'
import { GENERAL_SET_CUSTOM_VALUE, GENERAL_SET_CUSTOM_VALUE2 } from "../store/action-types.ts"
import { Link, useLocation } from 'react-router-dom'
import Radium from 'radium'
import { STORIES_STORY_CREATED } from '../store/action-types.ts'
import { SelectionBlockElement } from '../components/explore/SelectionBlockElement.js'
import { SelectionBlockMini} from '../components/product-drawer/SelectionBlockMini'
import { FilterChooser} from '../components/filter/FilterChooser'
import { PowerPointCreator } from '../components/explore/PowerPointCreator.js'
import { ContentItemPointer as Pointer }  from '../components/explore/ContentItemPointer.js'
import { TypeForm } from '../components/common/TypeForm.js'
import { ChatGpt } from '../components/explore/ChatGpt'
import { ScenarioEditorMultiConfigs } from '../components/config/ScenarioEditorMultiConfigs'
import { OpenSaveScenario } from '../components/scenarios/OpenSaveScenario'
import { ScenarioSummary } from '../components/config/ScenarioSummary'
// import { BardAi } from '../components/explore/BardAi'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
import './custom.scss'
import createTrend from 'trendline'
import { ScenarioEditorMultiConfigs2 } from '../components/config/ScenarioEditorMultiConfigs2'
import { ScenarioEditorMultiConfigsTAM } from '../components/config/ScenarioEditorMultiConfigsTAM'
import { ScenarioEditorMultiConfigsTAMChoice } from '../components/config/ScenarioEditorMultiConfigsTAMChoice'
import { AvgSensitivitiesOutToFile } from '../components/widgets/AvgSensitivitiesOutToFile.js'
import { AvgSensByBrandByFuelType} from '../components/widgets/AvgSensByBrandByFuelType.js'
import { ImportancesToFile } from '../components/widgets/ImportancesToFile.js'

dayjs.extend(utc)
exporting(Highcharts)
wordcloud(Highcharts)
HighchartsMore(Highcharts)
venn(Highcharts)

export const CARD_ERROR_MESSAGE = 'CARD_ERROR_MESSAGE'

export function CardErrorMessage(message, isError = false) {
    this.message = message
    this.isError = isError
    this.name = CARD_ERROR_MESSAGE
}

const optionsObject = {
    presets: ['env']
}

export const compileItem = (item) => {

    let idata = item?.data
    if (!idata) return item

    //this is a comment

    // compute compute
    if (idata?.compute) {

    }

    // compile renderConfig
    if (idata?.renderConfig) {

    }

    // compile render
    if (idata?.render) {

    }

    // compile instances
    if (idata?.instances) {
        idata.getInstances = compileGetInstances(idata?.instances)
        //console.log('idata.getInstances', idata.getInstances)
    }

    // // compile title
    // if( item?.title && item?.title.startsWith("{") && item?.title.endsWith("}")){
    //     item.title = (context) => "function!"
    //     console.log('compiled title', item.title)
    // }

    return item
}



export const compileGetInstances = (syntax) => {

    // transpile
    let transpiled_syntax = null
    try {
        const fullSyntax = "\"use strict\"; async (context, lib) => { " + syntax + " \r\n}"
        transpiled_syntax = Babel.transform(fullSyntax, optionsObject)
        //console.log('transpiled syntax', transpiled_syntax)
    }
    catch (err) {
        
        //throw 'error transpiling syntax: ' + err
        console.error('error transpiling syntax', err)
        return null
    }

    // eval
    let func
    try {
        func = eval(transpiled_syntax.code)
    }
    catch (err) {
        //throw 'error during eval of transpiled syntax: ' + err
        console.error('error during eval of transpiled syntax', err)
        return null
    }

    return func

}

const VegaEx = (props) => {

    const node = useRef()

    useEffect(() => {
        try {
            node.current.vegaEmbed.current.viewPromise
                .then((view) => {
                    if (props.viewReady) {
                        props.viewReady(view)
                    }
                })
        }
        catch { }
    }, [node.current])

    return <Vega {...props} ref={node} />
}

export const defaultLib = {
    d3Format,
    dateFormat,
    moment,
    Media,
    pptxgen,
    Highcharts,
    HighchartsReact,
    PowerPointCreator,
    Vega,
    VegaEx,
    VegaLite,
    Link,    
    useLocation,
    RadiumLink: Radium(Link),
    SelectionBlockElement,
    SelectionBlockMini,
    Pointer,
    TypeForm,
    createTrend,
    DatePicker,
    FilterChooser,
    dayjs,
    ChatGpt,
    ScenarioEditorMultiConfigs,
    ScenarioEditorMultiConfigs2,
    ScenarioEditorMultiConfigsTAM,
    ScenarioEditorMultiConfigsTAMChoice,
    OpenSaveScenario,
    ScenarioSummary,
    AvgSensitivitiesOutToFile,
    AvgSensByBrandByFuelType,
    ImportancesToFile,
    bo: {
        ScatterPlot,
        BoxPlot,
        DotPlot,
        BasicTable: TableFred,
        SimpleTable,
        DWordCloud,
        Multiselect
    }
}

export const getDefaultContext = (study, auth, reduxStore, aux) => {

    const parseQueries = (query) => {

        let noFilterMeasures = []
        let filteredMeasures = {}
        
        query.measures.forEach(m => {
            if (!m.filter) {
                noFilterMeasures.push(m)
            }
            else {
                let arr = filteredMeasures[m.filter] || []
                arr.push(m)
                filteredMeasures[m.filter] = arr
            }
        })

        let queryGroups = []

        // push the query with no measure filters
        if (noFilterMeasures.length > 0) {
            queryGroups.push(
                {
                    ...query,
                    measures: noFilterMeasures
                }
            )
        }

        // push the queries that no have measure filters
        Object.entries(filteredMeasures).forEach(entry => {
            const filterSyntax = entry[0]
            const entryMeasures = entry[1]
            const newQuery = {
                ...query,
                filters: [
                    ...(query.filters || []),
                    filterSyntax
                ],
                measures: entryMeasures
            }
            queryGroups.push(newQuery)
        })
        //console.log('parseQueries.query', query)
        //console.log('parseQueries.queryGroups', queryGroups)
        return queryGroups
    }

    const compute2 = async (query) => {
        if (!query) return null
        const queries = parseQueries(query)
        const mResult = {}
        for (let i in queries) {
            const query = queries[i]
            //console.log('computing query', query)
            const url = `${globals.apiRoot}/study/${study.uid}/compute/yak`
            const res1 = await apiService.aPost(auth, url, query)
            const res2 = await res1.json()
            if (res2?.success) {
                const results = res2.results
                Object.entries(results).forEach(entry => {
                    const key = entry[0]
                    const value = entry[1]
                    if (mResult[key]) {
                        //throw "duplicate measure key encountered"
                        return {
                            success: false,
                            errorMessage: "duplicate measure key encountered: " + key
                        }
                    }
                    mResult[key] = value
                })
            }
            else {
                //throw res2?.errorMessage
                return {
                    success: false,
                    errorMessage: res2?.errorMessage
                }
            }
        }
        //console.log('mResult', mResult)
        return {
            success: true,
            results: mResult
        }
    }

    const compute = async (query) => {
        if (!query) return null
        const url = `${globals.apiRoot}/study/${study.uid}/compute/yak`
        const res1 = await apiService.aPost(auth, url, query)
        const res2 = await res1.json()
        return res2
    }

     const callApi = async (url, token) => {
         if (!url) return null
         
         const res = await apiService.aGetExternalApi(auth, url, token)
         const res2 = await res.json().then(data => console.log(data))
        return res2
    }

    const computeMeasures = async (query, metric = null) => {
        if (!query) return null
        var res = await compute(query)
        if (!res.success) {
            return res
        }
        const results = res?.results
        const data = query.measures.map(m => {
            let row = {
                ...m
            }
            const mcell = results?.[m.id || m.syntax]

            if (query.groups) {
                query.groups.forEach(group => {
                    const groupKey = group.id || group.syntax
                    row[groupKey] = metric ? mcell?.[groupKey]?.[metric] : mcell?.[groupKey]
                })
            }
            else {
                row.value = metric ? mcell?.[metric] : mcell
            }
            return row
        })
        return data
    }

    const computeGroups = async (query, metric = null) => {
        if (!query) return null
        var res = await compute(query)
        if (!res.success) {
            return res
        }
        const results = res?.results
        const data = query.groups.map(g => {
            let row = {
                ...g
            }
            const groupKey = g.id || g.syntax
            //const mcell = results?.[m.id || m.syntax]
            query.measures.forEach(m => {
                const mcell = results?.[m.id || m.syntax]
                row[m.id || m.syntax] == metric ? mcell?.[groupKey]?.[metric] : mcell?.[groupKey]
            })
            return row
        })
        return data
    }

    const yakEngine = {
        compute: compute2,
        computeMeasures,
        computeGroups
    }

    const sqlEngine = {
        lookup: async (query) => {
            if (!query) return null
            const url = `${globals.apiRoot}/study/${study.uid}/compute/lookup`
            const res1 = await apiService.aPost(auth, url, query)
            if (!res1.ok) {
                console.error(res1)
                console.error(await res1.text())
                return null
            }
            const res2 = await res1.json()
            return res2
        }
    }

    const metaEngine = {
        getValue: async (key) => {
            if (!key) return null
            const url = `${globals.apiRoot}/study/${study.uid}/meta/key/${key}`
            const res1 = await apiService.aGet(auth, url)
            const res2 = await res1.json()
            return res2?.value
        },
        setLicValue: async (key, value) => {
            const body = {
                licValue: value
            }
            const url = `${globals.apiRoot}/study/${study.uid}/lic-meta/key/${key}`
            const res = await apiService.aPost(auth, url, body)
            return res?.ok ? true : false
        },
        setLicValueString: async (key, strValue) => {
            const body = {
                licValueString: strValue
            }
            const url = `${globals.apiRoot}/study/${study.uid}/lic-meta/key/${key}`
            const res = await apiService.aPost(auth, url, body)
            return res?.ok ? true : false
        },
    }
    

    const reduxEngine = {
        setValue: (key, value) => {
            reduxStore.dispatch({ type: GENERAL_SET_CUSTOM_VALUE, key, value })
        },
        setValue2: (key1, key2, value) => {
            reduxStore.dispatch({ type: GENERAL_SET_CUSTOM_VALUE2, key1, key2, value })
        },
        getValue: (key) => {
            const state1 = reduxStore.getState()
            return state1?.general?.custom?.[key]
        },
        getStoreValue: (key) => {
            let state = reduxStore.getState()
            const keys = key.split('.')

            const value = keys.reduce((currentValue, key) => {
                return currentValue ? currentValue[key] : undefined
            }, state)

            return value
        }
    }

    const blobEngine = {
        getJson: async (path) => {
            try {
                const url = `${globals.apiRoot}/study/${study.uid}/blob/${path}`
                const res1 = await apiService.aGet(auth, url)
                const res2 = await res1.json()
                return res2
            }
            catch (err) {
                return null
            }
        }
    }

    const bigDataEngine = {
        getJson: async (path, body) => {
            try {
                const url = `${globals.apiRoot}/study/${study.uid}/bigdata?path=${encodeURIComponent(path)}`
                const res1 = await apiService.aPost(auth, url, body || {})
                const res2 = await res1.json()
                return res2
            } catch (err) {
                return null
            }
        },
    }

    const state = reduxStore?.getState()
    const studyId = state?.study?.uid     

    const actions = {
        
        createStory: async (storyData) => {

            const body = {
                ...storyData
            }

            try {
                const url = `${globals.apiRoot}/study/${studyId}/story`
                const response = await apiService.aPost(state.auth, url, body)
                if( response.ok ){
                    const newStory = await response.json()
                    reduxStore.dispatch({ type: STORIES_STORY_CREATED, story: newStory })
                    return newStory
                }
                else{
                    console.error(response.statusText)
                }

            } catch (error) {
                console.error(error)
            }

        },

        navigate: {
            withinStudy: (urlFragment) => {
                console.log('navigate withinStudy', urlFragment, aux)
                if (aux?.history) {
                    const url = `/study/${studyId}/${urlFragment}`
                    aux.history.push(url)
                }
            },
            // withinExplore: (urlFragment) => {
            //     if (aux?.history) {
            //         const url = `${globals.apiRoot}/study/${studyId}/explore/${urlFragment}`
            //         aux.history.push(url)
            //     }
            // }
        },
        setPrimaryDefault: async (title) => {
            const studyId = study.uid
            let licDefaults

            try {
                const response = await apiService.aGet(
                    auth,
                    `${globals.apiRoot}/study/${studyId}/definition`,
                )

                licDefaults = await response.json()

                } catch (err) {
                console.log(err)
                
            }


            if (licDefaults ) { 
                licDefaults.defaults.selection.title = title
            
                //save to defaults field in studies table
                try {
                    const response = await apiService.aPost(
                        auth,
                        `${globals.apiRoot}/study/${studyId}/defaults`,
                        licDefaults?.defaults
                    )
                    // console.log(response)
                
                } catch (err) {
                    console.log(err)
                
                }
            }
        },

        
        setCompDefaults: async (compList) => {

		const studyId = study.uid
		let licDefaults

		try {
			const response = await apiService.aGet(
				auth,
				`${globals.apiRoot}/study/${studyId}/definition`,
			)

			licDefaults = await response.json()

			} catch (err) {
			console.log(err)
			
		}


		if (licDefaults ) { 
			licDefaults.defaults.selection.titles = compList
		
		 	//save back to db
			try {
				const response = await apiService.aPost(
					auth,
					`${globals.apiRoot}/study/${studyId}/defaults`,
					licDefaults?.defaults
				)
				// console.log(response)
			
			} catch (err) {
				console.log(err)
			
			}
		}
		

	}
    }
    

         
    
    return {
        meta: {
            ...(window.meta),
            ...metaEngine
        },
        api: callApi,
        blob: blobEngine,
        yak: yakEngine,
        db: sqlEngine,
        bigData: bigDataEngine,
        globalStore: reduxEngine,
        throwMessage: (m) => { throw new CardErrorMessage(m) },
        throwError: (m) => { throw new CardErrorMessage(m, true) },
        study: {
            uid: study.uid,
            label: study.label,
            subscriptionLabel: study.subscriptionLabel,
            folder: study.folder,
            studySpecificItems: study.studySpecificItems
        },
        channels: state?.channels,
        redux: {
            dispatch: reduxStore.dispatch
        },
        actions
        
    }

}