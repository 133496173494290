import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as globals from '../../globals';
//import { actionCreators } from '../../store/BigData';
import { FilterChooser } from '../filter/FilterChooser';
import { ProductBlockMini } from '../product-drawer/ProductBlockMini';
import { AnalyticsWidgetDebugBar } from './AnalyticsWidgetDebugBar';

export const ImageWidget = (props) => {

    const studyId = useSelector(state => state.study?.uid);

    const path = props.expanded ? props.bigPath || props.path : props.path;
    if( !path ) return;

    const content = <img src={`${globals.appRoot}/study/${studyId}/shared/images/${path}`} alt=""
        style={props.expanded ? props.bigStyle || props.style : props.style}
    />;

    return <div className='widget'>
        <div className='widget-body' style={{ textAlign: 'center' }}>
            {content}
        </div>
    </div>;


}
