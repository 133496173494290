import React, {useEffect, useState} from 'react';
import './Modal.scss'

export function Modal({ close, content }) {

    return (
        <div className='modal-main'>           
            <div className='modal-content'>
                {content}
            </div>
            <div className='modal-footer'>
                <button className='btn drk rounded left-space' onClick={close} style={{ fontSize: 12, height:30}} role='button' aria-label='Close window'>
                    <i className='fal fa-times-circle' style={{ paddingRight: 5 }} />
                    Close
                </button>
            </div>
        </div>
    )

}