

// const mapObject = (obj, func) => Object.entries(obj).map(entry => ({
//     key: entry[0],
//     value: func(entry[1])
// })).reduce((accum, val) => {
//     accum[val].key = val.value;
//     return accum;
// }, {});


export const mapObject = (obj, func) => Object.entries(obj).reduce((accum, entry) => {
    accum[entry[0]] = func(entry[1]);
    return accum;
}, {});


// usage

//mapObject(fred, (obj) => obj.asdf);