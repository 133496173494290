import * as Babel from "@babel/standalone";
import * as React from "react";
import Radium from "radium";
import { useEffect, useState, useContext } from "react";
import ReactJson from "react-json-view";
import { useSelector, useStore, useDispatch } from "react-redux";
import * as ReCharts from "recharts";
import * as jsCompiler from "../../utils/jsCompiler";
import * as selectionHelper from "../../utils/selectionHelper";
import * as metaUtils from "../../utils/metaUtils";
import DBarChart from "../dwidgets/DBarChart";
import DColumnChart from "../dwidgets/DColumnChart";
import DLineChart from "../dwidgets/DLineChart";
import DPieChart from "../dwidgets/DPieChart";
import DScatterPlot from "../dwidgets/DScatterPlot";
import DWordCloud from "../dwidgets/DWordCloud";
import { SimpleTable } from "../vis/SimpleTable";
import { TableFred } from "../vis/TableFred";
import { CardLoading } from "../card/CardLoading";
import { CompChooser } from "../filter/CompChooser";
import { CompareChooser } from "../filter/CompareChooser";
import { FilterChooser } from "../filter/FilterChooser";
import { ProductBlockMini } from "../product-drawer/ProductBlockMini";
import { SelectionBlockMini } from "../product-drawer/SelectionBlockMini";
import { actionCreators as storyActionCreators } from "../../store/Stories.ts"
import { actionCreators as forecastActionCreators } from '../../store/Forecast'
import { actionCreators as fordForecastActionCreators } from '../../store/FordForecast'
import { useHistory } from 'react-router-dom';
import * as forecastHelper from "../../utils/forecastHelper";
import * as globals from "../../globals";
import * as apiService from "../../utils/apiService";
import { ScrollPositionContext } from "../contexts/ScrollPositionContext";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import './dcard.scss'


//const format = d3Format('.1%');

// async function asyncForEach(array, callback) {
//   for (let index = 0; index < array.length; index++) {
//     await callback(array[index], index, array);
//   }
// }


// role is: compute, asTable, render
export const DynamicWidget = (props) => {

	const reduxStore = useStore();
	const study = useSelector(store => store.study);
	const auth = useSelector(store => store.auth);
	const dispatch = useDispatch();
	const storeModel = useSelector(store => store.model)
	const storeModelResults = useSelector(store => store.forecaster?.fordForecast); // change me eventually!
	const config = storeModel[storeModel.activeConfig]?.config 

	const [cache, setCache] = useState(props.cache); // should load from props for insight cards
	const [loading, setLoading] = useState(false);

	const [computeCounter, setComputeCounter] = useState(0);

	const item = props.item;
	const idata = props.data || {};
	const history = useHistory();

	// selectors
	const selections = useSelector(store => store.selections);
	const stateFilter = useSelector(store => store.filter);
	const stateCompare = useSelector(store => store.compare);
	let _selectionLevel = idata?.selectionLevel || "product"; 

	const selectionTypes = useSelector(store => store.study?.selectionTypes);
	const selection = useSelector(store => store.selection);

	// const hideHeader = idata?.hideHeader;

	// console.log('hide my header', hideHeader)

	const storyDrafts = useSelector(store => store.stories?.drafts);
	const storiesDict = useSelector(store => store.stories?.dict);
	const general = useSelector(store => store.general);

	const reRenderCard = [];
	const cardItems = useSelector(store => store.general.custom)
	const forecastResults = useSelector(store => store.forecaster.forecast)

	if (cardItems)//globalStore
	{
		idata.useGlobalStoreItems?.forEach(item => {
			reRenderCard.push(cardItems[item]);
		})
	}

	if (Object.keys(forecastResults).length > 0) {
		// console.log('forecast happened', forecastResults);
		reRenderCard.push(forecastResults)
	}

	const comps = selections?.competitors;
	//console.log('comps', comps);
	//console.log('props', props);

	const aux = {
		dispatch,
		history
	}

	// vars
	const filterDatasetId = idata?.useFilterDatasetId;
	const filter = props.inDataCard ? (filterDatasetId ? (stateFilter?.selected?.[filterDatasetId] || {}) : stateFilter?.selectedFilter) : props.filter;
	const compare = props.inDataCard ? stateCompare?.selection : props.compare;
	const _selectionId = selections?.[_selectionLevel]?.[_selectionLevel];
	const selectionLevel = _selectionLevel; // props.inDataCard ? _selectionLevel : props.selectionLevel;
	const selectionId = props.inDataCard ? _selectionId : props.selectionId;
	// console.log('_selectionId',_selectionId);
	// console.log('selectionLevel',selectionLevel);
	// console.log('selectionId',selectionId);

	// console.log('filter', filter);
	// console.log('compare', compare);

	const showAsInsightCard = props.inInsightCard || props.showAsInsightCard;

	const selectedItem = selectionHelper.getSelectionOfLevel(
		props,
		study,
		selections,
		selectionLevel,
	)

	//dcards need to update when selections change

	// if (idata.useFilter) {
		reRenderCard.push(filter.syntax);
	// }
	if (idata.useCompare) {
		reRenderCard.push(compare?.groups);
	}
	if (idata.useSelection) {
		reRenderCard.push(selectionLevel);
		reRenderCard.push(selectionId);
	}
	if (idata.useComps) {
		reRenderCard.push(comps);
	}
	if (idata.useStoryDrafts) {
		reRenderCard.push(storyDrafts);
		reRenderCard.push(storiesDict);
	}
	if (idata.compute) {
		reRenderCard.push(idata.compute);
	}
	if (idata.renderConfig) {
		reRenderCard.push(idata.renderConfig);
	}
	if (idata.useConfigs) {
		idata.useConfigs?.forEach(configName => {//configname = 'play' or 'base'...
			reRenderCard.push(config?.[configName])
		})
	}
	if (idata.useModelConfigs) {
		idata.useModelConfigs?.forEach(modelName => { // modelName = 'model1' or 'modelwhatever'...
			reRenderCard.push(storeModel?.[modelName]?.config)
		})
	}
	if (idata.useModelResults) {
		idata.useModelResults?.forEach(modelName => { // modelName = 'model1' or 'modelwhatever'...
			reRenderCard.push(storeModelResults?.[modelName])
		})
	}


	let state = {};
	// if (idata.useFilter) {
		state.filter = filter;
	// }
	if (idata.useCompare) {
		state.compare = compare;
	}
	if (idata.useSelection) {
		state.selections = selections;
		state.selectionId = selectionId;
		state.selectionLevel = selectionLevel;
		state.selectedItem = selectedItem;
	}
	if (idata.useComps) {
		state.selections = selections;
		state.selectionId = selectionId;
		state.selectionLevel = selectionLevel;
		state.selectedItem = selectedItem;
		state.comps = comps;
	}
	if (idata.useStoryDrafts) {
		state.storyDrafts = storyDrafts;
		state.storiesDict = storiesDict;
	}
	if (props.instanceContext) {
		state.instance = props.instanceContext;// props.inDataCard ? props.instanceContext : props.instance;
	}
	if (idata.useConfigs) {
		state.configs = {}
		idata.useConfigs?.forEach(configName => { //play or base
			state.configs[configName] = config?.[configName];
			// state.configs[configName + '_etag']  = config?.etag;
		})
	}
	if (idata.useModelConfigs) {
		state.modelConfigs = {}
		idata.useModelConfigs?.forEach(modelName => { // model1 or whatever
			state.modelConfigs[modelName] = storeModel?.[modelName]?.config;
			// state.configs[configName + '_etag']  = config?.etag;
		})
	}
	if (idata.useModelResults) {
		state.modelResults = {}
		idata.useModelResults?.forEach(modelName => { // model1 or whatever
			state.modelResults[modelName] = storeModelResults?.[modelName];
			// state.configs[configName + '_etag']  = config?.etag;
		})
	}
	if (idata.useModels) {
		state.models = {};
		for (var i in idata.useModels) {
			var modelName = idata.useModels[i];//dcmModel - this refers to model name in meta
			state.models[modelName] = {
				compute: async (payload) => { //compute will return the results back to the dcard (calling function)
					if (payload.forecaster === 'ford')//filterDatasetId = filtergroups (study.xlm) datasetId="tam"
						return await forecastHelper.computeFordModel(study.uid, auth, payload)//, filterDatasetId)
					else
						return await forecastHelper.computeModel(study.uid, auth, payload)//, config?.playHash)
				},
				forecast: async (payload) => { //forecast will save results in forecaster in redux store
					if (payload?.kind === 'ford')
						await dispatch(fordForecastActionCreators.ford_forecast(payload.filter, null, idata.useFilterDatasetId))
					else
						await dispatch(forecastActionCreators.requestForecast(null))
				},
				run_aux2: async (payload) => {
					return await forecastActionCreators.runAux2({ study, auth }, payload.path, payload.modelName, payload.data);
				},
			}
		}
	}

	// load selections state
	let selections_state = idata.useSelections?.reduce((accum, sel) => {
		let name = sel;
		let flavor = null;
		let hide = false;
		if (name.startsWith('!')) {
			name = name.substring(1);
			hide = true;
		}
		if (name.includes('(')) {
			try {
				const idx1 = name.indexOf('(');
				const idx2 = name.indexOf(')', idx1);
				flavor = name.substring(idx1 + 1, idx2);
				name = name.substring(0, idx1);
			}
			catch (err) {
				console.error(err);
			 }
		}
		let nameMulti = selectionTypes?.[name]?.nameMulti || name + 's';

		let selItem = selection?.[name];
		let selItems = selection?.[nameMulti];
		if (flavor == null) {
			accum[name] = selItem;
			reRenderCard.push(selItem);
		}
		else {
			// accum[nameMulti] = selItems;
			// reComputeKeys.push(selItems);

			// compute single and multi and remove dups
			accum[nameMulti] = [selItem, ...(selItems || [])].reduce((acc, el) => {
				if (!acc.find(e => e.id === el.id)) {
					acc.push(el);
				}
				return acc;
			}, []);

			reRenderCard.push(selItem);
			reRenderCard.push(selItems);
		}
		return accum;
	}, {});

	if (selections_state) {
		state = {
			...state,
			sel: selections_state
		};
	}


	const sstate = state;
	const getSaveInfo = () => {
		const retval = {
			state: {
				...sstate,
				cache,
			},
			is_dynamic: true,
			dynamic_item: item
		}
		return retval
	}


	
	// allow datacard to save this widget
	useEffect(() => {
		props.stateContext.getSaveInfo = getSaveInfo;
	})

	const highlightNavigation = () => {
		console.log('inside higlight naviation');
	}


	// compile and execute compute func(s)
	useEffect(() => {

		if (props.inInsightCard) return; // don't compute since cache should already exist
		async function async_func() {
			setLoading(true);

			const optionsObject = {
				presets: ["env", "react"],
			};

			setComputeCounter(computeCounter + 1);
			let _cache = {
				computeCounter,
			};

			let context = jsCompiler.getDefaultContext(study, auth, reduxStore, aux);
			context = {
				...context,
				...state,
			}

					
			// compile compute func
			let compiledComputeFunc = null;
			if (idata.compute) {
				try {
					const computeSyntax =
						'"use strict"; async (context, lib, prevCache) => { ' + idata.compute + " \r\n}";
					compiledComputeFunc = Babel.transform(computeSyntax, optionsObject);
				} catch (err) {
					_cache.computeCompilationError = err?.message;
				}
			}

			// compile renderOptions (deprecated)
			let compiledConfigFunc = null;
			if (idata.renderConfig) {
				try {
					const configSyntax =
						'"use strict"; (context, lib) => { ' + idata.renderConfig + " \r\n}";
					compiledConfigFunc = Babel.transform(configSyntax, optionsObject);
				} catch (err) {
					_cache.renderConfigCompilationError = err;
				}
			}
			
			const lib = jsCompiler.defaultLib;
			let metaHash = {};
			
			// run the meta function if needed
			if (idata.useMetaItems === "*") {
				let meta_all = await metaUtils.getAllMeta(study.uid, auth);
				for (let i = 0; i < meta_all.length; i++) {
					let metaKey = meta_all[i].key;
					let val = meta_all[i].value;
					metaHash[metaKey] = val;
				}	
			}
			else if (idata?.useMetaItems?.length > 0 ) {
				for (let i = 0; i < idata.useMetaItems.length; i++) {
					let metaKey = idata.useMetaItems[i];
					let val = await metaUtils.getMeta(study.uid, auth, metaKey);
					metaHash[metaKey] = val;
				}				
			}
			// append to context
				context.meta = {
					...context.meta,
					...metaHash
				}

			// run the compute function
			if (compiledComputeFunc) {
				try {
					// eval compiled compute
					const computeFunc = eval(compiledComputeFunc.code);
					// run computeFunc
					_cache.eval = {
						compute: await computeFunc?.(context, lib, cache?.compute)
					};
				} catch (err) {
					//console.error(`compute error on element ${props.entry?.uid}`, err);
					_cache.evalError = err?.message;
				}
			}

			// run the renderOptions function
			if (compiledConfigFunc) {
				try {
					var configFunc = eval(compiledConfigFunc.code) || null;
					_cache.eval = {
						..._cache?.eval,
						renderConfig: (configFunc && configFunc?.(context, lib)) || null,
					};
				} catch (err) {
					console.error('renderOptions error', err)
					_cache.renderConfigEvalError = err
				}
			}
			
			setCache(_cache?.eval || _cache)
			setLoading(false)
		}

		async_func()
	}, reRenderCard)

	const asTableStr = props.data?.asTable?.trim()
	const compileAsTable = asTableStr?.length > 0 ? () => {
		// compile render jsx

		let f = null
		const context = jsCompiler.getDefaultContext(study, auth, reduxStore, aux)
		const lib = jsCompiler.defaultLib
		
		
		try {
			const optionsObject = {
				presets: ["env", "react"],
			}
			const syntax = "(cache, context, lib) => { " + props.data?.asTable + " \r\n}"
			const asTableCompiled = Babel.transform(syntax, optionsObject)
			f = eval(asTableCompiled.code)
			return (cache) => f(cache?.compute, context, lib)
		}
		catch (err) {
			return () => {
				context.throwError("asTable doesn't compile: " + err.message)
			}
		}


	} : null

	useEffect(() => {
		if (props.exportHandler) {

			props.exportHandler.hasInfo = props.description && props.description.trim() != ""

			props.exportHandler.asTable = compileAsTable ? () => {
				const asTableFunc = compileAsTable()
				// console.log('asTableFunc', asTableFunc);
				return asTableFunc?.(cache)
			} : null
			props.exportHandler.hasTable = props.data?.asTable
			props.exportHandler.refresh()
		}

	}, [cache, compileAsTable, props.description])
	

	const renderOptions = () => {
		return (
			<div className="filter-etc" style={{ marginTop: 5 }}>
				options
			</div>
		);
	};

	
	let renderContent = undefined;

	if (cache?.computeCompilationError) {
		
		renderContent = () => {
			throw new jsCompiler.CardErrorMessage("compute doesn't compile: " + cache.computeCompilationError, true);
		}
	}
	else if (cache?.evalError) {
		renderContent = () => {
			throw new jsCompiler.CardErrorMessage("compute crashed: " + cache.evalError, true);
		}
	}
	else if (props.data?.visualize && !props.debug) {
		const visual =
			props.showJson ? 'asJson' :
			props.showTable ? 'asTable' :
			props.data?.visual;

		if (visual === "bar") {
			renderContent = (cache) => cache && "compute" in cache && "renderConfig" in cache && <DBarChart compute={cache?.compute} renderConfig={cache?.renderConfig} />;
		}
		else if (visual === "column") {
			renderContent = (cache) => cache && "compute" in cache && "renderConfig" in cache && <DColumnChart compute={cache?.compute} renderConfig={cache?.renderConfig} />;
		} else if (visual === "scatter") {
			renderContent = (cache) => cache && "compute" in cache && "renderConfig" in cache && <DScatterPlot compute={cache?.compute} renderConfig={cache?.renderConfig} />;
		} else if (visual === "line") {
			renderContent = (cache) => cache && "compute" in cache && "renderConfig" in cache && <DLineChart compute={cache?.compute} renderConfig={cache?.renderConfig} />;
		} else if (visual === "wordcloud") {
			renderContent = (cache) => {
				return cache && <DWordCloud compute={cache?.compute} renderConfig={cache?.renderConfig} />;
			};
		} else if (visual === "pie") {
			renderContent = (cache) => cache && "compute" in cache && "renderConfig" in cache && <DPieChart compute={cache?.compute} renderConfig={cache?.renderConfig} />;
		}
		else if (visual === "table") {
			renderContent = (cache) => {
				const compute = cache?.compute;
				const renderConfig = cache?.renderConfig;
				const columns = renderConfig?.columns;
				return (
					<center>
						<TableFred columns={columns} data={compute} />
					</center>
				);
			};
		} else if (visual === "custom") {

			// compile render jsx
			let context = jsCompiler.getDefaultContext(study, auth, reduxStore, aux);
			context = {
				...context, 
			}

			// console.log('context', context);
			
			//find dcard_main in content object - this is for card big modal
			if (props.modalOpen && !props.data.render.includes('open')) 
				props.data.render = props.data?.render.replace('dcard_main', 'dcard_main open')
			else
				props.data.render = props.data?.render.replaceAll('open', '')
			

			try {
					const optionsObject = {
						presets: ["env", "react"],
					}
				
					const syntax = "(React, ReCharts, cache, context, lib) => { " + props.data?.render + " \r\n}";
					// console.log('SYNTAX', syntax)
					const renderCompiled = Babel.transform(syntax, optionsObject);

					// let context = jsCompiler.getDefaultContext(study, auth, reduxStore, aux);
					if (showAsInsightCard) {
						context.insightCard = true;
					}
				
					const lib = jsCompiler.defaultLib;
					const f = eval(renderCompiled.code);
				
					renderContent = (cache) => f(React, ReCharts, cache?.compute, context, lib);				
			}
			catch (e) {
				renderContent = () => {
					context.throwError("custom render doesn't compile: " + e?.message);
				}
			}

		}
		else if (visual === "asTable") {

			// compile render jsx
			renderContent = (cache) => {
				const asTableFunc = compileAsTable()
				const asTableResult = asTableFunc?.(cache)

				const updateImage = {
					marginTop:'15%',
					borderRadius: 5,
					width:300,
				}

				const cover = {
					position: 'fixed', 
					top:0, 
					bottom:0, 
					right:0, 
					left: 0, 
					backgroundColor: 'rgba(0,0,0, 0.6)',
					display:'flex', 
					justifyContent:'center',
					alignItems: 'flex-start',    
					zIndex:1000					
				}

				// console.log('cache?.compute?.update_needed', cache?.compute?.update_needed);
				
				return cache?.compute?.update_needed ? (
						<div style={cover}> 
							<img src={`https://blueowl-storage.azureedge.net/cdn-shared/update_needed.png`} style={updateImage} />
						</div>
					)
					: (
						<center><SimpleTable columns={asTableResult?.columns} data={asTableResult?.data || asTableResult?.rows} showTabular={props.showTable} style={asTableResult?.style} {...asTableResult?.props} /></center>
					)
				
			}

		}
		else if (visual === "asJson") {
			renderContent = null;	
		}
			
		else {
			
			return 'unsupported visual';

		}
	}

	let extra = {};
	if (true) {
		//extra.fakeSelectionLabel = `All Market Simulation ${studyProductLabel}s`;
	}

	//data = all tabs in dcard, compute, render...
	const data = cache;	
	
	
	let extraContentClass = "";
	let content = null;
	const render = renderContent;
	if (render) {

		try {
			content = render(data);			
		}
		catch (e) {
			//content = "...error in widget render: " + err;

			let messageStr = null;
			let messageStyle = null;
			let isError = false;

			if (e?.name === jsCompiler.CARD_ERROR_MESSAGE) {
				const message = e.message;
				if (typeof message === 'string') {
					messageStr = message;
				}
				else if (typeof message === 'object') {
					messageStr = message.text;
					messageStyle = message.style;
				}
				isError = e.isError;
			}
			else {
				//console.error("error compiling or evaluating render jsx", e);
				messageStr = "render crashed: " + e?.message;
				isError = true;
			}
			
			content = <div className={'card-message' + (isError ? ' error' : '')} style={messageStyle}>
				{messageStr}
			</div>
		}
	}
	else {
		//content = 'render missing: ' + (data ? JSON.stringify(data) : null);
		content = (
			<ReactJson
				src={data?.compute || data || {}}
				theme={"rjv-default"}
				iconStyle="circle"
				displayDataTypes={false}
				name={null}
				collapsed={1}
				enableClipboard={true}
			/>
		);
		extraContentClass += " vscroll hscroll";
	}

	

	let title =
		props.title && typeof props.title === "function"
			? "" + props.title(data?.compute)
			: props.title;
    if( title ){
        title = title.replace(/\{([0-9a-zA-Z]+)\}/g, (str) => data?.compute?.[str.slice(1,-1)] || "")
	}

	let st = props.data.subTitle || props.subTitle;
	if (props.subTitle && typeof props.subTitle === "function") {
		st = props.subTitle(data?.compute);
	}
	if( st ){
        st = st?.replace(/\{([0-9a-zA-Z]+)\}/g, (str) => data?.compute?.[str.slice(1,-1)] || "")
    }

	let t2 = props.title2;
	if (props.title2 && typeof props.title2 === "function") {
		t2 = props.title2(props.transformContext);
	}

	let labelIfExists = {};
	if (idata.selectionLabelOverride) {
		labelIfExists.label = idata.selectionLabelOverride;
	}

	let selections_content = idata.useSelections?.map((sel, selIdx) => {
		let name = sel;
		let flavor = null;
		if (name.startsWith('!')) {
			return null; // don't show this item
		}
		if (name.includes('(')) {
			try {
				const idx1 = name.indexOf('(');
				const idx2 = name.indexOf(')', idx1);
				flavor = name.substring(idx1 + 1, idx2);
				name = name.substring(0, idx1);
			}
			catch (err) {
				console.error(err);
			 }
		}
		let nameMulti = selectionTypes?.[name]?.nameMulti || name + 's';

		let selItem = selection?.[name];
		let selItems = selection?.[nameMulti];

		

		if (props.inInsightCard) {
			selItem = props.sel?.[name];
			selItems = props?.sel?.[nameMulti];
		}

		return <div className="selection" key={selIdx}>
			{flavor ?
				<SelectionBlockMini name={name} flavor={flavor} selectedItem={selItem} selectedItems={selItems} disabled={showAsInsightCard} />
				: <SelectionBlockMini name={name} flavor={flavor} selectedItem={selItem} disabled={showAsInsightCard}/>}
		</div>
	})

	const removeBorders = idata?.removeBorders;	

	return (
		<ErrorBoundary>
			<div className={"widget core-widget1 " + (props.class1 || "")} id='export-root'>
					<div className={"widget-header" + (removeBorders ? ' no-pad' : '')}>
						{!title || title === '' ? null : <div className="title">{title}</div>}
						{st ? <div className="subtitle">{st}</div> : null}{selections_content}
						{idata.useSelection ? (
							<div className="selection">
								<ProductBlockMini
									{...selectedItem}
									disabled={showAsInsightCard}
									allowChange={[selectionLevel]}
									{...labelIfExists}
								/>
							</div>
						) : null}
						{idata.useComps ? (
							showAsInsightCard ? null : (
								<div className="filter-etc">
									<CompChooser
										mini={true}
										disabled={showAsInsightCard}
										selection={comps}
										allow={[selectionLevel]}
										{...labelIfExists}
									/>
								</div>
							)
						) : null}
						{idata.useCompare ? (
							<div className="filter-etc">
								<CompareChooser
									mini={true}
									disabled={showAsInsightCard}
									selection={compare}
									{...labelIfExists}
								/>
							</div>
						) : null}
						{idata.useFilter ? (
							<div className="filter-etc">
								<FilterChooser
								mini={true}
								disabled={showAsInsightCard}
								selection={filter}
								datasetId={idata.useFilterDatasetId}
								/>
							</div>
						) : props.showFilter ? (
							<div className="filter-etc">
								<FilterChooser
									mini={true}
									disabled={true}
									selection={globals.getDefaultFilter(study)}
								/>
							</div>
						) : null}
						{t2 ? <div className="title2">{t2}</div> : null}
						{props.options ? props.options() : null}
					</div>
				<div className={"widget-body " + extraContentClass + " " + (idata?.bodyClass || "") + " " + (props.class2 || "") + " " + (props.showTable || renderContent === null ? ' vscroll hscroll ' : '')} >
					{content}
				</div>
				{props.showLoadingIcon ? <CardLoading loading={loading} expired={false} /> : null}
			</div>
		</ErrorBoundary>
	)
}

export const RadiumDynamicWidget = Radium(DynamicWidget);

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		//logErrorToMyService(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <div>render failed</div>;
		}

		return this.props.children;
	}
}
