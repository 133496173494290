import React from 'react';
import { useState } from "react";
import { useSelector } from "react-redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { actionCreators as configActionCreators } from '../../store/Config.ts';
import { actionCreators as selectionsActionCreators } from '../../store/Selections.ts';
import './ProductBlockMini.scss';


export const SelectionBlockMini = (props) => {

    const selectionTypes = useSelector(state => state.study?.selectionTypes);
    const selectionType = selectionTypes?.[props.name];
    const selLabel = selectionType?.label;
    const nbSelect = props.flavor === null ? 1 : props.flavor;
    //console.log('nbSelect', nbSelect, props.flavor);
    const typeLabelMulti = selectionType?.labelMulti || selectionType?.label + 's';

    const handleClick = () => {

        if( !props.disabled ){
            
            const callback = (sel) => {
                window.topBar.hideMenu();
            };
            const options = {
                selectionType,
                selectionName: props.name,
                nbSelect
            }
            if (props.flavor) {
                window.topBar.openMultiSelection(null, callback, options);
            }
            else {
                window.topBar.openSelection(null, callback, options);                
            }
                
        }
    }

    const selectedItem = props.selectedItem;
    const selectedItems = props.selectedItems;
    const allSelectedItems = [props.selectedItem, ...(props.selectedItems || [])]
        .filter(x => x) // remove missing entries
        .reduce((acc, el) => {
            if (!acc.find(i => i?.id === el?.id)) {
                acc.push(el);
            }
            return acc;
        }, [])
        ;

    const selectedItemsLabel = props.flavor == '2' ? allSelectedItems?.[0]?.label + ' vs. ' + allSelectedItems?.[1]?.label
        : //props.flavor == '*'
            `(${allSelectedItems?.length || 0} ${typeLabelMulti} selected)`

    const selectionTypeLabel = selectedItem?.selectionTypeLabel || selectionType?.label;
    
    return <div 
        className={'product-block-mini'
            + (props.standalone ? ' standalone' : '')
            + (props.selected ? ' sel' : '')
            + (props.image ? ' has-image' : '')
            + (props.disabled ? ' disabled' : '')
        }
        style={props.style}
        onClick={handleClick}
    >
        
        <div className='label-container'>
            {selectedItem && !props.flavor ?
                <div className="text1 text-truncate" title={selectedItem.label}>
                    {selectedItem.label}
                </div>
            : allSelectedItems ?
                <div className="text1 text-truncate" title={selectedItemsLabel}>
                    {selectedItemsLabel}
                </div>
            :
                <div className='select-message'>
                    {`Click to select ${selLabel}`}
                </div>
            }
            <div className="text2">
            </div>
        </div>

        {/* there are two type-containers. The left one is transparent, but exists to take up enough space to center the label-container */} 
        <div className={'type-container' + (props.typeLabel ? '' : ' blank')}>
            [{selectionTypeLabel}]
        </div>

        <div className={'type-container2' + (props.typeLabel ? '' : ' blank')}>
            [{selectionTypeLabel}]
        </div>

    </div>;
}
