import * as globals from '../globals';
import * as apiService from '../utils/apiService';
import { 
    CARDS_CARD_INSIGHTS_PATCH,
    CARDS_UPDATE_TEXTCARD,
    CARDS_CARD_RECEIVE,
    CARDS_CARD_REQUEST,
    CARDS_RECEIVE,
    CARDS_REQUEST,
    CARDS_SELECT,
    STUDY_RESET
    // @ts-ignore
} from './action-types';

export interface CardsState{

    dict: {
        [key: string]: any
    };

    loading?: boolean;

    loaded?: boolean;

    all?: any[];

    viewer?: any;

}

const initialState:CardsState = {
    dict: {}
};

export const actionCreators = {

    loadCard: (cardId: string, callback: any) => async (dispatch: any, getState: any) => {

        dispatch({ type: CARDS_CARD_REQUEST, cardId });

        const state = getState();
        let studyId = state.study.uid;

        try {
            
            const url = `${globals.apiRoot}/study/${studyId}/card/${cardId}`;
            const response = await apiService.aGet(state.auth, url);

            if( response.ok ){
                const card = await response.json();
                dispatch({ type: CARDS_CARD_RECEIVE, card });
                if( callback ) callback(card);
            }
            else{
                dispatch({ type: CARDS_CARD_RECEIVE, card: { uid: cardId, error: response.statusText } });
            }

        } catch (error) {
            console.log(error);
            dispatch({ type: CARDS_CARD_RECEIVE, card: { uid: cardId, error }});
        }

    },

    saveCard: (cardId: string, widget_uid: string, saveState: any, comment: any, tags: any, callback: any) =>
        async (dispatch: any, getState: any) => {

        const state = getState();
        const studyId = state.study.uid;

        const body = {
            uid: cardId,
            widget_uid,
            state: saveState,
            comment,
            tags
            };
            
        try {
            const url = `${globals.apiRoot}/study/${studyId}/card`;
            const response = cardId ? await apiService.aPatch(state.auth, `${url}/${cardId}`, body) :
                await apiService.aPost(state.auth, url, body);
            
            let _cardId = cardId;
            if( !_cardId ){
                const _r = await response.json();
                _cardId = _r.uid;
            }
            console.log(_cardId);

            if( callback ) callback(_cardId);

        } catch (err) {
            console.error('saveCard error', err);
        }

    },

    saveCardInsights: (cardId: string, insights: any, callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();
        const studyId = state.study.uid;

        const body = {
            comment: insights
        };

        try {
            const url = `${globals.apiRoot}/study/${studyId}/card/${cardId}/insights`;
            const response = await apiService.aPatch(state.auth, url, body);
            
            if( response.ok ){
                dispatch({ type: CARDS_CARD_INSIGHTS_PATCH, cardId, insights })
                if( callback ) callback();
            }
            else{
                console.error('saveCardInsights not ok', response.statusText);    
            }

        } catch (err) {
            console.error('saveCardInsights error', err);
        }

    },

    updateTextCard: (cardId: string, studyId: string, headlineText: string, contentText: string, callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();      
        

        const body = {
            theState: {
                headline: headlineText,
                content: contentText
            }
        };

        console.log('text card update', state, cardId, body)

        try {
            const url = `${globals.apiRoot}/study/${studyId}/card/${cardId}/textcards`;
            const response = await apiService.aPatch(state.auth, url, body);
            
            if( response.ok ){
                dispatch({ type: CARDS_UPDATE_TEXTCARD, cardId, body })
                if( callback ) callback();
            }
            else{
                console.error('saveCardInsights not ok', response.statusText);    
            }

        } catch (err) {
            console.error('saveCardInsights error', err);
        }

    },

    deleteCard: (cardId: string, callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();
        const studyId = state.study.uid;

        try {
            const url = `${globals.apiRoot}/study/${studyId}/card/${cardId}`;
            await apiService.aDelete(state.auth, url);
            
            // rob commented out 2020-04-08 since the loadCards function was commented out and I deleted it
            //actionCreators.loadCards(callback)(dispatch, getState);

        } catch (err) {
            console.error('deleteCard error', err);
        }

    },

    selectCard: (cardId: string, widgetId: string, state: any, editInsights: any) => (dispatch: any) => {
        dispatch({ type: CARDS_SELECT, cardId, widgetId, state, editInsights });
    },

    updateDataCardDetails: (card_uid: string, data: any, callback: any) => async (dispatch: any, getState: any) => {
        console.log('updateDataCardDetails', card_uid, data);
        const state = getState();

        const body = {
            //card_uid,
            ...data
        };

        if (card_uid) {
            try {
                const url = `${globals.apiRoot}/datacard/${card_uid}`;
                const response = await apiService.aPost(state.auth, url, body);

                if (response.ok) {

                    // todo: refresh the master repo
                    // let wRepo = getRepo();
                    // let entry = wRepo.find(entry => entry.uid === card_uid);
                    // console.log('todo: update entry: ', entry);

                    if( callback ){
                        callback();
                    }
                } else {
                    console.error('updateDataCardDetails failed', response.statusText);
                }
            } catch (err) {
                console.error('updateDataCardDetails error', err);
            }
        }

    },


};



export const reducer = (state:CardsState = initialState, action: any): CardsState => {

    switch (action.type) {

        case STUDY_RESET:
            return initialState;

        case CARDS_CARD_REQUEST: {
            return {
                ...state,
                dict: {
                    ...state.dict,
                    [action.cardId]: {
                        ...state.dict[action.cardId],
                        loading: true
                    }
                }
            }
        }
        case CARDS_CARD_RECEIVE: {
            
            return {
                ...state,
                dict: {
                    ...state.dict,
                    [action.card.uid]: action.card
                }
            }
        }
        case CARDS_CARD_INSIGHTS_PATCH : {
            const card = state.dict ? state.dict[action.cardId] : null;
            if( !card ) return state;

            return {
                ...state,
                dict: {
                    ...state.dict,
                    [action.cardId] : {
                        ...card,
                        comment: action.insights
                    }
                }
            }
        }
            case CARDS_UPDATE_TEXTCARD : {
            const card = state.dict ? state.dict[action.cardId] : null;
            if( !card ) return state;

            return {
                ...state,
                dict: {
                    ...state.dict,
                    [action.cardId] : {
                        ...card,
                        state: action.body
                    }
                }
            }
        }
        case CARDS_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case CARDS_RECEIVE: {
            return {
                ...state,
                loading: false,
                loaded: true,
                all: action.value
            }
        }
        case CARDS_SELECT: {
            return {
                ...state,
                viewer: {
                    cardId: action.cardId,
                    widgetId: action.widgetId,
                    state: action.state,
                    editInsights: action.editInsights
                }
            }
        }
        default:
            return state;
    }

};
