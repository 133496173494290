import * as d3 from 'd3';
import { format as d3format } from 'd3-format';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as globals from '../../globals';
//import { actionCreators } from '../../store/BigData';
import * as cacheHelper from '../../utils/cacheHelper';
import { CardLoading } from '../card/CardLoading';
import { FilterChooser } from '../filter/FilterChooser';

const defaultValueFormat = (val) => d3format('.2')(val);


// https://github.com/jasondavies/newick.js
function parseNewick(a){for(var e=[],r={},s=a.split(/\s*(;|\(|\)|,|:)\s*/),t=0;t<s.length;t++){var n=s[t];switch(n){case"(":var c={};r.branchset=[c],e.push(r),r=c;break;case",":var c={};e[e.length-1].branchset.push(c),r=c;break;case")":r=e.pop();break;case":":break;default:var h=s[t-1];")"==h||"("==h||","==h?r.name=n:":"==h&&(r.length=parseFloat(n))}}return r}

const width = 964;
const outerRadius = width / 2;
const innerRadius = outerRadius - 200;

function addName(node, name){
    return {
      ...node,
      name: node.name || name,
      branchset: node.branchset ? node.branchset.map((n, idx) => addName(n, `${name},${idx}`)) : undefined
    }
  }

function maxLength(d) {
    return d.data.length + (d.children ? d3.max(d.children, maxLength) : 0);
}

// does something
const cluster = d3.cluster()
    .size([360, innerRadius])
    .separation((a, b) => 1)

const color = d3.scaleOrdinal()
    //.domain(["Bacteria", "Eukaryota", "Archaea"])
    //.domain(["a", "b", "c", "d"])
    .domain(["0,0,0", "0,0,1", "0,1,0", "0,1,1"])
    .range(d3.schemeCategory10)


// Set the radius of each node by recursively summing and scaling the distance from the root.
const setRadius = (d, y0, k) => {
    d.radius = (y0 += d.data.length) * k;
    if (d.children) d.children.forEach(d => setRadius(d, y0, k));
}

// Set the color of each node by recursively inheriting.
function setColor(d) {
    var name = d.data.name;
    d.color = color.domain().indexOf(name) >= 0 ? color(name) : d.parent ? d.parent.color : null;
    if (d.children) d.children.forEach(setColor);
}

function linkVariable(d) {
    return linkStep(d.source.x, d.source.radius, d.target.x, d.target.radius);
}

function linkConstant(d) {
    return linkStep(d.source.x, d.source.y, d.target.x, d.target.y);
}

function linkExtensionVariable(d) {
    return linkStep(d.target.x, d.target.radius, d.target.x, innerRadius);
}

function linkExtensionConstant(d) {
    return linkStep(d.target.x, d.target.y, d.target.x, innerRadius);
}

function linkStep(startAngle, startRadius, endAngle, endRadius) {
    const c0 = Math.cos(startAngle = (startAngle - 90) / 180 * Math.PI);
    const s0 = Math.sin(startAngle);
    const c1 = Math.cos(endAngle = (endAngle - 90) / 180 * Math.PI);
    const s1 = Math.sin(endAngle);
    return "M" + startRadius * c0 + "," + startRadius * s0
    + (endAngle === startAngle ? "" : "A" + startRadius + "," + startRadius + " 0 0 " + (endAngle > startAngle ? 1 : 0) + " " + startRadius * c1 + "," + startRadius * s1)
    + "L" + endRadius * c1 + "," + endRadius * s1;
}

const raw_data = parseNewick("(((((((Jeep Renegade:41.36,Jeep Compass:41.36):7.25,Jeep Wrangler:48.62):10.47,(Jeep Grand Cherokee:37.09,Jeep Cherokee:37.09):22.00):23.45,((Ford Escape:49.25,Ford Edge:49.25):5.42,(Ford Explorer:47.91,Ford Expedition:47.91):6.77):27.87):9.85,(((((GMC Yukon:38.97,Chevrolet Tahoe:38.97):5.44,Chevrolet Suburban:44.42):6.59,Dodge Durango:51.01):9.77,(Chevrolet Traverse:47.60,Chevrolet Equinox:47.60):13.18):6.97,(((GMC Terrain:41.19,GMC Acadia:41.19):7.73,Cadillac XT5:48.93):5.95,(Buick Encore:35.72,Buick Enclave:35.72):19.16):12.87):24.64):16.41,(((((Toyota Highlander:42.32,Toyota 4Runner:42.32):11.04,Honda Pilot:53.36):6.06,((Nissan Rogue:43.65,Nissan Murano:43.65):4.70,Nissan Pathfinder:48.35):11.07):8.79,(Toyota RAV4:53.97,Honda CR-V:53.97):14.24):7.90,((Subaru Outback:38.44,Subaru Forester:38.44):12.80,Subaru CrossTrek:51.24):24.87):32.68):49.17,((((((Ford EcoSport:48.36,Chevrolet Trax:48.36):1.32,(Mitsubishi Outlander:47.98,Dodge Journey:47.98):1.70):5.97,((Volkswagen Tiguan:43.62,Volkswagen Atlas:43.62):5.57,Hyundai Kona:49.19):6.46):9.91,((Toyota C-HR:48.39,Honda HR-V:48.39):5.98,((Mazda CX-9:34.32,Mazda CX-5:34.32):1.32,Mazda CX-3:35.64):18.74):11.19):5.51,((Hyundai Tucson:42.27,Hyundai Santa Fe:42.27):14.81,(Kia Sportage:37.74,Kia Sorento:37.74):19.35):13.98):34.22,((((((Audi Q7:30.59,Audi Q5:30.59):10.94,Infiniti QX60:41.54):0.75,(BMW X5:32.25,BMW X3:32.25):10.04):3.81,(Mercedes Benz GLE:30.12,Mercedes-Benz GLC-Class:30.12):15.98):7.43,(Acura RDX:35.03,Acura MDX:35.03):18.50):3.02,(((Lexus UX:33.85,Lexus NX:33.85):2.29,Lexus GX:36.14):4.33,Lexus RX:40.47):16.08):48.74):52.69);")
//const raw_data = parseNewick("(((((((Path of Exile[3]:82.43,Super Smash Bros.[3]:82.43):4.24,(Tom Clancy's Rainbow Six Siege[3]:81.83,Final Fantasy[3]:81.83):4.85):8.11,(Tomb Raider[3]:85.23,Street Fighter[3]:85.23):9.55):11.36,(((Cyberpunk[3]:80.35,The Legend of Zelda[3]:80.35):6.65,((Dungeon Fighter Online[3]:73.97,Blade & Soul[3]:73.97):6.23,CrossFire[3]:80.20):6.80):3.79,((League of Legends[3]:79.87,Borderlands [3]:79.87):5.58,(FIFA Online[3]:78.49,Hearthstone[3]:78.49):6.96):5.34):15.36):6.49,(((Middle-earth[1]:82.44,Madden NFL[1]:82.44):6.35,(Monster Hunter[1]:80.21,Just Dance[1]:80.21):8.59):2.16,(((Diablo[1]:77.66,Devil May Cry[1]:77.66):4.24,Grand Theft Auto[1]:81.90):3.71,(Sekiro: Shadows Die Twice[1]:78.72,Rocket League[1]:78.72):6.89):5.34):21.69):21.76,((((((God of War[2]:77.32,Forza[2]:77.32):3.09,Red Dead Redemption[2]:80.41):2.62,Spider-man[2]:83.03):8.75,Fallout[2]:91.78):6.72,((NBA2K[2]:82.70,Mortal Kombat[2]:82.70):2.34,Resident Evil[2]:85.04):13.46):9.95,(((The Sims[4]:79.89,The Outer Worlds[4]:79.89):1.23,Fortnite[4]:81.12):4.70,(Tom Clancy's Ghost Recon Breakpoint[4]:78.59,Star Wars Jedi: Fallen Order[4]:78.59):7.23):22.63):25.95):24.35,(((((Kingdom Hearts[0]:79.71,Elder Scrolls [0]:79.71):2.66,(Fantasy Western Journey[0]:74.33,Dota 2[0]:74.33):8.04):1.80,(Assassin's Creed[0]:77.10,Apex Legends[0]:77.10):7.07):1.20,((Destiny[0]:74.46,Call of Duty[0]:74.46):4.00,(Battlefield[0]:72.48,Batman: Arkham [0]:72.48):5.99):6.91):5.40,((The Avengers[0]:75.03,Overwatch[0]:75.03):3.69,(PlayerUnknown's Battlegrounds[0]:73.82,Minecraft[0]:73.82):4.90):12.05):67.99);");
//const raw_data2 = addName(raw_data, '0');




export class TreeOfLifeWidget extends React.Component{

    constructor(props){
        super(props);
        this.state = {
        };

        // Allow card to be saved as insight card. Not the greatest pattern.
        this.props.stateContext.getSaveState = this.getSaveState;
    }



    componentDidMount(){

        const raw_data3 = this.props.newickData ? parseNewick(this.props.newickData) : null || raw_data;
        const raw_data2 = addName(raw_data3, '0');

        this.createChart(raw_data2);
        this.update(true);
        if (this.props.getJSONData && typeof this.props.getJSONData === 'function')
            this.props.getJSONData(null, true)
        if (this.props.loaded && typeof this.props.loaded === 'function')
            this.props.loaded(true)

        // if( this.props.cache && this.props.cache.newick_tree ){

        //     //this.createChart(raw_data2);
        //     this.createChart(addName({ ...parseNewick(this.props.cache.newick_tree) },'0'));
        //     this.update(true);
        // }
    }

    componentDidUpdate(){
        if (this.props.cache && !this.props.cache.loading){
            if( this.props.loaded && typeof this.props.loaded === 'function')
                this.props.loaded(!this.props.cache.loading)
        }
        if( !this.created ){
            if( this.props.cache && this.props.cache.newick_tree ){
                //this.createChart(raw_data2);
                //this.createChart(addName(parseNewick(this.props.cache.newick_tree),'0'));
                //this.update(true);
            }
        }
    }

    created = false;

    static inheritOptions = ['option'];

    static getTitle = (state) => {
        return state.title ? `${state.title}` : null;
    }

    static getSources = (state) => (state.sources || []);//.map(src => ({ label: src }));

    static getComputeDetails = (state) => ({
        type: 'bigData',
        path: `${state.path}`
    });

    getSaveState = () => {

        return {
            option: this.props.option,
            cache: this.props.cache
        };

    }

    node;

    createChart = (data) => {

        this.created = true;

        const root = d3.hierarchy(data, d => d.branchset)
            .sum(d => d.branchset ? 0 : 1)
            .sort((a, b) => (a.value - b.value) || d3.ascending(a.data.length, b.data.length));
        this.root = root;
      
        cluster(root);
        setRadius(root, root.data.length = 0, innerRadius / maxLength(root));
        setColor(root);
      
        const svg = d3.select(this.node) //DOM.svg(width, width))
            .style("width", "100%")
            .style("height", "auto")
            .style("font", "20px sans-serif")
            .attr("viewBox", [-outerRadius, -outerRadius, width, width]);
      
        //svg.append("g")
        //  .call(legend);
      
        svg.append("style").text(`
      
            .link--active {
                stroke: #000 !important;
                stroke-width: 1.5px;
            }
            
            .link-extension--active {
                stroke-opacity: .6;
            }
            
            .label--active {
                font-weight: bold;
            }
            
        `);
      
        this.linkExtension = svg.append("g")
            .attr("fill", "none")
            .attr("stroke", "#000")
            .attr("stroke-opacity", 0.25)
          .selectAll("path")
          .data(root.links().filter(d => !d.target.children))
          .join("path")
            .each(function(d) { d.target.linkExtensionNode = this; })
            .attr("d", linkExtensionConstant);
      
        this.link = svg.append("g")
            .attr("fill", "none")
            .attr("stroke", "#000")
          .selectAll("path")
          .data(root.links())
          .join("path")
            .each(function(d) { d.target.linkNode = this; })
            .attr("d", linkConstant)
            .attr("stroke", d => d.target.color);
      
        svg.append("g")
          .selectAll("text")
          .data(root.leaves())
          .join("text")
            .attr("dy", ".31em")
            .attr("transform", d => `rotate(${d.x - 90}) translate(${innerRadius + 4},0)${d.x < 180 ? "" : " rotate(180)"}`)
            .attr("text-anchor", d => d.x < 180 ? "start" : "end")
            .text(d => d.data.name.replace(/_/g, " "))
            .on("mouseover", this.mouseovered(true))
            .on("mouseout", this.mouseovered(false));
      
        
        //return Object.assign(svg.node(), {update});
        
    }

    update = (checked) => {
        const t = d3.transition().duration(750);
        this.linkExtension.transition(t).attr("d", checked ? linkExtensionVariable : linkExtensionConstant);
        this.link.transition(t).attr("d", checked ? linkVariable : linkConstant);
    }
    
    mouseovered = (active) => {
        return function(d) {
            d3.select(this).classed("label--active", active);
            d3.select(d.linkExtensionNode).classed("link-extension--active", active).raise();
            do d3.select(d.linkNode).classed("link--active", active).raise();
            while (d = d.parent);
        };
    }
    


    render() {

        const cache = this.props.cache;
        const props = this.props;
        
        
        const content = !cache ? null :
            cache.loading ? null :
            <div style={{ fontFamily: "consolas 'courier new' monospace"  }}>
            </div>;


        return <div className='widget'>
            <div className='widget-header'>
                <div className='title'>
                    {this.props.title || 'Tree of Life'}
                </div>
                <div className='filter-etc'>
                <FilterChooser mini={true} disabled={true} selection={{ label: globals.bigDataFilterLabel }}/>
                </div>
            </div>
            <div className='widget-body'>
                <svg
                    ref={node => {
                        (this.node = node)
                        // if( this.node ){
                        //     Object.assign(this.node, { update: this.update });
                        // }
                    }
                    }
                    style={{ 
                        //border: '1px solid red',
                        width: width,
                        height: width,
                        maxWidth: '100%',
                        maxHeight: '100%'
                    }}
                    //update={this.update}
                    //viewBox={'0 0 ' + this.width + ' ' + this.width}
                    preserveAspectRatio="xMidYMid meet"
                />
            </div>
            <CardLoading loading={cache && cache.loading} />
        </div>;

    }

   

}

TreeOfLifeWidget = connect(
    (state, ownProps) => ({
        cache: cacheHelper.getCache(state.bigData, `${ownProps.path}`)
    })
    //,dispatch => bindActionCreators(actionCreators, dispatch)
)(TreeOfLifeWidget)