import * as React from 'react';
import Textarea from 'react-expanding-textarea';
import * as debounce from '../../utils/debounce';
import { Markdown } from '../common/Markdown';
import './ExecutiveSummary.scss';

export class ExecutiveSummary extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            headline: props.headline,
            content: props.content
        }
    }

    static inheritOptions = ['selection'];

    handleHeadlineChange = (ev) => {
        this.setState({ headline: ev.target.value }, () => {
            this.triggerCallback();
        });
    }

    handleContentChange = (ev) => {
        this.setState({ content: ev.target.value }, () => {
            this.triggerCallback();
        });
    }

    triggerCallback = debounce.debounce(() => {
        if( this.props.onChange ){
            this.props.onChange(this.state);
        }
    }, debounce.insightsTextDelay);

    render() {

        const editMode = this.props.editMode;

        return <div className='widget executive-summary-widget'>
            <div className='widget-body'>
                <div className='headline'>
                    {editMode ? 
                        <Textarea className='headline-input' 
                            placeholder='Type headline here'
                            value={this.state.headline || ''}
                            onChange={this.handleHeadlineChange}
                        />
                        //: this.props.headline
                        : <Markdown data={this.props.headline}/>
                    }
                </div>
                <div className='doc'>
                    {editMode ?
                        <Textarea className='content-input'
                            placeholder='Type summary here'
                            value={this.state.content || ''}
                            onChange={this.handleContentChange}
                        />
                        //: this.props.content
                        : <Markdown data={this.props.content}/>
                    }
                </div>
            </div>
        </div>

    }

}

