import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
//import { actionCreators as bigDataActionCreators } from '../../store/BigData';
import { actionCreators as configActionCreators } from '../../store/Config.ts';
import { actionCreators as exploreActionCreators } from '../../store/Explore.ts';
import { BreadCrumb } from '../common/BreadCrumb';
import { PageHeader2 } from '../common/PageHeader';
import { WhatIf } from '../config/WhatIf';
import { Explore } from '../explore/Explore';
import { Optimize } from '../optimize/Optimize';
import { ProductBlock } from '../product-drawer/ProductBlock';
import { questionMenu } from '../topbar/HelpPane';
import './QuestionPage.scss';



//const getRepo = () => widgetRepo.filter(entry => entry.type === 'api');


export class QuestionPage extends React.Component {

    constructor(props){
        super(props);

        this.state = {
        }

    }

    goRoot = () => {
        let study = this.props.study;
        this.props.history.push(`/study/${study.uid}/explore`)
    }

    flatten = (root, arr = []) => {
        arr.push(root);
        if( root.children ){
            root.children.forEach(ch => {
                this.flatten(ch, arr);
            });
        }
        return arr;
    }
    
    render() {

        let study = this.props.study;
        if( !study ) return null;

        const questionId = this.props.match.params.qid;

        
        
        const question = this.flatten(questionMenu).find(q => q.key === questionId);
        if( !question ) 
            return "error: question not found";

        //console.log(this.props);

        if( !study.config ) return null;
        const selection = study.config.selectedItem2;

        let breadCrumbElements = [
        ];

        breadCrumbElements.push({ label: 'Explore', link: `/study/${study.uid}/explore` });
        
        let area = this.props.match.params.area;
        if( area ){
            breadCrumbElements.push({ label: area, link: `/study/${study.uid}/explore/area/${area}` });
        }
        breadCrumbElements.push({ label: 'Question' });
        
        //dont delete
    // <PageHeader2 title={ <BreadCrumb elements={breadCrumbElements}/> }  fullWidth={true}/>

        return <div className="page question-page">
            <div className='content'>
                <div className='header'>
                    <div className='buttons'>
                        <div className='question-text'>
                            {question.label}
                        </div>
                        {question.instruction ?
                        <div className='question-instruction'>
                            {question.instruction}
                        </div> : null}

                        {question.action === 'outcomes' ?
                            <div className='' style={{marginTop: 10, maxWidth: 400 }}>
                                <ProductBlock studyId={study.uid} {...selection}
                                    standalone={true}
                                    productsOnly={true}
                                    allow={['product']}
                                    style={{ width: '100%' }}
                                    onClick={() => 
                                        window.topBar.openProducts(selection, 
                                            (sel) => {
                                                this.props.set('selectedItem2', sel)
                                                window.topBar.hideMenu();
                                            },
                                            {
                                                allow: ['product']
                                            }
                                        )
                                    }
                                />
                            </div>
                            : null
                        }
                    </div>

                </div>

                <div className='body'>

                    {question.action === 'edit' ? 
                        <WhatIf noHeader={true}/> : null
                    }
                    {
                        question.action === 'outcomes' ? 
                        <WhatIf noHeader={true} noEditor={true} selectedTag={question.tag}/> : null
                    }
                    {
                        question.action === 'optimize' ?
                        <Optimize noHeader={true}/> : null
                    }
                    {
                        question.action === 'explore' ?
                        <Explore mini={true} miniFilter={question.tag} /> : null
                    }
                    
                </div>
            
                
            </div>

        </div>;


    }

}

QuestionPage = withRouter(
    connect(
        state => state,
        dispatch => bindActionCreators({...exploreActionCreators, ...configActionCreators}, dispatch)
    )(QuestionPage)
);



