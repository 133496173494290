import { format as d3Format } from 'd3-format';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as globals from '../../globals';
import { actionCreators as rsActionCreators } from '../../store/ResearchScenarios.ts';
import { createBaseCaseScenario } from '../scenarios/ResearchScenarios';
import { AttViewer } from './AttViewer';
import './OptRunScenario.scss';

const beqFormat = d3Format('.2f');

export class OptRunScenario extends React.Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){
        this.checkData();
    }

    componentDidUpdate(){
        //this.checkData();
    }

    checkData = () => {
        if( !this.props.optRun ){
            return;
        }

        if( this.props.scenarioKey === 'basecase' )
            return;

        const scenario = this.props.scenario;
        if( !scenario ){
            this.props.loadOptRunScenario(this.props.runId, this.props.scenarioKey);
        }
        else{
            if( !scenario.config ){
                this.props.loadOptRunScenario(this.props.runId, this.props.scenarioKey);
            }
        }

    }

    navigateToScenario = () => {
        this.props.loadOptRunScenario(this.props.runId, this.props.scenarioKey, true, () => {
            const studyId = this.props.study.uid;
            this.props.history.push(`/study/${studyId}/what-if`);
        });
    }

    render() {

        const study = this.props.study;
        if( !study ) return null;
        if( !study.config ) return null;

        const scenario = this.props.scenario;
        if( !scenario ) return null;

        const smetrics = scenario.cache ? scenario.cache.metrics : null;

        //console.log(scenario);

        const sConfig = scenario.config;
        let productAtts;
        let priceAtts;

        {
            const def = study.config.productDefs.default;
            productAtts = def.atts.filter(
                att => !att.readOnly && (!att.area || att.area === 'product')
            );
            priceAtts = def.atts.filter(
                att => !att.readOnly && att.area === 'price'
            )
        }

        let brandEntries;
        let messageEntries;
        {
            const level = study.config.brandEquitiesDef.level;
            const entries = study.config.brandEquitiesDef.entries;
            brandEntries = entries.filter(e => e.area === 'brand');
            messageEntries = entries.filter(e => e.area === 'message');
        }
        
        let product_ids = this.props.products || [];
        let products = sConfig && sConfig.productSlots ? product_ids.map(id => ({ ...sConfig.productSlots[id], uid: id })) : [];

        const bLevel = study && study.config && study.config.brandEquitiesDef ? study.config.brandEquitiesDef.level : null;

        const brandEquities = sConfig ? sConfig.brandEquities : null;
        const brandEquityItemKeys = brandEquities && brandEquities.data ? Object.keys(brandEquities.data).filter(key => brandEquities.data[key].__opt) : null;
        const brandEquityItems = brandEquityItemKeys ? brandEquityItemKeys.map(key => {

            // find first matching product at level

            let proto = null;
            if( brandEquities.level === 'product' ){
                proto = products.find(product => product[brandEquities.level] == key)
            }
            else{
                const att = study.config.productDefs.default.atts.find(a => a.name === brandEquities.level);
                const levels = (att || {}).levels || [];
                proto = levels.find(l => l.value == key);
            }


            return {
                key: key,
                label: proto ? proto.label : 'missing proto',
                data: brandEquities.data[key]
            }
        }) : null || [];
        
        let brandEquitySections = [
            {
                label: "Message: Emotion",
                area: "message",
                category: "Emotion"
            },
            {
                label: "Message: Core Motive",
                area: "message",
                category: "Core Motive"
            },
            {
                label: "Brand Promise",
                area: "brand",
                category: "Brand Promise"
            },
        ];
        brandEquitySections = brandEquitySections.map(s => ({
            ...s,
            entries: study.config.brandEquitiesDef.entries.filter(e => e.area === s.area && e.category === s.category)
        }));
        

        

        return <div className='optrun-scenario'>
       
            <div className='scenario-label'>
                {scenario.label}
                {scenario.key !== 'basecase' ?
                <span className='load-button' onClick={this.navigateToScenario}>
                    {/* <i className='fal fa-location-arrow'/> */}
                    load...
                </span> : null}
            </div>
            <div className='heading'>
                Target Output Metrics
            </div>
            <div className='metrics'>
                {globals.metrics.map(metric => {
                    const val = smetrics && smetrics[metric.key] ? smetrics[metric.key]['{target}'] : null;
                    return <div className='metric' key={metric.key}>
                        <div className='label'>{metric.label}</div>
                        <div className='value'>{val ? metric.fformat(val) : '.'}</div>
                    </div>
                })}
            </div>
            {products.length === 0 ? <div className='section'>
                loading scenario...
            </div> : null}
            {products.map(product => <React.Fragment key={product.uid}>
                <div className='heading product-label'>
                    {product.label}
                </div>
                <div className='heading layer2'>
                    Product Configuration
                </div>
                {productAtts ?
                    <div className='section'>
                        {productAtts.map(att => <AttViewer att={att} slot={product} key={att.name}/>)}
                    </div>
                    : null
                }
                <div className='heading layer2'>
                    Price Configuration
                </div>
                {priceAtts ?
                    <div className='section'>
                        {priceAtts.map(att => <AttViewer att={att} slot={product} key={att.name}/>)}
                    </div>
                    : null
                }
            </React.Fragment>
            )}
            {
                brandEquityItems.map(item => <React.Fragment key={'be:' + item.key}>
                    <div className='heading product-label'>
                        {item.label}
                    </div>
                    {
                        brandEquitySections.map(section => <React.Fragment key={section.label}>
                            <div className='heading layer2'>
                                {section.label}
                            </div>
                            <div className='section'>
                            {
                                section.entries.map(entry => <div key={entry.name} className='brand-equity'>
                                    <span className='label'>{entry.label}</span>
                                    <span className='value'>{beqFormat(item.data[entry.name])}</span>
                                    
                                </div>)
                            }
                            </div>
                        </React.Fragment>)
                    }
                    
                </React.Fragment>)
            }
            
        </div>;

    }
}



OptRunScenario = withRouter(
    connect(
        (state, ownProps) => {
            const runId = ownProps.runId;

            const rs = state.researchScenarios || {};
            const optRuns = rs.optRuns || {};
            const optRun = optRuns[runId];
            
            const output = (optRun || {}).output;
            const scenarios = output.scenarios || [];
            const scenario = ownProps.scenarioKey === 'basecase' ? 
                createBaseCaseScenario(state.study ? state.study.config : null, state.cache, ownProps.goal)
                : scenarios.find(s => s.key === ownProps.scenarioKey);

            return {
                study: state.study,
                optRun,
                scenario
            }
            
        },
        dispatch => bindActionCreators(rsActionCreators, dispatch)
    )(OptRunScenario)
);
