import { format as d3Format } from 'd3-format';
import * as React from 'react';
import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import * as globals from '../../globals';
import { CoreWidget1 } from './CoreWidget1';
import CustomizedTick from "../common/CustomizedTick";

const format = d3Format('.1%');

export const FunnelWidget = (props) => {

    const renderContent = (cache, getJSONData) => {

        if( !cache ) return null;

        const data = [
            { value: cache.aided_awareness, label: "Awareness (aided)" },
            { value: cache.aided_interest, label: "Interest" },
            { value: cache.aided_consider, label: "Consideration" },
            { value: cache.aided_intent, label: "Intent" },
            { value: cache.purchases, label: "Purchase" },
            { value: cache.aided_loyalty, label: "Loyalty" },
            { value: cache.aided_advocacy, label: "Advocacy" },
        ]
            // divide by 100
            .map(el => ({
                ...el,
                value: el.value ? el.value / 100.0 : null
            }));
    
        if (getJSONData && typeof getJSONData === 'function')
            getJSONData(data);
        return <ResponsiveContainer width='100%' height='100%'>
            <BarChart data={data} layout="vertical">
                <XAxis dataKey="value" type="number" tickFormatter={format}
                    domain={['auto', dataMax => (dataMax * 1.2)]}
                />
                <YAxis dataKey="label" type='category' width={125} tick={<CustomizedTick />}/>
                <Tooltip formatter={format} />
                <Bar isAnimationActive={false} dataKey="value" fill={globals.colors.primary1} >
                    <LabelList dataKey="value" position="right" formatter={format}/>
                </Bar>
            </BarChart>
        </ResponsiveContainer>;
    }

    return <CoreWidget1 {...props} useFilter={true} useSelection={true} useScenario={true} render={renderContent} useAux={{ path: "funnel" }}/>
}
