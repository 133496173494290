import * as utf8 from 'utf8';
import * as base64 from 'base-64';
import * as global from '../globals';
import * as apiService from '../utils/apiService';
import { RootState } from './RootState';
import { InteractionRequiredAuthError } from "@azure/msal-browser";

import { 
    AUTH_REQUEST_TOKEN, 
    AUTH_RENEW_TOKEN, 
    AUTH_CLEAR_TOKEN, 
    AUTH_CLEAR_MESSAGES, 
    AUTH_STORE_TOKEN, 
    AUTH_LOGOUT,
    AUTH_REQUEST_RESET,
    AUTH_STORE_SCROLL_POS,
    AUTH_UPDATE_SCROLL_POS
    // @ts-ignore
} from './action-types';

export interface AuthState {

    auth_fetch_status: any;

    token: string;

    // tokenExpires: string;

    studies: AuthStudyState[];

    user: AuthUserState;

    scrollPos: any[]; // remove me!

}

interface AuthUserState {

    id: string;

    name: string;

    company: string;

    userFilter: string; // not used

    accountId: number;

    isAccountAdmin: boolean;

    isSuperAdmin: boolean;
    
}

interface AuthStudyState {

    uid: string;

    label: string;

    groupLabel: string;

    isStudyAdmin: boolean;

    channels: AuthStudyChannelState[];

    studySpecificItems: any;

}

interface AuthStudyChannelState{

    key: string;

    label: string;

    read: boolean;

    write: boolean;

}



const initialState: AuthState = {
    auth_fetch_status: {
        fetching: false,
        fetchMessage: null,
        errorMessage: null,
        successMessage: null
    },

    token: '',
    // tokenExpires: '',
    studies: [],
    user: {
        id: '',
        name: '', 
        company: '', 
        userFilter: '', 
        accountId: -1,
        isAccountAdmin: false,
        isSuperAdmin: false

    },
    scrollPos: []
};




export const actionCreators = {    

    changePassword: (body: any, callback: any) => async (dispatch: any, getState: any) => {

        const state:RootState = getState();

        try {

            const url = `${global.apiRoot}/account/change-password`;
            const response = await apiService.aPost(state.auth, url, body);

            if( response.ok ){
                const res = await response.json();
                if( callback ) callback(res, false);
            }
            else{
                console.log(response);
                if( callback ) callback(null, true);
            }

        } catch (error) {
            console.log(error);
        }

    },

    uploadAvatar: (data: any, callback: any) => async (dispatch: any, getState: any) => {

        const state:RootState = getState();

        try {
            
            const url = `${global.appRoot}/avatar`;
            const response = await fetch(url, {
                method: 'POST',
                body: data,
                headers: {
                    Authorization: 'Bearer ' + state.auth.token
                }
            });

            if( response.ok ){
                const res = await response.json();
                if( callback ) callback(res, false);
            }
            else{
                console.log('error => ', response.statusText);
                if( callback ) callback(null, true);
            }

        } catch (error) {
            console.log(error);
        }

    },

    _storeToken: (src: any) => ({        
        token: src.access_token,
        // tokenExpires: src.expires,
        studies: src.studies,
        user: src.user,
        webclient_version: src.webclient_version
    }),

    clearMessages: () => async (dispatch: any, __: any) =>
        dispatch({ type: AUTH_CLEAR_MESSAGES }),

    _stripTemp: (auth:any) => ({
        ...auth,
        auth_fetch_status: undefined
    }),

    /** renew authentication token (gets a new token using a non-expired token).
     *  stores in local storage and dispatches storage into state. 
     */
    // renewAuthToken: () => async (dispatch: any, getState: any) => {
        
    //     var state:RootState = getState();
    //     var auth: any = { ...initialState };

    //     try {
    //         const response = await apiService.aGet(state.auth, `${global.apiRoot}/auth/renew-token`);

    //         const json = await response.json();
    //         if (json.authorized) {
    //             auth = { ...auth, ...actionCreators._storeToken(json) };
    //         } else {
    //             auth.temp.errorMessage = json.message || json;
    //         }
    //     } catch (reason) {
    //         auth.temp.errorMessage = reason == 'TypeError: Failed to fetch' ? 'You have been logged out due to inactivity' : `unexpected error : ${reason}`;
    //         console.log(reason)
    //     }

    //     localStorage.setItem(
    //         'auth',
    //         JSON.stringify(actionCreators._stripTemp(auth))
    //     );

    //     dispatch({ type: AUTH_STORE_TOKEN, auth });
    // },

    /** request a new authentication token using username, password.
     *  stores in local storage and dispatches storage into state. 
     */
    requestAuthToken: ( msalInstance: any) => async (dispatch: any, getState: any) => {
        // var bytes: any = utf8.encode(username + ':' + password);
        // var basicstr: any = base64.encode(bytes);

        var auth: any = { ...initialState };
        dispatch({ type: AUTH_REQUEST_TOKEN });

        try {

            // 1. Login with Azure AD using MSAL
            const loginResponse = await msalInstance.loginPopup();
            console.log('loginResponse', loginResponse.account);
            const accessTokenResponse = await msalInstance.acquireTokenSilent({
                scopes: ["User.Read", "email","openid"],
                account: loginResponse.account
            });

            // 2. Send this token to the backend for validation         
            const response = await fetch(
                `${global.apiRoot}/auth/token?grant_type=client_credentials&scope=`,
                {
                    method: 'get',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessTokenResponse.accessToken}`,
                        'userId-token': loginResponse.idToken
                    }
                }
            )

            const json = await response.json()
            // console.log('auth json', json);
            if (json.authorized) {
                auth = { 
                    ...auth,
                    ...actionCreators._storeToken(json) 
                }
            }
            else {
                auth.auth_fetch_status.errorMessage = json.message || json
            }
        }
        catch (reason) {
                if (reason instanceof InteractionRequiredAuthError) {
                    auth.auth_fetch_status.errorMessage ='User is NOT valid, if this is a mistake contact Blue Owl'
                } 
                else {
                    auth.auth_fetch_status.errorMessage = 'unexpected error: ' + reason;                    
                }
            }
        

        //user login succeeded, store in localstorage and save to redux store
        localStorage.setItem('auth', JSON.stringify(actionCreators._stripTemp(auth)) );

        dispatch({ type: AUTH_STORE_TOKEN, auth });
    },

    logoutUser: () => async (dispatch: any) => {
        // Remove the authentication data from localStorage
        localStorage.removeItem('auth')
        var auth: any = {
            ...initialState,
            user: null,
            token: null
        }

        // Update the Redux store to reflect the logged-out state
        dispatch({ type: AUTH_LOGOUT, auth: auth })        
    },

    // requestAuthTokenExpire: (username: string, password: string, utc: string) => async (dispatch: any, getState: any) => {
        
    //     var bytes: any = utf8.encode(username + ':' + password);
    //     var basicstr: any = base64.encode(bytes);

    //     var auth: any = { ...initialState };

    //     try {
    //         dispatch({ type: AUTH_REQUEST_TOKEN });
            
    //         const response = await fetch(
    //             `${global.apiRoot}/auth/token?grant_type=client_credentials&scope=`,
    //             {
    //                 method: 'get',
    //                 headers: {
    //                     Accept: 'application/json',
    //                     'Content-Type': 'application/json',
    //                     Authorization: 'Basic ' + basicstr
    //                 }
    //             }
    //         );

    //         const json = await response.json();
    //         if (json.authorized) {
    //             auth = { 
    //                 ...auth,
    //                 ...actionCreators._storeToken(json),
    //                 tokenExpires: utc
    //             };
                
    //         } else {
    //             auth.temp.errorMessage = json.message || json;
    //         }
    //     } catch (reason) {
    //         auth.temp.errorMessage = 'unexpected error: ' + reason;
    //     }

    //     localStorage.setItem(
    //         'auth',
    //         JSON.stringify(actionCreators._stripTemp(auth))
    //     );
    //     // console.log('auth auth', auth)
    //     dispatch({ type: AUTH_STORE_TOKEN, auth });
    // },

    /** remove token from local storage and dispatch removal from state.  */
    // clearAuthToken: () => async (dispatch: any, getState: any) => {

    //     dispatch({ type: AUTH_CLEAR_TOKEN });

    //     localStorage.removeItem('auth');

    //     dispatch({ type: AUTH_STORE_TOKEN, auth: { ...initialState } });
    // },



    signUp: (
        email: string,
        name: string,
        company: string,
        studycode: string,
        g_recaptcha_response: string) => async (dispatch: any, getState: any) => {

        dispatch({
            type: AUTH_STORE_TOKEN,
            auth: {
                ...initialState,
                auth_fetch_status: {
                    ...initialState.auth_fetch_status,
                    fetching: true,
                    fetchMessage: 'Please wait: submitting'
                }
            }
        });

        var auth = { ...initialState };

        try {
            apiService.aPost(null, `${global.apiRoot}/account/create-account`, {
                email,
                name,
                company,
                studycode,
                g_recaptcha_response
            })
                .then(response => response.json())
                .then(json => {
                    auth.auth_fetch_status.successMessage = json.success ? json.message : null;
                    auth.auth_fetch_status.errorMessage = json.success ? null : json.errorMessage;
                });
        } catch (reason) {
            auth.auth_fetch_status.errorMessage = reason;
        }

        dispatch({ type: AUTH_STORE_TOKEN, auth });
    }
};

export const reducer = (state: AuthState = initialState, action: any): AuthState => {
    
    switch (action.type) {
        case AUTH_RENEW_TOKEN :
            return {
                ...state,
                auth_fetch_status: {
                    fetching: true,
                    fetchMessage: 'renewing auth token'
                }
            };

        case AUTH_REQUEST_TOKEN :
            return {
                ...state,
                auth_fetch_status: {
                    fetching: true,
                    fetchMessage: 'requesting access'
                }
            };

        case AUTH_CLEAR_TOKEN :
            return {
                ...state,
                auth_fetch_status: {
                    fetching: true,
                    fetchMessage: 'clearing auth token'
                }
            };

        case AUTH_CLEAR_MESSAGES:
            return {
                ...state,
                auth_fetch_status: null
            }

        case AUTH_STORE_TOKEN:
            return {
                ...action.auth
            }
        
        case AUTH_LOGOUT:
            return {
                ...state,
                ...action.auth
            }

        case AUTH_REQUEST_RESET :
            return {
                ...state,
                auth_fetch_status: {
                    fetching: true,
                    fetchMessage: 'Please wait: resetting'
                }
            };

        case AUTH_STORE_SCROLL_POS :
            let {scrollPos} = state, f = 0;
            
            scrollPos.forEach((i: any) => {
                if (i.pathname === action.value.pathname) {
                    i.scroll = action.value.scroll;
                    ++f;
                }
            });
            
            // store only those scroll position which user has scrolled.
            if (f === 0 && action.value.scroll[1] > 0) scrollPos.push(action.value)
            
            return {
                ...state,
                scrollPos: scrollPos
            };

        case AUTH_UPDATE_SCROLL_POS :
            return {
                ...state,
                scrollPos: [...action.value]
            }
        
       
        default:
            return state;
    }
};
