export async function loadOptRunSetSelectionHelper(data, study, setFunc) {

    const input = data ? data.input : null;
    const goal = input ? input.goal : null;
    const targetProducts = goal ? goal.targetProducts : null;
    console.log('targetProducts', targetProducts);
    if( targetProducts && targetProducts.length > 0 ){
        const productId = targetProducts[0];
        const slot = study.config.productSlots.find(slot => slot.uid === productId);
        if( slot ){
            const sel2 = {
                label: slot.label,
                uid: slot.uid,
                image: slot.image,
                type: 'product',
                typeLabel: study.productLabel,
                typeIndex: 2,
                product: slot.uid,
                available: slot.play.available,
            }
            await setFunc('selectedItem2', sel2); //(dispatch, getState);
            const brandEquitiesLevel = study.config.brandEquitiesDef.level;
            if( brandEquitiesLevel && brandEquitiesLevel !== 'product'){
                // find attribute
                const be_att = study.config.productDefs.default.atts.find(att => att.name === brandEquitiesLevel);
                if( be_att ){
                    const sel_val = slot.play[be_att.name];
                    const sel_level = (be_att.levels || []).find(level => level.value == sel_val) || {}; // intentional use of '=='
                    const selp = {
                        label: sel_level.label,
                        uid: `${be_att.name}:${sel_val}`,
                        image: sel_level.image,
                        type: be_att.name,
                        typeLabel: be_att.label,
                        typeIndex: 2,
                        [be_att.name]: sel_val,
                        available: 1
                    };
                    await setFunc('selectedItemP', selp); //(dispatch, getState);
                }
            }
        }
    }

}