import * as globals from "../globals"
import { guid } from "../utils/guid"
import { mapObject } from "../utils/mapObject"
import { xxHash32 } from "js-xxhash"
import {
	MODEL_CLONE_PRODUCT,
	MODEL_DELETE_PRODUCT,
	//MODEL_RESET,
	MODEL_SET_ETAG,
	MODEL_RESET_BRAND_EQUITY_VALUE,
	MODEL_APPLY_CONDITIONS,
	MODEL_RESET_TO_BASECASE,
	MODEL_RESET_PRODUCT,
	MODEL_SET_ATT_LOCKED,
	MODEL_SET_ATT_VALUE,
	MODEL_SET_BASE,
	MODEL_SET_BRAND_EQUITY_VALUE,
	MODEL_SET_PRODUCT_LABEL,
	STUDY_PIN_BRAND,
	MODEL_SET_LEVEL_ALLOWED,
	MODEL_SET_MESSAGE_ALLOWED,
	MODEL_SET_MISC_VALUE,
	MODEL_SET_OPTIMIZATION_METRIC,
	MODEL_TOGGLE_OPTIMIZATION_SELECTION,
	MODEL_ADD_OPTIMIZATION_SELECTION,
	MODEL_REMOVE_OPTIMIZATION_SELECTION,
	MODEL_SET_PRODUCT_AVAILABLE,
	MODEL_SET_PRODUCT_LOCKED,
	MODEL_SET_PRODUCT_PINNED,
	MODEL_SET_SEGMENTATION,
    MODEL_SET_VIEW,
    MODEL_CONFIG_INIT,
	MODEL_UPDATE_CONFIG,
    MODEL_NEED_UPDATE as MODEL_REQUEST_FORECAST,
    MODEL_SET_ACTIVE_CONFIG,
	NOTIF_ADD,
	NOTIF_MARK_COMPLETE,
	STUDY_RESET,
	STUDY_RECEIVE
    
	// @ts-ignore
} from "./action-types"
import * as apiService from "../utils/apiService"
import { OPTIM_SET_RUNLIST_DATA, OPTIM_SET_RUNLIST_LOADING } from "./action-types"
import * as Scenario from "./Scenario";
import { applyConditions } from '../utils/dcmUtils'
import { getMeta } from  '../utils/metaUtils'
import { getOptRunScenarioKey } from "./ResearchScenarios"

const isNull = (val: any, fallback: any) => (val === undefined || val === null) ? fallback : val

export interface ConfigDetails {
  assumptionsId: string
  baseCaseId: string
  brandEquities: any
  type: string
  etag: string
  showHints: boolean
  brandEquitiesDef: any // Consider moving to study
  optim: any
  dirty: boolean
  def: string
}
export interface configs {
	uid: string
	cloneOf?: string
	label: string
	image?: string
	play: any
    base: any    
}

const initialState: ModelState = {
	//nothing: "hi",
}

export interface ModelState {
	//nothing: string,
	[key: string]: any
}

interface ModelStateAction{
	model: string,
	[key: string]: any
}

const timeout = (ms: any) => new Promise((res: any) => setTimeout(res, ms))

export const actionCreators = {

	forecast: (play_base: string, configs: any) => async (dispatch: any, getState: any) => {
		dispatch({
			type: MODEL_UPDATE_CONFIG,
			play_base,
			// configs: , 
			configModels: configs
			// productSlots: getState()?.study?.config?.productSlots
		})
	},

	setAvailable: (productUid: any, available: any, configModel: any) => async (dispatch: any, getState: any) => {
		console.log('avail', available);
		dispatch({
			type: MODEL_SET_PRODUCT_AVAILABLE,
			productUid,
			value: available,
			activeConfig: configModel
		})
		// dispatch({ type: MODEL_REQUEST_FORECAST, id: 'play', configModel })
		
	},

	applyConditions: () => async (dispatch: any, getState: any) => {
		
		const state = getState()
		const metaConditions = await getMeta(state.study.uid, state.auth, 'conditions')
		
		const dcm = getState().dcm
		const activeConfig = dcm.activeConfig
		const productDef = dcm.productDefs['default']
		const curr_prodId = state.selections.product?.uid
		const curr_product = dcm.configs[activeConfig]?.play.find(f => f.uid === curr_prodId)

		// Apply the conditions to the products
		let updatedAtts = []
		if (metaConditions) {
			// console.log('rules', rules);
			updatedAtts = applyConditions(curr_product, productDef?.atts, metaConditions.rules)

			// Dispatch an action to update the products in the state
			dispatch({ type: MODEL_APPLY_CONDITIONS, updatedAtts })
		}

		return updatedAtts
	},

	resetToBasecase: () => (dispatch: any) => {
		dispatch({ type: MODEL_RESET_TO_BASECASE })
		//dispatch({ type: DCM_UPDATE_CONFIG, id: 'play' })
	},
	
	
	applyMultiBasecases: () => async (dispatch: any, getState: any) => {

		const state = getState()
		const configModels = state.dcm.configModels
		const activeConfig = state.dcm.activeConfig
		const productDef = state.dcm.productDefs.default

		for (let cm of configModels) {

			let metaBasecase = await getMeta(state.study.uid, state.auth, `basecase${cm}`)

			if (metaBasecase) {
				const base = JSON.parse(JSON.stringify(state.dcm.configs[cm].base))

				if (base) {
					for (let prod of base) {
						let basecaseData = metaBasecase.find(mb => mb.productname === prod.uid)

						if (basecaseData) {
							for (let att of productDef.atts) {
								if (basecaseData.hasOwnProperty(att.name)) {
									prod[att.name] = basecaseData[att.name]
								}
							}
						}
					}
				}
			
				dispatch({ type: MODEL_SET_BASE, activeConfig: cm, basecase: base, resetPlay: true })
			}
		}
	},

	cloneProduct: (modelName: string, productUid: any, newLabel: string) => async (dispatch: any, getState: any) => {
		dispatch({
			type: MODEL_CLONE_PRODUCT,
			modelname: modelName,
			productUid,
			newLabel
		})
		// dispatch({ type: MODEL_REQUEST_FORECAST, id: 'play' })
		// dispatch({
		// 	type: MODEL_UPDATE_CONFIG, id: 'play',
		// 	productSlots: getState()?.study?.config?.productSlots
		// })
	},

	deleteProduct: (productUid: any) => async (dispatch: any, getState: any) => {
		dispatch({
			type: MODEL_DELETE_PRODUCT,
			productUid,
		})
		dispatch({ type: MODEL_REQUEST_FORECAST, id: 'play' })
	},

	setProductPinned: (productUid: any, pinned = true) => async (dispatch: any, getState: any) =>
		dispatch({
			type: MODEL_SET_PRODUCT_PINNED,
			productUid,
			value: pinned,
		}),

	setProductLabel: (productUid: any, label: any) => async (dispatch: any, getState: any) => {
		dispatch({
			type: MODEL_SET_PRODUCT_LABEL,
			productUid,
			label,
		})
		// dispatch({
		// 	type: MODEL_UPDATE_CONFIG, id: 'play', productUid,
		// 	productSlots: getState()?.study?.config?.productSlots
		// })
	},

	// todo: move setBrandPinned out of config reducer. doesn't belong here
	setBrandPinned: (brandUid: any, pinned: any = true) => async (dispatch: any, getState: any) =>
		dispatch({
			type: STUDY_PIN_BRAND,
			brandUid,
			value: pinned,
		}),

	// this was experimental, to lock a product slot (i think), and is not being used anymore.
	setLocked: (productUid: any, locked: any) => async (
		dispatch: any,
		getState: any,
	) =>
		dispatch({
			type: MODEL_SET_PRODUCT_LOCKED,
			productUid,
			value: locked,
		}),

	setPlay: (configModel: string, productUid: any, attName: any, value: any) => async (dispatch: any, getState: any) => {
		dispatch({
			type: MODEL_SET_ATT_VALUE,
			configModel,
			productUid,
			attName,
			value,
		})
		dispatch({ type: MODEL_SET_ACTIVE_CONFIG, activeConfig: configModel })
		// dispatch({ type: MODEL_NEED_UPDATE, id: 'play' })
		
	},

		
	setBase: () => (dispatch: any) => {
		dispatch({ type: MODEL_SET_BASE })
		//dispatch({ type: MODEL_UPDATE_CONFIG, id: 'base' })
	},
    
	setActiveConfig: (activeConfig: any) => async (dispatch: any, getState: any) => {
		dispatch({
			type: MODEL_SET_ACTIVE_CONFIG,
			activeConfig
		})
	},

	resetPlay: (productUid: any) => (dispatch: any, getState: any) => {
		dispatch({ type: MODEL_RESET_PRODUCT, productUid })
		dispatch({ type: MODEL_REQUEST_FORECAST, id: 'play' })
		// dispatch({
		// 	type: MODEL_UPDATE_CONFIG, id: 'play', productUid,
		// 	productSlots: getState()?.study?.config?.productSlots
		// })
	},

	// resetBrandEquities: item => (dispatch) =>
	//     dispatch({ type: MODEL_RESET_BRAND_EQUITIES, item }),

	resetConfig: () => (dispatch: any, getState: any) => {
		dispatch({ type: MODEL_RESET })
		dispatch({ type: MODEL_REQUEST_FORECAST, id: 'play' })
		// dispatch({
		// 	type: MODEL_UPDATE_CONFIG, id: 'play',
		// 	productSlots: getState()?.study?.config?.productSlots
		// })
	},

	setView: (view: any) => (dispatch: any) => 
		dispatch({ type: MODEL_SET_VIEW, value: view }),
	

	set: (key: any, value: any) => (dispatch: any, getState: any) => {
		dispatch({ type: MODEL_SET_MISC_VALUE, key, value })
		dispatch({ type: MODEL_REQUEST_FORECAST, id: 'play' })		
	},

	setBrandEquityValue: (item: any, name: any, value: any) => (dispatch: any) => {
		dispatch({ type: MODEL_SET_BRAND_EQUITY_VALUE, item, name, value })
		dispatch({ type: MODEL_REQUEST_FORECAST, id: 'play' })
		//dispatch({ type: MODEL_UPDATE_CONFIG, id: 'play', brandEquityItem: item, brandEquityName: name })
	},

	resetBrandEquityValue: (item: any, name: any, value: any) => (dispatch: any	) => {
		dispatch({ type: MODEL_RESET_BRAND_EQUITY_VALUE, item, name, value })
		dispatch({ type: MODEL_REQUEST_FORECAST, id: 'play' })
		//dispatch({ type: MODEL_UPDATE_CONFIG, id: 'play', brandEquityItem: item, brandEquityName: name })
	},

	// for optimizer. if att is locked, optimizer will leave it set at attValue
	setAttLock: (slotUid: any, attName: any, locked: any) => async (dispatch: any) =>
		dispatch({
			type: MODEL_SET_ATT_LOCKED,
			slotUid,
			attName,
			locked,
		}),

	// for optimizer. if level is allowed, optimizer will consider it as possible value
	setLevelAllow: (slotUid: any,attName: any,levelValue: any,allowed: any) => async (dispatch: any) =>
		dispatch({
			type: MODEL_SET_LEVEL_ALLOWED,
			slotUid,
			attName,
			levelValue,
			allowed,
		}),

	setMessageAllow: (key: any, name: any, allowed: any) => async (dispatch: any) =>
		dispatch({
			type: MODEL_SET_MESSAGE_ALLOWED,
			key,
			name,
			allowed,
		}),

	setOptimizationMetric: (metric: any) => (dispatch: any) =>
		dispatch({ type: MODEL_SET_OPTIMIZATION_METRIC, value: metric }),

	toggleOptimizationSelection: (selection: any, callback: any) => (dispatch: any,	getState: any) => {
		dispatch({ type: MODEL_TOGGLE_OPTIMIZATION_SELECTION, selection })
		if (callback) {
			const state = getState()
			const newSelections = state.study.config.optim.selections
			callback(newSelections)
		}
	},

	addOptimizationSelection: (selection: any, callback: any) => (dispatch: any,getState: any) => {
		dispatch({ type: MODEL_ADD_OPTIMIZATION_SELECTION, selection })
		if (callback) {
			const state = getState()
			const newSelections = state.study.config.optim.selections
			callback(newSelections)
		}
	},

	removeOptimizationSelection: (selection: any, callback: any) => (dispatch: any,getState: any) => {
		dispatch({ type: MODEL_REMOVE_OPTIMIZATION_SELECTION, selection })
		if (callback) {
			const state = getState()
			const newSelections = state.study.config.optim.selections
			callback(newSelections)
		}
	},

	setSegmentation: (id: any) => (dispatch: any) => dispatch({ type: MODEL_SET_SEGMENTATION, value: id }),

	runOptimizer: () => async (dispatch: any, getState: any) => {
		var state: any = getState()

		// create a UID for this optimization run
		//var runId = 'testrun1'// guid()
		var runId: any = guid()

		// send a notification
		// dispatch({
		// 	type: NOTIF_ADD,
		// 	//value: `Optimization run ${runId} started`,
		// 	value: `Optimization is running (${runId})`,
		// 	running: true,
		// 	runId,
		// })

		//await timeout(15000)

		const optim: any = state.study.config.optim || {}
		const metric: any = optim.metric || globals.default_optimizer_metric
		const filterobj: any =
			state.filter.selectedFilter || globals.getDefaultFilter(state?.study)
		//const filter = (state.study.selectedFilter || {}).syntax || '1==1'
		//const filterLabel = (state.study.selectedFilter ||
		const config: any = state.study.config

		const targetProducts: any = (optim.selections || []).map(
			//(sel: any) => sel.uid,
			(sel: any) => ({ uid: sel.uid, label: sel.label }),
		)
		// console.log('targetProducts', targetProducts)

		// construct a label
		let label = "Maximize " + globals.metrics.find(m => m.key == metric)?.label + " of "
		if (targetProducts.length == 1) {
			label += `'${targetProducts[0]?.label}'`
		}
		else {
			label += `'${targetProducts[0]?.label}'`
            for (let i = 1; i < targetProducts.length-1; i++){
				label += `, '${targetProducts[i]?.label}'`
			}
			label += ` and '${targetProducts[targetProducts.length - 1]?.label}'`
		}
		label += " for '" + filterobj?.label + "'"
		label += " started " + new Date().toLocaleString()

		var query: any = {
			studyId: state.study.uid,
			uid: runId,
			label,
			goal: {
				targetMeasure: metric,
				targetFilter: filterobj.syntax,
				targetFilterLabel: filterobj.label,
				targetProducts: targetProducts,
			},
			config: {
				products: state.study.config.productSlots.map((slot: any) => {
					const def = state.study.config.productDefs[slot.def || "default"]
					let p: any = {
						uid: slot.uid,
						label: slot.label, // optimizer seems to need this but i want to delete
						available: slot.play.available,
					}
					const optim = slot.optim || {}
					def.atts.forEach((att: any) => {

						// NOTE: it's possible that the start value doesn't exist in allowed values. figure out where and how that should be handled!!

						const notTargetSlot = !targetProducts.find((p1: any) => p1.uid == slot.uid)
						const isReadOnly = att.readOnly
						const isLocked = optim.locks?.includes(att.name)

						// multiselect is a bit different than discrete or contin
						if (att.type == "multiselect") {

							// loop through options
							att.options.forEach((option: any) => {
								
								const currentVal = slot.play[option.name] || att.defaultValue

								if (notTargetSlot || isReadOnly || isLocked) {
									p[option.name] = currentVal
								}
								else {
									p[option.name] = {
										startValue: currentVal,
										allowedLevels: [isNull(att.yesValue,1), isNull(att.noValue,0)]
									}
								}

							})

						}
						else {
							// discrete or contin

							let currentVal = slot.play[att.name] || att.defaultValue

							if (notTargetSlot || isReadOnly || isLocked) {
								p[att.name] = currentVal
							}
							else {
								
								if (att.type == "discrete") {
									const disallowedLevels = (optim.disallow ? optim.disallow[att.name] : null) || []
									p[att.name] = {
										startValue: currentVal,
										allowedLevels: att.levels.filter((level: any) => !disallowedLevels.includes(level.value)).map((level: any) => level.value)
									}
								}
								else if (att.type == "contin") {
									p[att.name] = {
										startValue: currentVal,
										allowedRange: [att.min, att.max]
										// todo: step???
									}
								}
								
							}
						}

						
					})
					return p
				}),
				equity_drivers: {
					level: state.study.config.brandEquitiesDef.level,
					//data: config.brandEquities
					data: Object.keys(config.brandEquities).reduce(
						(accum: any, key: any) => {
							// convert key to level-less format, e.g. 'model:5' to '5'
							const newKey: any = key.replace(
								state.study.config.brandEquitiesDef.level + ":",
								"",
							)
							accum[newKey] = Object.keys(config.brandEquities[key]).reduce(
								(accum2: any, key2: any) => {
									accum2[key2] = config.brandEquities[key][key2].play
									return accum2
								},
								{},
							)
							return accum
						},
						{},
					),
				},
				brand_equities_to_optimize: {
					level: state.study.config.brandEquitiesDef.level,
					perceptions: Object.keys(config.brandEquities).reduce(
						(accum: any, key: any) => {
							// convert key to level-less format, e.g. 'model:5' to '5'
							const newKey: any = key.replace(
								state.study.config.brandEquitiesDef.level + ":",
								"",
							)
							const arr: any = Object.keys(config.brandEquities[key]).reduce(
								(accum2, key2) => {
									if (config.brandEquities[key][key2].allowed) {
										accum2.push(key2)
									}
									return accum2
								},
								[],
							)
							if (arr.length > 0) accum[newKey] = arr
							return accum
						},
						{},
					),
				},
			},
			assumptions: {
				// this attaches on the backend server i think. don't delete without adjusting backend, because backend assumes it exists.
			},
		}
		// console.log("optimizer query", query)
		
		

		const url: string = `${globals.apiRoot}/optimize/start`
		try {
			const response: any = await apiService.aPost(state.auth, url, query)

			if (response.status === 202) {
				const value: any = await response.json()
				// console.log("optimization accepted", value)

				// todo: reload the optinfo stuff
				// load the optruns
				{
					const f = async () => {

						dispatch({ type: OPTIM_SET_RUNLIST_LOADING, value: true })

						//setLoadingItems(true)
						try {
							const response = await apiService.aGet(state.auth, `${globals.apiRoot}/study/${state.study.uid}/optruns`)
							if( response.ok ){
								const runs = await response.json()
								//setItems(runs)
								//console.log('runs', runs)

								dispatch({ type: OPTIM_SET_RUNLIST_DATA, listData: runs })
								dispatch({ type: OPTIM_SET_RUNLIST_LOADING, value: false })
							}
						}
						catch (err) {
							console.error(err)
						}
						//setLoadingItems(false)
						
					}
					f()

				}







				// if( value.status === 'error' ){

				//     dispatch({
				//         type: NOTIF_MARK_COMPLETE, // 'COMPLETE_RUNNING_NOTIFICATION',
				//         runId,
				//         success: false,
				//         message: 'Optimization run failed. ' + value.message
				//     })

				// }
				// else{

				//     dispatch({
				//         type: NOTIF_MARK_COMPLETE, // 'COMPLETE_RUNNING_NOTIFICATION',
				//         runId,
				//         success: true,
				//         data: value,
				//         message: 'Optimization run completed. Click to view results',
				//         showOptButtons: true
				//     })

				// }
			} else {
				console.error("Optimization failed to start.", response.statusText)
				// dispatch({
				// 	type: NOTIF_MARK_COMPLETE, // 'COMPLETE_RUNNING_NOTIFICATION',
				// 	runId,
				// 	success: false,
				// 	message: "Optimization failed to start. " + response.statusText,
				// })
			}
		} catch (err) {
			console.error("Optimizer error", err)

			// dispatch({
			// 	type: NOTIF_MARK_COMPLETE, //'COMPLETE_RUNNING_NOTIFICATION',
			// 	runId,
			// 	success: false,
			// 	message: "Optimization run failed: " + JSON.stringify(err),
			// })
		}
	},
}

export const dirtyCheckSlot = (play: any, base: any, pdef: any): boolean => {
	
	if (play?.available != base.available) return true // eslint-disable-line eqeqeq
	for (let i in pdef.atts) {
		let att = pdef.atts[i]
		// this is hacky replace "1" on the next line with the attribute's default value
		if ((play[att.name] || 1) !== (base[att.name] || 1)) {
			return true
		}
	}
	return false
}

const reHash = (config: ModelState): ModelState => {

	// //let etag = hash(obj) // taking too long! could minimize the data being hashed
	// let etag = guid() // sorry redux rules, hashing is taking too long fix this later
	// // breaking the rules of redux because a reducer should have a predictable outcome. this random guid is not predictable
	// // I don't think it matters though, due to how I'm using the etag
	// // maybe i should just think of a better way to do this, avoiding the etag
	// // i'm not just using object refs, because i want to compare config against cached results.
	// // maybe there's a way to do this using object refs

	// trying xxHash32
	const str = JSON.stringify(config?.play);
	const hashNum = xxHash32(str);
	const playHash = hashNum.toString(16);
	
	return {
		...config,
		playHash,
	}
}

const toArray = (obj: any) => {
	return Object.entries(obj).map((entry) => ({
		key: entry[0],
		value: entry[1],
	}))
}

const toNestedArray = (obj: any) => {
	return Object.entries(obj).map((entry) => ({
		key: entry[0],
		value: toArray(entry[1]),
	}))
}



export const reducer = (state: ModelState, action: ModelStateAction): ModelState => {

    state = state || initialState
	// console.log('state', state);
	
	const modelName = action.model;	
	
	if (action.type.startsWith(Scenario.scenarioActionType + ":")) {
		return Scenario.reducer(state, action)
	}

	switch (action.type) {
		case STUDY_RESET:
			return initialState
		
		case STUDY_RECEIVE: {
			// when a study is loaded, grab the models
			//action.value = study on first load
			
			let mod: any = {
				activeConfig: null,
				modelNames: []
			}

			for (let modelDef of action.value?.models || []) {
				let config: any = {
					dirty: false
				}
				
				if (modelDef.basecase) {
					config["play"] = JSON.parse(JSON.stringify(modelDef.basecase?.products)) 
					config["base"] = JSON.parse(JSON.stringify(modelDef.basecase?.products)) 
				}

				if (modelDef.productDef) {
					config['productDef'] = JSON.parse(JSON.stringify(action.value.config.productDefs[modelDef.productDef]))
				}

				config['brandEquitiesDef'] = action.value?.config.brandEquitiesDef
				config['brandEquities'] = action.value?.config.brandEquities
				
				config = reHash(config);

				let obj: any = {
					modelInfo: modelDef,
					config,
				}
				delete obj.modelInfo?.basecase

				mod[modelDef.name] = obj	
				mod.modelNames.push(modelDef.name)

			}
			return mod;
		}
			
		case MODEL_CLONE_PRODUCT: {
			const { modelname, productUid, newLabel } = action

			// Find the existing product in the play list
			const prod = state[modelname]?.config?.play.find(
				(p: any) => p.uid === productUid,
			)

			if (!prod) return state

			// Sort the products by uid
			const sortedProducts = [...state[modelname]?.config?.play].sort((a, b) => {
				const aNumber = parseInt(a.uid.replace(/^\D+/g, ''), 10)
				const bNumber = parseInt(b.uid.replace(/^\D+/g, ''), 10)
				return aNumber - bNumber
			})

			// Get the last product after sorting
			const lastProduct = sortedProducts.slice(-1)[0]
			let newUid = 'prod1' // Default if there are no products

			if (lastProduct) {
				const lastUid = lastProduct.uid
				const lastNumber = parseInt(lastUid.replace(/^\D+/g, ''), 10) // Extract the numeric part
				newUid = `prod${lastNumber + 1}` // Increment the numeric part
			}

			// Create the new product with the new uid
			const newProd: configs = {
				...prod,
				uid: newUid,
				label: newLabel, // Use the new label
				cloneOf: prod.uid,
				isClone: true
			}

			// Return the new state with the cloned product added to both play and base
			return {
				...state,
				[modelname]: {
					...state[modelname],
					config: {
						...state[modelname].config,
						play: [
							...state[modelname].config.play, 
							newProd
						],
						base: [
							...state[modelname].config.base, 
							newProd
						]
					}
				}
			}
		}

			
			
			
		case MODEL_SET_ATT_VALUE: {
			
			const { configModel, productUid, attName, value } = action

			// Check if the config model exists
			if (!state.hasOwnProperty(configModel)) {
				console.error(`Config model ${configModel} not found.`)
				return state // Return current state if the specified configModel does not exist
			}

			// Find the index of the product to update in the play array
			const play = state[configModel]?.config.play.find(p => p.uid === productUid)
			const base = state[configModel]?.config.base.find(p => p.uid === productUid)
			const proddef = state[configModel].config?.productDef

			// console.log('play', play);
			// console.log('proddef', proddef);

			// Ensure the product was found before attempting to update
			if (play) {
				const dirty = dirtyCheckSlot(
					play,
					base,
					proddef,
				)

				// Make a copy of the play object and update the attribute
				const updatedPlay = { ...play, [attName]: value };

				// Create a new play array with the updated product
				const updatedProds = state[configModel].config.play.map(p =>
					p.uid === productUid ? updatedPlay : p
				)

				
				// Update the state immutably
				return {
					...state,
					[configModel]: {
						...state[configModel],
						config: reHash({
							...state[configModel].config,
							dirty,
							play: updatedProds
						})
					}					
				}
			} else {
				console.error(`Product with UID ${productUid} not found in config model ${configModel}.`)
				return state // Return current state if the product to update does not exist
			}
		}
			
		case MODEL_SET_ACTIVE_CONFIG: {

			const { activeConfig } = action

			return {
				...state,
				activeConfig
			}
		}
        
		case MODEL_REQUEST_FORECAST: {

			const configId: string = action.id //play or base
			return {
				...state,
				[action.configModel]: {
					...state[state.activeConfig],
					modelInfo: {
						...state[state.activeConfig].modelInfo,
						dirty: true
					}
				}
			}
		}
			
		case MODEL_DELETE_PRODUCT: {
			return reHash({
				...state,
				configs: state[state.activeConfig].config.play.filter((p: any) => p.uid !== action.productUid),
			})
		}
            
        // case MODEL_CONFIG_INIT: {

        //     // const { configs, configModels, productDefs } = action
        //     const data = action.data
        //     const configModels = action.configModels
        //     const configs = data.config.productSlots
        //     const productDefs = data.config.productDefs
			
		// 	// console.log('study', study);

        //     const configTypes = ['play', 'base']
        //     let updatedConfigs = {...state.configs } //{}

        //     //defaults for config details
        //     const config_details: ConfigDetails = {
        //         assumptionsId: '', 
        //         baseCaseId:'todo',
        //         brandEquities: null,
        //         brandEquitiesDef: {},
        //         dirty: false,
        //         etag: guid(),
        //         optim: null,
        //         showHints: false,
		// 		type: 'multi',
		// 		def: configs?.[0]?.def || 'default'
        //     }

        //     configModels.forEach((cm: string) => {
        //         // Update the configs state with the modified config model
        //         updatedConfigs[cm] = {
        //             ...config_details,
        //             play: configs.map((prod: any) => {
        //                 return {
        //                     uid: prod.uid,
        //                     label: prod.label, 
		// 					image: prod.image,
		// 					dirty: false,
		// 					cloneOf: prod.cloneOf,
        //                     ...prod['play']
        //                 }
        //             }), 
        //             base: configs.map((prod: any) => {
        //                 return {
        //                     uid: prod.uid,
        //                     label: prod.label, 
        //                     image: prod.image,
		// 					cloneOf: prod.cloneOf,
        //                     ...prod['base']
        //                 }
        //             })
        //         }
        //     })

        //     return {
		// 		...state,
		// 		configModels: configModels,
        //         activeConfig: configModels[0],
        //         configs: updatedConfigs,
		// 		productDefs,				
        //     }
        // }
            
		case MODEL_SET_PRODUCT_AVAILABLE: {

			let play = state[action.activeConfig]?.config.play.find(p => p.uid === action.productUid)
			let base = state[action.activeConfig]?.config.base.find(p => p.uid === action.productUid)

			return {
				...state,
				[action.activeConfig]: {
					...state[action.activeConfig],
					config: reHash({
						...state[action.activeConfig].config,
						play: state[action.activeConfig].config.play.map(prod =>
							prod.uid !== action.productUid
								? prod
								: {
									...prod,
									available: action.value ? 1 : 0,
									dirty: dirtyCheckSlot(
										play,
										base,
										state[action.activeConfig]?.config.productDef,
									),
								}
						),
					})
				}
					
			}
		}

		// case MODEL_SET_ETAG: {
		// 	const { activeConfig } = action
		// 	return {
		// 		...state,
		// 		[modelName]: {
		// 			...state[modelName],
		// 			config: {
		// 				...state[modelName]?.config,
		// 				etag: guid()
		// 				// play: [...state.configs[activeConfig].play || [] ]
		// 			}
		// 		}
		// 	}
		// }


		// case MODEL_SET_ATT_VALUE: {
			
		// 	const { configModel, productUid, attName, value } = action

		// 	// Check if the config model exists
		// 	if (!state.configs.hasOwnProperty(configModel)) {
		// 		console.error(`Config model ${configModel} not found.`)
		// 		return state // Return current state if the specified configModel does not exist
		// 	}

		// 	// Find the index of the product to update in the play array
		// 	const playIndex = state.configs[configModel].play.findIndex(p => p.uid === productUid)
		// 	const base = state.configs[configModel].base.find(p => p.uid === productUid)

		// 	// Ensure the product was found before attempting to update
		// 	if (playIndex !== -1) {
		// 		const dirty = dirtyCheckSlot(
		// 						state.configs[configModel].play[playIndex],
		// 						base,
		// 						state.productDefs.default,
		// 					)
		// 		// Create a new updated product object with the new attribute value
		// 		const updatedProd = {
		// 			...state.configs[configModel].play[playIndex],
		// 			[attName]: value,	
		// 			dirty: dirty
		// 		}

		// 		// Create a new play array with the updated product
		// 		const updatedPlay = [
		// 			...state.configs[configModel].play.slice(0, playIndex),
		// 			updatedProd,
		// 			...state.configs[configModel].play.slice(playIndex + 1),					
		// 		]

		// 		// Update the state immutably
		// 		return {
		// 			...state,
		// 			configs: {
		// 				...state.configs,
		// 				[configModel]: {
		// 					...state.configs[configModel],	
		// 					etag: guid(),
		// 					play: updatedPlay,							
		// 				},
		// 			},
		// 		}
		// 	} else {
		// 		console.error(`Product with UID ${productUid} not found in config model ${configModel}.`)
		// 		return state // Return current state if the product to update does not exist
		// 	}
		// }

		case MODEL_SET_PRODUCT_LABEL:
			// this doesn't look right
			return reHash({
				...state,
				[state.activeConfig]: {
					...state[state.activeConfig],
					config: state[state.activeConfig].config.play.map((p: any) =>
						p.uid !== action.productUid
							? p
							: {
								...p,
								label: action.label,
							},
					),
				}
			})

		// case MODEL_SET_BASE: {
		// 	const { activeConfig, basecase, resetPlay } = action
		// 	// console.log('basecase', basecase);
		// 	// console.log('resetPlay', resetPlay);

		// 	return {
		// 		...state,
		// 		configs: {
		// 			...state.configs,
		// 			[activeConfig]: {
		// 				...state.configs[activeConfig],
		// 				play: resetPlay ? basecase : state.configs[activeConfig].play,
		// 				base: basecase,
		// 			},
		// 		}				
		// 	}
			
		// }

		case MODEL_RESET_TO_BASECASE:

			let activeConfig = state.activeConfig;
			// Ensure that the active config and base are available
			if (!state[activeConfig].config || !state[activeConfig].config.base) {
				console.error('Base configuration not found');
				return state // Return current state if base is not defined
			}

			return {
				...state,
				[activeConfig]: {
					...state[activeConfig],
					config: reHash({// Spread all existing properties of the active config
						...state[activeConfig].config,
						play: [...state[activeConfig]?.config.base] // Overwrite play with a clone of base
					})
				}	
				
			}


		// case MODEL_RESET_PRODUCT:
		// 	let active_config = state.activeConfig
		// 	return reHash({
		// 		...state,
		// 		configs: state.configs[active_config].map((prod: any) =>
		// 			prod.uid !== action.productUid
		// 				? prod
		// 				: {
		// 					...prod,
		// 					play: { ...prod.base, dirty: false },
		// 				  },
		// 		),
		// 	})

		// case MODEL_RESET:
		// 	// todo: simplify the brandEquities structure to make resetting it easier than all this junk:
		// 	const newBrandEquities: any = mapObject(state.brandEquities, (i: any) =>
		// 		mapObject(i, (j: any) => ({
		// 			...j,
		// 			play: j.base,
		// 			dirty: false,
		// 		})),
		// 	)

		// 	return reHash({
		// 		...state,
		// 		configs: state.configs.map((product: any) => ({
		// 			...product,
		// 			play: { ...product.base, dirty: false },
		// 		})),
		// 		brandEquities: newBrandEquities,
		// 	})

		case MODEL_SET_MISC_VALUE:
			return {
				...state,
				[action.key]: action.value,
			}
		

		default:
			return state
	}

	

	
	
}
