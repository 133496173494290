import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from '../../store/Account.ts';
import { CardLoading } from '../card/CardLoading';
import { PageHeader } from '../common/PageHeader';
import './ManageAccount.scss';
import UserStats from './UserStats';

export function ManageAccount() {
  const [studies, setStudies] = useState([]);
  const [users, setUsers] = useState(null);
  const [selectedStudyId, setSelectedStudyId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [statsLoader, setStatsLoader] = useState(false);
  const [stats, setStats] = useState([]);
  const [showStats, setShowStats] = useState(false);
  
  const reduxUsers = useSelector(state => state.account.users);
  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(actionCreators.getStudies(res => {
      if (res) {
        setStudies(res);
      } else {
        setStudies([]);
      }
    }));

  }, [dispatch]);

  const getUsageStats = (studyId, shouldShowStats) => {
    setShowStats(shouldShowStats);
    if (!shouldShowStats) {
      setStats([]);
    } else {
      setStatsLoader(true);
      dispatch(actionCreators.getStudyUsers(studyId, shouldShowStats, res => {
        if (res) {
          console.log('study users', res);
          setStats(res);
          setStatsLoader(false);
        }
      }));
    }
  };

  const getSelectedStudyUser = (studyId, shouldShowStats = false) => {
    setIsLoading(true);
    setSelectedStudyId(studyId);
    setStats([]);
    setStatsLoader(false);
    setShowStats(false);
    dispatch(actionCreators.getStudyUsers(studyId, shouldShowStats, res => {
      if (res) {

        console.log('USERS', res);
        setUsers(res);
        setIsLoading(false);
      } else {
        setUsers([]);
        setIsLoading(false);
      }
    }));
  };

  const userEnabledDisableHandler = (uid, userState) => {
    dispatch(actionCreators.setUserEnabled(uid, userState, () => setUsers([...reduxUsers])));
  };

  const listStudies = () => (
    <>
      {studies.length > 0 
        ? studies.map((item, i) => (
            <option key={i} value={item.uid} defaultValue={i === 0}>
              {item.label}
            </option>
          ))
        : <option>No study found...</option>}
    </>
  );

  return (
    <div className="page manage-account-page">
        <PageHeader title={'Manage Accounts'} fullWidth={true} />
        <div className="content">
          <div className="header">
            <h2>Users</h2>
            <select onChange={e => getSelectedStudyUser(e.target.value)}>
                <option defaultValue>---Select Study---</option>
                <option defaultValue>ALL</option>
                {listStudies()}
            </select>
            {selectedStudyId !== null && 
              <>
                <label className="switch" title="Click to show user stats">
                  <input checked={showStats} type="checkbox" onChange={e => getUsageStats(selectedStudyId, e.target.checked)} />
                  <span className="slider round" />
                </label><span style={{color:'#d4d4d4'}}>Show Usage Stats</span>
                {statsLoader && <span style={{color:'#0ba918', paddingLeft:20, fontWeight:500}}>loading...</span>}
              </>
            }
          </div>
          <div className="manage-account-body body">
            <div className="row">
              {isLoading 
                ? <p>Loading users and statistics. Please wait...</p> 
                : users && users.length > 0 
                  ? users.map((user, i) => (
                      <div className="col" key={i}>
                        <div className="post-info">
                          <div className="user-detail">
                            <p className="author m-0">
                              <a href="#">{user.name}</a>
                            </p>
                            <a href={'mailto:' + user.email}>{user.email}</a>
                          </div>
                          <div className="info-line">
                            <span className="generic">Last Login</span>
                            <span className="date">
                              {user.lastLogin !== null
                                ? moment.utc(user.lastLogin).local().fromNow()
                                : 'never logged in'}
                            </span>
                          </div>
                          {stats.length > 0 && 
                            <UserStats {...stats.find(i => i.id === user.id)} />
                          }
                        </div>
                        <button className="user-enable" onClick={() => userEnabledDisableHandler(user.uid, user.disabled)}>
                          {user.disabled ? 'disabled' : 'enabled'}
                        </button>
                      </div>
                    )) 
                  : (studies.length > 0 && users !== null) && <p>No records.</p>
              }
            </div>
          </div>
        </div>
      </div>
  );
}


{/* <CardLoading loading={statsLoader} */}