import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators as filterCreator} from '../../store/Filter';
import { actionCreators as selectionsCreator} from '../../store/Selections';
import { actionCreators as dcmCreator} from '../../store/DCM';
import './FilterDrawer.scss';
import * as debounce from '../../utils/debounce';
import { filter } from 'lodash';

export class FilterDrawer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedTabIndex: 0,
            selectedCategory: null,
            searchTerm: null,
            isSearching: false
        };
        this.searchNode = React.createRef();

        this.iconCollapsed = 'fal fa-folder';
        this.iconCollapsed2 = 'fa fa-folder';
        this.iconExpanded = 'fal fa-folder-open';
        this.iconExpanded2 = 'fa fa-folder-open';

        this.selIndicator = '●'; // <i className='fal fa-asterisk' style={{fontSize: '7pt'}} />;

        window.filterDrawer = this;
    }

    

    selectCategory(cat) {
        this.setState({ selectedCategory: cat });
    }

    getNbFiltersSelected(filterGroup) {
        let nb = 0;
        for (var i in filterGroup.filters) {
            if (filterGroup.filters[i].isSelected) nb++;
        }
        return nb++;
    }

    clearFilterGroupSelections(filterGroup) {
        this.props.deselectFilterGroup(filterGroup.id, this.props.datasetId);
    }

    beginSearch = debounce.debounce(() =>
        this.props.expandOnlySearchedFilterGroups(this.state.searchTerm),
        debounce.forecastDelay
    );

    render() {
        const { dcm, selections, filter } = this.props;

        let filterGroups = filter.filterGroups || [];
        let isOpen = this.props.isOpen;

        const datasetId = this.props.datasetId;

        // use subset of filterGroups if setId is present
        if (datasetId) {
            filterGroups = filterGroups.filter(fg => fg.datasetId === datasetId);
        }

        // obtain list of filtergroup categories
        let categories = [];
        let defaultCategory = filterGroups.length > 0 ? filterGroups[0].category : 'General';
        filterGroups.filter(f => f.showOnUi).forEach(fg => {
            let category = fg.category || defaultCategory;
            if (!categories.includes(category)) {
                categories.push(category);
            }
        });

        // compute the number of user-selected filters in each category
        //FIX - what if more than one att is showOnui?
        let categoryCounts = {};
        filterGroups.forEach(fg => {
            let category = fg.category || defaultCategory;
            let nb = this.getNbFiltersSelected(fg);
            categoryCounts[category] = (categoryCounts[category] || 0) + nb;
        })


        let selectedFilter = this.props.selectedFilter || {};

        // get elements for scroll list
        let elements = null;
        if (this.props.isLoading) {
            elements = <div>loading...</div>;
        } else if (filterGroups == null) {
            elements = <div>error</div>;
        } else {
            let selFilters = this.state.isSearching
                ? filterGroups.filter(f => f.showOnUi)
                : filterGroups.filter(fg => (fg.category || defaultCategory) === (this.state.selectedCategory || defaultCategory) && fg.showOnUi);

            elements = selFilters.map(fg => {
                let nb = this.getNbFiltersSelected(fg);
                const groups = <div
                    className={'group-item' + (nb > 0 ? ' has-selections' : '')}
                    onClick={() =>
                        fg.isExpanded
                            ? this.props.collapseFilterGroup(fg.id)
                            : this.props.expandFilterGroup(fg.id)
                    }
                >
                    <i className={fg.isExpanded ? (nb > 0 ? this.iconExpanded2 : this.iconExpanded)
                        : (nb > 0 ? this.iconCollapsed2 : this.iconCollapsed)}
                    />
                    &nbsp; {fg.label}
                    {nb > 0 ? <span className="sel-indicator"> {this.selIndicator}</span> : null}
                    {nb > 0 ? (
                        <button
                            className="clear-selections"
                            title="Clear selection(s)"
                            role='button'
                            aria-label='clear selections'
                            onClick={ev => {
                                this.clearFilterGroupSelections(fg);
                                ev.preventDefault();
                                ev.stopPropagation();
                            }}
                        >
                            <i className="fal fa-times" />
                        </button>
                    ) : null}
                </div>;

                return (
                    <div key={'fg_' + fg.id} className={'filter-group'}>
                        {this.state.isSearching ? fg.matchedGroup ? groups : '' : groups}
                        {fg.isExpanded
                            ? fg.filters.map(f => {
                                let groupItem = <div
                                    key={'filter_' + (f.id || f.syntax)}
                                    className={'filter-item' + (f.isSelected ? ' selected' : '')}
                                    onClick={() =>
                                        f.isSelected
                                            ? this.props.deselectFilter(fg.id, f.id || f.syntax, datasetId)
                                            : this.props.selectFilter(fg.id, (f.id || f.syntax), null, datasetId) //hard coded model FIX
                                    }
                                >
                                    {f.label}
                                </div>
                                return this.state.isSearching ? f.matched ? groupItem : '' : groupItem;
                            })
                            : null}
                    </div>
                );
            });
        }

        // main render
        return (
            <div className={'filter-drawer' + (isOpen ? ' open' : '')}>

                <div className="pane-title">
                    <i className="fal fa-users" /> Filter
                    {/* <span className="right">
                        <i className="fal fa-male" /> {selectedFilter && selectedFilter.n !== null ? selectedFilter.n : '...'}
                    </span> */}
                    <button style={{ float: 'right', cursor: 'pointer' }} role='button' aria-label='close' onClick={() => this.props.closeRequested()}>
                        <i className='fal fa-times'></i>
                    </button>

                    <button className="right clear-all-btn" role='button' aria-label='clear filter'  onClick={() => {
                        this.props.setUseCustomLabel(false);
                        this.props.deselectAll(datasetId)
                    }}>
                        <i className='fal fa-eraser'/> clear filter
                    </button>
                </div>


                {/* filter label section */}
                <div className="section filter-label">
                    <div className="title">Label ({selectedFilter.useCustomLabel ? 'custom' : 'auto'})</div>
                    <div className="row">
                        <button
                            className={
                                'toggle-btn' + (selectedFilter.useCustomLabel ? ' selected' : '')
                            }
                            role='button'
                            aria-label='customize label'
                            title="Customize label"
                            onClick={() => this.props.setUseCustomLabel(!selectedFilter.useCustomLabel)}
                        >
                           <i className="fal fa-edit"></i>
                        </button>
                        {selectedFilter.useCustomLabel ? (
                            <input type="text" placeholder="custom filter label" role='input' aria-label='custom filter label' value={selectedFilter.customLabel || ""}
                                onChange={(ev) => this.props.setCustomLabel(ev.target.value)}
                            />
                        ) : (
                                <div className="auto-label" title={selectedFilter.autoLabel}>
                                    {selectedFilter.autoLabel}
                                </div>
                            )}
                    </div>
                </div>

                {/* subgroups head section (title, tabs, search) */}
                <div className="section bottom-border subgroups-head">

                    {/* title */}
                    <div className="title">
                        Subgroup(s)
                    </div>

                    {/* search box */}
                    <div className="filter-search">
                        <input ref={this.searchNode} type="text" placeholder="search" onChange={(ev) => {
                            if (ev.target.value.trim() !== '') {
                                this.setState({ searchTerm: ev.target.value.trim() });
                                this.setState({ isSearching: true });
                                this.beginSearch();
                            } else {
                                this.setState({ isSearching: false });
                                this.props.collapseAllFilterGroups();
                            }
                        }} />
                        <button className="clear" role='button' aria-label='clear search' title="Clear search" onClick={() => {
                            this.searchNode.current.value = '';
                            this.setState({ searchTerm: '', isSearching: false });
                            this.props.collapseAllFilterGroups();
                        }}>
                            <i className="fal fa-times" />
                        </button>
                    </div>

                    {/* category tabs */}
                    {
                        !this.state.isSearching
                            ? <div className="tab-header">
                            {categories.map(cat => (
                                <div
                                    key={'tab_' + cat}
                                    className={
                                        'tab' +
                                        ((this.state.selectedCategory || defaultCategory) === cat
                                            ? ' selected'
                                            : '') +
                                        (categoryCounts[cat] > 0 ? ' has-selections' : '')
                                    }
                                    onClick={() => this.selectCategory(cat)}
                                >
                                    {cat}
                                    {categoryCounts[cat] > 0 ? (
                                        <span className="sel-indicator"> {this.selIndicator}</span>
                                    ) : null}
                                </div>
                            ))}
                        </div> : null
                    }
                </div>

                {/* scrolling list of elements */}
                <div className="scroller">{elements}</div>


                {/* close button */}
                {/* <div className='bottom-fixed'>
                    <span className='btn' onClick={() => this.props.closeRequested()}>Close
                    </span>
                </div> */}

            </div>
        );
    }
}

FilterDrawer = withRouter(
    connect(
      state => ({
            filter: state.filter,
            selections: state.selections,
            dcm: state.dcm
        }),
        dispatch => bindActionCreators({ ...filterCreator, ...selectionsCreator, ...dcmCreator }, dispatch)
    )(FilterDrawer)
);
