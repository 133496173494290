import * as React from 'react';
import './Card.scss';

export class CardError extends React.Component{

    render() {
        return <div className='card-error'>
            <div className='message'>
                {this.props.message}
            </div>
        </div>;
    }

}