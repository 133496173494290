import { format as d3Format } from 'd3-format';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators as configActionCreators } from '../../store/Config.ts';
import { EditSlider2 } from './att-editor/EditSlider2';
import './EditAreaSliders.scss';

const diffFmt = d3Format('+0.2f');

export class EditAreaSliders extends React.Component {

    isUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    handleDirtyBitClick = (payload) => {
        console.log(payload);
        // const slot = this.props.slot;
        // const att = this.props.att;
        // const baseValue = slot.base[att.name] || att.defaultValue;
        // this.props.setValue(slot.uid, att.name, baseValue);
    }

    render() {
        
        let config = this.props.config;
        if (!config) return null;

        let area = this.props.area;

        //let sectionId = this.props.match.params.sectionId;

        let brandEquitiesDef = config.brandEquitiesDef;
        let brandEquities = config.brandEquities;
        let level = brandEquitiesDef.level;

        //let configSliders = config.brandEquities; //[area === 'message' ? 'messages' : 'perceptions'];

        let entries = brandEquitiesDef.entries.filter(e => e.area === area);
        let categories = entries.map(e => e.category).filter(this.isUnique);

        //let categories = brandEquitiesDef.entries.map(e => e.category); configSliders.map(m => m.category).filter(this.isUnique);
        const selection = this.props.selection;
        const selectionLabel = this.props.selectionLabel;
        
        if( !selection )
            return null;


        // let cfg_play = globals.configs.find(cfg => cfg.key === 'play');
        // let cfg_base = globals.configs.find(cfg => cfg.key === 'base');

        const debug = false;

        return (
            <div className="slider-area-editor">

                {categories.map((cat, catidx) => {

                    let retItems = [];

                    let catEntries = entries.filter(m => m.category === cat);
                    return catEntries.length > 0 ?
                        <React.Fragment key={catidx}>
                            <div className='category-header'>
                                <h3>{cat}</h3>
                            </div>
                            {catEntries.map(message => { 

                                const instance = brandEquities && brandEquities[selection] ? brandEquities[selection][message.name] : null || {};
                                const playValue = instance.play;
                                const baseValue = instance.base;
                                const dirty = playValue != baseValue;

                                const payload = {
                                    message,
                                    instance
                                }

                                

                                return <div key={message.name} className='slider-editor'>
                                    <label>
                                        {debug ? <span>[{message.name}]</span> : null} {message.label}
                                        {/* {(playValue != baseValue) ? <span>{diffFmt(playValue - baseValue)}</span> : null} */}
                                        {dirty ? <span className='dirty-bit' onClick={() => {
                                            this.props.setBrandEquityValue(selection, message.name, baseValue)
                                        }}>
                                            <span className='diff-text'>{diffFmt(playValue - baseValue)}</span>
                                            <i className='fas fa-circle'/>
                                        </span> : null}
                                    </label>
                                    <div className='slider-value-editor'>
                                        <EditSlider2
                                            playValue={instance.play}
                                            baseValue={instance.base}
                                            onChange={(newValue) => {
                                                this.props.setBrandEquityValue(selection, message.name, newValue);
                                            }}
                                        />
                                    </div>
                                </div>
                            })}
                        </React.Fragment>
                        : null;
                })}
                
            </div>
        );
    }
}

EditAreaSliders = withRouter(
    connect(
        (state) => ({
            config: (state.study || {}).config
        }),
        dispatch => bindActionCreators(configActionCreators, dispatch)
    )(EditAreaSliders)
);
