import * as React from 'react';
import { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Link, withRouter, useLocation, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Auth.ts';
import * as globals from '../../globals';
import * as apiService from '../../utils/apiService';
import { PageHeader } from '../common/PageHeader';
import './StudyApproval.scss'

export let LicenseListElement = (props) => {
    const {body, setBody, licenseList: licenseList} = props
    const [studyChannel, setStudyChannel] = useState(null);
    const studies = props?.studies;   

    const selectStudy = evt => {
        setStudyChannel(studies.filter(study => study.uid === evt.target.value))
        props.setlicenseList({...props.licenseList, [props.propskey] : evt.target.value})
        setBody({...body, [evt.target.value] : {
            studyid : evt.target.value,
            channels_read : [],
            channels_write : [],
            is_study_admin : false
        }})
    }

    const toggleIsAdmin = (e, uid) => {
        const is_study_admin = body[uid].is_study_admin ? false : true
        setBody({...body, [uid] : {
            ...body[uid], is_study_admin
        }})
    }

    const toggleReadPermission = (e, uid, channel) =>{
        if (body[uid].channels_read.includes(channel.key)){
            let channels_read = body[uid].channels_read
            channels_read = channels_read.filter(c => c!==channel.key)
            setBody({
                ...body, [uid]: {
                    ...body[uid], channels_read
                }
            }) 
        } else
        setBody({
            ...body, [uid]: {
                ...body[uid], channels_read : [...body[uid].channels_read, channel.key]
            }
        })
    }

    const toggleWritePermission = (e, uid, channel) => {
        if (body[uid].channels_write.includes(channel.key)) {
            let channels_write = body[uid].channels_write
            channels_write = channels_write.filter(c => c !== channel.key)
            setBody({
                ...body, [uid]: {
                    ...body[uid], channels_write
                }
            })
        } else
            setBody({
                ...body, [uid]: {
                    ...body[uid], channels_write: [...body[uid].channels_write, channel.key]
                }
            })
    }

    const removePermissions = (uid) =>{
        setBody({
            ...body, [uid]: {
                studyid: uid,
                channels_read: [],
                channels_write: [],
                is_study_admin: false
            }
        })

        Object.keys(licenseList).map(key => {
            if(licenseList[key] === uid){
                delete licenseList[key]; 
            }
        });
    
    }

    useEffect(() => {
        let currentlySelectedStudies = Object.keys(licenseList).map(k => licenseList[k])
        let studiesInBody = Object.keys(body)
        let studiesToRemove = studiesInBody.map(study => !currentlySelectedStudies.includes(study) ? study : null)
        let newBody = body

        studiesToRemove.forEach(toRemove => {
            if(newBody[toRemove]){
                delete newBody[toRemove]
            }
        })

        setBody(newBody)



    }, [licenseList])

    return <div className='study-list'>
        <select name="cars" id="cars" onChange={evt => selectStudy(evt)}>
            <option value=''>--Select Study--</option>
            {studies.map(study => {
                // console.log(Object.keys(props.licenseList).map(k => (props.licenseList[k])), study.uid)
                return <option value={study?.uid} disabled={props.selectedStudies.includes(study?.uid)} key={study?.uid}>{study?.label}</option> 
            } )}
            {/* {studies.map(study => <option value={study?.uid} key={study?.uid} disabled={study?.selected}>{study?.label}</option>)} */}
        </select>
        {studyChannel && <>
            {studyChannel.map(study => <React.Fragment key={`${study?.uid}`}>
                <div className='study-admin-checkbox'>
                    <input type="checkbox" id={study?.uid} name={study?.uid} checked={body[study?.uid].is_study_admin}  onChange={e=> toggleIsAdmin(e, study?.uid)}/>
                    <label htmlFor={study?.uid}> isStudyAdmin</label>
                </div>
                <div className="channels-wrapper">
                    <span className='field-label channel-head'><span className="label">Channels</span></span>
                    <span className='field-label channel-head'><span className="label">Read</span></span>
                    <span className='field-label channel-head'><span className="label">Write</span></span>
                    {study?.channels.map((channel, idx) =>
                        <React.Fragment key={`${channel?.key}`}>
                            <span className='field-label'>{channel.key}</span>
                            <span><input type="checkbox" checked={body[study?.uid].channels_read.includes(channel.key)} onChange={e => toggleReadPermission(e, study.uid, channel)} /></span>
                            <span><input type="checkbox" checked={body[study?.uid].channels_write.includes(channel.key)} onChange={e => toggleWritePermission(e, study.uid, channel)}/></span>
                        </React.Fragment>)}
                </div>
                <button type='button' title='Remove Permission' className='permission-btn' role='button' aria-label='remove permission' onClick={e => removePermissions(study?.uid, study)}>
                    <i className="fal fa-trash-alt">&nbsp;</i>
                </button>
            </React.Fragment>)
            }
        </>}
    </div>
}


export const UserListElement = (props) => {

    const {userid, auth} = props
    const user = props?.user;
    const [showStudy, setShowStudy] = useState(props?.selectedUser === user.email ? true : false);
    const [selectedStudy, setSelectedStudy] = useState();
    const [studies, setStudies] = useState(props?.studies);
    const [licenseList, setlicenseList] = useState({});

    const [body, setBody] = useState({})

    let tempBody = [];
    Object.keys(licenseList).map(list => {
        tempBody.push(body[licenseList[list]])
    });


    const submitPermission = (e) => {
        e.preventDefault();
        setSelectedStudy(true);
        const reqBody = tempBody;
        reqBody.forEach(body => {
            body.channels_read = JSON.stringify(body.channels_read);
            body.channels_write = JSON.stringify(body.channels_write);
        });

               
        
        apiService.aPost(auth, `${globals.apiRoot}/account/set_user_permissions?userid=${userid}`, reqBody).then(res => res.json()).then(res => {
            setSelectedStudy(false);
            if(res.success){
                topBar.enqueueSnack({
                    id: parseInt(+new Date().getMilliseconds() + Math.random().toFixed()),
                    message: `User has been approved and permissions have been set properly`,
                    to: 3000,
                    success: false
                })
                props?.userList?.setUserList(props?.userList?.userList?.filter(user => user.uid !== userid));
            }
        })

    }

    const rejectUserApproval = (userid) => {
        apiService.aPost(auth, `${globals.apiRoot}/account/reject_permissions_and_delete_user?userid=${userid}`, null).then(res => res.json()).then(res => {
            topBar.enqueueSnack({
                id: parseInt(+new Date().getMilliseconds() + Math.random().toFixed()),
                message: `${res}`,
                to: 3000,
                success: false
            })
            props?.userList?.setUserList(props?.userList?.userList?.filter(user => user.uid !== userid));
        })
    }

    const removeLicensesFromUser = (userid) => {
        const reqBody = tempBody;
        const studyIds = reqBody.map(m => m.studyid) || []
        apiService.aPost(auth, `${globals.apiRoot}/account/removeLicenses/${userid}`, studyIds).then(res => res.json()).then(res => {
            if(res.success){
                topBar.enqueueSnack({
                    id: parseInt(+new Date().getMilliseconds() + Math.random().toFixed()),
                    message: `Licenses successfully removed for this user`,
                    to: 3000,
                    success: false
                })
            }
        })
          
    }
    
    useEffect(() => {
       let key = Date.now();
       setlicenseList({[key] : ''})
    }, [])

    return <li>
        <div style={{}}>
            <div className='user-wrapper'>
                <div className="user-info">
                    <h3>{user.name}</h3>
                    <div className="user-email">{user.email}</div>
                    <div style={{color: `${user.isSuperAdmin ? 'green' : 'red'}`}}>{ user.isSuperAdmin ? 'User has all licenses' : '**Be careful not to add the same license user already has**'}</div>
                </div>
                {/* <div className='user-action'>
                    <button type="button" className="approve-btn btn" onClick={e => setShowStudy(!showStudy)} role='button' aria-label='set permission'>
                        Set Permissions
                </button>
                    {!showStudy && <button type="button" className="reject-btn btn" onClick={e => rejectUserApproval(user.uid)} role='button' aria-label='reject'>
                        Reject
                </button>}
                </div> */}
            </div>
            {
                !user.isSuperAdmin ?
                 <div className="study-approve-content">
                    <form onSubmit={e => submitPermission(e)}>
                        <div className="study-wrapper">
                            {Object.keys(licenseList).map((key) => <LicenseListElement body={body} licenseList={licenseList} setlicenseList={setlicenseList} setBody={setBody} studies={studies} key={key} propskey={key} selectedStudies={Object.keys(licenseList).map((key => licenseList[key]))}   />)}
                            {Object.keys(licenseList).length < studies.length &&
                                <div className={"study-list" + (setSelectedStudy ? ' no-study' : '')}>
                                    <button type="button" className='licence-btn' onClick={e => setlicenseList({...licenseList, [Date.now()] : ''})}>
                                        <i className="fal fa-plus">&nbsp;</i>
                                        Add Addnl License
                                    </button>
                                </div>}

                        </div>
                        <div className="action-head">
                            <button type="submit" className={"action-button " + (selectedStudy ? 'req-sending': '')} role='button' aria-label='approve'>Assign</button>
                            <button type="button" className="action-button reject-btn" onClick={e => removeLicensesFromUser(user.uid)} role='button' aria-label='reject'>Remove</button>
                        </div>
                    </form>
                    </div>
                    :
                    null
            }

        </div>
    </li>
}

export let StudyApprove = (props) => {

    const [userList, setUserList] = useState(null)
    const history = useHistory();

   let userMail = new URLSearchParams(props.location.search)
    userMail = decodeURIComponent(userMail.get('email')) || null;
    
   useEffect(() => {
        apiService.aGet(props.auth, `${globals.apiRoot}/account/pending_registrations`)
            .then((res) => res.json())
            .then((res) => {
                setUserList(res);
            })
            .catch((err) => console.error(err));
        }, [])
        
    const studies = props?.auth?.studies;

    const handleGoBack = () => {
        history.goBack();
    }

    return (userList ?
        <div className="page edit-profile">
            <PageHeader title={'Assign licenses to users'} fullWidth={true} />
            <div className="content">
                <button className='btn btn-primary border' style={{width:80, textAlign:'center'}} onClick={handleGoBack}>Go Back</button>
                <ul className="user-list">
                    {userList.map(user => <UserListElement userid={user.uid} selectedUser={userMail} key={user.uid} user={user} userList={{userList, setUserList}} studies={studies} auth={props.auth} />)}
                </ul>
            </div>
        </div> :null
    );
}


StudyApprove = withRouter(connect(state => ({
    auth: state.auth
}),
    dispatch => bindActionCreators(actionCreators, dispatch)
)(StudyApprove)
)