import { format } from "d3-format";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as analyticsActionCreators } from "../../store/Analytics.ts";
import { CardLoading } from "../card/CardLoading";
import { FilterChooser } from "../filter/FilterChooser";
import "./CorrelationsViewer.scss";
import { TableFred } from "../vis/TableFred";


const varEntries = [
	{
		label: "Vehicle Appeal",
		key: "raw",
	},
	{
		label: "Utils",
		key: "utils",
	},
	{
		label: "Know Well/Consider",
		key: "know_well_consider",
	},
	{
		label: "Know Little/Consider",
		key: "know_little_consider",
	},
	{
		label: "Own/Buy Again",
		key: "own_buy_again",
	},
];

export class CorrelationsViewer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			var: props.var || varEntries[0].key,
		};

		// Allow card to be saved as insight card. Not the greatest pattern.
		this.props.stateContext.getSaveState = this.getSaveState;
	}

	componentDidMount() {
		this.refreshIfNeeded();
	}

	componentDidUpdate() {
		this.refreshIfNeeded();
	}

	refreshIfNeeded = () => {
		// cancel if cached data already exists

		if (this.props.cache) return; // insights card

		const fullCache = this.props.fullCache;
		const rootPath = this.props.path;
		const v = this.props.var || this.state.var;
		const path = `${rootPath}/${v}`;
		const cache = fullCache ? fullCache[path] : null;

		if (cache && cache.loading) return;
		if (cache && cache.error) return;
		if (cache && cache.value) return;

		// refresh is needed. request it.
		this.props.loadAnalytics(path);
	};

	static inheritOptions = ["option"]; //, 'optionLabel'];

	static getTitle = (state) => {
		return `${state.title}`;
	};

	static getSources = (state) => state.sources || [];

	getSaveState = () => {
		let cache = this.props.cache;
		if (!cache) {
			const fullCache = this.props.fullCache;
			const rootPath = this.props.path;
			const v = this.props.var || this.state.var;
			const path = `${rootPath}/${v}`;
			cache = fullCache ? fullCache[path] : null;
		}

		return {
			option: this.props.option,
			var: this.props.var || this.state.var,
			cache,
		};
	};

	getColor = (val, minVal) => {
		if (isNaN(val)) return "#ffffff";

		let scale = 1.0 / (1.0 - minVal);
		let alpha = 1.0 - Math.abs((1.0 - val) * scale);

		//return `rgba(0,100,200,${alpha})`;
		return `rgba(255, 190, 0,${alpha})`;
	};

	render() {
		const study = this.props.study;
		if (!study) return;

		let cache = this.props.cache;
		if (!cache) {
			const fullCache = this.props.fullCache;
			const rootPath = this.props.path;
			const v = this.props.var || this.state.var;
			const path = `${rootPath}/${v}`;
			cache = fullCache ? fullCache[path] : null;
		}
		const data = cache ? cache.value : null;

		const slots = data
			? Object.keys(data).map((key) => ({
					uid: key,
					label: key,
			  }))
			: [];

		// if a slot can be found that matches option, move it to the front
		// const selSlot = slots.find(slot => slot.uid === this.props.optionLabel);
		// console.log('selSlot', selSlot, this.props);

		// find min value
		let minVal = null;
		if (data) {
			minVal = 1.0;
			Object.values(data).forEach((i1) => {
				Object.values(i1).forEach((i2) => {
					if (i2 < minVal) {
						minVal = i2;
					}
				});
			});
		}

		// var tableColumns = [
		//     {
		//         Header: '',
		//         id:'title_images',
		//         accessor: d => d,
		//         Cell: props=> <div className='label headrow' title={props.value.label}>
		//             <div className='row-title'>
		//                 {props.value.label}
		//             </div>
		//         </div>,
		//         fixed: 'left',
		//     }
		// ];

		// if( data ){
		//     slots.forEach(slot =>
		//         tableColumns.push({
		//             Header: props => <div key={slot.uid} className={'headcol'}>
		//                     <div className='hack1'>
		//                         {slot.label}
		//                     </div>
		//                 </div>,
		//             id:slot.uid,
		//             accessor: d => d,
		//             Cell: props=> <div className={'data'}
		//                     style={{ backgroundColor: this.getColor(data[props.value.uid][slot.uid],minVal)}}
		//                 >
		//                     {format('.3f')(data[props.value.uid][slot.uid])}
		//                 </div>
		//         })
		//     )
		// }

		//cloumns
		const columns = [
			{
				key: "label",
				type: "string",
				className: "label sticky",
				label: "",
			},
			...slots.map((slot) => ({
				key: slot.uid,
				type: "cell",
				className: "data right-align",
				label: slot.label,
			})),
		];

		if (data) {
			var tableRows = Object.keys(data).map((key) => {
				return { label: key, ...data[key] };
			});
			tableRows.forEach((item) => {
				slots.map((slot) =>
					Object.assign(item, {
						[slot.label]: {
							value: format(".3f")(item[slot.label]),
							bgColor: this.getColor(item[slot.label], minVal),
						},
					}),
				);
			});
		}

		const content = !cache ? null : cache.loading ? null : (
			// <div className='correlations-table-container' style={{userSelect: 'none'}}>
			//     <ReactTableFixedColumns className={'correlations-table'}

			//         data={slots}
			//         columns={tableColumns}
			//         showPagination={false}
			//         defaultPageSize={slots.length}
			//     />
			// </div>;
			<TableFred
				getJSONData={this.props.getJSONData}
				columns={columns}
				data={tableRows}
			/>
		);

		const selVar =
			varEntries.find((e) => e.key === this.props.var || this.state.var) || {};

		if (
			cache &&
			!cache.loading &&
			this.props.loaded &&
			typeof this.props.loaded === "function"
		)
			this.props.loaded(!cache.loading);
		if (
			this.props.getJSONData &&
			typeof this.props.getJSONData === "function" &&
			columns &&
			tableRows
		)
			this.props.getJSONData({ columns: [...columns], data: [...tableRows] });

		return (
			<div className="widget correlations-viewer">
				<div className="widget-header">
					<div className="title">{this.props.title}</div>
					<div className="filter-etc">
						<FilterChooser
							mini={true}
							disabled={true}
							selection={globals.getDefaultFilter(this.props.study)}
						/>
					</div>
					{this.props.inDataCard && (
						<select
							value={this.props.var || this.state.var}
							onChange={(ev) => this.setState({ var: ev.target.value })}>
							{varEntries.map((e) => (
								<option key={e.key} value={e.key}>
									{e.label}
								</option>
							))}
						</select>
					)}
					{this.props.inInsightCard && (
						<label className="ml-1">
							{varEntries.filter((i) => i.key === this.state.var)[0].label}
						</label>
					)}
				</div>
				<div className="widget-body vscroll hscroll">{content}</div>
				<CardLoading loading={cache && cache.loading} />
			</div>
		);
	}
}

CorrelationsViewer = connect(
	(state, ownProps) => ({
		study: state.study,
		...(ownProps.inDataCard
			? {
					//cache: state.cache.analytics[ownProps.path]
					fullCache: state.cache.analytics,
			  }
			: {}),
	}),
	(dispatch) => bindActionCreators(analyticsActionCreators, dispatch),
)(CorrelationsViewer);
