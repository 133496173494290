import React, { useState } from 'react'
import { Bar, ComposedChart, CartesianGrid, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { format as d3Format } from 'd3-format'
import {useSelector} from 'react-redux'
import { FilterChooser } from '../filter/FilterChooser'

const formatValue = d3Format('.4f')

export const CovidWidget = (props) => {
  const [state, setState] = useState({
		diff: [],
		step2Arr: [],
		whatIf: [0.137, 0.114, 0.695, 0.528, 0.492, 0.075, 0.023, 0.279, 0.074, 0.052, 0.041, 0.462, 2.324, 2.445, 0.288, 3.114, 4.287, 8.584, 6.939, 6.829, 0.172, 0.374, 0.195, 0.294, 0.181, 0.141, 0.244, 0.445, 0]
  })
  
  const filter = props.filter || useSelector(state=>state.filter.selectedFilter)
	
  const baseline = [0.193, 0.121, 0.719, 0.574, 0.511, 0.057, 0.021, 0.316, 0.075, 0.04, 0.021, 0.424, 2.292, 2.386, 0.281, 3.038, 4.299, 8.851, 7.296, 7.23, 0.231, 0.413, 0.148, 0.298, 0.18, 0.136, 0.225, 0.482, 3.101];

  const waves = [
		['Wave 1', 'Wave 2', 'Wave 3', 'Wave 5', 'Wave 7', 'Wave 9'],
    [0.21, 0.199, 0.199, 0.182, 0.151, 0.137],
    [0.089, 0.107, 0.127, 0.13, 0.113, 0.114],
    [0.68, 0.706, 0.734, 0.718, 0.74, 0.695],
    [0.544, 0.562, 0.589, 0.57, 0.574, 0.528],
    [0.257, 0.45, 0.538, 0.545, 0.558, 0.492],
    [0.071, 0.045, 0.062, 0.065, 0.062, 0.075],
    [0.034, 0.018, 0.024, 0.02, 0.015, 0.023],
    [0.354, 0.318, 0.331, 0.299, 0.297, 0.279],
    [0.124, 0.078, 0.079, 0.067, 0.081, 0.074],
    [0, 0.026, 0.041, 0.052, 0.03, 0.052],
    [0, 0.015, 0.021, 0.027, 0.034, 0.041],
    [0.386, 0.393, 0.444, 0.436, 0.457, 0.462],
    [2.23, 2.266, 2.327, 2.283, 2.316, 2.324],
    [2.616, 2.489, 2.385, 2.285, 2.323, 2.445],
    [0.269, 0.27, 0.298, 0.274, 0.331, 0.288],
    [2.949, 2.98, 3.073, 3.061, 3.039, 3.114],
    [4.179, 4.252, 4.373, 4.273, 4.298, 4.287],
    [8.697, 8.838, 9.013, 8.703, 8.677, 8.584],
    [7.205, 7.205, 7.659, 7.025, 6.833, 6.939],
    [7.416, 7.233, 7.5, 6.958, 6.83, 6.829],
    [0.308, 0.226, 0.268, 0.198, 0.166, 0.172],
    [0.457, 0.4, 0.444, 0.396, 0.379, 0.374],
    [0.142, 0.139, 0.143, 0.163, 0.183, 0.195],
    [0.265, 0.272, 0.288, 0.335, 0.328, 0.294],
    [0.196, 0.177, 0.172, 0.19, 0.202, 0.181],
    [0.125, 0.137, 0.132, 0.138, 0.127, 0.141],
    [0.195, 0.229, 0.216, 0.231, 0.206, 0.244],
    [0.516, 0.498, 0.504, 0.445, 0.466, 0.445],
    [3.115, 3.01, 3.216, 3.078, 3.053, 3.267],
	];
	
	const sumProductArray = (diff) => {
		const items = [
			[0.04348108,0,0.07620252,0.06446444,0.3187806,0.11505748,0.09909518,0.14706804,0.11229488,0.0770676,0.06705138,0,0.1085156,0.17372186],
			[0.04348108,0,0.07620252,0.06446444,0.3187806,0.11505748,0.09909518,0.14706804,0.11229488,0.0770676,0.06705138,0,0.1085156,0.17372186],
			[-0.00647716,0.449082667,0.330945067,0.162919173,0.22237248,0.337975467,0.264378747,0.289608533,0.259328213,0,0.17368712,0,0.403524267,0],
			[-0.00647716,0.449082667,0.330945067,0.162919173,0.22237248,0.337975467,0.264378747,0.289608533,0.259328213,0,0.17368712,0,0.403524267,0],
			[-0.00647716,0.449082667,0.330945067,0.162919173,0.22237248,0.337975467,0.264378747,0.289608533,0.259328213,0,0.17368712,0,0.403524267,0],
			[0.12488544,0,0.19644816,0.5419188,0.39965316,0.23992404,0.04062348,0,0,0.04560628,0.06761488,0,0.0410938,0.08443048],
			[0.12488544,0,0.19644816,0.5419188,0.39965316,0.23992404,0.04062348,0,0,0.04560628,0.06761488,0,0.0410938,0.08443048],
			[0.27280708,0,0,0,0.09840696,0.11332048,0.22290784,0,0.1151794,0.09621796,0.1702378,0.1926262,0.13936484,0.07459164],
			[0.27280708,0,0,0,0.09840696,0.11332048,0.22290784,0,0.1151794,0.09621796,0.1702378,0.1926262,0.13936484,0.07459164],
			[0.12895728,0,0.023736908,0.25340376,0.27676056,0.05894372,0.1064818,0,0,0.095081,0.076165,-0.019116324,-0.02069404,0.04892096],
			[0.12895728,0,0.023736908,0.25340376,0.27676056,0.05894372,0.1064818,0,0,0.095081,0.076165,-0.019116324,-0.02069404,0.04892096],
			[0.34754536,0,0,0,0,0,0,0,0.35819848,0,0.38454552,0.40575912,0.1064108,0],
			[0,0,0,0,0,0.01914832,0,0,0,0,0,-0.02479005,0.08228087,-0.001411895],
			[0.03372842,0,0,0,0,-0.01537614,0.01303766,-0.02989812,-0.05365946,0,0.06810733,0,-0.04301143,0],
			[0.07468439,0,0,0,0,-0.02669577,0.08630674,0,0,-0.02302209,0,0,0.04590014,-0.0252456],
			[0,0,0,0,0,0,0,0,0,0,0,0,0,0.11074805],
			[0.2001006,0,0,0,0,0.0183449,0.03153855,0.005084817,-0.01592201,0,0,0,0.05413818,0.10509075],
			[0,0,0,0,0,0.03172857,0,0,0,0,0,0,0,0],
			[0.03099847,0,0,0,0,0.2421153,0.1122642,0,0,-0.03933495,0,0.002442424,0,0.01906225],
			[0,0,0,0,0,0.1151146,0.09430438,0,0,0.02414152,0,-0.01317857,0,0.04458503],
			[-0.0585816,0,0,0,0,0,0.6401238,0,0,0.357785,0.2895554,0,0,0.05929148],
			[0,0,0,0,0,0,0,0,0,0.4300598,0,-0.407404,0,0.02318248],
			[0,0,0,0,0,0,0,0,0,0.3866266,0,0,0,0],
			[0,0,0,0,0,0,0,0,0,0.15006826,0,0,0,0],
			[-0.006576238,0,0,0,0,0,0,0,0,0,0,0,0,0.08674446],
			[0.0340613,0,0,0,0,0,0,0,0,0,0,0,0,0],
			[0,0,0,0,0,0,0,0,0,0,0,0,0,-0.011101201],
			[0,0,0,0,0,0,0,0,0,0,0,0,0,0.0101012],
			[0,0,0,0,0,0,0,0,0,0,0,0,0,0],
		]
		
		var length = items[0].length
		var sumProduct = []
		
		for (let i = 0; i < length; i++) {
			var result = diff.map((j, idx) => j * items[idx][i])
			.reduce((acc, curVal) => {
				return acc + curVal
			}, 0)
			sumProduct.push(result)
		}
		
		return sumProduct
	}
		
  props.stateContext.getSaveState = () => {
    return {filter};
  };
  
	const sumDiff = diff => {
		var step1 = sumProductArray(diff)
	
		step1 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,...step1]
	
		var diffStep2 = diff.map((i, idx) => i + step1[idx])
		var step2 = sumProductArray(diffStep2)
	
		return step2.slice(step2.length-1)[0] + step1.slice(step1.length-1)[0] + baseline.slice(baseline.length-1)[0]
	}

	var wavesLength = waves[0].length,
	step2Arr = [];

	for (let k=0;k<wavesLength;k++) {
		var diff = baseline.map((i, idx) => waves[idx+1][k] - i)
		step2Arr.push(sumDiff(diff))
	}

	diff = baseline.map((i, idx) => state.whatIf[idx] - i)
	var _step2Arr = sumDiff(diff)
	step2Arr = [...step2Arr, _step2Arr]
	
	return (
    <div className="widget">
      <div className="widget-header">
        <div className="title">{props.title}</div>
        <div className="filter-etc">
          <FilterChooser selection={filter} disabled={props.inInsightCard} mini={true} />
        </div>
      </div>
      <div className='widget-body vscroll hscroll no-pad'>
        {props.expanded && (
					<>
						<table className='table-fred'>
							<thead>
								<tr className='header-row'>
									{[...waves[0], 'What If'].map((i, idx) => (
										<th key={idx} className='data'>
											{i}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
									{waves.slice(1).map((row, rowIndex) => (
										<tr key={rowIndex} className='data-row'>
											{row.map((col, colIndex) => (
												<td key={colIndex}>{col*100}</td>
											))}
											<td>
												<input
													defaultValue={formatValue(state.whatIf[rowIndex])}
													onClick={e => e.target.value = state.whatIf[rowIndex]}
													onBlur={e => e.target.value = formatValue(state.whatIf[rowIndex])}
													onChange={e => {
														let whatif = [...state.whatIf]
														whatif[rowIndex] = parseFloat(e.target.value)
														setState({ whatIf: whatif })
													}}
													style={{ border: 'none' }}
												/>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</>
				)}

				<div style={{height: '100%'}}>
					<div style={{height: '60%'}}>
						<ResponsiveContainer>
							<ComposedChart data={step2Arr.map(i => ({ d: i }))} isAnimationActive={false} margin={{ top: 20, bottom: 10, right: 20, left: 70 }}>
								<CartesianGrid strokeDasharray="3 3" />
								<Tooltip formatter={(value, _, __) => formatValue(value)} />
								<Line dataKey="d" type="monotone" stackId="b" yAxisId="right" stroke='#ba4723' name="Covid 19">
									{/* <LabelList dataKey="d" position="top" /> */}
								</Line>
							</ComposedChart>
						</ResponsiveContainer>
					</div>
					<div style={{height: '60%'}}>
						<ResponsiveContainer>
							<ComposedChart data={step2Arr.map(i => ({ d: i }))} isAnimationActive={false} maxBarSize={50}>
								<XAxis dataKey="d" tickFormatter={formatValue} />
								<YAxis yAxisId="left" orientation="left" tickFormatter={formatValue} />
								<Tooltip formatter={(value, _, __) => formatValue(value)} />
								<Bar formatter={formatValue} dataKey="d" yAxisId="left" fill='#3969d9' name="Detractor">
									{/* <LabelList dataKey="d" position="center" /> */}
								</Bar>
							</ComposedChart>
						</ResponsiveContainer>
					</div>
				</div>
      </div>
    </div>
  )
}
