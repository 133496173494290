import * as React from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as globals from "../../globals";
import * as apiService from "../../utils/apiService";
import * as debounce from "../../utils/debounce";
import "./ElementNotesEditor.scss";
import { TabStrip2, Tab } from "../common/TabStrip";
import { CodePropertyEditor } from "./CodePropertyEditor";
import { ElementPropertyEditor } from "./ElementPropertyEditor";




const PROP_ID = {
	name: "id",
	type: "string(36)",
	required: true,
	readOnly: true,
	description:
		"Unique identifier for this element. Should be unique within study.",
};
const PROP_TYPE = { name: "type", type: "string", readOnly: true };

export const ElementNotesEditor = (props) => {
	const item = props.item;
	if (!item) return null;

	const requestClose = () => {
		if (props.closeRequested) {
			props.closeRequested();
		}
		if (props.reloadItem) {
			props.reloadItem();
		}
	};

	const type = item.type;

	let properties = [];
	properties.push({ ...PROP_ID, group: 'Notes' });
	properties.push({ ...PROP_TYPE, group: 'Notes' });
	properties.push({
		name: "data.devStatus",
		group: "Notes",
		type: "select",
		examples: [
			"ok",
			"todo",
			"needs research",
		],
	});
	properties.push({
		name: "data.notes",
		group: "Notes",
		type: "markdown",
		codePrec: "Notes (markdown):",
		codePost: "",
		editorHeight: 300,
	});

	const propertyGroups = properties.reduce((acc, p) => {
		const groupLabel = p.group || "General";
		let group = acc.find((entry) => entry.label === groupLabel);
		if (!group) {
			group = { label: groupLabel, properties: [] };
			acc.push(group);
		}
		group.properties.push(p);
		return acc;
	}, []);

	const [selTabIndex, setSelTabIndex] = useState(0);

	const selPropertyGroup = propertyGroups[selTabIndex];

	const me = ( //<div className={'element-property-editor ' + (props.floating ? 'floating' : '')}>
		<div className="element-property-editor-border">
			<div className="main">
				<TabStrip2
					style={{ margin: "10px 20px" }}
					selectedIndex={selTabIndex}
					selectedIndexChanged={(selectedTabIndex, selectedTabValue) =>
						setSelTabIndex(selectedTabIndex)
					}>
					{propertyGroups.map((group) => (
						<Tab value={group.label} key={group.label}>
							{group.label}
						</Tab>
					))}
				</TabStrip2>
				<div className="tabpage">
					<div className={"element-property-editor modal"}>
						{/* <div className="col-header" />
						<div className="col-header">PropertyName</div>
						<div className="col-header">PropertyValue</div> */}
						{selPropertyGroup?.properties.map((p) => {
							const updateContentItem = debounce.debounce(
								(auth, study_uid, id, body, callback) => {
									const response = apiService
										.aPatch(
											auth,
											`${globals.apiRoot}/study/${study_uid}/content/${id}`,
											body,
										)
										.then(() => {
											console.log("updated");
											if (callback) {
												callback();
											}
										})
										.catch((err) => {
											console.error(response);
										});
								},
								1000,
							);

							return (
								<ElementPropertyEditor
									key={p.name}
									item={item}
									prop={p}
									xreloadItem={props.reloadItem}
									updateContentItem={updateContentItem}
								/>
							);
						})}
					</div>
				</div>
			</div>
			<div className="footer">
				<div className="btn border rounded inline-block" onClick={requestClose}>
					Close
				</div>
			</div>
		</div>
	);

	const modalRoot = document.getElementById("modal-root");

	return ReactDOM.createPortal(
		<div className="element-notes-editor-modal-container">{me}</div>,
		modalRoot,
	);
};
