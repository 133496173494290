import * as React from 'react';
import { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { useDispatch, useSelector } from 'react-redux';
import './Tile.scss';
import { Markdown } from './Markdown';
import { FILTER_DESELECT_ALL, FILTER_SELECT } from '../../store/action-types.ts';
import { actionCreators as selectionsActionCreators } from '../../store/Selections.ts';
import * as selectionHelper from '../../utils/selectionHelper';
import { COMPARE_ALL_DESELECT, COMPARE_GROUP_SELECT } from '../../store/action-types.ts';

export function Tile(props) {


    var icon = React.createRef(),
        title = React.createRef();

    const payload = {
        tag: props.tag,
        filter: props.filter,
        label: props.label
    };

    const tileBackground = props.tileBackground || '#e7d3f6';
    const tileRadius = props.tileRadius || 0;

    const history = useHistory();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const selections = state.selections;

    useEffect(() => {
            props?.setLayoutReady?.(true);
    }, []);

    let gridStyle = {
        gridTemplateRows: '1fr'
    };
    let insideStyle = {
        backgroundColor: tileBackground,
        borderRadius: tileRadius
    };

    let style = {};
    if (props.tileHeight) {
        style.height = props.tileHeight;
    }

    if (props.tileWidth) {
        style.width = props.tileWidth;
    }

    if (props.tileMargin) {
        style.margin = props.tileMargin;
    }

    let fg = null;
    if (props.tileForeground) {
        style.color = props.tileForeground;
        fg = {
            color: props.tileForeground
        }
    }
    if (props.tileBackgroundImage) {
        insideStyle.backgroundImage = `url(${props.tileBackgroundImage})`;
        insideStyle.backgroundPosition = 'center'; /* Center the image */
        insideStyle.backgroundRepeat = 'no-repeat'; /* Do not repeat the image */
        insideStyle.backgroundSize = 'cover'; /* Resize the background image to cover the entire */
    }

    const markdown = props.contentMarkdown;
    if (markdown) {
        gridStyle.gridTemplateRows = '1fr min-content';
    }

    if (props.to) {
        return <Link
            key={props.tag + props.label}
            className={"tile " + (props.filter ? ' has-filter' : '') + (props.noDrag ? ' filtered' : '') + (props?.disable === 1 ? ' link-disabled' : '')}
            style={style}
            to={props.to}
            title={props?.tooltip ? props?.tooltip : ''}
            onClick={e => {
                if (props?.disable === 1) {
                    e.preventDefault();
                }
                if (props.commands) {
                    e.preventDefault();

                    const commands = props.commands || [];
                    commands.forEach(cmd => {
                        console.log('cmd', cmd);
                        if (cmd.type === 'filter') {
                            dispatch({ type: FILTER_DESELECT_ALL });
                            if (cmd.filters) {
                                cmd.filters.forEach(f => {
                                    dispatch({ type: FILTER_SELECT, groupId: f.folderId, filterId: f.id });
                                })
                            }
                            else if( cmd.id ) {
                                dispatch({ type: FILTER_SELECT, groupId: cmd.folderId, filterId: cmd.id });
                            }
                            
                        }
                        else if (cmd.type === "select") {
                            // const key = cmd.key;
                            // const value = cmd.value;
                            const v2 = selectionHelper.getSelectionInfo(cmd.key, cmd.value, state.study, selections.hierarchy);
                            selectionsActionCreators.setSelection(cmd.key, v2, true, () => {
                            })(dispatch, () => state);
                        }
                        else if (cmd.type === "compare") {
                            console.log('compare');
                            dispatch({ type: COMPARE_ALL_DESELECT });
                            if (cmd.groups) {
                                cmd.groups.forEach(g => {
                                    console.log('selecting compare', g);
                                    dispatch({ type: COMPARE_GROUP_SELECT, folderId: g.folderId, groupId: g.id, selected: true });
                                })
                            }
                            else if (cmd.id) {
                                console.log('selecting compare', cmd);
                                dispatch({ type: COMPARE_GROUP_SELECT, folderId: cmd.folderId, groupId: cmd.id, selected: true });
                            }
                        }
                        else {
                            console.log('unknown command', cmd);
                            alert('unknown command. see console.');
                            // todo: get 'subType'
                        }
                    })

                    history.push(props.to);
                    

                }
                
            }}
        >
            <div className='overlay' style={{ borderRadius: tileRadius }} />



            <div className='tile-grid' style={gridStyle}>
                <div className='inside' style={insideStyle}>
                    {props.icon ? <span className='glyph' style={{ ...fg }}>{props.icon.split(';').map(i => <i key={i} className={`glyph ${i}`} style={{ ...fg }} />)}</span> : null}
                    <span className='label'>
                        {props.label}
                    </span>
                </div>
                {markdown ?
                    <div className='outside'>
                        <Markdown data={markdown} />
                    </div>
                    : null}
            </div>
        </Link>
            ;
    }

    // if (props.fields && props.onSave && props.onCancel) {
    //     return <div 
    //         key={props.tag + props.label || 'add title'} 
    //         className={"tile new-tile"} 
    //         style={{ backgroundColor: tileBackground }}>
    //             <input type='text' placeholder='Add icon' defaultValue={JSON.stringify({icon: ''})} ref={icon} />
    //             <input type='text' placeholder='Add title' ref={title} />
    //             <div className="tile-action">
    //                 <button onClick={() => props.onSave(icon.current.value, title.current.value)}>
    //                     <i className='fas fa-check'></i>
    //                 </button>
    //                 <button onClick={props.onCancel}>
    //                     <i className='fas fa-times'></i>
    //                 </button>
    //             </div>
    //     </div>;
    // }


    return <div
        key={props.tag + props.label}
        className={"tile " + (props.editTile ? "edit-tile" : "") + (props.filter ? ' has-filter' : '') + (props.noDrag ? ' filtered fg' : '')}
        style={{ backgroundColor: tileBackground }}
        onClick={ev => props.onClick ? props.onClick(payload) : null}>
        <div className='overlay' />
        {props.icon ? <span className={!props.editTile ? 'glyph' : ''}>{props.icon.split(';').map(i => <i key={i} className={(!props.editTile ? 'glyph ' : '') + `${i}`} />)}</span> : null}
        <span className='label'>
            {props.label}
        </span>
    </div>;



}
