import * as React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Auth.ts';
import { Avatar } from '../common/Avatar';
import { PageHeader } from '../common/PageHeader';
import './EditProfile.scss';

export class EditProfile extends React.Component {
    readURL(event) {
        if(event.target.files[0].type.split('/')[0] === 'image'){
            const formData = new FormData();
            formData.append('File', event.target.files[0]);
            this.props.uploadAvatar(formData, (res, err) => {
                if (res) {
                    window.location.reload();
                }
            });
        } else{
            alert('Please upload a valid format of image e.g. png, jpg');
        }
    }

    updateProfile() {
        //TODO: stay on license you can in on.
        this.props.history.push('/')
    }

    render() {

        const user = this.props.user;
        if( !user ) return null;

        return (
            <div className="page edit-profile">
                <PageHeader title={'Edit Profile'} fullWidth={true} />
                <div className="content">
                    <div className="edit-profile-body">
                    <div className="profile-body">
                    <div className="user-profile">
                            <Avatar uid={user.id} />
                            <input type="file" name="user-profile" onChange={e => this.readURL(e)} title="Upload Profile Picture" />
                        </div>
                    </div>
                        <div className="input-group">
                            <label>Name</label>
                            <input
                                type="text"
                                defaultValue={user.name}
                            />
                        </div>
                        <div className="input-group">
                            <label>Email</label>
                            <input
                                type="email"
                                disabled
                            />
                        </div>
                        <div className="input-group">
                            <label>company</label>
                            <input
                                type="text"
                                defaultValue={user.company}
                            />
                        </div>
                        {/* <div className="input-group">
                            <label>account</label>
                            <input
                                type="text"
                                disabled
                            />
                        </div> */}
                        {/* <div className="input-group">
                            <label>created</label>
                            <input
                                type="text"
                                defaultValue={user.created}
                                disabled
                            />
                        </div>
                        <div className="input-group">
                            <label>Last Login</label>
                            <input
                                type="text"
                                defaultValue={user.lastlogin}
                                disabled
                            />
                        </div> */}
                        <div className="action-head">
                            <span className="action-button" onClick={(e) => this.updateProfile()}>ok</span>
                            <Link to="/" className="action-button">cancel</Link>
                        </div>                   
                  </div>
            </div>
            </div>
    )
    }
}

EditProfile = withRouter(
    connect(
      state => ({
          auth: state.auth,
          user: state.auth?.user
      }),
      dispatch => bindActionCreators(actionCreators, dispatch)
    )(EditProfile)
);
