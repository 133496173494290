import { format as d3Format } from "d3-format";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as analyticsActionCreators } from "../../store/Analytics.ts";
import { CardLoading } from "../card/CardLoading";
import { FilterChooser } from "../filter/FilterChooser";
import { TableFred } from "../vis/TableFred";

const fmt_nps = d3Format(".0f");
const fmt_pct = d3Format(".0%");

const fmt_nps2 = d3Format(".1f");
const fmt_pct2 = d3Format(".1%");

export class NPSViewer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};

		// Allow card to be saved as insight card. Not the greatest pattern.
		this.props.stateContext.getSaveState = this.getSaveState;
	}

	componentDidMount() {
		this.refreshIfNeeded();
	}

	componentDidUpdate() {
		this.refreshIfNeeded();
	}

	refreshIfNeeded = () => {
		// cancel if cached data already exists

		if (this.props.cache) return; // insights card

		const fullCache = this.props.fullCache;
		const rootPath = this.props.path;

		const path = `${rootPath}`;
		const cache = fullCache ? fullCache[path] : null;

		if (cache && cache.loading) return;
		if (cache && cache.error) return;
		if (cache && cache.value) return;

		// refresh is needed. request it.
		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);
		const query = this.props.getQuery(this.props.selection, filter);
		this.props.loadYak(path, `${filter.syntax}:${path}`, query);
	};

	static inheritOptions = ["selection"];

	static getTitle = (state) => {
		return `${state.title}`;
	};

	static getSources = (state) => state.sources || [];

	getSaveState = () => {
		let cache = this.props.cache;
		if (!cache) {
			const fullCache = this.props.fullCache;
			const rootPath = this.props.path;
			const v = this.props.var || this.state.var;
			const path = `${rootPath}/${v}`;
			cache = fullCache ? fullCache[path] : null;
		}

		return {
			selection: this.props.selection,
			var: this.props.var || this.state.var,
			cache,
			filter: this.props.filter,
		};
	};

	render() {
		const study = this.props.study;
		if (!study) return;

		let cache = this.props.cache;
		// if( !cache ){
		//     const fullCache = this.props.fullCache;
		//     const rootPath = this.props.path;
		//     const v = this.props.var || this.state.var;
		//     const path = `${rootPath}/${v}`
		//     cache = fullCache ? fullCache[path] : null;
		// }
		const data = cache ? cache.value : null;
		const data2 = data
			? this.props.entries.map((entry) => {
					const topbox = (data[`${entry.survey_id}:topbox`] || {}).value;
					const botbox = (data[`${entry.survey_id}:botbox`] || {}).value;
					const midbox =
						isNaN(topbox) || isNaN(botbox) ? null : 1.0 - topbox - botbox;
					const nps =
						isNaN(topbox) || isNaN(botbox) ? null : (topbox - botbox) * 100.0;
					return {
						...entry,
						topbox,
						botbox,
						midbox,
						nps,
						const1: 1,
						n: (data[`${entry.survey_id}:topbox`] || {}).n,
					};
			  })
			: [];
		const data3 = data2.filter((f, i) => i < 10);
		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);
		//console.log(data3);

		//console.log('minVal', minVal);

		const columns = [
			{
				key: "label",
				type: "string",
				className: "label_sm",
				label: "Model",
				className: "label",
			},
			{
				key: "distrib",
				type: "stacked-bar",
				columns: [
					{
						key: "botbox",
						type: "cell",
						className: "data",
						format: fmt_pct,
						label: "Detractor",
						fill: globals.npsColors.detractor,
						color: globals.npsColors.detractorFg,
						fillHeader: true,
					},
					{
						key: "midbox",
						type: "cell",
						className: "data",
						format: fmt_pct,
						label: "Neutral",
						fill: globals.npsColors.neutral,
						color: globals.npsColors.neutralFg,
						fillHeader: true,
					},
					{
						key: "topbox",
						type: "cell",
						className: "data",
						format: fmt_pct,
						label: "Promoter",
						fill: globals.npsColors.promoter,
						color: globals.npsColors.promoterFg,
						fillHeader: true,
					},
				],
			},
			{
				key: "nps",
				type: "cell",
				className: "data",
				format: fmt_nps,
				label: "NPS",
			},
			{
				key: "gap",
				type: "cell",
				className: "data",
				format: fmt_nps,
				label: "Gap",
			},
		];

		let tableRows1 = data
			? this.props.entries.map((entry) => {
					const topbox = (data[`${entry.survey_id}:topbox`] || {}).value;
					const n = (data[`${entry.survey_id}:topbox`] || {}).n;
					const botbox = (data[`${entry.survey_id}:botbox`] || {}).value;
					const midbox =
						isNaN(topbox) || isNaN(botbox) ? null : 1.0 - topbox - botbox;
					const nps =
						isNaN(topbox) || isNaN(botbox) ? null : (topbox - botbox) * 100.0;
					return {
						...entry,
						topbox: {
							value: topbox,
							n,
						},
						botbox: {
							value: botbox,
							n,
						},
						midbox: {
							value: midbox,
							n,
						},
						nps: {
							value: nps,
							n,
						},
						gap: {},
						const1: 1,
						n: (data[`${entry.survey_id}:topbox`] || {}).n,
					};
			  })
			: [];

		let averageRow = tableRows1.reduce(
			(accum, row) => {
				accum.botbox = (accum.botbox || 0) + row.botbox.value;
				accum.midbox = (accum.midbox || 0) + row.midbox.value;
				accum.topbox = (accum.topbox || 0) + row.topbox.value;
				accum.rowCount++;
				return accum;
			},
			{ rowCount: 0 },
		);

		// compute average row
		averageRow.botbox = averageRow.botbox / averageRow.rowCount;
		averageRow.midbox = averageRow.midbox / averageRow.rowCount;
		averageRow.topbox = averageRow.topbox / averageRow.rowCount;
		averageRow = {
			key: "avg",
			label: "Average",
			className: "average-row",
			fill: "#cfcfcf",
			botbox: { value: isNaN(averageRow.botbox) ? "-" : averageRow.botbox },
			midbox: { value: isNaN(averageRow.midbox) ? "-" : averageRow.midbox },
			topbox: { value: isNaN(averageRow.topbox) ? "-" : averageRow.topbox },
			nps: {
				value: isNaN((averageRow.topbox - averageRow.botbox) * 100)
					? "-"
					: (averageRow.topbox - averageRow.botbox) * 100,
			},
			gap: { value: 0 },
		};

		// assign gap scores
		tableRows1.forEach((row) => {
			row.gap.value = isNaN(row.nps.value - averageRow.nps.value)
				? "-"
				: row.nps.value - averageRow.nps.value;
		});

		const rows = [averageRow, ...tableRows1].sort(
			(a, b) => b.nps.value - a.nps.value,
		);

		const content = !cache ? null : cache.loading ? null : (
			<center>
				<TableFred columns={columns} data={rows} style={{ margin: "0 10px" }} />
			</center>
		);

		if (
			cache &&
			!cache.loading &&
			this.props.loaded &&
			typeof this.props.loaded === "function"
		)
			this.props.loaded(!cache.loading);
		if (this.props.getJSONData && typeof this.props.getJSONData === "function")
			this.props.getJSONData({ columns: [...columns], data: [...rows] });

		return (
			<div className="widget">
				<div className="widget-header">
					{/* <div className='selection'>{this.props.option}</div> */}
					<div className="title">{this.props.title}</div>
					<div className="filter-etc">
						<FilterChooser
							disabled={this.props.inInsightCard}
							selection={filter}
							mini={true}
						/>
					</div>
				</div>
				<div className="widget-body vscroll hscroll no-pad">{content}</div>
				<CardLoading loading={cache && cache.loading} />
			</div>
		);
	}
}

NPSViewer = connect(
	(state, ownProps) => {
		const filter = state.filter
			? state.filter.selectedFilter
			: null || globals.getDefaultFilter(state?.study);

		return {
			study: state.study,
			...(ownProps.inDataCard
				? {
						cache: state.cache.analytics[`${filter.syntax}:${ownProps.path}`],
						filter,

						//fullCache: state.cache.analytics
				  }
				: {}),
		};
	},
	(dispatch) => bindActionCreators(analyticsActionCreators, dispatch),
)(NPSViewer);
