import * as React from "react";
import { useEffect } from 'react';
import "./ContentViewer.scss";
import { EditModeWrapper } from "./EditModeWrapper";
import { ContentLoader } from "./ContentLoader";

export const FlexContainer = (props) => {
    const item = props.item;
    if (!item) return null;

    const style = {
        ...item.data?.style,
    };

    style.flexDirection = item?.data?.flexDirection || 'row';
    style.justifyContent = item?.data?.flexJustifyContent || 'flex-start';
    style.alignItems = item?.data?.flexAlignItems || 'flex-start';

    useEffect(() => {
        if (props.item) {
            //console.log('dynamic card layout ready', props.item?.id);
            props.setLayoutReady?.(true);
        }
    }, []);

    

    const classes = ["flexbox-container"];
    if (!item.data?.wide) {
        classes.push("page-width");
    }


    return (
        <EditModeWrapper className="has-children" item={item} edit={props.edit} restoredItem={props?.restoredItem} reloadItem={props.reloadItem} reloadParent={props.reloadParentItem} pageLevel={props.pageLevel} >
            <div className={classes.join(" ")} style={style}>
                {!item.contents ? "add flex item(s)" : null}
                {item.contents?.map((child) => (
                    <ContentLoader
                        key={child.id}
                        id={child.id}
                        reloadParentItem={props.reloadItem}
                        edit={props.edit}
                        restore={props.restore}
                        restoredItem={child?.deleted}
                        pageLevel={props.pageLevel}
                    />
                ))}
            </div>
        </EditModeWrapper>
    );
};