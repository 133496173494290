import React from 'react'
import { useSelector } from 'react-redux'
import { FilterChooser } from '../filter/FilterChooser'
import { BubbleChart } from '../vis/BubbleChart'

export const NPSSegments = props => {
    const filter = props.filter || useSelector(state => state.filter.selectedFilter)

    props.stateContext.getSaveState = () => {
        return { filter };
    };
    let data = [
        ...props.entries.map((i, idx) => ({
            uid: idx+3,
            label: i.label,
            nps: parseInt(Math.floor(Math.random() * (1 + 100 +100)) - 100),
            sales: parseInt((Math.random() * 100) * (Math.random() * 10)),
            detractor: parseInt(Math.floor(Math.random() * (1 + 100 +100)) - 100),
            neutral: parseInt(Math.floor(Math.random() * (1 + 100 +100)) - 100),
            promoter: parseInt(Math.floor(Math.random() * (1 + 100 +100)) - 100),
        }))
    ]
    const content = <BubbleChart data={data} />
    
    return (
        <div className='widget'>
            <div className='widget-header'>
                {/* <div className='selection'>{this.props.option}</div> */}
                <div className='title'>
                    {props.title}
                </div>
                <div className='filter-etc'>
                    <FilterChooser selection={filter} disabled={props.inInsightCard} mini={true}/>
                </div>
            </div>
            <div className='widget-body vscroll hscroll no-pad'>
                {content}
            </div>
            {/* <CardLoading loading={cache && cache.loading}/> */}
        </div>
    )
}
