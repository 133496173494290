import * as globals from "../globals";
import * as apiService from "../utils/apiService";
import * as filterUtils from "../utils/filters";
import { actionCreators as selectionsCreator} from "../store/Selections";
import {
	STUDY_RESET,
	STUDY_RECEIVE,
	FILTER_DESELECT,
	FILTER_DESELECT_ALL,
	FILTER_GROUPS_RECEIVE,
	FILTER_GROUP_COLLAPSE,
	FILTER_GROUP_COLLAPSE_ALL,
	FILTER_GROUP_DESELECT,
	FILTER_GROUP_EXPAND,
	FILTER_GROUP_EXPAND_ONLY_SEARCHED_TERM,
	FILTER_SAMPLE_SIZES_RECEIVE,
	FILTER_SAMPLE_SIZES_REQUEST,
	FILTER_SELECT,
	FILTER_SET,
	FILTER_SET_CUSTOM_LABEL,
	FILTER_SET_USE_CUSTOM_LABEL,
	// @ts-ignore
} from "./action-types";

export interface FilterState {
	filterGroups: any[];

	isLoading: boolean;

	selectedFilter: any;

	selected: any; // this is like selectedFilter except that it has an entry for each datasetId

	defaultFilterLabel: string;
}

const initialState: FilterState = {
	filterGroups: [],
	isLoading: false,
	selectedFilter: {
		autoLabel: "Total Population",
		syntax: "1==1",
		label: "Total Population",
		name: "total"
	},
	selected: null,
	defaultFilterLabel: "Total Population",
};

export const actionCreators = {
	// requestFilters: studyId => async (dispatch) => {
	//     dispatch({ type: `${filtersActionType}:REQUEST_FILTERS` });
	//     try {
	//         const url = `${globals.apiRoot}/study/${studyId}/filters`;
	//         const response = await fetch(url);
	//         const filters = await response.json();
	//         dispatch({ type: `${filtersActionType}:RECEIVED_FILTERS`, filters});
	//     } catch (err) {
	//         console.error(err);
	//     }
	// },

	expandFilterGroup: (id: any) => (dispatch: any, getState: any) =>
		dispatch({ type: FILTER_GROUP_EXPAND, id }),

	collapseFilterGroup: (id: any) => (dispatch: any, getState: any) =>
		dispatch({ type: FILTER_GROUP_COLLAPSE, id }),

	expandOnlySearchedFilterGroups: (term: any) => (
		dispatch: any,
		getState: any,
	) => dispatch({ type: FILTER_GROUP_EXPAND_ONLY_SEARCHED_TERM, term }),

	collapseAllFilterGroups: () => (dispatch: any, getState: any) =>
		dispatch({ type: FILTER_GROUP_COLLAPSE_ALL }),

	refreshSampleSizes: () => async (dispatch: any, getState: any) => {
		const state = getState();
		const study = state.study;
		const filter = state.filter;
		const selectedFilter = filter.selectedFilter;
		const filterSyntax = selectedFilter
			? selectedFilter.syntax
			: null || "1==1";
		dispatch({ type: FILTER_SAMPLE_SIZES_REQUEST, syntax: filterSyntax });

		const query = {
			measures: [{ syntax: "validcount(respid)", id: "n" }],
			filters: [{ syntax: filterSyntax }],
		};

		try {
			const response = await apiService.aPost(
				state.auth,
				`${globals.apiRoot}/study/${study.uid}/compute/yak`,
				query,
			);
			if (response.ok) {
				const value = await response.json();
				//console.log('response value is', value);
				if (value && value.success) {
					dispatch({
						type: FILTER_SAMPLE_SIZES_RECEIVE,
						syntax: filterSyntax,
						n: value.results.n ? value.results.n.value : null || 0,
					});
				}
			}
		} catch (error) {
			console.error(error);
			dispatch({
				type: FILTER_SAMPLE_SIZES_RECEIVE,
				syntax: filterSyntax,
				n: null,
				error,
			});
		}
	},

	selectFilter: (groupId: any, filterId: any, filterName?: any, datasetId?: any) => (dispatch: any, getState: any,) => {		
		dispatch({ type: FILTER_SELECT, groupId, filterId, filterName, datasetId });
		actionCreators.refreshSampleSizes()(dispatch, getState);
	},

	deselectFilter: (groupId: any, filterId: any, datasetId?: any) => (dispatch: any,getState: any,) => {
		dispatch({ type: FILTER_DESELECT, groupId, filterId, datasetId });
		actionCreators.refreshSampleSizes()(dispatch, getState);
	},

	deselectFilterGroup: (groupId: any, datasetId?: any) => (dispatch: any, getState: any) => {
		dispatch({ type: FILTER_DESELECT, groupId, datasetId });
		actionCreators.refreshSampleSizes()(dispatch, getState);
	},

	deselectAll: (datasetId?: any) => (dispatch: any, getState: any) => {
		dispatch({ type: FILTER_DESELECT_ALL, datasetId });
		actionCreators.refreshSampleSizes()(dispatch, getState);
	},

	setUseCustomLabel: (useCustomLabel: any) => (dispatch: any) => {
		dispatch({ type: FILTER_SET_USE_CUSTOM_LABEL, useCustomLabel });
	},

	setCustomLabel: (customLabel: any) => (dispatch: any) => {
		dispatch({ type: FILTER_SET_CUSTOM_LABEL, customLabel });
	},
};

export const reducer = (state: FilterState = initialState, action: any): FilterState => {
	// state = state || initialState;
	
	switch (action.type) {
		
		case STUDY_RESET:
			return initialState;

		case STUDY_RECEIVE:
			let s = {
				...state,
				defaultFilterLabel:
					action.value?.defaultFilterLabel || state.defaultFilterLabel,
			}
			s.selectedFilter = filterUtils.getSelectedFilter(s)
			return s

		case FILTER_GROUPS_RECEIVE:

			// create a default for each datasetId (if any)
			const selected = action.filterGroups.reduce((acc: any, fg: any) => {
				if (fg.datasetId && !acc[fg.datasetId]) {
					acc[fg.datasetId] = filterUtils.getSelectedFilter(state, fg.datasetId)
				}
				return acc;
			}, {})
			
			return {
				...state,
				filterGroups: action.filterGroups,
				isLoading: false,
				selected
			};

		case FILTER_GROUP_EXPAND_ONLY_SEARCHED_TERM:
			return {
				...state,
				filterGroups: state.filterGroups.map((fg) => {
					if (fg.label.toLowerCase().includes(action.term.toLowerCase()))
						return {
							...fg,
							isExpanded: true,
							matchedGroup: true,
							filters: fg.filters.map((f: any) => ({ ...f, matched: true })),
						};
					return {
						...fg,
						isExpanded:
							fg.filters.filter((f: any) =>
								f.label.toLowerCase().includes(action.term.toLowerCase()),
							).length > 0,
						matchedGroup:
							fg.filters.filter((f: any) =>
								f.label.toLowerCase().includes(action.term.toLowerCase()),
							).length > 0,
						filters: fg.filters.map((f: any) =>
							f.label.toLowerCase().includes(action.term.toLowerCase())
								? { ...f, matched: true }
								: { ...f, matched: false },
						),
					};
				}),
			};

		case FILTER_GROUP_COLLAPSE_ALL:
			return {
				...state,
				filterGroups: state.filterGroups.map((fg) => ({
					...fg,
					isExpanded: false,
					filters: fg.filters.map((f: any) => ({ ...f, matched: false })),
				})),
			};

		case FILTER_GROUP_EXPAND:
			return {
				...state,
				filterGroups: state.filterGroups.map((fg) => ({
					...fg,
					isExpanded: fg.id === action.id ? true : fg.isExpanded,
					isSearchingItem: fg.id === action.id ? true : false,
				})),
			};

		case FILTER_GROUP_COLLAPSE:
			return {
				...state,
				filterGroups: state.filterGroups.map((fg) => ({
					...fg,
					isExpanded: fg.id === action.id ? false : fg.isExpanded,
					isSearchingItem: fg.id === action.id ? true : false,
				})),
			};
		
		case FILTER_SET: {

			let s = {
				...state,
				selectedFilter: action.selectedFilter,
				filterGroups: state.filterGroups.map((fg) => {
					if (action.selections) {
						let findFolder = action.selections?.find((sel: any) => sel.folderId === fg.id);
						// note: even if findGroup==null, we still want to make sure all filters are cleared
						let filters = fg.filters.map((f: any) => {
							let findFilter = findFolder?.selectedFilterIds?.find((fid: any) => fid === (f.id || f.syntax));
							if (findFilter) {
								return {
									...f,
									isSelected: true
								};
							}
							else {
								let r = {
									...f,
									isSelected: undefined
								};
								delete r.isSelected;
								return r;
							}
						})
						return { ...fg, filters };
					}
					else
						return fg;
				})
			}
			s.selectedFilter = filterUtils.getSelectedFilter(s);
			return s;
		}

		case FILTER_SELECT: {

			const { groupId, filterId, filterName, datasetId } = action
			
			//alter state(filterstate)
			if (filterName) {
				state = {
					...state,
					selectedFilter: {
						...state.selectedFilter,
						syntax: filterId,
						name:filterName
					}
				}
			}

			
			let s = {
				...state,
				filterGroups: state.filterGroups.map((fg) =>
					fg.id === groupId
						? {
								...fg,
								filters: fg.filters.map((f: any) =>
									(f.id || f.syntax) === filterId
										? { ...f, isSelected: true }
										: f,
								),
						  }
						: fg,
				),
			};

			if (datasetId) {
				s.selected = {
					...s.selected,
					[datasetId]: {
						...filterUtils.getSelectedFilter(s, datasetId)
					}
				}
			}
			else {
				s.selectedFilter = filterName ? state.selectedFilter : filterUtils.getSelectedFilter(s)
			}

			return s;
		}

		case FILTER_DESELECT: {

			const { datasetId } = action

			let s = {
				...state,
				filterGroups: state.filterGroups.map((fg) =>
					fg.id === action.groupId
						? {
								...fg,
								filters: fg.filters.map((f: any) =>
									action.filterId
										? (f.id || f.syntax) === action.filterId
											? { ...f, isSelected: false }
											: f
										: f.isSelected
										? { ...f, isSelected: false }
										: f,
								),
						  }
						: fg,
				),
			};
			if (datasetId) {
				s.selected = {
					...s.selected,
					[datasetId]: {
						...filterUtils.getSelectedFilter(s, datasetId)
					}
				}
			}
			else {
				s.selectedFilter = filterUtils.getSelectedFilter(s);
			}
			return s;
		}

		case FILTER_GROUP_DESELECT: {

			const { datasetId } = action

			let s = {
				...state,
				filterGroups: state.filterGroups.map((fg) =>
					fg.id === action.groupId
						? {
								...fg,
								isExpanded: false, // collapse
								filters: fg.filters.map((f: any) => ({
									...f,
									isSelected: false,
								})),
						  }
						: fg,
				),
			};
			if (datasetId) {
				s.selected = {
					...s.selected,
					[datasetId]: {
						...filterUtils.getSelectedFilter(s, datasetId)
					}
				}
			}
			else {
				s.selectedFilter = filterUtils.getSelectedFilter(s);
			}
			return s;
		}

		case FILTER_DESELECT_ALL: {

			const { datasetId } = action

			let s = {
				...state,
				filterGroups: state.filterGroups.map((fg) => ({
					...fg,
					isExpanded: false, // collapse
					filters: fg.filters.map((f: any) => ({
						...f,
						isSelected: false,
					})),
				})),
			};

			if (datasetId) {
				s.selected = {
					...s.selected,
					[datasetId]: {
						...filterUtils.getSelectedFilter(s, datasetId)
					}
				}
			}
			else {
				s.selectedFilter = filterUtils.getSelectedFilter(s);
			}
			return s;
		}

		case FILTER_SET_USE_CUSTOM_LABEL: {
			let s = {
				...state,
				selectedFilter: {
					...state.selectedFilter,
					useCustomLabel: action.useCustomLabel,
				},
			};
			s.selectedFilter = filterUtils.getSelectedFilter(s);
			return s;
		}

		case FILTER_SET_CUSTOM_LABEL: {
			let s = {
				...state,
				selectedFilter: {
					...state.selectedFilter,
					customLabel: action.customLabel,
				},
			};
			s.selectedFilter = filterUtils.getSelectedFilter(s);
			return s;
		}

		case FILTER_SAMPLE_SIZES_RECEIVE: {
			const selectedFilter = state.selectedFilter;
			if (selectedFilter && selectedFilter.syntax === action.syntax) {
				return {
					...state,
					selectedFilter: {
						...state.selectedFilter,
						n: action.n,
					},
				};
			} else return state;
		}
	}

	return state;
};
