import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { guid } from "../../utils/guid";
import "./HelpPane.scss";
import "./TopBar.scss";
import * as globals from "../../globals.js";

const renderFeedText = () => {
    return (
        <React.Fragment>
            The home page of Blue Owl is The Feed. The Feed is where you can view recommendations and insights from me, Bo, and other team members. For video tutorials, click on
            {<i className="far fa-video" style={{ paddingLeft: "3px" }} />}, to see tips, click on
            {<i className="far fa-lightbulb" style={{ paddingLeft: "3px" }} />}.
        </React.Fragment>
    );
};

const helpText = {
    feed: renderFeedText(),

    "research-scenarios": `The Research Scenarios section allows you to view 
    scenarios shared by me, Bo, the AI, and by others in your organization. You
    can view predicted outcomes and choose a scenario to load.`,

    optimize: `In the Optimize area, you use Bo to find a set of optimal solutions. 
    You set an optimization goal, constraints, and targets. Then, Bo does a smart
    search across millions of possibilities.`,

    edit: `The Edit Scenarios area is where you can analyze and edit a scenario
    that you have loaded from the Feed or Research Scenarios. From here, you can
    post a scenario to the Feed.`,

    explore: `In Explore data, you can delve into the data, analyses, and charts
    from the historical, real-time social media streams, real-time e-commerce 
    streams, and survey data that drive Bo.`,

    segments: `The Create segments area is where you explore and compare market
    segmentation solutions. I’ve created strong statistical possibilities, and 
    you can apply the “art” to get to a segmentation that will sing.`,

    game: `In the Competitive Game area, you can set up and run strategic games
    to simulate competitive response. You set the goals, number of rounds, and 
    combination of Bo and human players.`,

    assumptions: `In the Assumptions area, you can view and edit the market size,
    cost per feature, and other assumptions that are included in the volume,
    revenue, and profit calculations.`,
};

const labelToKey = (label) => {
    if (!label) return guid();
    let key = label.toLowerCase();
    key = key.replace(/\s/g, "-");
    key = key.replace(/\?/g, "");
    key = key.replace(/\./g, "");
    key = key.replace(/\(/g, "");
    key = key.replace(/\)/g, "");
    key = key.replace(/’/g, "");

    return key;
};

const addKey = (node) => {
    let retval = {
        ...node,
        //key: guid()
        key: labelToKey(node.label),
    };
    if (node.children) {
        retval.children = node.children.map((ch) => addKey(ch));
    }
    return retval;
};

export const questionMenu = addKey({
    //label: "A few things you can do with Bo",
    //icon: <i className="icon fal fa-books" />,
    children: [
        {
            glyph: <i className="fal fa-cube" />,
            label: "Product",
            children: [
                {
                    label: "What are the financial outcomes if I change a feature?",
                    action: "edit",
                    tags: ["product"],
                    instruction: "Use the scenario pane to change features. You may change features of one or more products. The summary tab shows a summary of all changes from base. View projected outcomes in the Outcomes pane.",
                },
                {
                    label: "How can I optimize features for a single trim to maximize revenue?",
                    action: "optimize",
                    tags: ["product"],
                    instruction: "Select target product(s), goal, population and constraints. Then click Optimize.",
                },
                {
                    label: "What dollar value do customers put on features?",
                    action: "outcomes",
                    tag: "hints",
                    tags: ["product"],
                    //instruction: "todo: write instructions here"
                },
                {
                    label: "How can I optimize features across all trims in a model to maximize profit?",
                    action: "optimize",
                    tags: ["product"],
                    instruction: "Select target trims, goal, population and constraints. Then click Optimize.",
                    //instruction: "todo: write instructions here"
                },
                {
                    label: "What are the most important features by customer segment?",
                    action: "explore",
                    tags: ["product"],
                    tag: "people",
                },
                {
                    label: "What is the trend in the importance of product features?",
                    action: "explore",
                    tags: ["product"],
                    tag: "trend",
                    //instruction: "todo: write instructions here"
                },
            ],
        },

        {
            glyph: <i className="fal fa-tag" />,
            label: "Price",
            children: [
                {
                    label: "What is the price sensitivity for a particular trim?",
                    action: "outcomes",
                    tag: "price-sensitivity",
                    tags: ["price"],
                    //instruction: "todo: write instructions here"
                },
                {
                    label: "What is the price sensitivity across all trims in a model?",
                    action: "outcomes",
                    tag: "price-sensitivity",
                    tags: ["price"],
                },
                {
                    label: "How can I optimize price for a single trim?",
                    action: "optimize",
                    tags: ["price"],
                    //instruction: "todo: write instructions here"
                },
                {
                    label: "How can I optimize price for all trims in a model?",
                    action: "optimize",
                    tags: ["price"],
                    //instruction: "todo: write instructions here"
                },
                {
                    label: "What is the optimal response to a competitor’s price promotion?",
                    action: "optimize",
                    tags: ["price"],
                    //instruction: "todo: write instructions here"
                },
            ],
        },

        {
            glyph: <i className="fal fa-bullhorn" />,
            label: "Message",
            children: [
                {
                    label: "What are various messages’ impact on Brand consideration?",
                    action: "outcomes",
                    tag: "message-hints",
                    tags: ["message"],
                },
                { label: "What will be the monetary impact of moving from a 4.5 to a 7.0 on particular message?", action: "edit", tags: ["message"] },
                { label: "What message strategy will increase make and model consideration?", action: "optimize", tags: ["message"] },
                { label: "What message will drive increases in revenue for a particular target group?", action: "explore", tags: ["message"] },
                {
                    label: "What is the financial impact of better activating various core motives?",
                    action: "outcomes",
                    tags: ["message"],
                    tag: "core-motives",
                },
            ],
        },

        {
            glyph: <i className="fal fa-registered" />,
            label: "Brand",
            children: [
                {
                    label: "What is the relationship between Brand Utilities (preference) and Sentiment?",
                    action: "explore",
                    tag: "utility-sentiment",
                    tags: ["brand"],
                },
                {
                    label: "What is the optimal Brand Positioning for our brand?",
                    action: "explore",
                    tags: ["brand"],
                    tag: "competition",
                },
                {
                    label: "What is the competitive landscape for our Brand?",
                    action: "explore",
                    tags: ["brand"],
                    tag: "competition",
                },
                {
                    label: "Which brands to consumers see as most similar? Most different?",
                    action: "explore",
                    tags: ["brand"],
                    tag: "similarity",
                },
                {
                    label: "What are the top three drivers that will increase revenue for a make-model?",
                    action: "optimize",
                    tags: ["brand"],
                },
            ],
        },

        {
            glyph: (
                <>
                    <i className="fal fa-male" />
                    <i className="fal fa-female" />
                </>
            ),
            label: "People",
            children: [
                {
                    label: "How well does my current segmentation fit my line?",
                    action: "explore",
                    tag: "segmentation",
                    tags: ["people"],
                },
                {
                    label: "What segmentation would best fit my line and help us sell more?",
                    action: "explore",
                    tag: "segmentation",
                    tags: ["people"],
                },
                {
                    label: "What are the attitudes, behaviors, and preferences of my loyal group?",
                    action: "explore",
                    tags: ["people"],
                    tag: "people",
                },
                {
                    label: "What are the attitudes, behaviors, and preferences of the switchable group?",
                    action: "explore",
                    tags: ["people"],
                    tag: "people",
                },
                {
                    label: "How do our trims map to our market segmentation?",
                    action: "explore",
                    tags: ["people"],
                    tag: "people",
                },
                {
                    label: "How do preferences for Hispanics differ from the general population?",
                    action: "explore",
                    tags: ["people"],
                    tag: "people",
                },
                {
                    label: "How do preferences for Switchables differ from the general population?",
                    action: "explore",
                    tags: ["people"],
                    tag: "people",
                },
            ],
        },

        {
            hide: true,
            glyph: (
                <>
                    <i className="fal fa-user" />
                </>
            ),
            label: "Explore Subgroups",
            children: [
                {
                    label: "Explore what-if for this subgroup",
                    directAction: "what-if",
                    tag: "explore-subgroups",
                    tags: ["explore-subgroups"],
                },
            ],
        },
    ],
});

class MenuNode1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: this.props.expanded ? true : false,
        };
    }

    clicked = () => {
        const node = this.props.node;
        if (node.action) {
            // const actionR = node.action === 'edit' ? 'what-if' : node.action;

            // let studyId = this.props.study.uid;// this.props.match.params.studyId;
            // this.props.history.push(`/study/${studyId}/${actionR}`)
            // window.topBar.hideMenu();

            let studyId = this.props.study.uid; // this.props.match.params.studyId;
            this.props.history.push(`/study/${studyId}/question/${node.key}`);
            window.topBar.hideMenu();
        }

        if (node.children) {
            this.setState({ expanded: !this.state.expanded });
        }
    };

    render() {
        const node = this.props.node;
        const visChildren = node.children ? node.children.filter((ch) => !ch.hide) : null;
        return (
            <React.Fragment>
                {node.label ? (
                    <div className={"menu-item" + (node.children ? " taller" : " question-item")} onClick={(ev) => this.clicked()}>
                        {node.glyph ? (
                            <>
                                <span className="glyph">{node.glyph}</span>
                                <span className="offset-label bold">{node.label}</span>
                            </>
                        ) : (
                            <span className="offset-label">{node.label}</span>
                        )}

                        {visChildren ? this.state.expanded ? <i className="icon fas fa-caret-up" /> : <i className="icon fas fa-caret-down" /> : null}
                    </div>
                ) : null}
                {this.state.expanded && visChildren ? visChildren.map((ch) => <MenuNode key={ch.key} study={this.props.study} node={ch} />) : null}
            </React.Fragment>
        );
    }
}
const MenuNode = withRouter(MenuNode1);

export class HelpPane extends React.Component {
    render() {
        const study_folder = (this.props.study || {}).folder;

        //let user = this.props.user || {};

        const { section } = this.props;
        const { pathname } = this.props.location;
        const splitPath = !pathname ? ["/"] : pathname.split("/");
        const findLastPath = splitPath[splitPath.length - 1];
        const whichPage = section || findLastPath || "error";
        const testPage = Object.keys(helpText).includes(whichPage);

        //const exploreOnly = globals.exploreOnly(this.props.study?.uid);

        return (
            <div className="basic-menu">
                {true ? null : <div className="separator-with-text">A few things you can do with Bo</div>}

                {/* <a
                    className="menu-item"
                    href="about:todo"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Getting Started
                    <i className="icon fal fa-books" />
                </a> */}

                {true ? null : <MenuNode study={this.props.study} node={questionMenu} expanded={true} />}

                <div className="separator-with-text">Knowledge base</div>

                {/* <a className="menu-item" href="https://tutorial.blueowl.ai/story_html5.html" target="_blank" rel="noopener noreferrer">
                    Tutorial videos
                    <i className="icon far fa-play-circle" />
                </a> */}


                {this.props.study.uid === "video-games-demo" ? <>
                    <a className="menu-item" href={`${globals.cdnUrl}/cdn-shared/${study_folder}/files/Bo Navigation Intro.mp4`} target="_blank" rel="noopener noreferrer">
                        Tutorial videos - Intro
                    <i className="icon far fa-play-circle" />
                </a>
                <a className="menu-item" href={`${globals.cdnUrl}/cdn-shared/${study_folder}/files/Blue Owl Orientation - Navigation.pdf`} target="_blank" rel="noopener noreferrer">
                        Documentation - Navigation
                    <i className="icon far fa-file-pdf" />
                </a>
                 <a className="menu-item" href={`${globals.cdnUrl}/cdn-shared/${study_folder}/files/Blue Owl Orientation - Colors.pdf`} target="_blank" rel="noopener noreferrer">
                        Documentation - Colors
                    <i className="icon far fa-file-pdf" />
                </a> </>: null
                }

                {/* <a className="menu-item" href="about:todo" target="_blank" rel="noopener noreferrer">
                    Discussions
                    <i className="icon fal fa-comments" />
                </a> */}

                <div className="separator-with-text">Contact</div>

                <a className="menu-item" href="https://www.blueowlai.com/" target="_blank" rel="noopener noreferrer">
                    Contact us
                    <i className="icon fal fa-phone" />
                </a>

                <a className="menu-item" href="mailto:support@blueowlai.com" rel="noopener noreferrer">
                    Request a card or feature
                    <i className="icon fal fa-envelope" />
                </a>

                <div className="spacer" />

                {!testPage ? null : (
                    <React.Fragment>
                        <div className="separator-with-text">
                            Help on this page: <span className="help-page-label">{!whichPage ? "" : whichPage.replace("-", " ").toUpperCase()}</span>
                        </div>
                        <div className="text-section">
                            {/* <div className="bo-says">
                                <i className="fab fa-earlybirds" /> Bo says...
                            </div> */}

                            <div className="bo-content">
                                {helpText[whichPage]}
                                {/* <br />
                                <br />
                                Read <a href="#todo">more...</a> */}
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let user = undefined;
    if (state.auth != null) {
        user = state.auth.user;
    }

    return {
        user,
    };
};

const mapDispatchToProps = (dispatch) => ({});

HelpPane = withRouter(connect(mapStateToProps, mapDispatchToProps)(HelpPane));
