import * as d3 from 'd3';
import * as _ from 'lodash';
import React, { Component, createRef } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { guid } from '../../utils/guid';
import './ScatterPlot.scss';

export default class ScatterPlot extends Component {
    constructor(props) {
        super(props);

        this.node = createRef();
        this.minX = 0;
        this.scatterid =  guid();
    }

    componentDidMount() {
        this.updateChart();
    }

    componentDidUpdate() {
        this.updateChart();
    }

    updateChart = () => {
        const { x, y } = this.props;
        let data = x && y ? this.props.data?.map(item => ({
            ...item,
            x: item[x.key],
            y: item[y.key]
        })) : null;
        if (!data) return null;

        let avgDatum = data?.find(m => m.label === this.props.average);
        
        try {
            const domNode = this.node.current;
            if (!domNode) return;

            var margin = !this.props?.hideAxis ? { top: 20, right: 30, bottom: 60, left: 60 } : { top: 15, right: 15, bottom: 15, left: 15 };

            const width = this.node.current.clientWidth - margin.left - margin.right;
            const height = this.node.current.clientHeight - margin.top - margin.bottom;
            if (!width || !height) return;

            const maxX = d3.max(data.map(i => Math.abs(i.x))) * 1.1;
            const maxY = d3.max(data.map(i => Math.abs(i.y))) * 1.1;

            // we've find the min value of x, y points and checked for
            // negative or positive value so that we'd multiply 1.1 for
            // negative value and 0.1 for positive value.
            const minX = Math.sign(_.min(data.map(i => i.x))) == -1
                ? _.min(data.map(i => i.x)) * 1.1
                : _.round(_.min(data.map(i => i.x)) * 0.1);
            
            const minY = Math.sign(_.min(data.map(i => i.y))) == -1
                ? _.min(data.map(i => i.y)) * 1.1
                : _.round(_.min(data.map(i => i.y)) * 0.1);
                
            this.minX = minX;
            
            const centerX = width / 2.0;
            const centerY = height / 2.0;

            // scaling for x axis.
            const x = d3
                .scaleLinear()
                .domain([minX, maxX])
                .range([centerX - width / 2.0, centerX + width / 2.0]);

            // scaling for y axis.
            const y = d3
                .scaleLinear()
                .domain([minY, maxY])
                .range([centerY + height / 2.0, centerY - height / 2.0]);

            // voronoi data
            const vData = data.map(pt => [pt.x, pt.y]);
            // const vpols = d3.voronoi()
            //     .extent([[-maxX, -maxY], [maxX, maxY]])
            //     .polygons(vData);

            // store the calculated values in data.
            data = data.map((entry, i) => ({
                ...entry
                // ,voronoi: vpols[i]
                // ,centroid: vpols[i] ? d3.polygonCentroid(vpols[i]) : null
                // ,voronoiArea: vpols[i] ? d3.polygonArea(vpols[i]) : null
            }));

            // sort by voronoi area
            // data = data.sort((a, b) => a.voronoiArea - b.voronoiArea);

            let tooltip = d3.select('#tooltip'+this.scatterid)
                .style('background-color', '#fff')
                .style('border', '1px solid #ddd')
                .style('border-radius', '4px')
                .style('opacity', 0)
                .style('width', 'auto')
                .style('height', 'auto')
                .style('position', 'absolute')
                .style('padding', '5px')
                .style('font-size', '12px')
                .style('z-index', '10')
                // .style('transform', 'translate(50%, 50%)');

            // select the node.
            let svg = d3
                .select(this.node.current)
                .attr('width', width)
                .attr('height', height);

            svg.selectAll('*').remove();

            const showTooltip = (node, x, y) => {
                node.on("mouseover", (d) => {
                    tooltip.html(`${d.label}`)
                    .style('opacity', 1)
                    .style('left', x(d.x) + margin.left + 10 + 'px')
                    .style('top', y(d.y) + margin.top - 5 + 'px')
                })
                .on("mouseout", () => {
                    tooltip
                    .style('opacity', 0)
                    .style('left', '0px')
                    .style('top', '0px')
                })
            }

            const draw_circle = this.props.drawCircle;
            const radius = draw_circle?.radius || 0.25;

            // called when user zoom in and out.
            const zoomed = (event, d) => {
                
                // calculating new scale for x and y axis.
                var newXScale = event.transform.rescaleX(x),
                    newYScale = event.transform.rescaleY(y);               
                

                // binding it with x and y axis
                if(!this.props?.hideAxis) {
                    xAxis.call(xaxis.scale(newXScale));
                    let zoomedYAxis = yAxis.call(yaxis.scale(newYScale));
                    zoomedYAxis.selectAll(".tick text")
                    .attr("y","3")
                    .attr("dy","0")
                }

                var newlineData = [
                    { id: 'y-axis', x1: newXScale(0), y1: newYScale(newYScale.domain()[0]), x2: newXScale(0), y2: newYScale(newYScale.domain()[1]) },
                    { id: 'x-axis', x1: newXScale(newXScale.domain()[0]), y1: newYScale(0), x2: newXScale(newXScale.domain()[1]), y2: newYScale(0) },
                ]
                if( avgDatum ){
                    newlineData.push({ id: 'y-avg', x1: newXScale(avgDatum.x), y1: newYScale(newYScale.domain()[0]), x2: newXScale(avgDatum.x), y2: newYScale(newYScale.domain()[1]) });
                    newlineData.push({ id: 'x-avg', x1: newXScale(newXScale.domain()[0]), y1: newYScale(avgDatum.y), x2: newXScale(newXScale.domain()[1]), y2: newYScale(avgDatum.y) });
                }

                //this.updateLabelPositions();                

                // selecting the dots and its label
                var datapoint = d3.select(this.node.current)
                    .select('g .dots')
                    .selectAll('.datapoint-group');          
               

                var lines = d3.select(this.node.current)
                    .select('g #lines')
                    .selectAll('.origin-line')
                    .data(newlineData, (d, i) => d.id)

                lines.attr("x1", (d) => d.x1)
                    .attr("y1", (d) => d.y1)
                    .attr("x2", (d) => d.x2)
                    .attr("y2", (d) => d.y2);         
                                
                // updating the dots location.
                datapoint.select('ellipse')
                    .data(data, (d, i) => i)
                    .attr('cx', d => newXScale(d.x))
                    .attr('cy', d => newYScale(d.y))               
               
                
                // updating the stars location.
                let star = datapoint.select('g .star')
                    .data(data, (d, i) => i)
                    .attr("transform", d => `translate(${newXScale(d.x) - 8},${newYScale(d.y) - 8})`)
                
                //circle
                //let radius = newXScale(d.x + 0.1) - newXScale(d.x);
                let disc = datapoint.select('g .stardisc')
                    .data(data, (d, i) => i)
                    .attr("transform", d => `translate(${newXScale(d.x) - 0},${newYScale(d.y) - 0})`)
                    .select('circle')
                        .attr('r', d => newXScale(d.x + radius) - newXScale(d.x))

                // updating the labels location.
                datapoint.select('text')
                    .data(data, (d, i) => i)
                    .attr('x', d => {
                        const newX = newXScale(d.x);
                        return d.centroid?.[0] < d.x ? newX - 7 : newX + 7;
                    })
                    .attr('y', d => newYScale(d.y) + 3)
                    .text((d, i) => d.label);

                showTooltip(datapoint, newXScale, newYScale);
                // updating star's tooltip location.
                showTooltip(star, newXScale, newYScale);

                this.updateLabelPositions();
            };

            var lineData = [
                { id: 'y-axis', x1: x(0), y1: y(y.domain()[0]), x2: x(0), y2: y(y.domain()[1]) },
                { id: 'x-axis', x1: x(x.domain()[0]), y1: y(0), x2: x(x.domain()[1]), y2: y(0) }
            ];
            if( avgDatum ){
                lineData.push({ id: 'y-avg', x1: x(avgDatum.x), y1: y(y.domain()[0]), x2: x(avgDatum.x), y2: y(y.domain()[1]), className: 'avg-line' });
                lineData.push({ id: 'x-avg', x1: x(x.domain()[0]), y1: y(avgDatum.y), x2: x(x.domain()[1]), y2: y(avgDatum.y), className: 'avg-line' });
            }

            var zoom = d3
                .zoom()
                .extent([[0, 0], [width, height]])
                .scaleExtent([0.5, 20])
                .on("zoom", zoomed);

             svg.append("rect")
                .attr("width", width)
                .attr("height", height)
                .attr('transform', `translate(${margin.left}, ${margin.right})`)
                .style("fill", "none")
                .style("pointer-events", "all")
                .call(zoom);

            var g = svg.append('g')
                .attr('transform', `translate(${margin.left}, ${margin.right})`);

            const origin_lines = g.append('g')
                .attr('id', 'lines')
                .attr('clip-path', `url(#clip-${this.node.current.id})`)
                .style('class', 'origin_lines');

            const lines = origin_lines.selectAll('line')
                .data(lineData, (d, i) => d.id);

            lines.enter()
                .append("line")
                    .attr("class", (d) => `origin-line ${d.className || ''}`)
                    .attr("x1", (d) => d.x1)
                    .attr("y1", (d) => d.y1)
                    .attr("x2", (d) => d.x2)
                    .attr("y2", (d) => d.y2);

            // adding a clipping region
            g.append('defs')
                .append('clipPath')
                    .attr('id', 'clip-'+this.node.current.id)
                .append('rect')
                    .attr('width', width)
                    .attr('height', height);

            if(!this.props?.hideAxis) {
                 // x and y axis position
                    var xaxis = d3.axisBottom(x).ticks(8),
                    yaxis = d3.axisLeft(y).ticks(8);
                    // add x axis
                    var xAxis =g.append('g')
                    .attr('class', 'xAxis')
                    .attr('transform', `translate(0, ${height})`)
                    .call(xaxis);
                    
                    // add y axis
                    var yAxis = g.append('g').attr('class', 'yAxis').call(yaxis);

                    yAxis.selectAll(".tick text")
                    .attr("y","3")
                    .attr("dy","0")
            }

            // text label for the x axis
            svg.append("text")             
                .attr("transform", `translate(${((width/2) + (margin.left/2))}, ${(height + margin.bottom + 5)})`)
                .style("font-size", "14px")
                .text(this.props?.x?.label);
                
                // text label for the y axis
            svg.append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 10)
                .attr("x", 0 - (height / 2) - margin.bottom/2)
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .style("font-size", "14px")
                .text(this.props?.y?.label);

                // Add dots
                var dots = g.append("g")
                .attr('class', 'dots')
                .attr('clip-path', `url(#clip-${this.node.current.id})`)
                
                var dt = dots.selectAll("dot")
                .data(data, (d, i) => i)
                .enter()
                .append('g')
                .attr('class', 'datapoint-group');
                
                dots.append('use').attr('xlink:href', `#highlight-${this.node.current.id}`);
            // dt.append("ellipse")
            //     .attr("cx", d => x(d.x))
            //     .attr("cy", d => y(d.y))
            //     .attr("rx", 4)
            //     .attr("ry", 4)
            //     .attr('class', 'datapoint')
            //     .style("fill", d => (this.props.highlight ? this.props.highlight[0]?.label : null) === d.label ? "#bd0000" : d.color || "#4287fa")
            //     // .append("title")
            //     //     .text(function(d, i) { return d.label; });

            //to draw circle from dcard draw_circle= { {radius: 10, fill: #8900ff, stroke: #ff00ff} }

            const hlabel = this.props.highlight?.[0]?.label;
            
            let plotId = this.node.current.id;
            let sel = null;

            //have to draw circle first or else dots get drawn on top of
            if (draw_circle) {
                dt.each(function (d, i) //i = index
                {
                    sel = d3.select(this);
                    if (hlabel === d.label) {
                        let disc = sel.append("g")
                            .attr('class', 'stardisc')
                            .attr('id', 'disc-' + plotId)
                            .attr("transform", `translate(${x(d.x) - 0},${y(d.y) - 0})`)
                            .attr('pointer-events', 'none')
                            .append("circle")
                                .attr("cx", 0) //center of circle x,y
                                .attr("cy",  0)
                                .attr("r", x(d.x + radius) - x(d.x))
                                .style("fill", draw_circle?.fill || "rgba(0,0,0,.1)")
                                .style("stroke", draw_circle?.stroke || "")
                            .style("stroke-width", 2)
                        
                        //showTooltip(disc, x, y);
                    }
                })
            }

            dt.each(function (d, i) //i = index
            {
                sel = d3.select(this);
                if (hlabel === d.label)
                {
                    let star = sel.append("g")
                        .attr('class', 'star')
                        .attr('id', 'highlight-'+plotId)
                        .attr("transform", `translate(${x(d.x) - 8},${y(d.y) - 8})`)
                        .append("path")
                        .attr("d", "M8.0597448348999,0L9.9368839263916,5.78174018859863 16,5.78174018859863 11.089376449585,9.25784873962402 12.9581642150879,14.9432277679443 8.02505493164063,11.4048023223877 3.11186027526855,14.9792041778564 4.98321723937988,9.2861156463623 0,5.78174018859863 6.1511287689209,5.78174018859863 8.0597448348999,0z")
                        .style("fill", "#000000")                        
                    
                    showTooltip(star, x, y);
                                      
                        //.attr("d", 'M0,0 L100,0 L100,100z')
                        //.attr("d", `M0,0 L100,0 100,5 ${x(d.x)+8.0597448348999},${y(d.y)+0} ${x(d.x)+9.9368839263916},${y(d.y)+5.78174018859863}z`)
                        //.attr("d", `M${x(d.x)+8.0597448348999},${y(d.y)+0} L${x(d.x)+9.9368839263916},${y(d.y)+5.78174018859863} ${x(d.x)+16},${y(d.y)+5.78174018859863} ${x(d.x)+11.089376449585},${y(d.y)+9.25784873962402} ${x(d.x)+12.9581642150879},${y(d.y)+14.9432277679443} ${x(d.x)+8.02505493164063},${y(d.y)+11.4048023223877} ${x(d.x)+3.11186027526855},${y(d.y)+14.9792041778564} ${x(d.x)+4.98321723937988},${y(d.y)+9.2861156463623} ${x(d.x)+0},${y(d.y)+5.78174018859863} ${x(d.x)+6.1511287689209},${y(d.y)+5.78174018859863} ${x(d.x)+8.0597448348999},${y(d.y)+0}z`)
                        
                }
               
                else{
                    sel.append("ellipse")
                        .attr("cx", x(d.x))
                        .attr("cy", y(d.y))
                        .attr("rx", 4)
                        .attr("ry", 4)
                        .attr('class', 'datapoint')
                        .style("fill", d.color || "#4287fa")
                        .on('mouseover', () => d3.select(this).select('ellipse').style('fill', '#000000'))
                        .on('mouseout', (d) => d3.select(this).select('ellipse').style('fill', d.color || "#4287fa"))
                        
                }
                
                sel.append("text")
                    .attr("text-anchor", (d, i) => (d.centroid?.[0] < d.x ? "end" : "start"))
                    .attr("x", d => {
                        const _x = x(d.x);
                        return d.centroid?.[0] < d.x ? _x - 7 : _x + 7;
                    })
                    .attr("y", d => y(d.y) + 3)
                    .text((d, i) => d.label)
            });

           
            showTooltip(dt, x, y);
            this.updateLabelPositions();
            dots.exit().remove();
            g.exit().remove();
        } catch (err) {
            console.error('scatterplot2 error', err);
        }
    }

    // Returns true if two rectangles overlap 
    // doOverlap = (rect1, rect2) => {
    //     if( rect1.right < rect2.x ) return false;
    //     if( rect2.right < rect1.x ) return false;
    //     if( rect1.bottom < rect2.y ) return false;
    //     if( rect2.bottom < rect1.y ) return false;
    //     return true;
    // }

    doOverlap = (rect1, rect2) => {
        const endflex = 2;
        const topflex = 5;

        if( rect1.right - endflex < rect2.x ) return false;
        if( rect2.right - endflex < rect1.x ) return false;
        if( rect1.bottom < rect2.y + topflex ) return false;
        if( rect2.bottom < rect1.y + topflex ) return false;
        return true;
    } 

    updateLabelPositions = () => {
        
        const doOverlap = this.doOverlap;
        let rects = [];

        const dots = d3.select(this.node.current)
            .select('g .dots');
        
        // fill rects with dots
        dots
            .selectAll('g')
                .select('ellipse')
                .each(function (d, i) {
                    rects.push(this.getBoundingClientRect());
                })

        // label will be hidden if overlaps with an existing rect
        dots
            .selectAll('g')
                .select('text')
                .attr('class', function(d, i){
                    const rect = this.getBoundingClientRect();
                    const overlap = rects.filter(crect => doOverlap(crect, rect));
                    if( overlap?.length > 0 ){
                        return 'label overlapped';
                    }
                    else{
                        rects.push(rect);
                        return 'label';
                    }
                });
    }

    render() {
        return <ReactResizeDetector handleWidth handleHeight>
            {
                ({ width, height }) => {
                    this.updateChart(width, height);
                    return <>
                    <div id={'tooltip'+this.scatterid}></div>
                    <svg key='singleton'
                        ref={this.node}
                        id={this.scatterid}
                        className='scatterplot'
                        preserveAspectRatio="xMidYMid meet"
                        style={{
                            border: '0',
                        }}
                    /></>
                }
            }
        </ReactResizeDetector>
    }
}
