import * as React from 'react';
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import * as globals from '../../globals';
import * as apiService from '../../utils/apiService';
import { DataCard } from '../card/DataCard';
import { getRepo } from '../widgets/_widgetRepo';
import './CardContainer.scss';
import { getChildLink } from './ExploreUtils';
import { ReactSortable } from '../common/Sortable';
import { EditModeWrapper } from './EditModeWrapper';

export const GetCardsInContainer = (containerData, page_no, size = 4) => {
    const all_cards = getRepo();

    let cards = [];
    if( containerData?.cards && Array.isArray(containerData.cards) ){
        cards = containerData.cards.map(cardid => all_cards.find(m => m.uid === cardid))
            .filter(_ => _); // remove null entries (if id wasn't found)
    }
    if( containerData?.tagFilter ){
        const addCards = all_cards
            .filter(card => card.tags?.includes(containerData.tagFilter))
            .filter(m => !cards.find(c => c.uid === m.uid)) // exclude any card that's already in there
        cards = [...cards, ...addCards];
    }
    if( containerData?.tags ){
        containerData.tags.forEach(tag => {
            const addCards = all_cards
            .filter(card => card.tags?.includes(tag))
            .filter(m => !cards.find(c => c.uid === m.uid)) // exclude any card that's already in there
            cards = [...cards, ...addCards];
        })
    }
    if( !containerData?.cards && !containerData?.tagFilter && !containerData?.tags ){
        cards = all_cards;
    }
    return page_no ? cards.slice(0, page_no * size) : cards;
}


export const CardContainer = (props) => {

    React.useEffect(() => {
        props.setLayoutReady?.(true);
    }, [])

    const {id, title, contents, data} = props.item;

    const auth = useSelector(state => state.auth);
    const study_uid = useSelector(state => state.study?.uid);
    if( !study_uid ) return null;

    const area = props.area;
    const params = useParams();
    //const [editMode, setEditMode] = useState(false);
    const {editMode, setEditMode} = props.edit || {};// useState(false);
    const [cardData, setCardData] = useState(data ? JSON.stringify(data) : '{}');
    const [page, setPageNo] = useState(1);

    const saveCardData = () => {
        const body = JSON.parse(cardData);
        apiService.aPost(auth, `${globals.apiRoot}/study/${study_uid}/content/${id}/data`, body)
            .then(() => window.location.reload())
            .catch((err) => console.error(err));
    }
    let size = 4;

    // get cards
    let cards = GetCardsInContainer(data, page, size);

    const example1 = "{\"cards\":[\"segprofiles1\",\"funnel1\"]}";
    //const example2 = "{\"tagFilter\":\"brand-awareness\"}";
    const example2 = "{\"tags\":[\"brand-awareness\",\"something-else\"]}";
    const example3 = "{\"cards\":[\"awareness1\"], \"tags\":[\"brand-awareness\"]}";


        // data card gets portion of state from page (inherited state) as props
        // data card gets props from repo entry if any
        // data card contains widget
        // data cards state is injected into widget via props
        // widget may modify its parent data card state via stateContext
        //      this would cause widget to receive new props and redraw
        //      it's the best method i could think of right now with short notice
        
        //const expandRoot = `/study/${study_uid}/explore${(area ? `/area/${area}` : '')}`;
        //const expandRoot = `/study/${study_uid}/explore`;

    const cardContents = cards.map(entry => {

        const expandRoot = getChildLink(params, null, study_uid);
        const item = { type: 'datacard', id: entry.uid } // this id just for the editmodewrapper

        return <EditModeWrapper key={item.id} item={item} edit={props.edit} pageLevel={props.pageLevel}>
            <DataCard
                expandRoot={expandRoot}
                key={entry.uid}
                studyId={study_uid}
                widgetId={entry.uid}
                repoEntry={entry}
                className='dataCardHeight'
            />
        </EditModeWrapper>
    });

    let cardContainerStyle = {
        gridTemplateColumns: "repeat(auto-fill,minmax(375px,1fr))"
        //gridTemplateColumns: "1fr"
        //grid-template-columns: repeat(auto-fill, minmax(350px, auto));
    }
    if( props.item?.data?.gridTemplateColumns ){
        cardContainerStyle.gridTemplateColumns = props.item?.data?.gridTemplateColumns;
    }

    const wide = data?.wide == true;
    return <EditModeWrapper className='has-children' item={props.item}
        restoredItem={props?.restoredItem} reloadParentItem={props.reloadParentItem} reloadItem={props.reloadItem} edit={props.edit}
        pageLevel={props.pageLevel}
    >
        <div className={'content-section' + (editMode ? ' edit-mode' : '') + (wide ? ' wide-width with-margin' : ' page-width')}>
            {title ?
                <div className='title'>
                    {title}
                </div>
                : null
            }
            {false && editMode ? 
                <ReactSortable
                    list={cards}
                    reorder
                    setList={orderedCards => {
                        let cardDataObj = JSON.parse(cardData);
                        cardDataObj.cards = orderedCards.map(card => card.uid);
                        setCardData(JSON.stringify(cardDataObj));
                    }}
                    tag='div'
                    className='card-container'
                    style={cardContainerStyle}
                >
                    {cardContents}
                </ReactSortable>
            :
                <>
                    <div className="card-container" style={cardContainerStyle}>
                        {cardContents}
                    </div>
                    {(cards.length >= page * size) && (<button onClick={_ => setPageNo(page+1)} className='btn-primary' type='button'>Load more</button>)}
                </>
            }
            {!cardContents?.length ? 'no cards' : null}
            {false && auth.user.isSuperAdmin ?
                <div className={'edit-content'}>
                    {!editMode ?
                        <div className='edit-button'>
                            <span className='btn1 inline-block' onClick={() => setEditMode(!editMode)}>
                                <i className={editMode ? 'fal fa-times' : 'fal fa-pencil'}/>
                            </span>
                        </div> : null
                    }
                    <div>
                        {editMode ?
                            <div className='editing-content'>
                                <div>
                                    <textarea className='edit-card-data' value={cardData} onChange={(ev) => setCardData(ev.target.value)}/>
                                </div>
                                <div>
                                    <button className='btn-save-card-data' role='button' aria-label='save' onClick={saveCardData}>save</button>
                                    <button className='btn-save-card-data' role='button' aria-label='cancel' onClick={() => {
                                        setEditMode(false);
                                        setCardData(JSON.stringify(data));
                                    }}>cancel</button>
                                </div>
                                <div style={{marginTop: 10}}>
                                    <div>Example (specify exact cards):</div>
                                    <pre className='example-syntax'>
                                        {example1}
                                    </pre>
                                    <div>Example (specify tags only):</div>
                                    <pre className='example-syntax'>
                                        {example2}
                                    </pre>
                                    <div>Example (includes exact cards plus any card that has a matching tag):</div>
                                    <pre className='example-syntax'>
                                        {example3}
                                    </pre>
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                : null
            }
        </div>
    </EditModeWrapper>;

}
