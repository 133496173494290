import moment from 'moment'
import React, { Component } from 'react'
import './UserStats.scss'

export default class UserStats extends Component {
    render() {
        var getDates = function (startDate, endDate) {
            var dates = [],
                currentDate = startDate,
                addDays = function (days) {
                    var date = new Date(this.valueOf())
                    date.setDate(date.getDate() + days)
                    return date
                }
            while (currentDate <= endDate) {
                dates.push(currentDate.toString())
                currentDate = addDays.call(currentDate, 1)//adding a day in current date and looping
            }
            return dates
        }

        var weeksTimespan = []

        //current date
        const now = moment()        
        // Go back 1 year 
        const oneYearAgo = now.subtract(1, 'years')
        // Go to last Sunday
        const start = oneYearAgo.startOf('week')
        // Format start date
        const startDate = start.format('YYYY-MM-DD')

        // let startDate = moment(new Date()).startOf('week').subtract(1, 'year').format("YYYY-MM-DD") //orig subtract(105, 'day')
        let lastDates = moment(new Date()).endOf('week').format("YYYY-MM-DD")
        weeksTimespan = getDates(moment(startDate), moment(lastDates))

        const listItems = weeksTimespan.map((number, i) => {
            let loginClass = ""
            let loginsStr = ""
            let weekEndsClass = ""
            let day = moment(number).format('dddd').toString()
            let daysOfWeek = ['S', 'M', 'T', 'W', 'Th', 'F', 'S']

            if (day === "Saturday" || day === "Sunday") {
                weekEndsClass = "weekend"
            }
            let loginDate = this.props.usageStats?.logins.filter(date => moment(date.timestamp).format('L') === moment(number).format('L')) || []
            if (loginDate.length > 0) {
                console.log('loginDate[0].logins', loginDate[0].logins);
                loginClass = loginDate[0].logins <= 2 ? "oneplus" : loginDate[0].logins < 6 ? "fiveplus" : "tenplus" //for different shades of blue
                loginsStr = ': ' + loginDate[0].logins + ' login(s)'
            }
            if (moment(number).isBefore(moment(new Date()))) {
                return (
                    <li key={i} title={moment(number).format('MMM DD YYYY') + loginsStr} className={loginClass + " " + weekEndsClass}>
                        {moment(number).format('dddd').toString() === "Sunday" ? <span>{moment(number).format('MMM DD \'YY')}</span> : ""}                        
                        {i < 7 ? <i>{daysOfWeek[i]}</i> : ""}
                        {loginDate[0]?.logins > 0 && 
                            <label className="login-count">{loginDate[0].logins}</label>  
                        }
                    </li>
                )
            }

        })
        return (<div className="usage-stats" style={{overflowX:'auto'}}>
            <ul className="usage-stats-list">
                {listItems}
            </ul>
        </div>)
    }
}
