import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as globals from '../../globals';
import { actionCreators as configActionCreators } from '../../store/Config.ts';
import { actionCreators as studyActionCreators } from '../../store/Study.ts';
import { PageHeader } from '../common/PageHeader';
import { FilterChooser } from '../filter/FilterChooser';
import './Optimize.scss';
import { OptimizeEditorMulti } from './OptimizeEditorMulti';

export class Optimize extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            spinnerIndex: 0
        }
    }

    optimizeClicked = () => {

        // cancel if no target selected
        const optim = this.props.config.optim || {};
        const selections = optim.selections || [];
        if( selections.length === 0 ) 
            return;

        this.props.runOptimizer();
        //window.topBar.openMenu('notifications');
        window.topBar.openMenu('optruns');

    }
    
    render() {
        const study = this.props;
        if (!this.props.config) return null;

        const optim = this.props.config.optim || {};
        const selections = optim.selections || [];
        let selection = selections && selections.length == 1 ? selections[0] :
            {
                label: selections.map(s => s.label).join(', ')
            };

        const selMetric = optim.metric || globals.default_optimizer_metric;
        const spinnerSel = selections.find((sel, idx) => idx === this.state.spinnerIndex);

        return (
            <div className="page optimize">
            
                <PageHeader 
                    fullWidth={true}
                    title={'Optimize'}
                    right={
                        <>
                            
                        </>
                    }
                />

                <div className="content">

                    <div className='container goal'>
                        <h3>Select goal:</h3>
                        <div className='goal-section'>
                            {globals.metrics.map(metric => 
                                <span key={metric.key}
                                    className={'item ' + (metric.key === selMetric ? ' sel' : '')}
                                    onClick={() => this.props.setOptimizationMetric(metric.key)}
                                >
                                    Maximize {metric.label}
                                </span>
                            )}
                        </div>
                    </div>

                    <div className='container audience'>
                        <h3>Select target audience:</h3>
                        <FilterChooser style={{width: '100%'}} />
                    </div>
                    

                    <div className='container constraints'>
                        {/* <h3>Select target {study.productLabel}(s) and features:</h3> */}
                        {/* <ProductBlock studyId={study.uid} {...selection}
                                standalone={true}
                                allow={['product']}
                                style={{ width: '100%' }}
                                onClick={() => window.topBar.openProductsMulti(selections,
                                    (sel) => {
                                        this.props.toggleOptimizationSelection(sel, (newSelections) => {
                                            window.productDrawer.setState( { sels: newSelections });
                                        });
                                    },
                                    {
                                        allow: ['product'],
                                        multiSelect: true
                                    }
                                )}
                        />   */}

                        <OptimizeEditorMulti selections={selections} />
                        
                    </div>


                    <div className='container optimize-go-box'>
                        <h3>Run optimizer:</h3>
                            <div>
                                <span className={'action-button' + (selections.length == 0 ? ' disabled' : '')} style={{display:'inline'}} onClick={() => window.topBar.openMenu('optruns')}>
                                    <i className='fal fa-running'/> Running...
                                </span>
                            </div>
                        <div className='opt-button'>
                            {selections.length === 0 ?
                                <div className='btn-note'>
                                    (Select target {study.productLabel}(s) first)
                                </div>
                                : null
                            }
                            <div>
                                <span className={'action-button' + (selections.length == 0 ? ' disabled' : '')} style={{display:'inline-block'}} onClick={this.optimizeClicked}>
                                    <i className='fal fa-cog'/> Optimize
                                </span>
                            </div>
                        </div>

                    </div>

                </div>

        </div>
        );
    }
}

Optimize = withRouter(
    connect(
        state => state.study,
        dispatch => bindActionCreators({
            ...configActionCreators,
            ...studyActionCreators,
        }, dispatch)
    )(Optimize)
);
