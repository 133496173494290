import React from 'react'

import ScatterPlot from '../vis/ScatterPlot2'

const DScatterPlot = ({ compute, renderConfig }) => {

  if( !compute?.data ) return 'missing data';
  if( !renderConfig?.x ) return 'missing x';
  if( !renderConfig?.y ) return 'missing y';

	return <ScatterPlot
    data={compute?.data}
    x={renderConfig.x}
    y={renderConfig.y}
    highlight={renderConfig.highlight}
    average={renderConfig.average}
  />
}

export default DScatterPlot
