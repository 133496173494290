import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//import { actionCreators } from '../../store/BigData';
import { ScenarioSummary } from '../config/ScenarioSummary';
import './DCMConfigWidget2.scss';
import { ScenarioWidgetDebugBar } from './ScenarioWidgetDebugBar';

export class DCMConfigWidget2 extends React.Component{

    constructor(props){
        super(props);
        this.state = {
        };

        // Allow card to be saved as insight card. Not the greatest pattern.
        this.props.stateContext.getSaveState = this.getSaveState;
    }

    static inheritOptions = ['selection', 'configEtag'];

    static scenarioDriven = true;

    getSaveState = () => {

        return {
            config: this.props.config
        }

    }

    render() {

        const config = this.props.config;
        const slot = config ? config.productSlots.find(s => s.uid === (this.props.selection || {}).product) : null;
        //if( !slot ) return "no product";

        const pdef = config.productDefs[slot?.def] || config.productDefs.default; // todo: get this from study, because a saved config shouldn't have productDefs. It does right now, but it shouldn't.
        if( !pdef ) return "no pdef " + slot.def;

        const summarySelectedItem = config ? config.summarySelectedItem : null;
        //if( !summarySelectedItem ) return "no selection";
        
        const extraProps = this.props.inDataCard ? {} :
            {
                staticConfig: config
            };

        if (this.props.getJSONData && typeof this.props.getJSONData === 'function')
            this.props.getJSONData(null, true)

        const content = //!slot ? 'no data' :
            config.loading ? 'loading...' :
            <ScenarioSummary filter={summarySelectedItem} changesOnly={true} {...extraProps} />

        return <div className='widget'>
            
            <div className='widget-header'>
                <ScenarioWidgetDebugBar {...this.props}/>
                <div className='title'>
                    {this.props.title || 'Scenario Changes'}
                </div>
            </div>
            <div className='widget-body vscroll'>
                {content}
            </div>
            
        </div>;

    }

}

DCMConfigWidget2 = connect(
    (state, ownProps) => ownProps.inDataCard ? {
        study: state.study,
        config: state.study?.config
    } : {}
    //,dispatch => bindActionCreators(actionCreators, dispatch)
)(DCMConfigWidget2)