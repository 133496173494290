import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
	CartesianGrid,
	Cell,
	Line,
	LineChart,
	ResponsiveContainer,
	Scatter,
	ScatterChart,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as forecastActionCreators } from "../../store/Forecast.ts";
import { actionCreators as rsActionCreators } from "../../store/ResearchScenarios.ts";
import { CardLoading } from "../card/CardLoading";
import { ComboBox } from "../common/ComboBox";
import { PageHeader2 } from "../common/PageHeader";
import { OptRunScenario } from "../optimize/OptRunScenario";
import "./ResearchScenarios.scss";

class CustomizedLabel extends PureComponent {
	render() {
		const { x, y, stroke, value } = this.props;

		return (
			<text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
				{value}
			</text>
		);
	}
}

class CustomizedXAxisTick extends PureComponent {
	render() {
		const { x, y, stroke, payload } = this.props;

		return (
			<g transform={`translate(${x},${y})`}>
				<text
					x={0}
					y={0}
					dy={16}
					textAnchor="end"
					fill="#666"
					transform="rotate(-35)">
					{payload.value}
				</text>
			</g>
		);
	}
}

class CustomizedDot extends PureComponent {
	render() {
		const { cx, cy, stroke, payload, value } = this.props;

		const dot =
			payload.key === "basecase" ? (
				<circle cx={cx} cy={cy} r={5} stroke={stroke} fill={stroke} />
			) : (
				<circle cx={cx} cy={cy} r={4} stroke={stroke} fill={"#ffffff"} />
			);

		return payload.isSelected ? (
			<g>
				<circle cx={cx} cy={cy} r={5} fill="#202020" />
				<text
					x={cx + 10}
					y={cy}
					transform={`rotate(-90 ${cx} ${cy})`}
					textAnchor="start"
					fill="#404040"
					dy=".3em">
					{payload.name}
				</text>
			</g>
		) : (
			dot
		);
	}
}

export const createBaseCaseScenario = (config, cache, goal) => {
	if (!config) return null;
	if (!goal) return null;

	const targetFilter =
		goal.targetFilter || globals.getDefaultFilter(this.props.study);
	const targetProducts = goal.targetProducts;

	// add the base case (which is formatted a little differently, so it needs to be tweaked a bit)
	const forecast = cache ? cache.forecast : null;
	const filterCache = forecast ? forecast[targetFilter] : null;
	let baseCaseScenario = {
		key: "basecase",
		label: "Base case",
		index: -1,
		cache: {
			metrics: globals.metrics.reduce((acc, metric) => {
				acc[metric.key] = {};

				let target = null;
				targetProducts.forEach((tp) => {
					const base = ((filterCache || {})[tp] || {}).base || {};
					const baseVal = base[metric.key];
					acc[metric.key][tp] = baseVal;
					if (baseVal) {
						target =
							metric.key === "consider"
								? Math.max(target || 0, baseVal)
								: (target || 0) + baseVal;
					}
				});

				acc[metric.key]["{target}"] = target;

				return acc;
			}, {}),
		},
		config: {
			type: "single",
			productSlots: config.productSlots.reduce((acc, el) => {
				acc[el.uid] = { ...el.play };
				acc[el.uid].label = el.label;
				return acc;
			}, {}),
		},
	};
	return baseCaseScenario;
};

export class ResearchScenarios extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			x: "index",
			y: globals.default_optimizer_metric,
			selectedScenarios: [],
		};
	}

	componentDidMount() {
		this.checkData();
	}

	componentDidUpdate() {
		this.checkData();
	}

	checkData = () => {
		//console.log('ResearchScenarios checkData');

		//let studyId = this.props.match.params.studyId;
		let runId = this.props.match.params.runId;

		let study = this.props.study;
		if (!study) return;

		let researchScenarios = this.props.researchScenarios;
		if (!researchScenarios) return;

		let optRun = this.props.optRun;
		if (!optRun) {
			this.props.loadOptRun(runId, (optRun) => {
				// set y metric to match optimization run goal
				if (
					optRun &&
					optRun.input &&
					optRun.input.goal &&
					optRun.input.goal.targetMeasure
				) {
					this.setState({
						y: optRun.input.goal.targetMeasure,
					});
				}
			});
		}

		// todo: check for current scenario's results
		const cache = this.props.cache;
		const forecast = cache ? cache.forecast : null;
		if (forecast) {
			const filter =
				this.props.filter || globals.getDefaultFilter(this.props.study);
			const filterCache = (forecast || {})[filter.syntax];
			if (!filterCache) {
				this.props.requestForecast();
			}
		}
	};

	refresh = () => {
		//this.props.computeScenarios();
	};

	filterClicked = () => {
		//window.topBar.openMenu('filter');
	};

	sortDirs = ["desc", "asc", "none"];
	labelSortDirs = ["asc", "desc", "none"];

	xChanged = (item) => {
		this.setState({
			x: item.key,
		});
	};

	yChanged = (item) => {
		this.setState({
			y: item.key,
		});
	};

	selectScenario(ev) {
		let newList = this.state.selectedScenarios;
		if (newList.includes(ev.payload.key)) {
			newList = newList.filter((x) => x != ev.payload.key);
		} else {
			newList = [...newList, ev.payload.key];
		}
		this.setState({ selectedScenarios: newList });
	}

	render() {
		const optRun = this.props.optRun;
		if (!optRun) return null;

		if (optRun.loading) return "loading";

		if (optRun.error) return "error: " + optRun.error;

		const output = optRun.output;

		const input = optRun.input || {};
		const goal = input.goal || {};
		const targetProducts = goal.targetProducts || [];
		const targetFilter = goal.targetFilter || {};
		const targetFilterLabel = goal.targetFilterLabel || targetFilter;

		const study = this.props.study;
		if (!study) return null;

		const config = study.config;
		if (!config) return null;

		const slots = config.productSlots || [];

		const targetProductLabels = targetProducts.map((tp) => {
			const slot = slots.find((slot) => slot.uid === tp);
			return slot ? slot.label : tp;
		});

		const axis_items = [
			{
				label: "Scenario Index",
				key: "index",
			},
			...globals.metrics,
		];

		let xAxisMetric =
			axis_items.find((item) => item.key === this.state.x) || axis_items[0];
		let yAxisMetric =
			axis_items.find((item) => item.key === this.state.y) ||
			axis_items.find((i) => i.key === globals.default_optimizer_metric);

		// get scenarios
		const scenarios = (output ? output.scenarios : null) || [];
		let sortedScenarios = scenarios.map((x) => x); //.map((s, idx) => ({...s, index: idx}));

		// add the base case (which is formatted a little differently, so it needs to be tweaked a bit)
		let baseCaseScenario = createBaseCaseScenario(
			config,
			this.props.cache,
			goal,
		);
		sortedScenarios.push(baseCaseScenario);

		// sort scenarios
		const sort = {
			col: this.state.y || "index", // this.state.sortCol || 'index',
			dir: "asc", // this.state.sortDir || 'desc'
		};

		{
			sortedScenarios = sortedScenarios.sort((a, b) => {
				if (sort.col === "index") {
					return sort.dir === "asc" ? a.index - b.index : b.index - a.index;
				} else if (sort.col === "label") {
					return sort.dir === "asc"
						? a.label.localeCompare(b.label)
						: b.label.localeCompare(a.label);
				} else {
					const getCacheResultData = (cache, metricKey) =>
						(((cache || {}).metrics || {})[metricKey] || {})["{target}"];

					let xa = getCacheResultData(a.cache, sort.col);
					let xb = getCacheResultData(b.cache, sort.col);
					return sort.dir === "asc" ? xa - xb : xb - xa;
				}
			});
		}

		let selectedScenarios = sortedScenarios.filter((s) =>
			this.state.selectedScenarios.includes(s.key),
		);

		// hack for easy testing:
		//selectedScenarios = sortedScenarios.filter((x, i) => x.index == 0); // i % 4 === 1 && i < 10);

		let chartData = sortedScenarios.map((s, idx) => {
			const metrics = s.cache ? s.cache.metrics : null;
			let obj = {
				key: s.key,
				name: s.label,
				index: s.index,
				[xAxisMetric.key]:
					xAxisMetric.key === "index"
						? idx
						: metrics && metrics[xAxisMetric.key]
						? metrics[xAxisMetric.key]["{target}"]
						: null,
				[yAxisMetric.key]:
					metrics && metrics[yAxisMetric.key]
						? metrics[yAxisMetric.key]["{target}"]
						: null,
				isSelected: selectedScenarios.includes(s),
			};
			return obj;
		});

		//dont delete
	//<PageHeader2 title={"Analyze Scenarios"} />

		return (
			<div className="page research-scenarios">

				<div className="content scrolling">
					{this.props.cache.forecast[targetFilter]?.loading === false ? (
						<>
							<div className="map">
								<div className="map-title">
									<span className="label1">
										Target {study.productLabel}(s):
									</span>
									<span className="label2">
										{targetProductLabels.join(", ")}
									</span>
									;<span className="label1">Target Audience:</span>
									<span className="label2">{targetFilterLabel}</span>
								</div>

								{xAxisMetric.key === "index" ? (
									<ResponsiveContainer
										width={"100%"}
										height={300}
										maxWidth={1000}>
										<LineChart
											data={chartData}
											margin={{
												top: 55,
												right: 20,
												left: 10,
												bottom: 0,
											}}>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis
												dataKey="name"
												height={60}
												tick={<CustomizedXAxisTick />}
											/>
											<YAxis tickFormatter={yAxisMetric.fformat} />
											<Tooltip formatter={yAxisMetric.fformat} />
											{/* <Legend /> */}
											<Line
												key={yAxisMetric.key}
												isAnimationActive={false}
												type="monotone"
												dataKey={yAxisMetric.key}
												stroke={yAxisMetric.color}
												dot={<CustomizedDot context={this} />}
												activeDot={{
													onClick: (ev) => {
														this.selectScenario(ev);
													},
													stroke: "red",
													strokeWidth: 1,
													fill: "rgba(255,0,0,0.1)",
													r: 7,
												}}
											/>
										</LineChart>
									</ResponsiveContainer>
								) : (
									<ScatterChart
										width={600}
										height={300}
										margin={{
											top: 20,
											right: 20,
											bottom: 20,
											left: 20,
										}}>
										<CartesianGrid />
										<XAxis
											type="number"
											domain={["dataMin", "dataMax"]}
											dataKey={xAxisMetric.key}
											name={xAxisMetric.label}
											tickFormatter={xAxisMetric.fformat}
										/>
										<YAxis
											type="number"
											domain={["dataMin", "dataMax"]}
											dataKey={yAxisMetric.key}
											name={yAxisMetric.label}
											tickFormatter={yAxisMetric.fformat}
										/>
										<Tooltip cursor={{ strokeDasharray: "3 3" }} />
										<Scatter
											name="A school"
											data={chartData}
											fill="#8884d8"
											onClick={(ev) => this.selectScenario(ev)}>
											{chartData.map((entry, index) => {
												let obj = this.state.selectedScenarios.filter(
													(item) => item === entry.key,
												);
												return obj.length > 0 ? (
													<Cell key={`cell-${index}`} fill="#333" />
												) : (
													<Cell key={`cell-${index}`} fill="#8884d8" />
												);
											})}
										</Scatter>
									</ScatterChart>
								)}

								<div className="axis-boxes">
									<ComboBox
										style={{ marginLeft: 10 }}
										width={140}
										menuWidth={140}
										items={axis_items}
										icon={<span style={{ fontWeight: 600 }}>x:</span>}
										caption={xAxisMetric.label}
										selectionChanged={(item) => this.xChanged(item)}
									/>

									<ComboBox
										style={{ marginLeft: 10 }}
										width={140}
										menuWidth={140}
										items={axis_items.filter((i) => i.key !== "index")}
										icon={<span style={{ fontWeight: 600 }}>y:</span>}
										caption={yAxisMetric.label}
										selectionChanged={(item) => this.yChanged(item)}
									/>
								</div>
							</div>

							<div className={"scenarios"}>
								{selectedScenarios.length === 0 ? (
									<div className="no-scenarios-note">
										Click scenario(s) in chart to view detail
									</div>
								) : (
									<div className="scenario-grid">
										{selectedScenarios.map((scenario) => {
											const smetrics = scenario.cache
												? scenario.cache.metrics
												: null;
											return (
												<div className="scenario" key={scenario.key}>
													<OptRunScenario
														runId={this.props.runId}
														scenarioKey={scenario.key}
														goal={goal}
														products={targetProducts}
													/>
												</div>
											);
										})}
									</div>
								)}
							</div>
						</>
					) : (
						<CardLoading
							loading={this.props.cache.forecast[targetFilter]?.loading}
						/>
					)}
				</div>
			</div>
		);
	}
}

ResearchScenarios = withRouter(
	connect(
		(state, ownProps) => ({
			study: state.study,
			cache: state.cache,
			researchScenarios: state.researchScenarios,
			optRun: state.researchScenarios
				? state.researchScenarios.optRuns[ownProps.runId]
				: null,
		}),
		(dispatch) =>
			bindActionCreators(
				{ ...rsActionCreators, ...forecastActionCreators },
				dispatch,
			),
	)(ResearchScenarios),
);
