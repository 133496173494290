import * as React from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as globals from "../../globals";
import * as apiService from "../../utils/apiService";
import * as debounce from "../../utils/debounce";
import "./ElementPropertyEditor.scss";
import { TabStrip2, Tab } from "../common/TabStrip";
// import  Editor  from '@monaco-editor/react';
import { CodePropertyEditor } from "./CodePropertyEditor";
import { constructAutoSelect } from "../../utils/metaUtils";
import { stringValue } from "vega";

// const exampleBody = {
//     title: 'hi',
//     description: 'asdf',
//     data: {

//     }
// }

const uploadContentFile = (auth, study_uid, data, callback) => {
	fetch(`${globals.apiRoot}/study/${study_uid}/content-file`, {
		method: "POST",
		body: data,
		headers: {
			Authorization: "Bearer " + auth.token,
		},
	})
		.then((response) => {
			if (response.ok) {
				console.log("upload succeess");
				response
					.json()
					.then((res) => {
						console.log("got response json", res);
						if (callback) {
							callback(res);
						}
					})
					.catch((err) => {
						console.error(err);
					});
			}
		})
		.catch((err) => {
			console.error(err);
		});

};


export const ElementPropertyEditor = (props) => {
	const auth = useSelector((state) => state.auth);
	const study_uid = useSelector((state) => state.study?.uid);

	const [expandInfo, setExpandInfo] = useState(false);
	const [editParent, setEditParent] = useState(false);
	const [editId, setEditId] = useState(false);
	const [studyId, setStudyId] = useState(study_uid);
	const [dirty, setDirty] = useState(false);

	const prop = props.prop;
	const item = props.item;
	const inData = prop?.name?.startsWith("data.");
	const localName = inData ? prop.name?.substring(5) : prop?.name || "error";
	const currentValue = inData ? item?.data?.[localName] : item?.[localName];
	const isJson = prop.json;

	const [imageUploading, setImageUploading] = useState(false);

	const [value, setValue] = useState(
		isJson ? JSON.stringify(currentValue) : currentValue,
	);
	if (!prop || !item) return null;

	const updateContentItem = props.updateContentItem;

	

	const valueChanged = (newValue, reload) => {
		setValue(newValue);
		setDirty(true);
		let saveValue = newValue;
		if (prop.json) {
			try {
				saveValue = saveValue === "" ? null : JSON.parse(saveValue);
				console.log("parsed", saveValue);
			} catch (err) {
				//console.log(err);
				return; // cancel the update
			}
		}

		const model = inData
			? {
				data: {
					[localName]: saveValue,
				},
				mergeData: true,
			}
			: {
				[localName]: saveValue,
			};
		console.log('saving model', model);
		
		
		updateContentItem(auth, study_uid, item.id, model, () => {
			setDirty(false);
			if (reload) {
				reload();
			}
		});


	};

	const genericValueChanged = (ev, reload) => {
		const newValue = ev.target.value;
		valueChanged(ev.target.value, reload);
	};

	const boolValueChanged = (ev, reload) => {
		const newValue = ev.target.checked ? 1 : 0;
		//console.log('newvALUE', newValue, ev.target.value);
		valueChanged(newValue, reload);
	};

	const moveElement = (ev) => {
		apiService
			.aPost(
				auth,
				`${globals.apiRoot}/study/${study_uid}/content/${item?.id}/moveto?dest_parent_id=${value}&dest_studyid=${studyId}`,
			)
			.then(() => {
				setEditParent(false);
			})
			.catch((err) => {
				console.log("error");
				console.error(err);
			});
	};

	const idValueChanged = (ev, reload) => {
		const newValue = ev.target.value;
		setValue(newValue);
		// don't save yet
	};

	const changeElementId = () => {
		if (item?.id === "explore") {
			alert("please don't change id of explore");
			return;
		}
		apiService
			.aPost(
				auth,
				`${globals.apiRoot}/study/${study_uid}/content/${item?.id}/change-id?new_id=${value}`,
			)
			.then((res) => res.text())
			.then((res) => {
				alert(res);
				setEditId(false);
			})
			.catch((err) => {
				console.log("error");
				console.error(err);
			});
	};

	const readURL = (event, reload) => {
		if (event.target.files[0].type.split("/")[0] === "image") {
			const formData = new FormData();
			formData.append("File", event.target.files[0]);

			uploadContentFile(auth, study_uid, formData, (res) => {
				console.log("uploaded file. res is ", res);
				const model = {
					data: {
						[localName]: res.url,
					},
					mergeData: true,
				};
				console.log("uploading model", item.id, model);

				updateContentItem(auth, study_uid, item.id, model, reload);
				console.log("uploadContentImage callback");
				//window.location.reload();
			});
		} else {
			alert("Please upload a valid format of image e.g. png, jpg");
		}
	};

	const addTabSpace = (e) => {
		let keyCode = e.keyCode || e.which;
		if (keyCode == 9) {
			e.preventDefault();
			let start = e.target.selectionStart;
			let end = e.target.selectionEnd;
			//add space
			e.target.value =
				e.target.value.substring(0, start) +
				"\t" +
				e.target.value.substring(end);

			// put caret at right position again
			e.target.selectionEnd = start + 1;
		}
	};

	const deprecated = prop.deprecated;
	const fullWidth = prop.codePrec ? true : false;

	return (
		<>
			{fullWidth ? null :
				<>
					<div
						className={"property-info-button" + (dirty ? " dirty" : "")}
						onClick={() => setExpandInfo(!expandInfo)}>
						<i
							className={dirty ? "fas fa-circle" : expandInfo ? "fal fa-minus-circle" : "fal fa-info-circle"}
						/>
					</div>
					<div
						className={"property-name " + (inData ? "data-prop" : "core-prop") + (deprecated ? " deprecated " : "") + (fullWidth ? " fullwidth" : "")}
						onClick={() => setExpandInfo(!expandInfo)}>
						{localName}
					</div>
				</>
			}
			<div className={"property-value " + (fullWidth ? " fullwidth " : "")}>
				{prop.type === "image" ? (
					<div>
						{imageUploading ? (
							"please wait..."
						) : (
								<div className="image-property">
									<input
										type="text"
										value={value || ""}
										disabled={prop.readOnly}
										placeholder={prop.defaultValue}
										onChange={(ev) => genericValueChanged(ev, props.reloadItem)}
									/>
									<label className="custom-file-upload">
										<input
											type="file"
											name="image-upload"
											onChange={(e) => {
												setImageUploading(true);
												readURL(e, () => {
													setImageUploading(false);
													props.reloadItem?.();
												});
											}}
											title="Upload Photo"
										/>
										<i className="fa fa-ellipsis-h" />
									</label>
								</div>
							)}
					</div>
				) : prop.type === "xmarkdown" ? (
					<div className="markdown-property">
						<textarea
							className="markdown-editor"
							style={{ height: prop.editorHeight }}
							value={value || ""}
							onChange={(ev) => genericValueChanged(ev, props.reloadItem)}
						/>
					</div>
					) : prop.type === "javascript" || prop.type === "jsx" ? (
							<CodePropertyEditor {...props} dirty={props.dirty} />
					) : prop.type === "markdown"  ? (
							<CodePropertyEditor {...props} dirty={props.dirty} language="markdown" />
				) : prop.type === "select" ? (
					<div className="select-property">
						<select
							value={value || ""}
							onChange={(ev) =>
								genericValueChanged(ev, props.reloadItem, "visual")
							}>
							{prop.examples.map((e, idx) => (
								<option key={idx} className="example">
									{e}
								</option>
							))}
						</select>
					</div>
				) : prop.type === "bool" ? (
					
					<label htmlFor={`checkbox_${localName}`}>
						<input
							id={`checkbox_${localName}`}
							type="checkbox"
							checked={value == 1}
							disabled={prop.readOnly}
							onChange={(ev) => boolValueChanged(ev, props.reloadItem)}
						/>
						{value == 1 ? "true" : "false"}
					</label>
				) : prop.type === "parent" ? (
					<div className="parent-property">
						<input
							type="text"
							value={value || ""}
							disabled={prop.readOnly && !editParent}
							placeholder={prop.defaultValue}
							onChange={(ev) => genericValueChanged(ev, props.reloadItem)}
						/>
						<div className="study-id-content">
							<div
								className={
									"property-name " + (inData ? "data-prop" : "core-prop")
								}
								onClick={() => setExpandInfo(!expandInfo)}>
								study id
							</div>
							<input
								type="text"
								defaultValue={studyId || ""}
								disabled={prop.readOnly && !editParent}
								placeholder={"study_id"}
								onChange={(ev) => setStudyId(ev.target.value)}
							/>
						</div>
						<div className="action-btn">
							{editParent ? (
								<>
									<button className="btn inline-block left-space" onClick={moveElement}>
										Done
									</button>
									<button className="btn inline-block left-space" onClick={(e) => setEditParent(false)}>
										Cancel
									</button>
								</>
							) : (
									<button className="btn inline-block left-space" onClick={(e) => setEditParent(true)}>
										Move
									</button>
								)}
						</div>
					</div>
				) : prop.name === "id" && prop.type === "string(36)" ? (
					<div>
						<input
							id={'ITEM_ID'}
							type="text"
							value={value || ""}
							disabled={!editId}
							placeholder={prop.defaultValue}
							onChange={(ev) => idValueChanged(ev)}
							style={{ width: 300 }}
												/>
												<button className='btn inline-block' onClick={(ev) => {
													const el = document.getElementById('ITEM_ID');
													el.select();
													el.setSelectionRange(0, 99999);
													document.execCommand("copy");
													window.topBar.enqueueSnack({
														id: 'copy_' + el.value,
														message: 'Copied to clipboard: ' + el.value,
														to: 3000,
														success: false
													})
													//alert("copied: " + el.value);
												}}>
							<i className='fal fa-copy'/>
						</button>
						{editId ? (
							<>
								<button
									className="btn inline-block"
									onClick={() => {
										changeElementId();
										//setEditId(false); will be called by changeElementId
									}}>
									Save
								</button>
								<button
									className="btn inline-block"
									onClick={() => {
										setEditId(false);
										// reload the id
										props.reloadItem?.();
									}}>
									Cancel
								</button>
							</>
						) : (
								<>
									<button
										className="btn inline-block"
										onClick={() => setEditId(true)}>
										Edit
								</button>
								</>
							)}
					</div>
				) : (
													<input
														type="text"
														value={value || ""}
														disabled={prop.readOnly}
														placeholder={prop.defaultValue}
														onChange={(ev) => genericValueChanged(ev, props.reloadItem)}
													/>
												)}
			</div>
			{expandInfo ? (
				<>
					<div className="property-separator" />
					<div className="property-separator" />
					<div className="property-info property-separator">
						<div className="type">
							type: {prop.type}{" "}
							{prop.required ? (
								<span className="required">required</span>
							) : (
									<span className="optional">optional</span>
								)}
						</div>
						<div className="description">{prop.description}</div>
						{prop.examples ? (
							<div className="examples">
								{prop.examples.map((e, idx) => (
									<div key={idx} className="example">
										example: {e}
									</div>
								))}
							</div>
						) : null}
						{prop.link ? (
							<div className="link">
								<a href={prop.link} target="_blank">
									{prop.link}
								</a>
							</div>
						) : null}
					</div>
				</>
			) : null}
		</>
	);
};

const PROP_ID = {
	name: "id",
	type: "string(36)",
	required: true,
	readOnly: true,
	description:
		"Unique identifier for this element. Should be unique within study.",
};
const PROP_PARENT_ID = {
	name: "parent_id",
	type: "parent",
	required: true,
	readOnly: true,
	description: "Parent identifier of this element",
};
const PROP_TYPE = { name: "type", type: "string", readOnly: true };
const PROP_HIDE = { name: "data.hide", type: "bool", description: "If true, object will not display unless editMode is on" };
const PROP_TITLE = {
	name: "title",
	type: "string(100)",
	description: "If exists, will be shown as header label over container",
};
const PROP_SUBTITLE = { name: "data.subTitle", type: "string(100)" };
const PROP_DESCRIPTION = { name: "description", type: "string" };
const PROP_ICON = {
	name: "data.icon",
	type: "string(100)",
	examples: ["fal fa-rocket"],
	link: "https://fontawesome.com/icons?d=gallery",
};

const SEARCH_TAGS = {name:"data.searchTags", type: "string", description: "Targeting, market share, price sensitivities,..."}
const EXAMPLES_HTMLCOLOR = ["#0064c8", "red", "rgba(0,100,200,0.5)"];
const EXAMPLES_MARGIN = [
	"0 // no margin",
	"10px // 10 pixel margin around all sides",
	"10px 0 // 10 pixel margin on top and bottom, no margin on sides",
	"0 0 10px 0 // 10 pixel margin on bottom only (declared as top, right, bottom, left)",
];
const EXAMPLES_BORDER = ["1px solid red"];
const EXAMPLES_RENDER_CONFIG = [
	`{"series" : [{"label": "uv", "props": {"fill" : "red" }  },{"label": "pv", "props": {"fill" : "blue" }  }], "xAxis": { "label" : "count" , "props": { "tickFormatter" : "format" } }, "yAxis": { "label" : "number" , "props": { "tickFormatter" : "format" } } }`,
];

const PROP_CONTENTMARKDOWN = { name: "data.contentMarkdown", type: "markdown", editorHeight: 500 };
const PROP_WIDE = { name: "data.wide", type: "bool" };
const PROP_STYLE = { name: "data.style", type: "object", json: true, examples:["{\"border\":\"2px dashed black\"}"]};
const PROP_USE_SELECTION = { name: "data.useSelection", type: "bool", deprecated: true, };
const PROP_USE_SELECTIONS = {
	name: "data.useSelections", type: "array", json: true,
	examples: ["[\"title\"]", "[\"title(2)\"]", "[\"title(*)\"]", "[\"segment\", \"title\"]"]
};
const PROP_USE_MODELS = {
	name: "data.useModels", type: "array", json: true,
	description: "an entry should exist in meta models array with matching name",
	examples: ["[\"dcmModel1\"]"]
};
const PROP_USE_CONFIGS = {
	name: "data.useConfigs", type: "array", json: true,
	description: "an entry should exist in meta configs array with matching name",
	examples: ["[\"play\", \"base\", \"form1\" ]"]
};
const PROP_USE_MODEL_CONFIGS = {
	name: "data.useModelConfigs", type: "array", json: true,
	description: "todo",
	examples: ["[\"model1\", \"model2\"]"]
};
const PROP_USE_MODEL_RESULTS = {
	name: "data.useModelResults", type: "array", json: true,
	description: "todo",
	examples: ["[\"model1\", \"model2\"]"]
};
const PROP_SELECTION_LEVEL = {
	name: "data.selectionLevel",
	deprecated: true,
	type: "string",
	examples: ["product", "publisher", "model", "brand"],
};
const PROP_SELECTION_LABEL_OVERRIDE = {
	name: "data.selectionLabelOverride",
	deprecated: true,
	type: "string",
};
const PROP_SELECTION_ALLOW_MULTI = {
	name: "data.selectionAllowMulti",
	deprecated: true,
	type: "bool",
};

const PROP_STICKY = { name: "data.sticky", type: "bool" };

const PROP_USE_COMPS = { name: "data.useComps", type: "bool", deprecated: true };
const PROP_USE_FILTER = { name: "data.useFilter", type: "bool", xdeprecated: true };
const PROP_USE_FILTER_DATASET_ID = { name: "data.useFilterDatasetId", type: "string" };
const PROP_SHOW_SELECTOR = { name: "data.showSelector", type: "bool"};
const PROP_SELECTOR_TYPE = { name: "data.selectorType", type: "string(100)", examples: ["title", "keygroup"] };
const PROP_USE_COMPARE = { name: "data.useCompare", type: "bool", xdeprecated: true };
const PROP_HEIGHT = { name: "data.height", type: "int" };
const PROP_WIDTH = { name: "data.width", type: "int" };
const PROP_RESIZABLE = { name: "data.resizable", type: "bool" , description: 'Make flexbox items resizable by user' };

const PROP_REMOVEBORDERS = { name: "data.removeBorders", type: "bool" };
const PROP_BORDERRADIUS = { name: "data.borderRadius", type: "bool" };

const PROP_SHADOW = { name: "data.shadow", type: "bool" };
const PROP_SHOWLOADINGICON = { name: "data.dontShowLoadingIcon", type: "bool", default: true };
const PROP_HIDEMENU = { name: "data.hideMenu", type: "bool" };

const PROP_GRIDCOLUMN = { name: "data.gridColumn", type: "string", examples: ["span 2"] };
const PROP_GRIDROW = { name: "data.gridRow", type: "string", examples: ["span 2"] };

const PROP_FLEXDIRECTION = { name: "data.flexDirection", type: "string", examples: ["row", "column","row-reverse", "column-reverse"] };
const PROP_FLEXJUSTIFYCONTENT = { name: "data.flexJustifyContent", type: "string", examples: ["flex-start", "center", "flex-end", "space-between", "space-around", "space-evenly"] };
const PROP_FLEXALIGNITEMS = { name: "data.flexAlignItems", type: "string", examples: ["stretch","center", "flex-start", "flex-end"] };

const PROP_USE_GLOBAL_STORE_ITEMS = {
	name: "data.useGlobalStoreItems",
	type: "array",
	json: true,
	examples: ['["mykey1", "mykey2"]'],
};

const PROP_USE_META_ITEMS = {
	name: "data.useMetaItems",
	type: "array",
	json: true,
	examples: ['["mykey1", "mykey2"]'],
};

const PROP_INSTANCES = {
	name: "data.instances",
	group: "Instances",
	type: "javascript",
	codePrec: "const instances = async (context, lib) => {",
	codePost: "}",
	editorHeight: 700,
};

// const PROP_SHOWIF = {
// 	name: "data.showIf",
// 	type: "javascript",
// 	editHeight: 100,
// };

const PROP_ALLOW_ROLES = {
	name: "allow_roles",
	type: "array",
	json: true,
	description: 'Javascript array of role strings. If you include a list of roles here, the content item will be hidden unless the user has at least one of the allow_roles. deny_roles is processed after this and may trump.'
};

const PROP_DENY_ROLES = {
	name: "deny_roles",
	type: "array",
	json: true,
	description: 'Javascript array of role strings. If you include a list of roles here, the content item will be hidden if the user has at least one of the deny_roles. allow_roles is processed before this but does not trump.'
};

export const ElementProperties = (props) => {
	const item = props.item;
	if (!item) return null;

	const requestClose = () => {
		if (props.closeRequested) {
			props.closeRequested();
		}
		if (props.reloadItem) {
			props.reloadItem();
		}
	};

	const type = item.type;

	let properties = [];
	properties.push(PROP_ID);
	properties.push(PROP_PARENT_ID);
	properties.push(PROP_TYPE);
	properties.push(PROP_INSTANCES);
	properties.push(PROP_ALLOW_ROLES);
	properties.push(PROP_DENY_ROLES);
	switch (type) {
		case "tile-container":
			properties.push(PROP_TITLE);
			properties.push({
				name: "data.tileBackground",
				type: "html color",
				description:
					"Child tiles will display background in this inherited color unless overwritten in the tile itself",
				examples: EXAMPLES_HTMLCOLOR,
			});
			properties.push({
				name: "data.tileForeground",
				type: "html color",
				description:
					"Child tiles will display foreground in this inherited color unless overwritten in the tile itself",
				examples: EXAMPLES_HTMLCOLOR,
			});
			properties.push({
				name: "data.tileRadius",
				type: "integer",
				description: null,
				examples: ["0", "10px", "100px"],
			});
			properties.push({
				name: "data.tileHeight",
				type: "integer",
				description: null,
				examples: ["100px", "150px"],
			});
			properties.push({
				name: "data.tileSize",
				type: "integer",
				description: null,
				examples: ["100px", "150px"],
			});
			properties.push({
				name: "data.tileSpacing",
				type: "integer",
				description: null,
				examples: ["10px", 0],
			});
			properties.push({
				name: "data.tileMargin",
				type: "integer",
				description: null,
				examples: ["10px", 0],
			});
			properties.push({
				name: "data.margin",
				type: "margin",
				description: null,
				examples: EXAMPLES_MARGIN,
			});
			properties.push(PROP_WIDE);
			break;
		
		case "nav-element":		
			properties.push(PROP_USE_SELECTIONS);
			properties.push({
				name: "data.selectorDataToUse",
				type: "string",
				examples: ['title', 'franchise', 'brand']
			});
			properties.push({
				name: "data.selectorPosition",
				type: "select",
				examples: ['start', 'end']
			});
			properties.push({
				name: "data.hasPowerPointCreator",
				type: "bool",
			}); 
			properties.push({
				name: "data.powerpointStyle",
				type: "object",
				json: true,
				description: "{'color': '#444', 'fontSize':'12pt', 'fontWeight':500}"
			});
			properties.push({
				name: "data.pptSectionName",
				type: "string",
				description: 'This will be part of the output ppt file name (name of this section eg "pricingStrategy")'
			});

			properties.push({
				name: "data.navItemsPosition",
				type: "select",
				examples: ['left', 'center', 'right']
			});		
			
			properties.push({
				name: "data.navHeight",
				type: "integer",
				examples: ['80 (px)']
			});
			properties.push({
				name: "data.scrollHighlight",
				type: "bool",
			});
			properties.push(PROP_STICKY)
			properties.push({
				name: "data.stickyFromTop",
				type: "integer",
				examples: ['80 (px) - integer']
			});
			properties.push({
				name: "data.backgroundColor",
				type: "string",
				description: '#ccc or black or rbga()'
			});
			properties.push({
				name: "data.activeColor",
				type: "string",
				description: '#ccc or black'
			});
			properties.push({
				name: "data.navItemStyle",
				type: "object",
				json: true,
				description: "{'color': '#444', 'fontSize':'12pt', 'fontWeight':500}"
			});
			properties.push({
				name: "data.zIndex",
				type: "integer",
				examples: ['101']
			});
			properties.push({
				name: "data.navItems",
				json: true,
				type: "array",
				examples: ['[  { "id":"nav1", "label":"Executive Summary", "anchorId":"exec_sum", "active": true}, { "id":"nav2", "label":"Data Analysis", "anchorId":"data_analysis"}]']
			});
				properties.push({
				name: "data.compute",
				group: "Compute",
				type: "javascript",
				codePrec: "const compute = async (context, lib, prevCache) => {",
				codePost: "}",
				editorHeight: 1000,
			});
			properties.push({
				name: "data.visualize",
				group: "Render",
				type: "bool",
			});
			properties.push({
				name: "data.visual",
				group: "Render",
				type: "select",
				deprecated: true,
				examples: [
					"bar",
					"column",
					"pie",
					"line",
					"table",
					"scatter",
					"custom",
					"wordcloud",
					"bar highcharts",
					"pie highcharts",
					"asTable"
				],
			});
			
			properties.push({
				name: "data.render",
				group: "Render",
				type: "jsx",
				codePrec: "const render = (React, ReCharts, cache, context, lib) => {",
				codePost: "}",
				editorHeight: 1000,
			});

			break;
		
		case "tab-control":
			properties.push(PROP_TITLE);
			properties.push({
				name: "data.tabsAlignment",
				type: "select",
				examples: ['left', 'center', 'right']
			});
			properties.push({
				name: "data.zIndex",
				type: "integer",
				examples: ['101']
			});
			properties.push({
				name: "data.tabStripClass",
				type: "string",
				examples: ['blue-buttons','transparent', 'transparentBG']
			});
			properties.push(PROP_SHOW_SELECTOR);
			properties.push(PROP_SELECTOR_TYPE);
			properties.push({
				name: "data.wide",
				type: "bool",
			});
			break;
		
		case "pagetitle":
			properties.push({
				name: "title",
				type: "string(100)",
				description: "If left blank - It will auto display page's (parent element) title"
			});
			properties.push(PROP_SUBTITLE);
			properties.push({
				name: "data.flex_align",
				type: "string",
				examples: ['flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'space-evenly']
			});
			properties.push({ name: "data.title_style", type: "object", json: true });
			properties.push({ name: "data.subtitle_style", type: "object", json: true });
			properties.push({ name: "data.backgroundColor", type: "string", description: '#ccc or black or rbg()' });
			properties.push({
				name: 'data.padding', 
				type: "string", 
				examples: ['0 0 50px 0']
			});
			properties.push({
				name: "data.wide",
				type: "bool",
				examples: ['true', 'false']
			});
			break;

		case "card-container":
			properties.push(PROP_TITLE);
			properties.push({
				name: "data.cards",
				json: true,
				type: "array",
				description: "Specify exact cards or exact ordering",
				examples: ['["segprofiles1","funnel1"]'],
			});
			properties.push({
				name: "data.tags",
				json: true,
				type: "array",
				description: "Include all cards with matching tag(s)",
				examples: ['["brand-awareness","something-else"]'],
			});
			properties.push(PROP_WIDE);
			properties.push({
				name: "data.gridTemplateColumns",
				type: "string",
				examples: ["repeat(auto-fill,minmax(375px,1fr))", "1fr"],
			});
			break;

		case "page":
			properties.push(PROP_TITLE);
			properties.push(PROP_ICON);
			properties.push(PROP_DESCRIPTION);
			properties.push({
				name: "data.disable",
				type: "bool",
				description: "",
				examples: ["true", "false"],
			});
			properties.push({
				name: "data.showConfig",
				type: "bool",
				description: "",
				examples: ["true", "false"],
			});
			properties.push({
				name: "data.hidePaging",
				type: "bool",
				description: "Hides the left/right arrow and 'x' buttons in breadcrumb bar. Default is false",
				examples: ["true", "false"],
			});
			properties.push(SEARCH_TAGS);
			properties.push({
				name: "data.tooltip",
				type: "string",
				description: "",
				examples: ["Page tooltip"],
			});
			properties.push({
				name: "data.tileBackground",
				type: "html color",
				description: "",
				examples: EXAMPLES_HTMLCOLOR,
			});
			properties.push({
				name: "data.tileBackgroundImage",
				type: "image",
				description: "",
				examples: [],
			});
			properties.push({
				name: "data.tileForeground",
				type: "html color",
				description: "",
				examples: EXAMPLES_HTMLCOLOR,
			});
			properties.push({
				name: "data.style",
				type: "object",
				json: true,
				examples: ["{ 'top':'45px', 'border-radius': '1px solid red'}"],
			});
			properties.push({
				name: "data.tileRadius",
				type: "integer",
				description: null,
				examples: ["0", "10px", "100px"],
			});
			properties.push({
				name: "data.tileHeight",
				type: "integer",
				description: null,
				examples: ["100px", "150px"],
			});
			properties.push({
				name: "data.maxWidth",
				type: "int",
				description: "Maximum page width",
				examples: ["1000px"],
			});
			properties.push({
				name: "data.hrefLink",
				type: "string",
				description: "",
				examples: ["#fred-the-section"],
			});
			properties.push({
				name: "data.commands",
				type: "array",
				json: true,
				description: "",
				examples: [
					"[{ \"type\": \"filter\", \"folderId\": \"gender\", \"id\": \"s4a==1\" }]",
					"[{ \"type\": \"filter\", \"filters\": [{ \"folderId\": \"gender\", \"id\": \"s4a==1\" }, { \"folderId\": \"hh_income\", \"id\": \"hh_income==3\" }]  }]",
					"[{ \"type\": \"compare\", \"groups\": [{ \"folderId\": \"total\", \"id\": \"1==1\" }, { \"folderId\": \"segment\", \"id\": \"seg1==1\" }]  }]",
					"[{ \"type\": \"select\", \"key\": \"product\", \"value\": \"crucible\" }]",
					"[{ \"type\": \"select\", \"key\": \"brand\", \"value\": \"101\" }]",
				],
			});
			properties.push(PROP_CONTENTMARKDOWN);
			break;

		case "jumbotron":
			properties.push({
				name: "data.image",
				type: "image",
				description: null,
				examples: [],
			});
			properties.push({
				name: "data.maxHeight",
				type: "int",
				description: null,
				examples: ["250px"],
			});
			properties.push({
				name: "data.margin",
				type: "margin",
				description: null,
				examples: EXAMPLES_MARGIN,
			});
			properties.push({
				name: "data.border",
				type: "border",
				description: null,
				examples: EXAMPLES_BORDER,
			});
			properties.push({
				name: "data.borderBottom",
				type: "border",
				description: null,
				examples: EXAMPLES_BORDER,
			});
			properties.push(PROP_WIDE);
			break;

		case "tab":
		case "section":
			properties.push(PROP_HIDE);
			properties.push(PROP_TITLE);
			properties.push({
				name: "data.titleStyle",
				type: "object",
				json: true,
				description: "",
			});
			properties.push(PROP_SUBTITLE);
			properties.push({
				name: "data.subTitleStyle",
				type: "object",
				json: true,
				description: "",
			});
			properties.push(PROP_STICKY);
			properties.push({
				name: "data.stickyFromTop",
				type: "int",
				description: "Height from top to be sticky. eg 50",
			});
			properties.push(SEARCH_TAGS);
			properties.push({
				name: "data.backgroundColor",
				type: "html color",
				description: "",
				examples: EXAMPLES_HTMLCOLOR,
			});
			properties.push({
				name: "data.wide",
				type: "bool",
				description: "",
				examples: ["true", "false"],
			});
			properties.push({
				name: "data.padding",
				type: "string",
				description: "",
				examples: EXAMPLES_MARGIN,
			});
			properties.push({
				name: "data.margin",
				type: "string",
				description: "",
				examples: EXAMPLES_MARGIN,
			});
			properties.push({
				name: "data.border",
				type: "string",
				description: "",
			});
			// properties.push({ // should probably add this eventually
			// 	name: "data.style",
			// 	type: "string",
			// 	description: "",
			// });
			break;

		case "flexcontainer":
			properties.push(PROP_STYLE);
			properties.push(PROP_WIDE);
			properties.push(PROP_FLEXDIRECTION);
			properties.push(PROP_FLEXJUSTIFYCONTENT);
			properties.push(PROP_FLEXALIGNITEMS);			
			break;
		
		case "flexitem":
			properties.push(PROP_TITLE);
			properties.push(PROP_SUBTITLE);
			properties.push(PROP_USE_SELECTIONS);
			properties.push(PROP_USE_CONFIGS);
			properties.push(PROP_USE_MODELS);
			properties.push(PROP_USE_FILTER);
			properties.push(PROP_USE_COMPARE);
			properties.push(PROP_USE_GLOBAL_STORE_ITEMS);
			properties.push(PROP_USE_META_ITEMS);

			properties.push(PROP_STYLE);
			properties.push(PROP_HEIGHT);
			properties.push(PROP_WIDTH);
			properties.push(PROP_RESIZABLE);
			properties.push(PROP_REMOVEBORDERS);
			properties.push(PROP_SHOWLOADINGICON);
			properties.push(PROP_GRIDCOLUMN);
			properties.push(PROP_GRIDROW);

			properties.push({
				name: "data.useRadium",
				type: "bool",
			});
			properties.push({
				name: "data.useStoryDrafts",
				type: "bool",
			});
			properties.push({
				name: "data.bodyClass",
				type: "string",
				examples: ["hscroll", "vscroll", "hscroll vscroll"],
			});
			properties.push({
				name: "data.compute",
				group: "Compute",
				type: "javascript",
				codePrec: "const compute = async (context, lib, prevCache) => {",
				codePost: "}",
				editorHeight: 700,
			});
			properties.push({
				name: "data.visualize",
				group: "Render",
				type: "bool",
			});
			properties.push({
				name: "data.visual",
				group: "Render",
				type: "select",
				deprecated: true,
				examples: [
					"bar",
					"column",
					"pie",
					"line",
					"table",
					"scatter",
					"custom",
					"wordcloud",
					"bar highcharts",
					"pie highcharts",
					"asTable"
				],
			});
			// properties.push({
			// 	name: "data.renderConfig",
			// 	group: "Render",
			// 	type: "javascript",
			// 	codePrec: "const getRenderConfig = (context, lib, cache) => {",
			// 	codePost: "}",
			// 	examples: EXAMPLES_RENDER_CONFIG,
			// 	editorHeight: 700,
			// 	deprecated: true,
			// });
			properties.push({
				name: "data.render",
				group: "Render",
				type: "jsx",
				codePrec: "const render = (React, ReCharts, cache, context, lib) => {",
				codePost: "}",
				editorHeight: 700,
			});
			properties.push({
				name: "data.asTable",
				group: "As Table",
				type: "jsx",
				codePrec: "const asTable = (cache, context, lib) => { // note: should return { columns, data }",
				codePost: "}",
				editorHeight: 700,
			});
			properties.push({
				name: "description", 
				group: "Info",
				type: "markdown",				
				editorHeight: 100,
			});
			properties.push({
				name: "data.disableDOMExport",
				group: "General",
				type: "bool",
			});
			properties.push({
				name: "data.hideAddMenu",
				group: "General",
				type: "bool",
			});
			properties.push(PROP_HIDEMENU);	
			
			break;

		case "rrow":
			properties.push(PROP_STICKY);
			properties.push(PROP_HEIGHT);
			properties.push({
				name: "data.margin",
				type: "margin",
				description: null,
				examples: EXAMPLES_MARGIN,
			});
			properties.push({
				name: "data.padding",
				type: "padding",
				description: null,
				examples: EXAMPLES_MARGIN,
			});
			properties.push(PROP_WIDE);
			properties.push({
				name: "data.gap",
				type: "string",
				description: 'default is 10px',
			});
			properties.push(SEARCH_TAGS);

			properties.push({
				name: "data.columns",
				type: "int",
				description: "default is 2. 2-5 is supported.",
			});

			properties.push({
				name: "data.columns_grid",
				type: "string",
				description: "2 columns ? enter 1fr, 2fr.",
			});


			properties.push({
				name: "data.maxHeight",
				type: "int",
				description: "if specified, overflow:hidden is also set",
			});
			properties.push(PROP_STYLE);
			break;

		case "markdown":
			properties.push({ name: "data.content", type: "markdown", editorHeight: 500 });
			properties.push(PROP_STYLE);
			properties.push(PROP_GRIDCOLUMN);
			properties.push(PROP_GRIDROW);
			break;
		
		case "pointer":
			properties.push({ name: "data.src_id", type: "string" });
			break;
		
		case "selection-block":
			properties.push({
				name: "data.type",
				type: "string",
				description: "e.g., brand, brand(2), brand(*), country, product, etc.",
			});
			properties.push(PROP_STYLE);
			properties.push(PROP_GRIDCOLUMN);
			properties.push(PROP_GRIDROW);
			
			properties.push({ name: "data.textSectionStyle", type: "object", json: true });
			properties.push({ name: "data.hideLabel", type: "bool" });
			properties.push({ name: "data.labelStyle", type: "object", json: true });

			properties.push({ name: "data.hideType", type: "bool" });
			properties.push({ name: "data.typeLabelStyle", type: "object", json: true });
			properties.push({ name: "data.typeLabelTemplate", type: "string", description: 'default is "{type}"', examples: ['Click to select {type}'] });

			properties.push({ name: "data.imageStyle", type: "object", json: true });
			properties.push({ name: "data.imageProperty", type: "string", description: "Tells selector which property specifies the image's relative path. Uses 'image' if not specified", examples: ['hero'] });
			properties.push({ name: "data.imageFit", type: "string", description: "Image fitment within container. The default is 'contain'", examples: ['contain (preserves aspect ratio and makes image fit)', 'cover (preserves aspect ratio but clips edges if needed)', 'fill (tweaks aspect ratio)'] });
			properties.push({ name: "data.largeImageForm", type: "bool", description: "If true, the image takes main portion of selector instead of being smaller on the left." });
			properties.push({ name: "data.largeImageHeight", type: "string", description: "If unset, selector could change sizes as different image heights are loaded" });
			properties.push({ name: "data.largeImagePadding", type: "string", description: "Padding between the image and the selection box border", examples: ['15px'] });
			break;

		case "dcard":
			properties.push(PROP_TITLE);
			properties.push(PROP_SUBTITLE);
			properties.push(SEARCH_TAGS);
			properties.push(PROP_USE_SELECTIONS);
			properties.push(PROP_USE_CONFIGS);
			properties.push(PROP_USE_MODEL_CONFIGS);
			properties.push(PROP_USE_MODEL_RESULTS);
			properties.push(PROP_USE_MODELS);
			// properties.push(PROP_USE_SELECTION);
			// properties.push(PROP_SELECTION_LEVEL);
			// properties.push(PROP_SELECTION_LABEL_OVERRIDE);
			// properties.push(PROP_SELECTION_ALLOW_MULTI);
			// properties.push(PROP_USE_COMPS);
			properties.push(PROP_USE_FILTER);
			properties.push(PROP_USE_FILTER_DATASET_ID);
			properties.push(PROP_USE_COMPARE);
			properties.push(PROP_USE_GLOBAL_STORE_ITEMS);
			properties.push(PROP_USE_META_ITEMS);
			properties.push(PROP_HEIGHT);
			properties.push(PROP_RESIZABLE);
			properties.push(PROP_REMOVEBORDERS);
			properties.push(PROP_BORDERRADIUS);
			properties.push(PROP_SHADOW);
			properties.push(PROP_SHOWLOADINGICON);
			properties.push(PROP_GRIDCOLUMN);
			properties.push(PROP_GRIDROW);
			properties.push({
				name: "data.useRadium",
				type: "bool",
			});
			properties.push({
				name: "data.useStoryDrafts",
				type: "bool",
			});
			properties.push({
				name: "data.bodyClass",
				type: "string",
				examples: ["hscroll", "vscroll", "hscroll vscroll"],
			});
			properties.push({
				name: "data.compute",
				group: "Compute",
				type: "javascript",
				codePrec: "const compute = async (context, lib, prevCache) => {",
				codePost: "}",
				editorHeight: 700,
			});
			properties.push({
				name: "data.visualize",
				group: "Render",
				type: "bool",
			});
			properties.push({
				name: "data.visual",
				group: "Render",
				type: "select",
				deprecated: true,
				examples: [
					"bar",
					"column",
					"pie",
					"line",
					"table",
					"scatter",
					"custom",
					"wordcloud",
					"bar highcharts",
					"pie highcharts",
					"asTable"
				],
			});
			// properties.push({
			// 	name: "data.renderConfig",
			// 	group: "Render",
			// 	type: "javascript",
			// 	codePrec: "const getRenderConfig = (context, lib, cache) => {",
			// 	codePost: "}",
			// 	examples: EXAMPLES_RENDER_CONFIG,
			// 	editorHeight: 100,
			// 	deprecated: true,
			// });
			properties.push({
				name: "data.render",
				group: "Render",
				type: "jsx",
				codePrec: "const render = (React, ReCharts, cache, context, lib) => {",
				codePost: "}",
				editorHeight: 700,
			});
			properties.push({
				name: "data.asTable",
				group: "As Table",
				type: "jsx",
				codePrec: "const asTable = (cache, context, lib) => { // note: should return { columns, data }",
				codePost: "}",
				editorHeight: 700,
			});
			properties.push({
				name: "description", 
				group: "Info",
				type: "markdown",				
				editorHeight: 700,
			});
			properties.push({
				name: "data.disableDOMExport",
				group: "General",
				type: "bool",
			});
			properties.push({
				name: "data.hideAddMenu",
				group: "General",
				type: "bool",
			});
			properties.push(PROP_HIDEMENU);
			properties.push({
				name: "data.hideHeader",
				type: "bool",
			});
			
			break;
		
		case "slideshow":
			properties.push(PROP_WIDE)
			properties.push(PROP_STYLE)

			properties.push({
				name: "data.position",
				type: "select",
				examples: ['top', 'bottom']
			
			})

			properties.push({
				name: "data.buttonIcons",
				type: "array",
				json: true,
				examples: [["fal fa-cog", "fab gitkraken"]]
			})

			properties.push({
				name: "data.buttonLabels",
				type: "array",
				json: true,
				examples: [["Convert Group Perceptions", "Optimal Pricing Strategy"]]
			})

			properties.push({
				name: "data.buttonStyle",
				type: "select",
				examples: ['blue-buttons', 'grey-buttons', 'tabs', 'btns-no-icon']
			
			})

			// properties.push({
			// 	name: "data.fontColor",
			// 	type: "string",
			// 	examples: ["#fff"],
			// })

			properties.push({
				name: "data.backgroundColor",
				type: "string",
				examples: ["#dab200"],
			});
			break;
		
		case "ccarousel":
			properties.push(PROP_HEIGHT);
			properties.push({
				name: "data.useArrowIcons",
				type: "bool",
			});

			break;
	}


	
			

	const propertyGroups = properties.reduce((acc, p) => {
		const groupLabel = p.group || "General";
		let group = acc.find((entry) => entry.label === groupLabel);
		if (!group) {
			group = { label: groupLabel, properties: [] };
			acc.push(group);
		}
		group.properties.push(p);
		return acc;
	}, []);

	const [selTabIndex, setSelTabIndex] = useState(0);

	const selPropertyGroup = propertyGroups[selTabIndex];

	const me = ( //<div className={'element-property-editor ' + (props.floating ? 'floating' : '')}>
		<div className="element-property-editor-border">
			<div className="main">
				<TabStrip2
					style={{ margin: "10px 20px" }}
					selectedIndex={selTabIndex}
					selectedIndexChanged={(selectedTabIndex, selectedTabValue) =>
						setSelTabIndex(selectedTabIndex)
					}>
					{propertyGroups.map((group) => (
						<Tab value={group.label} key={group.label}>
							{group.label}
						</Tab>
					))}
				</TabStrip2>
				<div className="tabpage">
					<div className={"element-property-editor modal"}>
						{/* <div className="col-header" />
						<div className="col-header">PropertyName</div>
						<div className="col-header">PropertyValue</div> */}
						{selPropertyGroup?.properties.map((p) => {
							const updateContentItem = debounce.debounce(
								(auth, study_uid, id, body, callback) => {
									const response = apiService.aPatch(	auth, `${globals.apiRoot}/study/${study_uid}/content/${id}`,body)
										.then(() => {
											console.log("updated");
											if (callback) {
												callback();
											}
										})
										.catch((err) => {
											console.error(response);
										});
								},
								1000,
							);

							return (
								<ElementPropertyEditor
									key={p.name}
									item={item}
									prop={p}
									xreloadItem={props.reloadItem}
									updateContentItem={updateContentItem}
								/>
							);
						})}
					</div>
				</div>
			</div>
			<div className="footer">
				<div className="btn border rounded inline-block" onClick={requestClose}>
					Close
				</div>
			</div>
		</div>
	);

	const modalRoot = document.getElementById("modal-root");

	return ReactDOM.createPortal(
		<div className="element-property-editor-modal-container">{me}</div>,
		modalRoot,
	);
};
