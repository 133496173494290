import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from "react-redux";
import { DataCard } from '../card/DataCard';
import { DynamicWidget, RadiumDynamicWidget } from '../widgets/DynamicWidget';
import { getRepo } from '../widgets/_widgetRepo';
import './ContentViewer.scss';
import { EditModeWrapper } from './EditModeWrapper';


export const DynamicCardElement = (props) => {

    const study_uid = useSelector(state => state.study?.uid);
    const auth = useSelector(state => state.auth)

    useEffect(() => {
        if (props.item) {
            //console.log('dynamic card layout ready', props.item?.id);
            props.setLayoutReady?.(true);
        }
    }, []);

    const item = props.item;
    if( !item ) return null;

    const autoHeight = item.data?.height == "auto";
    let style = {
        ...item.data?.style,
        //width: item.data?.width,
        gridColumn: item.data?.gridColumn,
        gridRow: item.data?.gridRow,
    }
    if (!autoHeight) {
        style.height = item.data?.height || 400;
    }

    const classes = ['dynamic-card-element']

    if( !item.data?.wide ){
        classes.push('page-width');
    }
    
    const pageState = {};

    const useRadium = item?.data?.useRadium ? true : false;

    const widget_uid = useRadium ? 'RadiumDynamicWidget' : 'DynamicWidget';

    const showLoadingIcon = item?.data?.dontShowLoadingIcon ? false : true;
    // const hideHeader = item?.data?.hideHeader ? true : false

    const repo = getRepo(study_uid);

    let dtitle = item.title;
    if( dtitle ){
        dtitle = (cache) => item.title.replace(/\{([0-9a-zA-Z]+)\}/g, (str) => cache?.[str.slice(1,-1)] || "")
    }

    let dsubTitle = item?.data?.subTitle || item.subTitle;
    if( dsubTitle ){
        dsubTitle = (cache) => (item?.data?.subTitle || item.subTitle)?.replace(/\{([0-9a-zA-Z]+)\}/g, (str) => cache?.[str.slice(1,-1)] || "")
    }

    const entry = {
        uid: props.id,// item.uid,
        props: {
            auth,
            title: dtitle, //item.title,
            subTitle: dsubTitle,
            item: { // this stuff is needed by card save feature
                title: item?.title,
                data: item?.data
            },
            data: item.data,
            description: item?.description,
            resizable: item?.data?.resizable
        },
        widget: useRadium ? RadiumDynamicWidget : DynamicWidget
    }

    //const entry = repo.find(entry => entry.uid === widget_uid)
    if( !entry ) return 'unknown widget ' + widget_uid;

    const editMode = props.edit?.editMode;

    // note: i'm cancelling debugwrap as functional component for now because it was causing excessive re-rendering
    const DebugWrap = <div className={classes.join(' ')} style={style}>
        <DataCard
            className={'in-div' + (autoHeight ? ' auto-height' : '')}
            //className={`in-div ${autoHeight ? ' auto-height' : ''} ${props?.classes ? props.classes.join(' ') : ''}`}
            key={entry.uid}
            studyId={study_uid}
            widgetId={entry.uid}
            repoEntry={entry}
            {...entry.props}
            {...pageState}
            isolated={true}
            optionsContext={this}
            location={props.location}
            hideControls={!true}
            instanceContext={props.instanceContext}
            instanceIndex={props.instanceIndex}
            editMode={editMode}
            carouselInfo={props.carouselInfo}
            showLoadingIcon={showLoadingIcon}
            // hideHeader = {hideHeader}
        />
    </div>

    return <EditModeWrapper item={item}
        edit={props.edit} reloadItem={props.reloadItem}
        reloadParent={props.reloadParentItem} reloadParentItem={props.reloadParentItem} restoredItem={props?.restoredItem}
        pageLevel={props.pageLevel}
        style={{
            gridColumn: item.data?.gridColumn,
            gridRow: item.data?.gridRow
        }}
    >
        <ErrorBoundary reloadItem={props.reloadItem || props.reloadParentItem}>
            {DebugWrap}
        </ErrorBoundary>
    </EditModeWrapper>;

}


class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
        //logErrorToMyService(error, errorInfo);
        this.setState({ error, errorInfo });
	}

	render() {
		if (this.state.hasError) {
            // You can render any custom fallback UI
            console.log('error', this.state.error);
            console.log('errorInfo', this.state.errorInfo);
            return <div style={{padding: 10}}>
                Render failed, probably due to changing useGlobalStoreItems. Check console log.<br/><br/>
                <span className='btn inline-block border' onClick={() => this.props.reloadItem() } role='button' aria-label='reload card'>
                    Reload card
                 </span>
            </div>;
		}

		return this.props.children;
	}
}