import * as React from 'react';
import './CardMenuButton.scss';

export class CardMenuButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };

        this.closeTimer = null;
    }


    softCloseMenu = () => {
        //console.log('softCloseMenu');
        if( this.closeTimer ){
            clearTimeout(this.closeTimer);
        }
        this.closeTimer = setTimeout(this.closeMenu, 1);
    }

    cancelSoftClose = () => {
        //console.log('cancelSoftClose');
        if( this.closeTimer ){
            clearTimeout(this.closeTimer);
        }
    }

    closeMenu = () => {
        //console.log('closeMenu');
        this.closeTimer = null;
        this.setState({ isOpen: false });
    }

    toggleOpen = () => {

        const newState = !this.state.isOpen;

        this.setState({ isOpen: newState });
        if( newState ){
            // set the focus
            this.menu.focus();
        }

    }

    itemClicked = (item, checked) => {
        if( this.props.multiSelect ){
            if( this.props.itemChecked ){
                this.props.itemChecked(item, checked);
            }
        }
        else{
            if( item.onClick ){
                item.onClick();
            }
            if( this.props.selectionChanged ){
                this.props.selectionChanged(item);
            }
            this.closeMenu();
        }
    }

  render() {
    const caption = this.props.caption;// || 'button';
    const items = this.props.items || [];
    //const width = this.props.width || '100pt';
    const multiSelect = this.props.multiSelect;
    const menuWidth = this.props.menuWidth || '100pt';// width;
    const hideGlyph = this.props.hideGlyph;

    let btnStyle = {};
    if( this.props.width ){
        btnStyle.width = this.props.width;
    }

    //return <span className='card-button-menu'>hi</span>;

    return (
        
      <span className={"card-menu-button " + (hideGlyph ? ' hide-glyph' : '')} style={this.props.style} 
        title={this.props.title}
        onBlur={() => this.softCloseMenu()}
        onFocus={() => this.cancelSoftClose()}
      >
          <span className='button' onClick={this.toggleOpen} tabIndex={0}>
            {this.props.caption}
          </span>
          <div className={"menu" + (this.state.isOpen ? ' open' : ' closed')} tabIndex="1" 
            ref={(el) => {this.menu = el}}
            >  
            
                {items.map((item, idx) => {
                    let enabled = !item.disabled;

                    return item.type === 'section' ?
                        <div key={idx} className='section'>
                            {item.label}
                        </div>
                        : item.type === 'separator' ?
                            <div key={idx} className="separator" />
                        : <span key={idx} className={"item" + (enabled ? "" : " disabled")} onClick={() => enabled && this.itemClicked(item, multiSelect ? !item.checked : null)}>
                            {item.icon ? <span className='checkbox'>{item.icon}</span> : null}
                            {item.label}
                        </span>
                })}
                
                
            </div>
        {/* <div className="button" onClick={this.toggleOpen} style={btnStyle} tabIndex="0" >
            {this.props.icon ? <span className='icon'>{this.props.icon}</span> : null}
            {caption}
            {hideGlyph ? null
                : this.state.isOpen ? <i className='fal fa-chevron-up drop-glyph' />
                : <i className='fal fa-chevron-down drop-glyph' />
            }
        </div>
        <div className={"menu" + (this.state.isOpen ? ' open' : ' closed')} tabIndex="1" 
            style={{ minWidth: menuWidth }} 
            
            ref={(el) => {this.menu = el}}
            
            >
          
        </div> */}
      </span>
    );
  }
}
