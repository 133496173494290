import { format as d3Format } from "d3-format";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as globals from "../../globals";
import * as apiService from "../../utils/apiService";
import * as selectionHelper from "../../utils/selectionHelper";
import { CardLoading } from "../card/CardLoading";
import { CompChooser } from "../filter/CompChooser";
import { FilterChooser } from "../filter/FilterChooser";
import { ProductBlockMini } from "../product-drawer/ProductBlockMini";
import "./CoreWidget1.scss";
import { CompareChooser } from "../filter/CompareChooser";
import ReactJson from "react-json-view";

// const exampleProps = {
//     useFilter: true, // if filter should be included in api request and shown on card title
//     useScenario: true, // if scenario should be included in api request
//     useSelection: false // if selection should be included in api request, and cause a re-compute when changed
//     showSelection: true // if selection should be shown on card title. will also show if useSelection is true
//     useForecast, useAux="path", or useBigData={{ path="", body={} }}
// }

export const CoreWidget1 = (props) => {
	const debug = props.debug;

	// selectors
	const etag = useSelector((state) => state.study?.config?.etag); // when etag changes, indicates that a re-forecast is needed
	const auth = useSelector((state) => state.auth);
	const study = useSelector((state) => state.study); // sucks to need entire study, not just studyid and productdefs; change study to not change every time config changes; otherwise re-render is too frequent for some cards
	const selections = useSelector((state) => state.selections);
	const stateFilter = useSelector((state) => state.filter); // rename filter? to filters? filterselections?
	const stateCompare = useSelector((state) => state.compare);
	let _selectionLevel = useSelector(
		(state) => state.explore?.selectionLevel || state.selections?.exploreLevel,
	);
	if (props.useForecast) {
		_selectionLevel = "product"; // temporary, because forecaster doesn't support additional levels
	}

	//console.log('corewidget selectionLevel', _selectionLevel);
	//alert('wait');

	const comps = selections?.competitors;

	const format = props.fformat || d3Format(props.format || ".1%");

	// state
	const [cache, setCache] = useState(props.cache);
	const [loading, setLoading] = useState(false);

	// vars
	const filter = props.inDataCard ? stateFilter?.selectedFilter : props.filter;
	const compare = props.inDataCard ? stateCompare?.selection : props.compare;
	const _selectionId = selections?.[_selectionLevel]?.[_selectionLevel];
	const selectionLevel = props.inDataCard
		? _selectionLevel
		: props.selectionLevel;
	const selectionId = props.inDataCard ? _selectionId : props.selectionId;

	const selectedItem = selectionHelper.getSelection(props, study, selections);
	//console.log('from core widget; selections', selections);
	//console.log('from core widget; selectionId', selectionId);
	//console.log('from core widget; selectedItem', selectedItem);

	let reComputeKeys = [];
	if (props.useFilter) {
		reComputeKeys.push(filter.syntax);
	}
	if (props.useSelection) {
		reComputeKeys.push(selectionLevel);
		reComputeKeys.push(selectionId);
	}

	if (props.useForecast) {
		reComputeKeys.push(etag);
	} else if (props.useAnalytics) {
		if (
			props.useAnalytics.path &&
			typeof props.useAnalytics.path === "function"
		) {
			reComputeKeys.push(props.useAnalytics.path(props.transformContext));
		} else {
			reComputeKeys.push(props.useAnalytics.path);
		}
	} else if (props.useBigData) {
		reComputeKeys.push(props.useBigData.path);
	} else if (props.useAux) {
		reComputeKeys.push(etag);
		reComputeKeys.push(props.useAux.key || "");
	} else if (props.useYak) {
		reComputeKeys.push(props.useYak.query);
	}

	// allow datacard to save this widget
	props.stateContext.getSaveState = () => {
		let ss = {
			cache,
		};
		if (props.useSelection || props.showSelection) {
			ss.selectionLevel = selectionLevel;
			ss.selectionId = selectionId;
		}
		if (props.useFilter) {
			ss.filter = filter;
		}
		if (props.useCompare) {
			ss.compare = compare;
		}
		if (props.useComps) {
			ss.comps = comps;
		}
		if (props.useScenario) {
			//ss.config = 'todo'; // todo:
		}
		if (props.getSaveState) {
			ss = { ...ss, ...props.getSaveState() };
		}
		return ss;
	};

	    useEffect(() => {
            props?.setLayoutReady?.(true);
    }, []);

	useEffect((_) => {
		if (props.loaded && typeof props.loaded === "function")
			props.loaded(content);
	}, content);

	// compute
	useEffect(() => {
		if (props.inInsightCard) return;
		if (!study?.config?.productSlots) return;

		let _body = {};
		if (props.useSelection) {
			if (!selectionId) return;
			_body.aggregation_level = selectionLevel;
			_body.target = selectionId;
		}
		if (props.useFilter) {
			_body.filter = filter.syntax;
			_body.uFilter = filter.syntax;
		}
		if (props.useScenario) {
			_body.studyId = study.uid;
			_body.config = {
				productSlots: study.config.productSlots.map((slot) => ({
					uid: slot.uid,
					def: slot.def,
					base: slot.base,
					play: slot.play,
				})),
				brandEquities: study.config.brandEquities,
			};
		}

		if (props.useAux) {
			const { path, body } = props.useAux;
			const requestBody = { ..._body, ...body };

			if (debug) {
				console.log("aux body", requestBody);
			}

			setLoading(true);
			apiService
				.aPost(
					auth,
					`${globals.apiRoot}/study/${
						study.uid
					}/compute/aux?path=${encodeURIComponent(path)}`,
					requestBody,
				)
				.then((res) => res.json())
				.then((obj) => {
					setCache(obj);
					setLoading(false);
				})
				.catch((err) => {
					console.error(err);
					setLoading(false);
				});
		} else if (props.useForecast) {
			// use (or request) the cache'd forecast

			const { body } = props.useForecast;
			const requestBody = {
				..._body,
				...body,
				studyId: study.uid,
			};
			//console.log('forecast body', requestBody);

			// trigger forecast
			setLoading(true);
			apiService
				.aPost(auth, `${globals.apiRoot}/compute/summary`, requestBody)
				.then((res) => res.json())
				.then((obj) => {
					setCache(obj);
					setLoading(false);
				})
				.catch((err) => {
					console.error(err);
					setLoading(false);
				});
		} else if (props.useBigData) {
			const { path, body, eTag } = props.useBigData;
			const requestBody = { ..._body, ...body };

			if (!path) return;

			if (debug) {
				console.log("bigData body", requestBody);
			}

			setLoading(true);
			const url = `${globals.apiRoot}/study/${
				study.uid
			}/bigdata?path=${encodeURIComponent(path)}`;
			apiService
				.aPost(auth, url, requestBody)
				.then((res) => res.json())
				.then((obj) => {
					setCache(eTag ? { ...obj, eTag } : obj);
					setLoading(false);
				})
				.catch((err) => {
					console.error(err);
					setLoading(false);
				});
		} else if (props.useAnalytics) {
			const { body, method } = props.useAnalytics;
			const requestBody = { ..._body, ...body };

			let { path } = props.useAnalytics;
			if (path && typeof path === "function") {
				path = path(props.transformContext);
			}

			if (!path) return;

			if (debug) {
				console.log("analytics body", requestBody);
			}

			let path2 = path?.replace("{selectionId}", selectionId);

			setLoading(true);
			const url = `${globals.apiRoot}/study/${
				study.uid
			}/analytics/misc?path=${encodeURIComponent(path2)}`;
			const f = method == "get" ? apiService.aGet : apiService.aPost;
			f(auth, url, requestBody)
				.then((res) => res.json())
				.then((obj) => {
					setCache(obj);
					setLoading(false);
				})
				.catch((err) => {
					console.error(err);
					setLoading(false);
				});
		} else if (props.useYak) {
			const query = props.useYak.query;
			if (!query) return;
			if (debug) {
				//console.log('yak query', query);
			}
			setLoading(true);
			// TODO check for same query, then don't call the api.
			const url = `${globals.apiRoot}/study/${study.uid}/compute/yak`;
			apiService
				.aPost(auth, url, query)
				.then((res) => res.json())
				.then((obj) => {
					setCache(obj);
					setLoading(false);
				})
				.catch((err) => {
					console.error(err);
					setLoading(false);
				});
		}
	}, reComputeKeys);

	let data = cache;
	if (props.useForecast) {
		let fCache = cache?.[filter?.syntax];
		data = fCache;
	}

	if (props.transform) {
		data = props.transform(data, props.transformContext);
	}

	const expired = false; // selectedItemId && this.props.inDataCard && this.props.etag !== (cache || {}).etag;
	let extraContentClass = "";
	let content = null;
	if (props.render) {
		try {
			content = props.render(data, props.getJSONData);
		} catch (err) {
			content = ".error in widget render: " + err;
		}
	} else {
		//content = 'render missing: ' + (data ? JSON.stringify(data) : null);
		content = (
			<ReactJson
				src={data}
				theme={"rjv-default"}
				iconStyle="circle"
				displayDataTypes={false}
				collapsed={1}
				enableClipboard={true}
			/>
		);
		extraContentClass += " vscroll hscroll";
	}

	// useEffect(_ => {
	//     if (props.getJSONData && typeof props.getJSONData === 'function')
	//         props.getJSONData(data)
	// }, [data])

	let st = props.subTitle;
	if (props.subTitle && typeof props.subTitle === "function") {
		st = props.subTitle(props.transformContext);
	}

	let t2 = props.title2;
	if (props.title2 && typeof props.title2 === "function") {
		t2 = props.title2(props.transformContext);
	}

	return (
		<div className={"widget core-widget1 " + (props.class1 || "")} id='export-root'>
			<div className="widget-header">
				<div className="title">{props.title}</div>
				{!props.useComps && (props.showSelection || props.useSelection) ? (
					<div className="selection">
						<ProductBlockMini
							{...selectedItem}
							disabled={props.inInsightCard}
							allowChange={[selectionLevel]}
						/>
					</div>
				) : null}
				{props.fakeSelectionLabel ? (
					<div className="selection">
						<ProductBlockMini
							label={props.fakeSelectionLabel}
							disabled={true}
						/>
					</div>
				) : null}
				{props.useComps ? (
					props.inInsightCard ? null : (
						<div className="filter-etc">
							<CompChooser
								mini={true}
								disabled={props.inInsightCard}
								selection={comps}
							/>
						</div>
					)
				) : null}
				{props.useCompare ? (
					<div className="filter-etc">
						<CompareChooser
							mini={true}
							disabled={props.inInsightCard}
							selection={props.compare}
							xselection={{}}
						/>
					</div>
				) : null}
				{props.useFilter ? (
					<div className="filter-etc">
						<FilterChooser
							mini={true}
							disabled={props.inInsightCard}
							selection={filter}
						/>
					</div>
				) : props.showFilter ? (
					<div className="filter-etc">
						<FilterChooser
							mini={true}
							disabled={true}
							selection={globals.getDefaultFilter(study)}
						/>
					</div>
				) : null}
				{t2 ? <div className="title2">{t2}</div> : null}
				{st ? <div className="subtitle">{st}</div> : null}
				{props.options ? props.options() : null}
			</div>
			<div
				className={
					"widget-body " + extraContentClass + " " + (props.class2 || "")
				}>
				{content}
			</div>
			<CardLoading loading={loading} expired={expired} />
		</div>
	);
};
