import { format as d3Format } from "d3-format";
import * as React from "react";
import { connect } from "react-redux";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as analyticsActionCreators } from "../../store/Analytics.ts";
import { CardLoading } from "../card/CardLoading";
import { FilterChooser } from "../filter/FilterChooser";
import "./SegmentProfilesViewer.scss";

const format_slice = d3Format(".0%");
const format_slice_tooltip = d3Format(".1%");
const format_cell = d3Format(".4f");

export class SegmentProfilesViewer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			segmentation: this.props.segmentation, // in case of insight card
		};

		// Allow card to be saved as insight card. Not the greatest pattern.
		this.props.stateContext.getSaveState = this.getSaveState;
	}

	componentDidMount() {
		this.refreshIfNeeded();
	}

	componentDidUpdate() {
		this.refreshIfNeeded();
	}

	refreshIfNeeded = () => {
		// cancel if insight card
		if (this.props.inInsightCard) return;

		// cancel if no segments available
		const segmentations = this.props.segmentations;
		if (!segmentations || segmentations.length === 0) return;

		// cancel if no segment available;
		const seg = this.state.segmentation || segmentations[0].id;
		if (!seg) return;

		const fullCache = this.props.fullCache;
		if (!fullCache) return;

		const cache = fullCache?.[`${this.props.path}:${seg}`];
		if (cache && cache.loading) return;
		if (cache && cache.error) return;
		if (cache && cache.value) return;

		// refresh is needed. request it.
		this.props.loadAnalytics(this.props.path, `${this.props.path}:${seg}`, {
			segmentSolution: {
				solutionName: seg,
			},
			assumptions: {
				numProfilingVars: 15,
			},
		});
	};

	static inheritOptions = [];

	getSaveState = () => {
		const seg = this.state.segmentation;

		const fullCache = this.props.fullCache;
		const cache = fullCache?.[`${this.props.path}:${seg}`];

		return {
			segmentation: seg,
			cache,
		};
	};

	render() {
		const study = this.props.study;
		if (!study) return;

		const segmentations = this.props.segmentations || [];
		const seg = this.state.segmentation || segmentations?.[0]?.id;
		const segObj =
			seg && segmentations ? segmentations.find((s) => s.id === seg) : null;

		let cache = this.props.inInsightCard ? this.props.cache : null;
		if (this.props.inDataCard) {
			const fullCache = this.props.fullCache;
			cache = seg ? fullCache?.[`${this.props.path}:${seg}`] : null;
		}
		const data = cache?.value;
		if (cache && cache.loading) {
			if (this.props.loaded && typeof this.props.loaded === "function")
				this.props.loaded(!cache.loading);
		}

		let pieData = null;
		if (data && segObj) {
			pieData = [];
			segObj.filters.forEach((filter) => {
				if (data[filter.syntax]) {
					pieData.push({
						name: filter.label,
						value: data[filter.syntax].segment_size / 100.0,
					});
				}
			});
			//console.log(pieData);
		}

		// const data1 = [
		//     {name: 'Group A', value: 400},
		//     {name: 'Group B', value: 300},
		//     {name: 'Group C', value: 300},
		//     {name: 'Group D', value: 200}
		// ];
		const data1 = pieData;
		//const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
		const piecolors = [
			"#4771be",
			"#2b4675",
			"#5d99d0",
			"#2474b4",
			"#a3a3a3",
			"#636363",
		];

		const pieContent = pieData ? (
			<div className="pie-section">
				<ResponsiveContainer width="100%" height={275}>
					<PieChart>
						<Pie
							isAnimationActive={false}
							data={pieData}
							dataKey="value"
							//cx={250}
							//cy={200}
							innerRadius={60}
							outerRadius={80}
							label={(obj) =>
								`${obj.payload.name} ${format_slice(obj.payload.value)}`
							}>
							{pieData.map((entry, index) => (
								<Cell key={index} fill={piecolors[index % piecolors.length]} />
							))}
						</Pie>
						<Tooltip formatter={format_slice_tooltip} />
					</PieChart>
				</ResponsiveContainer>
				{this.props.expanded ? null : (
					<div style={{ textAlign: "center" }}>
						(expand card to view profiling variables)
					</div>
				)}
			</div>
		) : null;
		var jsonD =
			this.props.expanded && segObj
				? segObj.filters.map((filter) => {
						const d1 = data ? data[filter.syntax] : null;
						const pvars = d1 ? d1.profiling_variables : null;
						const pvarnames = pvars ? Object.keys(pvars) : [];
						let rowData = pvarnames.map((pvarname) => ({
							label: `[${pvarname}] ${study.profileVars[pvarname]}`,
							deviation: pvars[pvarname].deviation,
						}));
						rowData = rowData.sort((a, b) => b.deviation - a.deviation);
						return pvars
							? {
									columns: [
										{
											key: "label",
											label: `${filter.label} | ${format_slice(
												d1.segment_size / 100.0,
											)}`,
										},
										{ key: "deviation", label: "Differences" },
									],
									rows: rowData,
							  }
							: null;
				  })
				: null;
		if (this.props.getJSONData && typeof this.props.getJSONData === "function")
			this.props.getJSONData(jsonD, true);
		if (this.props.loaded && typeof this.props.loaded === "function")
			this.props.loaded(segObj);

		const tableContent =
			this.props.expanded && segObj
				? segObj.filters.map((filter, idx) => {
						const d1 = data ? data[filter.syntax] : null;
						const pvars = d1 ? d1.profiling_variables : null;
						const pvarnames = pvars ? Object.keys(pvars) : [];
						let rowData = pvarnames.map((pvarname) => ({
							name: pvarname,
							deviation: pvars[pvarname].deviation,
							label: study.profileVars[pvarname],
						}));
						rowData = rowData.sort((a, b) => b.deviation - a.deviation);
						const color1 = piecolors[idx % piecolors.length];
						const fcolor1 = "#ffffff";
						return pvars ? (
							<div className="table-container" key={filter.syntax}>
								<table key={`${idx}:${filter.syntax}`}>
									<tbody>
										<tr className="header-row">
											<td
												className="var-header"
												style={{ backgroundColor: color1, color: fcolor1 }}>
												{filter.label} | {format_slice(d1.segment_size / 100.0)}
											</td>
											<td
												className="diff-header"
												style={{ backgroundColor: color1, color: fcolor1 }}>
												Differences <i className="fas fa-sort-down" />
											</td>
										</tr>
										{rowData.map((row) => {
											return (
												<tr key={row.name} className="data-row">
													<td className="var-label">
														[{row.name}] {row.label}
													</td>
													<td className="diff-value">
														{format_cell(row.deviation)}
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						) : null;
				  })
				: null;

		const content = !cache ? null : cache.loading ? null : (
			<div>
				{pieContent}
				{tableContent}
			</div>
		);

		//const selVar = varEntries.find(e => e.key === this.props.var || this.state.var) || {};

		return (
			<div className="widget segment-profiles-viewer">
				<div className="widget-header">
					<div className="title">
						{this.props.title}: {(segObj || {}).label}
					</div>
					<div className="filter-etc">
						<FilterChooser
							mini={true}
							disabled={true}
							selection={globals.getDefaultFilter(this.props.study)}
						/>
					</div>
					{this.props.inDataCard && (
						<select
							value={this.state.segmentation || ""}
							onChange={(ev) => {
								this.setState({ segmentation: ev.target.value }, (_) =>
									this.refreshIfNeeded(),
								);
							}}>
							{segmentations.map((fg) => (
								<option key={fg.id} value={fg.id}>
									{fg.label}
								</option>
							))}
						</select>
					)}
					{this.props.inInsightCard && (
						<label className="ml-1">{segObj.label}</label>
					)}
				</div>
				<div className="widget-body vscroll">{content}</div>
				<CardLoading loading={cache && cache.loading} />
			</div>
		);
	}
}

SegmentProfilesViewer = connect(
	(state, ownProps) => {
		const segmentations =
			state?.filter?.filterGroups?.filter((fg) => fg.category === "Segments") ||
			[];

		return {
			study: state.study,
			segmentations,
			...(ownProps.inDataCard
				? {
						//cache: state.cache.analytics[ownProps.path]
						fullCache: state.cache.analytics,
				  }
				: {}),
		};
	},
	(dispatch) => bindActionCreators(analyticsActionCreators, dispatch),
)(SegmentProfilesViewer);
