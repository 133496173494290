import * as React from 'react'
//import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { actionCreators as dcmActionCreators } from '../../store/DCM'
import { actionCreators as studyActionCreators } from '../../store/Study.ts'
import { ProductBlock } from './ProductBlock'
import './ProductDrawer.scss'
import * as globals from '../../globals'


//const filterAttNames = ['brand', 'model']

const NOT_SET = '[not-set]'

export class ProductDrawer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            sel: this.props.selection,
            sels: this.props.selections,
            //productsOnly: false
            allow: null,
            searchText: '',
            multiSelect: this.props.study.multiSelect
        }

        window.productDrawer = this
        if (props.state) {
            this.setState({...props.state})
        }
        
    }    

    commaJoin = (elements) => {
        if( !elements ) return null
        if( elements.length === 1 )
            return elements[0]
        const subElements = elements.slice(0, elements.length - 1)
        return subElements.join(', ') + ' or ' + elements[elements.length - 1]
    }


    handleClick = (slot) => {
        //console.log('handleClick')
        if( this.props.onSelect ){
            this.props.onSelect(slot)
        }
    }

    handlePrimaryClick = (slot) => {
        //console.log('handlePrimaryClick')
        if(this.props.onPrimarySelect){
            this.props.onPrimarySelect(slot)
        }
    }

    isMatch = (slot, searchItems) => {
        let str = slot.label.toLocaleLowerCase()
        for( let i in searchItems ){
            if( str.indexOf(searchItems[i]) === -1 )
                return false
        }
        return true
    }

    cloneSelection = () => {
        //console.log('sel', this.state.sel )
        this.props.cloneProduct(this.state.sel?.uid)
    }

    render() {
        if (!this.props.dcm.configs) return null
        if (!this.props.study.brands) return null

        let products = this.props.dcm.configs[this.props.dcm.activeConfig]
        const activefilters = this.props.selection['suvTruck'].prods//remove hard-coded garbage
       
        const allTypes = this.props.study.selectionHierarchy || []
        const allTypes2 = [...allTypes, { name: 'product', label: this.props.study.productLabel }]

        const allowedTypes = allTypes2
            .filter(t => !this.state.allow || this.state.allow.includes(t.name))
        
        const canClone = false//allowedTypes.length === 1 && allowedTypes[0]?.name === 'product'

        let filteredProds = products.play
        if (activefilters) {
            filteredProds = products.play.filter(pp => activefilters.includes(pp['model']))//remove hard-coded garbage
        }

        // create items to display. populate one entry per slot
        let items = filteredProds.map(prod => {
            let item = {
                label: prod.label,
                uid: prod.uid,
                image: prod.image,
                type: 'product',
                typeLabel: this.props.study.productLabel || 'Product',
                typeIndex: allTypes.length,
                product: prod.uid,
                available: prod.available,
                dirty: products.dirty
            }
            allTypes.forEach(att => item[att.name] = prod[att.name])
            return item
        })
        
        // create filterAtt mapping (e.g., link model levels to brand levels)
        for (let i = 1; i < allTypes.length; i++ ){
            let prevAtt = allTypes[i-1]
            let att = allTypes[i]
            att.levels = att.levels.map(level => {
                let match = items.find(item => item[att.name] == level.value)
                if( match ){
                    return { 
                        ...level, 
                        [prevAtt.name]: match[prevAtt.name]
                    }
                }
                else
                    return level
            })
        }
        
        // add an item for each level of each att in selectionHierarchy (e.g., add models, brands)
        for (let i = 0; i < allTypes.length; i++ ){
            let att = allTypes[i]
            if( !this.state.allow || this.state.allow.includes(att.name) ){
                att.levels.forEach(level => {
                    let groupItem = {
                        label: level.label,
                        type: att.name,
                        typeLabel: att.label,
                        typeIndex: i,
                        [att.name]: level.value,
                        uid: `${att.name}:${level.value}`
                    }
                    if( level.image )
                        groupItem.image = level.image
                    if( level.photo )
                        groupItem.photo = level.photo
                    for (let j = 0; j < i; j++ ){
                        let prevAtt = allTypes[j]
                        groupItem[prevAtt.name] = level[prevAtt.name]
                    }
                    items.push(groupItem)
                })
            }
        }

        // sort by last att in selectionHierarchy, then prev att, ..., then first att, then typeIndex, then label
        const allTypes2b = [...allTypes, { name: 'label' }]
        //console.log('allTypes2b', allTypes2b)
        //console.log('items before sort', JSON.parse(JSON.stringify(items)))
        items = items.sort((a, b) => {
            
            return a.label.localeCompare(b.label)
        })
        //console.log('sorted items', JSON.parse(JSON.stringify(items)))

        // filter out products if not allowed
        if( this.state.allow && !this.state.allow.includes('product')){
            items = items.filter(item => item.type !== 'product')
        }

        // filter items according to state selections
        allTypes.forEach(att => {
            if( this.state[att.name] && this.state[att.name] !== NOT_SET ){
                items = items.filter(item => item[att.name] == this.state[att.name])
            }
        })

        // create filtered dropdowns according to state selections
        let dropdowns = []
        let filteredSlots = [...products.play]
        for (let i = 0; i < allTypes.length && filteredSlots.length > 0; i++ ){
            let att = allTypes[i]

            // only add levels that exist in filtered slots
            let allowedLevels = att.levels.filter(level =>
                    i == 0 // always allow all levels of first filterAtt (could probably eliminate this since filteredSlots should contain a match)
                    || filteredSlots.find(slot => slot[att.name] == level.value)
                )
            dropdowns.push({
                name: att.name,
                label: att.label,
                levels: allowedLevels
            })
            
            // if the dropdown for this att is set, and valid according to allowed levels, filter the filteredSlots
            if( this.state[att.name] && this.state[att.name] !== NOT_SET ){
                filteredSlots = filteredSlots.filter(slot => 
                    slot[att.name] == this.state[att.name] // slot matches state setting
                    //|| !allowedLevels.find(level => level.value == this.state[att.name]) // or state setting isn't one of allowed levels
                )
            }
        }
        
        if (this.state.searchText !== '') {
            const searchItems = this.state.searchText.toLowerCase().split(' ')
            items = items.filter(item => this.isMatch(item, searchItems))
        }

        return (
        <div className={"product-drawer" + (this.props.isOpen ? ' open' : ' closed')}>

            <div className='top'>

                <div className='h3'>
                    Select {this.commaJoin(allowedTypes.map(att => att.label)) || 'Item'}
                    <button style={{ float: 'right', cursor: 'pointer' }} role='button' aria-label='close' onClick={() => window.topBar.hideMenu()}>
                        <i className='fal fa-times'></i>
                    </button>
                </div>

                {dropdowns.map((dropdown, idx) =>
                    <div className='filter-item' key={dropdown.name}>
                        <label className=''>{dropdown.label}</label>
                        <select value={this.state[dropdown.name] || undefined} onChange={(ev) => {
                            
                            // set the state for this dropdown
                            let newState = { 
                                [dropdown.name]: ev.target.value
                            }

                            // clear state value for subsequent dropdowns
                            dropdowns.forEach((dd, idx2) => {
                                if( idx2 > idx ){
                                    newState[dd.name] = null
                                }
                            })

                            this.setState(newState)
                            
                        }}>
                        <option value={NOT_SET}>Filter {dropdown.label}</option>
                            {dropdown.levels.map(level => 
                                <option key={level.value} value={level.value}>{level.label}</option>
                            )}
                        </select>
                    </div>
                )}

                <div className='search'>
                    <div className='input-container'>
                        <input type="text" placeholder="search" value={this.state.searchText} onChange={(ev) => this.setState({ searchText: ev.target.value })}/>
                    </div>
                    <span className={'btn-cancel-search ' + (this.state.searchText !== '' ? ' enabled' : '')}

                        onClick={() => this.setState({ searchText: '' })}
                    >
                        <i className='fas fa-times-circle'/>
                    </span>
                </div>

                {canClone ? 
                <div>
                    <span className='clone-btn' onClick={() => this.cloneSelection()}>clone</span>
                </div> : null}

                {
                    this.state.multiSelect ? //&& this.state.sels && this.state.sels.length >= 1 ? 
                    (<div className='content-selected'>
                        <label>{!this.state.sels ? '?' : this.state.sels.length === 1 ? this.state.sels.length + ' item' : this.state.sels.length + ' items'} selected</label>
                        {this.state.sels && this.state.sels.length > 0 ?
                            <button onClick={() => this.state.sels.forEach(i => this.props.onSelect(i))} role='button' aria-label='clear'>Clear</button>
                            : null}
                    </div>) : ''
                }

            </div>

            

            <div className="scrolling-content">
                {items.map(prod => {
                    const selected = this.state.multiSelect ? ((this.state.sel?.uid === prod.uid) || (this.state.sels && this.state.sels.find(s => s.uid === prod.uid)))
                        : (this.state.sel && prod.uid === this.state.sel.uid)
                    const isPrimary = this.state.multiSelect && this.state.sel?.uid === prod.uid
                    const showPrimary = this.state.multiSelect && this.state.includePrimary
                    return (
                        <ProductBlock key={prod.uid} {...prod} selected={selected} showPrimary={showPrimary} isPrimary={isPrimary} onClick={() => {
                            let x = prod
                            this.handleClick(x)
                        }} onPrimaryClick={() => {
                            let x = prod
                            this.handlePrimaryClick(x)
                        }} />
                    )
                })}
            </div>


            <div className='bottom text-center' style={{ display: this.state.multiSelect ? 'block': 'none'}}>
                <button  className="action-button" onClick={() => window.topBar.hideMenu()} role='button' aria-label='close'>close</button>
            </div>

        </div>
        )
    }
}

ProductDrawer = withRouter(
  connect(
      state => ({
            dcm: state.dcm,
            study: state.study,
            selections: state.selections,
            selection: state.selection,
        }),
    dispatch => bindActionCreators({ ...dcmActionCreators, ...studyActionCreators }, dispatch)
  )(ProductDrawer)
)
