import React, { Component, createRef } from 'react'
import ReactResizeDetector from 'react-resize-detector'
import * as d3 from 'd3'

export class BubbleChart extends Component {
    constructor(props) {
        super(props)

        this.node = createRef()
    }

    componentDidMount = _ => this.updateChart()
    componentDidUpdate = _ => this.updateChart()

    updateChart(width = 500, height = 420) {
        const { data } = this.props;
        const domNode = this.node.current;
        if (!domNode) return;

        // set the dimensions and margins of the graph
        var margin = { top: 20, right: 30, bottom: 60, left: 80 }
        width = this.node.current.parentNode.clientWidth - margin.left - margin.right
        height = this.node.current.parentNode.clientHeight - margin.top - margin.bottom

        // append the svg object to the body of the page
        var svg = d3.select(this.node.current)
                .attr('width', width + margin.left + margin.right - 20)
                .attr('height', height + margin.top + margin.bottom - 20)

        svg.selectAll('*').remove();
        var g = svg.append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`)
        
        // Add X axis
        var x = d3.scaleLinear()
            .domain([d3.min(data.map(i => i.nps)), d3.max(data.map(i => i.nps))])
            .range([ 0, width ])
            
        g.append('g')
            .attr('transform', `translate(0, ${height})`)
            .call(d3.axisBottom(x).ticks(3))

        // Add X axis label
        g.append('text')
            .attr('text-anchor', 'end')
            .attr('x', width/2)
            .attr('y', height+40 )
            .style('text-anchor', 'middle')
            .style('font-size', '14px')
            .text('NPS')

        // Add Y axis
        var y = d3.scaleLinear()
            .domain([d3.min(data.map(i => i.promoter)), d3.max(data.map(i => i.promoter))])
            .range([ height, 0])

        g.append('g')
            .call(d3.axisLeft(y))

        g.append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', -60)
            .attr('x', 0 - ((height / 2) - margin.bottom/2)+0)
            .attr('dy', '1em')
            .style('text-anchor', 'middle')
            .style('font-size', '14px')
            .text('Year / Year sales growth')

        // Add a scale for bubble size
        var z = d3.scaleLinear()
            .domain([8, 1000])
            .range([ 1, 40])

        // Add dots
        g.append('g')
            .selectAll('dot')
            .data(data)
            .enter()
            .append('circle')
            .attr('class', d => 'bubbles ' + d.label)
            .attr('cx', d => x(d.nps))
            .attr('cy', d => y(d.promoter))
            .attr('r', d => z(d.sales))
            .style('fill', '#3969d94d')
    }

    render() {
        return <ReactResizeDetector handleWidth handleHeight>
            {
                ({ width, height }) => {
                    this.updateChart(width, height)
                    return <svg key='singleton'
                        ref={this.node}
                        className='bubble'
                        preserveAspectRatio='xMidYMid meet'
                        style={{ border: '0' }}
                    />
                }
            }
        </ReactResizeDetector>
    }
}
