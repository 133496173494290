import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/Study.ts";
import "./CompareChooser.scss";

export class CompareChooser extends React.Component {
	render() {
		let selection = this.props.selection || {
			label: "error: compare not set",
		};

		return (
			<div
				className={
					"compare-chooser" +
					(this.props.mini ? " mini" : "") +
					(this.props.disabled ? " disabled" : "")
				}
				style={this.props.style}>
				<div className="label">Compare:</div>

				<div
					className={"filter-cell " + (this.props.disabled ? " disabled" : "")}
					onClick={() => {
						if (!this.props.disabled) {
							window.topBar.openMenu("compare");
						}
					}}>
					<i className="fal fa-filter glyph" />
					<span className="label">{selection.label}</span>
				</div>
			</div>
		);
	}
}

CompareChooser = withRouter(
	connect(
		(state, ownProps) =>
			ownProps.selection
				? {
						selection: ownProps.selection,
				  }
				: state.compare,
		(dispatch) => bindActionCreators(actionCreators, dispatch),
	)(CompareChooser),
);
