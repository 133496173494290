import { select } from "d3-selection";

export const getSelection = (props, study, selections) => {

    // console.log('getSelection: props', props);
    // console.log('getSelection: study', study);
    // console.log('getSelection: selections', selections);

    if( !study || !selections ) return null;

    const selStyle = props.inInsightCard && props.selection?.type;
    if( selStyle ){
        // backwards compatibility for { selection: { type: "model", uid: "model:27" } }
        const selectionLevel = props.inInsightCard ? props.selection.type : study?.explore?.selectionLevel;
        const selectionKey = props.selection.uid;
        const _selectionKey = selectionKey?.startsWith(selectionLevel + ':') ? selectionKey.substring(selectionLevel.length + 1) : selectionKey;
        const selection = _selectionKey ? getSelectionInfo(selectionLevel, _selectionKey, study, selections?.hierarchy) : null;
        return selection;
    }
    else{
        // regular form: { selectionLevel: "model", selectionId: 27 }
        //const selectionLevel = props.inInsightCard ? props.selectionLevel : selections?.selectionLevel;// study?.explore?.selectionLevel;
        const selectionLevel = props.inInsightCard ? props.selectionLevel : study?.explore?.selectionLevel || selections?.selectionLevel;
        //console.log('getSelection/selectionLevel', selectionLevel);
        const selectionId = props.inInsightCard ? props.selectionId : selections?.[selectionLevel]?.uid;
        const selectionKey = selectionId ? getKeyFromUid(selectionLevel, selectionId) : null;
        const selection = selectionKey ? getSelectionInfo(selectionLevel, selectionKey, study, selections?.hierarchy) : null;
        return selection;

    }

}

export const getSelectionOfLevel = (props, study, selections, selectionLevel) => {

    // console.log('getSelection: props', props);
    // console.log('getSelection: study', study);
    // console.log('getSelection: selections', selections);

    if( !study || !selections ) return null;

    
    const selStyle = props.inInsightCard && props.selection?.type;
    if( selStyle ){
        // backwards compatibility for { selection: { type: "model", uid: "model:27" } }
        //const selectionLevel = props.inInsightCard ? props.selection.type : study?.explore?.selectionLevel;
        const selectionKey = props.selection.uid;
        const _selectionKey = selectionKey?.startsWith(selectionLevel + ':') ? selectionKey.substring(selectionLevel.length + 1) : selectionKey;
        const selection = _selectionKey ? getSelectionInfo(selectionLevel, _selectionKey, study, selections?.hierarchy) : null;
        return selection;
    }
    else{
        // regular form: { selectionLevel: "model", selectionId: 27 }
        //const selectionLevel = props.inInsightCard ? props.selectionLevel : selections?.selectionLevel;// study?.explore?.selectionLevel;
        //console.log('getSelection/selectionLevel', selectionLevel);
        const selectionId = props.inInsightCard ? props.selectionId : selections?.[selectionLevel]?.uid;
        const selectionKey = selectionId ? getKeyFromUid(selectionLevel, selectionId) : null;
        const selection = selectionKey ? getSelectionInfo(selectionLevel, selectionKey, study, selections?.hierarchy) : null;
        return selection;

    }

}

// given a type and a key, returns the full selection info
// ex:
//   getSelectionInfo( 'product', 'bmw-x5-xdrive40i', ... )
//
// returns:
//      label
//      image
//      other values
// note: needs the following from study: product slots, product label, productDefs
export const getSelectionInfo = (type, key, study, hierarchyLevels) => {

    if( type === 'product' ){
        const slot = study?.config?.productSlots?.find(slot => slot.uid == key);
        if(!slot) return null;

        return {
            type,
            typeLabel: study.productLabel,
            uid: slot.uid,
            product: slot.uid,
            label: slot.label,
            image: slot.image,
            ...slot.play
        }
    }
    else{
        // find the attribute
        const att = study?.config?.productDefs?.default?.atts.find(a => a.name === type);
        if(!att) return null;

        const level = att.levels?.find(l => l.value == key);
        
        // find the first slot that matches the property
        const firstMatch = study.config.productSlots.find(slot => slot.play[type] == key);
        
        let retval = {
            type,
            typeLabel: att.label,
            uid: `${type}:${key}`,
            label: level?.label,
            image: level?.image,
        }
        hierarchyLevels.forEach(l => {
            retval[l] = l === 'product' ? firstMatch.uid : firstMatch?.play[l]
        });
        return retval;
    }

}

export const getKeyFromUid = (type, uid) => {
    if( type === 'product' ) return uid;
    if( !uid ) return;
    if( (uid + "").startsWith(type + ':')){
        return uid.substring(type.length + 1);
    }
    return uid + "";
}