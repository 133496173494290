import * as React from 'react';
import { format as d3Format } from 'd3-format';

const CutomizedTick = (props) => {
  const { width, height, x, y, stroke, payload, textAnchor, fill, wordBreakLimit } = props;
  // console.log(props)
  const formatter = props?.tickFormatter || d3Format("");
  const wordArray = (text, wordBreak = wordBreakLimit ? wordBreakLimit : 30) => {

    let breakVal = 0;
    let remainingWord = text;
    let wordArrayText = [];

    if (text.length > wordBreak) {
      do {
        let breakstring = remainingWord.substr(0, wordBreak);
        let fullWords = breakstring.substr(breakVal, Math.min(breakstring.length, breakstring.lastIndexOf(" ")))
        wordArrayText.push(fullWords)
        remainingWord = remainingWord.replace(fullWords, '');
        if (remainingWord.length < wordBreak) {
          wordArrayText.push(remainingWord)
        }
      } while (remainingWord.length > wordBreak)
    } else {
      wordArrayText.push(text);
    }
    return wordArrayText;
  }
  let yAxis = y+3;
  return <text width={width} height={height} x={x} y={yAxis} stroke={stroke} textAnchor={textAnchor} fill={fill} className='recharts-text recharts-cartesian-axis-tick-value'>
    {wordArray(payload?.value)?.map((text, idx) => <tspan key={idx} x={x} y={yAxis+(idx*12)}>{!isNaN(text) ? formatter(text) : text}</tspan>)}
  </text>
}

export default CutomizedTick;