import * as React from 'react';
import { useEffect } from 'react';
import { Markdown } from '../common/Markdown';
import './ContentViewer.scss';
import { EditModeWrapper } from './EditModeWrapper';


export const MarkdownElement = (props) => {

    const item = props.item;

    useEffect(() => {
        if (item) {
            //console.log('markdown layout ready', item?.id);
            props.setLayoutReady?.(true);
        }
    }, []);

    if( !item ) return null;

    const style = {
        ...item.data?.style,
        gridColumn: item.data?.gridColumn,
        gridRow: item.data?.gridRow
    }

    return <EditModeWrapper item={item} edit={props.edit} reloadItem={props.reloadItem} reloadParent={props.reloadParentItem}
        style={{
            gridColumn: item.data?.gridColumn,
            gridRow: item.data?.gridRow
        }}
        pageLevel={props.pageLevel}
    >
        {/* <div className='force-square'> */}
            <div className='markdown-element' style={style}>
                <Markdown data={item.data?.content || ''}/>
            </div>
        {/* </div> */}
    </EditModeWrapper>;
}
