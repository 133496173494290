import * as React from "react"
import { useSelector, useDispatch } from "react-redux"
import { actionCreators as modelActionCreators } from "../../store/Model"
import { actionCreators as GlobalStoreCreator } from "../../store/General"
import { ToggleSwitch } from "../common/ToggleSwitch"
import { ContinBox } from "../config/att-editor/ContinBox"
import "./AttributeBox.scss"
import * as metaUtils from '../../utils/metaUtils'

export function AttributeBox({ prod, att, configModel, allowOptimize, monitor_atts }) {
   
  //const cfgModel = useSelector(state => state.dcm.configs[configModel])//send in specific year
  const cfgModel = useSelector(state => state.model[configModel])//send in specific year
  const auth = useSelector(state => state.auth)
  const study = useSelector(state => state.study)
  const dispatch = useDispatch()

  if (!cfgModel || !prod || !att) return null
  
  //const { play, base } = cfgModel 
  const play = cfgModel.config?.play;
  const base = cfgModel.config?.base;

  let dirty = false
  let error = null

  const isAttLocked = false; // cfgModel.optim && cfgModel.optim.locks && cfgModel.optim.locks.includes(att.name)
  const disallow = [];  // (cfgModel.optim && cfgModel.optim.disallow ? cfgModel.optim.disallow[att.name] : null) || []
  const debug = false
  let content = null  
  

  // const tamForecast = async () => {
  //   await dispatch(GlobalStoreCreator.setGlobalStore('tam_adj', true))
  // }

  const setPlay = (config_model, prodId, attname, level_val) => {
    dispatch(modelActionCreators.setPlay(config_model, prodId, attname, level_val))

    // if (monitor_atts && monitor_atts.includes(attname)) {
    //     tamForecast()
    // }
    dispatch(GlobalStoreCreator.setGlobalStore('forecast_needed', true))
  }

  const setLevelAllow = (prodId, attname, level_val, allowed) => {
    dispatch(modelActionCreators.setLevelAllow(prodId, attname, level_val, !allowed))
    dispatch(GlobalStoreCreator.setGlobalStore('forecast_needed', true))
  }
    
  if (att.type === "discrete") {

    // console.log('playAttbox', play);
    // console.log('baseAttbox', base);
    let playValue = play.find(p => p.uid === prod.uid)[att.name] || att.defaultValue
    const baseValue = base.find(p => p.uid === prod.uid)[att.name] || att.defaultValue

    // console.log('playvalue', playValue);
    // console.log('baseValue', baseValue);
    dirty = playValue != baseValue

    const sel_level = att.levels.find((level) => playValue == level.value) || att.levels[0] // eslint-disable-line eqeqeq
    const base_level = att.levels.find((level) => baseValue == level.value) || att.levels[0] // eslint-disable-line eqeqeq

    content = att.levels.map((level) => {

    const isLevelAllowed = !disallow.includes(level.value)
      
  
    return !allowOptimize || isAttLocked ? (
      // show the regular way
      <div key={`${att.name}:${level.value}`} className={"att-level" + (level === sel_level ? " sel" : "")} onClick={() => setPlay(configModel,prod.uid,att.name, level.value) } title={level.label}>
        {debug ? <span>[{level.value}]</span> : null} {level.label}
        {level === base_level ? <span className="base-level">base</span> : null}
        {/* {level === base_level.tp ? <span className="tipping-point">tp</span> : null} */}
      </div>
          
      ) : (
        // show the constraints way
        <div key={`${att.name}:${level.value}`} className={"att-level with-toggle" + (isLevelAllowed ? "" : " off")} onClick={() => setLevelAllow(prod.uid, att.name, level.value, !isLevelAllowed)}>
          <ToggleSwitch className="mini" on={isLevelAllowed}>
            Allow: {level.label}
          </ToggleSwitch>
        </div>
      )
    })
  }
    else if (att.type === "multiselect") {

      let nbSelected = 0
      const yesValue = att.yesValue || 1
      const noValue = att.noValue || 0

      content = att.options.map((option) => {
        const isOptionAllowed = !disallow.includes(option.name)

        let playValue = play[option.name] == null ? att.defaultValue : play[option.name]
        const baseValue = base[option.name] == null ? att.defaultValue : base[option.name]
        if (playValue != baseValue) {
          dirty = true
        }

        const selected = playValue == yesValue

        if (selected) {
          nbSelected++
        }

        const isLevelAllowed = true // todo

        const itemDirty = playValue != baseValue

        return !allowOptimize || isAttLocked ? (
          // show the regular way
          <div className='level-container'>
            <div key={`${att.name}:${option.name}`} className={"att-level" + (selected ? " sel" : "")} onClick={() => setPlay(configModel,prod.uid, option.name, selected ? noValue : yesValue)}>
              {debug ? <span>[{option.name}]</span> : null} {option.label}
              {itemDirty ? (
                <span className="item-dirty">
                  <i className="fas fa-circle" />
                </span>
              ) : null}
              {/* {level === base_level ? <span className='base-level'>base</span> : null} */}
              </div>
            </div>
        ) : (
          // show the constraints way
          <div key={`${att.name}:${option.name}`} className={"att-level with-toggle" + (isLevelAllowed ? "" : " off")} onClick={() => setLevelAllow(prod.uid, option.name, level.value, !isLevelAllowed)}>
            <ToggleSwitch 
                className='mini' 
                on={isLevelAllowed}                
              >Allow: {option.label}
            </ToggleSwitch>
          </div>
        )
      })

      if (att.maxSelect !== null && nbSelected > att.maxSelect) {
        error = `Too many options are selected. Select no more than ${att.maxSelect}.`
      }
    }
    else if (att.type === "contin") {

      let playValue = play[att.name] != null ? play[att.name] : att.defaultValue
      const baseValue = base[att.name] != null ? base[att.name] : att.defaultValue
      dirty = playValue != baseValue

      content = (
        <div key={att.name} className="contin-area">
          {!allowOptimize || isAttLocked ? (
            // show the regular way
            <div>
              <ContinBox
                key={prod.uid + ":" + att.name}
                att={att}
                slot={prod}
                playValue={playValue}
                baseValue={baseValue}
                onChange={(newValue) => {setPlay(configModel,prod.uid, att.name, newValue) }}
              />
            </div>
          ) : (
            // show the constraints way
            <div className="contin-range">
              Min: <input type="text" className="rangebox" defaultValue={att.min} />
              &nbsp Max: <input type="text" className="rangebox" defaultValue={att.max} />
            </div>
          )}
        </div>
      )
    }
    
    else {
      content = "att type not supported"
  }
  
  const handleDirtyBitClick = () => {
    
    if (!att) return

    if (att.type === "multiselect") {
      att.options.forEach((option) => {
        const baseValue = base[option.name] == null ? att.defaultValue : base[option.name]
        setPlay(prod.uid, option.name, baseValue)
      })
    } else {
      const baseValue = base[att.name] || att.defaultValue
      setPlay(configModel,prod.uid, att.name, baseValue)
    }
  }


  const multiSelectMessage = att.type === "multiselect" ? (att.maxSelect ? `(Select up to ${att.maxSelect})` : "(Select multiple)") : null
  const lightBlue = monitor_atts.includes(att.name) ? { backgroundColor: 'rgba(66,133,250, 0.05)', position:'relative' } : null

    return (
      <div className={"attribute-box" + (error ? " has-error" : "")} style={lightBlue}>
        {
          monitor_atts.includes(att.name) ?
            <div style={{position:'absolute', right:10,top:10, fontSize:'5pt', color:'#808080'}}>
              <span>IMPACTS MODEL LEVEL</span>
            </div>
            :
            null
        }
        <div className={"title " + (isAttLocked ? "locked" : "unlocked")} 
          onClick={() => {
            if (allowOptimize) {
              dispatch(modelActionCreators.setAttLock(prod.uid, att.name, !isAttLocked, play[att.name] || att.defaultValue))
            }
          }}
        >
          <label title={error}>
            {debug ? <span>[{att.name}]</span> : null}

            {att.label}
            {multiSelectMessage ? <span className="multi-select-message">{multiSelectMessage}</span> : null}
            {dirty && !allowOptimize ? (
              <span className="dirty-bit" onClick={handleDirtyBitClick}>
                <i className="fas fa-circle" />
              </span>
            ) : null}
          </label>
          {error ? (
            <div>
              <span className="error-bit">
                <span className="glyph">
                  <i className="fas fa-exclamation-triangle" />
                </span>
                <span className="message">{error}</span>
              </span>
            </div>
          ) : null}
        
        </div>
        <div className="level-container">
          {content}
        </div>
      </div>
    )
  
}

