import { AllElseEqualsWidget2 } from './AllElseEqualsWidget2';
import { AllElseEqualsWidget2C } from './AllElseEqualsWidget2C';
import { BarChartComp } from './BarChartComp';
import { BlankWidget } from './BlankWidget';
import { BoxPlotWidget } from './BoxPlotWidget';
import { BoxPlotWidget2 } from './BoxPlotWidget2';
import { BrandAllElseEqualWidget } from './BrandAllElseEqualWidget';
import { CoOccurringWordsTableViewer } from './CoOccurringWordsTableViewer';
import { CorrelationsViewer } from './CorrelationsViewer';
import { CorrespondenceMap } from './CorrespondenceMap';
import { CovidWidget } from './CovidWidget';
import { CovidWidget2 } from './CovidWidget2';
import { CovidWidget3 } from './CovidWidget3';
import { CustomWidget1 } from './CustomWidget1';
import { DCMColumnsWidget } from './DCMColumnsWidget';
import { DCMConfigWidget } from './DCMConfigWidget';
import { DCMConfigWidget2 } from './DCMConfigWidget2';
import { DCMProductsTable } from './DCMProductsTable';
import { DCMProductsTableB } from './DCMProductsTableB';
import { DCMProductsTableC } from './DCMProductsTableC';
import { DCMProductsWidget } from './DCMProductsWidget';
import { DCMSunburstWidget } from './DCMSunburstWidget';
import { DCMSunburstWidgetNonZoom } from './DCMSunburstWidgetNonZoom';
import { DCMTreeMapWidget } from './DCMTreeMapWidget';
import { DCMTreeMapWidget2 } from './DCMTreeMapWidget2';
import { DemogWidget } from './DemogWidget';
import { DemogWidget2 } from './DemogWidget2';
import { DotPlotWidget } from './DotPlotWidget';
import { DynamicWidget } from './DynamicWidget';
import { FunnelWidget } from './FunnelWidget';
import { FunnelWidgetTrended } from './FunnelWidgetTrended';
import { ImageWidget } from './ImageWidget';
import { LineChartWidget2 } from './LineChartWidget2';
import { NPSDetail } from './NPSDetail';
import { NPSDetailBar } from './NPSDetailBar';
import { NPSSegments } from './NPSSegment';
import { NPSViewer } from './NPSViewer';
import { NPSViewerCW } from './NPSViewerCW';
import { NPSViewerW } from './NPSViewerW';
import { PieChartWidget } from './PieChartWidget';
import { PieWidget2 } from './PieWidget2';
import { PredictTrendline } from './PredictedTrendline';
import { PriceCurvesWidget2 } from './PriceCurvesWidget2';
import { QuestionCover } from './QuestionCover';
import { RealTimeNewsWidget } from './RealTimeNewsWidget';
import { ScatterPlotWidget } from './ScatterPlotWidget';
import { SegmentProfilesViewer } from './SegmentProfilesViewer';
import { StackedBarsWidget } from './StackedBarsWidget';
import { SurveyBarViewer } from './SurveyBarViewer';
import { SurveyTableViewer } from './SurveyTableViewer';
import { SurveyTableViewerComp } from './SurveyTableViewerComp';
import { SwitchablesOutcomesWidget } from './SwitchablesOutcomesWidget';
import { TreeOfLifeWidget } from './TreeOfLifeWidget';
import { USAViewer } from './USAViewer';
import { UtilsLineChartWidget } from './UtilsLineChartWidget';
import { WordCloud } from './WordCloud';


export const widgetMap = {
    'AllElseEqualsWidget2': AllElseEqualsWidget2,
    'AllElseEqualsWidget2C': AllElseEqualsWidget2C,
    'BarChartComp' : BarChartComp,
    'BlankWidget': BlankWidget,
    'BoxPlotWidget': BoxPlotWidget,
    'BoxPlotWidget2': BoxPlotWidget2,
    'BrandAllElseEqualWidget': BrandAllElseEqualWidget,
    'CoOccurringWordsTableViewer': CoOccurringWordsTableViewer,
    'CorrelationsViewer': CorrelationsViewer,
    'CorrespondenceMap': CorrespondenceMap,
    'CovidWidget': CovidWidget,
    'CovidWidget2': CovidWidget2,
    'CovidWidget3': CovidWidget3,
    'CustomWidget1' : CustomWidget1,
    'DemogWidget': DemogWidget,
    'DemogWidget2': DemogWidget2,
    'DynamicWidget': DynamicWidget,
    'DCMColumnsWidget': DCMColumnsWidget,
    'DCMConfigWidget': DCMConfigWidget,
    'DCMConfigWidget2': DCMConfigWidget2,
    'DCMProductsTable': DCMProductsTable,
    'DCMProductsTableB': DCMProductsTableB,
    'DCMProductsTableC': DCMProductsTableC,
    'DCMProductsWidget': DCMProductsWidget,
    'DCMSunburstWidget': DCMSunburstWidget,
    'DCMSunburstWidgetNonZoom': DCMSunburstWidgetNonZoom,
    'DCMTreeMapWidget': DCMTreeMapWidget,
    'DCMTreeMapWidget2': DCMTreeMapWidget2,
    'DotPlotWidget': DotPlotWidget,
    'DynamicWidget' : DynamicWidget,
    'FunnelWidget': FunnelWidget,
    'FunnelWidgetTrended': FunnelWidgetTrended,
    'ImageWidget' : ImageWidget,
    'LineChartWidget': UtilsLineChartWidget, // temp!
    'LineChartWidget2': LineChartWidget2,
    'NPSDetail': NPSDetail,
    'NPSDetailBar': NPSDetailBar,
    'NPSSegments': NPSSegments,
    'NPSViewer': NPSViewer,
    'NPSViewerCW': NPSViewerCW,
    'NPSViewerW': NPSViewerW,
    'PieChartWidget' : PieChartWidget,
    'PieWidget2' : PieWidget2,
    'PredictTrendline': PredictTrendline,
    'PriceCurvesWidget2': PriceCurvesWidget2,
    'QuestionCover': QuestionCover,
    'RealTimeNewsWidget' : RealTimeNewsWidget,
    'ScatterPlotWidget': ScatterPlotWidget,
    'SegmentProfilesViewer': SegmentProfilesViewer,
    'StackedBarsWidget' : StackedBarsWidget,
    'SurveyBarViewer': SurveyBarViewer,
    'SurveyTableViewer': SurveyTableViewer,
    'SurveyTableViewerComp': SurveyTableViewerComp,
    'SwitchablesOutcomesWidget': SwitchablesOutcomesWidget,
    'TreeOfLifeWidget': TreeOfLifeWidget,
    'USAViewer': USAViewer,
    'UtilsLineChartWidget': UtilsLineChartWidget,
    'WordCloud': WordCloud,
    
}