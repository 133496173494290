import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

import { Redirect, Route, Switch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { SignOutPage } from './account/SignOutPage';
import { ChangePassword } from './change-password/ChangePassword';
import { TileContainer } from './common/TileContainer';
import { EditProfile } from './edit-profile/EditProfile';
import { StudyApprove } from './study-approval/StudyApproval';
import { ManageAccount } from './manage/ManageAccount';
import * as apiService from '../utils/apiService';
import * as globals from '../globals';
import './studyLoader.scss';
import { ManageUsers } from './account/ManageUsers';

export function StudyLoader(props) {

    const auth = useSelector(state => state.auth);
    const auth_studies = useSelector(state => state.auth?.studies);
    const history = useHistory();
    const [showStudies, setShowStudies] = useState(false);
    const [chosenGroup, setChosenGroup] = useState('');
    const [showAdminFeatures, setAdminFeatures] = useState(false);

    // determine fallback study in case we need to redirect
    let fallbackStudy = null;

    if (auth_studies != null && auth_studies.length === 1) {
        fallbackStudy = auth_studies[0];
    }


    let groupdict = {};
    auth_studies?.forEach(study => {
        var arr = groupdict[study.groupLabel] || [];
        arr.push(study);
        groupdict[study.groupLabel] = arr;
    })
    let groups = Object.keys(groupdict);

    // let studies = (auth_studies || []).reduce((accum, entry) => {
    //     let sdy = entry.groupLabel || '';
    //     if (!accum.includes(sdy)) {
    //         accum.push(sdy);
    //     }
    //     return accum;
    // }, []).sort();
    // console.log('studies', studies);

    let noStudies = auth_studies == null || auth_studies.length === 0;
    let hasStudies = auth_studies != null && auth_studies.length > 1;


    useEffect(() => {
        if(groups && groups.length == 1)
            setChosenGroup(groups[0]);
    } )
    
    return <>

        <Switch>
            <Route path="/sign-out" component={SignOutPage} />
            <Route path="/user/:userid" render={props => <div>todo: user profile</div>} />
            <Route path="/profile/edit" render={props => <EditProfile {...props} />} />

            {auth?.user?.isSuperAdmin && <Route path="/account/study-approval" render={props => <StudyApprove {...props} />} />}
            {auth?.user?.isSuperAdmin && <Route path="/account/manage-users" render={props => <ManageUsers {...props} />} />}

            <Route path="/account/manage" render={props => <ManageAccount {...props} />} />
            <Route path="/account/change-password" render={props => <ChangePassword {...props} />} />
            <Route path="/admin/users" render={props => <div>todo: admin users</div>} />
            <Route path="/admin/studies" render={props => <div>todo: admin studies </div>} />
            <Route path="/admin/approve-user/:userUid" render={props => <div>approve user</div>} />


            <Route render={() =>
                noStudies  ?
                    <div className="blank-screen" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  height: '100vh', width: '100vw', fontSize:18, color:'#404040', fontFamily:'Roboto'}}>
                        No assigned licenses. Please contact Blue Owl.
                    </div>

                    : hasStudies ?
                        <div className='studyContainer'>
                            <div className='studySection'>
                                <div className="studyHeader">
                                    <h2>Welcome to Blue Owl AI</h2>
                                    <p>Choose a version</p>
                                </div>
                                <div className="studyBody">
                                    {
                                        groups.map((group,i) => {

                                            const folder = groupdict[group]?.[0]?.folder;

                                            return (
                                                <div className="studyCardContainer" key={group} >
                                                    <div className="studyCard" onClick={() => { setChosenGroup(group) }} >
                                                        <div className={"innerCard " + (chosenGroup === group ? 'study-selected' : '')} >
                                                            <div tabIndex={i+1}>
                                                                <img src={`${globals.cdnUrl}/cdn-shared/${folder}/images/study.png`} alt={group} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label className="subText">{group}</label>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {auth?.user?.isSuperAdmin && showAdminFeatures ? <CloneStudy srcs={auth_studies.map(study => study.uid)} /> : null}

                                <span className={"admin-features-btn" + (showAdminFeatures ? ' showBtn' : '')} onClick={() => setAdminFeatures(!showAdminFeatures)}>
                                    <i className='far fa-shield-alt' />
                                </span>

                                <div className="filler"></div>
                            </div>
                            <div className="licenseContainer">
                                {
                                    chosenGroup !== '' &&
                                    (() => {
                                        const LicensePerStudy = auth_studies?.filter(study => !study.groupLabel || (study.groupLabel === chosenGroup));
                                        if (!LicensePerStudy) return null;
                                        return <>
                                            {
                                                LicensePerStudy.map((license,i) =>

                                                    <div className="license" key={license?.uid} onClick={() => history.push(`/study/${license?.uid}`)} >
                                                        <span className='licenseName'>{license?.label.includes(":") ?  license?.label.split(':')[1] : license.label}</span>
                                                    </div>
                                                )}
                                        </>
                                    })()
                                }
                            </div>
                        </div>
                        : <Redirect to={`/study/${fallbackStudy?.uid}`} />
            }
            />
        </Switch>
    </>
}

//superuser create new study hidden icon
const CloneStudy = (props) => {

    const srcs = props.srcs;
    const [srcName, setSrcName] = useState(srcs?.[0]);
    const [name, setName] = useState(null);
    const [label, setLabel] = useState(null);
    const auth = useSelector(state => state.auth);

    const create = () => {
        const body = {
            src: srcName,
            newStudyName: name,
            newStudyLabel: label
        };

        apiService.aPost(auth, `${globals.apiRoot}/study/${srcName}/clone`, body)
            .then((res) => {
                console.log('created');
            });
        
        alert(`Study ${srcName} created! Please logoff and back in to see changes`)
    }

    const deleteStudy = () => {
        let choice = window.confirm("You are about to delete the study: " + srcName);
        if (choice) {

            apiService.aDelete(auth, `${globals.apiRoot}/study/${srcName}/deleteStudy`)
                .then((res) => {
                    console.log('deleted study', srcName);
                });
            alert(`Study ${srcName} deleted!`)
        }
        else {
            alert('Study deletion cancelled')
        }
    }

    return <div className='new-study-widget'>
        <span className='new-study-create'>
            <i className='fal fa-shield-alt' /> clone:
        </span>
        <select className='new-study-box xname' placeholder='codename' value={srcName || ''} onChange={(ev) => setSrcName(ev.target.value)}>
            {srcs?.map(src => <option key={src}>{src}</option>)}
        </select>
        <span className='new-study-create'>
            as:
                </span>
        <input type='text' className='new-study-box xname' placeholder='codename' value={name || ''} onChange={(ev) => setName(ev.target.value)} />
        <input type='text' className='new-study-box xlabel' placeholder='label (without group name) e.g., Amazon' value={label || ''} onChange={(ev) => setLabel(ev.target.value)} />
        <span className='new-study-btn' onClick={() => create()} role='button' aria-label='new study'>
            <i className='fal fa-check' />
        </span>
        <span className='delete-study-btn' onClick={() => deleteStudy()} role='button' aria-label='delete study'>
            <i className='fal fa-times' />
        </span>
    </div>;

}



