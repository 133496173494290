import * as React from 'react';
import { format } from 'd3-format';
import { connect } from 'react-redux';
import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { bindActionCreators } from 'redux';
import * as globals from '../../globals';
import { actionCreators as forecastActionCreators } from '../../store/Forecast.ts';
import * as debounce from '../../utils/debounce';
import * as selectionHelper from '../../utils/selectionHelper';
import { CardLoading } from '../card/CardLoading';
import { FilterChooser } from '../filter/FilterChooser';
import { ProductBlockMini } from '../product-drawer/ProductBlockMini';
import './SwitchablesOutcomesWidget.scss';
import { TableFred } from '../vis/TableFred';
import { CoreWidget1 } from './CoreWidget1';

const fmt_pct = format('.1%');

export let SwitchablesOutcomesWidget = (props) => {

    if (props.loaded && typeof props.loaded === 'function')
        props.loaded(null, true)

    const models = props?.models || [];    

    const renderContent = (cache, getJSONData) => {


        if (cache && cache.length > 0) {
            var dataItem = cache.map(level => {
                let model = models.find(model => model.value === level.model)
                if (model) level = { ...level, model: model.label }

                return {
                    ...level,
                    lost: level.lost && typeof level.lost === 'object'
                        ? level.lost : { value: level.lost ? level.lost : '-' },
                    risk: level.risk && typeof level.risk === 'object'
                        ? level.risk : { value: level.risk ? level.risk : '-' },
                    prospect: level.prospect && typeof level.prospect === 'object'
                        ? level.prospect : { value: level.prospect ? level.prospect : '-' },
                    loyal: level.loyal && typeof level.loyal === 'object'
                        ? level.loyal : { value: level.loyal ? level.loyal : '-' },
                }
            })
            //console.log(dataItem);
        }

        const tableData = (slot, val) => {
            switch(slot.label){
                case 'lost': return { [slot.label]: { value: val, bgColor: globals.switchableColors.lost, fgColor: globals.switchableColors.lostFg } }
                case 'risk': return { [slot.label]: { value: val, bgColor: globals.switchableColors.atRisk, fgColor: globals.switchableColors.atRiskFg } }
                case 'prospect': return { [slot.label]: { value: val, bgColor: globals.switchableColors.prospect, fgColor: globals.switchableColors.prospectFg } }
                case 'loyal': return { [slot.label]: { value: val, bgColor: globals.switchableColors.loyal, fgColor: globals.switchableColors.loyalFg } }
            }
        }

        if (dataItem && dataItem.length > 0) {

            let slots = [];
            Object.keys(dataItem[0]).forEach(key => {
                switch(key){
                    case 'model' :
                    slots[0] = { uid: key, label: key }
                    break;
                    case 'lost' :
                    slots[1] = { uid: key, label: key }
                    break;
                    case 'risk' :
                    slots[3] = { uid: key, label: key }
                    break;
                    case 'prospect' :
                    slots[4] = { uid: key, label: key }
                    break;
                    case 'loyal' :
                    slots[5] = { uid: key, label: key }
                    break;
                }
            });

            const columns = [
                { key: 'model',  type: 'string', className: "label_sm", label: 'Model', className: 'label' },
                { key: 'distrib', type: 'stacked-bar', columns: [
                    { key: 'lost', type: 'cell', className: "data", format: fmt_pct, label: 'Lost-cause', fill: globals.switchableColors.lost, color: globals.switchableColors.lostFg, fillHeader: true },
                    { key: 'risk', type: 'cell', className: "data", format: fmt_pct, label: 'At-risk', fill: globals.switchableColors.atRisk, color: globals.switchableColors.atRiskFg, fillHeader: true },
                    { key: 'prospect', type: 'cell', className: "data", format: fmt_pct, label: 'Prospect', fill: globals.switchableColors.prospect, color: globals.switchableColors.prospectFg, fillHeader: true },
                    { key: 'loyal', type: 'cell', className: "data", format: fmt_pct, label: 'Loyal', fill: globals.switchableColors.loyal, color: globals.switchableColors.loyalFg, fillHeader: true },
                ]}
            ];

            var tableRows = dataItem;
            
            tableRows.forEach(item =>
                slots.map(slot => item[slot.label]
                    ? Object.assign(
                        item, slot.label !== 'model'
                            ? tableData(slot, item[slot.label].percent ? format('.3f')(item[slot.label].percent) : '-')
                            : null
                    )
                    : null
                )
            )

            if (getJSONData && typeof getJSONData === 'function')
                getJSONData(null, true)
            // [lost - dark red, risk - light red, prospect - light green , loyal - dark green]
            return <TableFred getJSONData={null} columns={columns} data={tableRows}/>;
        }

    }

    return <CoreWidget1 {...props}
        loaded={null}
        useFilter={true}
        useSelection={true}
        useScenario={true}
        render={renderContent}
        class2='vscroll hscroll'
        useAux={{
            path: "/run-switchables",
            method: "post",
            body: {
                multiFilterize: true
            }
        }}
    />


    // //const selectedProduct = this.props.selectedProducts?this.props.selectedProducts[0]:{};
    // // const colors = this.state.agg === 'product' ? [globals.configs2.play.color, globals.configs2.base.color] :
    // //     ['red', 'gray'];
    // const colors = [globals.configs2.play.color, globals.configs2.base.color];


    // const content = !aggregatedCache ? (filterCache && filterCache.error ? filterCache.error : null) :
    //     <div className='middle-content' >
    //         {globals.metrics.map((metric) => {

    //             let data = [{
    //                 name: metric.label,
    //                 play: aggregatedCache ? aggregatedCache.play[metric.key] : null,
    //                 base: aggregatedCache ? aggregatedCache.base[metric.key] : null
    //             }];

    //             const diffNum = data[0].play - data[0].base;
    //             const diff = metric.fformat(Math.abs(diffNum));
    //             //return this.props.inInsightCard ? 'raw data: ' + JSON.stringify(data) :

    //             return <div className='cell' key={metric.key}>
    //                 <div className="bar-chart">
    //                 <ResponsiveContainer>
    //                     <BarChart key={metric.key} data={data} style={{display: 'inline-block'}}>
    //                         <XAxis dataKey="name"/>
    //                         <YAxis padding={{ top: 20 }} hide={true}/>
    //                         <Tooltip formatter={metric.dformat}/>
    //                         <Bar dataKey="base" name={globals.configs2.base.label} fill={colors[1]} animationDuration={200}>
    //                             <LabelList dataKey="base" position="top" formatter={metric.fformat}></LabelList>
    //                         </Bar>
    //                         <Bar dataKey="play" name={globals.configs2.play.label} fill={colors[0]} animationDuration={200}>
    //                             <LabelList dataKey="play" position="top" formatter={metric.fformat}></LabelList>
    //                         </Bar>

    //                     </BarChart>
    //                 </ResponsiveContainer>
    //                 <div className="diff">
    //                 <i className={"far "+(diffNum < 0 ? "fa-minus red":diffNum > 0 ? "fa-plus green":null)}></i>
    //                 &nbsp; {diff}</div>
    //                 </div>
    //             </div>;
    //         })}
    //     </div>;



}

SwitchablesOutcomesWidget = connect(
    state => ({models: state?.study?.selectionHierarchy[1]?.levels})
)(SwitchablesOutcomesWidget);