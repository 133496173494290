import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as forecastActionCreators } from "../../store/Forecast.ts";

const barStyle = {
	fontSize: "8pt",
	fontFamily: "monospace",
	backgroundColor: "#efefef",
	padding: 4,
	borderBottom: "1px solid #990000",
	marginBottom: 5,
};

const keyStyle = {
	fontWeight: "bold",
	color: "#808080",
	marginRight: 5,
};

const valueStyle = {
	color: "#afafaf",
};

const upToDateStyle = {
	backgroundColor: "#009900",
	color: "#ffffff",
	padding: "0px 4px",
};

const needsUpdateStyle = {
	backgroundColor: "#990000",
	color: "#ffffff",
	padding: "0px 4px",
};

const errorStyle = {
	backgroundColor: "purple",
	color: "#ffffff",
	padding: "0px 4px",
	cursor: "pointer",
};

const loadingStyle = {
	backgroundColor: "#0064c8",
	color: "#ffffff",
	padding: "0px 4px",
};

const separatorStyle = {
	marginRight: 10,
};

const btnStyle = {
	color: "#0088ff",
	marginRight: 10,
	fontWeight: "bold",
	cursor: "pointer",
};

export class AnalyticsWidgetDebugBar extends React.Component {
	refresh = () => {
		// todo: run the dang query
	};

	debug = () => {
		console.log("props", this.props);
	};

	render() {
		if (!globals.debugCards) return null;

		if (this.props.inDataCard) {
			let cache = this.props.cache;
			let filter =
				this.props.filter || globals.getDefaultFilter(this.props.study);
			let fCache = (cache || {})[filter.syntax];
			let etag = this.props.etag
				? this.props.etag.substring(1, 6) + "..."
				: null;
			let cacheStatus = !fCache ? (
				<span style={needsUpdateStyle}>no data</span>
			) : fCache.loading ? (
				<span style={loadingStyle}>loading</span>
			) : fCache.etag !== this.props.etag ? (
				<span style={needsUpdateStyle}>needs update</span>
			) : fCache.error ? (
				<span style={errorStyle} title={fCache.error}>
					error <i className="fal fa-question-circle" />
				</span>
			) : (
				<span style={upToDateStyle}>up-to-date</span>
			);

			return (
				<div style={barStyle}>
					<a style={btnStyle} onClick={() => this.refresh()}>
						<i className="fas fa-sync" />
					</a>
					<span style={keyStyle}>ttl:</span>
					<span style={valueStyle}>todo</span>
					<span style={separatorStyle}>;</span>
					<span style={keyStyle}>cache:</span>
					{cacheStatus}
					<span style={separatorStyle}>;</span>
					<span style={keyStyle}>query:</span>
					<span style={valueStyle}>?todo=1</span>
					<a style={btnStyle} onClick={() => this.debug()}>
						<i className="fas fa-bug" />
					</a>
				</div>
			);
		}

		if (this.props.inInsightCard) {
			return (
				<div style={barStyle}>
					<span style={keyStyle}>cache:</span>
					'(insight card)'
				</div>
			);
		}

		return <div>context missing for debug bar</div>;
	}
}

AnalyticsWidgetDebugBar = connect(
	(state) => ({
		etag: ((state.study || {}).config || {}).etag,
		cache: (state.cache || {}).forecast,
		filter: (state.study || {}).selectedFilter,
		study: state.study,
	}),
	(dispatch) => bindActionCreators(forecastActionCreators, dispatch),
)(AnalyticsWidgetDebugBar);
