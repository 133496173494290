import * as React from 'react';
import { useSelector } from 'react-redux';
import * as selectionHelper from '../../utils/selectionHelper';
import BoxPlot from '../vis/BoxPlot';
import { CoreWidget1 } from './CoreWidget1';


export const StackedBarsWidget = (props) => {

    const study = useSelector(state => state.study);
    const selections = useSelector(state => state.selections);
    const selection = selectionHelper.getSelection(props, study, selections);
    
    const entries = props.entries || 'functional_benefits';

    const statements = window.meta?.[entries];
    if( !statements ) return `meta entries for ${entries} is missing`;

    //const entryCode = (entry) => entry.getCode ? entry.getCode(selObj) : entry.code;

    const levels = props.levels || [
        { value: 1, label: 'No, does not describe me' },
        { value: 2, label: 'Yes, describes me' }
    ];

    let measures = [];
    statements.forEach(statement => {
        levels.forEach(level => {
            measures.push( { id: `${statement.code}=${level.value}`, syntax: `pctin(${statement.code}, ${level.value})` })
        })
    })

    const renderContent = (cache, getJSONData) => {
        if( !cache?.results ) return null;

        const r = cache.results;
        let data = statements.map(statement => {
            return {
                label: statement.label,
                values: levels.map(level => {
                    const cell = r[`${statement.code}=${level.value}`];
                    return {
                        label: level.label,
                        value: cell?.value,
                        n: cell?.n || 0
                    }
                })
            }
        });

        const sort = props.sort || "none";
        const sortIndex = props.sortIndex || 0;
        if( sort === "asc" || sort === "desc" ){
            data = data.sort((a, b) => {
                const aValue = a.values?.[sortIndex]?.value;
                const bValue = b.values?.[sortIndex]?.value;
                const mult = sort === "desc" ? -1 : 1;

                if( aValue && bValue ) return (aValue - bValue) * mult;
                if( aValue ) return 1 * mult;
                if( bValue ) return -1 * mult;
                return 0;
            })
        }

        const boxPlotProps = {
            height: data.length * 25,
            labelWidth: props.labelWidth || [200,500],
        };
        if (getJSONData && typeof getJSONData === 'function')
            getJSONData(data.map(i => ({
                label: i.label,
                [i.values[0].label]: i.values[0].value,
                [i.values[1].label]: i.values[1].value
            })))

        return cache ? <div className='vscroll-content'>
            <BoxPlot data={data} visual='stacked-bars' {...boxPlotProps}/>
        </div> : null;
    }

    const selObj = selection ? window.meta.dcmToSurveyMap("" + selection?.[study?.explore?.selectionLevel]) : null;
    const sel = selObj?.survey_id;
    if( !sel ) return 'selection missing or unavailable';

    

    const filter = props.ownerFilter ? `q1_${sel}==7 or q1_${sel}==8` : // todo: change this to a generic var like 'owner' :
        props.getFilter ? props.getFilter(selObj) : 
        props.filter;
    const filters = filter ? [ filter ] : null;

    return <CoreWidget1 
        useSelection={true}
        useFilter={true}
        render={renderContent}
        useYak={{
            query: {
                filters,
                measures
            }
        }}
        {...props}
    />

}
