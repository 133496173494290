import * as React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as metaUtils from '../../utils/metaUtils';
import './LoginNotification.scss';
import * as globals from "../../globals.js";


export function LoginNotification(props) {
    
    const [metadata, setMetaData] = useState();
    const [userdata, setUserData] = useState();
    
    const studyId = "video-games-demo";//useSelector(state => state.study?.uid);
    const auth = useSelector(state => state.auth);

    const getMeta = async() => {
            try {
                let meta = await metaUtils.getMetas(studyId, auth, ["userNotifications"]);
                // console.log('meta', meta?.userNotifications)
                if (meta) {
                    let user = meta.userNotifications.find(f => f.name === auth?.user?.name)
                    setUserData(user);
                    setMetaData(meta?.userNotifications);                   
                    // console.log('user rights', auth)
                }
            }
            catch (err) {
                console.error(err);
            }
        }
   
    
    //  console.log('auth', auth?.user?.name)

    useEffect(() => {
        
        getMeta();

        return function cleanup() {
            let script = document.getElementById('script1')?.innerHTML;
            if(script?.length > 0)
                eval(script);
        }        
    })


    const close = () => {
        let elem = document.getElementsByClassName("unNotificationContainer");
        elem[0].classList.add('hide');

        const f2 = async() => {
            try {
                
                let user = metadata.find(f => f.name === auth?.user?.name)
                user.show = false;
                // console.log('meta data', metadata)

                let meta = await metaUtils.setMeta(studyId, auth, ["userNotifications"], metadata);
            }
            catch (err) {
                console.error(err);
            }
        }
        f2();
        
        props.showNotification(false);

    }


    return <div className="unNotificationContainer">
        <div className="close" onClick={close}><i className="fas fa-times-circle"></i></div>
        <div className="unNotification">
            <div className="unHeading"> Personal Notification </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop:20 }}>
                <img src={`${globals.cdnUrl}/cdn-shared/Bo Logo Lg.png`} className="icon" alt="blue owl logo"/>
            </div>
            <div className="unHeader"></div>
            <div className="unBody">
                 <div dangerouslySetInnerHTML={{ __html: userdata?.message }} />
            </div>            
            <div className="unFooter">
                <div className="btn rounded drk text-center" role='button' aria-label='close' style={{ position: 'absolute', bottom: 20, left: '50%', marginLeft: '-50px', width: 100 }} onClick={close}> Close </div>
            </div>
        </div>            
</div>
}