import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators as channelsActionCreators } from '../../store/Channels.ts';
import { actionCreators as postsActionCreators } from '../../store/Posts.ts';
import { actionCreators as storiesActionCreators } from '../../store/Stories.ts';
import { PageHeader2 } from '../common/PageHeader';
import { StoriesSubMenu } from '../leftbar/StoriesSubMenu';
import './Feed.scss';
import { FeedPost } from './FeedPost';


const sortOptions = [
    { key: 'newest', label: 'Newest' },
    { key: 'popular', label: 'Most popular' }
];

export class Feed extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            showFavorites: false
        };
    }

    componentWillMount() {
        this.checkData(true);
    }

    componentDidUpdate(){
        this.checkData();
    }

    checkData = (forceRefresh = false) => {

        // const channelId = this.props.match.params.channelId || '*';
        
        // const channels = this.props.channels;

        // if( channels && !channels.data && !channels.loading ){
        //     this.props.loadChannels();
        // }

        const posts = this.props.posts;
        if( !posts || !posts.channels || forceRefresh ){ //|| !posts.channels[channelId] ){
            this.props.loadPosts((posts) => {
                // const channelPosts = posts[channelId] || [];

                // const storyIds =  channelPosts.map(post => post.storyId).filter(x => x);

                // // note: fix this bad performance by creating a loadStores call that supports multiple storyids
                // // e.g., this.props.loadStories(storyIds);
                // // (right now loadStories only loads the user's story drafts)
                // storyIds.forEach(storyId => this.props.loadStory(storyId));
                
            });
        }

    }

    // getFilteredPosts = () => {

    //     const posts = this.props.posts;
    //     if(!posts || !posts.data) return [];

    //     const channelId = this.props.match.params.channelId;

    //     let filteredPosts = posts.data.filter(post => {

    //         // filter on favorites
    //         if( this.state.showFavorites && !post.isFavorite )
    //             return false;

    //         if(!channelId) return true; // viewing all channels, so include this post

    //         return (post.channel === channelId);

    //     });

    //     return filteredPosts;
        
    // }

    toggleShowFavorites() {
        this.setState({ showFavorites: !this.state.showFavorites });
    }

    render() {
        
        const posts = this.props.posts;
        if(!posts) return null; //'null posts';
        if(!posts.channels) return null; //'null post channels';

        let studyId = this.props.match.params.studyId;
        const channelId = this.props.match.params.channelId || '*';
        //if( !channelId ) return 'all channels not yet implemented';

        // find channel
        let channelPosts = posts.channels[channelId];
        let channelMembersStr = null;
        // if( channels.data ){
        //     channel = channels.data.find(ch => ch.key === channelId);

        //     if( channel && channel.users  ){
        //         const sortedUsers = channel.users.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
        //         channelMembersStr = sortedUsers.map(user => user.name + (user.readOnly ? ' (read only)' : '')).join('\r\n');
        //     }
        //     else{
        //         let users = []
        //         channels.data.forEach(ch => {
        //             ch.users.forEach(u => {
        //                 if( !users.find(user => user.uid === u.uid) ){
        //                     users.push(u);
        //                 }
        //             })
        //         })
        //         const sortedUsers = users.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
        //         channelMembersStr = sortedUsers.map(user => user.name).join('\r\n');
        //     }
        // }

        //let channelLabel = channel ? channel.label : 'All channels';
        const channelLabel = (channelId || '').replace('*', 'All Channels').replace('-', ' '); // hack. just look up the dang label
        
        //let filteredPosts = this.getFilteredPosts();

        const filteredPosts = (channelPosts || []).map(postId => {
            return posts.dict[postId];
        }).filter(x => x);
        
        // sort the filtered posts??

        //don't delete
        //  <PageHeader2 
        //             title={<span style={{ cursor: 'pointer' }} title={channelMembersStr} onClick={() => window.topBar.openMenu('channels')}>
        //                 {channelLabel}
        //             </span>}
        //             fullWidth={true}
        //             left={<>

        //                 <span className={'btn1 no-hover ' + (this.state.showFavorites ? ' sel' : '')}
        //                     title='Show Favorites'
        //                     onClick={() => this.toggleShowFavorites('~favs')}
        //                 >
        //                     <i className={(this.state.showFavorites ? 'fas fa-heart' : 'fal fa-heart')} />
        //                 </span>
        //             </>}

        //             right={null}
        //         />

        return (
            <div className="page feed submenu">
                
               

                <div className="submenu">
                    <StoriesSubMenu studyId={studyId}  />
                </div>

                <div className="content vscroll">

                    {!filteredPosts ? (
                        <div className="feed-loading">
                        loading stories<span className="blink_cursor">_</span>
                        </div>
                    ) 
                    : filteredPosts.length === 0 ? (
                        <div className="feed-loading">
                        (channel is empty)
                        </div>
                    )
                    : (
                        <div className="feed-post-list">
                        {filteredPosts.map((post, idx) => (
                            <FeedPost studyId={studyId} key={post.uid + ';' + idx} postId={post.uid} xdata={post} showFavorites={this.state.showFavorites} />
                        ))}
                        </div>
                    )}


                </div>
                
            </div>
        );
    }
}

Feed = withRouter(
    connect(
        state => ({ 
            posts: state.posts,
            channels: state.channels,
            authStudies: state.auth.studies 
        }),
        dispatch => bindActionCreators({...postsActionCreators, ...channelsActionCreators, ...storiesActionCreators}, dispatch)
    )(Feed)
);
