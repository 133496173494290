import * as React from 'react';
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import * as globals from '../../globals';
import * as apiService from '../../utils/apiService';
import { DataCard } from '../card/DataCard';
import { getRepo } from '../widgets/_widgetRepo';
import './SlideContainer.scss';
import { getChildLink } from './ExploreUtils';
import { ReactSortable } from '../common/Sortable';
import { EditModeWrapper } from './ContentViewer';

export const SlideContainer = (props) => {

    const {id, title, data} = props.item;

    const slides = data?.slides;

    const study_uid = useSelector(state => state.study?.uid);
    const study_folder = useSelector(state => state.study?.folder);

    const area = props.area;
    const params = useParams();

    const content = <div className='content-section'>
        <div className='title'>
            {title}
        </div>
        <div className='slide-container margin-bottom'>
            {slides?.map(slide => {
                const imageUrl = `${globals.cdnUrl}/cdn-shared/${study_folder}/images/${slide.image}`;
                return <div className='slide'>
                    <img className='slide-image' src={imageUrl} />
                </div>
            })}

        </div>
    </div>;

    return props.asPage ? wrapAsPage(props.item, content, null) : content;

}


