import * as React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Auth.ts';
import { PageHeader } from '../common/PageHeader';
import './ChangePassword.scss';


export class ChangePassword extends React.Component {
    constructor() {
        super();

        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            current_password: '',
            new_password: '',
            confirm_password: '',
            validate: false,
            disabled: true
        }
    }

    checkConfirmPassword(value) {
        this.setState({ confirm_password: value });
        this.state.new_password === value
            && this.state.validate
            && this.state.current_password !== '' ? this.setState({ disabled: false }) : this.setState({ disabled: true });
    }

    validate(value) {
        if (value !== '') {
            value.length > 5 &&
            /\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\=|\+/.test(value) &&
            /[A-Za-z]/.test(value) ?
                this.setState({ validate: true }) : this.setState({ validate: false });
        }
        this.setState({ new_password: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.changePassword({
            old_password: this.state.current_password,
            new_password: this.state.new_password
        }, (res, err) => {
            // console.log(res)
            if (res) {
                alert('Your password has been changed.');
                this.props.history.push('/');
                this.setState({
                    current_password: '',
                    new_password: '',
                    confirm_password: '',
                    validate: false,
                    disabled: true
                })
            }
            if (err) {
                alert('Current password does not match.');
            }
        });
    }

    render() {
        return (
            <div className="page change-password">
                <PageHeader title={'Change Password'} fullWidth={true} />
                <div className="content">

                    <form onSubmit={this.handleSubmit}>
                        <div className="change-password-body">
                            <div className="input-group">
                                <label>current password</label>
                                <div className="icon-set">
                                    <input value={ this.state.current_password } type="password" role='input' aria-label='current password'
                                        onChange={ (e) => this.setState({ current_password: e.target.value }) } />
                                    {/* <i className={ this.checkCurrentPassword() ? "far fa-check-circle" : "fal fa-times"} aria-hidden="true" /> */}
                                </div>
                            </div>

                            <div className="input-group password-instruction">
                                <label>password rules</label>
                                <ul className="instruction-list">
                                    <li className={ this.state.new_password === '' ? 'icon-set invalid' : this.state.new_password.length >= 6 ? 'icon-set valid' : 'icon-set invalid' }>
                                        <span>Password must be at least 6 characters in length.</span>
                                        <i className={ this.state.new_password.length >= 6 ? 'far fa-check-circle' : 'fal fa-times' } aria-hidden="true" />
                                    </li>
                                    <li className={ this.state.new_password === '' ? 'icon-set invalid' : /\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\=|\+/.test(this.state.new_password) ? 'icon-set valid' : 'icon-set invalid' }>
                                        <span>Password must include at least 1 special character: !@#$%^&amp;*()-_=+</span>
                                        <i className={ /\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\=|\+/.test(this.state.new_password) ? 'far fa-check-circle' : 'fal fa-times' } aria-hidden="true" />
                                    </li>
                                    <li className={ this.state.new_password === '' ? 'icon-set invalid' : /[A-Z]/.test(this.state.new_password) ? 'icon-set valid' : 'icon-set invalid' }>
                                        <span>Password must include at least 1 uppercase letter.</span>
                                        <i className={ /[A-Z]/.test(this.state.new_password) ? 'far fa-check-circle' : 'fal fa-times' } aria-hidden="true" />
                                    </li>
                                    <li className={ this.state.new_password === '' ? 'icon-set invalid' : /[a-z]/.test(this.state.new_password) ? 'icon-set valid' : 'icon-set invalid' }>
                                        <span>Password must include at least 1 lowercase letter.</span>
                                        <i className={ /[a-z]/.test(this.state.new_password) ? 'far fa-check-circle' : 'fal fa-times' } aria-hidden="true" />
                                    </li>
                                </ul>
                            </div>

                            <div className="input-group">
                                <label>new password</label>
                                <div className="icon-set">
                                    <input value={ this.state.new_password } type="password" role='input' aria-label='new password'
                                        onChange={ (e) => this.validate(e.target.value) } />
                                    {
                                        <i className={ this.state.new_password === '' ? '' : (this.state.new_password.length >= 6 &&
                                            /\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\=|\+/.test(this.state.new_password) &&
                                            /[a-zA-Z]/.test(this.state.new_password)) ? 'far fa-check-circle' : 'fal fa-times' } aria-hidden="true" />
                                    }
                                </div>
                            </div>

                            <div className="input-group">
                                <label>confirm new password</label>
                                <div className="icon-set">
                                    <input value={ this.state.confirm_password } type="password" role='input' aria-label='confirm new password'
                                        onChange={ (e) =>{  this.checkConfirmPassword(e.target.value) } } />
                                    {
                                        <i className={ this.state.confirm_password === '' ? '' :
                                            (this.state.new_password === this.state.confirm_password) ?
                                                'far fa-check-circle' : 'fal fa-times' } aria-hidden="true" />
                                    }
                                </div>
                            </div>

                            <div className="action-head">
                                <button
                                    type="submit"
                                    className="action-button"
                                    role='button'
                                    aria-label='Cancel'
                                    disabled={
                                        !(this.state.disabled === false &&
                                        this.state.current_password !== '' &&
                                        this.state.validate)
                                    }>ok</button>
                                     <Link to="/" className="action-button">cancel</Link>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        );
    }
}

ChangePassword = withRouter(
    connect(
        state => state.auth || {},
        dispatch => bindActionCreators(actionCreators, dispatch)
    )(ChangePassword)
);
