
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { BreadCrumb } from '../common/BreadCrumb';
import { PageHeader2 } from '../common/PageHeader';
import { ProjectedOutcomesPane } from './ProjectedOutcomesPane';
import { ScenarioEditor } from './ScenarioEditor';
import './WhatIf.scss';

export const WhatIf = () => {

    const params = useParams()
    const history = useHistory()
    const study = useSelector(state => state.study)
    // const dispatch = useDispatch() // Uncomment if you need to dispatch Redux actions

    const [tab, setTab] = useState('scenario')
    const [selectedTag, setSelectedTag] = useState(params.sectionId || 'projected-outcomes')

    if (!study.config) return null

    const studyId = study.uid

    let breadCrumbElements = [{ label: 'What if' }]
    if (selectedTag === 'all-else-equal') {
        breadCrumbElements.push({ label: 'Feature Impact' })
    } else {
        breadCrumbElements.push({ label: 'Projected Outcomes' })
    }

    return (
        <div className={"page what-if" + (study.noHeader ? ' no-header' : '') + (study.noEditor ? ' no-editor' : '')}>
            <div className="content" style={{borderTop:'1px solid #cfcfcf'}}>
                <div className={`inner-content1`}>
                    <div className='editor-header'>
                        <div className='h2'>Scenario</div>
                    </div>

                    <div className='outcomes-header'>
                        <div className='h2'>Outcomes</div>
                    </div>

                    <div className='headers-combined'>
                        <div className='h2'>
                            <span className='tab-buttons'>
                                <span className={'tab-button' + (tab === 'scenario' ? ' sel' : '')}
                                    onClick={() => setTab('scenario')}
                                >
                                    Scenario
                                </span>
                                <span className={'tab-button' + (tab === 'outcomes' ? ' sel' : '')}
                                    onClick={() => setTab('outcomes')}
                                >
                                    Outcomes
                                </span>
                            </span>
                        </div>
                    </div>

                    <div className={'editor' + (tab === 'scenario' ? ' sel' : '')}>
                        <ScenarioEditor />
                    </div>

                    <div className={'outcomes' + (tab === 'outcomes' ? ' sel' : '')}>
                        {studyId === 'xhigher-ed' ? null :
                            <ProjectedOutcomesPane
                                selectedTag={selectedTag}
                                loadData={(id) => {
                                    history.push(`/study/${studyId}/what-if/${id}`)
                                    setSelectedTag(id)
                                }}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}










// export class WhatIf extends React.Component {

//     constructor(props){
//         super(props);
//         this.state = {
//             tab: 'scenario',
//             selectedTag: this.props.match.params.sectionId || 'projected-outcomes'
//         }
//     }

//   render() {
//     if (this.props.config == null) return null; // eslint-disable-line eqeqeq
//     let studyId = this.props.uid;

    
//     let breadCrumbElements = [];
//     {
//         breadCrumbElements.push({ label: 'What if' });
//         if( this.state.selectedTag === 'all-else-equal' ){
//             breadCrumbElements.push({ label: 'Feature Impact' });
//         }
//         else{
//             breadCrumbElements.push({ label: 'Projected Outcomes' });
//         }
//       }
      
//     //don't delete
//     //    {!this.props.noHeader ? 
//     //             <PageHeader2 title={<BreadCrumb elements={breadCrumbElements}/>}
//     //                 fullWidth={true}
//     //             />
//     //         : null}

//     return (
//         <div className={"page what-if" + (this.props.noHeader ? ' no-header' : '') + (this.props.noEditor ? ' no-editor' : '')}>

//             <div className="content" style={{borderTop:'1px solid #cfcfcf'}}>
//                 <div className={`inner-content1`}>

//                     <div className='editor-header'>
//                         <div className='h2'>
//                             Scenario
//                         </div>
//                     </div>

//                     <div className='outcomes-header'>
//                         <div className='h2'>
//                             Outcomes
//                         </div>
//                     </div>

//                     <div className='headers-combined'>
//                         <div className='h2'>
//                         <span className='tab-buttons'>
//                             <span className={'tab-button' + (this.state.tab === 'scenario' ? ' sel' : '')}
//                                 onClick={(ev) => this.setState({ tab: 'scenario' })}
//                             >
//                                 Scenario
//                             </span>
//                             <span className={'tab-button' + (this.state.tab === 'outcomes' ? ' sel' : '')}
//                                 onClick={(ev) => this.setState({ tab: 'outcomes' })}
//                             >
//                                 Outcomes
//                             </span>
//                         </span>
//                         </div>
//                     </div>

//                     <div className={'editor' + (this.state.tab === 'scenario' ? ' sel' : '')}>
//                         <ScenarioEditor />
//                     </div>

//                     <div className={'outcomes' + (this.state.tab === 'outcomes' ? ' sel' : '')}>
//                         {studyId == 'xhigher-ed' ? null :
//                             <ProjectedOutcomesPane selectedTag={this.state.selectedTag} loadData={(id) => {
//                                 this.props.history.push(`/study/${studyId}/what-if/${id}`);
//                                 this.setState({ selectedTag: id });
//                                 }}
//                             />
//                         }
//                     </div>


//                 </div>

//             </div>
//       </div>
//     );
//   }
// }

// WhatIf = withRouter(
//   connect(
//     state => state.study,
//     null// dispatch => bindActionCreators(actionCreators, dispatch)
//   )(WhatIf)
// );
