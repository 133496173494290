import * as React from 'react';
import Textarea from 'react-expanding-textarea';
import * as debounce from '../../utils/debounce';
import { Markdown } from '../common/Markdown';
import { SelectionBlockImage } from '../product-drawer/SelectionBlockImage';
import './QuestionCover.scss';

export class QuestionCover extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            question: props.question,
            image: props.image,
            photo: props.photo,
            label: props.selectionLabel,
        }
    }

   

    static inheritOptions = ['selection'];

    handleQuestionChange = (ev) => {
        this.setState({ question: ev.target.value }, () => {
            this.triggerCallback();
        });
    }

    triggerCallback = debounce.debounce(() => {
        if( this.props.onChange ){
            this.props.onChange(this.state);
        }
    }, debounce.insightsTextDelay);

    handleSelectionClick = () => {

        const selection = {
            image: this.props.image,
            photo: this.props.photo,
            label: this.props.selectionLabel
        };
        const options = {
            allow: ['brand', 'model', 'product'],
            multiSelect: false
        };

        window.topBar.openProducts(
            selection,
            (sel) => {
                //console.log('newSel', sel);
                this.setState({
                    image: sel.image,
                    photo: sel.photo,
                    label: sel.label
                }, () => {
                    window.topBar.hideMenu();
                    this.triggerCallback();
                })
            },
            options
        )

    }

    render() {
        const editMode = this.props.editMode;

        return <div className='widget question-cover-widget'>
            <div className='widget-body'>
                <div className='selection-section-v2'>
                    <SelectionBlockImage
                        disabled={!editMode}
                        name={'storyImage'}
                        emptyText={editMode ? "select cover image" : null}
                        src={this.props.image}
                        onSelect={(sel) => {
                        this.setState({
                            image: sel.image,
                            photo: null,
                            label: sel.label
                        }, () => {
                            this.triggerCallback();
                        })
                    }} />
                </div>
                
                
                
                <div className='question'>
                    {editMode ? 
                        <Textarea className='question-input' 
                            placeholder='Type question here'
                            value={this.state.question || ''} 
                            onChange={this.handleQuestionChange}
                            /> 
                        //: this.props.question
                        : <Markdown data={this.props.question} />
                    }
                </div>
                <div className='answer'>
                    {this.props.answer}
                </div>
            </div>
        </div>;

    }

}

