import * as hash from "object-hash";
import {
	//COMPARE_GROUPS_REQEST,
	//COMPARE_SET_CTYPE,
	COMPARE_ALL_DESELECT,
	COMPARE_FOLDER_DESELECT,
	COMPARE_FOLDER_EXPAND,
	COMPARE_GROUPS_RECEIVE,
	COMPARE_GROUP_COLLAPSE_ALL,
	COMPARE_GROUP_EXPAND_ONLY_SEARCHED_TERM,
	//COMPARE_FOLDER_SELECT,
	//COMPARE_GROUP_DESELECT,
	COMPARE_GROUP_SELECT,
	COMPARE_SET,
	STUDY_RESET,
	STUDY_RECEIVE,
	// @ts-ignore
} from "./action-types";

export interface CompareState {
	folders: any[];

	isLoading: boolean;

	selection: any;

	defaultFilterLabel: string;
}

const getSelection = (folders: any) => {
	const groups = folders.reduce((accum: any, folder: any) => {
		const selGroups = folder.groups.filter((group: any) => group.isSelected);
		return accum.concat(selGroups);
	}, []);

	let selection: any = {
		label:
			groups.length === 0
				? "[select groups]"
				: groups.length === 1
				? groups[0]?.label
				: groups.length === 2
				? groups[0]?.label + " vs. " + groups[1]?.label
				: groups[0]?.label +
				  ", " +
				  groups[1]?.label +
				  ` and ${groups.length - 2} other group(s)`,
		groups,
	};
	selection.hash = hash(selection);
	return selection;
};

const initialState: CompareState = {
	folders: [],
	isLoading: false,
	selection: getSelection([]),
	defaultFilterLabel: "Total Population",
};

export const actionCreators = {
	expandFolder: (id: string) => (dispatch: any) =>
		dispatch({ type: COMPARE_FOLDER_EXPAND, id, expanded: true }),

	collapseFolder: (id: string) => (dispatch: any) =>
		dispatch({ type: COMPARE_FOLDER_EXPAND, id, expanded: false }),

	selectGroup: (folderId: string, groupId: string) => (dispatch: any) =>
		dispatch({ type: COMPARE_GROUP_SELECT, folderId, groupId, selected: true }),

	deselectGroup: (folderId: string, groupId: string) => (dispatch: any) =>
		dispatch({
			type: COMPARE_GROUP_SELECT,
			folderId,
			groupId,
			selected: false,
		}),

	deselectFolder: (folderId: string) => (dispatch: any) =>
		dispatch({ type: COMPARE_FOLDER_DESELECT, folderId }),

	deselectAll: () => (dispatch: any) =>
		dispatch({ type: COMPARE_ALL_DESELECT }),

	expandOnlySearchedFolder: (term: any) => (dispatch: any) =>
		dispatch({ type: COMPARE_GROUP_EXPAND_ONLY_SEARCHED_TERM, term }),

	collapseAllGroups: () => (dispatch: any) =>
		dispatch({ type: COMPARE_GROUP_COLLAPSE_ALL }),

	// setCompare: compare => (dispatch) =>
	//     dispatch({ type: COMPARE_SET_CTYPE, value: compare })
};

export const reducer = (
	state: CompareState = initialState,
	action: any,
): CompareState => {
	switch (action.type) {
		case STUDY_RESET:
			return initialState;

		case STUDY_RECEIVE:
			return {
				...state,
				defaultFilterLabel:
					action.value?.defaultFilterLabel || state.defaultFilterLabel,
			};

		case COMPARE_GROUP_EXPAND_ONLY_SEARCHED_TERM:
			return {
				...state,
				folders: state.folders.map((folder) => {
					if (folder.label.toLowerCase().includes(action.term.toLowerCase()))
						return {
							...folder,
							isExpanded: true,
							matchedGroup: true,
							groups: folder.groups.map((g: any) => ({
								...g,
								matched: true,
							})),
						};
					return {
						...folder,
						isExpanded:
							folder.groups.filter((g: any) =>
								g.label.toLowerCase().includes(action.term.toLowerCase()),
							).length > 0,
						matchedGroup:
							folder.groups.filter((g: any) =>
								g.label.toLowerCase().includes(action.term.toLowerCase()),
							).length > 0,
						groups: folder.groups.map((g: any) =>
							g.label.toLowerCase().includes(action.term.toLowerCase())
								? { ...g, matched: true }
								: { ...g, matched: false },
						),
					};
				}),
			};

		case COMPARE_GROUP_COLLAPSE_ALL:
			return {
				...state,
				folders: state.folders.map((folder) => ({
					...folder,
					isExpanded: false,
					groups: folder.groups.map((g: any) => ({ ...g, matched: false })),
				})),
			};

		case COMPARE_GROUPS_RECEIVE:
			return {
				...state,
				folders: action.folders,
				isLoading: false,
				selection: getSelection(action.folders),
			};

		case COMPARE_FOLDER_EXPAND:
			return {
				...state,
				folders: state.folders.map((folder) => ({
					...folder,
					isExpanded:
						folder.id === action.id ? action.expanded : folder.isExpanded,
				})),
			};

		case COMPARE_SET: {

			// note: right now, this only works with structured selections
			// one day if we allow more custom filtering, this will need to be enhanced

			let s = {
				...state,
				selection: action.selection,
				folders: state.folders.map((folder) => {
					if (action.selections) {
						let findFolder = action.selections?.find((sel: any) => sel.folderId === folder.id);
						// note: even if findFolder==null, we still want to make sure all filters are cleared
						let groups = folder.groups.map((g: any) => {
							let findGroup = findFolder?.selectedFilterIds?.find((fid: any) => fid === (g.id || g.syntax));
							if (findGroup) {
								return {
									...g,
									isSelected: true
								};
							}
							else {
								let r = {
									...g,
									isSelected: undefined
								};
								delete r.isSelected;
								return r;
							}
						})
						return { ...folder, groups };
					}
					else
						return folder;
				})
			}
			s.selection = getSelection(s.folders);
			return s;
		}
		
		case COMPARE_GROUP_SELECT: {
			if (action.groupId == null) {
				// select all
				let ret = {
					...state,
					folders: state.folders.map((folder) =>
						folder.id === action.folderId
							? {
									...folder,
									allSelected: action.selected,
							  }
							: folder,
					),
				};
				ret.selection = getSelection(ret.folders);
				return ret;
			} else {
				// select specified
				let ret = {
					...state,
					folders: state.folders.map((folder) =>
						folder.id === action.folderId
							? {
									...folder,
									groups: folder.groups.map((g: any) =>
										(g.id || g.syntax) === action.groupId
											? { ...g, isSelected: action.selected }
											: g,
									),
							  }
							: folder,
					),
				};
				ret.selection = getSelection(ret.folders);
				return ret;
			}
		}

		case COMPARE_FOLDER_DESELECT: {
			let ret = {
				...state,
				folders: state.folders.map((folder) =>
					folder.id === action.folderId
						? {
								...folder,
								allSelected: false,
								isExpanded: false, // collapse folder
								groups: folder.groups.map((g: any) => ({
									...g,
									isSelected: false,
								})),
						  }
						: folder,
				),
			};
			ret.selection = getSelection(ret.folders);
			return ret;
		}

		case COMPARE_ALL_DESELECT: {
			let ret = {
				...state,
				folders: state.folders.map((folder) => ({
					...folder,
					allSelected: false,
					isExpanded: false, // collapse set
					groups: folder.groups.map((g: any) => ({
						...g,
						isSelected: false,
					})),
				})),
			};
			ret.selection = getSelection(ret.folders);
			return ret;
		}
	}

	return state;
};
