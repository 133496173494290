import { Models } from "openai/resources"
import * as globals from "../globals"
import * as apiService from "../utils/apiService"
import { getMeta } from '../utils/metaUtils'
import { actionCreators as GlobalStoreCreator } from '../store/General'
import { actionCreators as FilterActionCreator } from '../store/Filter'
import {
	STUDY_RESET,	
	FORD_FORECAST,
	FORD_FORECAST_REQUEST,
	FORD_UPDATE_FORECAST,
	CLEAR_FORECASTS
	// @ts-ignore
} from "./action-types"

export interface FordForecastState {
	[key: string]: any
}

const initialState: FordForecastState = {}

// const hackConfig = (config: any) => {
// 	return {
// 		...config,
// 		price: (config.price - 0.32) * 3000,
// 		cashback: config.cashback * 400,
// 	}
// }

export const actionCreators = {
	// forecast the loaded scenario
	
	ford_forecast: (_filter: any, callback: any, filterDatasetId: any, modelName: any) => async (dispatch: any, getState: any) => {

		// console.log("ford_forecast modelName", modelName);

		dispatch(GlobalStoreCreator.setGlobalStore('forecast_needed', false))
		dispatch(GlobalStoreCreator.setGlobalStore('forecast_running', true))
		
		// we are supporting passing in a single filter or multiple
		let modelFilters: any = _filter;
		if (!Array.isArray(modelFilters)) {
			modelFilters = [modelFilters]
		}

		
		const state = getState()
		const auth = state.auth;
		const studyId = state.study.uid
		const model = state.model
		const selfilter = filterDatasetId ? state.filter.selected?.[filterDatasetId] : state.filter.selectedFilter

		
		let assump = state.general?.custom?.ford_ass;
		if (!assump) {
			assump = await getMeta(studyId, auth, "fordAssumptionsV2");
		}
		
		const activeConfig = modelName || model.activeConfig
		
		const config = model[activeConfig]?.config
		// const fModel = await getMeta(studyId, state.auth, model[activeConfig].modelInfo.name)
		if (!config) {
			console.log("cancelling forecast due to config not found")
			return;
		}

		const filterName = selfilter?.name;


		const modelInfo_copy = JSON.parse(JSON.stringify(model[activeConfig]?.modelInfo));

		// get totalMarketVolume from general.custom
		{
			let totalMarketVolume = -1;
			const year =
				(activeConfig === "dcm_ml_truck_2024" || activeConfig == "dcm_ml_suv_2024") ? 2024 :
				(activeConfig === "dcm_ml_truck_2025" || activeConfig == "dcm_ml_suv_2025") ? 2025 :
				(activeConfig === "dcm_ml_truck_2026" || activeConfig == "dcm_ml_suv_2026") ? 2026 :
				-1;
			
			
			
			
			if (["dcm_ml_truck_2024", "dcm_ml_truck_2025", "dcm_ml_truck_2026"].includes(activeConfig)) {
				//totalMarketVolume = state.general.custom?.pickups_marketsize?.[year] || modelInfo_copy.totalMarketVolume
				totalMarketVolume = assump[`dcm_tam_consideration_${year}`]?.Pickup_Truck_Demand
			}
			else {
				//totalMarketVolume = state.general.custom?.suvs_marketsize?.[year] || modelInfo_copy.totalMarketVolume
				totalMarketVolume = assump[`dcm_tam_consideration_${year}`]?.SUV_Segment_Demand
			}
			modelInfo_copy.totalMarketVolume = totalMarketVolume;
		}
		// console.log("modelInfo_copy", modelInfo_copy);


		// Prepare the query
		const _query = {
			studyId: studyId,
			//uFilter: modelfilter.syntax, //selfilter.name === 'total' ? modelfilter.syntax : (selfilter.syntax + ' AND ' + modelfilter.syntax),
			uFilterName: filterName,
			modelInfo: modelInfo_copy,// model[activeConfig]?.modelInfo,
			config: config.play,
			brandvalues: config.brandvalues || [],
			//etag: config.etag,
			playHash: config.playHash
		}

		dispatch({
			type: FORD_FORECAST_REQUEST,
			modelName: activeConfig,
			playHash: config.playHash,
			//filterSyntax: query.uFilter,
			filterName: filterName,
		})
		
		//console.log('FORECASTER query', query)
		// dispatch(FilterActionCreator.selectFilter('default', query.uFilter, query.uFilterName))
		// console.log('query', query)


		const url = `${globals.apiRoot}/compute/model`
		try {

			let modelData: any = null;

			for (let modelFilter of modelFilters) {

				const query = {
					..._query,
					uFilter: modelFilter.syntax
				}

				const response = await apiService.aPost(state.auth, url, query)
				let data = await response.json()

				data = data?.[filterName];

				if (data && modelFilter.productIds?.length) {
					const newData: any = {};
					for (let uid of modelFilter.productIds) {
						newData[uid] = data[uid];
					}
					data = newData;
				}

				if (data) {
					modelData = {
						...modelData,
						...data
					}
				}
				// console.log('forecast response:', resp);			
				
			}

			
			if (modelData) {
				
				//store this in forecaster.fordcollection
				await dispatch({
					type: FORD_FORECAST,
					data: {
						[filterName]: modelData
					},
					playHash: config.playHash,
					//filtersyntax: query.uFilter,
					filtername: filterName,
					activeConfig,
					products: config.play
				})
				callback && callback(true)

				dispatch(GlobalStoreCreator.setGlobalStore('forecast_running', false))
			
			}
			else {
				// const errorText = await response.text(); // Get the error message as text
					
				callback && callback(false, "Forecast request failed:")//, errorText)
				console.error("Forecast request failed:")//, errorText);
				// dispatch({
				// 	type: "FORECAST_RECEIVE",
				// 	error: response.statusText || "error",
				// 	value: null,
				// 	etag: cfgmodel.etag,
				// 	filtername: query.uFilterName,
				// 	model: activeConfig
				// });
					
			}
		} catch (err: any) {
			callback && callback(false, "Error during forecast computation:", err)
			console.error("Error during forecast computation:", err)
			// dispatch({
			// 	type: "FORECAST_RECEIVE",
			// 	error: err.message,
			// 	value: null,
			// 	etag: cfgmodel.etag,
			// 	filtername: query.uFilterName,
			// 	model: activeConfig
			// })
				
		}
	},

	clearFordForecasts: () => async (dispatch: any, getState: any) => {
			dispatch({ type: CLEAR_FORECASTS})

	},

	updateForecaster: (data: any) => async (dispatch: any, getState: any) => {
		console.error("shouldn't call updateForecaster")
		// if (data) {
		// 	dispatch({ type: FORD_UPDATE_FORECAST, data})
		// }
	}
}

	

export const reducer = (state: FordForecastState = initialState, action: any) => {

	switch (action.type) {
		case STUDY_RESET:
			return initialState

		case FORD_FORECAST_REQUEST:

			const { modelName, filterName, playHash } = action;
			
			return {
				...state,
				[modelName]: {
					...state[modelName],
					[filterName]: {
						...state[modelName]?.[filterName],
						//running: true,
						runningPlayHash: playHash
					}
				}
			}
		
		// case FORD_UPDATE_FORECAST:
		// 	const { data } = action
		// 	return {
		// 		...state,
		// 		...data				
		// 	}
		
		case FORD_FORECAST: {
			const { data, playHash, filtersyntax, filtername, products } = action

			const modelName = action.activeConfig;

			
			
			let results: any = {}

			let prevResults = null;  

			// Extract the numerical value from modelname (e.g., "model1" becomes 1)
			if (filtersyntax?.includes('model')) {

				// this should be obsolete now

				prevResults = state[modelName]?.[filtername];

				const modelNumberMatch = filtersyntax.match(/model(\d+)/)
				const modelNumber = modelNumberMatch ? parseInt(modelNumberMatch[1], 10) : NaN

				//get all products that are suv or pickup
				let prods = products.filter(f => f.model === modelNumber)

				// is this the first time this playHash is getting results? if so, clear the existing data
				const prevPlayHash = state[modelName]?.[filtername]?.playHash
				if (playHash != prevPlayHash) {
					prevResults = null
				}
				
				prods.forEach(prod => {
					if (data[filtername]?.[prod.uid]) {
						results[prod.uid] = data[filtername][prod.uid]
					}
				})
			}
			else
				results = data[filtername]


			return {
				...state,
				[modelName]: {
					...state[modelName],
					[filtername]: {
						//...state[modelName]?.[filtername],
						...prevResults,
						playHash,
						...results 
					}
				}				
			}
		}
			
		case CLEAR_FORECASTS:
            return {}       
			
	}

	
	return state
		
}
