import { format as d3Format } from 'd3-format'
import * as React from 'react'
import { useSelector } from 'react-redux'
// import { withRouter } from 'react-router-dom'
import { ProductBlock } from '../product-drawer/ProductBlock'
import './ScenarioSummary.scss'

const modes = ['changes', 'summary']

export function ScenarioSummary(props) {

    const selection = useSelector(state => state.selection)
    const suvTruck = selection?.suvTruck ?? null
    const model = useSelector(state => state.model)
    const modelsToUse = suvTruck && props.models.suvTruck ? props.models.filter(f => f.suvTruck === suvTruck.uid) : props.models
    const models = modelsToUse.map(m => model[m.name])
    const configModels = modelsToUse.map(m => m.label)
    
    // console.log('config', models);

    if (!models) return null

    const mode = props.changesOnly ? 'changes' : 'summary'


    const generateConfigContent = (mod => {

        let prods = mod.config.play        
        let totalChanges = 0

        const summaryVis = prods?.map((prod,idx) => {
            
            const pdef = mod.config.productDef || {}
            const atts = pdef.atts
                .filter(att => !att.readOnly)
                .sort((a, b) => a.displayIndex - b.displayIndex)

            let nbChanges = 0
            const prod_play = mod.config?.play.find(f => f.uid === prod.uid)
            const prod_base = mod.config?.base.find(f => f.uid === prod.uid)

            const slotVis = <div className='slot' key={prod.uid + idx}>                
                <div className='prod-header' key={prod.uid} >
                    <span>{ prod.label }</span>
                </div>
                <table className='att-table'>
                    <tbody>
                        {[1].map(() => {

                            if ((prod_play.available != prod_base.available) && mode == 'changes') {
                                nbChanges++
                                return <tr className='att' key={'available'}>
                                    <td className='label-cell' colSpan='3'>
                                        <span className='instruction'>
                                            {(prod_play.available ? 'Added to product line' : 'Removed from product line')}
                                        </span>
                                        
                                    </td>
                                </tr>
                                
                            }
                        })}
                        {prod_play.available ? atts.map(att => {

                            const playValue = prod_play[att.name] || att.defaultValue
                            const baseValue = prod_base[att.name] || att.defaultValue

                            const ff = att.type === 'contin' ? d3Format(att.format) : null

                            const playValueString = att.type === 'contin' ? ff(playValue) :
                                att.type === 'discrete' ? (att.levels.find(level => level.value == playValue) || {}).label :
                                    playValue

                            const baseValueString = att.type === 'contin' ? ff(baseValue) :
                                att.type === 'discrete' ? (att.levels.find(level => level.value == baseValue) || {}).label :
                                    baseValue

                            if (mode === 'changes_old') {

                                if (playValueString === baseValueString) return null

                                nbChanges++

                                return <tr className='att show-both' key={att.name}>
                                    <td className='label'>{att.label}</td>
                                    <td className='value'>{playValueString}</td>
                                    <td className='value'>{baseValueString}</td>
                                </tr>

                            }
                            else if (mode === 'changes') {

                                if (playValueString === baseValueString) return null

                                nbChanges++

                                if (att.binary) {
                                    return <tr className='att' key={att.name}>
                                        {prod_play.available != prod_base.available &&
                                            mode == 'changes' ? null : <td className='label-cell' colSpan='3'>
                                            <span className='label row'>{att.label}</span>
                                            <span className='instruction'>
                                                {(playValue == att.binary ? 'Added' : 'Removed')}
                                            </span>
                                        
                                        </td>}
                                    </tr>
                                }
                                else if (att.type === 'contin') {
                                    const diff = playValue - baseValue
                                

                                    return (
                                        <tr className='att' key={att.name}>
                                            {prod_play.available != prod_base.available &&
                                                mode == 'changes' ? (
                                                <td className='label-cell' colSpan='3'>
                                                    <span className='label row'>{att.label}</span>
                                                    <span className='valuestr1'>
                                                        {`${playValueString} `}
                                                    </span>
                                                </td>
                                            ) : (
                                                <td className='label-cell' colSpan='3'>
                                                    <span className='label row'>{att.label}</span>
                                                    <span className='instruction'>
                                                        {diff < 0 ? 'Decreased ' : 'Increased '}
                                                    </span>
                                                    <span className='instruction'>{`from `}</span>
                                                    <span className='valuestr1'>
                                                        {`${baseValueString} `}
                                                    </span>
                                                    <span className='instruction'>{`to `}</span>
                                                    <span className='valuestr1'>
                                                        {`${playValueString} `}
                                                    </span>
                                                    <span className='instruction'>(</span>
                                                    <span className='valuestr1'>
                                                        {diff > 0 ? '+' : ''}
                                                        {ff(diff)}
                                                    </span>
                                                    <span className='instruction'>)</span>
                                                </td>
                                            )}
                                        </tr>
                                    )
                                } else {
                               
                                    return (
                                        <tr className='att' key={att.name}>
                                            {prod_play.available != prod_base.available &&
                                                mode == 'changes' ? (
                                                <td className='label-cell' colSpan='3'>
                                                    <span className='label row'>{att.label}</span>
                                                    <span className='valuestr1'>
                                                        {playValueString}
                                                    </span>
                                                </td>
                                            ) : (
                                                <td className='label-cell' colSpan='3'>
                                                    <span className='label row'>{att.label}</span>
                                                    <span className='instruction'>
                                                        {`Changed `}
                                                    </span>
                                                    <span className='instruction'>{`from `}</span>
                                                    <span className='valuestr1'>
                                                        {`${baseValueString} `}
                                                    </span>
                                                    <span className='instruction'>{`to `}</span>
                                                    <span className='valuestr1'>
                                                        {`${playValueString} `}
                                                    </span>
                                                </td>
                                            )}
                                        </tr>
                                    )
                                }
                            }
                            else if (mode === 'summary') {

                                return <tr className='att' key={att.name}>
                                    <td className='label-cell'>
                                        <span className='label row'>{att.label}</span>
                                    </td>
                                    <td className='value'>{playValueString}</td>
                                </tr>

                            }
                        
                        
                        }) : null}
                    </tbody>
                </table>
            </div>

            if (nbChanges > 0) {
                totalChanges++
            }

            if ((mode === 'summary' && prod_play.available) ||
                (mode === 'changes' && nbChanges > 0)
            ) {
                return slotVis
                // return (
                //     <React.Fragment>
                //         {prodVis}
                //         {slotVis}
                //     </React.Fragment>
                // )
            }
            else
                return null
        
        })



        const brandEquitiesDef = mod.config.brandEquitiesDef || { entries: [] }

        // console.log('mod', mod.config);
        // console.log('mod', mod.config.play);
        
        let brandEquityLevelAtt = brandEquitiesDef.level === 'product' ? {
            name: 'product',
            label: props.productLabel,
            levels: mod?.config?.play?.map(prod => ({
                value: prod.uid,
                label: prod.label,
                image: prod.image
            }))
        }
            : { ...mod.config.productDef.atts.find(att => att.name === brandEquitiesDef.level) }
            || {
                name: 'error',
                label: 'Error',
                levels: []
            }
            

        if (mode === 'summary') {
            // filter levels that don't exist in current slots
            //console.log(slots, brandEquityLevelAtt)
            brandEquityLevelAtt.levels = brandEquityLevelAtt.levels.filter(level =>
                prods.find(prod =>
                    (brandEquitiesDef.level === 'product' && prod.uid === level.value)
                    || prod_play[brandEquitiesDef.level] == level.value
                )
            )
        }

        const brandEquities = mod.config.brandEquities

        const bef = d3Format('.2f')
        const bef2 = d3Format('+.2f')
        
        const getBrandEquitiesArea = (def, levelEquities, area, areaLabel) => {
            const entries = def.entries
                .filter(entry => entry.area === area)
                .map(entry => {
                    
                    const { play: playValue, base: baseValue } = levelEquities[entry.name]
                    if (mode === 'summary') {
                        return <tr className='att message-layout' key={entry.name}>
                            <td className='label-cell'>
                                <span className='label'>
                                    {entry.category}: {entry.label}
                                </span>
                            </td>
                            <td className='value'>
                                {bef(playValue)}
                            </td>
                        </tr>

                    }
                    else {
                    
                        return (playValue != baseValue) ?
                            <tr className='att' key={entry.name}>
                                <td className='label-cell'>
                                    <div className='label'>
                                        {entry.category}: {entry.label}
                                    </div>
                                    <span className='instruction'>Changed from </span>
                                    <span className='valuestr1'>{bef(baseValue)}</span>
                                    <span className='instruction'> to </span>
                                    <span className='valuestr1'>{bef(playValue)}</span>
                                    <span className='instruction'>(</span>
                                    <span className='valuestr1'>{bef2(playValue - baseValue)}</span>
                                    <span className='instruction'>)</span>
                                </td>
                            </tr>
                            : null
                    }
                }).filter(x => x)
        
            return entries.length > 0 ? <div className='area' key={area}>
                <div className='title'>{areaLabel}</div>
                <table className='att-table'>
                    <tbody>
                        {entries}
                    </tbody>
                </table>
                
            </div> : null
        }

        // create obj for each level
        const sliderVis = brandEquityLevelAtt?.levels?.map(level => {

            const levelKey = brandEquitiesDef.level === 'product' ? level.value :
                brandEquitiesDef.level + ':' + level.value
            const equities = brandEquities[levelKey]

            const message = getBrandEquitiesArea(brandEquitiesDef, equities, 'message', 'Message')
            const brand = getBrandEquitiesArea(brandEquitiesDef, equities, 'brand', 'Brand')

            if (message || brand) {
                totalChanges++
                return <div className='slot' key={levelKey}>

                    <ProductBlock
                        label={level.label}
                        image={level.image}
                        disabled={true}
                        typeLabel={brandEquityLevelAtt.label + (mode === 'summary' ? ' Summary' : ' Changes')}
                    />
                    
                    {message}
                    {brand}
                </div>
            }
            return null

        })

         return (
             <div key={mod.modelInfo.name} className='config-model-section'>                   
                 <h2>{mod.modelInfo.name.slice(mod.modelInfo.name.length - 4, mod.modelInfo.name.length)}</h2> 
                {summaryVis}                
            </div>
        )
        
    })
    
            
    return (
        <div className='scenario-summary'>
            {models.map(mod => generateConfigContent(mod))}
        </div>
    )
    
}

