import * as React from 'react';
import * as debounce from '../../../utils/debounce';
import './EditSlider2.scss';

/**
 * this class uses the state/store in a tricky way. the state is used so ui feedback
 * is fast, while store is updated behind the scenes.
 *
 * */
export class EditSlider2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playValue: props.playValue,
            baseValue: props.baseValue
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if( this.props.playValue != nextProps.playValue || this.props.baseValue != nextProps.baseValue ){
            this.setState({
                playValue: nextProps.playValue,
                baseValue: nextProps.baseValue
            })
        }
    }

    // This function is only called from the up/down buttons. Not dragging of the slider. It probably doesn't need debounce.
    adjust = (amount) => {
        let newVal = parseFloat(this.state.playValue) + amount;
        newVal = Math.round(newVal * 10.0) / 10.0;
        if (newVal < 1) newVal = 1;
        if (newVal > 7) newVal = 7;
        this.setValue(newVal);
    }

    handleChange = (ev) => {
        this.setValue(ev.target.value);
    }

    setValue = (newVal) => {
        this.setState({
                playValue: newVal
            },
            () => {
                this.triggerCallback();
            }
        )
    }

    triggerCallback = debounce.debounce(() => {
        if( this.props.onChange ){
            this.props.onChange(this.state.playValue);
        }
    }, debounce.sliderDelay);

    render() {
        
        let val = parseFloat(this.state.playValue || -1);

        return (
            <div className={"slider"}>

                <div className="value">
                    <span className="val-input">{val.toFixed(2)}</span>

                    <button className="spin-up" onClick={() => this.adjust(0.1)}>
                        <i className="fal fa-plus" />
                    </button>

                    <button className="spin-down" onClick={() => this.adjust(-0.1)}>
                        <i className="fal fa-minus" />
                    </button>
                </div>

                <div className="slider-box">
                    <input
                        type="range"
                        className="slider"
                        min="1"
                        max="7"
                        step="0.1"
                        value={val}
                        onChange={this.handleChange}
                    />
                </div>

            </div>
        );
    }
}

