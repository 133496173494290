import * as React from 'react';
import { useEffect } from 'react';
import * as d3_core from 'd3';
import { Symbols, Surface, filterEventsOfChild, classNames } from 'recharts';

const d3 = { ...d3_core };

const CustomLegend = (props) => {
	// console.log("props", props)
	const { payload, layout, align } = props;
	const SIZE = props?.size || 20;

	if (!payload || !payload.length) { return null; }
	const finalStyle = {
		padding: 0,
		margin: 0,
		textAlign: layout === 'horizontal' ? align : 'left',
	};

	const renderIcon = (data) => {
		const { inactiveColor } = props;
		const halfSize = SIZE / 2;
		const sixthSize = SIZE / 6;
		const thirdSize = SIZE / 3;
		const color = data.inactive ? inactiveColor : data.color;

		if (data.type === 'plainline') {
			return (
				<line
					strokeWidth={2}
					fill="none"
					stroke={color}
					strokeDasharray={data?.payload?.strokeDasharray}
					x1={0}
					y1={halfSize}
					x2={SIZE}
					y2={halfSize}
					className="recharts-legend-icon"
				/>
			);
		} if (data.type === 'line') {
			return (
				<path
					strokeWidth={4}
					fill="none"
					stroke={color}
					d={`M0,${halfSize}h${thirdSize}
				A${sixthSize},${sixthSize},0,1,1,${2 * thirdSize},${halfSize}
				H${SIZE}M${2 * thirdSize},${halfSize}
				A${sixthSize},${sixthSize},0,1,1,${thirdSize},${halfSize}`}
					className="recharts-legend-icon"
				/>
			);
		} if (data.type === 'rect') {
			return (
				<path
					stroke="none"
					fill={color}
					d={`M0,${SIZE / 8}h${SIZE}v${SIZE * 3 / 4}h${-SIZE}z`}
					className="recharts-legend-icon"
				/>
			);
		}

		return (
			<Symbols
				fill={color}
				cx={halfSize}
				cy={halfSize}
				size={SIZE}
				sizeType="diameter"
				type={data.type}
			/>
		);
	}


	const renderItems = () => {
		const { payload, iconSize, layout, formatter } = props;

		const itemStyle = {
			display: layout === 'horizontal' ? 'inline-block' : 'block',
			marginRight: 10,
		};
		const svgStyle = { display: 'inline-block', verticalAlign: 'middle' };

		return payload.map((entry, i) => {
			const finalFormatter = entry.formatter || formatter;

			//   const className = classNames({
			// 	'recharts-legend-item': true,
			// 	[`legend-item-${i}`]: true,
			// 	inactive: entry.inactive,
			//   });

			if (entry.type === 'none') {
				return null;
			}
			let svgWidth, svgHeight;

			var svgNode;
			useEffect(() => {
				calSVGWidth();
			}, [svgNode, entry])

			const calSVGWidth = () => {
				var nodeWidth = (d) => d.getBBox().width;
				var nodeHeight = (d) => d.getBBox().height;
				let svg = d3.select(svgNode);
				svg.attr('width', function (d, i) {
					svgWidth = nodeWidth(this);
					return svgWidth;
				});
				svg.attr('height', function (d, i) {
					svgHeight = nodeHeight(this)
					return svgHeight+2;
				});
			}


			return (
				<li
					//   className={className}
					style={itemStyle}
					key={`legend-item-${i}`}
				//   {...filterEventsOfChild(props, entry, i)}
				>
					<svg style={svgStyle} ref={node => svgNode = node}>
						<g transform="scale(0.75)">
							{entry?.type ? renderIcon(entry) : null}
							<text fontSize={iconSize} x={entry?.type ? SIZE + 5: 0} y={14} alignmentBaseline="alphabetic">
								{finalFormatter ? finalFormatter(entry.value, entry, i) : entry.value}
							</text>
						</g>
					</svg>
				</li>
			);
		});
	}


	return (
		<ul className="recharts-default-legend" style={finalStyle}>
			{renderItems()}
		</ul>
	);


}

export default CustomLegend;