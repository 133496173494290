export const aGet = (auth, url) => fetch(url, {
    method: 'get',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(auth ? { Authorization: 'Bearer ' + auth.token } : null)
    }
});

export const aGetExternalApi = (url, token) => fetch(url, {
    method: 'get',
    mode: 'no-cors',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }
});

export const aPut = (auth, url) => fetch(url, {
    method: 'put',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(auth ? { Authorization: 'Bearer ' + auth.token } : null)
    }
});


export const aPost = (auth, url, body, headers = null) => fetch(url, {
    method: 'post',
    body: JSON.stringify(body),
    timeout: 300000, //5 min (forecaster)
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(auth ? { Authorization: 'Bearer ' + auth.token } : null),
        ...headers
    }
});

export const aPostFile = (auth, url, body) => fetch(url, {
    method: 'post',
    body: JSON.stringify(body),
    headers: {
        // Accept: 'application/json',
        // 'Content-Type': 'application/json',
        ...(auth ? { Authorization: 'Bearer ' + auth.token } : null)
    }
});


export const aPatch = (auth, url, body) => fetch(url, {
    method: 'post', // note: i haven't been able to get patch to pass the server's CORS policy, so I'm using post for now
    body: JSON.stringify(body),
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(auth ? { Authorization: 'Bearer ' + auth.token } : null)
    }
});

export const aDelete = (auth, url) => fetch(url, {
    method: 'delete',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(auth ? { Authorization: 'Bearer ' + auth.token } : null)
    }
});

