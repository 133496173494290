import { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'  
import { bindActionCreators } from 'redux'
import { actionCreators as modelActionCreators } from '../../store/Model'
import { actionCreators as fordForecastActionCreators } from '../../store/FordForecast'
import { actionCreators as selectionsActionCreators } from '../../store/Selections'
import { actionCreators as GlobalStoreCreator } from "../../store/General"
import { Tab_buttons, TabStrip2 } from '../common/TabStrip.js'
import { ToggleSwitch } from '../common/ToggleSwitch.js'
import { EditConstraints } from '../optimize/EditConstraints.js'
import { EditSliderConstraints } from '../optimize/EditSliderConstraints.js'
import { ProductBlock } from '../product-drawer/ProductBlock.js'
import { EditAreaAtts2 } from './EditAreaAtts2.js'
import { EditAreaSliders } from './EditAreaSliders.js'
import './ScenarioEditor.scss'
import { ScenarioSummary } from './ScenarioSummary.js'
import { OpenSaveScenario } from '../scenarios/OpenSaveScenario.js'
import { SelectionBlockElement } from '../explore/SelectionBlockElement.js'
import { FilterChooser } from '../filter/FilterChooser'
import { debounce } from '../../utils/debounce'
import { computeFordModel } from '../../utils/forecastHelper'
import { active } from 'd3-transition'
import { guid } from '../../utils/guid.js'
import * as metaUtils from '../../utils/metaUtils'
import * as forecastHelper from '../../utils/forecastHelper'
import { json } from 'd3'
import { CloneProduct } from '../common/CloneProduct.js'


export const ScenarioEditorMultiConfigs2 = (props) => {

    const [selectedTabValue, setSelectedTabValue] = useState('');
    const [summaryChangesOnly, setSummaryChangesOnly] = useState(true);
    //const [forecastRunning, setForecastRunning] = useState(false);
    const [isLoadingTam, setIsLoadingTam] = useState(false)
    const dispatch = useDispatch();

    const { study, auth, model, filter, selection, general, modelResults } = useSelector(state => ({
        study: state.study,
        auth: state.auth,
        model: state.model,
        selection: state.selection,
        filter: state.filter,
        general: state.general.custom,
        modelResults: state.forecaster?.fordForecast
    }))

    const elevenAttNames = ['totalmodels', 'availrefuel', 'servicecenter', 'degrade', 'warranty', 'assist', 'repaircost', 'insurecost', 'interest', 'timecharge', 'fastcharger']
    const suvTruck = useSelector(state => state.selection.suvTruck);
    const suvTruckId = suvTruck?.uid || 1;
    const studyId = study.uid;

    const fordSelector = useMemo(() => {
        return suvTruck?.name === 'suv' ? selection?.ml_suvs : selection?.ml_trucks;
    }, [suvTruck, selection]);

    const filtersDatasetId = props.filtersDatasetId
    

    useEffect(() => {
        if (!props.models?.length) return

        const am = props.models.find(f => f.name === general['activeModel']);
        const st = props.models.find(f => f.suvTruck === suvTruckId);

        let defaultTab;

        if (suvTruckId === 1 && am?.name.includes('suv')) {
            defaultTab = am;
        } else if (suvTruck?.uid === 2 && am?.name.includes('truck')) {
            defaultTab = am;
        } else {
            defaultTab = st;
        }
        setSelectedTabValue(defaultTab?.label)
        dispatch(modelActionCreators.setActiveConfig(defaultTab.name))    
    }, [suvTruck, selection])
    
    
    const activeConfig = model?.activeConfig
    const _config = model?.[activeConfig]?.config;
    const _filter = filter?.selected?.[activeConfig]?.name || "total"
    const _result = modelResults?.[activeConfig]?.[_filter];
    const forecastRunning = _config?.playHash === _result?.runningPlayHash;    

    
    // if (!activeConfig || !selectedTabValue ) return null 
    const model_marketsizes = suvTruck.name === 'pickup' ? (general?.pickups_marketsize || Array(3).fill(model[activeConfig]?.modelInfo?.totalMarketVolume))
        : (general?.suvs_marketsize || Array(3).fill(model[activeConfig]?.modelInfo?.totalMarketVolume))
    
    
    useEffect(async () => {
         await initializeTamForecast()
    },[])


    const setAttsToBase = (base_mdl, tam_consideration_mdl, debug) => {
        //console.log("setAttsToBase", debug);

        const deepcopy_base = JSON.parse(JSON.stringify(base_mdl))
        const deepcopy_model = JSON.parse(JSON.stringify(tam_consideration_mdl))
        
        let play = deepcopy_model.config.play;
        let base = deepcopy_base.config.base;

        
        let specialAtts = deepcopy_model.config?.productDef?.atts?.filter(a => elevenAttNames.includes(a.name))

        //if att IS in the 11 atts listed above, copy from play
        for (let baseProduct of base) {
            //console.log("checking product " + baseProduct.uid);
            for (let att of specialAtts) {
                //console.log("checking att: " + att.name);
                const baseValue = baseProduct[att.name]
                const baseValueStr = att.levels?.find(lev => lev.value == baseValue)?.label;
                const playValue = play.find(p => p.uid === baseProduct.uid)[att.name];
                const playValueStr = att.levels?.find(lev => lev.value == playValue)?.label;

                // if (baseValue !== playValue) {
                //     //console.log(`[${debug}] changing product [${baseProduct.uid}] "${baseProduct.label}" att [${att.name}] "${att.label}" from [${baseValue}] "${baseValueStr}" to [${playValue}] "${playValueStr}"`);
                // }
                // else {
                    
                // }
                baseProduct[att.name] = playValue;
            }
        }

        return deepcopy_base;
    }

    const initializeTamForecast = useCallback(debounce(async () => {      

        setIsLoadingTam(true)
        try {
            const model_tam_2024_base = model?.['dcm_tam_2024_base']
            const model_tam_2024 = setAttsToBase(model_tam_2024_base, model?.['dcm_tam_consideration_2024'], "dcm_tam_consideration_2024")
            const model_tam_2025 = setAttsToBase(model_tam_2024_base, model?.['dcm_tam_consideration_2025'], "dcm_tam_consideration_2025")
            const model_tam_2026 = setAttsToBase(model_tam_2024_base, model?.['dcm_tam_consideration_2026'], "dcm_tam_consideration_2026")
            

            const models = {
                model_tam_2024_base,
                model_tam_2024,
                model_tam_2025,
                model_tam_2026
            }

            //just forecasting on total filter right now, otherwise add datasetid...
            // const filterTotal = { name: "total", syntax: "1==1" }
            const filterSuv = { id: "suv", name: "suvs", syntax: "model1==1 or model2==1 or model3==1 or model4==1 or model5==1" }
            const filterTruck = { id: "truck", name: "trucks", syntax: "model6==1 or model7==1" }

            const customFilters = [filterSuv, filterTruck]

            let allResults = {}
            for (let customFilter of customFilters) {
                for (let id of ["tam_2024_base", "tam_2024", "tam_2025", "tam_2026"]) {
                    let res1 = await forecastTam(`dcm_${id}`, models[`model_${id}`]?.config?.base, customFilter)
                    allResults[`dcm_${id}`] = { ...allResults[`dcm_${id}`], [customFilter.id]: res1 }
                }
            }

            // console.log("allResults", allResults)

            // await metaUtils.setMeta(study?.uid, auth, 'tamchoice_results', { ...res1, ...res2, ...res3, ...res4 })
            await dispatch(GlobalStoreCreator.setGlobalStore('tamchoice_results', allResults)) // { ...res1, ...res2, ...res3, ...res4 }))
            // await dispatch(GlobalStoreCreator.setGlobalStore('tam_adj', false))
        }
        finally {
            setIsLoadingTam(false)
      }
   }, 300), [])//dep was suvTruck
    

    // useEffect(() => {
    //     initializeForecast()
    // }, [initializeForecast])    

   

    const forecastTam = async (modelname, config, filter) => {         
        
        const modelInfo = model?.[modelname]?.modelInfo

        let payload = {
            activeConfig: modelname,
            config,
            modelInfo,
            filter,
            brandvalues: [],
            playHash: config?.playHash
        }
        // console.log("forecast payload", payload);
        // console.log("modelInfo", modelInfo);
        
        //forecast
        let results = await forecastHelper.computeFordModel(study.uid, auth, payload) 

        if (results) {
            // console.log('ScenarioEditorMultiConfigs2 results', results)

            //need to sum shares by fuel type
            let fuel_shares = {
                1: 0, // ice
                2: 0, // hybrid
                3: 0, // ev
                4: 0, // phev
                5: 0, // erev
                'other': 0,
            }
            

            for (let prodId in results) {

                let product = config?.find(f => f.uid === prodId)
                let fuelType = product?.fuel
                let modelType = product?.model
                let share = results[prodId]?.share

                if (prodId === 'other') {
                    fuel_shares['other'] = results[prodId]?.share
                }
                else
                    fuel_shares[fuelType] += share
            }
            // console.log('done forecasting: ', modelname, fuel_shares)
            
            return fuel_shares
        }
        else 
            return null
    }   
   
    
    // console.log('suvTruckId', suvTruckId);

    const dcard_models = (props.models || []).filter(m => m.suvTruck === suvTruckId)

    let activeTabs = dcard_models?.map((cm) => ({
            name: cm.name,
            label: cm.label,
            icon: "fa-truck-pickup"
    }))

    
    const available = fordSelector?.available ?? false 
    const beqLevel = (model[activeConfig]?.config?.brandEquitiesDef || {}).level || 'product'

    const productSelection = fordSelector
    const beqSelection = null// selections[beqLevel] //fix
    const summarySelectedItem = fordSelector

    const tab = selectedTabValue
    const format = props.format || 'config'

    const selTabIndex = activeTabs.findIndex(f => f.label === selectedTabValue)

    const truckFilter = {
		item: {
				data: {
					type: 'suvTruck', 
					imageProperty: 'image',
					imageFit: 'contain', 
					largeImageForm: false,
					largeImageHeight: 15,
					largeImagePadding: 0,
                    imageStyle: { "border": "0px solid white", ":hover": { "cursor": "pointer", "transform": "scale(1.2)", "transition": "all ease 500ms" }, "height":"auto", "width":"30px", "marginLeft":"auto"},
					hideLabel: false,
                    hideType: true,
                    style: { "height": 35, "border": "0px solid white", "textTransform": "uppercase" }

				}
			}
    }   
    
    const mlsuvs = {
		item: {
				data: {
					type: 'ml_suvs', 
					imageProperty: 'image',
					imageFit: 'contain', 
					largeImageForm: false,
					largeImageHeight: 40,
					largeImagePadding: 0,
					imageStyle: {"border":"0px solid #ccc",":hover":{"cursor":"pointer","transform":"scale(1.1)","transition":"all ease 500ms"}},
					hideLabel: false,
					hideType: false,
					style: {"border":"1px solid #ccc"}

				}
			}
    }
    
    const mltrucks = {
		item: {
				data: {
					type: 'ml_trucks', 
					imageProperty: 'image',
					imageFit: 'contain', 
					largeImageForm: false,
					largeImageHeight: 40,
					largeImagePadding: 0,
					imageStyle: {"border":"0px solid #ccc",":hover":{"cursor":"pointer","transform":"scale(1.1)","transition":"all ease 500ms"}},
					hideLabel: false,
					hideType: false,
					style: {"border":"1px solid #ccc"}

				}
			}
    }    
    
    const datasetFilter = (props.filtersDatasetId ? filter.selected[props.filtersDatasetId] : filter.selectedFilter) || {};

    const forecast = async () => {

        let selfilter = datasetFilter// filter.selectedFilter
        await dispatch(fordForecastActionCreators.ford_forecast(selfilter, null, props.filtersDatasetId, activeConfig))

    }

    const debouncedForecast = debounce(forecast, debounce.forecastDelay)

    const ResetToBasecase = () => {
        dispatch(modelActionCreators.resetToBasecase())
        dispatch(GlobalStoreCreator.setGlobalStore('forecast_needed', true))        
    }    	



    const HandleTabChange = (tabIndex, tabValue, tabKey) => {
        setSelectedTabValue(tabValue)
         dispatch(modelActionCreators.setActiveConfig(tabKey))
         
    }

    
    return (
        <div className="scenario-editor" style={props.style}> 
            <div className="tab-section">
                <div style={{ position: 'relative', display: 'flex', justifyContent:'space-between',  gap:5, marginBottom: 5,  }}>
                    <SelectionBlockElement key={'selblock1'} {...truckFilter} width={"32%"} />
                    <button className={`btn inline-block`} style={{width:'32%'}}  role="button" aria-label="RESET TO BASECASE" onClick={ResetToBasecase} >
                       <i className='fal fa-undo' style={{marginRight:5}}></i> {` RESET ${selectedTabValue} BASE`}
                    </button>
                    <button className={`btn inline-block`} style={{width:'32%'}}  role="button" aria-label="apply changes" onClick={debouncedForecast} disabled={forecastRunning}>
                        <i className={`fal fa-rocket-launch`} style={{marginRight:5}}></i> {forecastRunning ? ` RUNNING ${selectedTabValue} FORECAST` : ` FORECAST ${selectedTabValue}` } 
                    </button>
                </div>
                {/* <div style={{ position: 'absolute', right: 0, display: 'flex', alignItems: 'center' }}>
                    {isLoadingTam && <i className="fas fa-tire fa-spin" style={{ marginRight: '10px', color:'#0064c8', fontSize:18 }}></i>}
                    <button className='btn lt inline-block left-space' onClick={TamAdjust} disabled={isLoadingTam}>
                        APPLY TAM
                    </button>
                </div> */}
                    
                
               
                <TabStrip2 selectedIndex={selTabIndex} selectedIndexChanged={(tabIndex, tabValue) => HandleTabChange(tabIndex, tabValue, activeTabs[tabIndex]?.name)} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    {
                        activeTabs.map(t => {
                            if (format !== 'optimizer') {
                                return (
                                    <Tab_buttons key={t.name} value={t.label} icon={t.icon}>{t.label}</Tab_buttons>
                                )
                            } else {
                                return (
                                    <Tab_buttons key="summary" value="summary" icon={t.icon}>Summary</Tab_buttons>
                                )
                            }
                        })
                    }                    

                </TabStrip2>
            </div>
            {format === 'optimizer' ? null : true ? (
                <div className="product-selector" style={{ display: 'flex', flexDirection: 'column' }}> 
                    {
                        suvTruck.name === 'suv' ?
                        <SelectionBlockElement {...mlsuvs} />
                        :
                        <SelectionBlockElement {...mltrucks} />
                
                    }
                    {/* <ProductBlock
                        studyId={studyId}
                        {...fordSelector}  //{...productSelection}
                        available={available}
                        standalone={true}
                        productsOnly={true}
                        dirty={(fordSelector || {}).dirty}
                        allow={['product']}
                        style={{ width: '100%' }}
                        onClick={() => {
                            window.topBar.openProducts(fordSelector, (sel) => {
                                console.log('Product was selected', sel)
                                dispatch(selectionsActionCreators.setSelection('product', sel, false))
                                let val = general.custom['product_changed'] || true
                                dispatch(GlobalStoreCreator.setGlobalStore('product_changed', !val))   
                                window.topBar.hideMenu()
                            }, {
                                allow: ['product'],
                                multiSelect: false
                            })
                        }}
                    /> */}
                    <div style={{display: 'flex', justifyContent:'space-between', gap:5}}>
                        <div style={{width:'100%', marginTop:15}}>
                            <span style={{display:'block', fontSize:12, fontWeight:500, height:15, textTransform:'uppercase'}}>Filters</span>
                            <FilterChooser mini={false} selection={datasetFilter} datasetId={filtersDatasetId} style={{ width: '100%', margin:0 }} /> 
                        </div>
                        <div style={{ width: 100, paddingTop:30, display:'flex', justifyContent:'flex-end'}}>
                            <CloneProduct  activeModel={activeConfig} selectionType={suvTruck.uid === 1 ? mlsuvs.item.data.type : mltrucks.item.data.type} />
                        </div>
                    </div>
                </div>
                
            ) : tab === 'message' || tab === 'make' ? (
                <div className="product-selector">
                    <ProductBlock
                        studyId={studyId}
                        {...beqSelection}
                        standalone={true}
                        allow={[beqLevel]}
                        style={{ width: '100%' }}
                        onClick={() =>
                            window.topBar.openProducts(beqSelection, (sel) => {
                                dispatch(selectionsActionCreators.setSelection(beqLevel, sel))
                                window.topBar.hideMenu()
                            }, {
                                allow: [beqLevel]
                            })
                        }
                    />
                </div>
            ) : tab === 'summary' ? (
                <div className="product-selector">
                    <ToggleSwitch
                        className="mini"
                        on={summaryChangesOnly}
                        onChange={(newVal) => setSummaryChangesOnly(newVal)}
                    >
                        Show Changes Only
                    </ToggleSwitch>
                    {!summaryChangesOnly ? (
                        <ProductBlock
                            studyId={studyId}
                            {...summarySelectedItem}
                            standalone={true}
                            allow={'product'} //[selections.hierarchy]
                            style={{ width: '100%', marginTop: 10 }}
                            onClick={() =>
                                window.topBar.openProducts(beqSelection, (sel) => {
                                    dispatch(modelActionCreators.set('summarySelectedItem', sel))
                                    window.topBar.hideMenu()
                                }, {
                                    allow: 'product'//[selections.hierarchy]
                                })
                            }
                        />
                    ) : null}
                </div>
                ) : null}
            {format === 'optimizer' ? (
                <div className="editor-content">
                    {tab === 'product' ? <EditConstraints /> : null}
                    {tab === 'price' ? <EditConstraints area="price" /> : null}
                    {tab === 'message' ? <EditSliderConstraints area="message" /> : null}
                    {tab === 'make' ? <EditSliderConstraints area="make" /> : null}
                    {tab === 'scenarios' ? <EditSliderConstraints area="make" /> : null}
                </div>
            ) : (   
                <div className="editor-content">
                    {tab === selectedTabValue ? <EditAreaAtts2 prodId={(fordSelector || {}).uid} key={(fordSelector || {}).uid} configModel={activeConfig} monitor_atts={elevenAttNames} /> : null}
                    {tab === 'price' ? <EditAreaAtts2 area="price" prodId={(fordSelector || {}).uid} key={(fordSelector || {}).uid} configModel={activeConfig} monitor_atts={elevenAttNames}/> : null}
                    {tab === 'message' ? <EditAreaSliders area="message" selection={(beqSelection || {}).uid} selectionLabel={(beqSelection || {}).label} /> : null}
                    {tab === 'make' ? <EditAreaSliders area="make" selection={(beqSelection || {}).uid} selectionLabel={(beqSelection || {}).label} configModel={activeConfig}  /> : null}
                    {/* {tab === 'summary' ? <ScenarioSummary filter={summarySelectedItem} changesOnly={summaryChangesOnly} /> : null} */}
                    {/* {tab === 'scenarios' ? <OpenSaveScenario configModels={configModels} /> : null} */}
                </div>
            )}
        </div>
    )

}