import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DataCardContainer } from '../card/DataCardContainer';
import { getRepo } from '../widgets/_widgetRepo';
import './ProjectedOutcomesPane.scss';

export class ProjectedOutcomesPane extends React.Component {

    constructor(props){
        super(props);
    }

    render() {

        let state = {
            selectedItem: this.props.selectedItem,
            selection: this.props.selection,
        };

        if( this.props.config ){
            let slot = this.props.config.productSlots.find(slot => slot.uid === state.selectedItem);
            if( slot ){
                state.selectedItemLabel = slot.label;
            }
        }


        let filterLabel = this.props.selectedFilter ? this.props.selectedFilter.label : 'Total population';
        
        const selectedTag = this.props.selectedTag;
        const studyId = this.props.match.params.studyId; // haven't tested this
        const repo = getRepo(studyId);//.filter(entry => entry.type === 'scenario');
        let filteredRepo = repo.filter(entry => !selectedTag || (entry.tags || []).includes(selectedTag))
        // console.log('filterd repo', filteredRepo, selectedTag)
        
        
        // console.log(filteredRepo[2]);
        // console.log(filteredRepo[3]);
        
        if (studyId == 'higher-ed') {
            //filteredRepo = filteredRepo.filter((x, i) => x.uid != i < 2 || i >= 4);
            filteredRepo = filteredRepo.filter(x =>
                x.uid != 'forecast-all-metrics3'
                && x.uid != 'forecast-all-metrics2'
                //&& x.uid != 'forecast-sunburst1'
                && x.uid != 'switchables-outcomes'
            );
        }

        return (
            <div className='projected-outcomes-pane'>
                <div className='header'>
                    {
                        this.props.selectedTag ? 
                            <ul className='card-categories'>
                                <li><button className={(this.props.selectedTag === 'projected-outcomes' ? ' sel' : '')} onClick={(evt) => this.props.loadData('projected-outcomes')}>Projected Outcomes</button></li>
                                <li><button className={(this.props.selectedTag === 'all-else-equal' ? ' sel' : '')} onClick={(evt) => this.props.loadData('all-else-equal')}>Feature Impact</button></li>
                            </ul> : null
                    }
                </div>
                <div className='po-content'>
                    <DataCardContainer repo={filteredRepo} state={state} expandRoot={`/study/${studyId}/what-if/${selectedTag}`} />
                </div>
            </div>
        );
    }

}

ProjectedOutcomesPane = withRouter(
    connect(
        state => ({
            selections: state.selections,
            selectedItem: ((state.study || {}).config || {}).selectedItem,
            selection: ((state.study || {}).config || {}).selectedItem2,
            config: (state.study || {}).config,
            configEtag: ((state.study || {}).config || {}).etag,
            selectedFilter: (state.study || {}).selectedFilter
            //summary: (state.cache.forecast || {}).main || {},
        }),
        null // dispatch => bindActionCreators({ ...studyActionCreators, ...forecastActionCreators }, dispatch)
    )(ProjectedOutcomesPane)
);
