import { useEffect } from 'react';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import React from 'react';
import { FilterBlock } from '../filter/FilterBlock';
import './Markdown.scss';

export function Markdown (props) {
    const data = props?.data;     
    
    useEffect(() => {
       return  props?.setLayoutReady?.(true);
    },[])

    
// set up renderer
    var renderer = new marked.Renderer();
    renderer.link = ( href, target, text ) => new RegExp("(.*)blueowlai.com").test(href) ?
        '<a href="'+ href +'">' + text + '</a>' :
        '<a target="_blank" href="'+ href +'">' + text + '</a>';

    // const dataArr = data?.split('||') || [];

    let html = DOMPurify.sanitize(data);
    html = marked(html, { renderer });


    return <>
        {
            props?.allowComponents && data?.trim() === '[filterblock]' ?
                <FilterBlock key={i} />
                :                
                <div className="markdown" dangerouslySetInnerHTML={{ __html: html }} />
        }
        </>
        
}
