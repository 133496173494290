import { format as d3Format } from 'd3-format';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import * as selectionHelper from '../../utils/selectionHelper';
import { CoreWidget1 } from './CoreWidget1';
import CustomLegend from "../common/CustomLegend";
import './PieChartWidget.scss';

const format_slice = d3Format('.0%');
const format_slice_tooltip = d3Format('.1%');

export const PieChartWidget = (props) => {

    const study = useSelector(state => state.study);
    const selections = useSelector(state => state.selections);
    const selection = selectionHelper.getSelection(props, study, selections);
    const entries = props.entries || 'functional_benefits';
    const statements = window.meta?.[entries];

    const [selStatement, setSelStatement] = useState(props.statement || statements?.[0]?.code);
    const statement = statements.find(m => m.code === selStatement);

    const levels = props.levels || [
        { value: 1, label: "Yes, describes me" },
        { value: 2, label: 'No, does not describe me' }
        
    ];

    // create measures
    let measures = [];
    if( statement ){
        levels.forEach(level => {
            measures.push( { id: `${statement.code}=${level.value}`, syntax: `pctin(${statement.code}, ${level.value})` })
        })
    }


    const renderContent = (cache, getJSONData) => {

        const data = levels.map(level => {
            const cell = cache?.results?.[`${statement.code}=${level.value}`];
            return {
                label: level.label,
                value: cell?.value
            };
        });
        const piecolors = [
            '#4287fa',
            '#afafaf',
            '#4771be',
            '#2b4675',
            '#5d99d0',
            '#2474b4',
            '#a3a3a3',
            '#636363',
        ];
        if (getJSONData && typeof getJSONData === 'function')
            getJSONData(data)

            const customPayload = e => {
                let customVal = {...e, payload: e.payload.map(i => ({...i, value: i.payload.label }))}
                return <CustomLegend {...customVal} />
            }

        return <div className='pie-section'>
            <ResponsiveContainer>
                <PieChart >
                <Legend verticalAlign="top" height={36} content={customPayload}  />
                    <Pie  isAnimationActive={false} 
                    
                        data={data}
                        dataKey='value'
                        startAngle={-270}
                        innerRadius={0}
                        //outerRadius={80}
                        // label={(obj) => `${obj.payload.label} ${format_slice(obj.payload.value)}` }
                    >
                    {
                        data.map((entry, index) => 
                            <Cell key={index} fill={piecolors[index % piecolors.length]}/>
                        )
                    }
                    </Pie>
                <Tooltip formatter={format_slice_tooltip}/>
                
            </PieChart>
        </ResponsiveContainer>
        </div>

    }

    
    // render statement selector
    const renderOptions = () => {
        return <div className='filter-etc'>
            {props.inDataCard && (
                <select className='select-statement'
                    value={selStatement} onChange={(ev) => setSelStatement(ev.target.value)}>
                    {statements?.map(statement => 
                        <option key={statement.code} value={statement.code}>
                            {statement.label}
                        </option>
                    )}
                </select>
            )}
            {props.inInsightCard && (
                <label className='ml-1'>{statements.filter(i => i.code === selStatement)[0].label}</label>
            )}
        </div>
    }

    // create filter
    const selObj = selection ? window.meta.dcmToSurveyMap("" + selection?.[study?.explore?.selectionLevel]) : null;
    const sel = selObj?.survey_id;
    if( !sel ) return 'selection missing or unavailable';
    const filter = props.ownerFilter ? `q1_${sel}==7 or q1_${sel}==8` : // todo: change this to a generic var like 'owner' :
        props.getFilter ? props.getFilter(selObj) : 
        props.filter;
    const filters = filter ? [ filter ] : null;

    
    return <CoreWidget1 
        class1='piechart-widget'
        useSelection={true}
        options={renderOptions}
        useFilter={true}
        render={renderContent}
        getSaveState={() => ({ statement: selStatement })}
        useYak={{
            query: {
                filters,
                measures
            }
        }}
        {...props}
    />
    

}

