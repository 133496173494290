import * as selectionHelper from '../utils/selectionHelper';
// @ts-ignore
import { SELECTION_SET, SELECTION_SET_MULTI, SELECTION_TOGGLE_COMPETITOR, STUDY_RECEIVE } from './action-types';

export interface SelectionsState{
    
    competitors: any[];

    hierarchy: any;
}

export const initialState: SelectionsState = {
    competitors: [],
    hierarchy: null
};

export const actionCreators = {

    setSelection: (key: any, value: any, smart = true, callback: any = null) => async (dispatch: any, getState: any) => {

        const state = getState();
        const selections = state.selections; //dcm selection

        let setObj: any = {};
        let setobj2: any = {};

        // smart selection is not only selecting what was requested, but also changing other levels in the hierarchy to match if needed
        if( smart ){

            // set the requested selection
            setObj[key] = value;
            
            // find level information about the requested selection
            const type = value.type;
            const typeIndex = selections.hierarchy.findIndex((el: any) => el == type);

            // find levels higher up the selection hierarchy
            const higherLevels = selections.hierarchy.filter((el: any, idx: number) => idx < typeIndex);
            
            // change higher level selections if needed
            higherLevels.forEach((level: any) => {
                if((selections[level] || {})[level] != value[level]){
                    setObj[level] = selectionHelper.getSelectionInfo(level, value[level], state.study, selections.hierarchy);
                }
            });

            // find levels lower than the selection hierarchy
            const lowerLevels = selections.hierarchy.filter((el: any, idx: number) => idx > typeIndex);

            // change lower-level selections if they are out of line with selection
            const valueEx = selectionHelper.getSelectionInfo(key, value[key], state.study, selections.hierarchy); // has a little more info that the regular value
            lowerLevels.forEach((level: any) => {
                // how to determine!!?
                // if so, change to first lower level section in line

                if( selections && !selections[level] ){
                    // selection doesn't exist, add it
                    setObj[level] = selectionHelper.getSelectionInfo(level, valueEx[level], state.study, selections.hierarchy);
                }
                else{
                    // selection exists, check it
                    const check = selections[level];
                    if( check[key] != value[key] ){
                        setObj[level] = selectionHelper.getSelectionInfo(level, valueEx[level], state.study, selections.hierarchy);
                    }
                }

            });

            dispatch({ type: SELECTION_SET_MULTI, setObj });

        }
        else{

            dispatch({ type: SELECTION_SET, key, value });

        }

        if(callback){
            callback();
        }
            
    },

    toggleCompetitor: (selection: any, callback: any) => (dispatch: any, getState: any) => {

        dispatch({ type: SELECTION_TOGGLE_COMPETITOR, selection });
        if( callback ){
            let state = getState();
            callback(state.selections?.competitors);
        }
    },

};


export const reducer = (state: SelectionsState, action: any): SelectionsState => {
    state = state || initialState;

    switch (action.type) {

        case STUDY_RECEIVE: {
            return {
                ...state,
                hierarchy: [...(action.value.selectionHierarchy?.map((el: any) => el.name) || []), 'product']
            };
        }

        case SELECTION_SET: {
            // console.log('acton value',action.value);
            // console.log('acton key',action.key);
            return {
                ...state,
                [action.key]: action.value
            }
        }

        case SELECTION_SET_MULTI : {
            return {
                ...state,
                ...action.setObj
            }
        }

        case SELECTION_TOGGLE_COMPETITOR : {

            const exists = state.competitors.find((c: any) => c.uid === action.selection.uid);
            const newList = exists ? state.competitors.filter((c: any) => c.uid !== action.selection.uid) :
                [...state.competitors, action.selection];
            
            return {
                ...state,
                competitors: newList
            }

        }

        default:
            return state;
    }
};

