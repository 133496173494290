import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Config.ts';
import { PageHeader } from '../common/PageHeader';
import './Game.scss';

export class Game extends React.Component {


    render() {
        if (!this.props.config) return null;
        let studyId = this.props.match.params.studyId;
    
        return (
            <div className="page game">
            
            <PageHeader
                fullWidth={true}
                title='Competitive Game'
                left={
                    <>
                    </>
                }
    
            />
    
            <div className="content">
    
                <div className='inner-content'>
                    <div className='page1'>
                        {/* <img src={`${globals.appRoot}/study/${studyId}/shared/images/temp/gaming.png`} style={{ maxWidth: 900 }}/> */}
                    </div>
                </div>
              
            </div>
          </div>
        );
      }
}

Game = withRouter(
  connect(
    state => state.study,
    dispatch => bindActionCreators(actionCreators, dispatch)
  )(Game)
);
