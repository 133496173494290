import * as React from 'react';
//import { RouteComponentProps } from 'react-router';
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/Config.ts';
import { ProductBlock } from '../product-drawer/ProductBlock';
import './OptRunDrawer.scss';
import * as globals from '../../globals';
import * as metaUtils from '../../utils/metaUtils';
import { SELECTION_SELECT, SELECTION_ADD, SELECTION_REMOVE, SELECTION_REMOVE_ALL } from '../../store/action-types.ts';
import { SelectionBlock } from '../product-drawer/SelectionBlock';
import moment from 'moment';

import * as apiService from '../../utils/apiService';
import { OptRunItem } from './OptRunItem';
import { OPTIM_SET_RUNLIST_DATA, OPTIM_SET_RUNLIST_LOADING } from '../../store/action-types.ts';

const NOT_SET = '[not-set]';

const getSortValue = (run) => {

    return run.completed ? { cat: 3, ts: run.completed }
        : run.started ? { cat: 2, ts: run.started }
            : run.created ? { cat: 1, ts: run.created }
                : { cat: 4, ts: null }
        
}



export const OptRunDrawer = (props) => {

    const [searchText, setSearchText] = useState('');
    //const [items, setItems] = useState(null);
    //const [loadingItems, setLoadingItems] = useState(false);

    const itemIds = useSelector(state => state.optim?.optRunList);
    const optRunDict = useSelector(state => state.optim?.optRunDict);
    const items = itemIds.map(id => optRunDict?.[id]);

    const loadingItems = useSelector(state => state.optim?.loading);

    const [refreshCounter, setRefreshCounter] = useState(0);
    const studyId = useSelector(state => state.study?.uid);
    const studyFolder = useSelector(state => state.study?.folder);
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();


    // load the optruns
    useEffect(() => {
        const f = async () => {

            dispatch({ type: OPTIM_SET_RUNLIST_LOADING, value: true });

            //setLoadingItems(true);
            try {
                const response = await apiService.aGet(auth, `${globals.apiRoot}/study/${studyId}/optruns`);
                if( response.ok ){
                    const runs = await response.json();
                    //setItems(runs);
                    //console.log('runs', runs);

                    dispatch({ type: OPTIM_SET_RUNLIST_DATA, listData: runs });
                    dispatch({ type: OPTIM_SET_RUNLIST_LOADING, value: false });
                }
            }
            catch (err) {
                console.error(err);
            }
            //setLoadingItems(false);
            
        }
        f();

    }, [studyId, refreshCounter])


    const handleRemoveClick = (item) => {

        dispatch({
            type: SELECTION_REMOVE,
            key: selectionNameMulti,
            value: item
        })

        if (props.onSelect) {
            props.onSelect();
        }
    }

    const isMatch = (slot, searchItems) => {
        let str = slot.label.toLocaleLowerCase();
        for (let i in searchItems) {
            if (str.indexOf(searchItems[i]) === -1)
                return false;
        }
        return true;
    }

    let displayItems = [...(items || [])]
    // displayItems = displayItems?.sort((a, b) => {
    //     if (a.displayIndex !== null && a.displayIndex !== undefined)
    //         return a.displayIndex - b.displayIndex;
    //     return a.label.localeCompare(b.label);
    // }) || [];
    displayItems = displayItems.map(d => ({ ...d, sort: getSortValue(d) }));
    displayItems = displayItems.sort((a, b) => {
        if (a.sort.cat !== b.sort.cat) {
            return a.sort.cat - b.sort.cat;
        }
        else {
            return new Date(b.sort.ts) - new Date(a.sort.ts);
        }
    });

    
    if (searchText !== '') {
        const searchItems = searchText.toLowerCase().split(' ');
        displayItems = displayItems.filter(item => isMatch(item, searchItems));
    }

    let groupings = ["Recent", "Saved"];
    
    return (
        <div className={"optrun-drawer" + (props.isOpen ? ' open' : ' closed')}>

            <div className='top'>

                <div className='form-buttons'>
                    <div className='btn inline-block'>
                        <i className='fal fa-expand-alt'/>
                    </div>
                    <div className='btn inline-block'>
                        <i className='fal fa-times'/>
                    </div>
                </div>

                <div className='main-section'>

                    <div className='h3'>
                        Optimation Runs
                    </div>

                    <div className='search'>
                        {/* <div className='input-container'>
                            <input type="text" placeholder="search" value={searchText} onChange={(ev) => setSearchText( ev.target.value )}/>
                        </div>
                        <span className={'btn-cancel-search ' + (searchText !== '' ? ' enabled' : '')}
                            onClick={() => setSearchText('')}
                        >
                            <i className='fas fa-times-circle'/>
                        </span> */}
                    </div>

                    <span>
                        <span className='btn inline-block' onClick={() => {
                            setRefreshCounter(refreshCounter + 1);
                        }}>
                            <i className='fal fa-sync'/>
                        </span>
                    </span>

                    {groupings.length > 1 ? <div className='grouping-selector'>
                        <span className='label1'>
                            Recent &nbsp;.&nbsp; Saved
                        </span>
                        {groupings.map(groupingName => {
                            // return <span key={groupingName + '.'} className={'grouping-button' + (groupingName == selectedGrouping ? ' sel' : '')} onClick={() => {
                            //     setSelectedGrouping(groupingName);
                            // }}>
                            //     {groupingName ? groupingName : "All"}
                            // </span>
                        })}
                    </div> : null}

                </div>


            </div>

            <div className="list-view">
                {loadingItems ? <div className='loading'>loading...</div>
                    : displayItems?.map((run, idx) => <OptRunItem runId={run.id} key={idx} />)
                }
            </div>

            <div className='bottom text-center' >
                <button  className="action-button" onClick={() => window.topBar.hideMenu()}>close</button>
            </div>

        </div>
    );
}

