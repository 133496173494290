// @ts-ignore
import { connectRouter, routerMiddleware } from 'connected-react-router';
// @ts-ignore
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
// @ts-ignore
import thunk from 'redux-thunk';
// @ts-ignore
import * as Account from './Account';
// @ts-ignore
import * as Analytics from './Analytics';
// @ts-ignore
import * as Auth from './Auth';
// @ts-ignore
import * as Cards from './Cards';
// @ts-ignore
import * as Channels from './Channels';
// @ts-ignore
import * as Compare from './Compare';
// @ts-ignore
// import * as Configs from './Configs';
// @ts-ignore
import * as DCM from './DCM';
// @ts-ignore
import * as Model from './Model';
// @ts-ignore
import * as Explore from './Explore';
// @ts-ignore
import * as Filter from './Filter';
// @ts-ignore
import * as Forecast from './Forecast';
// @ts-ignore
import * as FordForecast from './FordForecast';
// @ts-ignore
import * as General from './General';
// @ts-ignore
import * as Notify from './Notify';
// @ts-ignore
import * as Optim from './Optim';
// @ts-ignore
import * as Posts from './Posts';
// @ts-ignore
import * as ResearchScenarios from './ResearchScenarios';
// @ts-ignore
import * as Selections from './Selections';
// @ts-ignore
import * as Selection from './Selection';
// @ts-ignore
import * as Stories from './Stories';
// @ts-ignore
import * as Study from './Study';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION__?: any;
    }
}

export default function configureStore(history: any, initialState: any) {

    const reducers = combineReducers({
        account: Account.reducer,
        auth: Auth.reducer,
        forecaster: combineReducers({
            fordForecast: FordForecast.reducer,
            forecast: Forecast.reducer,
            analytics: Analytics.reducer
        }),
        cards: Cards.reducer,
        channels: Channels.reducer,
        compare: Compare.reducer,
        // configs: Configs.reducer,
        dcm: DCM.reducer,
        model: Model.reducer,
        explore: Explore.reducer,
        filter: Filter.reducer,
        general: General.reducer,
        notify: Notify.reducer,
        optim: Optim.reducer,
        posts: Posts.reducer,
        researchScenarios: ResearchScenarios.reducer,
        router: connectRouter(history),
        selection: Selection.reducer,
        selections: Selections.reducer, // deprecated
        stories: Stories.reducer,        
        study: Study.reducer,
         //bigData: BigData.reducer,
    })

    const middleware = [thunk, routerMiddleware(history)];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    // console.log('isDevelopment', isDevelopment)
    if (
        isDevelopment &&
        typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION__
    ) {
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }


    return createStore(
        reducers,
        initialState,
        compose(
        applyMiddleware(...middleware),
            ...enhancers
        )
    );
}
