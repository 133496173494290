import * as React from "react";
import { connect } from "react-redux";
import {
	Bar,
	BarChart,
	CartesianGrid,
	LabelList,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as forecastActionCreators } from "../../store/Forecast.ts";
import * as debounce from "../../utils/debounce";
import * as selectionHelper from "../../utils/selectionHelper";
import { CardCover } from "../card/CardCover";
import { CardLoading } from "../card/CardLoading";
import { FilterChooser } from "../filter/FilterChooser";
import { ProductBlockMini } from "../product-drawer/ProductBlockMini";
import CustomizedTick from "../common/CustomizedTick";
import CustomLegend from "../common/CustomLegend";

const metrics = globals.metrics;

export class BrandAllElseEqualWidget extends React.Component {
	constructor(props) {
		super(props);

		// local widget settings
		this.state = {
			metric: this.props.metric || "share",
		};

		// Allow card to be saved as insight card. Not the greatest pattern.
		this.props.stateContext.getSaveState = this.getSaveState;
	}

	componentDidMount() {
		//this.refreshIfNeeded();
	}

	componentDidUpdate() {
		//this.refreshIfNeeded();
	}

	// these are needed from parent state
	static inheritOptions = ["configEtag"];

	static scenarioDriven = true;

	// returns widget's local settings (for save state)
	getSaveState = () => {
		const selectedProductId = this.props.selectedProductId;
		if (!selectedProductId) return null;

		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);

		// get the cache
		const fullCache = this.props.fullCache;
		const key = `${filter.syntax}:brand_all_else_equal:${selectedProductId}`;
		const cache = (fullCache || {})[key];

		return {
			selectedProductId,
			filter,
			metric: this.props.metric || this.state.metric,
			cache: cache,
		};
	};

	isLoading = () => {
		let fCache = (this.props.cache || {})[this.props.filter.syntax] || {};
		return fCache.loading || fCache.etag !== this.props.etag;
	};

	refresh = () => {
		this.refreshIfNeeded();
	};

	refreshIfNeeded = debounce.debounce(() => {
		// cancel if insight card
		if (this.props.inInsightCard) return;

		// cancel if no selection
		const selectedProductId = this.props.selectedProductId;
		if (!selectedProductId) return;

		// cancel if config isn't ready
		if (!this.props.etag) return;

		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);

		// cancel if cached data already exists, with matching filter and etag
		const fullCache = this.props.fullCache;
		const key = `${filter.syntax}:brand_all_else_equal:${selectedProductId}`;
		const cache = (fullCache || {})[key];

		if (cache && cache.etag === this.props.etag) return;

		// cancel if cached data is loading
		if (cache && cache.loading) return;

		// refresh is needed. request it.
		this.props.requestAux(
			"brand_all_else_equal",
			filter.syntax,
			`brand_all_else_equal:${selectedProductId}`,
			{
				target_product: selectedProductId,
				forecaster_name: "nestedMNL_wConsider",
			},
		);
	}, debounce.forecastDelay);

	render() {
		const selectedProductId = this.props.selectedProductId;
		const selectedProduct = selectedProductId
			? selectionHelper.getSelectionInfo(
					"product",
					selectedProductId,
					this.props.study,
					this.props.selections?.hierarchy,
			  )
			: null;

		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);

		let cache = this.props.cache;
		if (!cache) {
			const fullCache = this.props.fullCache;
			const key = `${filter.syntax}:brand_all_else_equal:${selectedProductId}`;
			cache = (fullCache || {})[key];
		}
		let cacheValue = (cache || {}).value;

		let data = null;
		if (cacheValue) {
			data = cacheValue
				.filter((el) => el.metric === this.state.metric)
				.map((el) => {
					const message =
						this.props.messages.find((m) => m.name === el.perception) || {};
					return {
						label: message.label,
						area: message.area,
						category: message.category,
						value: el.diff,
					};
				})
				.filter((el) => el.area === this.props.area)
				.filter((el) => el.category === this.props.category)
				.sort((a, b) => b.value - a.value);
			//.filter((el, idx) => idx < 20)
		}

		// const filterCache = (cache || {})[filter.syntax];
		// let productCache = (filterCache || {})[this.props.selectedItem];

		const expired =
			this.props.inDataCard && this.props.etag !== (cache || {}).etag;
		const loading = cache && cache.loading;
		if (this.props.loaded && typeof this.props.loaded === "function")
			this.props.loaded(!loading);
		if (this.props.getJSONData && typeof this.props.getJSONData === "function")
			this.props.getJSONData(data);

		const selMetric = metrics.find((m) => m.key === this.state.metric);
		const format = selMetric.fformat;
		const toolTipformat = selMetric.toolTipformat;

		const content = !cacheValue ? null : (
			<ResponsiveContainer width="100%" height={data?.length * 50}>
				<BarChart
					layout="vertical"
					data={data}
					margin={{ right: 40, left: 30 }}>
					<CartesianGrid stroke="#f5f5f5" />
					<XAxis
						type="number"
						domain={["datamin", (dataMax) => Math.max(0.05, dataMax * 1.5)]}
						tickFormatter={format}
					/>
					<YAxis
						dataKey="label"
						type="category"
						width={this.props.labelWidth || 120}
						tick={<CustomizedTick />}
					/>
					<Tooltip formatter={toolTipformat} />
					<Legend content={<CustomLegend />} />
					<Bar dataKey="value" fill={globals.colors.primary1}>
						<LabelList
							dataKey="value"
							position="right"
							formatter={format}
							style={{ fontSize: "9pt" }}
						/>
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		);

		return (
			<div className="widget">
				<div className="widget-header">
					{this.props.title ? (
						<div className="title">{this.props.title}</div>
					) : null}
					{this.props.subtitle ? (
						<div className="subtitle">{this.props.subtitle}</div>
					) : null}
					<div className="selection">
						<ProductBlockMini
							{...selectedProduct}
							disabled={this.props.inInsightCard}
							allowChange={["product"]}
						/>
					</div>

					<div className="filter-etc">
						<FilterChooser
							mini={true}
							disabled={this.props.inInsightCard}
							selection={filter}
						/>
					</div>
					{this.props.inDataCard && (
						<select
							value={this.state.metric}
							onChange={(ev) => this.setState({ metric: ev.target.value })}>
							{metrics.map((m) => (
								<option key={m.key} value={m.key}>
									{m.label}
								</option>
							))}
						</select>
					)}
					{this.props.inInsightCard && (
						<label className="ml-1">
							{metrics.filter((i) => i.key === this.state.metric)[0].label}
						</label>
					)}
				</div>
				<div className="widget-body vscroll">
					{content}
					{!selectedProductId ? (
						<CardCover message="no selection" />
					) : loading ? null : expired ? (
						<CardCover button="update" buttonClicked={() => this.refresh()} />
					) : null}
				</div>
				<CardLoading loading={loading} />
			</div>
		);
	}
}

BrandAllElseEqualWidget = connect(
	(state, ownProps) => ({
		study: state.study,
		etag: state.study?.config?.etag,
		messages: state.study?.config?.brandEquitiesDef?.entries,
		selections: state.selections,
		...(ownProps.inDataCard
			? {
					filter: (state.filter || {}).selectedFilter,
					fullCache: state.cache?.forecast,
					selectedProductId: state.selections?.product?.uid,
			  }
			: null),
	}),
	(dispatch) => bindActionCreators(forecastActionCreators, dispatch),
)(BrandAllElseEqualWidget);
