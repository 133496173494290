import { format as d3Format } from 'd3-format';
import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import * as selectionHelper from '../../utils/selectionHelper';
import './BarChartComp.scss';
import CustomizedTick from "../common/CustomizedTick";
import { CoreWidget1 } from './CoreWidget1';

export const BarChartComp = (props) => {

    const study = useSelector(state => state.study);
    const selections = useSelector(state => state.selections);
    const selection = selectionHelper.getSelection(props, study, selections);

    const comps = props?.comps || useSelector(state => state.selections?.competitors);
    let sels = [selection, ...(comps || [])].filter(_ => _);

    let measures = []
    sels.forEach(sel => {
        const qModel = window.meta.dcmToSurveyMap(sel.model);
        measures.push({ id: `awareness:${qModel.dcm_id}`, syntax: `pctin(q1_${qModel.dcm_id},2,3,4,5,6,7,8)` });
    });

        useEffect(() => {
            props?.setLayoutReady?.(true);
    }, []);
    
    // render
    const renderContent = (cache, getJSONData) => {

        if( !cache?.results ) return null;
        const r = cache.results;
        let data = sels.map(sel => {
            const qModel = window.meta.dcmToSurveyMap(sel.model);
            return {
                ...r[`awareness:${qModel.dcm_id}`],
                label: sel.label
            }
        });
        if (getJSONData && typeof getJSONData === 'function')
            getJSONData(data)
        const fmt = d3Format('.1%');
        return <ResponsiveContainer height={55 + sels.length * 40}>
            <BarChart data={data}
                layout="vertical"
                    margin={{top: 0, right: 0, left: 0, bottom: 10}}
                    barGap={0}
                    >
                <XAxis type="number" tickFormatter={fmt} domain={[0, 'dataMax + 0.1']} />
                <YAxis type="category" dataKey="label" width={props.yAxisWidth || 145} tick={<CustomizedTick wordBreakLimit={20} />} />
                <Tooltip formatter={fmt}/>
                {/* <Legend /> */}
                <Bar isAnimationActive={false} dataKey={`value`} fill={"#3c68d3"} >
                    <LabelList dataKey={`value`} position="right" formatter={fmt}/>
                </Bar>
            </BarChart>
        </ResponsiveContainer>

    }
    
    return <CoreWidget1 {...props}
        showSelection={true}
        useComps={true}
        useFilter={true}
        render={renderContent}
        class2="vscroll"
        useYak={{
            query: {
                measures
            }
        }}
        />

}
