import { format as d3Format } from "d3-format";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as analyticsActionCreators } from "../../store/Analytics.ts";
import { CardLoading } from "../card/CardLoading";
import { FilterChooser } from "../filter/FilterChooser";
import { TableFred } from "../vis/TableFred";

const nullcheck = (func) => (val) =>
	val === undefined || val === null ? "." : func(val);

const fmt_nps = nullcheck(d3Format(".0f"));
const fmt_pct = nullcheck(d3Format(".0%"));
const fmt_nps2 = nullcheck(d3Format(".1f"));
const fmt_pct2 = nullcheck(d3Format(".1%"));

export class NPSViewerCW extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};

		// Allow card to be saved as insight card. Not the greatest pattern.
		this.props.stateContext.getSaveState = this.getSaveState;
	}

	componentDidMount() {
		this.refreshIfNeeded();
	}

	componentDidUpdate() {
		this.refreshIfNeeded();
	}

	refreshIfNeeded = () => {
		// cancel if cached data already exists

		if (this.props.cache) return; // insights card

		const fullCache = this.props.fullCache;
		const rootPath = this.props.path;

		const path = `${rootPath}`;
		const cache = fullCache ? fullCache[path] : null;

		if (cache && cache.loading) return;
		if (cache && cache.error) return;
		if (cache && cache.value) return;

		// refresh is needed. request it.
		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);
		const query = this.props.getQuery(this.props.selection, filter);
		this.props.loadYak(path, `${filter.syntax}:${path}`, query);
	};

	static inheritOptions = ["selection"];

	static getTitle = (state) => {
		return `${state.title}`;
	};

	static getSources = (state) => state.sources || [];

	getSaveState = () => {
		let cache = this.props.cache;
		if (!cache) {
			const fullCache = this.props.fullCache;
			const rootPath = this.props.path;
			const v = this.props.var || this.state.var;
			const path = `${rootPath}/${v}`;
			cache = fullCache ? fullCache[path] : null;
		}

		return {
			selection: this.props.selection,
			var: this.props.var || this.state.var,
			cache,
			filter: this.props.filter,
		};
	};

	render() {
		const study = this.props.study;
		if (!study) return;

		let cache = this.props.cache;
		// if( !cache ){
		//     const fullCache = this.props.fullCache;
		//     const rootPath = this.props.path;
		//     const v = this.props.var || this.state.var;
		//     const path = `${rootPath}/${v}`
		//     cache = fullCache ? fullCache[path] : null;
		// }

		// original cache from computation engine
		const data = cache ? cache.value : null;
		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);

		// add id to the groups
		const groups = (this.props.groups || []).map((group) => ({
			...group,
			id: group.id || group.syntax,
		}));

		// convert data into rows
		const orderedGroups = groups;
		let rows = data
			? this.props.entries.map((entry) => {
					let row = {
						...entry,
					};
					orderedGroups.forEach((group, gi) => {
						const modelTopBox = data[`${entry.survey_id}:topbox`];
						const modelBotBox = data[`${entry.survey_id}:botbox`];

						const groupTopBox = modelTopBox ? modelTopBox[group.id] : null;
						const groupBotBox = modelBotBox ? modelBotBox[group.id] : null;

						const topBox = groupTopBox?.value || 0;
						const botBox = groupBotBox?.value || 0;
						const nps = (topBox - botBox) * 100.0;
						const n = groupTopBox?.n || groupBotBox?.value || 0;

						let obj =
							n > 0
								? {
										topBox,
										botBox,
										nps,
										n: groupTopBox?.n || groupBotBox?.value || 0,
								  }
								: {
										topBox: null,
										botBox: null,
										nps: null,
										n,
								  };
						row[group.id] = obj;

						// // if prev exists, add comparisons to it
						// let prevGroup = orderedGroups[gi - 1];
						// if( prevGroup ){
						//     let prevObj = row[prevGroup.id || prevGroup.syntax]
						//     if( prevObj ){
						//         obj.npsChange = obj.nps !== null && prevObj.nps !== null ? obj.nps - prevObj.nps : null;
						//     }
						// }
					});

					return row;
			  })
			: [];

		// compute all models average row
		const all_models_init = orderedGroups.reduce(
			(acc, grp) => {
				acc[grp.id] = { sum_topBox: 0, sum_botBox: 0, count: 0 };
				return acc;
			},
			{ label: "Average" },
		);
		const all_models = rows.reduce((accum, element) => {
			orderedGroups.forEach((group) => {
				const groupElement = element[group.id];
				if (groupElement?.n > 0) {
					const a = accum[group.id];
					a.sum_topBox += groupElement.topBox;
					a.sum_botBox += groupElement.botBox;
					a.count++;
					a.topBox = a.sum_topBox / a.count;
					a.botBox = a.sum_botBox / a.count;
					a.nps = (a.topBox - a.botBox) * 100.0;
				}
			});
			return accum;
		}, all_models_init);

		// add gap scores to each row (row diff from avg)
		rows = rows.map((row) => {
			let ret = {
				...row,
			};
			orderedGroups.forEach((group) => {
				const groupKey = group.id;
				if (row[groupKey].n > 0) {
					ret[groupKey].npsGap = ret[groupKey].nps - all_models[groupKey].nps;
				}
			});
			return ret;
		});

		// add change scores to each row (group diff from prior group)
		rows = rows.map((row) => {
			let ret = {
				...row,
			};
			orderedGroups.forEach((group, groupIndex) => {
				const prevGroup = orderedGroups[groupIndex - 1];
				if (prevGroup) {
					let prevObj = ret[prevGroup.id];
					if (prevObj.n > 0) {
						let obj = ret[group.id];
						if (obj.n > 0) {
							obj.npsChange = obj.nps - prevObj.nps;
							obj.npsGapChange = obj.npsGap - prevObj.npsGap;
						}
					}
				}
			});
			return ret;
		});

		// push the average row
		//rows.push(all_models);

		const tableRows = rows.map((row) => {
			let ret = {
				label: row.label,
			};
			orderedGroups.forEach((group) => {
				ret[`${group.id}_nps`] = row[group.id].nps;
				ret[`${group.id}_npsChange`] = row[group.id].npsChange;
				ret[`${group.id}_npsGap`] = row[group.id].npsGap;
				ret[`${group.id}_npsGapChange`] = row[group.id].npsGapChange;
			});
			return ret;
		});

		const selGroup = orderedGroups[1];

		const columns = [
			{
				key: "label",
				type: "string",
				className: "label_sm",
				label: "Model",
				className: "label",
			},
			{
				key: `${selGroup.id}_nps`,
				type: "horizontal-nps-bar",
				className: "data",
				format: fmt_nps,
				label: (
					<span>
						{selGroup.label}
						<br />
						NPS
					</span>
				),
			},
			{
				key: `${selGroup.id}_npsChange`,
				type: "number",
				className: "data",
				format: fmt_nps,
				label: (
					<span>
						{selGroup.label}
						<br />
						NPS Change
					</span>
				),
			},
			{
				key: `${selGroup.id}_npsGap`,
				type: "horizontal-nps-bar",
				className: "data",
				format: fmt_nps,
				label: (
					<span>
						{selGroup.label}
						<br />
						Gap
					</span>
				),
			},
			{
				key: `${selGroup.id}_npsGapChange`,
				type: "number",
				className: "data",
				format: fmt_nps,
				label: (
					<span>
						{selGroup.label}
						<br />
						Gap Change
					</span>
				),
			},
		];
		if (
			this.props.getJSONData &&
			typeof this.props.getJSONData === "function" &&
			columns &&
			tableRows
		)
			this.props.getJSONData(
				{ columns: [...columns], data: [...tableRows] },
				true,
			);

		const content = !cache ? null : cache.loading ? null : (
			<TableFred
				getExportData={this.props.getExportData}
				getJSONData={this.props.getJSONData}
				columns={columns}
				data={tableRows}
				style={{ margin: "0 10px" }}
			/>
		);

		if (
			cache &&
			!cache.loading &&
			this.props.loaded &&
			typeof this.props.loaded === "function"
		)
			this.props.loaded(!cache.loading);
		return (
			<div className="widget">
				<div className="widget-header">
					{/* <div className='selection'>{this.props.option}</div> */}
					<div className="title">{this.props.title}</div>
					<div className="filter-etc">
						<FilterChooser
							disabled={this.props.inInsightCard}
							selection={filter}
							mini={true}
						/>
					</div>
				</div>
				<div className="widget-body vscroll hscroll no-pad">{content}</div>
				<CardLoading loading={cache && cache.loading} />
			</div>
		);
	}
}

NPSViewerCW = connect(
	(state, ownProps) => {
		const filter = state.filter
			? state.filter.selectedFilter
			: null || globals.getDefaultFilter(state.study);

		return {
			study: state.study,
			...(ownProps.inDataCard
				? {
						cache: state.cache.analytics[`${filter.syntax}:${ownProps.path}`],
						filter,

						//fullCache: state.cache.analytics
				  }
				: {}),
		};
	},
	(dispatch) => bindActionCreators(analyticsActionCreators, dispatch),
)(NPSViewerCW);
