import * as React from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { actionCreators as selectionsActionCreators } from '../../store/Selections.ts';
import './CompChooser.scss';

export const CompChooser = (props) => {
    
    const dispatch = useDispatch();
    const store = useStore();
    //const selectionLevel = useSelector(state => state.explore?.selectionLevel || 'product');
    const allow = props.allow || ['product'];
    const selectionLevel = allow?.[0];
    const competitors = useSelector(state => state.selections?.competitors);
    const primarySelection = useSelector(state => state.selections?.[selectionLevel]);

    return <div 
        className={'comp-chooser' + (props.mini ? ' mini' : '') + (props.disabled ? ' disabled' : '')}
        xstyle={props.style}
        >

        {/* <div className={'wave-cell disabled'}>
            <span className='label'>
                Competitors:
            </span>
        </div> */}

        <div className={'filter-cell ' + (props.disabled ? ' disabled' : '')} onClick={() => {
            if( !props.disabled ){
                window.topBar.openProductsMulti(competitors,
                    (sel) => {
                        selectionsActionCreators.toggleCompetitor(sel, (newSelections) => {
                            window.productDrawer.setState({ sels: newSelections });
                        })(dispatch, store.getState);
                    },
                    {
                        allow,//: [selectionLevel],
                        multiSelect: true,
                        includePrimary: true
                    },
                    primarySelection,
                    (psel) => {
                        selectionsActionCreators.setSelection(psel.type, psel, true, () => {
                            window.productDrawer.setState({ sel: psel });
                        })(dispatch, store.getState);
                    }
                )
            }
        }}>
            <i className='fal fa-filter glyph'/>
            <span className='label'>
                {props.label || primarySelection?.label + ' and competitors'}
                {/* {competitors.length === 0 ? '[select competitors]' : competitors.map(c => c.label).join(', ')} */}
            </span>
        </div>
        

    </div>;
    
    

}

