import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import * as globals from '../../globals';
import { actionCreators as configActionCreators } from '../../store/Config.ts';
import { actionCreators as notifyActionCreators } from '../../store/Notify.ts';
import { actionCreators as researchScenariosActionCreators } from '../../store/ResearchScenarios.ts';
import { actionCreators as scenarioActionCreators } from '../../store/Scenario.ts';
import { dateFormat } from '../../utils/dateFormat';
import { loadOptRunSetSelectionHelper } from '../../utils/optRunHelper';
import './NotificationsPane.scss';


export class NotificationsPane extends React.Component {


    componentDidMount() {
        // poll config/cache to see if results are expired
        var ms = 10 * 1000; // 10 seconds
        this.timer = setInterval(this.checkForUpdateNeeded, ms);
    }

    componentWillUnmount() {
        // remove polling
        clearInterval(this.timer);
    }

    statusUpdating = false;

    /** runs update if results are expired and an update is not already running */
    checkForUpdateNeeded = () => {
        
        if( !this.props.notifications ) return;

        if( this.statusUpdating ) return;

        this.statusUpdating = true;
        this.props.notifications
            .filter(n => n.running)
            .forEach(n => {
                console.log('calling updateRunStatus', n.id, n.runId);
                this.props.updateRunStatus(n.id, n.runId);
            });
            this.statusUpdating = false;

    };

    optLoadBest = (runId) => {

        this.props.loadOptimizerScenario(runId, 0, (succeeded, data) => {

            loadOptRunSetSelectionHelper(data, this.props.study, this.props.config_set)
                .then(() => {
                    let studyId = this.props.study.uid;
                    window.topBar.hideMenu();
                    this.props.history.push(`/study/${studyId}/what-if`)
                });

        })
    }

    optResearch = (runId) => {

        window.topBar.hideMenu();
        let studyId = this.props.study.uid;
        this.props.history.push(`/study/${studyId}/optrun/${runId}`);

        // this.props.loadOptRun(runId, () => {
        //     let studyId = this.props.study.uid;
        //     window.topBar.hideMenu();
        //     this.props.history.push(`/study/${studyId}/research-scenarios`)
        // })

    }

    optSave = (runId) => {

        this.props.saveOptRun(runId, () => {
            window.topBar.hideMenu();
        })

    }

    render() {

        let nb_unread = this.props.notifications.reduce(
            (acc, cval) => acc + (cval.read ? 0 : 1),
            0
        );

        return (
            <div className="notifications-menu">
                <div className="separator-with-text">
                <i className="far fa-bell"></i> &nbsp; Notifications{' '}
                    {nb_unread > 0 ? (<span className="unread">({nb_unread} New)</span>) : null}
                </div>

                <div className="note-section">
                <center>Hover or click a notification to mark it as read.</center>
                </div>

                <div className="clear-section">
                  <center>
                    <button
                            className="btn"
                            role='button'
                            aria-label='close all read notifications'
                        onClick={() => {
                            this.props.clearAllNotifications(() => {
                                this.props.closeRequested();
                            });
                            
                        }}
                    >
                        Close all read notifications &nbsp; <i className="fal fa-times"></i>
                    </button>
                  </center>
                </div>

                {this.props.notifications.map(notif => {

                    

                    let state = notif.state || 'unknown';
                    let message = notif.runStatus?.message || '...';
                    if( typeof message === 'object' ){
                        const gen = message.gen;
                        const goal = globals.metrics.find(m => m.key == message.goal);
                        const best = goal && message.best ? goal.fformat(message.best) : '.'; 
                        const tot_elapsed = message.tot_elapsed;
                        message = `Running gen ${gen}; Best is ${best}; Elapsed: ${tot_elapsed} second(s)`;
                    }
                    if( state === 'error' ){
                        message = notif.error;
                    }

                    return <div key={notif.id}
                        className={
                            'notification' +
                            (notif.read ? '' : notif.error ? ' unread-error' : ' unread') +
                            (notif.showOptButtons || (notif.running) ? ' has-clickable-content' : ' one-click')
                        }
                        onMouseOver={() => {
                            if (!notif.read && !notif.running)
                                this.props.markNotificationRead(notif.runId, true);
                            
                            if (notif.runStatus) {
                                if (
                                    (notif.runStatus.status === 'running' || notif.runStatus.status === 'done')
                                    && !notif.read
                                ) this.props.markNotificationRead(notif.runId, true);
                            }
                        }}
                    >
                        <div className="header">
                            <span className="timestamp">
                                {notif.timestamp != null
                                    ? dateFormat(notif.timestamp, 'h:MM:ss TT')
                                    : ''}
                            </span>
                            <span className={'type ' + (notif.error ? 'error' : 'note')}>
                                {notif.error ? (
                                    <span>
                                        <i className="fal fa-exclamation-triangle" /> Error
                                    </span>
                                ) : null}
                            </span>

                            <button
                                className="close-btn"
                                role='button'
                                aria-label='close'
                                onClick={() => {
                                    if( notif.runId ){
                                        this.props.deleteOptRun(notif.runId, () => {
                                            this.props.clearNotification(notif.id)        
                                        })
                                    }
                                    else{
                                        this.props.clearNotification(notif.id)
                                    }
                                }}
                            >
                                <i className="fal fa-times" />
                            </button>
                        </div>

                        <div className="o-result">
                            {/* <div className="bo-logo"><img src="\images\BlueOwlLogo.png"></img></div> */}
                            <div className="o-message">            
                                {notif.running ? 
                                    <div className='running'>
                                        <div className='run-status' >
                                            {/* [{notif.runId}:{state}]  */}
                                            {message}
                                            {/* <div className='status'>{(notif.runStatus||{}).status}</div>
                                            <div className='message'>
                                                <div className="text-section">
                                                    Bo is optimizing [Make-model-trim] [goal] using evolutionary computing. <span className="elapsed_time"> Elapsed time: {((notif.runStatus||{}).message||{}).tot_elapsed}s </span>
                                                </div>
                                                <div className="text-section">
                                                    Base case revenue: [price] | Best so far: {((notif.runStatus||{}).message||{}).best}
                                                </div>
                                                <div>
                                                    Generation {((notif.runStatus||{}).message||{}).gen}
                                                </div>
                                                <div className="steps">
                                                <p>Encoding strategy genes in chromosomes | <span className="color-blue">Assessing fitness for reproduction</span> </p>
                                                <p>Selecting parents for reproduction | Creating offspring from parental genes </p>
                                                <p>Mutating to maintain diversity</p>
                                                </div>
                                                
                                            </div> */}
                                        </div>
                                    {/* <span className='btn1'>cancel</span> */}
                                </div> : 
                                <div className='complete'>
                                    {true || notif.runStatus ? <>
                                        <div className='run-status' >
                                            <div className='status'>{state}</div>
                                            <div className='message'>
                                                {(notif.runState || {}).error ? 'Error: ' + notif.message : "Optimization complete."}
                                                {/* <div className="text-section">
                                                    Bo has evolved {(notif.runStatus.message||{}).gen} generations to find a diverse set of optimal [name] [maximization] solutions.
                                                </div>
                                                <div className="text-section">
                                                    Base case revenue: [price] | Best found: {(notif.runStatus.message||{}).best}
                                                </div> */}
                                            </div>
                                        </div>
                                    </>
                                    : null}

                                {/* <span className='btn1'>cancel</span> */}
                            </div>
                            }
                            </div>

                            


                        </div>
                        {notif.running ?
                        <div>
                            {/* <div>
                                <center>
                                <span className='btn1' style={{display:'inline-block'}} onClick={() => this.optLoadBest(notif.runId)}>
                                    <i className='fas fa-stop'/> Stop optimization
                                </span> 
                                </center>   
                            </div> */}
                            <div className='progress-bar-container'>
                                <span className="progress-bar">
                                    <span className="thumb" />
                                </span>
                            </div>
                        </div>:null}
                        {((notif.type === 'optrun' || notif.success) && notif.hasOutput) ? <div style={{marginBottom: '10px'}}>
                           <center>
                            <span className='btn1' onClick={() => this.optLoadBest(notif.runId)}>
                            <i className="fal fa-lightbulb"></i> &nbsp; Load best scenario
                            </span>
                            <span className='btn1' onClick={() => this.optResearch(notif.runId)}>
                            <i className="fas fa-analytics"></i> &nbsp; Analyze top 100
                            </span>
                           </center>
                            {/* <span className='btn1' onClick={() => this.optSave(notif.runId)}>
                                <i className='fal fa-save'/> Save
                            </span> */}
                        </div> : null}

                    </div>
                })}
            </div>
        );
    }
}

NotificationsPane = withRouter(
    connect(
        state => ({ ...state.notify, study: state.study, auth: state.auth }),
        dispatch => bindActionCreators({
            ...notifyActionCreators,
            ...scenarioActionCreators,
            ...researchScenariosActionCreators,
            config_set: configActionCreators.set
            }, dispatch)
    )(NotificationsPane)
);
