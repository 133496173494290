import { format as d3Format } from 'd3-format';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './AttViewer.scss';

export class AttViewer extends React.Component {

    constructor(props){
        super(props);
    }



    render() {
        const att = this.props.att;
        if( !att ) return "att missing";
        
        const slot = this.props.slot;
        if( !slot ) return "slot missing";

        let content = null;

        if( !att.type || att.type === 'discrete' ){
            const levels = att.levels || [];
            const selLevel = levels.find(level => level.value == slot[att.name]) || {};
            content = selLevel.label;
        }
        else if( att.type === 'contin' ){
            //console.log(att);
            content = d3Format(att.format)(slot[att.name]); // JSON.stringify(att); // slot[att.name]; // todo: format this gal
        }
        else if( att.type === 'string' ){
            content = slot[att.name];
        }

        return <div className='att-viewer'>
            <div className='app-label'>
                {att.label}
            </div>
            <div className='att-value'>
                {content || '.'}
            </div>
        </div>
        
    }
}

AttViewer = withRouter(
    connect(
        (state, ownProps) => {
            return {
                study: state.study,
            }
            
        },
        null //dispatch => bindActionCreators(rsActionCreators, dispatch)
    )(AttViewer)
);
