import * as React from 'react';
import './ContentViewer.scss';
import { EditModeWrapper } from './EditModeWrapper';


export const Jumbotron = (props) => {

    const item = props.item;
    if( !item ) return "error";

    const style = {
        border: item.data?.border,
        borderBottom: item.data?.borderBottom,
        margin: item.data?.margin || '0 0 10px 0',
        maxHeight: item.data?.maxHeight || 300
    }

    const imgsrc = item.data?.image || 'images/jumbotron.jpg';
    const wide = item.data?.wide === 'true';

    return <EditModeWrapper edit={props.edit} restore={props.restore} item={item}
        restoredItem={props?.restoredItem} reloadItem={props.reloadItem} reloadParentItem={props.reloadParentItem}
        pageLevel={props.pageLevel}
    >
        <div className='jumbotron' style={style}><img src={imgsrc}/></div>
    </EditModeWrapper>
    
}
