import * as d3 from "d3";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as forecastActionCreators } from "../../store/Forecast.ts";
import * as debounce from "../../utils/debounce";
import { CardLoading } from "../card/CardLoading";
import { FilterChooser } from "../filter/FilterChooser";
import "./DCMSunburstWidget.scss";

const gcolors = {
	background1: "#f4f4f4",
	background2: "#ffffff",
	pageBackground: "#ffffff",

	primary1: "#00517d", // dark blue
	primary2: "#0078bf", // mid blue

	secondary1: "#82c341", // green
	accent1: "#565a5c", // dark gray
	accent2: "#83908f", // light gray

	highlight1: "#ec7a08", // orange
	highlight2: "#f0ab00", // gold
	highlight3: "#822433", // plum

	//define color
	color1: "#bd4008", // dark red
	color2: "#f78026", // orange
	color3: "#faa41b", // yellow
	color4: "#fdbe11",
	color5: "#d2cf20",
	color6: "#9fcf21",
	color7: "#79cf22",
	color8: "#44c721",
	color9: "#1fbf51",
	color10: "#1cad86",
	color11: "#2991b6",
	color12: "#147b9e",
	color13: "#025395",
	color14: "#1c3585",

	// fg: "#4d535a",
	// fgDark: "#2d333a",
	// darkFg: "#ffffff",
};

const arcVisible = (d) => {
	return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
};

const labelVisible = (d) => {
	return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
};

const width = 900;
const radius = width / 6;

const labelTransform = (d) => {
	const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
	const y = ((d.y0 + d.y1) / 2) * radius;
	return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
};

const colors = [
	gcolors.primary1,
	gcolors.primary2,
	gcolors.accent2,
	gcolors.secondary1,
	gcolors.accent2,
];

export class DCMSunburstWidget extends React.Component {
	constructor(props) {
		super(props);
		this.createChart = this.createChart.bind(this);

		// local widget settings
		this.state = {
			agg: props.agg || "product",
		};

		this.props.stateContext.getSaveState = this.getSaveState;
	}

	node;

	componentDidMount() {
		this.refreshIfNeeded();
		this.createChart();
	}

	componentDidUpdate() {
		this.refreshIfNeeded();
		this.createChart();
	}

	formatDataLabel(val) {
		if (val == null) return ".";
		//return this.props.valueFormat || "hi";
		if (this.props.valueFormat === "0%") {
			return (val * 100.0).toFixed(0) + "%";
		} else return val.toFixed(0);
	}

	arc = d3
		.arc()
		.startAngle((d) => d.x0)
		.endAngle((d) => d.x1)
		.padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
		//.padAngle(d => 10)//Math.min((d.x1 - d.x0) / 2, 5))
		.padRadius(radius * 1.5)
		.innerRadius((d) => d.y0 * radius)
		.outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1));

	width = width;
	radius = radius; //this.width / 6

	root = null;
	parent = null;
	g = null;
	path = null;
	label = null;
	title = null;

	getData = () => {
		const config = this.props.config;
		if (!config) return null;

		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);

		const cache = this.props.cache;
		let filterCache = (cache || {})[filter.syntax];

		if (this.props.inInsightCard) {
			filterCache = this.props.filterCache;
		}
		if (!filterCache) return null;

		const metric = globals.get_sunburst_metric();

		let sunburst = {
			name: "product",
			children: config.productSlots.map((slot) => {
				let slotCache = filterCache[slot.uid];
				let value =
					slotCache && slotCache.play ? slotCache.play[metric.key] : null;
				return {
					name: slot.label,
					label2: slot.label + " " + value,
					labelNote: "click here to go back",
					value,
					aggValue: value,
					etc: slot.play,
				};
			}),
		};

		// pull hierarchy dynamically from study
		const selectionHierarchy = [...(this.props.selectionHierarchy || [])];
		selectionHierarchy.reverse().forEach((h) => {
			sunburst = this.groupBy(
				sunburst,
				config.productDefs.default.atts.find((att) => att.name === h.name),
			);
		});

		// sunburst = this.groupBy(sunburst, config.productDefs.default.atts.find(att => att.name === 'model'));
		// sunburst = this.groupBy(sunburst, config.productDefs.default.atts.find(att => att.name === 'brand'));

		// sunburst = this.groupBy(sunburst, config.productDefs.default.atts.find(att => att.name === 'publisher'));

		sunburst.labelNote = "click slice to explore";

		sunburst.aggValue = sunburst.children.reduce((accum, el) => {
			return accum + el.aggValue;
		}, 0);

		this.addLabel2(sunburst, metric);

		//sunburst.label = "Total Revenue";
		sunburst.label = "Total " + metric.label;
		

		return sunburst;
	};

	addLabel2 = (node, metric) => {
		node.label = node.name;
		node.label2 = metric.fformat(node.aggValue);
		node.valueLabel = metric.fformat(node.aggValue);
		if (node.children) {
			node.children.forEach((ch) => this.addLabel2(ch, metric));
		}
	};

	groupBy = (sunburst, att) => {
		// get distinct values
		let newSunburst = {
			name: att.name,
			children: [],
		};

		sunburst.children.forEach((child) => {
			const x = child.etc[att.name];
			let newChild = newSunburst.children.find((ch) => ch.key == x);
			if (!newChild) {
				const xLabel = ((att.levels || []).find((l) => l.value == x) || {})
					.label;
				newChild = {
					key: x,
					name: xLabel,
					labelNote: "click here to go back",
					etc: child.etc, // hacky
					aggValue: 0,
					children: [],
				};
				newSunburst.children.push(newChild);
			}
			newChild.children.push(child);

			newChild.aggValue += child.aggValue;
		});

		return newSunburst;
	};

	createChart = async () => {
		const node = this.node;
		const width = this.width;

		//const format = d3.format(",d")
		//const data = this.getData(); // this.props.data;// || await d3.json("https://raw.githubusercontent.com/d3/d3-hierarchy/v1.1.8/test/data/flare.json")
		//const data = await d3.json("https://raw.githubusercontent.com/d3/d3-hierarchy/v1.1.8/test/data/flare.json")
		const data = this.getData();
		//console.log('data', data);
		if (!data) return;

		//const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, data.children.length + 1))

		const interpolator1 = d3
			.scaleLinear()
			.domain([
				0,
				0.07,
				0.14,
				0.21,
				0.28,
				0.35,
				0.42,
				0.49,
				0.56,
				0.63,
				0.7,
				0.77,
				0.84,
				1,
			])
			.range([
				gcolors.color13,
				gcolors.color12,
				gcolors.color11,
				gcolors.color10,
				gcolors.color9,
				gcolors.color8,
				gcolors.color7,
				gcolors.color6,
				gcolors.color5,
				gcolors.color4,
				gcolors.color3,
				gcolors.color2,
				gcolors.color1,
			]);
		// .range([
		//     gcolors.primary1,
		//     gcolors.primary2,
		//     gcolors.secondary1,
		//     gcolors.accent1,
		//     gcolors.accent2,
		//     gcolors.highlight1,
		//     gcolors.highlight2,
		//     gcolors.highlight3,
		// ]);
		const color = d3.scaleOrdinal(
			d3.quantize(interpolator1, data.children.length + 1),
		);

		// const fg_interpolator = d3.scaleLinear()
		//     .domain([
		//         0,
		//         0.1,
		//         0.11,
		//         0.25,
		//         0.3,
		//         0.35,
		//         0.5,
		//         0.6,
		//         1
		//     ])
		//     .range([
		//         '#cfcfcf',
		//         '#cfcfcf',
		//         '#000000',
		//         '#000000',
		//         '#cfcfcf',
		//         '#000000',
		//         '#000000',
		//         '#000000',
		//         '#000000',
		//     ]);

		const fg_interpolator = d3
			.scaleLinear()
			.domain([0, 0.14, 0.141, 0.73, 0.731, 1])
			.range([
				"#ffffff",
				"#ffffff",
				"#000000",
				"#000000",
				"#ffffff",
				"#ffffff",
			]);

		const fg_color = d3.scaleOrdinal(
			d3.quantize(fg_interpolator, data.children.length + 1),
		);

		this.root = this.partition(data);
		const root = this.root;
		root.each((d) => (d.current = d));

		const svg = d3
			.select(node) //DOM.svg(width, width))
			.style("width", "700")
			.style("height", "auto")
			.style("font", "10px sans-serif");

		svg.selectAll("*").remove();
		svg
			.style("width", "100%")
			.style("height", "100%")
			.style("font", "10px sans-serif");

		this.g = svg
			.append("g")
			.attr("transform", `translate(${width / 2},${width / 2})`);
		const g = this.g;

		this.path = g
			.append("g")
			.selectAll("path")
			.data(root.descendants().slice(1))
			.join("path")
			//.attr("fill", d => { while (d.depth > 1) d = d.parent; return color(d.data.name); })
			//.attr("fill-opacity", d => arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)

			.attr("fill", (d) => {
				while (d.depth > 1) d = d.parent;
				return color(d.data.name);
			})
			//.attr("fill", d => { return colors[(this.getLevel(d) - 1) % colors.length]; })
			.attr("fill-opacity", (d) =>
				arcVisible(d.current) ? (d.children ? 1.0 : 0.8) : 0,
			)
			.attr("d", (d) => this.arc(d.current));

		this.path
			.filter((d) => d.children)
			.style("cursor", "pointer")
			.on("click", this.clicked);

		this.path
			.append("title")
			//.text(d => `${d.ancestors().map(d => d.data.name).reverse().join("/")}\n${format(d.value)}`);
			.text(
				(d) =>
					`${d
						.ancestors()
						.map((d) => d.data.name)
						.reverse()
						.join("/")}\n${d.data.valueLabel}`,
			);

		this.label = g
			.append("g")
			.attr("pointer-events", "none")
			.attr("text-anchor", "middle")
			.style("user-select", "none")
			.selectAll("text")
			.data(root.descendants().slice(1))
			.join("text")
			.attr("dy", "0.35em")
			.attr("fill-opacity", (d) => +labelVisible(d.current))
			.attr("transform", (d) => labelTransform(d.current))
			.attr("fill", (d) => {
				while (d.depth > 1) d = d.parent;
				return fg_color(d.data.name);
			})
			.text((d) => d.data.name);

		this.title = g
			.append("text")
			.attr("x", 0)
			.attr("y", -25)
			.attr("text-anchor", "middle")
			.attr("font-size", 16)
			.attr("style", "fill: #000000")
			.text(data.label);

		this.subTitle = g
			.append("text")
			.attr("x", 0)
			.attr("y", 0)
			.attr("text-anchor", "middle")
			.attr("font-size", 16)
			.attr("style", "fill: #000000")
			.text(data.label2);

		this.titleNote = g
			.append("text")
			.attr("x", 0)
			.attr("y", 25)
			.attr("text-anchor", "middle")
			.attr("font-size", 14)
			.attr("style", "fill: #afafaf")
			.text(data.labelNote)
			.attr("data-hideexport", 1);

		this.parent = g
			.append("circle")
			.datum(root)
			.attr("r", this.radius)
			.attr("fill", "none")
			.attr("pointer-events", "all")
			.on("click", this.clicked);
	};

	clicked = (p) => {
		this.parent.datum(p.parent || this.root);

		this.root.each(
			(d) =>
				(d.target = {
					x0:
						Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
						2 *
						Math.PI,
					x1:
						Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
						2 *
						Math.PI,
					y0: Math.max(0, d.y0 - p.depth),
					y1: Math.max(0, d.y1 - p.depth),
				}),
		);

		if (p.data) {
			this.title.text(p.data.label);
			this.subTitle.text(p.data.label2);
			this.titleNote.text(p.data.labelNote);
		}

		const t = this.g.transition().duration(750);

		// Transition the data on all arcs, even the ones that aren’t visible,
		// so that if this transition is interrupted, entering arcs will start
		// the next transition from the desired position.
		this.path
			.transition(t)
			.tween("data", (d) => {
				const i = d3.interpolate(d.current, d.target);
				return (t) => (d.current = i(t));
			})
			.filter(function (d) {
				return +this.getAttribute("fill-opacity") || arcVisible(d.target);
			})
			//.attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
			//.attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 1.0 : 0.4) : 0)
			.attr("fill-opacity", (d) =>
				arcVisible(d.target) ? (d.children ? 1.0 : 0.8) : 0,
			)
			.attrTween("d", (d) => () => this.arc(d.current));

		this.label
			.filter(function (d) {
				return +this.getAttribute("fill-opacity") || labelVisible(d.target);
			})
			.transition(t)
			.attr("fill-opacity", (d) => +labelVisible(d.target))
			.attrTween("transform", (d) => () => labelTransform(d.current));
	};

	getLevel = (data) => {
		//console.log('getLevel', data);
		//return data.height;
		return data.parent ? 1 + this.getLevel(data.parent) : 1;
	};

	partition = (data) => {
		const root = d3
			.hierarchy(data)
			.sum((d) => d.value)
			.sort((a, b) => b.value - a.value);
		return d3.partition().size([2 * Math.PI, root.height + 1])(root);
	};

	refreshIfNeeded = debounce.debounce(() => {
		//if( !this.props.selection ) return;

		// cancel if insight card
		if (this.props.inInsightCard) return;

		// cancel if config isn't ready
		if (!this.props.etag) return;

		// cancel if cached data already exists, with matching filter and etag
		const cache = this.props.cache;
		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);
		const filterCache = (cache || {})[filter.syntax];
		if (filterCache && filterCache.error) return;

		// const productCache = (filterCache || {})[this.props.selection.product];
		if (filterCache && filterCache.etag === this.props.etag) return;

		// cancel if cached data is loading
		if (filterCache && filterCache.loading) return;

		// refresh is needed. request it.
		this.props.requestForecast();
	}, debounce.forecastDelay);

	// these are needed from parent state
	static inheritOptions = ["configEtag"];

	static scenarioDriven = true;

	// returns widget's local settings (for save state)
	getSaveState = () => {
		const config = this.props.config;
		if (!config) return null;

		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);

		// get the cache
		const cache = this.props.cache;

		const filterCache = (cache || {})[filter.syntax];

		return {
			filterCache: filterCache,
			filter,
		};
	};

	isLoading = () => {
		let fCache = (this.props.cache || {})[this.props.filter.syntax] || {};
		return fCache.loading; // || fCache.etag !== this.props.etag;
	};

	render() {
		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);

		const cache = this.props.cache;
		let filterCache = (cache || {})[filter.syntax];

		if (this.props.inInsightCard) {
			filterCache = this.props.filterCache;
		}
		if (filterCache && !filterCache.loading) {
			if (this.props.loaded && typeof this.props.loaded === "function")
				this.props.loaded(!filterCache.loading);
		}
		if (this.props.getJSONData && typeof this.props.getJSONData === "function")
			this.props.getJSONData(null, true);

		const content = !filterCache ? null : (
			<div className="middle-content">
				{
					<svg
						key="singleton"
						ref={(node) => (this.node = node)}
						style={{
							border: "0px",
							width: 400,
							height: 900,
							...this.props.style,
						}}
						viewBox={"0 0 " + width + " " + width}
						preserveAspectRatio="xMidYMid meet"
					/>
				}
			</div>
		);

		return (
			<div className="widget dcm-sunburst-widget">
				<div className="widget-header">
					{this.props.title ? (
						<div className="title">{this.props.title}</div>
					) : null}
					<div className="filter-etc">
						<FilterChooser
							mini={true}
							disabled={this.props.inInsightCard}
							selection={filter}
						/>
					</div>
				</div>
				<div className="widget-body">{content}</div>
				<CardLoading loading={filterCache && filterCache.loading} />
			</div>
		);
	}
}

DCMSunburstWidget = connect(
	(state, ownProps) => ({
		study: state.study,
		studyId: state.study?.uid,
		selectionHierarchy: state.study?.selectionHierarchy,
		config: state.study?.config,
		...(ownProps.inDataCard
			? {
					etag: ((state.study || {}).config || {}).etag,
					filter: (state.filter || {}).selectedFilter,
					cache: (state.cache || {}).forecast,
			  }
			: {}),
	}),
	(dispatch) => bindActionCreators(forecastActionCreators, dispatch),
)(DCMSunburstWidget);
