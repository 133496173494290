import React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import * as globals from '../../globals';
import { actionCreators as configActionCreators } from '../../store/Config.ts';
import './SelectionBlock.scss';


export const SelectionBlock = (props) => {

    const [showMenu, setShowMenu] = useState(false);

    const handleClick = () => {
        if( props.onClick && !props.clickText2 && !props.menu2 ){
            props.onClick();
        }
    }

    const handleClick1 = () => {
        if( props.onClick ){
            props.onClick();
        }
    }

    const handleClick2 = () => {
        if( props.onClick2 ){
            props.onClick2();
        }
    }

    let hider;

    const delayedHideMenu = () => {
        hider = setTimeout(() => {
            setShowMenu(false);
            //$('#mydiv').fadeOut('fast');
        }, 500); // <-- time in milliseconds
    }

    const cancelHideMenu = () => {
        clearTimeout(hider);
    }

    const study_folder = props.study_folder;

    const imageProperty = props.imageProperty || "imageLogo";

    const image = props[imageProperty] || "_";
    const showImage = props.showImage === null || props.showImage === undefined || props.showImage ? true : false;
    const typeLabelTemplate = props.typeLabelTemplate || "{type}";

    const { hideLabel, hideType } = props;
    

    let logoContainerStyle = {

    };
    if (props.largeImageForm) {
        logoContainerStyle.height = props.largeImageHeight;
        logoContainerStyle.padding = props.largeImagePadding;
    }

    return <>
        <div className={
                (props.className ? ' ' + props.className + ' ' : '')
                + 'selection-block'
                + (props.largeImageForm ? ' large-image' : '')
                + (props.standalone ? ' standalone' : '')
                + (props.selected ? ' sel' : '')
                + (props.isPrimary ? ' primary' : '')
                + (showImage ? ' has-image' : '')
                + (props.disabled ? ' disabled' : '')
                + (props.clickText2 ? ' prevent-hover' : '')
                + (props.fade ? ' fade' : '')
                + ((props.type === 'product' && !props.available) ? ' not-available' : '')
            }
            style={props.style}
            onClick={handleClick}
            onMouseLeave={() => delayedHideMenu()}
    >
       
        {showImage ?
            <div className="logo-container" style={logoContainerStyle}>
                {props.type === 'product' && !props.available ?
                    <div className='not-available-text'>Not<br />Available</div> :
                    (image === '_') ? null :
                    <img
                        src={`${globals.cdnUrl}/cdn-shared/${study_folder}/images/${(image || '')}`}
                        //alt={props.label}
                            className="logo"
                            style={{
                                objectFit: props.imageFit || "contain",
                                ...props.imageStyle
                            }}
                    />
                }
            </div>
            : null
        }
        {!hideLabel || !hideType ?
            <div className='text' style={props.textSectionStyle}>
                {hideLabel ? null :
                    <div className="text1" style={props.labelStyle}>
                        {props.label}
                    </div>
                }
                {hideType ? null :
                    <div className="text2" style={props.typeLabelStyle}>
                        {typeLabelTemplate.replaceAll("{type}", props.typeLabel)}
                    </div>
                }
            </div>
            : null
        }
        {props.clickText ? <div className="click-button" onClick={handleClick1}>
            {props.clickText}
        </div> : null}
        {props.clickText2 ? <div className="click-button2" onClick={handleClick2}>
            {props.clickText2}
        </div> : null}
        {props.menu2 ? <div className="click-button2" onClick={() => setShowMenu(!showMenu)}>
            ...
        </div> : null}
        
    </div>
        {showMenu ? <div className="selection-block-menu" onMouseEnter={() => cancelHideMenu()} onMouseLeave={() => delayedHideMenu()}>
            {props.menu2?.map((menuItem, idx) => <div key={idx} className="item" onClick={() => {
                let x = menuItem;
                if (x.onClick) {
                    setShowMenu(false);
                    x.onClick(x);
                }
            }}>
            {menuItem.label}
        </div>)}
    </div> : null}
    </>
    
}

