import { format as d3Format } from "d3-format";
import * as React from "react";
import { connect } from "react-redux";
import {
	Bar,
	BarChart,
	CartesianGrid,
	ReferenceLine,
	ComposedChart,
	LabelList,
	Legend,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
	Surface,
	Symbols,
} from "recharts";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as analyticsActionCreators } from "../../store/Analytics.ts";
import { CardLoading } from "../card/CardLoading";
import { CompareChooser } from "../filter/CompareChooser";
import { FilterChooser } from "../filter/FilterChooser";
import { ProductBlockMini } from "../product-drawer/ProductBlockMini";
import CustomLegend from "../common/CustomLegend";
import CustomizedTick from "../common/CustomizedTick";

const fmt_nps = d3Format(".0f");
const fmt_pct = d3Format(".0%");

const fmt_nps2 = d3Format(".1f");
const fmt_pct2 = d3Format(".1%");

export class NPSViewerW extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};

		// Allow card to be saved as insight card. Not the greatest pattern.
		this.props.stateContext.getSaveState = this.getSaveState;
	}

	componentDidMount() {
		this.refreshIfNeeded();
	}

	componentDidUpdate() {
		// if (this.props.cache && this.props.cache.loaded)
		// 	this.props?.loaded(this.props.cache.loaded);
		// this.refreshIfNeeded();
	}

	refreshIfNeeded = () => {
		// cancel if nothing selected
		const selection = this.props.selection;
		if (!selection) return;

		// cancel if insight card
		if (this.props.inInsightCard) return;

		if (!this.props.cache) {
			const rootPath = this.props.path;
			const compareSelection = this.props.compareSelection;
			const cgroups = this.props.entries || compareSelection?.groups;
			const cgrouphash = this.props.entries ? "" : ":" + compareSelection?.hash;
			const path = `${rootPath}:${this.props.selection.model}${cgrouphash}`;

			const filter = this.props.filter;
			const query = this.props.getQuery(selection, filter, cgroups);
			// console.log('refreshing: selection=', selection);
			// console.log('refreshing: filter=', filter);
			// console.log('refreshing: cgroups=', cgroups);
			this.props.loadYak(path, `${filter.syntax}:${path}`, query);
		}
	};

	static inheritOptions = [];

	static getTitle = (state) => {
		return `${state.title}`;
	};

	static getSources = (state) => state.sources || [];

	getSaveState = () => {
		return {
			selection: this.props.selection,
			cache: this.props.cache,
			filter: this.props.filter,
			compareSelection: this.props.compareSelection, // ????
			entries: this.props.entries, // ????
		};
	};

	render() {
		const study = this.props.study;
		if (!study) return;

		let cache = this.props.cache;
		//console.log('render cache', cache);
		let selection = this.props.selection;
		if (!selection) return "no selection";

		const selectionLevel = this.props.selectionLevel;

		const filter = this.props.filter;
		const compare = this.props?.compareSelection;

		const model = selection?.[selectionLevel];
		const data = cache ? cache.value : null;

		const getModelData = (model, entry, prec) => {
			const topbox = ((data[`${model}:topbox`] || {})[entry.syntax] || {})
				.value;
			const topbox_all = (data[`*:topbox`] || {})[entry.syntax] || {}; //.value;
			const botbox = ((data[`${model}:botbox`] || {})[entry.syntax] || {})
				.value;
			const botbox_all = (data[`*:botbox`] || {})[entry.syntax] || {}; //.value;
			const midbox =
				isNaN(topbox) || isNaN(botbox) ? null : 1.0 - topbox - botbox;
			const midbox_all =
				isNaN(topbox_all) || isNaN(botbox_all)
					? null
					: 1.0 - topbox_all - botbox_all;
			const nps =
				isNaN(topbox) || isNaN(botbox) ? null : (topbox - botbox) * 100.0;
			const nps_all =
				isNaN(topbox_all) || isNaN(botbox_all)
					? null
					: (topbox_all - botbox_all) * 100.0;

			return {
				...entry,
				topbox,
				topbox_all,
				botbox,
				botbox_all,
				midbox,
				midbox_all,
				nps,
				nps_all,
				n: ((data[`${model}:topbox`] || {})[entry.syntax] || {}).n,
			};
		};

		const entries = this.props?.entries || compare?.groups;

		const data2 =
			data && model
				? entries?.map((entry) => getModelData(model, entry, ""))
				: [];
		const data3 = data2?.filter((f, i) => i < 10);

		const renderLegend = (e) => {
			let customVal = { ...e, payload: e.payload.reverse() };
			return <CustomLegend {...customVal} />;
		};

		const view = this.props.view || "line-nps-only";
		if (this.props.getJSONData && typeof this.props.getJSONData === "function")
			this.props.getJSONData(data2);

		const content = !cache ? null : cache?.loading ? null : view === "bar" ? (
			<ResponsiveContainer>
				<BarChart
					data={data2}
					barGap={0}
					layout="vertical"
					margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
					<XAxis type="number" />
					<YAxis
						type="category"
						dataKey="label"
						width={135}
						tick={<CustomizedTick />}
					/>
					<Tooltip />
					<Legend />
					<Bar
						isAnimationActive={false}
						dataKey="nps_all"
						formatter={fmt_nps2}
						fill={globals.npsColors.gap}
						name="All models"
						label={{ formatter: fmt_nps, position: "right" }}>
						{/* <LabelList dataKey="nps_all" position="right" formatter={fmt_nps} /> */}
					</Bar>
					<Bar
						isAnimationActive={false}
						dataKey="nps"
						formatter={fmt_nps2}
						fill={"#3969d9"}
						name={selection.label}
						label={{ formatter: fmt_nps, position: "right" }}>
						{/* <LabelList dataKey="nps" position="outsideLeft" formatter={fmt_nps} /> */}
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		) : view === "column" ? (
			<ResponsiveContainer>
				<BarChart
					data={data2}
					barGap={0}
					layout="horizontal"
					margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
					<ReferenceLine
						y={0}
						stroke={globals.npsColors.gap}
						strokeDasharray="3 3"
					/>
					<YAxis type="number" tick={<CustomizedTick />} />
					<XAxis type="category" dataKey="label" />
					<Tooltip />
					<Legend content={<CustomLegend />} />
					<Bar
						isAnimationActive={false}
						dataKey="nps_all"
						formatter={fmt_nps2}
						fill={globals.npsColors.gap}
						name="All models"
						label={{
							formatter: fmt_nps,
							position: Math.sign(fmt_nps) === "-1" ? "bottom" : "top",
						}}></Bar>
					<Bar
						isAnimationActive={false}
						dataKey="nps"
						formatter={fmt_nps2}
						fill={"#3969d9"}
						name={selection.label}
						label={{
							formatter: fmt_nps,
							position: Math.sign(fmt_nps) === "-1" ? "bottom" : "top",
						}}></Bar>
				</BarChart>
			</ResponsiveContainer>
		) : view === "line-nps-only" ? (
			<ResponsiveContainer>
				<LineChart data={data3}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="label" />
					<YAxis tick={<CustomizedTick />} />
					<Tooltip />
					<Legend />
					<Line dataKey="nps" type="monotone" stroke="#5d99d0" name="NPS" />
				</LineChart>
			</ResponsiveContainer>
		) : view === "line" ? (
			<ResponsiveContainer>
				<LineChart data={data3}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="label" />
					<YAxis yAxisId="left" orientation="left" />
					<YAxis yAxisId="right" orientation="right" stroke="orange" />
					<Tooltip />
					<Legend />
					<Line
						dataKey="botbox"
						type="monotone"
						stackId="a"
						yAxisId="left"
						stroke="#4771be"
						name="Detractor"
					/>
					<Line
						dataKey="midbox"
						type="monotone"
						stackId="a"
						yAxisId="left"
						stroke="#a3a3a3"
						name="Passive"
					/>
					<Line
						dataKey="topbox"
						type="monotone"
						stackId="a"
						yAxisId="left"
						stroke="#5d99d0"
						name="Promoter"
					/>
					<Line
						dataKey="nps"
						type="monotone"
						stackId="b"
						yAxisId="right"
						stroke="orange"
						name="NPS"
					/>
				</LineChart>
			</ResponsiveContainer>
		) : view === "iavg" ? null : (
			// <ResponsiveContainer>
			//     <LineChart data={data3}>
			//         <CartesianGrid strokeDasharray="3 3"/>
			//         <XAxis dataKey="label"/>
			//         <YAxis />
			//         <Tooltip/>
			//         <Legend />
			//         <Line dataKey="nps" type="monotone" stroke="orange" name="NPS"/>
			//         <Line dataKey="avg_nps2" type="monotone" stroke="blue" name="Industry Avg"/>
			//     </LineChart>
			// </ResponsiveContainer>
			<div style={{ height: "100%" }}>
				<div style={{ height: "30%" }}>
					<ResponsiveContainer>
						<ComposedChart
							data={data3}
							isAnimationActive={false}
							margin={{ top: 20, bottom: 10 }}>
							<XAxis dataKey="label" hide={true} />
							<YAxis yAxisId="right" hide={true} tick={<CustomizedTick />} />
							<Tooltip formatter={fmt_nps2} />
							<Legend
								width={80}
								align="left"
								layout="vertical"
								verticalAlign="middle"
								type="line"
								content={<CustomLegend />}
							/>
							<Line
								isAnimationActive={false}
								dataKey="nps"
								type="monotone"
								stackId="b"
								yAxisId="right"
								stroke={globals.npsColors.nps}
								name="NPS">
								<LabelList dataKey="nps" position="top" formatter={fmt_nps} />
							</Line>
						</ComposedChart>
					</ResponsiveContainer>
				</div>
				<div style={{ height: "70%" }}>
					<ResponsiveContainer>
						<ComposedChart
							data={data3}
							isAnimationActive={false}
							maxBarSize={50}>
							<XAxis dataKey="label" />
							<YAxis
								yAxisId="left"
								orientation="left"
								hide={true}
								tick={<CustomizedTick />}
							/>
							<Tooltip />
							<Legend
								width={80}
								align="left"
								layout="vertical"
								verticalAlign="middle"
								content={renderLegend}
							/>
							<Bar
								isAnimationActive={false}
								formatter={fmt_pct2}
								dataKey="botbox"
								type="monotone"
								stackId="a"
								yAxisId="left"
								fill={globals.npsColors.detractor}
								name="Detractor">
								<LabelList
									dataKey="botbox"
									position="center"
									formatter={(val) => (val > 0.05 ? fmt_pct(val) : null)}
									fill={globals.npsColors.detractorFg}
								/>
							</Bar>
							<Bar
								isAnimationActive={false}
								formatter={fmt_pct2}
								dataKey="midbox"
								type="monotone"
								stackId="a"
								yAxisId="left"
								fill={globals.npsColors.neutral}
								name="Neutral">
								<LabelList
									dataKey="midbox"
									position="center"
									formatter={(val) => (val > 0.05 ? fmt_pct(val) : null)}
									fill={globals.npsColors.neutralFg}
								/>
							</Bar>
							<Bar
								isAnimationActive={false}
								formatter={fmt_pct2}
								dataKey="topbox"
								type="monotone"
								stackId="a"
								yAxisId="left"
								fill={globals.npsColors.promoter}
								stroke="#ffffff"
								name="Promoter">
								<LabelList
									dataKey="topbox"
									position="center"
									formatter={(val) => (val > 0.05 ? fmt_pct(val) : null)}
									fill={globals.npsColors.promoterFg}
								/>
							</Bar>
						</ComposedChart>
					</ResponsiveContainer>
				</div>
			</div>
		);
		return (
			<div className="widget">
				<div className="widget-header">
					<div className="title">
						{this.props.title ? (
							this.props.title
						) : (
							<>NPS by {this.props.cLabel}</>
						)}
					</div>
					<div className="selection">
						<ProductBlockMini
							{...selection}
							disabled={this.props.inInsightCard}
							allowChange={selectionLevel}
						/>
					</div>
					<div className="filter-etc">
						<FilterChooser
							mini={true}
							hideWave={true}
							disabled={this.props.inInsightCard}
							selection={this.props.filter}
						/>
					</div>
					{this.props.entries ? null : (
						<div className="filter-etc">
							<CompareChooser
								mini={true}
								disabled={this.props.inInsightCard}
								selection={compare}
							/>
						</div>
					)}
				</div>
				<div className="widget-body">{content}</div>
				<CardLoading loading={cache && cache.loading} />
			</div>
		);
	}
}

NPSViewerW = connect(
	(state, ownProps) => {
		const filter =
			state.filter?.selectedFilter || globals.getDefaultFilter(state.study);
		const selectionLevel = ownProps.selectionLevel || "product";
		const selection = state.selections?.[selectionLevel];

		const compareSelection = state.compare?.selection || globals.defaultCompare;
		const cgroups = ownProps.entries || compareSelection?.groups;
		const cgrouphash = ownProps.entries ? "" : ":" + compareSelection?.hash;
		const path = `${filter.syntax}:${ownProps.path}:${
			selection?.[selectionLevel] || "."
		}${cgrouphash}`;
		//console.log('connecting path', path);

		return {
			study: state.study,
			selectionLevel,
			...(ownProps.inDataCard
				? {
						filter,
						selection,
						compareSelection,
						cache: state.cache.analytics[path],
				  }
				: null),
		};
	},
	(dispatch) => bindActionCreators(analyticsActionCreators, dispatch),
)(NPSViewerW);
