import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { computeFordModel } from '../../utils/forecastHelper'
import { getMeta } from '../../utils/metaUtils'
import { json } from 'd3'


export function ImportancesToFile({ payload }) {

    const { prodsToRun, modelConfig, modelInfo, attCondMetaTableName, attCondMetaLookupName, modelName, atts, filter, filetype } = payload

    const study = useSelector(state => state.study)
    const auth = useSelector(state => state.auth)
    const [running, setRunning] = useState(false)
    const [buttonClicked, setButtonClicked] = useState(false)
    const [conditions, setConditions] = useState(null)
    const [attLookups, setAttLookups] = useState(null)
    const [progress, setProgress] = useState(null)

    let base_copy = JSON.parse(JSON.stringify(prodsToRun))

    const getConditions = async (metakey) => {
        try {
            const res = await getMeta(study.uid, auth, metakey)
            return res || null
        } catch (error) {
            console.error('Error fetching meta:', error)
            return null
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const fetchedConditions = await getMeta(study.uid, auth, attCondMetaTableName)
            const fetchedAttLookups = await getMeta(study.uid, auth, attCondMetaLookupName)
            setConditions(fetchedConditions)
            setAttLookups(fetchedAttLookups)
        }

        fetchData()
    }, [attCondMetaTableName, attCondMetaLookupName, study.uid, auth])
    

    function applyConditions(curr_product, atts, rules, lookups) {
        let updatedAtts = atts.map(att => ({ ...att, readOnly: false }))

        rules?.forEach(rule => {
            rule.values.forEach(val => {
                if (curr_product[rule.attribute] === val.value) {
                    val.conditions.forEach(cond => {
                        const targetAttribute = updatedAtts.find(att => att.name === cond.attribute)
                        if (targetAttribute) {
                            targetAttribute.readOnly = cond.readOnly
                        }
                    })
                }
            })
        })

        function updateLevels(attributeName, levels) {
            return updatedAtts.map(att => {
                if (att.name === attributeName) {
                    const newLevels = levels.map((level, index) => ({
                        value: index + 1,
                        label: level
                    }))
                    return { ...att, levels: newLevels }
                }
                return att
            })
        }

        function applyLookups(currProduct, lookupData) {
            for (const key in lookupData) {
                const value = currProduct[key]
                if (lookupData[key][value]) {
                    const data = lookupData[key][value]
                    for (const attr in data) {
                        if (Array.isArray(data[attr])) {
                            updatedAtts = updateLevels(attr, data[attr])
                        } else if (typeof data[attr] === 'object') {
                            if (currProduct[attr] !== undefined) {
                                applyLookups(currProduct, data[attr])
                            }
                        }
                    }
                }
            }
        }

        if (lookups) {
            for (const lookupKey in lookups) {
                applyLookups(curr_product, { [lookupKey]: lookups[lookupKey] })
            }
        }

        return updatedAtts
    }

    const runForecasts = async (curr_prod, prodatts) => {
        let counter = 1
        let resultsCollection = []

        prodatts = applyConditions(curr_prod, prodatts, conditions.rules, attLookups)

        for (let att of prodatts) {
            if (att.type === 'discrete' && !att.readOnly) {
                let originalValue = curr_prod[att.name]

                curr_prod[att.name] = 1

                for (let level of att.levels) {
                    curr_prod[att.name] = level.value

                    const result = await computeFordModel(study.uid, auth, {    
                        config: base_copy,
                        filter,
                        modelInfo,
                        brandvalues: [], 
                        playHash: modelConfig.playHash
                    })
                    let share = result?.[curr_prod.uid]?.share
                    console.log('Forecast happened', counter)
                    counter++

                    resultsCollection.push({
                        id: curr_prod.uid,
                        label: curr_prod.label,
                        att: att.name,
                        levelLabel: level.label,
                        levelValue: level.value,
                        result: share,
                    })
                }

                curr_prod[att.name] = originalValue
            }
        }
        return resultsCollection
    }

    const sortAndReproportion = (data) => {
        const minValue = data[0].result

        if (minValue < 0) {
            const reproportionedData = data.map(item => ({
                ...item,
                result: Math.abs(item.result - minValue)
            }))
            return reproportionedData
        } else {
            return data
        }
    }

    const processProduct = async (prod) => {
        const baseResult = await computeFordModel(study.uid, auth, {
            config: modelConfig?.base,
            filter,
            modelInfo,
            brandvalues: [],
            playHash: modelConfig.playHash
        })

        const base_result = baseResult
        const baseVal = base_result[prod.uid]?.share

        const forecastCollection = await runForecasts(prod, atts)
        const groupedByAttribute = forecastCollection.reduce((acc, item) => {
            acc[item.att] = acc[item.att] || []
            acc[item.att].push(item)
            return acc
        }, {})

        let data = {}
        Object.keys(groupedByAttribute).forEach((att, idx) => {
            const diff_from_base = groupedByAttribute[att].map(itm => ({
                ...itm,
                result: itm.result - baseVal,
                color: '#5383f2'
            }))?.sort((a, b) => a.result - b.result)

            const finalres = sortAndReproportion(diff_from_base)

            data[att] = finalres.sort((a, b) => a.levelValue - b.levelValue)
        })

        return data
    }

    const aggregateResults = (allResults) => {
        const aggregated = {}
        
        allResults.forEach(prodResults => {
            Object.keys(prodResults).forEach(att => {
                if (!aggregated[att]) {
                    aggregated[att] = {}
                }
                prodResults[att].forEach(item => {
                    if (!aggregated[att][item.levelValue]) {
                        aggregated[att][item.levelValue] = { ...item, count: 0, totalResult: 0 }
                    }
                    aggregated[att][item.levelValue].totalResult += item.result
                    aggregated[att][item.levelValue].count += 1
                })
            })
        })

        const averaged = {}
        Object.keys(aggregated).forEach(att => {
            averaged[att] = Object.values(aggregated[att]).map(item => ({
                id: item.id,
                label: item.label,
                att: item.att,
                levelLabel: item.levelLabel,
                levelValue: item.levelValue,
                result: item.totalResult / item.count,
                color: item.color
            }))
        })

        return averaged
    }

   const sumResults = (averageResults) => {
    const attributeSums = {}
    let totalSum = 0

    // Sum the results for each attribute
    Object.keys(averageResults).forEach(attribute => {
        attributeSums[attribute] = averageResults[attribute].reduce((sum, item) => sum + item.result, 0)
        totalSum += attributeSums[attribute]
    })

    // Normalize the results for each attribute
    const normalizedResults = Object.keys(attributeSums).map(attribute => ({
        attribute: attribute,
        value: attributeSums[attribute] / totalSum
    }))

    return normalizedResults
}



    const saveToJsonFile = (data, filename) => {
        const jsonStr = JSON.stringify(data, null, 2)
        const blob = new Blob([jsonStr], { type: 'application/json' })
        const link = document.createElement('a')
        link.download = filename
        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const saveToExcelFile = (d, filename) => {
        const headers = ["Attribute", "Value"]
        const rows = []

       d.forEach(item => {
            rows.push([
                item.attribute,
                item.value
            ])
        })

        const csvContent = [
            headers.join(","),
            ...rows.map(row => row.join(","))
        ].join("\n")

        const blob = new Blob([csvContent], { type: 'text/csvcharset=utf-8' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const runForecaster = async () => {
        setButtonClicked(true)
        setRunning(true)

        let allResults = []
        
        let prodCounter = 0
        for (const prod of base_copy) {
            const prodResults = await processProduct(prod)
            setProgress(Math.round((prodCounter++ / base_copy.length) * 100),0)
            
            allResults.push(prodResults)
        }
        console.log('allResults', allResults);

        let averagedResults = aggregateResults(allResults)
        console.log('averagedResults', averagedResults);
        const importances = sumResults(averagedResults)
        const isPickup = modelInfo?.name?.includes('suv') ? 'suv' : 'truck'
        

        console.log('importances', importances);
        if (filetype.toLowerCase() === 'excel') {
            saveToExcelFile(importances, `Importances_${isPickup}.csv`)
        }
        else {
            saveToJsonFile(importances, `Importances_${isPickup}.json`)
        }
        setRunning(false)
    }



    const handleClick = async () => {
        await runForecaster()
    }

    return (
        <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <div style={{ fontSize: 12, color: '#808080', marginTop:40, marginBottom:15}}>
                Generates Average Sensitivities data in an Excel or Json file 
            </div>
            <div >
                <button className='btn border' onClick={handleClick}> Run Importances</button>
            </div>
            {
                running ?
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                        <div style={{marginTop:30, marginBottom:15}}>
                            <i className='fad fa-truck-monster fa-spin' style={{fontSize:28, color:'#0d47a1'}}></i>
                        </div>
                        <div>
                            <span style={{fontSize:18}}>{progress} %</span>
                        </div>
                    </div>
                : 
                buttonClicked && <div style={{marginTop:30, fontSize:18, color:'#006400', fontWeight:700, letterSpacing:3, textTransform:'uppercase' }}>Finished!</div>
            }
       </div>
    )
    
}

// export function AvgSensitivitiesOutToFile({payload}) {
//     const { prodsToRun, modelConfig, modelInfo, attCondMetaTableName, attCondMetaLookupName, modelName, atts, filter, filetype } = payload
    
//     const study = useSelector(state => state.study)
//     const auth = useSelector(state => state.auth)
//     const [running, setRunning] = useState(false)
//     const [buttonClicked, setButtonClicked] = useState(false)
//     const [conditions, setConditions] = useState(null)
//     const [attLookups, setAttLookups] = useState(null)

//     // const base = prodsToRun// modelConfig?.base
//     let base_copy = JSON.parse(JSON.stringify(prodsToRun))

//     const getConditions = async(metakey) => {
//         try {
//             const res = await getMeta(study.uid, auth, metakey)
//             return res || null
//         } catch (error) {
//             console.error('Error fetching meta:', error)
//             return null
//         }
//     }

//     useEffect(() => {
//         const fetchData = async () => {
//             const fetchedConditions = await getMeta(study.uid, auth, attCondMetaTableName)
//             const fetchedAttLookups = await getMeta(study.uid, auth, attCondMetaLookupName)
//             setConditions(fetchedConditions)
//             setAttLookups(fetchedAttLookups)
//         }

//         fetchData()
//     },[attCondMetaTableName, attCondMetaLookupName, study.uid, auth])


//     // let conditions = getConditions( attCondMetaTableName)
//     // let attLookups = getConditions( attCondMetaLookupName)
//     console.log('conditions', conditions)
//     console.log('attLookups', attLookups)

//     function applyConditions(curr_product, atts, rules, lookups) {
//         // console.log('products', curr_product)
//         // console.log('rules', Array.isArray(rules), rules)
//         // console.log('atts', atts)

//         //set all att's readOnly prop to false (show on ui)
//         let updatedAtts = atts.map(att => ({ ...att, readOnly: false }))

//         // apply att conditions rules
//         rules?.forEach(rule => {

//             rule.values.forEach(val => {
                
//                 if (curr_product[rule.attribute] === val.value) {
//                     // Apply each condition specified for this value
//                     val.conditions.forEach(cond => {
//                         // Find the attribute in updatedAtts to set readOnly
//                         const targetAttribute = updatedAtts.find(att => att.name === cond.attribute)
//                         if (targetAttribute) {
//                             targetAttribute.readOnly = cond.readOnly
//                         }
//                     })
//                 }
//             })
//         })

        
//         // Helper function to update attribute levels
//         function updateLevels(attributeName, levels) {
//             return updatedAtts.map(att => {
//                 if (att.name === attributeName) {
//                     const newLevels = levels.map((level, index) => ({
//                         value: index + 1, // Assuming the value is the index + 1
//                         label: level
//                     }))
//                     return { ...att, levels: newLevels }
//                 }
//                 return att
//             })
//         }

//         // Function to recursively apply lookups
//         function applyLookups(currProduct, lookupData) {
//             for (const key in lookupData) {
//                 const value = currProduct[key]
            
//                 if (lookupData[key][value]) {
//                     const data = lookupData[key][value]
//                     for (const attr in data) {
//                         if (Array.isArray(data[attr])) {
//                             updatedAtts = updateLevels(attr, data[attr])
//                         } else if (typeof data[attr] === 'object') {
//                             // Check if the attribute in currProduct exists and recursively apply lookups
//                             if (currProduct[attr] !== undefined) {
//                                 applyLookups(currProduct, data[attr])
//                             }
//                         }
//                     }
//                 }
//             }
//         }

//         // Iterate over each root-level property in the lookups object
//         if (lookups) {
//             for (const lookupKey in lookups) {
//                 applyLookups(curr_product, { [lookupKey]: lookups[lookupKey] })
//             }
//         }


//         return updatedAtts
//     }

        

//         const runForecasts = async (curr_prod, prodatts) => {
//             let counter = 1
//             let resultsCollection = []

//             console.log('conditions.rules', conditions.rules)
//             console.log('attLookups', attLookups)

//             prodatts = applyConditions(curr_prod, prodatts, conditions.rules, attLookups)

//             for (let att of prodatts) {
//                 if (att.type === 'discrete' && !att.readOnly) {
//                     let originalValue = curr_prod[att.name]

//                     curr_prod[att.name] = 1

//                     for (let level of att.levels) {
//                         curr_prod[att.name] = level.value

//                         const result = await computeFordModel(study.uid, auth, {    // await model?.compute({
//                             config: base_copy,
//                             filter,
//                             modelInfo,
//                             brandvalues: [], 
//                             playHash: modelConfig.playHash
//                         })
//                         console.log('result', result)
//                         console.log('Forecast happened', counter)
//                         counter++
//                         let share = result?.[curr_prod.uid]?.share//[modelName][filter.name][curr_prod.uid]?.share

//                         resultsCollection.push({
//                             id: curr_prod.uid,
//                             label: curr_prod.label,
//                             att: att.name,
//                             levelLabel: level.label,
//                             levelValue: level.value,
//                             result: share,
//                         })
//                     }

//                     curr_prod[att.name] = originalValue
//                 }
//             }
//             return resultsCollection
//     }
    
    

//     const sortAndReproportion = (data) => {
//         // Find the minimum levelValue in the array
//         const minValue = data[0].result

//         // Reproportion the levelValue for each object
//         if (minValue < 0) {
//             const reproportionedData = data.map(item => ({
//                 ...item,
//                 result: Math.abs(item.result - minValue)
//             }))
//             // console.log('reproportionedData',reproportionedData)

//             return reproportionedData
//         }
//         else
//             return data
//     }

    
//     const processProduct = async (prod) => {

//         const baseResult = await computeFordModel(study.uid, auth, {
//             config: modelConfig?.base,
//             filter,
//             modelInfo,
//             brandvalues: [],
//             playHash: modelConfig.playHash
//         })

//         console.log('baseResult', baseResult)
//         console.log('modelName', modelName)

//         const base_result = baseResult//[modelName][filter.name]
//         const baseVal = base_result[prod.uid]?.share
//         console.log('BASEVal', baseVal)

//         //RUNS AVG SENS
//         const forecastCollection = await runForecasts(prod, atts)
//         // console.log('forecastcollection', forecastCollection)
//         // const uniqueAttNames = Array.from(new Set(forecastCollection.map(item => item.att)))
//         const groupedByAttribute = forecastCollection.reduce((acc, item) => {
//             acc[item.att] = acc[item.att] || []
//             acc[item.att].push(item)
//             return acc
//         }, {})

//         let data = {}
//         Object.keys(groupedByAttribute).forEach((att, idx) => {

//             const diff_from_base = groupedByAttribute[att].map(itm => ({
//                 ...itm,
//                 result: itm.result - baseVal,
//                 color: '#5383f2'
//             }))?.sort((a, b) => a.result - b.result)
//             // console.log('diff_from_base', diff_from_base)

//             const finalres = sortAndReproportion(diff_from_base)
//             // console.log('finalres', finalres)
        
//             data[att] = finalres.sort((a, b) => a.levelValue - b.levelValue)

//         })
//         console.log('data', data)
    

      
//             //JSON
//               const saveToJsonFile = (data, filename) => {
//                 const jsonStr = JSON.stringify(data, null, 2)
//                 const jsonStrWithoutBraces = jsonStr.substring(1, jsonStr.length - 1)
//                 const blob = new Blob([jsonStrWithoutBraces], { type: 'application/json' })
//                 const link = document.createElement('a')
//                 link.download = filename
//                 link.href = URL.createObjectURL(blob)
//                 document.body.appendChild(link)
//                 link.click()
//                 link.parentNode.removeChild(link)
//             }

//             const jsonfile = {
//                 [prod.uid]: {
//                     ...data
//                 }
//             }


//             //EXCEL
//             const saveToExcelFile = (d, filename) => {
//                 // Convert JSON to CSV
//                 const headers = ["ID", "Product", "Attribute", "Level", "Share", "Color"]
//                 const rows = []

//                 Object.keys(d).forEach(key => {
//                     d[key].forEach(item => {
//                         rows.push([
//                             item.id,
//                             item.label,
//                             item.att,
//                             item.levelValue,
//                             item.result,
//                             item.color
//                         ])
//                     })
//                 })

//                 // Create CSV string
//                 const csvContent = [
//                     headers.join(","), // Join headers with commas
//                     ...rows.map(row => row.join(",")) // Join each row with commas
//                 ].join("\n") // Join rows with newlines

//                 // Create a blob from the CSV string
//                 const blob = new Blob([csvContent], { type: 'text/csvcharset=utf-8' })
//                 const link = document.createElement('a')
//                 link.href = URL.createObjectURL(blob)
//                 link.setAttribute('download', filename)
//                 document.body.appendChild(link)
//                 link.click()
//                 document.body.removeChild(link)
//             }

//             if(filetype.toLowerCase() === 'excel')
//                 saveToExcelFile(data, `${prod.label}_ms.csv`)
//             else
//                 saveToJsonFile(data, `${prod.label}_ms.json`)

//             console.log('data', data)
//     }
    

//     const runForecaster = async () => {
//         setButtonClicked(true)
//         setRunning(true)
       
//         //SPECIFY PRODUCTS IN DCARD TO RUN
//         for (const prod of base_copy) {
//             await processProduct(prod)
//         }

//         setRunning(false)
//     }

//     const handleClick = async () => {
//         await runForecaster()
//     }

//     return (
//         <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
//             <div style={{ fontSize: 12, color: '#808080', marginTop:40, marginBottom:15}}>
//                 Generates Average Sensitivities data in an Excel or Json file 
//             </div>
//             <div >
//                 <button className='btn border' onClick={handleClick}> Run Avg Sensitivities</button>
//             </div>
//             {
//                 running ?
//                 <div style={{marginTop:30}}>
//                     <i className='fad fa-truck-monster fa-spin' style={{fontSize:28, color:'#0d47a1'}}></i>
//                 </div>
//                 : 
//                 buttonClicked && <div style={{marginTop:30, fontSize:18, color:'#006400', fontWeight:700, letterSpacing:3, textTransform:'uppercase' }}>Finished!</div>
//             }
//        </div>
//     )

    
// }