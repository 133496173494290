import React from 'react'
import * as globals from '../../globals'
import { useSelector } from 'react-redux';
import { format as d3Format } from 'd3-format'
import { CardLoading } from '../card/CardLoading'
import { TableFred } from '../vis/TableFred'
import { FilterChooser } from '../filter/FilterChooser'
import { CoreWidget1 } from './CoreWidget1'

import ReactJson from 'react-json-view'

const fmt_nps = d3Format('.0f')

export const NPSDetail = props => {

    const stateFilter = useSelector(state => state.filter); // rename filter? to filters? filterselections?

    // // suffle the array.
    // const shuffle = array => array.sort(_ => Math.random() - 0.5)
    
    // // generate a fake array.
    // const getArray_old = _ => {
    //     let arr = [], limit = 100;
    //     for (let i = 0; i <= 10; i++) {
    //         var item = Math.random() * limit
    //         arr.push(parseInt(item))
    //         limit = parseInt(limit - item)
    //     }
    //     return shuffle(arr)
    // }

    const getArray = (results, model) => {
        // console.log(results);
        return [
            (results[`m${model}_eq_0`]?.value || 0) * 100,
            (results[`m${model}_eq_1`]?.value || 0) * 100,
            (results[`m${model}_eq_2`]?.value || 0) * 100,
            (results[`m${model}_eq_3`]?.value || 0) * 100,
            (results[`m${model}_eq_4`]?.value || 0) * 100,
            (results[`m${model}_eq_5`]?.value || 0) * 100,
            (results[`m${model}_eq_6`]?.value || 0) * 100,
            (results[`m${model}_eq_7`]?.value || 0) * 100,
            (results[`m${model}_eq_8`]?.value || 0) * 100,
            (results[`m${model}_eq_9`]?.value || 0) * 100,
            (results[`m${model}_eq_10`]?.value || 0) * 100,
        ]
    }

    const columns = [
        { key: 'label', type: 'string', className: 'label_sm', label: 'Model', className: 'label' },
        { key: 'distrib', type: 'details',
            format: fmt_nps,
         columns: [
            {
                key: 'botbox',
                type: 'cell',
                className: 'data',
                label: 'Detractor',
                format: fmt_nps,
                colors: {
                    detractor: { fill: globals.npsColors.detractor, color: globals.npsColors.detractorFg },
                    neutral:   { fill: globals.npsColors.neutral,   color: globals.npsColors.neutralFg },
                    promoter:  { fill: globals.npsColors.promoter,  color: globals.npsColors.promoterFg }
                },
                fill: globals.npsColors.detractor, color: globals.npsColors.detractorFg, fillHeader: true
                
            },
            {
                key: 'midbox',
                type: 'cell',
                className: 'data',
                label: 'Neutral',
                format: fmt_nps,
                fill: globals.npsColors.neutral, color: globals.npsColors.neutralFg, fillHeader: true
                
            },
            {
                key: 'topbox',
                type: 'cell',
                className: 'data',
                label: 'Promoter',
                format: fmt_nps,
                fill: globals.npsColors.promoter, color: globals.npsColors.promoterFg, fillHeader: true
                
            } 
        ]},
        { key: 'nps', type: 'cell', className: 'data', format: fmt_nps, label: 'NPS' },
    ]
    
    const filter = props.inDataCard ? stateFilter?.selectedFilter : props.filter;
    // console.log("filter", filter)
    //const query = props.getQuery(null, filter);
    //console.log('query', query);

    const renderContent = (cache, getJSONData) => {
        // console.log(cache?.results)
        // let rows = [
        //     // todo: add average row {
        //     //     uid: 0,
        //     //     label: 'Average',
        //     //     nps: { value: parseInt(Math.random()*10) },
        //     //     composition:  cache?.results ? getArray(cache?.results, ),
        //     //     className: 'average-row',
        //     //     fill: '#cfcfcf'
        //     // },
        //     ...(props.entries?.map((i, idx) => ({
        //         uid: idx+3,
        //         label: i.label,
        //         composition: cache?.results ? getArray(cache?.results, i.survey_id) : null
        //     })) || [])
        // ].map(row => {
        //     let { composition } = row
        //     //let arrSum = arr => arr?.reduce((a, b) => a + b, 0)

        //     let top = composition ? composition[10] + composition[9] : null;
        //     let bot = composition ? composition[0] + composition[1] + composition[2] + composition[3] + composition[4] + composition[5] : null;
        //     return {
        //         ...row,
        //         nps: { value: top - bot }
        //     }
        // })

        let Rows = [
            ...(props.entries?.map((i, idx) => ({
                uid: idx+3,
                label: i.label,
                composition: cache?.results ? getArray(cache?.results, i.survey_id) : null
            })) || [])
        ].map(row => {
            let { composition } = row
            let botbox = composition ? { composition: composition.slice(0,7), value: composition.slice(0,7).reduce((a,e) => a + e, 0) } : null,
                midbox = composition ? { composition: composition.slice(7,9), value: composition.slice(7,9).reduce((a,e) => a + e, 0) } : null,
                topbox = composition ? { composition: composition.slice(9), value: composition.slice(9).reduce((a,e) => a + e, 0) } : null;
            return {
                ...row,
                botbox,
                midbox,
                topbox,
                nps: { value: topbox?.value - botbox?.value }
            }
        })
        //return <ReactJson src={rows}/>
        if (getJSONData && typeof getJSONData === 'function') {
            getJSONData({ columns, data: Rows })
        }
        
        if (cache && cache.success && props.loaded && typeof props.loaded === 'function')
            props.loaded(true)

        let content = <center><TableFred columns={columns} data={Rows} showScale='nps-detail' /></center>
        return content;

    }
    // console.log(props?.getQuery(null, filter));
    return <CoreWidget1
        {...props}
        stateContext={props.stateContext}
        showFilter={true}
        useFilter={true}
        render={renderContent}
        class2='vscroll hscroll no-pad'
        useYak={{
            query: props?.getQuery(null, filter)
        }}
    />

    
}
