import * as globals from '../globals';
import * as apiService from '../utils/apiService';
import {
    STUDY_RESET,
    STORIES_RECEIVE,
    STORIES_REQUEST,
    STORIES_SELECT,
    STORIES_ADD_TEXTCARD,
    STORIES_ADD_CARD,
    STORIES_REMOVE_CARD,
    STORIES_STORY_CREATED,
    STORIES_STORY_DELETED,
    STORIES_STORY_RECEIVE,
    STORIES_STORY_REQUEST,
    STORIES_STORY_CARDS_ADDED,
    STORIES_STORY_CARDS_REMOVED,
    STORIES_STORY_CARDS_MOVED,
    STORIES_STORY_REACTION_SET_VALUE,
    STORIES_STORY_COMMENT_ADDED,
    STORIES_STORY_COMMENT_DELETED,
    STORIES_STORY_COMMENT_PATCHED,
    STORIES_STORY_POSTED,
    POSTS_POST_DELETED,
    POSTS_POST_CREATED,
    CARDS_DELETED
    // @ts-ignore
} from './action-types';

export interface StoriesState{

    drafts: string[],
    dict: {
        [key: string]: Story
    }
}

export interface Story{
    uid: string;
    headline: string;
    content: string;
    question: string;
    author_uid: string;
    author_name: string;
    created: Date;
    modified: Date;
    draft: boolean;
    label: string;
    favorites: number;
    likes: number;
    dislikes: number;
    isFavorite: boolean;
    isLiked: boolean;
    isDisliked: boolean;
    primarySelection: any;
    cards: string[];
    textcards: any[];
    comments: any;

}

const initialState: StoriesState = {
    drafts: null,
    dict: {},
};

export const actionCreators = {

    loadStories: (callback: any) => async (dispatch: any, getState: any): Promise<any> => {

        dispatch({ type: STORIES_REQUEST });

        const state = getState();
        let studyId = state && state.study ? state.study.uid : null;
        if( !studyId ) return null;

        try {
            
            const url = `${globals.apiRoot}/study/${studyId}/stories`;
            const response = await apiService.aGet(state.auth, url);

            if( response.ok ){
                const stories = await response.json();
                dispatch({ type: STORIES_RECEIVE, stories });
                if( callback ) callback();
            }
            else{
                dispatch({ type: STORIES_RECEIVE, stories: [], error: response.statusText });
            }

            

        } catch (err) {
            console.error('loadStories error', err);
        }

    },

    loadStory: (storyId: any, callback: any) => async (dispatch: any, getState: any): Promise<any> => {

        dispatch({ type: STORIES_STORY_REQUEST, storyId });

        const state = getState();
        let studyId = state.study.uid;
        if( !studyId ) return null;

        try {
            
            const url = `${globals.apiRoot}/study/${studyId}/story/${storyId}`;
            const response = await apiService.aGet(state.auth, url);
            if( response.ok ){
                const story = await response.json();
                dispatch({ type: STORIES_STORY_RECEIVE, story });
                if( callback ) callback();
            }
            else{
                dispatch({ type: STORIES_STORY_RECEIVE, story: { uid: storyId, error: response.statusText } });
            }


        } catch (err) {
            console.error('loadStory error', err);
        }

    },

    createStory: (storyData: any, callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();
        const studyId = state.study.uid;

        const body = {
            ...storyData
        };

        try {
            const url = `${globals.apiRoot}/study/${studyId}/story`;
            const response = await apiService.aPost(state.auth, url, body);
            if( response.ok ){
                const newStory = await response.json();
                dispatch({ type: STORIES_STORY_CREATED, story: newStory });
                if( callback ){ callback(newStory) }
            }
            else{
                console.error(response.statusText);
            }

        } catch (error) {
            console.error(error);
        }

    },

    addCardsToStory: (storyId: any, cardIds: any, callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();
        const studyId = state.study.uid;

        const body = {
            cards: cardIds //cardsIds has to be an array
        }

        console.log('add cards body', body, studyId)

        try {
            const response = await apiService.aPost(state.auth, `${globals.apiRoot}/study/${studyId}/story/${storyId}/add-cards`, body);
            if( response.ok ){
                dispatch({ type: STORIES_STORY_CARDS_ADDED, storyId, cardIds });
                if( callback ) callback();
            }
            else{
                console.error(response.statusText);
            }

        } catch (error) {
            console.error(error);
        }


    },

    removeCardsFromStory: (storyId: string, cardIds: any, callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();
        const studyId = state.study.uid;

        const body = {
            cards: cardIds
        }

        try {
            const response = await apiService.aPost(state.auth, `${globals.apiRoot}/study/${studyId}/story/${storyId}/remove-cards`, body);
            if( response.ok ){
                dispatch({ type: STORIES_STORY_CARDS_REMOVED, storyId, cardIds });
                if( callback ) callback();
            }
            else{
                console.error(response.statusText);
            }

        } catch (error) {
            console.error(error);
        }


    },

    moveStoryCard: (storyId: string, cardId: string, dir: any, callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();
        const studyId = state.study.uid;

        try {
            const response = await apiService.aPut(state.auth, `${globals.apiRoot}/study/${studyId}/story/${storyId}/card/${cardId}/move/${dir}`);
            if( response.ok ){
                const cardIds = await response.json();
                dispatch({ type: STORIES_STORY_CARDS_MOVED, storyId, cardIds });
                if( callback ) callback();
            }
            else{
                console.error(response.statusText);
            }

        } catch (error) {
            console.error(error);
        }


    },

    saveStory: (storyId: string, saveState: any, callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();
        const studyId = state.study.uid;

        const body = {
            ...saveState
        };
        console.log('saveStory body', body);

        try {
            const url = `${globals.apiRoot}/study/${studyId}/story`;
            const response = storyId ? await apiService.aPatch(state.auth, `${url}/${storyId}`, body) :
                await apiService.aPost(state.auth, url, body);

            let newStoryId = storyId;
            if( !newStoryId ){
                newStoryId = (await response.json() || {}).uid
            }

            actionCreators.loadStory(storyId, () => {
                if( callback ) callback(newStoryId);
            })(dispatch, getState);

        } catch (err) {
            console.error('saveStory error', err);
        }

    },

    patchStory: (storyId: string, body: any, callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();
        const studyId = state.study.uid;

        console.log('patchStory body', body);

        try {
            const url = `${globals.apiRoot}/study/${studyId}/story/${storyId}`;
            const response = await apiService.aPatch(state.auth, url, body);
            if( response.ok ){
                // not a fan of this; should dispatch an event instead so i don't have to reload it.
                actionCreators.loadStory(storyId, () => {
                    if( callback ) callback();
                })(dispatch, getState);
            }
            else{
                console.error(response.statusText);
            }

        } catch (err) {
            console.error('patchStory error', err);
        }

    },

    postStory: (storyId: any, saveState: any, callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();
        const studyId = state.study.uid;

        const body = {
            ...saveState
        }
        // console.log('postStory', body);

        try {
            const response = await apiService.aPost(state.auth, `${globals.apiRoot}/study/${studyId}/story/${storyId}/post`, body);
            if (response.ok) {
                const post = await response.json();
                dispatch({ type: POSTS_POST_CREATED, post }); // adds the post
                dispatch({ type: STORIES_STORY_POSTED, storyId }); // removes draft bit
                if(callback) callback(post);
            }
            else{
                console.error(response.statusText);
            }

        } catch (error) {
            console.error(error);
        }


    },

    cloneStory: (storyId: string, callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();
        const studyId = state.study.uid;

        try {
            const url = `${globals.apiRoot}/study/${studyId}/story/${storyId}/clone`;
            const response = await apiService.aPost(state.auth, url, {});

            const newStoryId = (await response.json() || {}).uid;
            actionCreators.loadStories(() => {
                if( callback ) 
                    callback(newStoryId);
            })(dispatch, getState);


        } catch (err) {
            console.error('cloneStory error', err);
        }


    },

    deleteStory: (storyId: string, callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();
        const studyId = state.study.uid;

        try {
            const url = `${globals.apiRoot}/study/${studyId}/story/${storyId}`;
            const response = await apiService.aDelete(state.auth, url);
            if( response.ok ){
                const stuffDeleted = await response.json();
                console.log('stuffDeleted', stuffDeleted);
                if( stuffDeleted.story_uid ){
                    dispatch({ type: STORIES_STORY_DELETED, storyId: stuffDeleted.story_uid });
                }
                if( stuffDeleted.post_uid ){
                    dispatch({ type: POSTS_POST_DELETED, postId: stuffDeleted.post_uid });
                }
                if( stuffDeleted.cards && stuffDeleted.cards.length > 0 ){
                    dispatch({ type: CARDS_DELETED, cardIds: stuffDeleted.cards });
                }
                if( callback ) callback(stuffDeleted);
            }
            else{
                console.error(response.statusText);
            }

        } catch (error) {
            console.error(error);
        }

    },


    react: (storyId: string, reaction: any, newValue: any, callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();
        const studyId = state.study.uid;

        let body = {
            entryType: 'story',
            entryId: storyId,
            reaction,
            newValue
        };

        try {

            const response = await apiService.aPost(state.auth, `${globals.apiRoot}/study/${studyId}/react`, body);

            if (response.ok) {
                dispatch({ type: STORIES_STORY_REACTION_SET_VALUE, storyId, reaction, newValue });
                if (callback) callback(newValue);
            }
            else{
                console.error(response.statusText);
            }
            
        } catch (error) {
            console.error(error);
        }
    },

    postComment: (storyId: string, comment: any, callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();
        const studyId = state.study.uid;
        
        const body = {
            targetType: 'story',
            targetUid: storyId,
            comment
        }

        try {
            const response = await apiService.aPost(state.auth, `${globals.apiRoot}/study/${studyId}/comment`, body);
            if( response.ok ){
                const comment = await response.json();
                dispatch({ type: STORIES_STORY_COMMENT_ADDED, storyId, comment });
                if( callback ) callback(comment);
            }
            else{
                console.error(response.statusText);
            }

        } catch (error) {
            console.error(error);
        }
    },

    deleteComment: (storyId: string, commentId: string, callback: any) => async (dispatch: any, getState: any) => {
        try {
            console.log('deleteComment', storyId, commentId);

            const state = getState();
            const studyId = state.study.uid;

            const response = await apiService.aDelete(state.auth, `${globals.apiRoot}/study/${studyId}/comment/${commentId}`);
            if( response.ok ){
                dispatch({ type: STORIES_STORY_COMMENT_DELETED, storyId, commentId });
                if (callback) callback();
            }
            else{
                console.error(response.statusText);
            }

        } catch (error) {
            console.error(error)
        }
    },

    editComment: (storyId: string, commentId: string, comment: any, callback: any) => async (dispatch: any, getState: any) => {
        try {
            console.log('editComment', storyId, commentId, comment);
            const state = getState();
            const studyId = state.study.uid;

            const body = {
                comment
            };

            const response = await apiService.aPatch(state.auth, `${globals.apiRoot}/study/${studyId}/comment/${commentId}`, body);
            if( response.ok ){
                dispatch({ type: STORIES_STORY_COMMENT_PATCHED, storyId, commentId, newContent: comment });
                if (callback) callback();
            }
            else{
                console.error(response.statusText);
            }

        } catch (error) {
            console.error(error)
        }
    },

  

};


const reduceByKey = (arr: any, key: string, initial = {}) => {
    if( !arr ) return null;
    return arr.reduce((accum: any, el: any) => {
        accum[el[key]] = el;
        return accum;
    }, initial)
}

const createKeySort = (key: string) => (a: any, b: any) => a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;


export const reducer = (state: StoriesState = initialState, action: any): StoriesState => {

    switch (action.type) {

        case STUDY_RESET:
            return initialState;

        case STORIES_REQUEST: {
            return {
                ...state,
                drafts: state.drafts || []
            }
        }
        case STORIES_RECEIVE: {
            return {
                ...state,
                drafts: action.stories ? action.stories.filter((story: Story) => story.draft).map((story: Story) => story.uid) : [],
                dict: reduceByKey(action.stories, 'uid', {...state.dict})
            }
        }
        // case STORIES_SELECT: {
        //     return {
        //         ...state,
        //         selected: action.story || {}
        //     }
        // }
        case STORIES_STORY_REQUEST: {
            return {
                ...state,
                dict: {
                    ...state.dict,
                    [action.storyId]: {
                        ...state.dict[action.storyId],
                        loading: true
                    }
                }
            };
        }
        case STORIES_STORY_RECEIVE: {
            // todo: add or remove from drafts maybe
            return {
                ...state,
                dict: {
                    ...state.dict,
                    [action.story.uid]: action.story
                }
            };
        }

        case STORIES_STORY_CREATED : {
            // add to dict and drafts if draft
            const drafts = state.drafts || [];
            const newDrafts = action.story.draft ? [...drafts, action.story.uid] : drafts;
            return {
                ...state,
                drafts: newDrafts,
                dict: {
                    ...state.dict,
                    [action.story.uid]: action.story
                }
            }
        }

        case STORIES_STORY_DELETED : {
            // remove from dict and drafts
            let dict = {...state.dict};
            delete(dict[action.storyId]);
            return {
                ...state,
                drafts: state.drafts ? state.drafts.filter(storyId => storyId !== action.storyId) : [],
                dict
            }
        }
            
        case STORIES_ADD_TEXTCARD: {
            console.log('action', action)
            let story = state.dict[action.storyId];
             return {
                ...state,
                 dict: {
                     ...state.dict,
                     [action.storyId]: {
                        ...story,
                        textcards: [...(story.textcards || []), ...action.cardIds]
                    }
                 }
            }
         }

        case STORIES_STORY_CARDS_REMOVED: {
            let story = state.dict[action.storyId];
            if( !story ) return state; // nothing to do (story not found)
            return {
                ...state,
                dict: {
                    ...state.dict,
                    [action.storyId]: {
                        ...story,
                        cards: story.cards.filter((id: string) => !action.cardIds.includes(id))
                    }
                }
            };
        }

        case STORIES_STORY_CARDS_MOVED: {
            let story = state.dict[action.storyId];
            if( !story ) return state; // nothing to do (story not found)
            return {
                ...state,
                dict: {
                    ...state.dict,
                    [action.storyId]: {
                        ...story,
                        cards: action.cardIds
                    }
                }
            };
        }

        case STORIES_STORY_CARDS_ADDED: {
            let story = state.dict[action.storyId];
            if( !story ) return state; // nothing to do (story not found)
            return {
                ...state,
                dict: {
                    ...state.dict,
                    [action.storyId]: {
                        ...story,
                        cards: [...(story.cards || []), ...action.cardIds]
                    }
                }
            };
        }

        case STORIES_STORY_REACTION_SET_VALUE: {
            let story = state.dict[action.storyId];
            if( !story ) return state;

            return {
                ...state,
                dict: {
                    ...state.dict,
                    [action.storyId]: {
                        ...story,
                        [action.reaction]: action.newValue
                    }
                }
            }
        }

        case STORIES_STORY_COMMENT_ADDED : {
            let story = state.dict[action.storyId];
            if( !story ) return state;
            const prevComments = (story.comments || []).filter((c: any) => c.uid !== action.comment.uid); // prevent adding same comment twice
            return {
                ...state,
                dict: {
                    ...state.dict,
                    [action.storyId]: {
                        ...story,
                        comments: [...prevComments, action.comment]
                    }
                }
            }
        }

        case STORIES_STORY_COMMENT_DELETED : {
            let story = state.dict[action.storyId];
            if( !story ) return state;
            return {
                ...state,
                dict: {
                    ...state.dict,
                    [action.storyId]: {
                        ...story,
                        comments: (story.comments || []).filter((c: any) => c.uid !== action.commentId)
                    }
                }
            };
        }

        case STORIES_STORY_COMMENT_PATCHED : {
            let story = state.dict[action.storyId];
            if( !story ) return state;
            return {
                ...state,
                dict: {
                    ...state.dict,
                    [action.storyId]: {
                        ...story,
                        comments: (story.comments || []).map((c: any) => c.uid === action.commentId ? { ...c, content: action.newContent } : c)
                    }
                }
            }
        }

        case STORIES_STORY_POSTED : {
            let story = state.dict[action.storyId];
            if( !story ) return state;
            return {
                ...state,
                dict: {
                    ...state.dict,
                    [action.storyId]: {
                        ...story,
                        draft: false
                    }
                }
            }
        }

        default:
            return state;
    }

};
