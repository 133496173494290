import { format as d3Format } from "d3-format";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as analyticsActionCreators } from "../../store/Analytics.ts";
import { CardLoading } from "../card/CardLoading";
import { CompChooser } from "../filter/CompChooser";
import { FilterChooser } from "../filter/FilterChooser";
import { ProductBlockMini } from "../product-drawer/ProductBlockMini";
import { TableFred } from "../vis/TableFred";
import "./SurveyTableViewerComp.scss";

const _fmt1 = d3Format("0.2f");
const _fmtn = d3Format(",");

const fmt1 = (val) => (val !== null ? _fmt1(val) : ".");
const fmtn = (val) => (val !== null ? _fmtn(val) : ".");

const onlyUnique = (value, index, self) => {
	return self.indexOf(value) === index;
};

export class SurveyTableViewerComp extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};

		// Allow card to be saved as insight card. Not the greatest pattern.
		this.props.stateContext.getSaveState = this.getSaveState;
	}

	componentDidMount() {
		this.refreshIfNeeded();
	}

	componentDidUpdate(prevProps) {
		this.refreshIfNeeded();
	}

	getSelections = () => {
		const selections = [
			this.props.selection,
			...(this.props.competitors || []),
		].filter((x) => x);
		let uids = selections.map((sel) => sel.uid);
		uids = uids.filter(onlyUnique);
		return uids.map((uid) => selections.find((sel) => sel.uid === uid));
	};

	refreshIfNeeded = () => {
		// cancel if cached data already exists

		if (this.props.cache) return; // insights card
		if (!this.props.selection) return; // nothing selected

		const fullCache = this.props.fullCache;
		const rootPath = this.props.path;

		const filter = this.props.filter;

		if (this.props.needsSelection && !this.props.selection) return;

		const selections = this.getSelections();

		selections.forEach((sel) => {
			const path = `${rootPath}/${sel.uid}`;
			const key = `${rootPath}/${sel.uid}/${filter.syntax}`;
			const cache = fullCache?.[key];

			if (cache && cache.loading) return;
			if (cache && cache.error) return;
			if (cache && cache.value) return;

			// refresh is needed. request it.
			const query = this.props.getQuery?.(sel, this.props.filter);
			this.props.loadYak(path, key, query);
		});
	};

	static inheritOptions = [];

	getSaveState = () => {
		const filter = this.props.filter;

		let fullCache = {};
		const selections = this.getSelections();
		const rootPath = this.props.path;
		selections.forEach((sel) => {
			const key = `${rootPath}/${sel.uid}/${filter.syntax}`;
			fullCache[key] = this.props.fullCache?.[key];
		});

		const s = {
			selection: this.props.selection,
			competitors: this.props.competitors,
			filter: this.props.filter,
			fullCache,
		};
		return s;
	};

	render() {
		const study = this.props.study;
		if (!study) return;

		let fullCache = this.props.fullCache;

		let selection = this.props.selection;
		if (!selection && this.needsSelection) return "no selection";

		const selectionLevel = this.props.selectionLevel;

		let sels = this.getSelections();

		// const model = this.props.selection ? (this.props.selection || {}).model : null;
		// const data = cache ? cache.value : null;

		// let data2 = data && model ? this.props.entries.map(entry => this.props.getResult(data, entry, this.props.filter, selection)) : null || [];

		const filter = this.props.filter;

		//
		const debug = false;

		const columns = [
			{
				key: "label",
				type: "string",
				label: this.props.label,
				className: "label",
			},
			...sels.map((sel) => {
				return {
					key: "sel:" + sel.uid,
					type: "cell",
					label: sel.label,
					className: "data",
					format: fmt1,
				};
			}),
		];
		//console.log('columns', columns);

		let rowData = this.props.entries?.map((entry) => {
			let retVal = {
				uid: entry.code,
				label: entry.label,
			};
			sels.forEach((sel) => {
				const rootPath = this.props.path;
				const key = `${rootPath}/${sel?.uid}/${filter.syntax}`;
				const value1 = fullCache?.[key]?.value;
				const value2 = (value1 || {})[`code:${entry.code}`];
				let cell =
					value2 && value2.value !== null
						? value2
						: {
								loading: true,
						  };
				// let cell = {
				//     value: fullCache[path]?.value
				// };
				retVal[`sel:${sel.uid}`] = cell;
			});
			return retVal;
		});
		//console.log('rowData', rowData);

		if (
			this.props.getJSONData &&
			typeof this.props.getJSONData === "function" &&
			columns &&
			rowData
		)
			this.props.getJSONData({ columns: [...columns], data: [...rowData] });

		const content = !fullCache ? null : (
			//cache.loading ? null :
			<div className="table-container">
				<TableFred columns={columns} data={rowData} debug={true} />
			</div>
		);

		if (this.props.loaded && typeof this.props.loaded === "function")
			this.props.loaded(true);

		let sel = this.getSelections()[0]?.uid;
		let key = `${this.props.path}/${sel}/${this.props.filter.syntax}`;

		return (
			<div className="widget survey-table-viewer-comp">
				<div className="widget-header">
					<div className="title">
						{this.props.getTitle
							? this.props.getTitle(this.props.selection)
							: this.props.title}
					</div>
					{this.props.inInsightCard ? null : (
						<div className="filter-etc">
							<CompChooser
								mini={true}
								disabled={this.props.inInsightCard}
								selection={sels}
							/>
						</div>
					)}
					<div className="filter-etc">
						<FilterChooser
							mini={true}
							disabled={this.props.inInsightCard}
							selection={filter}
						/>
					</div>
				</div>
				<div className="widget-body vscroll hscroll">{content}</div>
				<CardLoading loading={false} />
			</div>
		);
	}
}

SurveyTableViewerComp = connect(
	(state, ownProps) => {
		const filter =
			state.filter?.selectedFilter || globals.getDefaultFilter(state.study);
		const selectionLevel = ownProps.selectionLevel || "product";
		const selection = state.selections?.[selectionLevel];
		const competitors = state.selections?.competitors; // todo: move this to state.selections

		return {
			study: state.study,
			selectionLevel,
			...(ownProps.inDataCard
				? {
						filter,
						selection,
						competitors,
						fullCache: state.cache?.analytics,
				  }
				: null),
		};
	},
	(dispatch) => bindActionCreators(analyticsActionCreators, dispatch),
)(SurveyTableViewerComp);
