import * as globals from '../globals'
import * as apiService from './apiService'
import {getMeta} from '../utils/metaUtils'
import { chain } from 'lodash'



export function applyConditions(curr_product, atts, rules, lookups) {
    // console.log('products', curr_product);
    // console.log('rules', Array.isArray(rules), rules);
    // console.log('atts', atts);

    //set all att's readOnly prop to false (show on ui)
    let updatedAtts = atts.map(att => ({ ...att, readOnly: false }))

    // apply att conditions rules
    rules?.forEach(rule => {

        rule.values.forEach(val => {
            
            if (curr_product[rule.attribute] === val.value) {
                // Apply each condition specified for this value
                val.conditions.forEach(cond => {
                    // Find the attribute in updatedAtts to set readOnly
                    const targetAttribute = updatedAtts.find(att => att.name === cond.attribute)
                    if (targetAttribute) {
                        targetAttribute.readOnly = cond.readOnly
                    }
                })
            }
        })
    })

    
        // Helper function to update attribute levels
    function updateLevels(attributeName, levels) {
        return updatedAtts.map(att => {
            if (att.name === attributeName) {
                const newLevels = levels.map((level, index) => ({
                    value: index + 1, // Assuming the value is the index + 1
                    label: level
                }));
                return { ...att, levels: newLevels };
            }
            return att;
        });
    }

    // Function to recursively apply lookups
    function applyLookups(currProduct, lookupData) {

        // console.log('currProduct', currProduct);
        // console.log('lookupData', lookupData);

        for (const key in lookupData) {
            const value = currProduct[key]

            // console.log('key', key);
            // console.log('value', value);
          
            if (lookupData[key][value]) {
                const data = lookupData[key][value];
                // console.log('data', data);
                for (const attr in data) {
                    if (Array.isArray(data[attr])) {
                        updatedAtts = updateLevels(attr, data[attr])
                        // console.log('udpatedatts', updatedAtts);
                    } else if (typeof data[attr] === 'object') {
                        // Check if the attribute in currProduct exists and recursively apply lookups
                        if (currProduct[attr] !== undefined) {
                            applyLookups(currProduct, { [attr]: data[attr] })
                        }
                    }
                }
            }
        }
    }

    // Iterate over each root-level property in the lookups object
    if (lookups) {
        for (const lookupKey in lookups) {
            applyLookups(curr_product, { [lookupKey]: lookups[lookupKey] })
        }
    }


    return updatedAtts
}
