import * as React from 'react';
import './Snackbar.scss';

export const Snackbar = (props) => (
	<div className={"snackbar-main " + (props?.position ? props?.position : 'bottom-left')}>
		<div className={"snackbar-content" + (props.success ? ' success' : ' visible')}>
			{props?.message ?
				<div className="message">
					{props?.message}
				</div>
				: null}

			<div className={"actions-btn" + (props?.message ? ' ' : ' no-msg')}>
				{props?.button ?
					<button className="action" onClick={props?.button.onClick}>
						<span>{props?.button?.label}</span>
						<span className="action-overlay">&nbsp;</span>
					</button>
					: null}
				{!props?.hideCloseBtn ?
					<button className={props?.success ? ' close-icon-white' : 'close-icon'} onClick={_ => topBar.dequeueSnack(props.id)}>
						<i className="fal fa-times fa-lg">&nbsp;</i>
					</button>
					: null}
			</div>
		</div>
	</div>
)
