import * as global from '../globals'
import * as apiService from '../utils/apiService'
import { guid } from '../utils/guid'
// @ts-ignore
import { DCM_RESET } from './action-types'
// @ts-ignore
import { dirtyCheckSlot } from './Config'


////////////////////// This is connected to DCM.ts state //////////////////////
export interface ScenarioState{

    scenarios: any

}

export const scenarioActionType = 'SCENARIO_ACTION'

const initialState: ScenarioState = {
    scenarios: {
        working: null, // contains working message or null
        prompt: null, // contains prompt message or null
        promptAction: null, // e.g., Overwrite, Rename
        promptInput: null, // e.g., null, textbox
        error: null
        //loadingFiles: false,
        //loadFilesError: null,
        //saving: false,
        //saveError: null,
        //opening: false,
        //openError: null,
    }
}

const timeout = (ms: number) => new Promise(res => setTimeout(res, ms))


function getMatchingProperties(object:any, firstPropertyName: any) {
  const prefix = getPrefix(firstPropertyName);
  const matchingProperties = {};

  Object.keys(object).forEach(key => {
    if (key.startsWith(prefix)) {
      matchingProperties[key] = object[key];
    }
  });

  return matchingProperties;
}

function getPrefix(propertyName: any) {
  const parts = propertyName.split('_');
  if (parts.length > 1) {
    return parts.slice(0, -1).join('_');
  }
  return propertyName;
}

// function extractPlay(models:any) {
//     const result = {};

//     for (const [key, model] of Object.entries(models)) {
//         if (model && model.modelInfo && model.modelInfo.name && model.config && model.config.play) {
//             result[model.modelInfo.name] = {
//                 play: model.config.play
//             }
//         }
//     }

//     return result;
// }

const extractScenarioData = (model: any) => {

    
    const models: any = {};

    const data: any = {
        activeConfig: model.activeConfig,
        models
    };

    const modelNames = model?.modelNames || [];
    for (let modelName of modelNames) {
        models[modelName] = {
            config: {
                play: model[modelName]?.config?.play
            }
        }
    }

    return data;

}



export const actionCreators = {

    clearErrors: () => async (dispatch: any, getState: any) => {
        dispatch({ type: `${scenarioActionType}:CLEAR_ERRORS` })
    },

    loadFiles: (callback: any) => async (dispatch: any, getState: any) => {
        const state = getState()

        if ((state.scenarios || {}).working) {
            let error = 'Task already in progress'
            if (callback) {
                callback(false, error)
            } else {
                dispatch({ type: `${scenarioActionType}:LOAD_FILES_RESULT`, value: null, error })
            }
            return
        }

        dispatch({ type: `${scenarioActionType}:SET_WORKING`, message: 'Loading files' })

        //await timeout(5000)

        try {
            console.log('here');
            const response = await apiService.aGet(state.auth, `${global.apiRoot}/study/${state.study.uid}/scenarios`)
            const data = await response.json()

            console.log('laoad files data', data);
            dispatch({ type: `${scenarioActionType}:LOAD_FILES_RESULT`, value: data })

        } catch (err) {
            dispatch({ type: `${scenarioActionType}:LOAD_FILES_RESULT`, value: null, error: err })
        }
    },

    createFolder: (parentFolderId: string, label: string, callback: any) => async (dispatch: any, getState: any) => {
        
        const state = getState()

        if ((state.scenarios || {}).working) {
            let error = 'Task already in progress'
            if (callback) {
                callback(false, error)
            } else {
                dispatch({ type: `${scenarioActionType}:SET_WORKING`, message: null, error })
            }
            return
        }

        dispatch({ type: `${scenarioActionType}:SET_WORKING`, message: 'Creating folder' })

        try {
            let body = {
                parentFolderId: parentFolderId,
                label: label
            }

            const response = await fetch(`${global.apiRoot}/study/${state.study.uid}/scenario-folder`,
                {
                    method: 'put',
                    body: JSON.stringify(body),
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + state.auth.token
                    }
                }
            )

            dispatch({ type: `${scenarioActionType}:SET_WORKING`, message: null })

            if (response.ok) {
                const newId = await response.text() + ""
                if (callback) callback(true, null, newId) // todo: get the new id

            }
            else if (response.status === 409) {
                if (callback) callback(false, 'Folder already exists with same name')

            }
            else {
                if (callback)
                    callback(false, response.status + ' ' + response.statusText)
            }

        } catch (err) {
            dispatch({ type: `${scenarioActionType}:SET_WORKING`, message: null })

            if (callback) {
                callback(false, err)
            }
        }
    },

    //OPEN
    loadScenario: (scenarioId: string, callback: any) => async (dispatch: any, getState: any) => {
        // for now, opening what-if and comparison
        dispatch({
            type: `${scenarioActionType}:SET_WORKING`,
            value: 'loading...'
        })

        const state = getState()

        // get uid of study
        let uid = state.study.uid

        // get config
        // todo: separate play from base

        try {
            const response = await apiService.aGet(state.auth, `${global.apiRoot}/study/${uid}/scenario/${scenarioId}`)
            const data = await response.json()

            // make sure that the scenario version is supported
            //console.log("loaded scenario's version:", data?.version)
            //console.log("scenarioCompatibilityVersion", state.study.scenarioCompatibilityVersion)
            if (data?.version >= state.study.scenarioCompatibilityVersion) {
                // yes, this is supported
            }
            else {
                throw new Error("Incompatible scenario version");
            }
            
            //console.log("loaded a scenario. study=", state.study);
            //console.log("loaded a scenario data=", data);

            if (response.ok) {
                dispatch({ type: `${scenarioActionType}:LOAD_SCENARIO_RESULT`, value: data })
                if (callback)
                    callback(true, '')
            }
            else {
                console.error('failed to save scenario: ', response)
                if (callback) callback(false, 'Server error')
            }
        } catch (err: any) {
            if (callback) callback(false, 'Error: ' + err.message)
        }

        dispatch({ type: `${scenarioActionType}:SET_WORKING`, value: null })
    },

    loadOptimizerScenario: (runId: string, scenarioIndex: any, callback: any) => async (dispatch: any, getState: any) => {
        // for now, opening what-if and comparison

        dispatch({
            type: `${scenarioActionType}:SET_WORKING`,
            value: 'loading...'
        })

        const state = getState()

        // get uid of study
        let studyId = state.study.uid

        // get config
        // todo: separate play from base

        try {
            const response = await apiService.aGet(state.auth, `${global.apiRoot}/optimize/scenario/${studyId}/${runId}/${scenarioIndex}`)

            const data = await response.json()
            console.log('received optimizer scenario from api', data)

            if (response.ok) {
                dispatch({ type: `${scenarioActionType}:LOAD_SCENARIO_RESULT_SINGLECONFIG`, value: data, configModels: state.dcm.configModels })
                if (callback) callback(true, data)
            } else {
                console.error(response)
                if (callback) callback(false, 'Server error')
            }
        } catch (err) {
            if (callback) callback(false, 'Server error')
        }

        dispatch({ type: `${scenarioActionType}:SET_WORKING`, value: null })
    },

    saveScenario: (parentFolderId: string, filename: string, overwrite: boolean, callback: any) => async ( dispatch: any, getState: any ) => {

        dispatch({ type: `${scenarioActionType}:SET_WORKING`, value: 'saving...' })

        const state = getState()

        // get uid of study
        let uid = state.study.uid
        let version = state.study.scenarioVersion
        const model = state.model        

        //save all 3 models
        let active_model = state.model[model.activeConfig]?.modelInfo.name
        //let models = getMatchingProperties(model, active_model)
        //let scn_data = extractPlay(models)

        let scn_data = extractScenarioData(model)


        let body = {
            info: {
                author: state.auth.user.name,
                studyid: state.study.uid,
                version: version, // scenario version
                // assumptionsId: assumptionsId,
                // baseCaseId: baseCaseId,
                studyEtag: state.study.etag,
                parentFolderId: parentFolderId,
                filename: filename,
                //configModels: Object.keys(models),
                //activeConfig: model.activeConfig,
                overwrite: overwrite ? true : false
            },
            config: scn_data
        }

        //console.log('save scenario body', body);
        //return;

        try {
            const response = await apiService.aPost(state.auth, `${global.apiRoot}/study/${uid}/save-scenario`, body)

            const data = response // await response.json()
            if (response.ok) {
                dispatch({ type: `${scenarioActionType}:SAVE_SCENARIO_RESULT`, value: data })
                if (callback) callback(true)

            } else if (!overwrite && response.status === 409) {
                // conflict. assuming file exists
                if (callback) callback(false, 'File exists', true) // send to callback for prompt

            } else {
                console.error(response)
                if (callback) callback(false, 'Server error')
            }
        } catch (err) {

            dispatch({ type: `${scenarioActionType}:SAVE_SCENARIO_RESULT`, value: {}, error: 'error saving scenario' })
            if (callback) callback(false, 'Server error')
        }

        dispatch({ type: `${scenarioActionType}:SET_WORKING`, value: null })
    },

    deleteFile: (fileId: string, overwrite: boolean, callback: any) => async (dispatch: any, getState: any) => {

        dispatch({ type: `${scenarioActionType}:SET_WORKING`, message: 'deleting...' })

        const state = getState()

        // get uid of study
        let uid = state.study.uid

        try {
            const response = await apiService.aDelete(state.auth, `${global.apiRoot}/study/${uid}/scenario/${fileId}?overwrite=${overwrite ? 'true' : 'false'}`)

            if (response.ok) {
                if (callback) { callback(true) }

            } else if (response.status === 409) {
                // conflict - assuming means that folder is not empty
                if (callback) {
                    callback(false, 'Folder is not empty.', true)
                }

            } else if (response.status === 400) {
                if (callback) {
                    callback(false, 'File not found')
                }

            } else {
                if (callback) {
                    callback(false, response.status + ' ' + response.statusText)
                }
                console.error(response)

            }

        } catch (err) {

            //dispatch({ type: `${scenarioActionType}:LOAD_FILES_RESULT`, value: {}, error: 'error deleting file' })
            if (callback) {
                callback(false, err)
            }
        }

        dispatch({ type: `${scenarioActionType}:SET_WORKING`, value: null })
    },

    renameFile: (fileId: string, newLabel: string, callback: any) => async (dispatch: any, getState: any) => {
        dispatch({
            type: `${scenarioActionType}:SET_WORKING`,
            message: 'renaming...'
        })

        const state = getState()

        const body = {
            label: newLabel
        }

        // get uid of study
        let uid = state.study.uid

        try {
            const response = await apiService.aPost(state.auth, `${global.apiRoot}/study/${uid}/scenario/${fileId}/patch`, body) // switching to post due to cors problems

            if (response.ok) {
                if (callback) {
                    callback(true)
                }
            } else if (response.status === 400) {
                if (callback) {
                    callback(false, 'File not found')
                }
            } else {
                if (callback) {
                    callback(false, response.status + ' ' + response.statusText)
                }
                console.error(response)
            }

            //const data = await response()
            //dispatch({ type: `${scenarioActionType}:LOAD_FILES_RESULT`, value: data })
        } catch (err) {
            //dispatch({ type: `${scenarioActionType}:LOAD_FILES_RESULT`, value: {}, error: 'error deleting file' })
            if (callback) {
                callback(false, err)
            }
        }

        dispatch({ type: `${scenarioActionType}:SET_WORKING`, value: null })
    },

    // move folder
    moveFolder: (folderId: string, newParentId: string) => async (dispatch: any, getState: any) => {
        // todo:
    },

    // move scenario
    moveScenario: (scenarioId: string, newParentId: string) => async (dispatch: any, getState: any) => {
        // todo:
    }
}

export const reducer = (state: any, action: any) => {

    if( action.type === DCM_RESET ) return initialState

    if (!action.type.startsWith(scenarioActionType)) return state

    state = state || initialState
    console.log('state',state)
    var subType = action.type.substring(scenarioActionType.length + 1)

    switch (subType) {
        case 'SET_WORKING':
            return {
                ...state,
                scenarios: {
                    ...state.scenarios,
                    working: action.message || action.value,
                    error: null
                }
            }

        case 'SET_PROMPT':
            return {
                ...state,
                scenarios: {
                    ...state.scenarios,
                    prompt: action.message,
                    promptAction: action.action,
                    promptInput: action.input
                }
            }
        
        case 'LOAD_SCENARIO_RESULT':

            const { scenarios } = action

            const scenarioData = action.value?.config;

            let newState = { ...state }

            // note: scenario version is checked before this action is dispatched

            const modelNames = state.modelNames;
            if (!modelNames) return newState // this makes sure the state is "model" instead of dcmModel
            
            // load activeConfig
            // newState.activeConfig = scenarioData?.activeConfig

            // load config for each model into newState
            for (let modelName of modelNames) {
                if (scenarioData?.models?.[modelName]) {
                    newState[modelName] = {
                        ...newState[modelName],
                        config: {
                            ...newState[modelName]?.config,
                            play: scenarioData.models[modelName].config?.play
                        }
                    }
                }
            }

            return newState

        
        case 'LOAD_SCENARIO_RESULT_SINGLECONFIG': 

            console.log('load scenario result singleconfig .action = ', action)

            let retval = {
                ...state,
                config: {
                    ...state.config, // keep existing productDefs, etc.
                    //selectedItem: null,
                    selectedArea: 'product',
                    etag: guid(),
                }
            }

            // load product slots
            {
                let products = action.value.config.products || []//Entries = Object.entries(action.value.config.products)
                products.forEach((p: any) => {
                    const uid = p.uid
                    //console.log('running entry ', entry)
                    let dslot = retval.config.productSlots.find((dslot: any) => dslot.uid == uid)
                    dslot.play = { ...p }
                    dslot.play.dirty = dirtyCheckSlot(dslot, state.config.productDefs.default)
                    //dslot.play = parseFloat(entry[1])
                })
            }

            // // load brand equities
            // {
            //     let srcBrandEquities = action.value.config.brandEquities
            //     let destBrandEquities = retval.config.brandEquities
            //     let level = srcBrandEquities.level
            //     let data = srcBrandEquities.data
            //     const itemEntries = Object.entries(data)
            //     itemEntries.forEach(itemEntry => {
            //         const itemKey = itemEntry[0]
            //         const item = itemEntry[1]
            //         let destItem = destBrandEquities[`${level}:${itemKey}`]
            //         const perceptionEntries = Object.entries(item)
            //         perceptionEntries.forEach(perceptionEntry => {
            //             const perceptionKey = perceptionEntry[0]
            //             const val = perceptionEntry[1]
            //             if( destItem[perceptionKey] ){
            //                 destItem[perceptionKey].play = val
            //             }
            //         })
            //     })
            // }

            //console.log('loaded singleconfig into scenario: ', retval)
            return retval
        

        case 'SAVE_SCENARIO_RESULT':
            return {
                ...state,
                scenarios: {
                    ...state.scenarios,
                    working: null,
                    error: action.error,
                }
            }

        case 'OPEN_SCENARIO_RESULT':
            return {
                ...state,
                scenarios: {
                    ...state.scenarios,
                    working: null,
                    error: action.error
                }
            }

        case 'LOAD_FILES_RESULT':
            
            return {
                ...state,
                scenarios: {
                    ...state.scenarios,
                    working: null,
                    error: action.error,
                    files: action.value
                }
            }

        case 'CLEAR_ERRORS':
            return {
                ...state,
                scenarios: {
                    ...state.scenarios,
                    error: null
                }
            }

        default:
            return state
    }
}
