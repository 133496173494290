import * as React from 'react';
import { AllSelections } from './AllSelectionsElement';
import { CardContainer } from './CardContainer';
import './ContentViewer.scss';
import { DynamicCardElement } from './DynamicCardElement';
import { FilterContainer } from './FilterContainer';
import { Jumbotron } from './JumbotronElement';
import { MarkdownElement } from './MarkdownElement';
import { PageContentViewer } from './PageContentViewer';
import { QuestionContainer } from './QuestionContainer';
import { ResponsiveRowElement } from './ResponsiveRowElement';
import { SectionElement } from './SectionElement';
import { SlideContainer } from './SlideContainer';
import { TileContainerElement } from './TileContainerElement';
import { useEffect, useState } from "react";
import { useSelector, useStore } from 'react-redux';
import * as jsCompiler from '../../utils/jsCompiler';
import { TabControlElement } from './TabControlElement';
import { NavElement } from './NavElement';
import { ContentItemPointer } from './ContentItemPointer';
import { CardCarousel } from './CardCarousel';
import { Slideshow } from './Slideshow';
import { PageTitle } from './PageTitle';
import { SelectionBlockElement } from './SelectionBlockElement';
import { FlexContainer } from './FlexContainer';
import { FlexItem } from './FlexItem';

/** Receives a fully loaded item and determines which Element should render it based on type. 'Fully loaded' means that
 * the item is loaded and the ids of its children are loaded. The item is responsible for fully loading its children.
 */
export const ContentViewer = (props) => {

    const item = props.item;
    const siblings = props.siblings;
    const [instances, setInstances] = useState(null);
    const [error, setError] = useState(null);
    

    const reduxStore = useStore();
    const auth = useSelector(state => state.auth);
    const study = useSelector(state => state.study); // totally sucks to need entire study, since study contains way to many things that change

    const pass_props = {
        asPage: props.asPage,
        reloadItem: props.reloadItem,
        reloadParentItem: props.reloadParentItem,
        item: props.item,
        parentItem: props.parentItem,
        id: props.item?.id,
        key: props.rkey + "_" + item.id,
        edit: props.edit,
        restore: props?.restore,
        restoredItem: props?.restoredItem,
        pageLevel: props.pageLevel,
        setLayoutReady: props.setLayoutReady, // note: for now, i won't worry about the fact that if multiple instances, this will call early, after a single instance is ready. but i'm not worrying abobut it right now because instances might go away.
        layoutReady: props.layoutReady,
        classes: props?.classes
    }

    useEffect(() => {

        const getInstances = item?.data?.getInstances;
        if( !getInstances ){
            setInstances([{}]);
            return;
        }

        try{
            const context = jsCompiler.getDefaultContext(study, auth, reduxStore);
            const lib = jsCompiler.defaultLib;

            getInstances(context, lib)
                .then(res => setInstances(res))
                .catch(err => {
                    setInstances([{}]);
                    //setError('error running getInstances: ' + err);
                    console.log('error running getInstances:', err);
                });
        }
        catch( err ){
            setInstances([{}]);
            //setError(err);
            console.log('unknown error running getInstances', err);
            console.log(err);
        }

    }, [item?.data?.instances])

    if( error ){
        return error;
    }

    if( !instances ){
        return 'loading...';
    }
        
    const getContent = (item) => {
        if( !item ) return null;

        return (instances || [{}]).map((instance, instance_idx) => {

            const pass_props2 = {
                ...pass_props,
                ...instance,
                instanceContext: instance,
                instanceIndex: instance_idx,
                key: 'instance' + instance_idx,
            }

            switch( item.type ){
                case 'page': return <PageContentViewer {...pass_props2} footer={props.footer} />;
                case 'tile-container': return <TileContainerElement {...pass_props2} />;
                case 'nav-element': return <NavElement {...pass_props2} />;
                case 'tab-control': return <TabControlElement {...pass_props2} />;
                case 'filter-container' : return <FilterContainer {...pass_props2}/>;
                case 'question-container' : return <QuestionContainer {...pass_props2}/>;
                case 'slide-container' : return <SlideContainer {...pass_props2}/>;
                case 'card-container' : return <CardContainer {...pass_props2}/>;
                case 'all-selections' : return <AllSelections {...pass_props2}/>;
                case 'jumbotron' : return <Jumbotron {...pass_props2}/>;
                case 'section': return <SectionElement {...pass_props2} />;
                case 'tab' : return <SectionElement {...pass_props2} asTab={true}/>;
                case 'flexcontainer' : return <FlexContainer {...pass_props2}/>; //make like rrow
                case 'flexitem' : return <FlexItem {...pass_props2}/>; //make like dcard
                case 'markdown' : return <MarkdownElement {...pass_props2}/>;
                case 'rrow' : return <ResponsiveRowElement {...pass_props2}/>;
                case 'dcard': return <DynamicCardElement {...pass_props2} carouselInfo={props.carouselInfo} />;
                case 'pointer': return <ContentItemPointer {...pass_props} />;
                case 'ccarousel': return <CardCarousel {...pass_props} />;
                case 'slideshow': return <Slideshow {...pass_props2} />;
                case 'pagetitle': return <PageTitle {...pass_props2} />;
                case 'selection-block' : return <SelectionBlockElement {...pass_props} />;
                default:
                    return null;
                   
            };

        })
        
    }

    

    return item ? getContent(item) : '[missing item]';

}



