import * as React from 'react';
import { useEffect } from 'react';
import './ContentViewer.scss';
import { EditModeWrapper } from './EditModeWrapper';
import { ContentLoader } from './ContentLoader';

const isNull = (x) => x === null || x === undefined;

const ifNull = (x, fallback) => isNull(x) ? fallback : x;

export const PageTitle = (props) => {

    const item = props.item;

    useEffect(() => {
        if (item) {
            //console.log('pagetitle layout ready', item?.id);
            props.setLayoutReady?.(true);
        }
    }, []);

    if (!item) return null;
        
    let titleStyle =  {
        fontSize: '32px', 
        fontWeight: 'normal', 
        paddingLeft: 0, 
        textTransform: 'capitalize'
    }

    titleStyle = { ...titleStyle, ...item?.data?.title_style };

    let subTitleStyle = {
        fontSize: '16px', 
        fontWeight: 'normal', 
        paddingLeft: 0,
        textTransform: 'capitalize', 
    }

    subTitleStyle = {...subTitleStyle,  ...item?.data?.subtitle_style }
    const titleStr = item?.title || props.parentItem?.title;


    return <EditModeWrapper
                className='has-children' item={item} edit={props.edit} restoredItem={props?.restoredItem} reloadItem={props.reloadItem}
                reloadParent={props.reloadParentItem} pageLevel={props.pageLevel}
    >
        <div style={{width:'100%', backgroundColor: item?.data?.backgroundColor}}>
        <div id={item.id} className={`responsive-row-element ${item?.data?.wide ? '' : 'page-width'}`} style={{  justifyContent: item?.data?.flex_align, padding: item?.data?.padding || 0, gap:0 }}>
            <span style={ titleStyle}>{ props.edit?.editMode ?  (titleStr  || 'Title Missing') : titleStr}</span>            
           
            <div className='page_subtitle'>
                 <span style={ subTitleStyle}>{ item?.data?.subTitle}</span>
            </div>         

            </div>
        </div>
        <div className='page_title_element'>
            {item.contents?.map(child => <ContentLoader key={child.id} id={child.id} reloadParentItem={props.reloadItem} edit={props.edit} restore={props.restore} restoredItem={child?.deleted} pageLevel={props.pageLevel} />)}
            {/* {(item.contents?.length > 0 || showHeading) ? null : (props.edit?.editMode ? `empty` : null)} */}
        </div>
    </EditModeWrapper>;
}
