


export const needsRefresh = (cache, pathItems) => {

    if( !cache ) return true;

    let arr = [...pathItems];
    let iter = cache;

    while( arr.length > 0 ){
        let key = arr.pop();
        iter = iter[key];
        if( !iter ) return true; // object not found. needs refresh

        if( iter.expires ){
            // todo
        }
    }

    return false; // data already exists

}



export const needsRefresh2 = (cache, path) => {

    if( !cache ) return true;

    let iter = cache[path];
    if( !iter ) return true;

    if( iter.expires ){
        // todo
    }

    return false; // data already exists

}

export const getCache = (container, key) => {
    if( !container ) return null;

    const cache = container.cache;
    if( !cache) return null;

    return cache[key];
    
}