import * as globals from "../globals";

export const commaOrJoin = (items) => {
	if (items.length === 0) return null;

	let str = items[0];
	let i = 1;
	while (i < items.length) {
		let sep = i < items.length - 1 ? ", " : " or ";
		str += sep + items[i];

		i++;
	}
	return str;
};

export const getAutoFilterGroupLabel = (filterGroup) => {
	let segs = [];

	if (filterGroup.allowRange || filterGroup.allowEllipsis) {
		//let range = filterGroup.allowRange;
		let msegs = [];

		let min = null;
		let max = null;
		//let defaultRangeSep = range ? ' to ' : ' ... ';
		let rangeSep = filterGroup.rangeSep || " to ";

		let maxUnbounded = null;
		for (let i in filterGroup.filters) {
			let f = filterGroup.filters[i];
			if (f.isSelected) {
				if (min == null) {
					min = f.min || f.label;
					max = f.max || f.label;
					maxUnbounded = f.maxUnbounded;
				} else {
					max = f.max || f.label;
					maxUnbounded = f.maxUnbounded;
				}
			} else {
				if (min != null) {
					msegs.push(
						min === max
							? min
							: maxUnbounded
							? min + "" + max
							: min + rangeSep + max,
					);
					min = null;
				}
			}
		}
		if (min != null) {
			msegs.push(
				min === max
					? min
					: maxUnbounded
					? min + "" + max
					: min + rangeSep + max,
			);
		}

		if (msegs.length === 0) {
		} else {
			if (filterGroup.rangePrec != null) {
				segs.push(filterGroup.rangePrec + " " + msegs.join(", "));
			} else if (filterGroup.rangePost != null) {
				segs.push(msegs.join(", ") + " " + filterGroup.rangePost);
			} else {
				segs.push(msegs.join(", "));
			}
		}
	} else {
		// join using , and or

		for (let i in filterGroup.filters) {
			let f = filterGroup.filters[i];
			if (f.isSelected) {
				segs.push(f.label);
			}
		}
	}

	if (segs.length === 0) return null;

	if (segs.length === 1) return segs[0] || "";

	//return segs.map(s => s.includes('or') ? `(${s})` : s).join(' or ');
	let items = segs.map((s) => (s.includes("or") ? `'${s}'` : s));
	return commaOrJoin(items);
};

// export const getAutoFiltersLabel = filterGroups => {
//     let groups = [];
//     for (let _fg in filterGroups) {
//         let fg = filterGroups[_fg];
//         let label = getAutoFilterGroupLabel(fg);
//         if (label != null) groups.push(label);
//     }

//     if (groups.length === 0) return 'Total';

//     if (groups.length === 1) return groups[0];

//     let finalGroups = [];

//     for (let i in groups) {
//         let group = groups[i];
//         if (group.includes('or')) {
//             finalGroups.push('(' + group + ')');
//         } else {
//             finalGroups.push(group);
//         }
//     }

//     return finalGroups.join(' ');
// };
export const generateFilterString = (filterGroups, property) => {
    let groups = [];

    for (let _fg in filterGroups) {
        let fg = filterGroups[_fg];
        let segs = fg.filters.filter(f => f.isSelected).map(f => f[property])

        if (segs.length === 0) continue;

        if (segs.length === 1) {
            groups.push(segs[0]);
        } else {
            // Apply different logic based on the property
            if (property === 'name') {
                // Join without parentheses for names
                groups.push(segs.join("_or_"));
            } else {
                // Wrap with parentheses for syntax
                groups.push(`(${segs.join(" or ")})`);
            }
        }
    }

    if (groups.length === 0) return property === 'name' ? "total" : "1==1";
    if (groups.length === 1) return groups[0];

    // Join groups with 'and' for both name and syntax
    return groups.join(property === 'name' ? "_and_" : " and ");
}



export const getAutoFiltersLabel = (filterGroups, defaultFilterLabel) => {
	let groups = [];
	for (let _fg in filterGroups) {
		let fg = filterGroups[_fg];
		let label = getAutoFilterGroupLabel(fg);
		if (label != null) groups.push(label);
	}

	if (groups.length === 0) return defaultFilterLabel;

	if (groups.length === 1) return groups[0];

	let finalGroups = [];

	for (let i in groups) {
		let group = groups[i];
		if (group.includes("or")) {
			//finalGroups.push('(' + group + ')');
			finalGroups.push(group);
		} else {
			finalGroups.push(group);
		}
	}

	return finalGroups.join(" | ");
};

// export const getFilterGroupSyntax = (filterGroup) => {
// 	let segs = [];

// 	// join using , and or
// 	for (let i in filterGroup.filters) {
// 		let f = filterGroup.filters[i];
// 		if (f.isSelected) {
// 			segs.push(f.syntax);
// 		}
// 	}

// 	if (segs.length === 0) return null;

// 	if (segs.length === 1) return segs[0];

// 	//return segs.map(s => s.includes('or') ? `(${s})` : s).join(' or ');
// 	// let items = segs; //.map(s => (s.includes('or') ? `'${s}'` : s));
// 	// return commaOrJoin(items);

// 	return "(" + segs.join(" or ") + ")";
// };

// export const getFilterSyntax = (filterGroups) => {
// 	let groups = [];
// 	for (let _fg in filterGroups) {
// 		let fg = filterGroups[_fg];
// 		let syntax = getFilterGroupSyntax(fg);
// 		if (syntax != null) groups.push(syntax);
// 	}

// 	if (groups.length === 0) return "1==1";

// 	if (groups.length === 1) return groups[0];

// 	return groups.join(" and ");
// };

// export const getSelectedFilter = (state) => {
// 	const syntax = getFilterSyntax(state.filterGroups)
// 	const name = getName(state.filterGroups)
// 	let retval = {
// 		useCustomLabel: (state.selectedFilter || {}).useCustomLabel,
// 		autoLabel: getAutoFiltersLabel(	state.filterGroups,	state.defaultFilterLabel),
// 		customLabel: (state.selectedFilter || {}).customLabel,
// 		syntax, //: getFilterSyntax(state.filterGroups)
// 		name,
// 		n: syntax == state.syntax ? state.n : null, // keep n if syntax hasn't changed, otherwise clear it
// 	};
// 	retval.label = retval.useCustomLabel ? retval.customLabel : retval.autoLabel;
// 	if (
// 		!retval.label &&
// 		retval.useCustomLabel &&
// 		retval.syntax == globals.getDefaultFilter(state).syntax
// 	)
// 		retval.label = globals.getDefaultFilter(state).label;
// 	return retval;
// };
export const getSelectedFilter = (state, datasetId) => {

	const filterGroups = state.filterGroups
		.filter(fg => !datasetId || (datasetId === fg.datasetId));
	
	const selectedFilter = datasetId ? state.selected?.[datasetId] : state.selectedFilter;

    const syntax = generateFilterString(filterGroups, 'syntax');
	const name = generateFilterString(filterGroups, 'name');
	
	

    let retval = {
        useCustomLabel: (selectedFilter || {}).useCustomLabel,
        autoLabel: getAutoFiltersLabel(filterGroups, state.defaultFilterLabel),
        customLabel: (selectedFilter || {}).customLabel,
        syntax,
        name,
        n: syntax == selectedFilter?.syntax ? selectedFilter?.n : null,
    };

    retval.label = retval.useCustomLabel ? retval.customLabel : retval.autoLabel;
    if (!retval.label && retval.useCustomLabel && retval.syntax == globals.getDefaultFilter(state).syntax)
        retval.label = globals.getDefaultFilter(state).label;

    return retval;
}
