// // Returns a function, that, as long as it continues to be invoked, will not
// // be triggered. The function will be called after it stops being called for
// // N milliseconds. If `immediate` is passed, trigger the function on the
// // leading edge, instead of the trailing.
// export const debounce = (func, wait) => {

//     var timeout;
//     let x = this;

//     return function(ev) {
//         let newVal = parseFloat(ev.target.value, 10);

//         x.setState({ val: newVal, item: x.props.item, message: x.props.message });

//         var context = this;
//         var later = function() {
//             timeout = null;
//             func.apply(context, [newVal]);
//         };
//         clearTimeout(timeout);
//         timeout = setTimeout(later, wait);
//     };

// }


export function debounce(a,b,c){var d,e;return function(){function h(){d=null,c||(e=a.apply(f,g))}var f=this,g=arguments;return clearTimeout(d),d=setTimeout(h,b),c&&!d&&(e=a.apply(f,g)),e}}

export const forecastDelay = 500;
export const sliderDelay = 500;
export const insightsTextDelay = 1000;
export const basicDelay = 500;