import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { themes } from '../../theme';
import './PageHeader.scss';

export class PageHeader2 extends React.Component{

    render(){

        var themekeys = Object.keys(themes);

        return <div className='page-header-v2'>
            <div className={'limit-width-container ' + (this.props.fullWidth ? 'full-width' : 'page-width')}>
                <div className='xtitle'>
                    {this.props.title || 'title goes here'}
                </div>
                <div className='right-side'>
                    {this.props.left}
                    {this.props.right}
                </div>
            </div>
        </div>
    }

}

PageHeader2 = withRouter(
    connect(
        state => ({}),
        dispatch => ({})
    )(PageHeader2)
);

export const PageHeader = PageHeader2;