import * as React from 'react';
import { useState } from "react";
import { useSelector } from "react-redux";
import * as globals from '../../globals';
import * as apiService from '../../utils/apiService';
import './EditModeWrapper.scss';
import { ElementProperties } from './ElementPropertyEditor';
import { ChildContentAdder } from './ChildContentAdder';
import { ChildCardAdder } from './ChildCardAdder';
import { ElementNotesEditor } from './ElementNotesEditor';


const deleteItem = (auth, study_uid, item_id, callback) => {
    // const study_uid = item.study_uid;
    // const item_id = item.id;
    console.log('delete item', study_uid, item_id);
    apiService.aDelete(auth, `${globals.apiRoot}/study/${study_uid}/content/${item_id}`)
        //.then(() => history.push(getParentLink(params, study_uid)))
        //.then(() => window.location.reload())
        .then(() => {
            if( callback ) callback();
        })
        .catch((err) => console.error(err));
}

const moveChild = (auth, study_uid, item_id, offset, callback) => {
    console.log('move Child');
    apiService.aPost(auth, `${globals.apiRoot}/study/${study_uid}/content/${item_id}/move`, { offset })
        .then(() => {
            console.log('moved Child');
            if( callback ) callback();
        })
        .catch((err) => console.error(err));
}

const cloneElement = (auth, study_uid, item_id, callback) => {
    console.log('clone element');
    apiService.aPost(auth, `${globals.apiRoot}/study/${study_uid}/content/${item_id}/clone`)
        .then(() => {
            if( callback ) callback();
        })
        .catch((err) => console.error(err));
}



export const EditModeWrapper = (props) => {
    if( !props.edit?.editMode )
        return props.children;

    const item = props.item;
    const study_uid = useSelector(state => state.study?.uid);
    const auth = useSelector(state => state.auth);
    const [showProps, setShowProps] = useState(false);
    const [showNotes, setShowNotes] = useState(false);
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [showHardDeletePrompt, setShowHardDeletePrompt] = useState(false);
    const [message, setMessage] = useState(null);
    const [reloadCounter, setReloadCounter] = useState(0);
    const [showCardAdder, setShowCardAdder] = useState(false);
    //const [showMoreOption, setShowMoreOption] = useState(false);
    //const [showAddMenu, setShowAddMenu] = useState(false);
    const [menu, setMenu] = useState(null);
    const [debug, setDebug] = useState(false);
    const [pinned, setPinned] = useState(false);
    // const reloadSelf = () => {
    //     setReloadCounter(reloadCounter+1);
    //     if( item.type === 'page' ){
    //         reloadParent();
    //     }
    //     console.log('itemType', item.type);
    //     //props.reloadParent?.();
    // }
    // const reloadSelfAndParent = () => {
    //     setReloadCounter(reloadCounter+1);
    //     props.reloadParent?.();
    // }
    const reloadParent = () => {
        console.log('reloading parent');
        if( props.reloadParentItem ){
            console.log('(exists)');
            props.reloadParentItem();
        }
    }

    const restoreElement = (auth, study_uid, item_id, callback) => {
        apiService.aPost(auth, `${globals.apiRoot}/study/${study_uid}/content/${item_id}/undelete`)
        .then(() => {
            console.log('restored');
            if( callback ){
                callback();
            }
        })
        .catch((err) => {
            console.error(err);
        })
    }

    const hardDelete = (auth, study_uid, item_id, callback) => {
        apiService.aDelete(auth, `${globals.apiRoot}/study/${study_uid}/content/${item_id}/hard-delete`)
        .then(() => {
            console.log('delete permanently');
            if( callback ){
                callback();
            }
        })
        .catch((err) => {
            console.error(err);
        })
    }

    const allowAddCard = item?.type === 'card-container';
    const allowAddElement = item?.type === 'page'
        || item?.type === 'tile-container'
        || item?.type === 'tab-control'
        || item?.type === 'tab'
        || item?.type === 'section'
        || item?.type === 'flexcontainer'
        || item?.type === 'flexitem'
        || item?.type === 'rrow'
        || item?.type === 'ccarousel'
        || item?.type === 'pagetitle'
        || item?.type === 'slideshow'
        ;
    
    const hasNotes = item?.data?.notes && item?.data?.notes.trim() !== "";
    const devStatus = item?.data?.devStatus || "ok";

    const addCard = (auth, study_uid, container_item, card_id, callback ) => {
        
        const body = {
            cards: container_item?.props?.cards ? [...container_item.props.cards, card_id] : [card_id],
            mergeData: true
        };
        const response = apiService.aPatch(auth, `${globals.apiRoot}/study/${study_uid}/content/${container_item?.id}`, body)
        .then(() => {
            console.log('updated');
            if( callback ){
                callback();
            }
        })
        .catch((err) => {
            console.error(response);
        })
    }
    

    let buggedChildren = null;
    if (debug) {
        buggedChildren = React.cloneElement(props.children, { debug: "true" });
    }

    return <div className={'edit-mode-wrapper ' + (props.className ? props.className : '') + (pinned ? ' pinned' : '')} style={props.style}>
        {message ? <div className='message'>
            {message}
        </div> : null}
        {showDeletePrompt ? <div className='delete-prompt'>
            <div className='prompt'>
                Delete element and all descendants?
            </div>
            <div className='buttons'>
                <span className='btn' onClick={() => {
                    setMessage("deleting...");
                    setShowDeletePrompt(false);
                    deleteItem(auth, study_uid, item.id, () => { setMessage(null); reloadParent(); })
                }}><i className='fal fa-trash-alt'/> delete</span>
                <span className='btn' onClick={() => setShowDeletePrompt(false)}>cancel</span>
            </div>
        </div> : null}
        {showHardDeletePrompt ? <div className='delete-prompt'>
            <div className='prompt'>
                Delete element and all descendants permanently? Cannot restore.
            </div>
            <div className='buttons'>
                <span className='btn' onClick={() => {
                    setMessage("deleting...");
                    setShowHardDeletePrompt(false);
                    hardDelete(auth, study_uid, item.id, () => { setMessage(null); reloadParent(); })
                }}>
                <i className='fal fa-skull' /> Hard delete</span>
                <span className='btn' onClick={() => setShowHardDeletePrompt(false)}>cancel</span>
            </div>
        </div> : null}
        {showProps ? <ElementProperties item={item} reloadItem={props.reloadItem} floating={props.mini} closeRequested={() => setShowProps(false)} /> : null}
        {showNotes ? <ElementNotesEditor item={item} reloadItem={props.reloadItem} floating={props.mini} closeRequested={() => setShowNotes(false)} />  : null}
        <div className={'edit-mode-content' + (props?.restoredItem ? ' restored-element' : '')} key={reloadCounter}>
            {debug ? buggedChildren : props.children}
        </div>
        <div className='edit-mode-overlay-rect'/>
        {props.mini ? 
            <div className='edit-mode-overlay-buttons'>
                <span className='rtbuttons'>
                    <span className='btn' title='properties' onClick={() => setShowProps(!showProps)}><i className='fal fa-id-card' /></span>
                    <span className='btn' title='move up' onClick={() => moveChild(auth, study_uid, item.id, -1, reloadParent)}><i className='fal fa-arrow-up'/></span>
                    <span className='btn' title='move down' onClick={() => moveChild(auth, study_uid, item.id, +1, reloadParent)}><i className='fal fa-arrow-down'/></span>
                    <span className='btn more-options' onClick={() => setMenu(menu === "more" ? null : "more")}>
                        <i className='far fa-ellipsis-v'/>
                        { menu === 'more' ?
                            <ul>
                                <li><span className='btn' title='clone' onClick={() => cloneElement(auth, study_uid, item.id, reloadParent)}><i className='fal fa-clone'/>Clone</span></li>
                                <li><span className='btn' title='delete element and all descendents' onClick={() => setShowDeletePrompt(true)}><i className='fal fa-trash-alt'/>Delete</span></li>
                            </ul>   
                            : null                 
                        }
                    </span>
                </span>
            </div> :
            <div className={'edit-mode-overlay-buttons'} style={{zIndex: 900 - props.pageLevel}}>
                {props?.restoredItem ? 
                <span className='rtbuttons'>
                    <span className='btn' title={`Restore ${item?.type}`} onClick={() => restoreElement(auth, study_uid, item.id, reloadParent)}><i className='fal fa-trash-restore'/></span>
                    <span className='btn' title='Delete Permanently' onClick={() => setShowHardDeletePrompt(true)}><i className="fal fa-skull" /></span>
                </span>
                :
                <>
                <span className='uid'>[{item?.type}]</span>
                <span className='rtbuttons'>   
                    {allowAddCard ? <span className='btn' title='add card' onClick={() => setShowCardAdder(!showCardAdder)}><i className='far fa-plus'/></span> : null}
                    {showCardAdder ? <ChildCardAdder closeRequested={() => setShowCardAdder(false)} className='btn nopad' item={item} reloadParent={props.reloadItem} addCard={addCard}/> : null}
                    {allowAddElement ? <span className='btn more-options' onClick={() => setMenu(menu === "add" ? null : "add")}>
                        <i className='fal fa-plus'/>
                        {menu === 'add' ? <ChildContentAdder className='btn more-options' item={item} reloadParent={props.reloadItem}/> : null}
                        {/* <ChildContentAdder className='btn more-options' item={item} reloadParent={props.reloadItem}/> */}
                    </span> : null}
                    <span className='btn' title='properties' onClick={() => setShowProps(!showProps)}><i className='fal fa-id-card' /></span>
                            <span className='btn' title='notes' onClick={() => setShowNotes(!showNotes)}>{
                                devStatus === "todo" ? <i className='fas fa-engine-warning' style={{ color: '#ec223c' }} /> :
                                devStatus === "ok" ? (hasNotes ? <i className='fal fa-comment-alt-lines' /> : <i className='fal fa-comment-alt' />) :
                                <i className='fas fa-engine-warning' style={{ color: '#faf615' }} />}</span>
                    <span className='btn' title='move up' onClick={() => moveChild(auth, study_uid, item.id, -1, reloadParent)}><i className='fal fa-arrow-up'/></span>
                    <span className='btn' title='move down' onClick={() => moveChild(auth, study_uid, item.id, +1, reloadParent)}><i className='fal fa-arrow-down'/></span>
                    <span className='btn' title='pin' onClick={() => setPinned(!pinned)}><i className={pinned ? 'fas fa-thumbtack' : 'fal fa-thumbtack'}/></span>
                    <span className='btn more-options' onClick={() => setMenu(menu === 'more' ? null : 'more')}>
                        <i className='far fa-ellipsis-v'/>
                        { menu === 'more' ?
                            <ul>
                                <li><span className='btn' title='clone' onClick={() => cloneElement(auth, study_uid, item.id, reloadParent)}><i className='fal fa-clone'/>Clone</span></li>
                                <li><span className={'btn' + (debug ? ' on' : '')} title='debug' onClick={() => setDebug(!debug)}>
                                    <i className={debug ? 'fas fa-bug' : 'fal fa-bug'}/>Debug</span>
                                </li>
                                <li><span className='btn' title='delete element and all descendents' onClick={() => setShowDeletePrompt(true)}><i className='fal fa-trash-alt'/>Delete</span></li>
                            </ul>   
                            : null                 
                        }
                    </span>
                </span>
                </>
            }
            </div>
        }
    </div>
}

