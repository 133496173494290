import * as React from 'react';
import './Card.scss';

export function CardLoading(props) {

        return <div className='card-loading' ref={props?.reference} >
            <div className={'pane' + (props.loading || props.expired ? ' visible' : '')}>
                {props.loading ? 
                    <>
                        {/* loading<span className='blink_cursor'>_</span> */}
                        {/* <img className='spin_logo' src='/images/bo55px.png' alt="Blue Owl logo" /> */}
                        <img style={{maxWidth:650}} src='/images/New_2023_Bo_Small_Spinner_White_Bkgrd.gif' alt="Blue Owl logo" />

                        {/* <img className='spin_logo' src='/images/bo55px.png' alt="Blue Owl logo" />
                        <span style={{display:'block', color: '#202020', paddingTop:5}}>ANALYZING...</span> */}
                    </>
                    : props.expired ? 
                        <>
                            {/* expired<span className='blink_cursor'>_</span> */}
                            <img src='/images/bo55px.png' alt="Blue Owl logo" />
                            <span style={{display:'block', color: '#202020'}}>EXPIRED</span>
                        </>
                    :
                    null
                }
                
            </div>
        </div>

}