import * as React from 'react';
import { useState } from "react";
import { useSelector } from "react-redux";
import * as globals from '../../globals';
import * as apiService from '../../utils/apiService';
import { ComboBox } from '../common/ComboBox';
import { ComboBoxCustom } from '../common/ComboBoxCustom';


const SECTION_ELEMENT = {
    label: "Section",
    key: "section",
    body: {
        type: 'section',
        title: 'Section',
        data: {
            title: 'Section title',
            subTitle: 'subtitle goes here',
            textAlign: 'center'
        }
    }
}


const TILE_CONTAINER = {
    label: "Tile Container",
    key: "tile-container",
    body: {
        type: 'tile-container',
        title: null, //'Tile Container',
        data: {
            tileBackground: null
        }
    }
}

const NAVELEMENT_CONTROL = {
    label: "Nav Element",
    key: "nav-element",
    body: {
        type: 'nav-element',
        data: {
            navItems: [{ "id":"nav1", "label":"Executive Summary", "anchorId":"exec_sum", "active": true}, { "id":"nav2", "label":"Data Analysis", "anchorId":"data_analysis"}]
        }
    }
}



const TAB_CONTROL = {
    label: "Tab Control",
    key: "tab-control",
    body: {
        type: 'tab-control',
        title: null, //'Tile Container',
        data: {
        }
    }
}

const TAB_CONTROL_V2 = {
    label: "Tab Control (blue-buttons)",
    key: "tab-control",
    body: {
        type: 'tab-control',
        title: null, //'Tile Container',
        data: {
            tabStripClass: "blue-buttons"
        }
    }
}

const TAB = {
    label: "Tab",
    key: "tab",
    body: {
        type: 'tab',
        title: 'Tab',
        data: {
        }
    }
}


const JUMBOTRON = {
    label: "Jumbotron",
    key: "jumbotron",
    body: {
        type: 'jumbotron',
        title: 'Jumbotron title',
        data: {
            imageRef: null
        }
    }
}

const CARD_CONTAINER = {
    label: "Card Container",
    key: "card-container",
    body: {
        type: 'card-container',
        title: null, //'Card Container',
        data: {
            cards: []
        }
    }
}


const TILE = {
    label: "Page (label and icon)",
    key: "page",
    body: {
        type: 'page',
        title: 'New Page',
        data: {
            icon: "fal fa-rocket"
        },
        description: 'description'
    }
}

const TILE2 = {
    label: "Page (blank)",
    key: "page",
    body: {
        type: 'page',
        title: null,
        data: {
            
        }
    }
}

const FLEXBOX_CONTAINER = {
    label: "Flexbox Container",
    key: "flexcontainer",
    body: {
        type: 'flexcontainer'
    }
}

const FLEXBOX_ITEM = {
    label: "Flex Item",
    key: "flexitem",
    body: {
        type: 'flexitem'
    }
}

const MARKDOWN_ELEMENT = {
    label: "Markdown",
    key: "markdown",
    body: {
        type: 'markdown',
        data: {
            content: 'enter markdown here'
        }
    }
}

const RROW_ELEMENT = {
    label: "Responsive Row",
    key: "rrow",
    body: {
        type: 'rrow'
    }
}

const CARD_CAROUSEL = {
    label: "Card Carousel",
    key: "ccarousel",
    body: {
        type: 'ccarousel'
    }
}

const SLIDESHOW = {
    label: "Slideshow",
    key: "slideshow",
    body: {
        type: 'slideshow'
    }
}

const PAGE_TITLE = {
    label: "Page Title",
    key: "pagetitle",
    body: {
        type: 'pagetitle',
        // title: '{parent.title}'
    }
}

const DYNAMIC_CARD_ELEMENT = {
    label: "Dynamic Card",
    key: "dcard",
    body: {
        type: 'dcard'
    }
}

const CONTENT_ITEM_POINTER = {
    label: "Pointer",
    key: "pointer",
    body: {
        type: 'pointer'
    }
}

const SELECTION_BLOCK_ELEMENT = {
    label: "Selection Block",
    key: "selection-block",
    body: {
        type: 'selection-block'
    }
}

const SLIDE_CONTAINER = {
    label: "Slide Container",
    key: "slide-container",
    body: {
        type: 'slide-container',
        data: {
            "slides": [
                { image: "slides/01 Data Description.JPG" },
                { image: "slides/02 Data Details v2.JPG" },
                { image: "slides/02B Data Collection.JPG" },
                { image: "slides/12 Big Data 1.JPG" },
                { image: "slides/12 Big Data 2.JPG" },
                { image: "slides/13 Market Data.JPG" },
                { image: "slides/04 DCM1.JPG" },
                { image: "slides/05 DCM2.JPG" },
                { image: "slides/06 DCM3.JPG" },
                { image: "slides/07 DCM4.JPG" },
                { image: "slides/08 DCM5.JPG" },
                { image: "slides/09 DCM6.JPG" },
                { image: "slides/11 DCM8.JPG" }
            ]
        }
    }
}


export const ChildContentAdder = (props) => {

    //const item = props.item;
    const {id, type, title, contents, data} = props.item;
    const [tileContent, setTileContent] = useState(contents || []);

    const study_uid = useSelector(state => state.study?.uid);
    const auth = useSelector(state => state.auth);


    const addChild = (body, cb) => {
        apiService.aPost(auth, `${globals.apiRoot}/study/${study_uid}/content/${id}/child`, body)
            .then(res => res.json())
            .then(res => {
                if (cb)
                    cb(res, body)
            })
            .catch((err) => console.error(err));
    }

    let child_types = [];



    switch(type){
        case 'tile-container':
            child_types.push(TILE);
            child_types.push(TILE2);
            child_types.push(CONTENT_ITEM_POINTER);
            break;
        
        case 'tab-control':
            child_types.push(TAB);
            child_types.push(CONTENT_ITEM_POINTER);
            break;

        case 'page':
            child_types.push(TILE_CONTAINER);
            child_types.push(NAVELEMENT_CONTROL);
            child_types.push(TAB_CONTROL);
            child_types.push(TAB_CONTROL_V2);
            child_types.push(CARD_CONTAINER);
            child_types.push(JUMBOTRON);
            child_types.push(SECTION_ELEMENT);
            child_types.push(PAGE_TITLE);
            // child_types.push("filter-container");
            // child_types.push("question-container");
            child_types.push(SLIDE_CONTAINER);
            child_types.push(FLEXBOX_CONTAINER);
            child_types.push(RROW_ELEMENT);
            child_types.push(CONTENT_ITEM_POINTER);
            break;

        case 'tab':
        case 'section':
            child_types.push(TILE_CONTAINER);
            child_types.push(TAB_CONTROL);
            child_types.push(FLEXBOX_CONTAINER);
            child_types.push(RROW_ELEMENT);
            child_types.push(CONTENT_ITEM_POINTER);
            break;

        case 'flexcontainer':
            child_types.push(PAGE_TITLE);
            child_types.push(MARKDOWN_ELEMENT);            
            child_types.push(CONTENT_ITEM_POINTER);            
            child_types.push(SELECTION_BLOCK_ELEMENT);
            child_types.push(CARD_CAROUSEL);
            child_types.push(FLEXBOX_ITEM);
            break;
        
        // case 'flexitem':
        //      child_types.push(FLEXBOX_CONTAINER);
        

        case 'rrow':
            child_types.push(PAGE_TITLE);
            child_types.push(MARKDOWN_ELEMENT);            
            child_types.push(CONTENT_ITEM_POINTER);            
            child_types.push(SELECTION_BLOCK_ELEMENT);
            child_types.push(CARD_CAROUSEL);
            child_types.push(DYNAMIC_CARD_ELEMENT);
            child_types.push(SLIDESHOW);            
            break;

        case 'ccarousel':
            child_types.push(DYNAMIC_CARD_ELEMENT);
            //child_types.push(CONTENT_ITEM_POINTER);
            break;
        
        case 'slideshow':
            child_types.push(DYNAMIC_CARD_ELEMENT);
            child_types.push(CARD_CAROUSEL);
            child_types.push(CONTENT_ITEM_POINTER);
            break;
    

    }

    const selected = (payload) => {
        addChild(payload.body, () => {
            // callback:
            //console.log('added', payload.body);
            if( props.reloadParent ){
                //console.log('reloading parent');
                props.reloadParent();
            }
        })
    }


    return <ul>
        {child_types.map((ct, idx) =>
            <li key={ct.key} onClick={() => selected(ct)}>
                <span className="has-element-label"><i className='fal fa-code'/>{ct.label}</span>
            </li>)}
    </ul>;


 
    

}

