import * as globals from '../globals';
import * as apiService from '../utils/apiService';
// @ts-ignore
import { EXPLORE_OPTIONS_VISIBLE_SET, EXPLORE_SET_SELECTION, EXPLORE_TOGGLE_COMPETITOR, STUDY_RECEIVE, STUDY_RESET } from './action-types';

export interface ExploreState{

    selection: any;

    optionsVisible: boolean;

    competitors: any[];

    selectionLevel?: any;

}

export const initialState: ExploreState = {
    selection: null,
    optionsVisible: true,
    competitors: []
};

export const actionCreators = {

    setSelection: (selection: any, callback: any) => (dispatch: any, getState: any) => {
        dispatch({ type: EXPLORE_SET_SELECTION, selection });
        if( callback ){
            callback();
        }
    },
    setOptionsVisible: (visible: any) => async (dispatch: any, getState: any) => {
        dispatch({ type: EXPLORE_OPTIONS_VISIBLE_SET, value: visible });
    },
    toggleCompetitor: (selection: any, callback: any) => (dispatch: any, getState: any) => {

        dispatch({ type: EXPLORE_TOGGLE_COMPETITOR, selection });
        if( callback ){
            let state = getState();
            callback(state.explore?.competitors);
        }
    },
    getTiles: (studyId: any, contentId: any, callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();

        try {
            const url = `${globals.apiRoot}/study/${studyId}/content/${contentId}`;
            const response = await apiService.aGet(state.auth, url);

            if( response.ok ){
                const res = await response.json();
                if( callback ) callback(res, false);
            }
            else{
                if( callback ) callback(null, false);
            }
        } catch(err) {
            console.log(err);
            if (callback) callback(null, err);
        }

    }

};


export const reducer = (state: ExploreState, action: any): ExploreState => {

    state = state || initialState;

    switch( action.type ){

        case STUDY_RESET : return initialState;

        case STUDY_RECEIVE : {
            const selectionLevel = action.value.explore?.selectionLevel || 'product';
            return {
                ...state,
                selectionLevel
            };
        }
        
        case EXPLORE_SET_SELECTION : {

            return {
                ...state,
                selection: action.selection
            }

        }

        case EXPLORE_TOGGLE_COMPETITOR : {

            const exists = state.competitors.find(c => c.uid === action.selection.uid);
            const newList = exists ? state.competitors.filter(c => c.uid !== action.selection.uid) :
                [...state.competitors, action.selection];
            
            return {
                ...state,
                competitors: newList
            }
        }
        
        case EXPLORE_OPTIONS_VISIBLE_SET : {
            return {
                ...state,
                optionsVisible: action.value
            }
        }
    }

    return state;


};
