import moment from 'moment';
import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link,  useHistory, useParams} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as globals from '../../globals';
import * as cardUtil from '../../utils/cardUtil';
import './EditStory.scss';
import { StoryComments } from './StoryComments';
import ReactJson from 'react-json-view';
import { constructAutoSelect } from '../../utils/metaUtils';
import { debounce } from '../../utils/debounce';
import { actionCreators as storyActionCreators } from '../../store/Stories.ts'
import { actionCreators as postsActionCreators } from '../../store/Posts.ts'
import { QuestionCover } from '../widgets/QuestionCover'
import { AddCardWidget } from '../widgets/AddCardWidget';
import { PageHeader2 } from '../common/PageHeader';
import { BreadCrumb } from '../common/BreadCrumb';
import { Avatar } from '../common/Avatar';
import { ReactSortable } from '../common/Sortable';
import { ExecutiveSummary } from '../widgets/ExecutiveSummary';
import { InsightCard } from '../card/InsightCard';
import { DynamicCardElement } from '../explore/DynamicCardElement';
import { text } from 'd3-fetch';
import { PostStoryBox } from '../story/PostStoryBox';
import { PromptBox } from '../common/PromptBox';


export function EditStory(props) {

      const findPost = (postId, state) => {
        if( !postId ) return null;
        if( !state.posts ) return null;
        if( !state.posts.dict ) return null;
        return state.posts.dict[postId];
    }
    const findStory = (storyId, state) => {
        if( !storyId ) return null;
        if( !state.stories ) return null;
        if( !state.stories.dict ) return null;
        return state.stories.dict[storyId];
    }
    
    const state = useSelector(state => state);
    const post = findPost(props?.postId, state);
    const story = post ? findStory(post?.storyId, state) : findStory(props?.storyId, state);
    let storyLabel = story ? story?.label : null;
    const storyCardIds = (story || {}).cards; // so re-render happens when cards change
    const auth = state.auth; // to check if current user is author

    //  console.log('props', props)
    //  console.log('state', state)
    // console.log('story', story)

    const history = useHistory();
    const { studyId } = useParams();
    const { channelId } = useParams();
    const [isMobile, setMobile] = useState(window.innerWidth <= 1015);
    const dispatch = useDispatch();
    

    useEffect(() => {
        checkForData(props, props?.storyId, story);
        window.addEventListener('resize', setMobile(window.innerWidth <= 1015));
        
        return () => {
            window.removeEventListener('resize', setMobile( window.innerWidth <= 1015));
        }
    },[])

        // two ways to consume storypage:
    // 1. there's a post, which has a story and engagement (favs, comments). story is not editable. actually maybe i'll allow it for now
    // 2. there's just a story. story should be editable.
    // let studyId = props.match.params.studyId;
    // const pageChannelId = channelId;
    const pageChannel = channelId; // todo: grab label for channel
    const editMode = !post;
    const study_folder = (state?.study || {}).folder;
    const image = ((story||{}).primarySelection||{}).image;
    const imageUrl = image ? `${globals.cdnUrl}/cdn-shared/${study_folder}/images/${image}` : null;
    const photo = ((story||{}).primarySelection||{}).photo;
    const photoUrl = photo ? `${globals.cdnUrl}/cdn-shared/${study_folder}/images/${photo}` : null;


      
    const checkForData = (props, storyId, story) => {
        
        const postId = props?.postId;
        if( postId ){
            if( !post ){
                postsActionCreators?.loadPost(postId);
            }
        }
        if( !storyId ) return;
        
        if( !story ){
            storyActionCreators?.loadStory(storyId);
            return true;
        }
        
        return false;
    }
    
    const deleteCard = (storyId, cardId) => {
        if( post ) return; // not allowing this on posted stories for now
        storyActionCreators.removeCardsFromStory(storyId, [cardId], () => console.log('card deleted'))(dispatch, () => state);
    }

    const moveCard = (storyId, cardId, dir) => {
        if( post ) return; // not allowing this on posted stories for now
        storyActionCreators.moveStoryCard(storyId, cardId, dir, () => console.log('card moved'))(dispatch, () => state);
    }
    
    const deleteStory = () => {
        if( !story ) return;
        const feedbackBox = <PromptBox prompt={'Deleting...'} />
        window.topBar.openDynamicMenu(feedbackBox);
        // this will delete the post too (if any). At least that's what the api call currently does.
        storyActionCreators.deleteStory(story?.uid, () => { 
            window.topBar.hideMenu();
            // todo: UPDATE CHANNELS!!
            // story has been deleted and stories have been reloaded. navigate back to the feed.
            // let studyId = props.match.params.studyId;
            history.push(`/study/${studyId}/feed`)
        })(dispatch, () => state); 
}
    
    const cloneStory = () => {
        // let studyId = props.match.params.studyId;
        
        if (!story) return;
        const feedbackBox = <PromptBox prompt={'Cloning...'} />
        window.topBar.openDynamicMenu(feedbackBox);
        storyActionCreators.cloneStory(story?.uid, (newId) => {
            window.topBar.hideMenu();
            // navigate to the cloned story
            history.push(`/study/${studyId}/edit-story/${newId}`)
        });
    }

    const postStory = () => {
        if( post ) return; // not allowed for posts
        // let studyId = props.match.params.studyId;
        const box = <PostStoryBox okayClicked={(saveState) =>
        {
            
            window.topBar.hideMenu();
            if( !story ){
                alert('story missing');
                return;
            }
            const channel = saveState?.channel;
            if( !channel ) {
                alert('channel missing');
                return;
            }
            
            const feedbackBox = <PromptBox prompt={'Posting...'} />

            window.topBar.openDynamicMenu(feedbackBox);
            storyActionCreators.postStory(story?.uid, saveState, (newPost) =>
            {
                window.topBar.hideMenu();
                // posting a story should dispatch necessary events to add it to the channel
                // // actually here I should reload the channel
                // console.error('todo: reload the channel');
                // props.loadPost(postId, () => {
                    //postsActionCreators?.loadPosts();
                // });
                // navigate to the selected feed channel (probably don't need to wait for lostPost - see if it looks bad)
                
                history.push(`/study/${studyId}/feed/${channel}`);
                
                
            })(dispatch, () => state)
            
            
        }
    }
        closeRequested={() => window.topBar.hideMenu()} />;
        
        window.topBar.openDynamicMenu(box);

}

    const addTextCardFunc = async (cardId) => {
          
        const myState = {
            headline: 'Type headline here',
            content: 'Type summary here'
        }
        
        const saveInfo = {
            uid: cardId,
            is_dynamic: true,
            dynamic_item: null,
            widget_uid: cardId.toString(),
            state: myState,
            comment: null,
            tags: null
        }

        const newCardId = await cardUtil.saveCard(studyId, auth, null, saveInfo);

        if (newCardId) {
            storyActionCreators.addCardsToStory(story?.uid, [newCardId], () => {
                console.log('Added Text Card')
            })(dispatch, () => state)
        }

        
        // setTextCards(saveState);
    }

    //todo
    const handleStoryLabelChange = (ev) => {
        storyLabel = ev.target.value;
        triggerCallback();
    }
    
    const triggerCallback = debounce(() => {
            
            console.log('saving story label: ', storyLabel);
            storyActionCreators?.patchStory(props?.storyId, { label: storyLabel }, () => {
                console.log(`saved story label as ${storyLabel}`);
            })(dispatch, () => state);
    }, debounce.insightsTextDelay);
    
    
        // add fake story cards
    const fakeCard_Question = editMode || image || photo || story?.question ?
            <QuestionCover key='question-cover'
                image={imageUrl}
                photo={photoUrl}
                selectionLabel={((story||{}).primarySelection||{}).label}
                question={story?.question}
                editMode={editMode}
                onChange={(curr) => {
                    
                    // console.log('saving question:', curr?.question);
                    let saveState = {
                        question: curr?.question,
                    };
                    if( curr?.photo || curr?.image || curr?.label ){
                        saveState.primarySelection = {
                            image: curr?.image,
                            photo: curr?.photo,
                            label: curr?.label
                        }
                    }

                    storyActionCreators?.patchStory(story?.uid, saveState, () => {
                        console.log('saved');
                    })(dispatch, () => state);
                }}
            /> : null;
    
   
            
        const addcard = (editMode) ?
            <AddCardWidget key='add-card'  addTextCard={addTextCardFunc} />
        : null
    
    // console.log('storyCardIds', storyCardIds)
    let dcards = storyCardIds?.map(cardId => {
        
            return <InsightCard
                inFeed={true} // why??
                editMode={editMode}
                showButtons={true}
                inStoryBuilder={true}
                key={cardId}
                studyId={studyId}
                cardId={cardId}
                storyId={story?.uid}
                showHeaderContent = {false}
            />
        })
            
    const allCards = [fakeCard_Question];
    dcards?.forEach(card => {
        allCards.push(card);
    })
    
    allCards.push(addcard);
            
    const storyCardElements = allCards?.map(card => {

            return <div id={card?.key} className={'story-card' + (card === addcard ? ' dashed' : '') + (card === fakeCard_Question ||  card === addcard ? ' filtered' : '')} key={card?.key}>
                {
                    (card === fakeCard_Question ||  card === addcard) ? 
                        <div className='header'>
                        </div>
                        : <div className='header'>
                            {/* {props.editing
                            ? <span className='btn1 handle' title='Move'>
                            <i className='fal fa-arrows'></i>
                        </span> : null} */}
                            {
                                auth.user.isSuperAdmin ?
                                    <button className='json-code' onClick={async () => {
                                    
                                        let __obj = {};
                                        {
                                            // load the card by id    
                                            const saved_card = await cardUtil.loadSavedCard(studyId, auth, card?.props?.cardId);
                                            console.log('saved_card', saved_card);
                                            __obj.state = saved_card?.state;
                                            __obj.widget_uid = saved_card?.widget_uid;
                                            __obj.is_dynamic = saved_card?.is_dynamic;
                                            __obj.dynamic_item = saved_card?.dynamic_item;
                                        }
                                    
                                        const promptBox = <PromptBox
                                            prompt={<ReactJson
                                                name={null}
                                                iconStyle="circle"
                                                theme={"rjv-default"}
                                                displayDataTypes={false}
                                                collapsed={1}
                                                src={{ ...__obj }}
                                                style={{ width: '400px', height: '400px', overflow: 'scroll' }}
                                            
                                            
                                            />}
                                                        buttons={
                                                [{ label: 'OK', onClick: () => window.topBar.hideMenu() }]
                                            }
                                        />
                                        window.topBar.openDynamicMenu(promptBox)
                                    }}>
                                        <i className='fal fa-brackets-curly'></i>
                                    </button> : ''
                            }
                                <span className='btn1 inline-block' title='Move up' onClick={() => {
                                    moveCard(story?.uid, card?.key, -1)
                                }}>
                                    <i className='fal fa-arrow-up' />
                                </span>
                                <span className='btn1 inline-block' title='Move down' onClick={() => {
                                    moveCard(story?.uid, card?.key, 1)
                                }}>
                                    <i className='fal fa-arrow-down' />
                                </span>
                                <span className='btn1 inline-block' title='Delete' onClick={() => {
                                    deleteCard(story?.uid, card?.key)
                                }}>
                                    <i className='fal fa-trash-alt' />
                                </span>
                            {/* <Link className='btn1 inline-block' title='Expand' to={expandUrl}>
                                <i className='fal fa-expand' />
                            </Link> */}
                        </div>
      }
                <div className='cardi-container'>
                    {card}
                </div>
                
            </div>
        })
        //const data = post || {} // props.feed.posts.find(item => item.uid === postId);
        const breadCrumbElements = [];
        if( post ){
            if( pageChannelId ){
                breadCrumbElements.push({ label: pageChannel, link: `/study/${studyId}/feed/${pageChannelId}` });
            }
            else{
                breadCrumbElements.push({ label: 'All Channels', link: `/study/${studyId}/feed` });
            }
            breadCrumbElements.push({ label: 'POSTED STORY' });
        }
        else{
            breadCrumbElements.push({ label: 'MY STORY DRAFTS', link: `/study/${studyId}/feed` });
            breadCrumbElements.push({ label: <>
                    <span className='story-label'>
                        <input type='text' className='story-label-input' placeholder='Enter story name'
                            defaultValue={storyLabel || ''} onChange={(evt) => handleStoryLabelChange(evt)}/>
                    </span>
                </>
            });
    }
    
     //<PageHeader2 title={<BreadCrumb elements={breadCrumbElements}/>}  fullWidth={true}/>
    
        return <div className={"page edit-story-page"}>
        {
            !story ?
            <div className='edit-story-page loading'>loading story<span className='blink_cursor'>_</span></div>
            : null
        }             
                
        
           

            <div className={'content story-content' + (post ? ' post-mode' : ' edit-mode')}>

                {/* <div className='page-header1'>
                    <div className='xh2'>
                        <BreadCrumb elements={breadCrumbElements}/>
                        
                    </div>
                </div> */}

                {post ?
                <div className='combined-header'>
                    <div className='h2'>
                        <span className='tab-buttons'>
                            <span className={'tab-button' + (state?.tab === 'story' ? ' sel' : '')}
                                onClick={(ev) => state.tab = 'story' }
                            >
                                Story
                            </span>
                            <span className={'tab-button' + (state?.tab === 'comments' ? ' sel' : '')}
                                onClick={(ev) => state.tab = 'comments' }
                            >
                                Comments
                            </span>
                        </span>
                    </div>
                </div> : null}

                <div className='author-section'>
                    <Avatar uid={post ? post?.authorId : auth.user?.uid} mini={true}/>
                    <div className='author'>
                        {post ? post?.author : auth?.user.name}
                    </div>
                    <div className='timestamp'>
                        {post ?
                            <>Posted {moment.utc(post?.created).local().fromNow()} in <span className='channel'>{(post?.channel)}</span></>
                            : <>Not Posted (draft)</>
                        }
                    </div>
                    <div className='actions'>
                        {post ? <>
                            <a className='action-button-minor inline-block no-wrap' onClick={cloneStory} title='Clone into story drafts'>
                                <i className='fal fa-code-merge'/>
                                {/* <i className='fal fa-clone'/> */}
                            </a>
                            {(post?.authorId === auth.user.id) ? <a className='action-button-minor inline-block no-wrap' onClick={deleteStory}>Delete story</a> : null}
                            {(post?.authorId !== auth.user.id && auth.user.isSuperAdmin) ? <a className='action-button-minor inline-block no-wrap' onClick={deleteStory}><i className='far fa-user-shield'/> Delete story</a> : null}
                        </> :
                        <>
                            
                            <a className='action-button-minor inline-block no-wrap' onClick={deleteStory}>Discard draft</a>
                            <a className='action-button-minor inline-block no-wrap' onClick={postStory}>
                                <i className='fal fa-paper-plane'/> Post in channel...
                            </a>
                        </>
                        }
                    </div>
                </div>
                 <div className={'story-cards-section' + (state?.tab === 'story' ? ' sel' : ' not-sel')}>
                {
                        !state?.isMobile && props?.editing
                            ? (
                                <ReactSortable
                                    list={storyCardElements}
                                    setList={newState => {
                                        if (newState.move !== 0)
                                            moveCard(story?.uid, newState?.cardId, newState?.move);
                                    }}
                                    tag='div'
                                    className='story-cards-grid'
                                >
                                    {storyCardElements}
                                </ReactSortable>
                            ) : <div className={'story-cards-grid'}>
                                {storyCardElements}
                            </div>
                    }
                    
                </div>
                <div className={'engagement-section' + (state?.tab === 'comments' ? ' sel' : ' not-sel')}>
                    {post ?
                        <StoryComments storyId={story?.uid} postId={post?.uid} className='no-top-border' />
                    : null}
                </div>
            </div>
            </div>
}
    