import * as global from '../globals';
import * as apiService from '../utils/apiService';
import { 
    FEED_POST_COMMENTS,
    FEED_POST_DELETE_COMMENT,
    FEED_POST_EDIT_COMMENT,
    POSTS_POST_CREATED, 
    POSTS_POST_DELETED,
    POSTS_POST_RECEIVE,
    POSTS_POST_REQUEST,
    POSTS_RECEIVE,
    POSTS_REQUEST,
    STUDY_RESET
    // @ts-ignore
} from './action-types';

export interface PostsState{

    dict: {
        [key: string]: Post
    };

    channels: {
        [key: string]: string[]
    }
}

export interface Post{

    /** Unique identifier for Post */
    uid: string;

    /** Timestamp when posted */
    created: Date;

    /** String description of author, e.g. Bo, Fred Jones */
    author: string;

    /** Guid of author */
    authorId: string;

    /** Key (not label) of channel posted into, e.g., red-alerts */
    channel: string;

    /** Exists if post is a story. Guid of story. */
    storyId: string;

}

const initialState: PostsState = {
    dict: {},
    channels: null
};

export const actionCreators = {

    // loads all posts in all channels
    loadPosts: (callback: any) => async (dispatch: any, getState: any) => {
        try {
            dispatch({ type: POSTS_REQUEST });

            const state = getState();
            const studyId = state.study.uid;
            if (!studyId) return;
            
            const response = await apiService.aGet(state.auth, `${global.apiRoot}/study/${studyId}/posts?groupByChannel=true`);
            if( response.ok ){
                const posts = await response.json();
                dispatch({ type: POSTS_RECEIVE, posts });
                if( callback ) callback(posts);
            }
            else{
                console.error(response.statusText);
                dispatch({ type: POSTS_POST_RECEIVE, posts: [], error: response.statusText });
            }

        } catch (error) {
            console.error(error);
            dispatch({ type: POSTS_RECEIVE, posts: [], error });
        }
    },

    loadPost: (postId: string, callback: any) => async (dispatch: any, getState: any) => {
        try {
            dispatch({ type: POSTS_POST_REQUEST, postId });

            const state = getState();
            const studyId = state.study.uid;
            
            const response = await apiService.aGet(state.auth, `${global.apiRoot}/study/${studyId}/post/${postId}`);

            if(response.ok){
                const post = await response.json();
                dispatch({ type: POSTS_POST_RECEIVE, post });
                if(callback) callback(post);
            }
            else{
                console.error(response.statusText);
                dispatch({ type: POSTS_POST_RECEIVE, post:{ uid: postId, error: response.statusText }});
            }
            
        } catch (error) {
            console.error(error);
            dispatch({ type: POSTS_POST_RECEIVE, post: { uid: postId, error }});
        }
    },

    // deletePost not available in API. delete story instead and it will delete corresponding post

    

    addPostComment: (studyId: string, postId: string, reqObj: any, callback: any) => async (dispatch: any, getState: any) => {
        try {

            const state = getState();

            const response = await apiService.aPost(state.auth,
                `${global.apiRoot}/study/${studyId}/comment`,
                reqObj
            );
            const value = await response.json();

            var result = {
                authorName: state.auth.user.name,
                authorUid: state.auth.user.id,
                content: reqObj.comment,
                uid: value.uid,
                created: new Date().getTime(),
                postId: postId,
                //modified: null
            }

            dispatch({ type: FEED_POST_COMMENTS, value: result });

            if (callback) callback();
        } catch (err) {
            // todo: put this error somewhere
            console.error(err);
        }
    },

    deletePostComment: (uid: string, studyId: string, postId: string, callback: any) => async (dispatch: any, getState: any) => {
        try {
            
            const state = getState();

            const response = await apiService.aDelete(state.auth, `${global.apiRoot}/study/${studyId}/comment/${uid}`);

            const data = {
                uid,
                postId
            }

            if (response.ok) {
                dispatch({ type: FEED_POST_DELETE_COMMENT, data });
            }

            if (callback) callback();

        } catch (err) {
            console.log(err)
        }
    },

    editPostComment: (studyId: string, comment: string, uid: string, postId: string, callback: any) => async (dispatch: any, getState: any) => {
        try {

            const state = getState();

            const response = await apiService.aPost(state.auth, `${global.apiRoot}/study/${studyId}/comment/${uid}`, { comment: comment });

            const result = {
                postId: postId,
                uid: uid,
                comment: comment
            };

            if (response.ok) {
                dispatch({ type: FEED_POST_EDIT_COMMENT, value: result });
            }

            if (callback) callback();

        } catch (err) {
            if (callback) callback();
            console.log(err)
        }
    },
    
};

const reduceByKey = (arr: any, key: string, initial = {}) => {
    if( !arr ) return null;
    return arr.reduce((accum: any, el: any) => {
        accum[el[key]] = el;
        return accum;
    }, initial)
}


export const reducer = (state: PostsState, action: any): PostsState => {
    state = state || initialState;

    switch (action.type) {

        case STUDY_RESET:
            return initialState;

        case POSTS_REQUEST:
            return state;

        case POSTS_RECEIVE:
            return {
                ...state,
                channels: Object.keys(action.posts).reduce((prev: any, key: string) => {
                    prev[key] = action.posts[key].map((p:any) => p.uid);
                    return prev;
                }, {}),
                dict: Object.keys(action.posts).reduce((prev: any, key: string) => {
                    action.posts[key].forEach((post: any) => {
                        prev[post.uid] = post
                    });
                    return prev;
                }, {})
            };

        case POSTS_POST_REQUEST:
            return {
                ...state,
                dict: {
                    ...state.dict,
                    [action.postId]: {
                        ...state.dict[action.postId],
                        loading: true
                    }
                }
            };

        case POSTS_POST_RECEIVE:
            return {
                ...state,
                dict: {
                    ...state.dict,
                    [action.post.uid]: action.post
                }
            };

        case POSTS_POST_DELETED: {
            // note: we will remove post from dict and from all channels
            var dict = {...state.dict};
            delete(dict[action.postId]);
            return {
                ...state,
                channels: applyToEachKey(state.channels, (channel: any) => channel.filter((postId: string) => postId != action.postId)),
                dict
            };
        }

        case POSTS_POST_CREATED : {
            // note: we will add to dict and channels??
            let channels: { [key: string]: string[] } = {...state.channels};
            channels[action.post.channel] = [action.post.uid, ...(channels[action.post.channel] || [])];
            channels['*'] = [action.post.uid, ...(channels['*'] || [])];
            return {
                ...state,
                channels,
                dict: {
                    ...state.dict,
                    [action.post.uid]: action.post
                }
            }
        }

        default:
            return state;
    }
};

const applyToEachKey = (obj: any, f: any) => {
    if(!obj) return null;
    return Object.entries(obj).reduce((prev: any, entry: any) => {
        const key = entry[0];
        const val = entry[1];
        prev[key] = f(val);
        return prev;
    }, {});
}