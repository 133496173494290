import { widgetMap } from './_widgetMap';

// note: window.studyScripts is created from a dynamically loaded
// study-specific javascript file custom.js, which is part of study definition.
// see resources.[study] repo, e.g. resources.suvs

let wRepo = [];

const modify_widgets_for_testing = (r, study) => {


    // exclude widgets marked as test (for production)
    r = r.filter(x => !(x.tags || []).includes('test'));

    // remove the "might-like" tag from the wordcloud because I suspect it's the one draining DOM performance of explore page
    {
        const wordcloud = r.find(x => x.uid === 'temp1b')
        if( wordcloud ){
            wordcloud.tags = wordcloud.tags.filter(x => x != 'might-like');
        }
    }


    //r = []; //r = r.filter(x => x.uid === 'attitudes-boxplot');
    // r.push(
    //     {
    //         uid: 'test',
    //         tags: ["experiment"],
    //         widget: "ImageWidget",
    //         props: {
    //             title: 'no title',
    //             sources: ['sources go here'],
    //             path: 'photo/AcuraDX.png'
    //         }
    //     }
    // );

    r.push({
        tags: ['covid-19'],
        uid: 'covid-widget',
        props: {
            title: 'Covid Widget'
        },
        widget: 'CovidWidget'
    })
    r.push({
        tags: ['covid-19'],
        uid: 'covid-widget-1',
        props: {
            title: 'Covid Widget (Line Chart)'
        },
        widget: 'CovidWidget2'
    })
    r.push({
        tags: ['covid-19'],
        uid: 'covid-widget-2',
        props: {
            title: 'Covid Widget (Willingness To Return)'
        },
        widget: 'CovidWidget3'
    })
    r.push({
        tags: ['covid-19'],
        uid: 'toyota-predict-trendline',
        props: {
            product: 'toyota',
            title: 'Toyota Owner w/2nd Order Poly'
        },
        widget: 'PredictTrendline'
    })
    r.push({
        tags: ['covid-19'],
        uid: 'lexus-predict-trendline',
        props: {
            product: 'lexus',
            title: 'Lexus Polynomial Trendline w/Wave 9'
        },
        widget: 'PredictTrendline'
    })
    r.push({
        tags: ['covid-19'],
        uid: 'general-predict-trendline',
        props: {
            product: 'general',
            title: 'Population Polynomial Trendline'
        },
        widget: 'PredictTrendline'
    })

    let nps = r.filter(i => i.uid === 'nps1')?.[0];
    let npsDetail = r.filter(i => i.uid === 'nps-detail')?.[0];
    if( nps ){

        r.push({
            ...nps, 
            uid: 'nps-segments',
            props: {
                ...nps.props,
                title: 'NPS by Segments' 
            },
            widget: 'NPSSegments'
        });
        
    }
    
    if(npsDetail){
        r.push({
            ...npsDetail, 
            uid: 'nps-detail-bar',
            props: {
                ...npsDetail.props,
                title: 'NPS Detail Bar' 
            },
            widget: 'NPSDetailBar'
        });

    }

    // todo: move this into suvs resources
    if( study.folder === 'suvs' ){

        const atts = study?.config?.productDefs.default.atts.filter(a => a.type === 'discrete').map(a => a.name);
        // const atts = ["drivetrain", "rows_seats", "engine_type", "engine", "forward_collision",
        //     "blindspot", "braking_assist", "parking_assist", "liftgate", "moon_roof", "seat_material",
        // ];

        // ["engine_type"].forEach(att => {
        //     r.push(
        //         {
        //             uid: 'natural_demand_basecase2_' + att,
        //             tags: ["natural_demand"],
        //             widget: "CustomWidget1",
        //             props: {
        //                 title: 'Natural Demand Base Case',
        //                 subTitle: (context) => 'Preference Share',
        //                 sources: ['sources go here'],
        //                 var: 'gender',
        //                 useAnalytics: {
        //                     path: (context) => `natural-demand?att=${context.att?.name}&outcome=share&filter_syntax=${context.filterSyntax}`,
        //                     method: 'get' 
        //                 },
        //                 useFilter: true,
        //                 atts: atts,
        //                 att: att,// "engine_type",
        //                 //linear_att: "price",
        //                 format: ".1%",
        //                 valueLabel: "Preference Share",
        //                 transform: (data, context) => {
        //                     return {
        //                         ...context,
        //                         //original: data,
        //                         [context.att?.name]: context?.att?.levels?.map(l =>  ({
        //                             label: l.label,
        //                             value: data?.find(entry => entry.level == l.value)?.level_sum
        //                         }))
        //                     };
        //                 },
        //                 visualization: 'bar'
        //             }
        //         }
        //     );
        // });

        // ["engine_type"].forEach(att => {
        //     r.push(
        //         {
        //             uid: 'natural_demand_base_case_' + att,
        //             tags: ["natural_demand"],
        //             widget: "CustomWidget1",
        //             props: {
        //                 title: 'Natural Demand Base Case',
        //                 //title2: (context) => context?.att?.label,
        //                 //subTitle: (context) => 'Average ' + context?.linear_att?.label + ' by ' + context?.att?.label,
        //                 subTitle: (context) => 'Average ' + context?.linear_att?.label,
        //                 sources: ['sources go here'],
        //                 var: 'gender',
        //                 useAnalytics: {
        //                     path: (context) => `natural-demand-price?att=${context.att?.name}&linear_att=price`,
        //                     method: 'get' 
        //                 },
        //                 useFilter: false,
        //                 atts: atts,
        //                 att: att,// "engine_type",
        //                 linear_att: "price",
        //                 transform: (data, context) => {
        //                     return {
        //                         ...context,
        //                         [context.att?.name]: context?.att?.levels?.map(l =>  ({
        //                             label: l.label,
        //                             value: data?.average_by_level[l.value]
        //                         }))
        //                     };
        //                 },
        //                 visualization: 'bar'
        //             }
        //         }
        //     );
        // });

        // ["engine_type"].forEach(att => {
        //     r.push(
        //         {
        //             uid: 'natural_demand_aee2_' + att,
        //             tags: ["natural_demand"],
        //             widget: "CustomWidget1",
        //             props: {
        //                 title: 'Natural Demand All Else Equal Case',
        //                 //title2: (context) => context?.att?.label,
        //                 //subTitle: (context) => 'Average ' + context?.linear_att?.label + ' by ' + context?.att?.label,
        //                 subTitle: (context) => 'Preference Share',
        //                 sources: ['sources go here'],
        //                 var: 'gender',
        //                 useAnalytics: {
        //                     path: (context) => `natural-demand-all-else-equal?att=${context.att?.name}&filter_syntax=${context.filterSyntax}`,
        //                     method: 'get' 
        //                 },
        //                 useFilter: true,
        //                 atts: atts,
        //                 att: att,// "engine_type",
        //                 //linear_att: "price",
        //                 format: ".1%",
        //                 valueLabel: "Preference Share",
        //                 transform: (data, context) => {
        //                     return {
        //                         ...context,
        //                         [context.att?.name]: context?.att?.levels?.map(l =>  ({
        //                             label: l.label,
        //                             value: data?.summary.find(entry => entry.level == l.value)?.level_average
        //                         }))
        //                     };
        //                 },
        //                 visualization: 'bar'
        //             }
        //         }
        //     );
        // });

        // ["engine_type"].forEach(att => {
        //     r.push(
        //         {
        //             uid: 'natural_demand_aee_case' + att,
        //             tags: ["natural_demand"],
        //             widget: "CustomWidget1",
        //             props: {
        //                 title: 'Natural Demand All-Else-Equal Case',
        //                 //subTitle: (context) => 'Average ' + context?.linear_att?.label + ' by ' + context?.att?.label,
        //                 subTitle: (context) => 'Average ' + context?.linear_att?.label,
        //                 sources: ['sources go here'],
        //                 var: 'gender',
        //                 useAnalytics: {
        //                     path: (context) => `natural-demand-price?att=${context.att?.name}&linear_att=price`,
        //                     method: 'get' 
        //                 },
        //                 useFilter: false,
        //                 atts,
        //                 att: att,// "engine_type",
        //                 linear_att: "price",
        //                 transform: (data, context) => {
        //                     return {
        //                         ...context,
        //                         [context.att?.name]: context?.att?.levels?.map(l =>  ({
        //                             label: l.label,
        //                             value: data?.overall_average //average_by_level[l.value]
        //                         }))
        //                     };
        //                 },
        //                 visualization: 'bar'
        //             }
        //         }
        //     );
        // });


        // r = [];
        // r.push(
        //     {
        //         uid: 'segment-size',
        //         widget: "DemogWidget2",
        //         props: {
        //             title: 'Segment Size',
        //             useYak: {
        //             }
        //         }
        //     }
        // )

        
    }

    return r;
}



export const init = (study, cards) => {
    //console.log('window.studyScripts', window.studyScripts);
    // console.log('cards from api', cards);

    wRepo = [];
    if( window.studyScripts?.initCards ){
        //console.log('found card initializer');
        wRepo = window.studyScripts.initCards(cards, study);
        console.log('initialized cards');
    }
    else if( window.studyScripts?.getRepo ){
        console.log('no card initializer');
        wRepo = cards;// window.studyScripts.getRepo(study);
    }

    // allow tester to add widgets from this file rather than deploying to study def
    // wRepo = modify_widgets_for_testing(wRepo, study);

    
    // attach widgets
    wRepo = wRepo.map(
        entry => {
            const widget = widgetMap[entry.widget];
            if( !widget ) {
                console.log('widget not found:', entry.widget);
                return null;
            }
            return {
                ...entry,
                widget
            }
        }
    ).filter(notNull => notNull); // remove if widget not found

    //console.log('initialized ' + wRepo?.length + ' widgets', wRepo);
}



export const getRepo = (studyId) => {
    return wRepo;
}