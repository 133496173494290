import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './NewStoryBox.scss';


export class NewStoryBox extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            label: ''
        };
    }
    
    render() {

        return <div className={'new-story-box'}>
            <div className='caption'>
                New Story Label:
            </div>
            <div>
                <input type='text' className='story-label-input'
                    value={this.state.label}
                    onChange={(ev) => this.setState({ label: ev.target.value })}
                    placeholder='My story'
                />
            </div>
            <div className='buttons'>
                <span className='action-button inline-block' onClick={() => {
                    this.props.okayClicked(this.state, () => {
                        this.state.label = '';    
                    });
                        //this.state.label = '';
                    //this.props.closeRequested();
                }}>Create</span>
                <span className='action-button inline-block' onClick={() => {
                    this.state.label = '';
                    this.props.closeRequested();
                }}>Cancel</span>
            </div>
        </div>;

    }

}


NewStoryBox = withRouter(connect(
    null,
    null //dispatch => bindActionCreators(actionCreators, dispatch)
)(NewStoryBox))


