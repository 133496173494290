import * as React from 'react';
import { useSelector } from 'react-redux';
import * as selectionHelper from '../../utils/selectionHelper';
import BoxPlot from '../vis/BoxPlot';
import { CoreWidget1 } from './CoreWidget1';


// const temp_q13_attitudes = [
//     { code: "Q13_1", label: "I like SUV’s that are higher off the ground than sedans or smaller SUVs" },
// ]

export const BoxPlotWidget = (props) => {

    const study = useSelector(state => state.study);
    const selections = useSelector(state => state.selections);
    const selection = selectionHelper.getSelection(props, study, selections);
    const stateFilter = useSelector(state => state.filter); // rename filter? to filters? filterselections?
    const filter = props.inDataCard ? stateFilter?.selectedFilter : props.filter;

    const minValue = props.min || 1;
    const maxValue = props.max || 7;

    const entries = props.entries || 'q13_attitudes';

    const statements = window.meta?.[entries];
    if( !statements ) return `meta entries for ${entries} is missing`;

    const entryCode = (entry) => 
    entry.getCode ? entry.getCode(selObj) : entry.code;

    const renderContent = (cache, getJSONData) => {
        if( !cache?.results ) return null;

        const r = cache.results;
        let data = statements.map(s => {

            const scode = entryCode(s);

            const min = r[scode + ':min']?.value;
            const max = r[scode + ':max']?.value;
            return {
                label: s.label,
                min: minValue,
                max: maxValue,
                whiskerMin: min,
                whiskerMax: max,
                value: r[scode]?.value,
            }
        });

        const boxPlotProps = {
            height: data.length * 25,
            labelWidth: props.labelWidth || [200,500],
        };

        const sort = props.sort || "none";
        if( sort === "asc" || sort === "desc" ){
            data = data.sort((a, b) => {
                const aValue = a?.value;
                const bValue = b?.value;
                const mult = sort === "desc" ? -1 : 1;

                if( aValue && bValue ) return (aValue - bValue) * mult;
                if( aValue ) return 1 * mult;
                if( bValue ) return -1 * mult;
                return 0;
            })
        }
        if (getJSONData && typeof getJSONData === 'function')
            getJSONData(data)

        return cache ? <div className='vscroll-content'>
            <BoxPlot data={data} {...boxPlotProps} />
        </div> : null;
    }

    const selObj = selection ? window.meta.dcmToSurveyMap("" + selection?.[study?.explore?.selectionLevel]) : null;
    const sel = selObj?.survey_id;
    if( !sel ) return 'selection missing or unavailable';

    
    let filters = [];
    if( filter ){ filters.push(filter) }
    if( props.ownerFilter ){
        filters.push(`q1_${sel}==7 or q1_${sel}==8`); // todo: change this to a generic var like 'owner' :
    }
    if( props.getFilter ){
        filters.push(props.getFilter(selObj));
    }

    // const filter = props.ownerFilter ? `q1_${sel}==7 or q1_${sel}==8` : // todo: change this to a generic var like 'owner' :
    //     props.getFilter ? props.getFilter(selObj) : 
    //     props.filter;
    // const filters = filter ? [ filter ] : null;

    return <CoreWidget1 
        useSelection={true}
        useFilter={true}
        render={renderContent}
        useYak={{
            query: {
                filters,
                measures: [
                    ...statements.map(entry => ({ id: entryCode(entry), syntax: `mean(${entryCode(entry)})` })),
                    ...statements.map(entry => ({ id: entryCode(entry) + ':min', syntax: `min(${entryCode(entry)})` })),
                    ...statements.map(entry => ({ id: entryCode(entry) + ':max', syntax: `max(${entryCode(entry)})` }))
                ]
                
            }
        }}
        {...props}
    />

}
