import * as React from 'react';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import * as storeCards from '../../store/Cards.ts';
import * as storeScenario from '../../store/Scenario.ts';
import * as storeStudy from '../../store/Study.ts';
import * as storeConfig from '../../store/Config.ts';
import { ThemeContext } from '../../theme';
import { Avatar } from '../common/Avatar';
import { SearchBox } from '../common/SearchBox';
import { CompareDrawer } from '../filter/CompareDrawer';
import { FilterDrawer } from '../filter/FilterDrawer';
import { StoriesSubMenu } from '../leftbar/StoriesSubMenu';
import { ProductDrawer } from '../product-drawer/ProductDrawer';
import { SelectionDrawer } from '../product-drawer/SelectionDrawer';
import { MultiSelectionDrawer } from '../product-drawer/MultiSelectionDrawer';
import { CloneDialog } from '../common/CloneDialog'
import { HelpPane } from './HelpPane';
import { NotificationsPane } from './NotificationsPane';
import * as globals from '../../globals';
import './TopBar.scss';
import { UserMenu } from './UserMenu';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Snackbar } from '../common/Snackbar';
import { ContentLoader } from '../explore/ContentLoader';
import { OptRunDrawer } from '../optimize/OptRunDrawer';
import { Link } from 'react-router-dom';
import { resetIdCounter } from 'vega-lite';




export class TopBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeMenu: null,
            activeMenuOptions: null,
            hideBg: null,
            queue: []
        };

        window.topBar = this;
    }

    openMenu = (whichMenu, options) => {
        let extraState = {};

        if (whichMenu === 'main') {
            // ??
        }
        else if (whichMenu === 'open') {
            if (this.state.activeMenu !== 'open') {
                this.props.loadFiles();
            }
        } else if (whichMenu === 'save') {
            if (this.state.activeMenu !== 'save') {
                this.props.loadFiles();
            }
        }
        else if (whichMenu === 'feed') {
            //extraState.hideBg = true;
        }

        this.setState({
            activeMenu: (this.state.activeMenu === whichMenu) ? null : whichMenu,
            activeMenuOptions: options,
            ...extraState
        })
        
    }

    hideMenu = () => {
        this.productDrawerSelectCallback = null;
        this.openMenu(null);
    }

    openDynamicMenu = (component) => {
        this.setState({
            activeMenu: 'dynamic',
            activeComponent: component
        });
    }

    enqueueSnack = option => {
        if (option.message || option.button) {
            let snack = this.state.queue
            snack.push({
                ...option,
                timer: option.to === 'never'
                    ? null
                    : setTimeout(_ =>
                        this.setState({ queue: this.state.queue.slice(1) }),
                        option?.to ? option.to : 3000
                    )
            })
            this.setState({
                activeMenu: 'snackbar',
                hideBg: true,
                queue: snack,
            })
        }
    }

    dequeueSnack = n => {
        if (n) {
            let item = this.state.queue.filter(i => i.id === n)
            if (item.length) {
                clearTimeout(item.timer)
                this.setState({ queue: this.state.queue.filter(i => i.id !== n) })
            }
        } else {
            let snack = this.state.queue[0]
            clearTimeout(snack.timer)
            this.setState({ queue: this.state.queue.slice(1) })
        }
    }

    updateSnack = (id, option) => {
        let snack = this.state.queue.map(i => 
            i.id === id
                ? {
                    ...i,
                    ...option,
                    timer: i.timer === null
                    ? setTimeout(_ =>
                        this.setState({ queue: this.state.queue.slice(1) }),
                        option.to ? option.to : 10000
                    ) : i.timer
                }
                : i
        )
        this.setState({ queue: snack })
    }

    openSnackBar = (component) => {
        this.setState({
            activeMenu: 'snackbar',
            hideBg: true,
            activeSnackbarComponent: component
        });
    }

    openSelection = (selection, callback, settings) => {
        this.selectionDrawerSelectCallback = callback //<selectionDrawer onSelect is this callback in the component
        let options = {
            selection,
            settings
        }

        this.openMenu('selection', options)
    }

    openCloneDialog = (selection, callback, settings) => {
        this.cloneCallback = callback
        let options = {
            selection,
            settings
        }

        this.openMenu('clone', options)
    }

    openMultiSelection = (selection, callback, settings) => {
        this.selectionDrawerSelectCallback = callback;
        let options = {
            selection,
            settings
        };
        this.openMenu('multiselection', options);
    }

    openProducts = (selection, callback, settings) => {
        this.productDrawerSelectCallback = callback;
         
        //cant setState b/c ProductDrawer hasn't been initialized        
        let options = {
            sel: selection,
            allow: null,
            searchText: '',
            multiSelect: false,
            ...settings
        }
        this.openMenu('products', options);
    }

    openOptRuns = () => {
        this.openMenu('optruns');
    }

    openProductsMulti = (selections, callback, settings, primarySelection, primarySelectionCallback) => {
        this.productDrawerSelectCallback = callback;
        this.productDrawerPrimarySelectCallback = primarySelectionCallback;
        //window.productDrawer.setState({ sels: selections, sel: primarySelection });
        window.productDrawer.setState({
            sels: selections,
            sel: primarySelection,
            allow: null,
            multiSelect: true,
            ...settings
        }, () => {
            this.openMenu('products');        
        });
        
    }

    productDrawerSelectCallback;
    productDrawerPrimarySelectCallback;

    handleProductDrawerSelect = (selection) => {
        if( this.productDrawerSelectCallback ){
            this.productDrawerSelectCallback(selection);
        }
    }

    handleSelectionDrawerSelect = (selection) => {
        if( this.selectionDrawerSelectCallback ){
            this.selectionDrawerSelectCallback(selection);
        }
    }

    handleMultiSelectionDrawerSelect = (selection) => {
        //console.log('todo');
        // if( this.selectionDrawerSelectCallback ){
        //     this.selectionDrawerSelectCallback(selection);
        // }
    }

    handleProductDrawerPrimarySelect = (selection) => {
        if( this.productDrawerPrimarySelectCallback ){
            this.productDrawerPrimarySelectCallback(selection);
        }
    }

    handleBtnClone = () => {
        if( this.cloneCallback ){
            this.cloneCallback() //shuts window
        }
    }

   

    // openFilterDrawer = (selection, callback, settings) => {
    //     this.filterDrawerSelectCallback = callback;
    //     window.filterDrawer.setState({ sel: selection });
    //     window.filterDrawer.setState({
    //         ...settings
    //     });
    //     this.openMenu('filter');
    // }

    filterDrawerSelectCallback;

    handleFilterDrawerSelect = (selection) => {
        if( this.filterDrawerSelectCallback ){
            this.filterDrawerSelectCallback(selection);
        }
    }

    

    getSelRoot() {
        const match = matchPath(this.props.history.location.pathname, {
            path: '/:a/:b/:c',
            strict: false
        });
        let selRoot = match != null ? match.params.c : null;

        return selRoot;
    }

    getSection() {
        // get study from url
        let match = matchPath(this.props.history.location.pathname, {
            path: '/:a/:b/:c',
            strict: false
        });

        if (match == null || match.params == null) {
            match = matchPath(this.props.history.location.pathname, {
                path: '/:a/:b',
                strict: false
            });
        }

        if (match == null || match.params == null) {
            return '?';
        }

        let x = match.params.c;
        if (x === 'edit' || x === 'edit-split') return 'edit';
        if (x === 'feed' || x === '' || x == null) return 'feed';
        if (x === 'out') return 'out';

        return null;
    }

   
    render() {
        let study = this.props.study || {};
        let studyId = study.uid;
        let user = this.props.user || {};
        let section = this.getSection();
        

        let selPage = this.getSelRoot();

        let has_scenario = section === 'edit';
        //let theme = this.context;

        let darkCover = true
            || this.state.activeMenu === "open"
            || this.state.activeMenu === "save"
            || this.state.activeMenu === "post"
            || this.state.activeMenu === "main";
        
        let widthConditionalCover = this.state.activeMenu === "channels";
        const folder = study?.folder;
        const studyImage = study?.studySpecificItems?.mainLogo;

        console.log('activMenuOptions!!!', this.state.activeMenuOptions);
        const renderMenu = ((menuName) => {
            switch (menuName) {
                case 'notifications':
                    return <RightMenu isOpen={this.state.activeMenu === 'notifications'}>
                                <NotificationsPane closeRequested={this.hideMenu} study={study} />
                            </RightMenu>
                    break;
                // case 'help':
                //     return <RightMenu isOpen={true}>
                //             <HelpPane study={study} closeRequested={this.hideMenu} section={section} />
                //         </RightMenu>
                    break;
                case 'user':
                    return <RightMenu isOpen={true}>
                                <UserMenu study={study} closeRequested={this.hideMenu} />
                            </RightMenu>
                    break;
                case 'products':
                    return <ProductDrawer state={this.activeMenuOptions} isOpen={true} onSelect={this.handleProductDrawerSelect} onPrimarySelect={this.handleProductDrawerPrimarySelect} studyId={studyId} />
                    break;
                
                case 'selection':
                    return <SelectionDrawer isOpen={true} {...(this.state.activeMenu === 'selection' ? this.state.activeMenuOptions : null)} onSelect={this.handleSelectionDrawerSelect} studyId={studyId} />
                    break;              
                
                case 'multiselection':
                    return <MultiSelectionDrawer isOpen={true} {...(this.state.activeMenu === 'multiselection' ? this.state.activeMenuOptions : null)} onSelect={this.handleMultiSelectionDrawerSelect} studyId={studyId} />
                    break;
                
                case 'clone':
                    return <CloneDialog isOpen={true} {...(this.state.activeMenu === 'clone' ? this.state.activeMenuOptions : null)} onBtnClick={this.handleBtnClone} />
                    break;
                // case 'main':
                //     return <div className={'main-menu' }>
                //                 <ContentLoader id='main-menu' key='mmkey' asPage={false} pageLevel={0} edit={{}} restore={null, null} allowMainMenu={true} />
                //             </div>
                //     break;
                case 'optruns':
                    return <OptRunDrawer isOpen={true} studyId={studyId} />
                    break;
                
                case 'filter':
                     return <LeftMenu mediumWide={true} isOpen={true} flavor="filter">
                            <FilterDrawer
                                studyId={studyId}
                                rootUrl={``}
                                isOpen={true}
                                {...(this.state.activeMenu === 'filter' ? this.state.activeMenuOptions : null)}
                                //itemClicked={() => this.hideMenu()}
                                closeRequested={this.hideMenu}
                            />
                        </LeftMenu>
                    break;
                
                case 'compare':
                    return <RightMenu mediumWide={true} isOpen={true} flavor="compare">
                            <CompareDrawer
                                studyId={studyId}
                                rootUrl={``}
                                isOpen={true}
                                activeMenu
                                //itemClicked={() => this.hideMenu()}
                                closeRequested={this.hideMenu}
                            />
                        </RightMenu>
                    break;
                
                case 'channels':
                    return <NavSubMenu isOpen={true}>
                                <StoriesSubMenu studyId={studyId} closeRequested={this.hideMenu} />
                            </NavSubMenu>
                    break;
                
                case 'dynamic':
                    return <PopupWindow flavor="dynamic-box" isOpen={true}>
                                {this.state.activeComponent}
                            </PopupWindow>
                    break;
                default: null
                
            }

        })

        function FirstChild(props) {
            const childrenArray = React.Children.toArray(props.children);
            return childrenArray[0] || null;
        }

        
        return (
            <React.Fragment>
                <div
                    className={
                        'top-bar theme-' + ('default') + (has_scenario ? ' has-scenario' : ' no-scenario')
                    }
                >
                    {studyImage ?
                        <a className="icon" href="/">
                            <img src={`${globals.cdnUrl}/cdn-shared/${studyImage}`} alt="Blue Owl logo" />
                        </a>
                        : null
                    }

                    <div id='menu_center' className='menu-area'>
                        {(this.props.study && this.props.study?.uid != 'video-games-amazon') ?
                            <>
                                <center>
                                <Link className="icon" to={`/study/${studyId}/explore`} role='link' aria-label='home'>
                                    <span className="btn" style={{ marginRight: 1, fontSize: 14 }}>
                                        {
                                           <i className='fal fa-home' />
                                        }
                                    </span>
                                </Link>
                                {/* <Link className="icon" to={`/study/${studyId}/feed`} role='link' aria-label='feed'>
                                    <span className="btn" style={{ marginRight: 1, fontSize: 14 }}>
                                        {
                                            <i className='fal fa-books' />
                                        }
                                    </span>
                                </Link> */}
                                    <span className='btn '
                                        role='button'
                                        aria-label='main menu'
                                        style={{ letterSpacing: 3, fontSize: '10pt', fontWeight: 400 }}
                                        title={studyId === 'videogames-embed' ? "Disabled without a subscription." : null}
                                        onClick={() => studyId !== 'videogames-embed' ? this.openMenu('main') : undefined}  >
                                        <span>MENU {this.state.activeMenu === 'main' ? <i className="fal fa-chevron-up" /> : <i className="fal fa-chevron-down" />}</span>
                                </span>
                                </center>
                                 {/* {
                                    this.props.study && user?.isSuperAdmin ? 
                                    <div className='search-area'>
                                        <SearchBox />
                                    </div> : null
                                  } */}
                            </>
                            : null
                        }
                    </div>

                    <div className="tagline-area">
                        {/* earth's smartest marketing recommendations */}
                        {/* ACCURATE, REAL-TIME PREDICTIVE INSIGHTS */}
                        {/* Accurate, real-time predictive insights */}
                    </div>
                        
                    <div className='study-title'>
                        {study.subscriptionLabel}
                    </div>

                       
                    

                    {/* <button
                        className={`btn notification-button ${
                            this.props.notifs_unread_errors
                                ? ' has-unread-errors'
                                : this.props.notifs_unread
                                    ? ' has-unread-noerrors'
                                    : ''
                            }`}
                        onClick={() => this.openMenu('optruns')}
                        title="Notifications"
                    >
                        <i className="fal fa-bell" />
                        {this.props.notifs_unread > 0 ? (
                            <React.Fragment>
                                <span className="bubble">
                                    <i className="fa fa-circle" />
                                </span>
                                <span className="notif">
                                    {this.props.notifs_unread > 9
                                        ? '...'
                                        : this.props.notifs_unread}
                                </span>
                                <span className="progress-bar">
                                    <span className="thumb" />
                                </span>
                            </React.Fragment>
                        ) : null}
                    </button> */}

                    
                    {/* <button className="help-button btn" role='button' aria-label='help documents' onClick={() => this.openMenu('help')} title="Help">
                        <i className="fal fa-question-circle" />
                    </button> */}
                    <div className="user-button btn" onClick={() => this.openMenu('user')} >
                        <Avatar mini={true} uid={this.props.user.uid} />
                        {/* <img className='tiny-avatar' src={`${global.appRoot}/avatar/${user.id}`} alt='User Avatar' /> */}
                    </div>
                    
                </div>

                <div className='maincontent' id='modal-root'>

                    {/* covers the rest of the page when the menu is open. transparent. onclick hides menu. */}
                    <div
                        className={
                            'menu-cover' 
                            + (this.state.activeMenu != null && !this.state.hideBg ? ' show' : '')
                            + (darkCover ? ' dark' : '')
                            + (widthConditionalCover ? ' show-only-if-wide' : '')
                        }
                        onClick={() => this.hideMenu()}
                    />
                  
                   { renderMenu(this.state.activeMenu) }

                    {/* this relies on being rendered and then visible or hidden */}
                     <div className={'main-menu ' + (this.state.activeMenu === 'main' ? 'open' : 'closed')}>
                        <ContentLoader id='main-menu' key='mmkey' asPage={false} pageLevel={0} edit={{}} restore={null, null} allowMainMenu={true} />
                    </div>
                    


                    {/* <PopupWindow isOpen={this.state.activeMenu === 'run_optimization'}>
                        <RunOptimizer closeRequested={this.hideMenu}/>
                    </PopupWindow> */}

                    {/* <PopupWindow flavor="widget-viewer" isOpen={this.state.activeMenu === 'widget'} noScroll={true}>
                        <WidgetViewer studyId={studyId}/>
                    </PopupWindow> */}
                    
                    
                    <div className='snackbar-container'>
                        <TransitionGroup>         
                            {this.state.queue.map((snack,idx) =>
                            <CSSTransition
                                key={idx}
                                timeout={{enter: 500, exit: 500}}
                                >
                                <Snackbar
                                    key={snack.id}
                                    id={snack.id}
                                    message={snack.message}
                                    button={snack.button}
                                    success={snack.success}
                                />
                            </CSSTransition>
                        )}
                        </TransitionGroup>
                    </div>

                    

                </div>
                

            </React.Fragment>
        );
    }
}
TopBar.contextType = ThemeContext;

TopBar = withRouter(
    connect(
        state => ({
            user: state.auth ? state.auth.user : null,
            notifs_unread: state.notify.notifications.filter(n => !n.read).length,
            notifs_unread_errors: state.notify.notifications.find(n => n.error && !n.read) ? 1 : 0
        }),
        dispatch => bindActionCreators({
            ...storeScenario.actionCreators,
            ...storeStudy.actionCreators,
            ...storeCards.actionCreators,
            ...storeConfig.actionCreators
        }, dispatch)
    )(TopBar)
);

class NavSubMenu extends React.Component {
    render(){
        return (
            <div
                tabIndex={-1}
                className={
                    'nav-sub-menu ' +
                    (this.props.isOpen ? 'open' : 'closed')
                }
            >
                {this.props.children}
            </div>
        )
    }
}

class LeftMenu extends React.Component {
    render() {
        return (
            <div
                tabIndex={-1}
                className={
                    'left-menu ' +
                    (this.props.wide ? ' wide ' : '') +
                    (this.props.mediumWide ? ' medium-wide ' : '') +
                    (this.props.superWide ? ' super-wide ' : '') +
                    (this.props.isOpen ? 'open' : 'closed')
                }
            >
                {this.props.children}
            </div>
        );
    }
}

class RightMenu extends React.Component {
    render() {
        return (
            <div
                tabIndex={-1}
                className={
                    'right-menu ' +
                    (this.props.wide ? ' wide ' : '') +
                    (this.props.superWide ? ' super-wide ' : '') +
                    (this.props.isOpen ? 'open' : 'closed')
                }
            >
                {this.props.children}
            </div>
        );
    }
}


class PopupWindow extends React.Component {
    render() {
        return (
            <div className={'popup-window-container '
                + (this.props.flavor ? ` flavor-${this.props.flavor} ` : ' fit')
                + (this.props.isOpen ? ' open ' : ' closed ')
                
                }
                
                
                >
                <div
                    tabIndex={-1}
                    className={'popup-window ' +
                        (this.props.flavor ? ` flavor-${this.props.flavor} ` : ' fit ') +
                        (this.props.wide ? ' wide ' : '') +
                        (this.props.superWide ? ' super-wide ' : '') +
                        (this.props.isOpen ? ' open ' : ' closed ') +
                        (this.props.noScroll ? ' no-scroll' : '')
                    }
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}
