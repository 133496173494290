import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import ReactJson from "react-json-view";
import { useDispatch, useSelector } from "react-redux";
import * as globals from '../../globals';
import { OPTIM_UPDATE_RUNITEM } from '../../store/action-types.ts';
import * as apiService from '../../utils/apiService';
import './OptRunDrawer.scss';
import {
	Bar,
	BarChart,
	LabelList,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";




const getTimestampCell = (run) => {
    //return <div className='timestamp'>{getTimestampMessage(run)}</div>
    if (run.completed) {
        return <div className='timestamp completed'>
            {"Completed " + moment.utc(run.completed).local().fromNow()}
        </div>
    }
    if (run.started) {
        const started = moment.utc(run.started).local();
        const seconds = moment().diff(started, 'seconds');
        const dur = moment.duration(seconds, 'seconds').humanize();
        return <div className='timestamp running'>
            {"Running for " + dur}
        </div>
    }
    {
        const created = moment.utc(run.created).local();
        const seconds = moment().diff(created, 'seconds');
        const dur = moment.duration(seconds, 'seconds').humanize();
        return <div className='timestamp waiting'>
            {"Waiting for " + dur}
        </div>
    }
}



const loadOptimizerScenario = async (auth, studyId, runId, scenarioIndex, dispatch, callback) => {

    // get config
    try {
        const response = await apiService.aGet(auth, `${globals.apiRoot}/optimize/scenario/${studyId}/${runId}/${scenarioIndex}`);

        const data = await response.json();
        console.log('received optimizer scenario from api', data);

        if (response.ok) {
            dispatch({ type: `SCENARIO_ACTION:LOAD_SCENARIO_RESULT_SINGLECONFIG`, value: data });
            if (callback) callback(true, data);
        } else {
            console.error(response);
            if (callback) callback(false, 'Server error');
        }
    } catch (err) {
        if (callback) callback(false, 'Server error');
    }

 
};


export const OptRunItem = (props) => {

    const runId = props.runId;

    const run = useSelector(state => state.optim?.optRunDict?.[runId]);

    // const [searchText, setSearchText] = useState('');
    // const [items, setItems] = useState(null);

    const [loading, setLoading] = useState(false);
    const [refreshCounter, setRefreshCounter] = useState(0);
    const studyId = useSelector(state => state.study?.uid);
    // const studyFolder = useSelector(state => state.study?.folder);
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const history = useHistory();
    //const [runDetail, setRunDetail] = useState(null);


    const [selectedHistoryEntry, setSelectedHistoryEntry] = useState(null);

    const [expanded, setExpanded] = useState(false);

    // load the optrun detail
    useEffect(() => {
        if (!expanded) return;
        //console.log('load optrun detail');
        const f = async () => {

            //setLoading(true);
            
            try {
                const response = await apiService.aGet(auth, `${globals.apiRoot}/optimize/run/${run.id}`);
                if (response.ok) {
                    const d = await response.json();
                    //setRunDetail(d);

                    dispatch({ type: OPTIM_UPDATE_RUNITEM, id: runId, data: d });

                    // {"input":{"goal":{"targetMeasure":"share","targetFilter":"1==1","targetFilterLabel":"Total Population","targetProducts":["prod3"]}},"output":{"scenarios":[]}}
                }
            }
            catch (err) {
                alert(err);
                console.error(err);
            }

            setLoading(false);
            
        }
        f();

    }, [expanded, refreshCounter, run?.message])


    const handleDeleteClick = async (item) => {

        try {
            const response = await apiService.aDelete(auth, `${globals.apiRoot}/optimize/run/${run.id}`);
            if (response.ok) {
                //alert('todo: snack to say it was deleted');
                
                topBar.enqueueSnack({
                    id: `deleted_optrun_${run.id}`,
                    message: 'Deleted Optimization Run',
                    to: 3000,
                    success: true
                })
                // todo: refresh parent
            }
        }
        catch (err) {
            alert(err);
            console.error(err);
        }
            
    }

    const input = run?.input;
    const goal = input?.goal;

    const running = run.state == "running";

    const metric = globals.metrics.find(m => m.key == goal?.targetMeasure);
    const nbScenarios = run?.output?.scenarios?.length;

    let optSetup = {}
    goal?.targetProducts?.forEach(tp => {
        let tpObj = { ...tp };
        optSetup[tp.label] = tpObj;
    })
    optSetup = {
        goal: input?.goal,
        optimizerSettings: input?.all?.optimizerSettings
        //input
    };
    
    return (
        <div className='optrun' key={run.id + "|" + refreshCounter}>
            <div className='expander' onClick={() => {
                setExpanded(!expanded);
            }}>
                <i className={expanded ? 'fal fa-minus-circle' : 'fal fa-plus-circle'} />
                {run.label || 'missing label'}
            </div>
            <div className='state'>{run.state}</div>
            <div className='label'>{run.label || 'missing label'}</div>
            {getTimestampCell(run)}
            <div className='actions' title='Delete Run' onClick={handleDeleteClick}>
                <i className='fal fa-trash-alt' />
            </div>
            {expanded ? <div className='run-detail'>
                {loading ? "loading..." : <>
                    
                    <div className='goal'>
                        <div className="target-measure goal-item">
                            <span className="label">Target measure</span>
                            <span className="value">{metric?.label || goal?.targetMeasure}</span>
                            <span className='btn inline-block' onClick={() => setRefreshCounter(refreshCounter + 1)}>
                                <i className='fal fa-sync'/>
                            </span>
                        </div>
                        <div className="target-products goal-item">
                            <span className="label">Target Product(s)</span>
                            <span className="value">
                                {goal?.targetProducts?.map(p => <div key={p.uid} className='entry'>
                                    {p.label}
                                </div>)}
                            </span>
                        </div>
                        <div className="target-filter goal-item">
                            <span className="label">Target filter</span>
                            <span className="value">{goal?.targetFilterLabel}</span>
                        </div>

                        <div className="input goal-item dash">
                            <span className="label">Optimizer Setup Detail (hide me)</span>
                            <span className="value" style={{ textAlign: "left", border: '0px solid #cfcfcf', fontSize: 10 }}>
                                <ReactJson
                                    src={optSetup || {}}
                                    theme={"rjv-default"}
                                    iconStyle="circle"
                                    displayDataTypes={false}
                                    name={null}
                                    collapsed={0}
                                    enableClipboard={true}
                                />
                            </span>
                        </div>

                        <div className="actions">
                            <div className='status-section'>
                                
                                {run.message ?
                                    <div className='status-message'>
                                        {/* <hr/> */}
                                        {run.message}
                                    </div>
                                    : null}
                                {run.hasOutput ? <div className='best-result-section'>
                                    <span className="label">Best Result{running ? " so far" : ""}:</span>
                                    <span className="value">{metric?.fformat(run?.output?.bestResult)}</span>
                                    <div style={{height: 10}}/>
                                    <span className="label">History:</span>
                                    {false && run?.output?.bestResultHistory?.map((d, i) => {
                                        const prevRow = i > 0 ? run?.output?.bestResultHistory[i - 1] : null;
                                        const diffValue = prevRow ? d?.value - prevRow?.value : null;
                                        return <span key={i} className={'history-block' + (d == selectedHistoryEntry ? ' sel' : '')} onClick={() => setSelectedHistoryEntry(d == selectedHistoryEntry ? null : d)}>
                                            {metric?.fformat(d?.value)}
                                            {diffValue !== null ? <span style={{ marginLeft: 5 }}>
                                                {`(+${metric?.fformat(diffValue)})`}
                                            </span> : null}
                                        </span>
                                    })}
                                    {selectedHistoryEntry ? <div className='selected-history-entry'>
                                        {JSON.stringify(selectedHistoryEntry.config)}
                                    </div> : null}
                                    <div style={{ overflowX: "auto", overflowY: "hidden" }}>
                                        <center>
                                            {/* <ResponsiveContainer height={120}> */}
                                            
                                                <BarChart width={50 + run?.output?.bestResultHistory?.length * 20} height={120} margin={{ top: 20 }} data={run?.output?.bestResultHistory}>
                                                    <Tooltip formatter={metric?.fformat}/>
                                                    <Bar dataKey="value" fill="#202020" >
                                                        {/* <LabelList dataKey="value" position="top"
                                                                formatter={metric?.fformat} /> */}
                                                    </Bar>
                                                </BarChart>
                                            
                                            {/* </ResponsiveContainer> */}
                                        </center>
                                    </div>
                                </div> : null}
                            
                            </div>
                            <span className={"btn inline-block border right-space" + (run.hasOutput ? "" : " disabled")} disabled={true}
                                onClick={() => {
                                    loadOptimizerScenario(auth, studyId, run.id, 0, dispatch, () => {
                                        // callback
                                        // navigate to what-if page
                                        
                                    })
                                    window.topBar.hideMenu();
                                    history.push(`/study/${studyId}/what-if`);
                                    
                                }}
                            >
                                Load best Scenario
                            </span>
                            <span className={"btn inline-block border right-space" + (false && run.hasOutput ? "" : " disabled")}>Analyze top {nbScenarios} Scenarios</span>
                            <span className={"btn inline-block border right-space " + (false ? "" : " disabled")}>Load optimizer setup</span>
                            <span className={"btn inline-block border right-space" + (false && run.hasOutput ? "" : " disabled")}>Save run</span>
                        </div>
                    </div>
                        
                </>}
            </div> : null}
        </div>
    );
}

