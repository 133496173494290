import * as React from 'react';
import { format as d3Format } from 'd3-format';
import { Label, LabelList, LineChart, Line, CartesianGrid, Text, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CustomizedTick from "../common/CustomizedTick";
import CustomLegend from "../common/CustomLegend";

const DLineChart = ({ compute, renderConfig }) => {

  let xAxisProps = renderConfig?.xAxis?.props || {};
  let yAxisProps = renderConfig?.yAxis?.props || {};

  if (renderConfig?.xAxis?.tickFormatter) {
    xAxisProps = { ...xAxisProps, tickFormatter: d3Format('.0%') }
  }

  if (renderConfig?.yAxis?.tickFormatter) {
    yAxisProps = { ...yAxisProps, tickFormatter: d3Format(renderConfig?.yAxis?.tickFormatter || "") }
  }

  const tooltipProps = renderConfig?.tooltip?.props || {};
  const tooltipFormatter = d3Format(renderConfig?.tooltip?.formatter || "");
  return <ResponsiveContainer {...renderConfig?.container}>
    <LineChart data={compute} {...renderConfig?.chartConfig}>
      <XAxis {...xAxisProps} />
      <YAxis {...yAxisProps} tick={<CustomizedTick tickFormatter={d3Format(renderConfig?.yAxis?.tickFormatter || "")} />} />
      <Tooltip formatter={tooltipFormatter} {...tooltipProps} />
      {renderConfig?.cartesian && (
        <CartesianGrid strokeDasharray="3 3" {...renderConfig?.cartesian?.props}/>
      )}
      {renderConfig?.legend && (
        <Legend {...renderConfig?.legend} content={<CustomLegend />} />
      )}
      {renderConfig?.series?.map((config, idx) => (
        <Line dataKey={config?.dataKey} key={idx} {...config?.props} />
      ))}
    </LineChart>
  </ResponsiveContainer>
}

export default DLineChart