import * as React from 'react';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as globals from '../../globals';
import * as apiService from '../../utils/apiService';
import { Avatar } from '../common/Avatar';
import './PostStoryBox.scss';


const alphaSort = (a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0;


export const PostStoryBox = (props) => {

    const [channel, setChannel] = useState(null);
    const [channels, setChannels] = useState(null);
    const [studyUsers, setStudyUsers] = useState(null);    
    const [channelUsers, setChannelUsers] = useState(null);
    const [showReaders, setShowReaders] = useState(false);
    const [showSuperAdmin, setShowSuperAdmin] = useState(false);
    const [impersonatedUser, setImpersonatedUser] = useState(null);
    const [timestampOverwrite, setTimestampOverwrite] = useState(null);

    const auth = useSelector(state => state.auth);
    const study_uid = useSelector(state => state.study?.uid);
    //const channels = useSelector(state => state.channels);

    const selUser = showSuperAdmin ? impersonatedUser : null || auth.user.id;

    // get study users
    useEffect(() => {
        if( !study_uid ) return;
        const f = async () => {
            //console.log('get users of study ' + study_uid);
            const response = await apiService.aGet(auth, `${globals.apiRoot}/study/${study_uid}/users`);
            if( response.ok ){
                setStudyUsers(await response.json());
            }
            else{
                console.error(response);
                //setStudyUsers([]);
            }
        }
        f();
    }, [study_uid]);

    // get channels the selected user can access
    useEffect(() => {
        if( !selUser ) return;
        const f = async () => {
            //console.log('get channels of selected user ' + selUser);
            const response = await apiService.aGet(auth, `${globals.apiRoot}/study/${study_uid}/channels?user=${selUser}`);
            if( response.ok ){
                setChannels(await response.json());
            }
            else{
                console.error(response);
                //setChannels([]);
            }
        }
        f();
    }, [selUser]);

    // get users of the selected channel
    useEffect(() => {
        if( !channel ) return;
        const f = async () => {
            //console.log('get users of channel ' + channel);
            const response = await apiService.aGet(auth, `${globals.apiRoot}/study/${study_uid}/channel/${channel}/users`);
            if( response.ok ){
                setChannelUsers(await response.json());
            }
            else{
                console.error(response);
                //setChannelUsers([]);
            }
        }
        f();
    }, [channel]);


    // filter channels down to only those that user can write to
    const writeChannels = channels?.filter(ch => ch.canWrite);
    // const defaultChannel = writeChannels?.length > 0 ? writeChannels[0] : null;
    // const selChannel = writeChannels?.find(ch => ch.key === channel) || defaultChannel;


    return <div className={'post-story-box'}>

        {showSuperAdmin ?
            <div className='super-admin-section expanded-box'>
                <div className='impersonate-user'>
                    <span className='label'>
                        Impersonate user:
                    </span>
                    {studyUsers ?
                        <select name='impersonated-user' 
                            value={impersonatedUser || ''}
                            onChange={(ev) => setImpersonatedUser(ev.target.value)}
                        >
                            <option value="">[select user]</option>
                            {studyUsers.sort(alphaSort).map(u => <option 
                                key={u.uid}
                                value={u.uid}
                                className='user-option'>
                                {u.name}
                            </option>)}
                        </select>
                        : 'loading...'
                    }
                </div>
                
                <div className='adjust-timestamp'>
                    <span className='label'>
                        Overwrite timestamp:
                    </span>
                    <input type="datetime-local" name='timestamp-set' className='input-timestamp'
                        value={timestampOverwrite || ''}
                        onChange={(ev) => setTimestampOverwrite(ev.target.value)}
                    />
                </div>
            </div>
        : null}

        <div className='caption'>
            Select a channel:
        </div>
        <div>
            {channels ?
                <select className='channel-select' value={channel || ''} onChange={(ev) => setChannel(ev.target.value)}>
                    <option key='' value=''>[select]</option>
                    {writeChannels.map(channel => 
                        <option key={channel.key} value={channel.key}>{channel.label}</option>
                    )}
                </select>
                : 'loading...'
            }
        </div>
        
        <div className='channel-users'>
            {showReaders ? 
                <span className='show-hide-btn' onClick={() => setShowReaders(false)}>
                    Hide channel readers
                    <i className='fal fa-chevron-up'/>
                </span>
                : <span className='show-hide-btn' onClick={() => setShowReaders(true)}>
                    Show channel readers
                    <i className='fal fa-chevron-down'/>
                </span>
            }
        </div>

        {showReaders ?
            <div className='channel-users list expanded-box'>
                {(channelUsers || []).sort(alphaSort).map(u => <span key={u.uid} className='channel-user'>
                    <Avatar mini={true} uid={u.uid} /> 
                    <span className='name'>{u.name}</span>
                </span>)}
            </div>
        : null}
            
        <div className='buttons'>

            {auth.user.isSuperAdmin ?
                <span className={'action-button inline-block' + (showSuperAdmin ? '' : ' white')} 
                    title="Super-admin features"
                    onClick={() => setShowSuperAdmin(!showSuperAdmin)}>
                    <i className='far fa-user-shield'/>
                </span> 
                : null
            }

            <span className='action-button inline-block' onClick={() => {
                let saveState = {
                    channel: channel || defaultChannel.key
                }
                if( showSuperAdmin ){
                    if( timestampOverwrite && timestampOverwrite != "" ){
                        saveState.timestampOverwrite = new Date(timestampOverwrite).toUTCString()
                    }
                    if( impersonatedUser && impersonatedUser != "" )
                        saveState.impersonatedUser = impersonatedUser;
                }
                props.okayClicked(saveState);
            }}><i className='fal fa-paper-plane'/> Post story</span>
            <span className='action-button inline-block' onClick={() => {
                props.closeRequested();
            }}>cancel</span>
        </div>
    </div>;

}





