import * as globals from '../globals';
import * as apiService from '../utils/apiService';
import { guid } from '../utils/guid';
// @ts-ignore
import { NOTIFS_CLEAR, NOTIF_ADD, NOTIF_ADD_MULTI, NOTIF_CLEAR, NOTIF_MARK_COMPLETE, NOTIF_MARK_READ, NOTIF_RECEIVED_RUN_STATUS, STUDY_RESET } from './action-types';

export interface NotifyState{

    notifications: Notification[];

}

export interface Notification{
    
    /** e.g., optrun */
    type: string;

    /** unique identifier for notification */
    id: string;

    /** whether or not user has read the notification */
    read: boolean;

    /** if long running tasks is running */
    running: boolean;

    /** error if exists */
    error: any;

    /** e.g., completed,  */
    state?: string;

    /** if type is optrun, contains the id of optrun */
    runId: string;

    /** e.g., Optimization is running (8bf35c17-bea0-4098-943f-0f6cd72111e3) */
    message: string;

    /** timestamp of latest status update? */
    timestamp: Date;

    /** optimizer may have results even if not completed */
    hasOutput: boolean;

    /** status of optimization run */
    runStatus?: {

        /** e.g., running */
        status: string;

        /** message json */
        message: any;
    }

}

const initialState: NotifyState = {
    notifications: [
        // {
        //     id: 'faker',
        //     running: false,
        //     runId: 'ford-optrun-1',
        //     message: 'Optimization run completed. Click to view results.',
        //     timestamp: new Date(),
        //     runStatus: {
        //         status: "running",
        //         message: "Running generation 20"
        //     },
        //     success: true,
        //     data: {
        //     },
        //     showOptButtons: true
            
            
        // }
    ]
};

export const actionCreators = {

    markNotificationRead: (runId: string, is_read: boolean) => async (dispatch: any, getState: any) => {

        const state = getState();
        const studyId = state.study?.uid;

        const response = await apiService.aPut(state.auth, `${globals.apiRoot}/study/${studyId}/optimize/run/${runId}/mark_read/${is_read}`);
        if( response.ok ){
            dispatch({ type: NOTIF_MARK_READ, value: { id: runId, is_read } });
        }
    },

    clearNotification: (id: string) => async (dispatch: any, getState: any) => {
        dispatch({ type: NOTIF_CLEAR, value: id });
    },

    deleteOptRun: (runId: string, callback: any) => async (dispatch: any, getState: any) => {

        const state = getState();

        const response = await apiService.aDelete(state.auth, `${globals.apiRoot}/optimize/run/${runId}`);
        if( response.ok ){
            console.log(`run ${runId} deleted`)
            if( callback ){
                callback();
            }
        }
        else{
            console.error(`run ${runId} not deleted`, response.statusText);
        }
        
    },

    updateRunStatus: (id: string, runId: string) => async (dispatch: any, getState: any) => {

        const state = getState();
        if( !id || !runId ) return;

        console.log(`updateRunStatus id=${id} runId=${runId}`);

        const url = `${globals.apiRoot}/optimize/status/${runId}`;
        try{
            const response = await apiService.aGet(state.auth, url);

            const value = await response.json();

            dispatch({
                type: NOTIF_RECEIVED_RUN_STATUS,
                id,
                runId,
                value
            })

            if( value?.status === 'done' || value?.status === 'completed' ){
                dispatch({
                    type: NOTIF_MARK_COMPLETE, // 'COMPLETE_RUNNING_NOTIFICATION',
                    runId,
                    success: true,
                    data: value,
                    message: 'Optimization run completed. Click to view results',
                    showOptButtons: true
                });
                actionCreators.markNotificationRead(runId, false);
            }

        }
        catch( err ){
            console.log(err)
        }

    },

    clearAllNotifications: (callback: any) => async (dispatch: any, getState: any) => {
        dispatch({ type: NOTIFS_CLEAR });
        const state = getState();

        // callback is only called if all notifications were cleared
        // (running notifications aren't cleared)
        if( callback && (!state.notify || !state.notify.notifications || state.notify.notifications.length < 1)){
            callback();
        }
    },

    getOptRuns: () => async (dispatch: any, getState: any) => {

        const state = getState();
        const studyId = state.study?.uid;

        const response = await apiService.aGet(state.auth, `${globals.apiRoot}/study/${studyId}/optruns`);
        if( response.ok ){
            // todo: add as notifications?

            const runs = await response.json();
            let notifs = runs.map((run: any) => ({
                type: 'optrun',
                runId: run.id,
                read: !run.is_new,
                running: run.state === 'queued' || run.state === 'started' || run.state === 'running',
                hasOutput: run.hasOutput,
                message: run.message,
                error: run.error,
                state: run.state
            }));
            dispatch({ type: NOTIF_ADD_MULTI, notifs });

        }


    }

};

export const reducer = (state: NotifyState, action: any): NotifyState => {
    state = state || initialState;

    switch( action.type ){

        case STUDY_RESET : return initialState;

        case NOTIF_ADD : 
            return {
                ...state,
                notifications: [
                    {
                        id: guid(),
                        type: action.type,
                        read: false,
                        running: action.running,
                        runId: action.runId,
                        message: action.value,
                        error: action.error,
                        hasOutput: action.hasOutput,
                        timestamp: new Date()
                    },
                    ...state.notifications
                ]
            };

            case NOTIF_ADD_MULTI : 
            return {
                ...state,
                notifications: [
                    ...action.notifs.map((n: any) => ({
                        ...n,
                        id: guid(),
                        //timestamp: new Date()
                    }))
                    ,
                    ...state.notifications
                ]
            };
        

        case NOTIF_MARK_READ :
            return {
                ...state,
                notifications: state.notifications.map((n: any) =>
                    n.runId === action.value.id ? {
                        ...n,
                        read: action.value.is_read
                    } : n
                )
            };

        case NOTIF_MARK_COMPLETE :
            return {
                ...state,
                notifications: state.notifications.map((n: any) =>
                    n.runId === action.runId ? {
                        ...n,
                        state: 'completed',
                        running: false,
                        success: action.success,
                        errorMessage: action.errorMessage,
                        data: action.data,
                        message: action.message,
                        read: false,
                        showOptButtons: action.showOptButtons
                    }
                    : n
                )
            };
        
        case NOTIF_RECEIVED_RUN_STATUS :
            return {
                ...state,
                notifications: state.notifications.map((n: any) =>
                    n.runId === action.runId ? {
                        ...n,
                        runStatus: action.value
                    }
                    : n
                )
            };

        case NOTIF_CLEAR :
            return {
                ...state,
                notifications: state.notifications.filter((n: any) => n.id !== action.value)
            };
        
        case NOTIFS_CLEAR :
        
            return {
                ...state,
                notifications: state.notifications.filter((n: any) => n.running) // clear everything except running notifs
            };
        }

    return state;
};
