import * as d3_core from 'd3';
import * as d3_boxplot from 'd3-boxplot';
import { format as d3Format } from 'd3-format';
import * as _ from 'lodash';
import * as React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import './BoxPlot.scss';
import CustomLegend from '../common/CustomLegend';

const d3 = { ...d3_core, ...d3_boxplot };

var fmt = val => isNaN(val) ? '.' : d3Format('0.2f')(val);

const barColors = [
    '#4287fa',
    '#afafaf'
];


class BoxPlot extends React.Component {
    sortOrderNode = React.createRef()
    orderByNode = React.createRef()
    componentDidMount() {
        this.updateSvg();
    }

    componentDidUpdate() {
        this.updateSvg();
    }

    node;

    originX = 0;
    originY = 0;
    zoom = 1.0;
    in_out = '';

    // margin = {
    //     top: 0,
    //     bottom: 20,
    //     left: this.props.labelWidth || 130,
    //     right: 20
    // }

    handleSort = data => {
        switch (this.orderByNode.current.value) {
            case 'valueLabel':
                data.sort((a, b) => {
                    const aValue = a?.value;
                    const bValue = b?.value;
                    const mult = this.sortOrderNode.current.value === 'desc' ? -1 : 1;

                    if (aValue && bValue) return (aValue - bValue) * mult;
                    return 0
                });
                break;
            case 'value2Label':
                data.sort((a, b) => {
                    const aValue = a?.value2;
                    const bValue = b?.value2;
                    const mult = this.sortOrderNode.current.value === 'desc' ? -1 : 1;

                    if (aValue && bValue) return (aValue - bValue) * mult;
                    return 0
                });
                break;
            case 'gap':
                data.sort((a, b) => {
                    const aValue = a?.value;
                    const bValue = b?.value;
                    const aValue2 = a?.value2;
                    const bValue2 = b?.value2;
                    const mult = this.sortOrderNode.current.value === 'desc' ? -1 : 1;

                    if (aValue && bValue && aValue2 && bValue2) return (Math.abs(aValue - aValue2) - Math.abs(bValue - bValue2)) * mult;
                    return 0;
                });
                break;
        }
        this.updateSvg(data);
    }

    updateSvg = (_data) => {
        //data passed from dcard into <lib.bo.boxplot data={} />
        let data = _data || this.props.data;
        //console.log('boxplot data', data);

        if (!data) return;
        const stats = data;

        try {

            const domNode = this.node;
            if (!domNode) return;

            const width = domNode.clientWidth;
            const height = domNode.clientHeight;
            if (!width || !height) return;

            const [minLabelWidth, maxLabelWidth] = this.props.labelWidth || [130, 130];

            let margin = {
                top: 20,
                bottom: 20,
                left: minLabelWidth,
                right: 20
            };

            if (width / 2 > minLabelWidth) {
                margin.left = Math.min(maxLabelWidth, width / 2);
            }

            const visual = this.props.visual || 'boxplot';


            const vertical = false; //options.indexOf('vertical') !== -1
            const jitter = 0.2;
            const opacity = 0.64;
            const showInnerDots = true;
            const useSymbolTick = false;
            const minimalStyle = false;
            const useColor = true;

            const dataMin = data?.[0]?.min || (data?.[0]?.min === 0 ? 0 : 1);
            const dataMax = data?.[0]?.max || (data?.[0]?.max === 0 ? 0 : 10);
            const step = data?.[0].step || 1;
            const usePct = dataMin === 0 && dataMax === 1;

            const colors = d3.quantize(t => d3.interpolateSpectral(t), stats.length).reverse().map(c => d3.hsl(c).darker(2));
            const textColor = '#606060';


            const scale = d3.scaleLinear()
                .domain(dataMin, dataMax)
                .range(vertical ? [height - margin.top - margin.bottom, margin.top] : [margin.left, width - margin.left - margin.right])

            const band = d3.scaleBand()
                .domain(d3.range(stats.length))
                .range(vertical ? [margin.left, width - margin.left - margin.right] : [margin.top, height - margin.top - margin.bottom])
                .paddingInner(minimalStyle ? 0 : 0.3)
                .paddingOuter(minimalStyle ? 2 : 0.2)

            const band_nopad = d3.scaleBand()
                .domain(d3.range(stats.length))
                .range(vertical ? [margin.left, width - margin.left - margin.right] : [margin.top, height - margin.top - margin.bottom])
                .paddingInner(0)
                .paddingOuter(0)


            const getToolTip = (d) => {

                const doubleDot = d.value2 || d.valueLabel || d.value2Label;
                const gap = doubleDot ? d.value2 - d.value : null;
                if (usePct)
                    fmt = d3Format('0.1%');

                // tooltip
                return doubleDot ? d.label + ':\n' + d.valueLabel + ': ' + fmt(d.value) + '\n' + d.value2Label + ': ' + fmt(d.value2) + '\nGap: ' + fmt(gap)
                    : d.label + ": " + fmt(d.value);
            }

            // const plot = d3.boxplot()
            //   .scale(scale)
            //   .jitter(jitter)
            //   .opacity(opacity)
            //   .showInnerDots(showInnerDots)
            //   .symbol(useSymbolTick ? d3.boxplotSymbolTick : d3.boxplotSymbolDot)
            //   .bandwidth(band.bandwidth())
            //   .boxwidth(minimalStyle ? 6 : band.bandwidth())
            //   .vertical(vertical)
            //   .key(d => d)

            const h = band.bandwidth();
            const barWidth = width - margin.left - margin.right;
            let values = _.sortBy(data[0].values, 'value')



            const square = function (d, i) {

                //console.log('data: ', d);
                const sel = d3.select(this);

                // covers up long labels
                sel.append('rect')
                    .attr('x', -10)
                    .attr('y', 0)
                    .attr('width', barWidth + margin.right + 10)
                    .attr('height', h)
                    .attr('fill', '#ffffff')

                if (visual === 'stacked-bars') {

                    let pos = 0;
                    d.values?.forEach((valObj, i) => {
                        if (valObj.value) {
                            sel.append('rect')
                                .attr('x', pos * barWidth)
                                .attr('y', 0)
                                .attr('width', valObj.value * barWidth)
                                .attr('height', h)
                                .attr('fill', barColors[i % barColors.length])
                            sel.append('text')
                                .attr('class', 'text-value')
                                .attr('text-anchor', 'middle')
                                .attr('x', d => i == 0
                                    ? valObj.value * barWidth / 2
                                    : (pos * barWidth) + (valObj.value * barWidth / 2)
                                )
                                .attr('y', h / 2 + 5)
                                .text(d =>
                                    i == 0
                                        ? ((pos * barWidth) > 20) || (valObj.value * barWidth / 2) > 20
                                            ? (valObj.value * 100).toFixed() + '%'
                                            : ''
                                        : (valObj.value * 100).toFixed() + '%'
                                )
                                .attr('fill', '#fff');
                            pos += valObj.value;
                        }
                    })

                }
                else {

                    // range line
                    sel.append('line')
                        .attr('x1', 0)
                        .attr('y1', h / 2)
                        .attr('x2', barWidth)
                        .attr('y2', h / 2)
                        .attr('stroke', '#afafaf')

                    //tick lines
                    for (let i = d.min; i <= d.max; i += step) {
                        sel.append('line')
                            .attr('x1', ((i - d.min) / (d.max - d.min)) * barWidth)
                            .attr('x2', ((i - d.min) / (d.max - d.min)) * barWidth)
                            .attr('y1', 4)
                            .attr('y2', h - 4)
                            .attr('stroke', '#afafaf')
                    }

                    if (d.value2) {
                        sel.append('ellipse')
                            .attr('cx', ((d.value2 - d.min) / (d.max - d.min)) * barWidth)
                            .attr('cy', h / 2)
                            .attr('rx', 4)
                            .attr('ry', 4)
                            .attr('fill', '#ffc20e')
                    }

                    // value dot
                    if (d.value) {
                        sel.append('ellipse')
                            .attr('cx', ((d.value - d.min) / (d.max - d.min)) * barWidth)
                            .attr('cy', h / 2)
                            .attr('rx', 4)
                            .attr('ry', 4)
                            .attr('fill', '#0064c8')
                    }

                    // tooltip
                    sel.append('title')
                        .text(d => getToolTip(d));

                }



            }

            const svg = d3.select(this.node);

            svg.selectAll("*").remove();

            svg.append('g').attr('class', 'labels')
            svg.append('g').attr('class', 'plots')
            svg.append('g').attr('class', 'scale')

            if (visual !== 'stacked-bars') {
                let _scale = svg.select('g.scale')
                    .append('g')
                    .attr('transform', 'translate(' + [margin.left, margin.top + 17] + ')')

                //whisker text markers
                for (let i = dataMin; i <= dataMax; i += step) {
                    _scale.append('text')
                        .attr('x', d => ((i - dataMin) / (dataMax - dataMin)) * barWidth - 4)
                        .attr('y', 5)
                        .style('font-size', '10')
                        .text(usePct ? parseInt(Math.round(i * 100)) : i);
                }
            }

            svg.select('g.plots')
                .attr('transform', 'translate(' + [margin.left, margin.top] + ')')
                .selectAll('.plot').data(stats)
                .join('g')
                .attr('class', 'plot')
                .attr('transform', (_, i) => `translate(${vertical ? [band(i), 0] : [0, band(i)]})`)
                .attr('color', (_, i) => useColor ? colors[i / 2 | 0] : '#000')
                // .on("mouseover", showTooltip)
                // .on("mousemove", showTooltip)
                .each(square)


            svg.select('g.labels')
                .attr('transform', 'translate(' + [0, margin.top] + ')')
                .selectAll('.label').data(stats)
                .join('text')
                .attr('class', 'label')
                .attr('x', 20)
                .attr('y', (d, i) => band(i) + band.bandwidth() / 2.0 + 3)
                .attr("font-family", "sans-serif")
                .attr("font-size", "9pt")
                .attr("fill", (d, i) => textColor)// d3.hsl(useColor ? colors[i / 2|0] : '#000').darker(1))
                .text(d => d.label || 'label missing')

                // tooltip
                .append('title')
                .text(d => getToolTip(d));


            var nodeWidth = (d) => d.getBBox().width;


            if (visual === 'stacked-bars') {
                var legend = svg.append('g')
                    .attr('class', 'legend')
                    .attr('transform', `translate(${(width / 2) - 120}, 0)`);

                const lg = legend.selectAll('g')
                    .data(values)
                    .enter()
                    .append('g')
                    .attr('transform', (d, i) => `translate(${i * 100},${15})`);


                lg.append('rect')
                    .style('fill', (d, i) => barColors[i % barColors.length])
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', 12)
                    .attr('height', 12);

                lg.append('text')
                    .attr('x', 17.5)
                    .attr('y', 10)
                    .text(d => d.label);

                let offset = 0;
                lg.attr('transform', function (d, i) {
                    let x = offset;
                    offset += nodeWidth(this) + 20;
                    return `translate(${x},${0})`;
                });

                legend.attr('transform', function () {
                    return `translate(${(width - nodeWidth(this)) / 2},${0})`
                });

            } else {
                // if (data[0].value2Label && data[0].valueLabel) {
                //     var legend = svg.append('g')
                //         .attr('class', 'legend')
                //         .attr('transform', `translate(${(width / 2)-40}, 5)`);

                //     legend
                //         .append('ellipse')
                //             .attr('cx', 0)
                //             .attr('cy', 4)
                //             .attr('rx', 4)
                //             .attr('ry', 4)
                //             .attr('fill', '#0064c8')

                //     legend
                //         .append('text')
                //             .text(data[0].valueLabel)
                //             .attr('x', 12)
                //             .attr('y', 0)
                //             .attr('text-anchor', 'start')
                //             .attr('alignment-baseline', 'hanging');

                //     legend
                //         .append('ellipse')
                //             .attr('cx', (data[0].valueLabel.length * 9))
                //             .attr('cy', 4)
                //             .attr('rx', 4)
                //             .attr('ry', 4)
                //             .attr('fill', '#ffc20e')

                //     legend
                //         .append('text')
                //             .text(data[0].value2Label)
                //             .attr('x', (data[0].valueLabel.length * 9) + 12)
                //             .attr('y', 0)
                //             .attr('text-anchor', 'start')
                //             .attr('alignment-baseline', 'hanging');
                // }
            }
        }
        catch (err) {
            console.error(err);
        }

    }



    render() {

        const { data } = this.props;
        //console.log(data);

        return data ?
            <ReactResizeDetector handleWidth handleHeight>
                {({ width, height }) => {
                    var legendPayload = {
                        align: "left",
                        chartHeight: 300,
                        chartWidth: 300,
                        width: 300,
                        iconSize: 12,
                        iconType: "circle",
                        size: 12,
                        payload: [
                            {
                                color: "#0064c8",
                                type: "circle",
                                value: data[0]?.valueLabel,
                            },
                            {
                                color: "#ffc20e",
                                type: "circle",
                                value: data[0]?.value2Label,
                            },
                        ],
                        layout: "horizontal",
                        verticalAlign: "middle"
                    }

                    this.updateSvg();
                    return <>
                        {
                            data[0].valueLabel && data[0].value2Label
                                ? <div className='sort-control' style={{ transform: `translate(${(width / 2) - 136}px, 0)` }}>
                                    <p>Sort By:</p> <select ref={this.orderByNode} disabled={this.props.inInsightCard} onChange={() => this.handleSort(data)}>
                                        <option value='gap'>Gap</option>
                                        <option value='valueLabel'>{this.props.data[0].valueLabel}</option>
                                        <option value='value2Label'>{this.props.data[0].value2Label}</option>
                                    </select>

                                    <p>Sort Order:</p> <select disabled={this.props.inInsightCard} ref={this.sortOrderNode} onChange={() => this.handleSort(data)}>
                                        <option value='desc'>Desc</option>
                                        <option value='asc'>Asc</option>
                                    </select>
                                </div> : ''
                        }
                        {
                            data[0].value2Label && data[0].valueLabel ? <div className='boxplot-legend'><CustomLegend {...legendPayload} /></div> : null
                        }

                        <svg
                            className='boxplot'
                            style={(data[0].value2 !== null && data[0].value2 !== undefined)
                                ? { height: this.props.height || height, marginTop: '30px' }
                                : { height: this.props.height || height }
                            }
                            ref={node => this.node = node}
                        />
                    </>
                }
                }
            </ReactResizeDetector> : ''

    }
}

export default BoxPlot;