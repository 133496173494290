import { Models } from "openai/resources"
import * as globals from "../globals"
import * as apiService from "../utils/apiService"
import { getMeta } from '../utils/metaUtils'
import { actionCreators as GlobalStoreCreator } from '../store/General'


import {
	STUDY_RESET,
	FORECAST_LEVEL_HINTS_RECEIVE,
	FORECAST_LEVEL_HINTS_REQUEST,
	FORECAST_RECEIVE,
	FORECAST_REQUEST,
	FORECAST_MESSAGE_HINTS_RECEIVE,
	FORECAST_MESSAGE_HINTS_REQUEST,
	FORECAST_SEGMENT_PROFILES_RECEIVE,
	FORECAST_SEGMENT_PROFILES_REQUEST,
	FORECAST_AUX_RECEIVE,
	FORECAST_AUX_REQUEST,
	FILTER_SELECT,
	MODEL_CONFIG_INIT,
	// @ts-ignore
} from "./action-types"

export interface ForecastState {
	[key: string]: any
}

const initialState: ForecastState = {
	
}

// const hackConfig = (config: any) => {
// 	return {
// 		...config,
// 		price: (config.price - 0.32) * 3000,
// 		cashback: config.cashback * 400,
// 	}
// }

export const actionCreators = {
	// forecast the loaded scenario
	requestForecast: (callback: any) => async (dispatch: any, getState: any) => {

		dispatch(GlobalStoreCreator.setGlobalStore('forecast_needed', false))
		dispatch(GlobalStoreCreator.setGlobalStore('forecast_running', true))

		const state = getState()

		// Accessing the study ID and configs from the updated state structure
		const studyId = state.study.uid
		const model = state.model
		const activeConfig = model?.activeConfig 
		const config = model[activeConfig]?.config

		if (!config) {
			console.error("Config for model not found:", config)
			return
		}

		
		const query = {
			studyId: studyId,
			modelInfo: model[activeConfig]?.modelInfo,
			uFilter: (state.filter.selectedFilter || {}).syntax ,
			uFilterName: (state.filter.selectedFilter || {}).name ,
			config: config.play,
			brandvalues: config.brandvalues || [],
			//etag: config.etag,
			playHash: config.playHash
		}

		// console.log('filter syntax', query.uFilter)
		const url = `${globals.apiRoot}/compute/model`
		try {
			const response = await apiService.aPost(state.auth, url, query)
			const resp = await response.json()
			console.log('RESPONSE', resp);
			console.log('forecast is finished!!')
			if (resp) {
				// const resp = await response.json()
				if (resp) {
					dispatch({ type: FORECAST_RECEIVE, data: resp, etag: config.etag, filtername: query.uFilterName, model: activeConfig })
					dispatch(GlobalStoreCreator.setGlobalStore('forecast_running', false))

					callback && callback(true)
				}
			}
			
			else {
				// const errorText = await response.text(); // Get the error message as text
					
				callback && callback(false, "Forecast request failed:")//, errorText)
				console.error("Forecast request failed:")//, errorText);
				// dispatch({
				// 	type: FORECAST_RECEIVE,
				// 	error: response.statusText || "error",
				// 	value: null,
				// 	etag: config.etag,
				// 	filtername: query.uFilterName,
				// 	model: activeConfig
				// });
					
			}
		} catch (err: any) {
			callback && callback(false, "Error during forecast computation:", err)
			console.error("Error during forecast computation:", err)
			// dispatch({
			// 	type: FORECAST_RECEIVE,
			// 	error: err.message,
			// 	value: null,
			// 	etag: config.etag,
			// 	filtername: query.uFilterName,
			// 	model: activeConfig
			// })
				
		}
	},
	
	

	requestAux: (path: string, filter: any, key: string, data: any, configModel: string) => async (dispatch: any, getState: any	) => {
		// path is the api url + args if any, e.g. brand_all_else_equals?something=asdf
		// key is the forecaster key, meaning that the results will be found in forecaster.forecast[filter:key]
		// data contains extra body parameters to post to the api call

		var state = getState()

		var studyId = state.study.uid
		var config = state.dcm.configs
		const configForModel = config[configModel]

		var body = {
			...data,
			filter: filter || "1==1",
			config: {
				etag: configForModel.etag,
				assumptionsId: configForModel.assumptionsId,
				baseCaseId: configForModel.baseCaseId,
				productSlots: configForModel.play.map((prod: any) => ({
					uid: prod.uid,
					def: prod.def,
					base: configForModel.base,
					play: configForModel.play,
				})),
				brandEquities: configForModel.brandEquities,
			},
		}
		//console.log('aux body', body)

		dispatch({
			type: FORECAST_AUX_REQUEST,
			path,
			filter,
			key,
			etag: configForModel.etag,
		})

		const url = `${	globals.apiRoot	}/study/${studyId}/compute/aux?path=${encodeURIComponent(path)}`
		try {
			const response = await apiService.aPost(state.auth, url, body)

			if (!response.ok) {
				console.error(response)
				dispatch({
					type: FORECAST_AUX_RECEIVE,
					path,
					filter,
					key,
					etag: configForModel.etag,
					error: response.statusText || "error",
				})
				return
			}

			const value = await response.json()
			//console.log(`forecast aux ${path} received `, value)

			if (value && value.debug && value.debug.play) {
				//console.log('debug (forecast input): ', value.debug.play)
			}

			dispatch({
				type: FORECAST_AUX_RECEIVE,
				path,
				filter,
				key,
				etag: configForModel.etag,
				value,
			})
		} catch (error) {
			console.error("forecast price curves error", error)

			dispatch({
				type: FORECAST_AUX_RECEIVE,
				path,
				filter,
				key,
				etag: configForModel.etag,
				error,
			})

			// todo: dispatch the error
		}
	},

	runAux2: async (state: any, path: string, modelName: string, data: any) => {

		//const state = getState()
		const studyId = state.study.uid

		const body = {
			studyId,
			modelName,
			data
		}
		
		const url = `${globals.apiRoot}/study/${studyId}/compute/aux2?path=${path}` // path is e.g., "att-importances"
		const response = await apiService.aPost(state.auth, url, body)
		let rdata = await response.json()
		return rdata;
	},

	requestLevelHints: (filter: any,productSlotUid: string,	attName: string, configModel: string) => async (dispatch: any, getState: any) => {
		const state = getState()
		const studyId = state.study.uid

		dispatch({
			type: FORECAST_LEVEL_HINTS_REQUEST,
			productSlotUid,
			attName,
			filter,
		})

		var config = state.dcm.configs

		var body = {
			uFilter: filter,
			config: {
				etag: config.etag,
				assumptionsId: config.assumptionsId,
				baseCaseId: config.baseCaseId,
				productSlots: state.study.config.productSlots.map((slot: any) => ({
					uid: slot.uid,
					def: slot.def,
					base: slot.base,
					play: slot.play,
				})),
				brandEquities: config.brandEquities,
			},
			productSlotUid,
			attName,
		}
		//console.log('sending query: ', query)

		const url = `${globals.apiRoot}/study/${studyId}/compute/level-hints`
		try {
			// const response = await fetch(url, {
			//     method: 'post',
			//     headers: {
			//         Accept: 'application/json',
			//         'Content-Type': 'application/json',
			//         Authorization: 'Bearer ' + state.auth.token
			//     },
			//     body: JSON.stringify(body)
			// })

			const response = await apiService.aPost(state.auth, url, body)
			const value = await response.json()
			//console.log('received value: ', value)

			dispatch({
				type: FORECAST_LEVEL_HINTS_RECEIVE,
				filter,
				productSlotUid,
				attName,
				value,
				etag: config.etag,
			})
		} catch (err) {
			console.error("computeLevelHints error", err)

			// todo: dispatch the error
		}
	},

	requestMessageHints: () => async (dispatch: any, getState: any) => {
		var state = getState()

		var studyId = state.study.uid
		var config = state.study.config

		var config2 = {
			etag: config.etag,
			assumptionsId: config.assumptionsId,
			baseCaseId: config.baseCaseId,
			productSlots: state.study.config.productSlots.map((slot: any) => ({
				uid: slot.uid,
				def: slot.def,
				base: slot.base,
				play: slot.play,
			})),
			brandEquities: config.brandEquities,
		}

		var query = {
			studyId: studyId,
			uFilter: (state.study.selectedFilter || {}).syntax || "1==1",
			// todo: add study version or config version to make sure model updates are handled correctly
			config: config2,
		}

		dispatch({
			type: FORECAST_MESSAGE_HINTS_REQUEST,
			filter: query.uFilter,
			etag: config.etag,
		})

		const url = `${globals.apiRoot}/compute/message-hints`
		try {
			const response = await apiService.aPost(state.auth, url, query)

			if (!response.ok) {
				console.error(response)
				dispatch({
					type: FORECAST_MESSAGE_HINTS_RECEIVE,
					error: response.statusText || "error",
					value: null,
					etag: config.etag,
					filter: query.uFilter,
				})
				return
			}

			const value = await response.json()
			//console.log('forecast received ', value)

			if (value && value.debug && value.debug.play) {
				//console.log('debug (forecast input): ', value.debug.play)
			}

			dispatch({
				type: FORECAST_MESSAGE_HINTS_RECEIVE,
				value,
				etag: config.etag,
				filter: query.uFilter,
			})
		} catch (err) {
			console.error("computeSummary error", err)

			dispatch({
				type: FORECAST_MESSAGE_HINTS_RECEIVE,
				error: err,
				value: null,
				etag: config.etag,
				filter: query.uFilter,
			})

			// todo: dispatch the error
		}
	},

	requestSegmentProfiles: (
		studyId: string,
		whichConfig: string,
		solutionName: string,
	) => async (dispatch: any, getState: any) => {
		dispatch({ type: FORECAST_SEGMENT_PROFILES_REQUEST, whichConfig })

		var state = getState()

		var query = {
			studyId: studyId,
			segmentSolution: {
				solutionName, //: "game_motivations_3"
			},
			assumptions: {
				numProfilingVars: 15,
			},
		}
		//console.log('sending query: ', query)

		const url = `${globals.apiRoot}/compute/segment-profiles`
		try {
			const response = await apiService.aPost(state.auth, url, query)

			const value = await response.json()
			//console.log('received value: ', value)

			dispatch({
				type: FORECAST_SEGMENT_PROFILES_RECEIVE,
				whichConfig,
				solutionName,
				value,
			})
		} catch (err) {
			console.error("computeSummary error", err)

			// todo: dispatch the error
		}
	},
}


export const reducer = (state: ForecastState = initialState, action: any) => {

	switch (action.type) {
		case STUDY_RESET:
			return initialState

		case FORECAST_REQUEST:
			return {
				...state,
				[action.filter]: {
					...state[action.filter],
					loading: true,
					loading_etag: action.etag,
				},
				
			}		
			

		case FORECAST_RECEIVE: //data, etag, filter
		
			const { model, filtername, etag, data, error } = action

			console.log('...data[filtername]', data);
			console.log('model', model);
			return {
				...state,//'forecaster'
				etag: etag,
				[model]: {
					...state[model],
					...data
					// [filtername]: {
					// 	...state[model][filtername],                        
                    //     ...data[filtername]
					// 	// loading: false,
					// 	// error: error,
					// }
				}
			}
	

		case FORECAST_AUX_REQUEST:
			return {
				...state,
				[`${action.filter}:${action.key}`]: {
					...state[`${action.filter}:${action.key}`],
					loading: true,
					loading_etag: action.etag,
				},
			}

		case FORECAST_AUX_RECEIVE:
			return {
				...state,
				[`${action.filter}:${action.key}`]: {
					loading: false,
					error: action.error,
					etag: action.etag,
					value: action.value || {},
				},
			}
		
		case FORECAST_MESSAGE_HINTS_REQUEST:
			return {
				...state,
				[`${action.filter}:MH`]: {
					...state[`${action.filter}:MH`],
					loading: true,
					loading_etag: action.etag,
				},
			}

		case FORECAST_MESSAGE_HINTS_RECEIVE:
			return {
				...state,
				[`${action.filter}:MH`]: {
					loading: false,
					error: action.error,
					etag: action.etag,
					...(action.value || {})[action.filter],
				},
			}

		case FORECAST_LEVEL_HINTS_REQUEST: {
			return {
				...state,
				[`${action.filter}:LH:${action.productSlotUid}:${action.attName}`]: {
					loading: true,
					loading_etag: action.etag,
				},
			}
		}

		case FORECAST_LEVEL_HINTS_RECEIVE: {
			return {
				...state,
				[`${action.filter}:LH:${action.productSlotUid}:${action.attName}`]: {
					loading: false,
					etag: action.etag,
					value: action.value || {},
				},
			}
		}

		// case FORECAST_SEGMENT_PROFILES_REQUEST : return {
		//     ...state,
		//     cache: {
		//         segmentProfiles: {
		//             ...(state.cache || {}).segmentProfiles,
		//             [action.whichConfig]: {
		//                 isLoading: true
		//             }
		//         }
		//     }
		// }

		// case FORECAST_SEGMENT_PROFILES_RECEIVE : return {
		//     ...state,
		//     cache: {
		//         segmentProfiles: {
		//             ...(state.cache || {}).segmentProfiles,
		//             [action.whichConfig]: {
		//                 isLoading: false,
		//                 solution: {
		//                     name: action.solutionName,
		//                     label: ((state.filterGroups || []).find(fg => fg.id === action.solutionName) || {}).label || action.solutionName
		//                 },
		//                 tables: action.value
		//             }
		//         }
		//     }
		// }
	}

	return state
}
