import * as React from 'react'
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SELECTION_SELECT } from '../../store/action-types.ts'
//import * as globals from '../../globals'
import * as metaUtils from '../../utils/metaUtils'
import { SelectionBlock } from './SelectionBlock'
// import { actionCreators as selectionsActionCreators } from "../../store/Selections"
import './SelectionDrawer.scss'


export const SelectionDrawer = (props) => {

    const [searchText, setSearchText] = useState('')
    const [items, setItems] = useState([])
    const [allItems, setAllItems] = useState(null)
    const [loadingItems, setLoadingItems] = useState(false)
    const [groupingItems, setGroupingItems] = useState(null)
    const [loadingGroupingItems, setLoadingGroupingItems] = useState(false)
    const [selectedGrouping, setSelectedGrouping] = useState(null)
       

    //const selectedBoxRef = React.useRef(null)

    const studyId = useSelector(state => state.study?.uid)
    const studyFolder = useSelector(state => state.study?.folder)
    const auth = useSelector(state => state.auth)    
    const dispatch = useDispatch()

    const selectionTypes = useSelector(state => state.study?.selectionTypes)
    const selectionType = props.settings?.selectionType
    const selectionName = props.settings?.selectionName
    const autoSelect = metaUtils.constructAutoSelect(selectionType?.autoSelect, selectionTypes)

    const models = useSelector(state => state.model)
    const active_model = useSelector(state => state.model.activeConfig)

    const selection = useSelector(state => state.selection)
    // const dcm_base_prods = useSelector(state => state.configs?.base)

    // console.log('props.settings', props.settings)
    // console.log('selectionType', selectionType)
    // console.log('selectionName', selectionName)
    // console.log('selection', selection)

    useEffect(() => {
        window.selectionDrawer = this
        
    }, [])    

    // load the meta
    useEffect(() => {

        const getSelectionType = async () => {

            const metaKey = selectionType?.meta
            if (!metaKey) return

            const metaKeys = [metaKey, ...autoSelect?.map(e => e.meta)]

            setLoadingItems(true)
            try {
                let obj = await metaUtils.getMetas(studyId, auth, metaKeys)
                setItems(obj?.[metaKey]) //table with selection data in meta
                setAllItems(obj) // contains info for autoSelect

                // check selected grouping
                if (!selectedGrouping || selectionType?.groupings?.includes(selectedGrouping)) {
                    //console.log('selected grouping okay')
                }
                else {
                    //console.log('selected grouping not found for type')
                    setSelectedGrouping(null)
                }                    
            }
            catch (err) {
                console.error(err)
            }
            setLoadingItems(false)

        }

        getSelectionType()         

    }, [selectionType])


    // load the selected grouping
    useEffect(() => {

        const f = async () => {

            const groupingMeta = selectionTypes?.[selectedGrouping]
            
            const metaKey = groupingMeta?.meta
            if (!metaKey) return

            setLoadingGroupingItems(true)
            try {
                let i = await metaUtils.getMeta(studyId, auth, metaKey)
                setGroupingItems(i)
            }
            catch (err) {
                console.error(err)
            }
            setLoadingGroupingItems(false)          
            
        }
        f()

    }, [selectedGrouping])


   useEffect(() => {
       const clonedProds = models?.[active_model]?.config?.play.filter(f => f.isClone) || []
       
        if (items.length > 0) {
            const newItems = clonedProds.reduce((acc, cp) => {
                if (!items.some(item => item.uid === cp.uid)) {
                    acc.push({
                        ...cp,
                        uid: cp?.uid || cp?.id,
                        image: 'clone.png'
                    })
                }
                return acc
            }, [])

            if (newItems.length > 0) {
                setItems(prevItems => [...prevItems, ...newItems])
            }
        }
    }, [items, models, active_model])


    const handleClick = (item) => {

        // select item. selectionName would be null if this is used in a non global way and we just want to return selected item
        if (selectionName) {
            let action = {
                type: SELECTION_SELECT,
                key: selectionName,
                value: item
            }

            // attach autoSelect info if exists
            if (autoSelect?.length > 0) {

                // look up autoSelect objects by id
                let aSel = autoSelect.map(entry => {
                    let retVal = {
                        ...entry,
                    }
                    retVal.select = allItems?.[entry.meta]?.find(row => row?.id === item?.[entry.from])
                    return retVal
                })

                // transform format from array to obj
                aSel = aSel.reduce((acc, el) => {
                    if (el?.select) { // if selection was not found, don't overwrite existing value
                        acc[el.to] = el.select
                    }
                    return acc
                }, {})

                // attach autoSelect to action
                action.autoSelect = aSel

            }
            
            
            dispatch(action)
        }

        if (props.onSelect) {
            props.onSelect(item)
        }
    }

  
    const handlePrimaryClick = (slot) => {
        //console.log('handlePrimaryClick')
        if (props.onPrimarySelect) {
            props.onPrimarySelect(slot)
        }
    }

    const isMatch = (slot, searchItems) => {
        let str = slot.label.toLocaleLowerCase()
        for (let i in searchItems) {
            if (str.indexOf(searchItems[i]) === -1)
                return false
        }
        return true
    }
   
   

    let displayItems = items?.sort((a, b) => {
        if (a.displayIndex !== null && a.displayIndex !== undefined)
            return a.displayIndex - b.displayIndex
        return a.label.localeCompare(b.label)
    }) || []
    
    if (searchText !== '') {
        const searchItems = searchText.toLowerCase().split(' ')
        displayItems = displayItems.filter(item => isMatch(item, searchItems))
    }

    let groupings = [null, ...(selectionType?.groupings || [])]    

    let displayGroups = selectedGrouping ? groupingItems?.map(gitem => {
        return {
            label: gitem?.label,
            items: displayItems?.filter(dItem => dItem?.[selectedGrouping] == gitem.id)
        }
    })
    : [{
        label: "All",
        items: displayItems,
        all: true
        }] 
   
    
    
    
    const CloseSelector = () => {
        window.topBar.hideMenu()
        setSearchText('')
    }

    
    
    return (
        <div className={"selection-drawer" + (props.isOpen ? ' open' : ' closed')}>

            <div className='top'>

                <div className='h3'>
                    Select {selectionType?.label}
                    <button style={{ float: 'right', cursor: 'pointer' }} role='button' aria-label='close' onClick={CloseSelector}>
                        <i className='fal fa-times'></i>
                    </button>
                </div>

                <div className='search'>
                    <div className='input-container'>
                        <input id="search" type="text" placeholder="search" value={searchText} onChange={(ev) => setSearchText( ev.target.value )}/>
                    </div>
                    <span className={'btn-cancel-search ' + (searchText !== '' ? ' enabled' : '')}
                        role='button' aria-label='cancel search'
                        onClick={() => setSearchText('')}
                    >
                        <i className='fas fa-times-circle'/>
                    </span>
                    
                </div>

                {groupings.length > 1 ? <div className='grouping-selector'>
                    <span className='label1'>
                        Group by:
                    </span>
                    {groupings.map(groupingName => {
                        return <span key={groupingName + '.'} className={'grouping-button' + (groupingName == selectedGrouping ? ' sel' : '')} onClick={() => {
                            setSelectedGrouping(groupingName)
                        }}>
                            {groupingName ? selectionTypes?.[groupingName]?.label : "All"}
                        </span>
                    })}
                </div> : null}

                {/* {
                    this.state.multiSelect ? //&& this.state.sels && this.state.sels.length >= 1 ? 
                    (<div className='content-selected'>
                        <label>{!this.state.sels ? '?' : this.state.sels.length === 1 ? this.state.sels.length + ' item' : this.state.sels.length + ' items'} selected</label>
                        {this.state.sels && this.state.sels.length > 0 ?
                            <button onClick={() => this.state.sels.forEach(i => this.props.onSelect(i))}>Clear</button>
                            : null}
                    </div>) : ''
                } */}

            </div>

            <div className="scrolling-content">
                {loadingItems || loadingGroupingItems ? <div className='loading'>loading...</div> :
                    displayGroups
                        ?.filter(dgroup => dgroup.items?.length > 0)
                        ?.map((dgroup, dgroupIndex) => 
                        <React.Fragment key={dgroup.label + '.' + dgroupIndex}>
                            {dgroup.all ? null : <div className="group-label">
                                {dgroup.label}
                            </div>}
                            <div className="items">
                                    {dgroup.items?.map(item => {
                                        
                                    const selected = selection?.[selectionName]?.uid == item.uid
                                    //: (this.state.sel && item.uid === this.state.sel.uid)
                                    return (
                                        <SelectionBlock
                                            key={item.id}
                                            study_folder={studyFolder}
                                            imageProperty = {selectionType.imageProperty}
                                            {...item}
                                            typeLabel={item?.selectionTypeLabel || selectionType?.label}
                                            selected={selected}
                                            //ref={selected ? selectedBoxRef : null}
                                            showImage={selectionType?.showImage}
                                            onClick={() => {
                                                let x = item
                                                handleClick(x)
                                                setSearchText('')
                                            }}
                                        />
                                    )
                                })}
                            </div>
                        </React.Fragment>
                    )
                }
            </div>


            <div className='bottom text-center' >
                <button  className="action-button" onClick={CloseSelector} role='button' aria-label='close'>close</button>
            </div>

        </div>
    )
}

