import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as globals from '../../globals';
import { actionCreators as postsActionCreators } from '../../store/Posts.ts';
import { actionCreators as storiesActionCreators } from '../../store/Stories.ts';
import { InsightCard } from '../card/InsightCard';
import { Avatar } from '../common/Avatar';
import { ExecutiveSummary } from '../widgets/ExecutiveSummary';
import { QuestionCover } from '../widgets/QuestionCover';
import './FeedPost.scss';
import { StoryComments } from './StoryComments';
import { StoryContent } from './StoryContent';

export class FeedPost extends React.Component {

    constructor(props){
        super(props);

    }

    componentDidMount(){
        this.checkData();
    }

    componentDidUpdate(){
        this.checkData();
    }

    checkData = () => {
        const postId = this.props.postId;
        if( postId && !this.props.post ){
            this.props.loadPost(postId);
        }

        const storyId = this.props.post ? this.props.post.storyId : null;
        if( storyId && !this.props.story ){
            this.props.loadStory(storyId);
        }
        
    }

    render() {

        let studyId = this.props.match.params.studyId;
        let pageChannelId = this.props.match.params.channelId;

        // var d = this.props.data;
        // if (d == null) return 'error';
        const post = this.props.post;
        if( !post ) return 'no post';
        if( post.error ) return 'post error';

        const story = this.props.story;
        if( !story ) return 'no story';
        if( story.error ) return 'story error';

        const cardIds = story.cards || [];
        const storyCards = cardIds.map(cardId =>
            <InsightCard 
                inFeed={true}
                //expanded={true}
                key={cardId}
                studyId={studyId}
                //widgetId={card.widget_uid}
                //repoEntry={entry}
                cardId={cardId}
                //{...card.state}
                //comment={card.comment}
                //tags={card.tags}
            />
        );
        

        // // get the story's insight cards
        // const storyCards = (story.cards || []).map(card => {
        //     const entry = widgetRepo.find(e => e.uid === card.widget_uid);
        //     return <InsightCard 
        //             inFeed={true}
        //             expanded={true}
        //             key={card.uid}
        //             studyId={studyId}
        //             widgetId={card.widget_uid}
        //             repoEntry={entry}
        //             cardId={card.uid}
        //             {...card.state}
        //             comment={card.comment}
        //             tags={card.tags}
        //         />;
            
        // })
        const study_folder = (this.props.study || {}).folder;
        // create the cover cards if not blank
        const image = ((story||{}).primarySelection||{}).image;
        const imageUrl = image ? `${globals.cdnUrl}/cdn-shared/${study_folder}/images/${image}` : null;
        const photo = ((story||{}).primarySelection||{}).photo;
        const photoUrl = photo ? `${globals.cdnUrl}/cdn-shared/${study_folder}/images/${photo}` : null;

        const storyCardsEx = [
            image || photo || story.question ? 
                <QuestionCover
                    image={imageUrl}
                    photo={photoUrl}
                    selectionLabel={((story||{}).primarySelection||{}).label}
                    question={story.question}
                /> : null
            // story.headline || story.content ?
            //     <ExecutiveSummary
            //         headline={story.headline}
            //         content={story.content}
            //     /> : null
            ,
            ...storyCards
        ].filter(x => x);

        const channelId = post.channel; 
        const channel = channelId; // todo: get channel label rather than just id

        return (!this.props.showFavorites || this.props.story.isFavorite) ? (
            <div className={`feed-post`}>

                <div className='header'>
                    <Avatar uid={post.authorId} mini={true} />
                    <div className='author'>
                        {post.author}
                    </div>
                    <div className='timestamp'>
                        Posted {moment.utc(post.created).local().fromNow()} in <span className='channel'>{channel}</span>
                    </div>
                    <div className='buttons'>
                        <Link className='btn' to={pageChannelId ? `/study/${studyId}/feed/${pageChannelId}/post/${post.uid}` : `/study/${studyId}/feed/post/${post.uid}`}>
                            <i className='fal fa-expand'/>
                        </Link>
                    </div>
                </div>

                <StoryContent className='content' cards={storyCardsEx} />

                <StoryComments className='comments' storyId={story.uid} postId={post.uid} maxNbComments={3} collapseComments={true}/>

            </div>
        ) : null;
    }
}

FeedPost = withRouter(
    connect(
        (state, ownProps) => {
            const post = state.posts && state.posts.dict ? state.posts.dict[ownProps.postId] : null;
            const story = post && post.storyId && state.stories && state.stories.dict ? state.stories.dict[post.storyId] : null;
            return {
                post,
                story,
                study: state.study
            }
        },
        dispatch => bindActionCreators({...postsActionCreators, ...storiesActionCreators}, dispatch)
    )(FeedPost)
);
