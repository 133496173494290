import * as React from "react";
import { connect, useSelector } from "react-redux";
import {
	CartesianGrid,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
	Legend,
} from "recharts";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
//import { actionCreators } from '../../store/BigData';
import * as cacheHelper from "../../utils/cacheHelper";
import * as debounce from "../../utils/debounce";
import { CardLoading } from "../card/CardLoading";
import { FilterChooser } from "../filter/FilterChooser";
import { ProductBlockMini } from "../product-drawer/ProductBlockMini";
import { CoreWidget1 } from "./CoreWidget1";
import * as selectionHelper from "../../utils/selectionHelper";
import { dateFormat } from "../../utils/dateFormat";
import CustomLegend from "../common/CustomLegend";

export const UtilsLineChartWidget = (props) => {
	const study = useSelector((state) => state.study);
	if (!study) return null;

	const selections = useSelector((state) => state.selections);
	const selection = selectionHelper.getSelection(props, study, selections);
	const stateFilter = useSelector((state) => state.filter);
	const filter = props.inDataCard ? stateFilter?.selectedFilter : props.filter;

	const timegroups = stateFilter?.filterGroups?.find(
		(fg) => fg.id === "yyyymm",
	);
	if (!timegroups) return "time var not found";

	//const sel2 = selection ? window.meta?.surveyToDcmMap(selection.model) : null;
	//console.log('sel2', sel2);
	if (!selection?.model) return null;

	let yAxisProps = {
		width: 125,
		...props.yAxis,
	};
	const series = props.series || [];

	const renderContent = (cache, getJSONData) => {
		if (!cache) return;
		const data = timegroups.filters.map((tg) => ({
			label: tg.label,
			key: tg.syntax,
			util: cache?.results?.util?.[tg.syntax],
			brand_utility: cache?.results?.util?.[tg.syntax]?.value,
		}));
		if (getJSONData && typeof getJSONData === "function") getJSONData(data);
		return (
			<ResponsiveContainer width={"100%"} height={"100%"}>
				<LineChart data={data} margin={{ right: 10, bottom: 20 }}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis
						dataKey={"label"}
						xtickFormatter={(val) => dateFormat(new Date(val), "mmm dd")}
					/>
					<YAxis {...yAxisProps} hide={true} />
					<Tooltip />
					{/* <Tooltip formatter={ value => Number.isNaN(parseInt(value)) ? '.' : value === 0 ? value : value.toFixed(3)} 
                labelFormatter={value => dateFormat(new Date(value), "mmm dd") } /> */}
					{series.map((ser, idx) => (
						<Line
							key={idx}
							name={ser.label}
							dataKey={ser.key}
							type="monotone"
							{...ser}
							animationDuration={400}
						/>
					))}
					<Legend content={<CustomLegend />} />
				</LineChart>
			</ResponsiveContainer>
		);
	};

	return (
		<CoreWidget1
			{...props}
			showSelection={true}
			useScenario={false}
			useFilter={true}
			render={renderContent}
			useYak={{
				query: {
					measures: [
						{ id: "util", syntax: `avg(util_model_${selection.model})` },
					],
					groups: timegroups.filters.map((f) => f.syntax),
					filters: [filter?.syntax || globals.getDefaultFilter(study).syntax],
				},
			}}
		/>
	);
};
