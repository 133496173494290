import * as React from 'react';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as globals from '../../globals';
import * as apiService from '../../utils/apiService';
import './ContentViewer.scss';
import { ContentViewer } from './ContentViewer';
import * as jsCompiler from '../../utils/jsCompiler';
import { EditModeWrapper } from './EditModeWrapper';
import { ContentLoader } from './ContentLoader';

export const ContentItemPointer = (props) => {

    const item = props.item;
    if (!item) return null;
    
    const idata = item.data;


    return <EditModeWrapper item={item} edit={props.edit} reloadItem={props.reloadItem} reloadParent={props.reloadParentItem}
        pageLevel={props.pageLevel}
    >
        {idata?.src_id ? <ContentLoader id={idata?.src_id} reloadParentItem={props.reloadItem} edit={props.edit} restore={props.restore} restoredItem={item?.deleted} pageLevel={props.pageLevel}
            setLayoutReady={props.setLayoutReady}
        />
            : "[unspecified pointer]"}
    </EditModeWrapper>;

}

