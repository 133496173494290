import { format as d3Format } from "d3-format";
import * as React from "react";
import { connect } from "react-redux";
import {
	Legend,
	Line,
	LineChart,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as forecastActionCreators } from "../../store/Forecast.ts";
import * as debounce from "../../utils/debounce";
import * as selectionHelper from "../../utils/selectionHelper";
import { CardCover } from "../card/CardCover";
import { CardLoading } from "../card/CardLoading";
import { FilterChooser } from "../filter/FilterChooser";
import { ProductBlockMini } from "../product-drawer/ProductBlockMini";
import CustomLegend from "../common/CustomLegend";
import CustomizedTick from "../common/CustomizedTick";

const metrics = globals.metrics;

export class PriceCurvesWidget2 extends React.Component {
	constructor(props) {
		super(props);

		// local widget settings
		this.state = {
			metric: this.props.metric || "share",
		};

		// Allow card to be saved as insight card. Not the greatest pattern.
		this.props.stateContext.getSaveState = this.getSaveState;
	}

	componentDidMount() {
		//this.refreshIfNeeded();
	}

	componentDidUpdate() {
		//this.refreshIfNeeded();
	}

	// these are needed from parent state
	static inheritOptions = ["configEtag"];

	static scenarioDriven = true;

	// returns widget's local settings (for save state)
	getSaveState = () => {
		const selectedProductId = this.props.selectedProductId;
		if (!selectedProductId) return null;

		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);

		// get the cache
		const fullCache = this.props.fullCache;
		const key = `${filter.syntax}:curves:${this.props.att}:${selectedProductId}`;
		const cache = fullCache?.[key];

		return {
			selectedProductId,
			filter,
			metric: this.props.metric || this.state.metric,
			cache: cache,
		};
	};

	refresh = () => {
		this.refreshIfNeeded();
	};

	refreshIfNeeded = debounce.debounce(() => {
		// cancel if insight card
		if (this.props.inInsightCard) return;

		// cancel if no selection
		const selectedProductId = this.props.selectedProductId;
		if (!selectedProductId) return;

		// cancel if config isn't ready
		if (!this.props.etag) return;

		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);

		// cancel if cached data already exists, with matching filter and etag
		let cache = this.props.cache;
		if (!cache) {
			const fullCache = this.props.fullCache;
			const key = `${filter.syntax}:curves:${this.props.att}:${selectedProductId}`;
			cache = fullCache?.[key];
		}

		if (cache && cache.etag === this.props.etag) return;

		// cancel if cached data is loading
		if (cache && cache.loading) return;

		// refresh is needed. request it.
		this.props.requestAux(
			"curves",
			filter.syntax,
			`curves:${this.props.att}:${selectedProductId}`,
			{
				target_product: selectedProductId,
				target_attribute: this.props.att,
			},
		);
	}, debounce.forecastDelay);

	render() {
		const selectedProductId = this.props.selectedProductId;
		const selectedProduct = selectedProductId
			? selectionHelper.getSelectionInfo(
					"product",
					selectedProductId,
					this.props.study,
					this.props.selections?.hierarchy,
			  )
			: null;

		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);
		const attName = this.props.att;
		const att = this.props.study.config.productDefs.default.atts.find(
			(a) => a.name === attName,
		);
		if (!att)
			return <div><center>{`att ${attName} missing`}</center></div>;
		
		const attFormat = d3Format(att.format);

		let cache = this.props.inInsightCard ? this.props.cache : null;

		if (!cache) {
			const fullCache = this.props.fullCache;
			const key = `${filter.syntax}:curves:${this.props.att}:${selectedProductId}`;
			cache = fullCache?.[key];
		}
		let cacheValue = cache?.value;

		let data = null;
		if (cacheValue) {
			data = (cacheValue.data || []).map((el) => ({
				[attName]: el[attName],
				...el[selectedProductId],
			}));
			//console.log(data);
			//.filter((el, idx) => idx < 20)
		}

		const selMetric = metrics.find((m) => m.key === this.state.metric);

		const maxEntry = data
			? data.reduce((acc, el) => {
					if (!acc.max || acc.max < el[selMetric?.key]) {
						return {
							max: el[selMetric?.key],
							input: el[attName],
						};
					} else return acc;
			  }, {})
			: null;
		const maxInput = maxEntry ? maxEntry.input : null;
		const maxOutput = maxEntry ? maxEntry.max : null;

		// const filterCache = (cache || {})[filter.syntax];
		// let productCache = (filterCache || {})[this.props.selectedItem];

		const expired =
			this.props.inDataCard && this.props.etag !== (cache || {}).etag;
		const loading = cache && cache.loading;
		if (this.props.loaded && typeof this.props.loaded === "function")
			this.props.loaded(!loading);

		const format = selMetric?.fformat;
		if (this.props.getJSONData && typeof this.props.getJSONData === "function")
			this.props.getJSONData(data);

		const renderTick = (e) => {
			let customVal = {
				...e,
				payload: { ...e.payload, value: format(e.payload.value) },
			};
			return <CustomizedTick {...customVal} />;
		};

		

		const content = !cacheValue ? null : (
			<ResponsiveContainer>
				<LineChart data={data}>
					<XAxis
						dataKey={attName}
						type="number"
						domain={["minValue", "maxValue"]}
						tickFormatter={attFormat}
					/>
					<YAxis type="number" tickFormatter={format} tick={renderTick} />
					<Tooltip formatter={format} labelFormatter={attFormat} />
					<Legend content={<CustomLegend />} />
					{/* <ReferenceLine x={maxInput} stroke="red" label={`Max ${att.label}: ${attFormat(maxInput)}`}/> */}
					<ReferenceLine
						x={maxInput}
						stroke="red"
						xlabel={`Max ${att.label}: ${attFormat(maxInput)}`}
					/>
					<ReferenceLine
						y={maxOutput}
						stroke="#cfcfcf"
						strokeDasharray="3 3"
						label={`Max ${selMetric?.label}: ${format(maxOutput)}`}
					/>
					<Line
						type="monotone"
						dataKey={selMetric?.key}
						name={selMetric?.label}
						stroke={globals.colors.primary1}
						dot={false}
						activeDot={{ r: 4 }}
					/>
				</LineChart>
			</ResponsiveContainer>
		);

		return (
			<div className="widget">
				<div className="widget-header">
					{this.props.title ? (
						<div className="title">{this.props.title}</div>
					) : null}
					<div className="selection">
						<ProductBlockMini
							{...selectedProduct}
							disabled={this.props.inInsightCard}
							allowChange={["product"]}
						/>
					</div>
					<div className="filter-etc">
						<FilterChooser
							mini={true}
							disabled={this.props.inInsightCard}
							selection={filter}
						/>
					</div>
					{this.props.inDataCard && (
						<select
							value={this.state.metric}
							onChange={(ev) => this.setState({ metric: ev.target.value })}>
							{metrics.map((m) => (
								<option key={m.key} value={m.key}>
									{m.label}
								</option>
							))}
						</select>
					)}
					{this.props.inInsightCard && (
						<label className="ml-1">
							{metrics.filter((i) => i.key === this.state.metric)[0].label}
						</label>
					)}
				</div>
				<div className="widget-body">
					{content}
					{!selectedProductId ? (
						<CardCover message="no selection" />
					) : loading ? null : expired ? (
						<CardCover button="update" buttonClicked={() => this.refresh()} />
					) : null}
				</div>
				<CardLoading loading={loading} />
			</div>
		);
	}
}

PriceCurvesWidget2 = connect(
	(state, ownProps) => ({
		etag: state.study?.config?.etag,
		study: state.study,
		selections: state.selections,
		...(ownProps.inDataCard
			? {
					filter: (state.filter || {}).selectedFilter,
					fullCache: state.cache?.forecast,
					selectedProductId: state.selections?.product?.uid,
			  }
			: {}),
	}),
	(dispatch) => bindActionCreators(forecastActionCreators, dispatch),
)(PriceCurvesWidget2);
