import * as global from '../globals';
import * as apiService from '../utils/apiService';
// @ts-ignore
import { CHANNELS_RECEIVE, CHANNELS_REQUEST, STUDY_RESET } from './action-types';

export interface ChannelsState{

    /** array of all channels that exist in study and who has access to each channel (is this secure?) */
    data: ChannelInfo[];

    /** true if channel arrays (data, read, write) are loading */
    loading: boolean;

    /** true if channel arrays (data, read, write) are loaded */
    loaded: boolean;

    /** channels that authenticated user has read access to */
    read: ChannelInfo[];

    /** channels that authenticated user has write access to */
    write: ChannelInfo[];

    /** error loading channels if any */
    error?: any;

}

interface ChannelInfo{
    key: string;
    label: string;
    users: ChannelUser[];
}

interface ChannelUser{
    uid: string;
    name: string;
    readOnly: boolean;
}

const initialState: ChannelsState = {
    data: null,
    loading: false,
    loaded: false,
    read: null,
    write: null
};

export const actionCreators = {

    loadChannels: (callback?: any) => async (dispatch: any, getState: any) => {
        try {
            const state = getState();
            if( state.channels && state.channels.loading ) return; // cancel
            
            //console.log('loadChannels');
            dispatch({ type: CHANNELS_REQUEST });
            
            const studyId = state.study.uid;
            if( !studyId ){
                dispatch({ type: CHANNELS_RECEIVE, channels: [], error: 'no study' });
                return;
            }

            const response = await apiService.aGet(state.auth, `${global.apiRoot}/study/${studyId}/channel-users`);

            if( response.ok ){
                const channels = await response.json();

                const read = channels.filter((ch: any) => ch.users.find((u: any) => u.uid === state.auth.user.id));
                const write = channels.filter((ch: any) => ch.users.find((u: any) => u.uid === state.auth.user.id && !u.readOnly));
                
                dispatch({ type: CHANNELS_RECEIVE, channels, read, write });
                if( callback ) callback();
            }
            else{
                dispatch({ type: CHANNELS_RECEIVE, channels: [], error: response.statusText });
            }

        } catch (error) {
            console.error(error);
            dispatch({ type: CHANNELS_RECEIVE, channels: [], error });
        }
    }

};

export const reducer = (state: ChannelsState, action: any): ChannelsState => {
    state = state || initialState;

    switch (action.type) {

        case STUDY_RESET : return initialState;

        case CHANNELS_REQUEST: {
            return {
                ...state,
                loading: true
            };
        }

        case CHANNELS_RECEIVE: {
            return {
                ...state,
                loading: false,
                loaded: true,
                data: action.channels,
                read: action.read,
                write: action.write,
                error: action.error
            };
        }

        default:
            return state;
    }
};
