import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as globals from '../../globals';
import { GENERAL_CARD_INFO_VISIBLE_SET } from '../../store/action-types.ts';
import * as apiService from '../../utils/apiService';
import * as debounce from '../../utils/debounce';
import { BreadCrumb } from '../common/BreadCrumb';
import { Markdown } from '../common/Markdown';
// import { PageHeader2 } from '../common/PageHeader';
import { GetCardsInContainer } from '../explore/CardContainer';
import { getChildLink, getStack } from '../explore/ExploreUtils';
import { DynamicWidget } from '../widgets/DynamicWidget';
import { getRepo } from '../widgets/_widgetRepo';
import './CardPage.scss';
import { DataCard } from './DataCard';
import { InsightCard } from './InsightCard';



const getSiblingCardsOnPage = (parentContent, widget_uid) => {
    // find cardContainers on page
    const cardContainers = parentContent?.contents?.filter(m => m.type === "card-container");
    if( !cardContainers ) return null;
    for( let i = 0; i < cardContainers.length; i++ ){
        const cardContainer = cardContainers[i];
        const cards = GetCardsInContainer(cardContainer?.data);
        const fi = cards.findIndex(c => c.uid === widget_uid);
        if( fi != -1 ){
            const prevCard = fi > 0 ? cards[fi - 1] : null;
            const nextCard = fi < cards.length - 1 ? cards[fi+1] : null;
            return { prevCard, nextCard, index: fi, count: cards.length };
        }
    };
    return null;
}


export const _getBreadCrumbElements = (study_uid, params) => {
    let breadCrumbElements = [];
    const stack = getStack(params);
    breadCrumbElements.push({ label: 'Explore', link: stack.length > 0 ? `/study/${study_uid}/explore` : null });
    for(let i = 0; i < stack.length; i++ ){
        const arr = stack.slice(0,i+1);
        const link = `/study/${study_uid}/explore/` + arr.join('/');
        let el = { label_id: stack[i], link };
        breadCrumbElements.push(el);
    }
    return breadCrumbElements;
}


const saveWidgetInfo = debounce.debounce((auth, study_uid, id, body) => {
    const response = apiService.aPatch(auth, `${globals.apiRoot}/datacard/${id}`, body)
        .then(() => {
            console.log('updated');
        })
        .catch((err) => {
            console.error(response);
        })
}, 1000);


export const CardPage = (props) => {

    const study = useSelector(state => state.study);

    const [rTab, setRTab] = useState('info');
    const [tab, setTab] = useState('card');
    const [editMode, setEditMode] = useState(false);
    
    const [parentContent, setParentContent] = useState(null);
    const [card, setCard] = useState(null);
    const [widget, setWidget] = useState(null);
    const [description, setDescription] = useState(null);
    const [tags, setTags] = useState(null);
    const [sources, setSources] = useState(null);
    const [cardTitle, setCardTitle] = useState(null);
    const stories = useSelector(state => state.stories);
    const [parentStory, setParentStory] = useState(null);

    const auth = useSelector(state => state.auth);
    const general = useSelector(state => state.general);
    const isSuperAdmin = auth?.user?.isSuperAdmin;
    const history = useHistory();
    
    const params = useParams();
    const dispatch = useDispatch();
    

    if( !study ) return null;

    const config = study.config;
    if( !config ) return null;

    
    const cardId = params.cardId;
    const isInsightCard = cardId ? true : false;
    const isDataCard = !isInsightCard;
    const widget_uid = isDataCard ? params.widgetId : card?.widget_uid;
    const parentContentId = isDataCard ? params.id : null;
    const storyId = params.storyId;

    // load parent content
    useEffect(() => {
        if( !parentContentId ) return;
        apiService.aGet(auth, `${globals.apiRoot}/study/${study.uid}/content2/${parentContentId}`)
            .then((res) => res.json())
            .then((res) => {
                //console.log('loaded container', res);
                setParentContent(res);
            })
            .catch((err) => console.error(err));
    }, [parentContentId])

    // load containing story info
    useEffect(() => {
        if( !storyId ) return;
        apiService.aGet(auth, `${globals.apiRoot}/study/${study.uid}/story/${storyId}`)
            .then(res => res.json())
            .then(res => {
                setParentStory(res);
                //console.log('parentStory', res);
            })
            .catch(err => console.error(err))
    }, [storyId])

    // load the card (if insights card)
    useEffect(() => {
        if( !cardId ) return;
        const url = `${globals.apiRoot}/study/${study.uid}/card/${cardId}`;
        apiService.aGet(auth, url)
            .then((res) => res.json())
            .then((res) => {
                setCard(res);
            })
            .catch((err) => console.error(err));
    }, [cardId])
    
    // load the widget
    useEffect(() => {
        if (!widget_uid) return;
        if (widget_uid === 'DynamicWidget') {
            
            // datacard (widget) doesn't exist. this is the dynamicwidget
        }
        else {
            const url = `${globals.apiRoot}/study/${study.uid}/datacard/${widget_uid}`;
            apiService.aGet(auth, url)
                .then((res) => res.json())
                .then((res) => {
                    setWidget(res);
                    setDescription(res?.description);
                    setTags(res?.tags);
                    setSources(res?.sources);
                })
                .catch((err) => console.error(err));
        }
    }, [widget_uid]);

    const saveWidgetChanges = (body) => {
        saveWidgetInfo(auth, study.uid, widget_uid, body);
    }
    
    const toggleCardInfoVisibility = () => 
        dispatch({ type: GENERAL_CARD_INFO_VISIBLE_SET, visible: !general?.cardInfoVisible });


    // this can either be an insight card or a data card
    // a data card has no attached post or story
    // an insight card has an attached story (if in story draft)
    // an insight card MIGHT have an attached post (if posted)
    //const study = useSelector(state => state.study);
    
    // we may not have a post
    // but we'll always have a story (if insight card), because it's either a post or a draft

    // if insight card, should pull the card and the widget key from that
    if (isInsightCard && !card) return 'loading card...';
    
    // console.log('card here', card);

    // study uid
    let entry = null;
    if( !card?.is_dynamic ){
        const repo = getRepo(study.uid);
        entry = repo.find(entry => entry.uid === widget_uid)
        if (!entry) return 'unknown widget ' + widget_uid;
    }
    else {
        entry = {
            entry: {
                uid: cardId,
                props: {
                    title: card.dynamic_item?.title, // todo: add support for {exprs} in title
                    subTitle: card.dynamic_item?.subTitle, // todo: add support for {exprs} in subtitle
                    data: card.dynamic_item?.data
                }
            },
            widget: DynamicWidget
        }
    }

    // console.log('entry', entry);

    //const selection = card?.cache?.selection;
        
    let pageState = {
    //     selection, // shouldn't need this anymore I think
    };

    const wState = entry ? {
        //...this.getRelevantState(),
        //selection,
        ...(card || {}).state,
        ...entry.props
    } : null;


    const cardStoryIdx = parentStory?.cards?.findIndex(m => m == cardId);
    const storyPrevCard = parentStory?.cards ? parentStory.cards[cardStoryIdx - 1] : null;
    const storyNextCard = parentStory?.cards ? parentStory.cards[cardStoryIdx + 1] : null;
    //console.log('prev, next', storyPrevCard, storyNextCard, parentStory?.cards?.length);


    //const area = this.props.match.params.area;

    const breadCrumbElements = [];

    switch( props.match.path ){
        case '/study/:studyId/explore/:parent3id/:parent2id/:parentid/:id/card/:widgetId' :
        case '/study/:studyId/explore/:parent2id/:parentid/:id/card/:widgetId' :
        case '/study/:studyId/explore/:parentid/:id/card/:widgetId' :
        case '/study/:studyId/explore/:id/card/:widgetId' :
        case '/study/:studyId/explore/card/:widgetId' : {

            const siblings = getSiblingCardsOnPage(parentContent, widget_uid);
            const { prevCard, nextCard } = siblings || {};
            const bc_els = _getBreadCrumbElements(study.uid, params);
            bc_els.forEach(el => breadCrumbElements.push(el));
            breadCrumbElements.push({ label: widget?.title,
                prev: siblings?.prevCard ? { label: prevCard.props?.title, link: getChildLink(params, `card/${prevCard.uid}`, study.uid) } : null,
                next: nextCard ? { label: nextCard.props?.title, link: getChildLink(params,  `card/${nextCard.uid}`, study.uid) } : null,
                index: siblings?.index,
                count: siblings?.count
            });
            break;
        }
        case '/study/:studyId/feed/:channelId/post/:postId/story/:storyId/card/:cardId' : {
            const { channelId } = params;
            const channel = channelId; // todo: get label
            breadCrumbElements.push({ label: channel, link: `/study/${study.uid}/feed/${channelId}` });
            breadCrumbElements.push({ label: 'POSTED STORY', link: `/study/${study.uid}/feed/${channelId}/post/${params.postId}` });
            breadCrumbElements.push({ label: wState.title || 'Card',
                prev: storyPrevCard ? { label: "", link: `/study/${study.uid}/feed/${channelId}/post/${params.postId}/story/${params.storyId}/card/${storyPrevCard}` } : null,
                next: storyNextCard ? { label: "", link: `/study/${study.uid}/feed/${channelId}/post/${params.postId}/story/${params.storyId}/card/${storyNextCard}` } : null,
                index: cardStoryIdx,
                count: parentStory?.cards?.length
            });
            break;
        }
        case '/study/:studyId/feed/post/:postId/story/:storyId/card/:cardId' : {
            breadCrumbElements.push({ label: 'ALL CHANNELS', link: `/study/${study.uid}/feed` });
            breadCrumbElements.push({ label: 'POSTED STORY', link: `/study/${study.uid}/feed/post/${params.postId}` });
            breadCrumbElements.push({ label: wState.title || 'Card',
                prev: storyPrevCard ? { label: "", link: `/study/${study.uid}/feed/post/${params.postId}/story/${params.storyId}/card/${storyPrevCard}` } : null,
                next: storyNextCard ? { label: "", link: `/study/${study.uid}/feed/post/${params.postId}/story/${params.storyId}/card/${storyNextCard}` } : null,
                index: cardStoryIdx,
                count: parentStory?.cards?.length
            });
            break;
        }
        case '/study/:studyId/edit-story/:storyId/card/:cardId' : {
            // viewing an expanded card in a story draft
            breadCrumbElements.push({ label: 'MY STORY DRAFTS', action: () => window.topBar.openMenu('channels') });
            const story = (stories?.dict || {})[props.match.params.storyId] || {};
            breadCrumbElements.push({ label: story.label || 'STORY', link: `/study/${study.uid}/edit-story/${props.match.params.storyId}` });
            breadCrumbElements.push({ label: wState.title || 'Card',
                prev: storyPrevCard ? { label: "", link: `/study/${study.uid}/edit-story/${params.storyId}/card/${storyPrevCard}` } : null,
                next: storyNextCard ? { label: "", link: `/study/${study.uid}/edit-story/${params.storyId}/card/${storyNextCard}` } : null,
                index: cardStoryIdx,
                count: parentStory?.cards?.length
            });
            break;
        }
        case '/study/:studyId/what-if/:sectionId/card/:widgetId' : {
            breadCrumbElements.push({ label: 'What If', link: `/study/${study.uid}/what-if/${props.match.params.sectionId}` });
            breadCrumbElements.push({ label: wState.title || 'Card' });
            break;
        }
    }
    
    const instanceContext = null; // todo
    const instanceIndex = -1; // todo

    //<PageHeader2 title={<BreadCrumb elements={breadCrumbElements}/>} fullWidth={true}/>

    return <div className={"page card-page"}>

        <div className={'content card-page-content ' + (general?.cardInfoVisible ? "info-is-expanded" : "info-is-collapsed")}>
            <div className={'card-area ' + (tab === 'card' ? ' sel' : '')}>
                <div className='card-space'>
                    {isInsightCard ?
                        <InsightCard
                            key={entry.uid + ';' + storyId + ';' + cardId}
                            studyId={study.uid}
                            widgetId={widget_uid}
                            //repoEntry={entry}
                            cardId={cardId}
                            expanded={true}
                            editInsights={props.editInsights}
                            //comment={(insightCard || {}).comment}
                            //tags={(insightCard || {}).tags}
                            //{...insightCardState}
                        />
                        :
                        <DataCard
                            key={entry.uid} 
                            studyId={props.studyId}
                            widgetId={entry.uid}
                            repoEntry={entry}
                            editMode={editMode}
                            {...entry.props}
                            {...pageState} 
                            isolated={true}
                            optionsContext={this}
                            hideControls={false}
                            instanceContext={instanceContext}
                            instanceIndex={instanceIndex}
                            location={props.location}
                        />
                    }
                </div>

            </div>

            <div className="card-info-toggle" onClick={toggleCardInfoVisibility}>
                <button className="card-toggle-btn toggle-btn" type="button" role='button'>
                    <i className={"far " + (general?.cardInfoVisible ? "fa-chevron-right" : "fa-chevron-left")}>&nbsp;</i>
                </button>
            </div>
            
            <div className='card-info-pane'>

                <div className='fixed'>
                    
                    <h2>Card Info</h2>
                    {auth.user.isSuperAdmin ?
                        <span className='btn-toggle-editmode' onClick={() => setEditMode(!editMode)}>
                            <i className={editMode ? 'far fa-times' : 'far fa-pencil'}/>
                        </span> : null
                    }
                </div>

                <div className='scrolling'>

                {
                    editMode ? <div className='prop'>
                        <label>Title:</label><br />
                        <input className='title' defaultValue={widget?.title} type='text' tabIndex='0' placeholder='Enter a title...' onChange={(e) => {
                            let newTitle = e.target.value.trim();
                            setCardTitle(newTitle);
                            saveWidgetChanges({ title: newTitle });
                        }} />
                    </div> : null
                }

                    <div className='prop'>
                        <label>Sources:</label>
                        <ul className='sources-list'>
                            {sources?.map((src, idx) => 
                                <li key={idx} className={'source-item' + (editMode ? ' delete-icon' : '')}
                                    onClick={(ev) => {
                                        if( editMode ){
                                            const newSources = sources.filter((item, sidx) => sidx != idx);
                                            setSources(newSources);
                                            saveWidgetChanges({ sources: newSources });
                                        }
                                    }}
                                >
                                    {src}
                                </li>
                            )}
                            {editMode ? 
                                <li className='new-source-item'>
                                    <input type='text' tabIndex='0' placeholder='Add new source...' onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === 'Tab' ) {
                                            let newSources = [...(sources||[]), e.target.value.trim()];
                                            setSources(newSources);
                                            e.target.value = ''; // reset the text box for the next new source
                                            saveWidgetChanges({ sources: newSources });
                                        }
                                    }} />
                                </li>
                                : null
                            }
                        </ul>
                    </div>

                    <div className='prop'>
                        <label>Tags:</label>
                        <div>
                            {tags?.map((tag, idx) => 
                                <span key={idx} className={'tag' + (editMode ? ' delete-icon' : '')} 
                                    onClick={(ev) => {
                                        if( editMode ){
                                            const newTags = tags.filter((item, fidx) => fidx != idx);
                                            setTags(newTags);
                                            saveWidgetChanges({ tags: newTags });
                                        }
                                    }}
                                >
                                    {tag}
                                </span>
                            )}
                            {editMode ? 
                                <input type='text' tabIndex='0' placeholder='Add new tag...' onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === 'Tab' || /\s+/g.test(e.key)) {
                                        let tagValue = e.target.value.trim().startsWith('#')? e.target.value.trim().replace(/#{1,}/g, '') : e.target.value.trim();
                                        let newTags = [...(tags||[]), tagValue];
                                        setTags(newTags);
                                        e.target.value = ''; // reset the text box for the next new tag
                                        saveWidgetChanges({ tags: newTags });
                                    }
                                }} />
                                : null
                            }
                        </div>
                    </div>

                    <div className='prop description'>
                        <label>Description:</label>
                        {editMode ?
                            <textarea 
                                className='edit-description'
                                value={description || ""}
                                onChange={(ev) => {
                                    setDescription(ev.target.value);
                                    saveWidgetChanges({
                                        description: ev.target.value
                                    });
                                }}
                            />
                            : <Markdown data={description} />
                        }
                        
                    </div>

                </div>

                <div className='footer'>

                    <div className={name}>
                        <h2>Next Steps</h2>
                        <div className='prop'>
                            <label>view related cards</label>;&nbsp; 
                            <label>load scenario</label>;&nbsp; 
                            <label>create story</label>
                        </div>
                        
                    </div>
                    
                </div>

            </div>

        </div>

    </div>;


    

}
