import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, Route, Switch, useParams } from 'react-router';
import { useHistory, Redirect } from 'react-router-dom';
import { LoginNotification } from './vis/LoginNotification';
import * as metaUtils from '../utils/metaUtils.js';

//import { ThemeContext } from '../theme';
import './Layout.scss';
//import { NonStudy } from './NonStudy';
import { Study } from './Study';
import { TopBar } from './topbar/TopBar';
import { StudyLoader } from './StudyLoader';
// import { version } from '../../package.json';

const version = require('../../package.json')


const versionToInt = (ver) => {
    // e.g. v1.7.24 --> 1,007,024
    if (!ver) return null;
    
    const str = ver; // ver.substring(1); // remove the 'v' (doesn't have a v!)
    const arr = str.toString().split('.')
    return (arr[0] * 1000000) + (arr[1] * 1000) + (arr[2] * 1);
}


export function Layout(props) {

    const [showNotification, setShowNotification] = useState(false);
    const studies = useSelector(state => state.auth?.studies);
    const user = useSelector(state => state.auth?.user);
    const history = useHistory();
    const webclient_version = useSelector(state => state.auth?.webclient_version);
    const auth = useSelector(state => state.auth);

    /** Gets studyid from url and returns it if it also exists in props.studies */
    const getValidStudy = () => {
        // get study from url
        const match = matchPath(history.location.pathname, {
            path: '/study/:studyId/',
            strict: false
        });
        let studyId = match != null && match.params != null ? match.params.studyId : null;

        // if studyId is not contained in study access list, clear studyId
        if (
            studies == null ||
            studies.length === 0 ||
            !studies.map(x => x.uid).includes(studyId)
        ) {
            studyId = null;
        }

        // get study object
        let study = studyId != null ? studies.find(x => x.uid === studyId) : null;

        return study;
    }

    /** returns true if userbar should be shown; false otherwise */
    const getTopBarVisibility = () => {

        // get study from url
        const match = matchPath(history.location.pathname, {
            path: '/:a/:b/',
            strict: false
        });
        if (match == null || match.params == null) return false;

        let a = match.params.a;
        if (
            a === 'sign-in' ||
            a === 'sign-out' ||
            a === 'forgot-password' ||
            a === 'sign-up'
        )
            return false;

        return user != null;
    }


    let study = getValidStudy();
    let showTopBar = true;

    // console.log('study getvalidstudy()', study);

    const changeNotification = (val) => {
        setShowNotification(val);
    }

    const StudyRoute = () => {
        const { studyId } = useParams()

        if (studyId === 'undefined' || !study) {
            return <Redirect to={'/studyloader'} />
        }

        return <Study uid={study?.uid} />
    }

    
    console.log('loaded version', version?.version, versionToInt(version?.version));
    console.log('latest version available on server', webclient_version, versionToInt(webclient_version));

    return (
        <div style={props.style} className={'App layout ' + (showTopBar ? ' has-top-bar' : '')}>
            {showTopBar  ? <TopBar study={study} /> : null}
            {showNotification ? <LoginNotification showNotification={changeNotification}  /> : <>

                <Switch>
                    <Route path="/study/:studyId" component={StudyRoute} />
                    <Route component={StudyLoader} />
                </Switch>
                {versionToInt(version?.version) < versionToInt(webclient_version) ?
                    <div className='webclient-notification'>
                        <b>
                            A newer version of BO is available. Please click <span className='callout'>browser refresh button</span> to download latest version.<br />
                        </b>
                    </div>
                    : null
                }
        </>
            }
            </div>
            
    );
}





