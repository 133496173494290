import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as globals from '../../globals'
import {  actionCreators } from '../../store/Config.ts'
import './ProductBlock.scss'

export const ProductBlock = (props) => {
    const history = useHistory()
    const study = useSelector(state => state.study)
    const dispatch = useDispatch()

    const handleClick = () => {
        if (props.onClick) {
            props.onClick(props)
        }
    }

    const handlePrimaryClicked = (ev) => {
        if (props.onPrimaryClick) {
            props.onPrimaryClick(props)
        }
        ev.stopPropagation()
    }

    const commaJoin = (elements) => {
        if (!elements) return null
        if (elements.length === 1) return elements[0]
        const subElements = elements.slice(0, elements.length - 1)
        return subElements.join(', ') + ' or ' + elements[elements.length - 1]
    }

    const handleDeleteClicked = (ev) => {
        if (props.onDelete) {
            props.onDelete(props)
        }
        ev.stopPropagation()
    }

    const resetProduct = (product) => {
        dispatch(actionCreators.resetPlay(product))
    }

    const studyId = study?.uid
    const study_folder = study?.folder

    let selTypes = []
    if (study && study.selectionHierarchy) {
        study.selectionHierarchy
            .filter(att => !props.allow || props.allow.includes(att.name))
            .forEach(att => selTypes.push(att.label))
    }
    if (!props.allow || props.allow.includes('product')) {
        selTypes.push(study.productLabel)
    }
    const selLabel = commaJoin(selTypes)

    const dirty = props.dirty
    const image = props.image || "_"

    return (
        <div
            className={
                (props.className ? ' ' + props.className + ' ' : '') +
                'product-block' +
                (props.standalone ? ' standalone' : '') +
                (props.selected ? ' sel' : '') +
                (props.isPrimary ? ' primary' : '') +
                (image ? ' has-image' : '') +
                (props.disabled ? ' disabled' : '') +
                ((props.type === 'product' && !props.available) ? ' not-available' : '')
            }
            style={props.style}
            onClick={handleClick}
        >
            {image && (
                <div className="logo-container">
                    {props.type === 'product' && !props.available ? (
                        <div className='not-available-text'>Not<br />Available</div>
                    ) : (
                        image !== '_' && (
                            <img
                                src={`${globals.cdnUrl}/cdn-shared/${study_folder}/images/${image}`}
                                alt={props.label}
                                className="logo"
                            />
                        )
                    )}
                </div>
            )}
            <div className='text'>
                <div className="text1">
                    {props.label ? props.label : `Click to select ${selLabel}`}
                </div>
                <div className="text2">
                    {props.typeLabel}
                    {/* {dirty && (
                        <span
                            className='dirty-bit'
                            title='Reset'
                            onClick={(ev) => {
                                if (props.allow[0] === 'product') {
                                    resetPlay(props.product)
                                }
                                ev.stopPropagation()
                            }}
                        >
                            <i className='fas fa-circle' />
                        </span>
                    )} */}
                </div>
            </div>
            {props.showPrimary && (
                <div
                    className={'primary' + (props.isPrimary ? ' sel' : '')}
                    onClick={handlePrimaryClicked}
                >
                    {props.isPrimary ? <i className='fas fa-star'/> : <i className='fal fa-star'/>}
                </div>
            )}
            {props.allowDelete && (
                <div className='delete-button' onClick={handleDeleteClicked}>
                    <i className='fal fa-times'/>
                </div>
            )}
        </div>
    )
}



