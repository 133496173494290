import * as widgetRepo from "../components/widgets/_widgetRepo";
import * as globals from "../globals";
import * as apiService from "../utils/apiService";
import * as signalR from '@aspnet/signalr';
import * as metaUtils from '../utils/metaUtils'
import { guid } from "../utils/guid";
import {
	COMPARE_GROUPS_RECEIVE,
	COMPARE_GROUP_SELECT,
	COMPARE_SET,
	EXPLORE_SET_SELECTION,
	FILTER_SET,
	FILTER_GROUPS_RECEIVE,
	SELECTION_SET,
	SELECTION_SET_MULTI,
	SELECTION_SET_MULTIPLE,	
	STUDY_CHANNEL_USERS_RECEIVE,
	STUDY_CHANNEL_USERS_REQUEST,
	STUDY_PIN_BRAND,
	STUDY_RECEIVE,
	STUDY_RESET,
	STUDY_SET_PRIMARY_SELECTION,
	STUDY_SET_WIDGET,
	DCM_CONFIG_INIT,
	CONFIG_SET_VIEW,
	DCM_UPDATE_CONFIG, 
	DCM_SET_ACTIVE_CONFIG,
	FILTER_SELECT
	// @ts-ignore
} from "./action-types";
// @ts-ignore
import { actionCreators as channelActionCreators } from "./Channels";
// @ts-ignore
import * as DCM from "./DCM";
// @ts-ignore
import { actionCreators as filterActionCreators } from "./Filter";
// @ts-ignore
 import { actionCreators as selectionsActionCreators } from "./Selections";
// @ts-ignore
import { actionCreators as notifyActionCreators } from "./Notify";
// @ts-ignore
import { actionCreators as dcmActionCreators } from './DCM'
// @ts-ignore

declare var studyScripts: any; // trying to appease typescript. loadJS should create it.

export interface StudyState {
	uid: string | null;
	label: string | null;
	isLoading: boolean;
	etag: string | null;
	subscriptionLabel: string | null;
	scenarioVersion: number | null;
	scenarioCompatibilityVersion: number | null;
	assumptionsId: string | null;
	baseCaseId: string | null; // not used yet
	profileVars: any;
	productLabel: string | null;
	selectionHierarchy: any[] | null;
	folder: string | null;
	// move somewhere else:
	researchScenarios: any;
	config: any;
	error?: any;
	// obsolete??
	channelUsers?: any;
	brands?: any;
	modules: any[] | null;
	studySpecificItems: any;
	defaultFilterLabel: string;
	modelNames: string[] | null;
}

const initialState: StudyState = {
	uid: null,
	label: null,
	isLoading: false,
	etag: null,
	subscriptionLabel: null,
	scenarioVersion: null,
	scenarioCompatibilityVersion: null,
	assumptionsId: null,
	baseCaseId: null,
	profileVars: null,
	productLabel: null,
	selectionHierarchy: null,
	folder: null,
	researchScenarios: null,
	config: null,
	modules: null,
	studySpecificItems: null,
	defaultFilterLabel: "Total Population",
	modelNames: null
}

declare global {
	interface Window {
		loadedCustomScripts: any;
		loadJS: any;
	}
}

declare global {
    interface Window {
        mainHub:any;
    }
}

export const actionCreators = {
	recycleStudy: (studyId: any) => async (dispatch: any, getState: any) => {
		const state: any = getState();
		try {
			const response: any = await apiService.aGet(
				state.auth,
				`${globals.apiRoot}/study/${studyId}/recycle`,
			);
			// console.log(await response.text());
			// console.log('recycle study');
			
			await actionCreators.loadStudy(studyId)(dispatch, getState);
		} catch (err) {
			console.error(err);
		}
	},

	/* loads the label, product definitions, base config */
	loadStudy: (studyId: any) => async (dispatch: any, getState: any) => {

		//dispatch({ type: STUDY_REQUEST, value: studyId });
		dispatch({ type: STUDY_RESET, studyId });

		const state: any = getState();

		try {
			//load study data from api
			const response: any = await apiService.aGet(
				state.auth,
				`${globals.apiRoot}/study/${studyId}/definition`,
			);
			
			//get USER defaults for selections, filters, etc
			const user_resp: any = await apiService.aGet(
				state.auth,
				`${globals.apiRoot}/users/${state.auth.user?.uid}/${studyId}/user_defaults`,
			)

			let user_defaults = null;
			if (user_resp.ok && user_resp.status !== 204) {
				user_defaults = await user_resp.json();
			}	
			
			const data: any = await response.json();	
			
			let study: any = { ...data };//new obj copy of data
			const filterGroups: any = study.filterGroups;
			delete study.filterGroups;

			const cGroups: any = study.cGroups;
			delete study.cGroups;

			const defaults: any = user_defaults ? user_defaults : study.defaults;//do we have defaults property??
			delete study.defaults;				

			// const selections: any = study.selections;
			// delete study.selections;

			// const selection: any = study.selection;
			// delete study.selection;

			const __compare: any = study.compare;
			delete study.compare;


			// const filt_def = await metaUtils.getMeta(studyId, state.auth, 'filter_defaults')
			// study.filter.selectedFilter = filt_def ? filt_def : study.filter.selectedFilter 
				

			// load study's custom (study or project resources)
			// const customFileResponse: any = await apiService.aGet(
			// 	state.auth,
			// 	`${globals.apiRoot}/study/${studyId}/resource/custom.js`,
			// );

			// let custom_cards: any = null;
			// if (customFileResponse.ok) {
			// 	const customFile: any = await customFileResponse.json();
			// 	custom_cards = customFile.cards;

			// 	if (!window.loadedCustomScripts) window.loadedCustomScripts = [];

			// 	// if(!window.loadedCustomScripts.includes(customFile.key)){
			// 	// window.loadedCustomScripts.push(customFile.key);

			// 	// there's got to be an easier way to write this.
			// 	// just doing this to await the loadJS callback
			// 	// NOTE!! I do in fact need to await it so that the dispatches further below aren't called until contraints are added
			// 	await (async () =>
			// 		new Promise((resolve) => {
			// 			window.loadJS(customFile.src, () => {
			// 				resolve(null);
			// 			});
			// 		}))();

			// 	studyScripts?.sayHi?.();
			// 	studyScripts?.addConstraints?.(study);

			// 	// }
			// }

			// initialize widget repo
			// widgetRepo.init(study, custom_cards);

			dispatch({ type: STUDY_RECEIVE, value: study})//sets selections, filters, etc
			dispatch({ type: FILTER_GROUPS_RECEIVE, filterGroups })
			dispatch({ type: COMPARE_GROUPS_RECEIVE, folders: cGroups })
			dispatch({ type: SELECTION_SET_MULTIPLE, setObj: user_defaults?.selection ? user_defaults.selection : study?.selection })

			//set default product for forecaster/opt
			// let forecast_selected = user_defaults ? user_defaults.selection[study?.productLabel.toLowerCase()] : study.defaults.selection[study?.productLabel.toLowerCase()]
			// //theselection needs to have uid, productname...from study.config.productslots
			// forecast_selected = study.config.productSlots.find((prod: any) => parseInt(prod.uid.slice(4)) == forecast_selected.id)
			// //why does this cause infinite loop?
			// dispatch({ type: SELECTION_SET, key:'product', value: forecast_selected });


			//this should set the dcm 'selections' to the explore 'selection'
			// //gets called too many times (setSelection)
			 await selectionsActionCreators?.setSelection('product', user_defaults?.selections?.product ? user_defaults.selections?.product : study?.selections?.product, false)(dispatch, getState)

			// hack to select cgroups
			dispatch({
				type: COMPARE_GROUP_SELECT,
				folderId: "total",
				groupId: "1==1",
				selected: true,
			});
			//dispatch({ type: COMPARE_GROUP_SELECT, folderId: "gen", groupId: "s5==3 or s5==10", selected: true });

			if (__compare?.selection?.selectGroups) {
				__compare.selection.selectGroups.forEach((sg: any) => {
					dispatch({
						type: COMPARE_GROUP_SELECT,
						folderId: sg.folderId,
						groupId: sg.groupId,
						selected: true,
					});
				});
			} else {
				dispatch({
					type: COMPARE_GROUP_SELECT,
					folderId: "gen",
					groupId: "s5==3 or s5==10",
					selected: true,
				});
			}

		

			//legacy?
			try {
				const selection = data?.other?.explore?.selection;
				if (selection) {
					dispatch({ type: EXPLORE_SET_SELECTION, selection })
				}
			} catch (err) {
				console.error(err)
			}

			// load channels
			channelActionCreators.loadChannels()(dispatch, getState)

			// load sample size
			filterActionCreators.refreshSampleSizes()(dispatch, getState)

			// load optimization runs
			//notifyActionCreators.getOptRuns()(dispatch, getState);

			// connect to hub

			
			// const connection = window.mainHub;
			// connection.invoke("Subscribe", studyId);

			//get meta for dcm in dcmModel entry if exists
			// const cfg = await metaUtils.getMeta(studyId, state.auth, 'dcmModel')
			// if (cfg !== null) {
			// 	dispatch({ type: DCM_CONFIG_INIT, data: study, configModels: cfg?.configModels })
			// 	dispatch({ type: DCM_SET_ACTIVE_CONFIG, activeConfig: cfg.configModels[0] })
				
			// 	dispatch(dcmActionCreators.applyMultiBasecases()) 				
			// }


		} catch (err) {
			dispatch({
				type: STUDY_RECEIVE,
				value: { uid: studyId },
				error: "error fetching data: " + err,
			});
		}
	},

	getStudyChannelUsers: (studyId: string) => async (
		dispatch: any,
		getState: any,
	) => {
		dispatch({ type: STUDY_CHANNEL_USERS_REQUEST, studyId });

		const state: any = getState();

		try {
			const response: any = await apiService.aGet(
				state.auth,
				`${globals.apiRoot}/study/${studyId}/channel-users`,
			);

			const channels: any = await response.json();

			dispatch({ type: STUDY_CHANNEL_USERS_RECEIVE, channels });
		} catch (err) {
			console.log(err);
			dispatch({
				type: STUDY_CHANNEL_USERS_RECEIVE,
				channels: [],
				error: "error fetching data",
			});
		}
	},

	setPrimarySelection: (selType: any, selUid: any) => (dispatch: any) => {
		dispatch({ type: STUDY_SET_PRIMARY_SELECTION, selType, selUid });
	},

	setWidget: (uid: any, state: any, editInsights: any) => (dispatch: any) => {
		dispatch({ type: STUDY_SET_WIDGET, uid, state, editInsights });
	},

	storeDefaults: () => async (dispatch: any, getState: any) => {
		//	dispatch({ type: STUDY_SET_DEFAULTS, studyId, defaults })
		const state: any = getState();

		const study = state.study;
		if (!study) throw 'missing study';

		const studyId = study.uid;
		let body: any = {
			selections: {
				...state.selections
			},
			selection: {
				...state.selection
			},
			filter: {
				selectedFilter: state.filter?.selectedFilter,
				selections: state.filter.filterGroups.map((folder: any) => {
					return {
						folderId: folder.id,
						selectedFilterIds: folder.filters.filter((f: any) => f.isSelected)
							.map((f: any) => f.id || f.syntax)
					};
				})
					.filter((fg: any) => fg.selectedFilterIds?.length > 0)
			},
			compare: {
				selection: state.compare?.selection,
				selections: state.compare.folders.map((folder: any) => {
					return {
						folderId: folder.id,
						selectedFilterIds: folder.groups.filter((f: any) => f.isSelected)
							.map((f: any) => f.id || f.syntax)
					}
				})
					.filter((folder: any) => folder.selectedFilterIds?.length > 0)
			}
		};
		delete body.selections.hierarchy;
		
		if (confirm("Are you sure you want to store defaults for ALL USERS? (review in console log)")) {
			try {
				const response: any = await apiService.aPost(
					state.auth,
					`${globals.apiRoot}/study/${studyId}/defaults`,
					body
				);
				console.log(response);
				await actionCreators.loadStudy(studyId)(dispatch, getState);
			
			} catch (err) {
				console.log(err);
			
			}
		}

	},

	//filters: Filters

storeUserDefaults: () => async (dispatch: any, getState: any) => {
	const state: any = getState();
	
		const user_uid = state.auth.user?.uid;
		const study = state.study;
		if (!study) throw 'missing study';
	

		const studyId = study.uid;
		let body: any = {
			[study.uid]: {
				selections: {
					...state.selections
				},
				selection: {
					...state.selection
				},
				filter: {
					selectedFilter: state.filter?.selectedFilter,
					selections: state.filter.filterGroups.map((folder: any) => {
						return {
							folderId: folder.id,
							selectedFilterIds: folder.filters.filter((f: any) => f.isSelected)
								.map((f: any) => f.id || f.syntax)
						};
					})
						.filter((fg: any) => fg.selectedFilterIds?.length > 0)
				},
				compare: {
					selection: state.compare?.selection,
					selections: state.compare.folders.map((folder: any) => {
						return {
							folderId: folder.id,
							selectedFilterIds: folder.groups.filter((f: any) => f.isSelected)
								.map((f: any) => f.id || f.syntax)
						}
					})
						.filter((folder: any) => folder.selectedFilterIds?.length > 0)
				}
			}
		}
		
		if (confirm("This will set your user defaults throughout the tool based on the current configuration, are you sure?")) {
			try {

				const response: any = await apiService.aPost(
					state.auth,
					`${globals.apiRoot}/users/${user_uid}/${studyId}/user_defaults/save`,
					body
				);
				console.log(response);
				await actionCreators.loadStudy(studyId)(dispatch, getState);
			
			} catch (err) {
				console.log(err);
			
			}
		}

	},


deleteUserDefaults: () => async (dispatch: any, getState: any) => {
	const state: any = getState();
	
		const user_uid = state.auth.user?.uid;
		const study = state.study;
		if (!study) throw 'missing study';	
		const studyId = study.uid;
	
		
		if (confirm("This will DELETE your user defaults for this study, are you sure?")) {
			try {

				const response: any = await apiService.aDelete(
					state.auth,
					`${globals.apiRoot}/users/${user_uid}/${studyId}/user_defaults/delete`
				)
				await actionCreators.loadStudy(studyId)(dispatch, getState);
			
			} catch (err) {
				console.log(err);
			
			}
		}

	}

}

	

//export const _ = obj => obj || {}; // since javascript doesn't have elvis operator

export const reducer = (state: StudyState, action: any): StudyState => {
	state = state || initialState;

	switch (action.type) {
		case STUDY_RESET: {
			// unsubscribe from old study
			if (state.uid) {
				//window.mainHub.invoke("unsubscribe", state.uid, "channelXYZ", "mytoken");
			}

			return {
				...initialState,
				uid: action.studyId,
				label: null,
				//data: null, // what is data? (no longer used I hope)
				isLoading: true,
				error: null,
			};
		}

		case STUDY_RECEIVE: {
			//console.log('received Study ', action.value);
			let s = {
				...initialState,
				...action.value,
				defaultFilterLabel:
					action.value?.defaultFilterLabel || initialState.defaultFilterLabel,
				isLoading: false,
				// config: {
				// 	selectedItem: null, // action.value.config.productSlots[0].uid,
				// 	selectedArea: "product",
				// 	showHints: false,
				// 	showBase: false,
				// 	assumptionsId: action.value.assumptionsId,
				// 	baseCaseId: action.value.baseCaseId,
				// 	// ...action.value.config,
				// 	etag: guid(),
				// },
				error: action.error,
			}

			s.modelNames = s.models?.map((m : any) => m.name) || [];

			//remove items
			delete s.config
			delete s.models

			// subscribe to study
			if (s.uid) {
				//window.mainHub.invoke("subscribe", s.uid, "channelXYZ", "mytoken");
			}

			return s;
		}

		case STUDY_CHANNEL_USERS_REQUEST: {
			return {
				...state,
				channelUsers: {
					channels: [
						...(state.channelUsers ? state.channelUsers.channels : null || []),
					],
					loading: true,
					loaded: false,
				},
			};
		}

		case STUDY_CHANNEL_USERS_RECEIVE: {
			return {
				...state,
				channelUsers: {
					channels: action.channels,
					loading: false,
					loaded: true,
					error: action.error,
				},
			};
		}

		case STUDY_PIN_BRAND: {
			// sloppy. had to move this out of config reducer. need to organize this.
			return {
				...state,
				brands: state.brands.map((brand: any) =>
					brand.uid === action.brandUid
						? { ...brand, pinned: action.value }
						: brand,
				),
			};
		}

		case STUDY_SET_PRIMARY_SELECTION: {
			return state;
			// no longer used (i think)
			// return {
			//     ...state,
			//     primarySelection: {
			//         type: action.selType,
			//         uid: action.selUid
			//     }
			// };
		}

		case STUDY_SET_WIDGET: {
			return state;
			// no longer used (i think)
			// return {
			//     ...state,
			//     widget: {
			//         uid: action.uid,
			//         state: action.state,
			//         editInsights: action.editInsights
			//     }
			// };
		}

		default:
			state;
	}

	// hack until I conform all the other nested reducers
	// {
	// 	if (action.type.startsWith("DCM_")) {
	// 		const newConfig = DCM.reducer(state.config, action);
	// 		if (newConfig !== state.config)
	// 			return {
	// 				...state,
	// 				config: newConfig,
	// 			};
	// 	}
	// }
	

	return state
}
