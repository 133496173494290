import * as React from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as globals from "../../globals";
import * as apiService from "../../utils/apiService";
import * as debounce from "../../utils/debounce";
import "./ElementPropertyEditor.scss";
import { TabStrip2, Tab } from "../common/TabStrip";
import Editor from '@monaco-editor/react';


export const CodePropertyEditor = (props) => {

	const auth = useSelector((state) => state.auth);
	const study_uid = useSelector((state) => state.study?.uid);
	const [dirtyCode, setDirtyCode] = useState(false);

	const prop = props.prop;
	const item = props.item;
	const inData = prop?.name?.startsWith("data.");
	const localName = inData ? prop.name?.substring(5) : prop?.name || "error";
	const currentValue = inData ? item?.data?.[localName] : item?.[localName];
	const isJson = prop.json;


	const [value, setValue] = useState(
		isJson ? JSON.stringify(currentValue) : currentValue,
	);
	if (!prop || !item) return null;

	const updateContentItem = props.updateContentItem;

	const valueChanged = (newValue, reload) => {
		setValue(newValue);
		setDirtyCode(true);
		let saveValue = newValue;
		
		const model = inData
			? {
				data: {
					[localName]: saveValue,
				},
				mergeData: true,
			}
			: {
				[localName]: saveValue,
			};
		
		updateContentItem(auth, study_uid, item.id, model, () => {
			setDirtyCode(false);
			if (reload) {
				//reload();
			}
		}); // is this debounced? i think so.
	};

	

	return (
		<div className="code-property-editor">
			<div className="code-prec">
				{dirtyCode ? <i className="fas fa-circle" style={{ color: 'orangered' }} /> : <i className='fal fa-circle' style={{ color: '#afafaf' }} />}
				&nbsp;
				{prop.codePrec}
			</div>
			<Editor height={prop.editorHeight} language={props.language || "javascript"} value={value || ""} theme='vs-dark'
				onChange={(value) => valueChanged(value, props.reloadItem)}
				/>
			<div className="code-post">{prop.codePost}</div>
		</div>
	);
};

