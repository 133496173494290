import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators as modelActionCreators } from '../../store/Model.ts'
import * as metaUtils from '../../utils/metaUtils'
import './CloneDialog.scss'


export const CloneDialog = (props) => {

    const studyId = useSelector(state => state.study?.uid)
    const auth = useSelector(state => state.auth)    
    const model = useSelector(state => state.model)
    const selectionTypes = useSelector(state => state.study?.selectionTypes)
    const selectionType = props?.settings?.selectionName
    const activeModel = props?.settings?.activeModel
    const dispatch = useDispatch()

    const [prodToClone, setProdToClone] = useState(null)
    const [cloneProdName, setCloneProdName] = useState('')
    const [items, setItems] = useState([])
    const [loadingItems, setLoadingItems] = useState(false)


    useEffect(() => {
        window.cloneDialog = this
    }, []) 


    useEffect(() => {
        console.log('items.length useEffect')

        if (items?.length > 0) {
            setProdToClone(items[0].uid || items[0].id)
        }
    }, [items])

    useEffect(() => {

        const getSelectionType = async () => {

            const metaKey = selectionTypes?.[selectionType]?.meta
            if (!metaKey) return

            const metaKeys = [metaKey]//, ...autoSelect?.map(e => e.meta)]
            setLoadingItems(true)
            try {
                let metaTable = await metaUtils.getMetas(studyId, auth, metaKeys)
                // console.log('metatable', metaTable)
                setItems(metaTable?.[metaKey]) //table with selection data in meta
                // setAllItems(obj) // contains info for autoSelect

                // check selected grouping
                // if (!selectedGrouping || selectionType?.groupings?.includes(selectedGrouping)) {
                //     //console.log('selected grouping okay')
                // }
                // else {
                //     //console.log('selected grouping not found for type')
                //     setSelectedGrouping(null)
                // }                    
            }
            catch (err) {
                console.error(err)
            }
            setLoadingItems(false)

        }

        getSelectionType()

    }, [selectionType])


    // Ensure cloned items are only added once
    useEffect(() => {
        const clonedProds = model?.[activeModel]?.config?.play.filter(f => f.isClone) || []

        clonedProds.forEach(cl => {
            const prod_cloned_from = items.find(f => f.uid === cl.cloneOf)
            if (prod_cloned_from) {
                const newProd = {
                    ...prod_cloned_from,
                    uid: cl.uid,
                    label: cl.label,
                    image: 'clone.png'
                }
               
            }
        })
    }, )

    const cloneButton = async () => {
        if (prodToClone && cloneProdName) {
            // console.log('Cloning Product!!!')

            //need to add product for each years models
            const years = ['2024', '2025', '2026']
            
            years.forEach(async (yr) => {
                let active_model = activeModel.replace(/\d{4}/, yr) 
                console.log('active_model', active_model)
                await dispatch(modelActionCreators.cloneProduct(active_model, prodToClone, cloneProdName))
            })

            // setCloneToggle(!cloneToggle)
            
              if (props.onBtnClick) {
                props.onBtnClick()
            }
            
        } else {
            console.log(
                'Please select a product to clone and provide a new product name.'
            )
        }
    }

    const closeDialog = () => {
        window.topBar.hideMenu()
    }

    return (
        <div className='prod-clone-dialog'>
             <button className='close-btn' onClick={closeDialog}>
                <i className='fas fa-times-circle'></i>
            </button>
            <div className='title'>Add a Product</div>
            <div className='subtitle'>Select a product to use as a template and give your product a new name</div>
            <input
                id='prod-to-clone'
                type='text'
                placeholder='Name'
                onChange={ev => setCloneProdName(ev.target.value)}
            />
            <select id='clone-selector' onChange={ev => setProdToClone(ev.target.value)} >
                {items?.map(option => (
                    <option
                        key={option?.uid || option?.id}
                        value={option?.uid || option?.id}
                    >
                        {option?.label}
                    </option>
                ))}
            </select>
            <button
                id='clone-btn'
                className='clone-btn'
                style={{ width: 50, height: 23, padding: 2 }}
                onClick={cloneButton}
            >
                Add
            </button>
        </div>
    )
}