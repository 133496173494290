import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { useParams } from "react-router-dom";
import * as globals from "../../globals";
import * as apiService from "../../utils/apiService";
import { Tile } from "../common/Tile";
import { TileContainer } from "../common/TileContainer";
import "./ContentViewer.scss";
import { EditModeWrapper } from "./EditModeWrapper";
import { getStudyLink, getChildLink, getContentLink } from "./ExploreUtils";
import { wrapAsPage } from "./PageContentViewer";
import * as jsCompiler from "../../utils/jsCompiler";

export const TileContainerElement = (props) => {
	const id = props.item?.id;
	const { editMode, setEditMode } = props.edit || {};

	const study_uid = useSelector((state) => state.study?.uid);
	const auth = useSelector((state) => state.auth);

	const reduxStore = useStore();
	const study = useSelector((state) => state.study); // totally sucks to need entire study, since study contains way to many things that change

	const [item, setItem] = useState(props.item);
	const [instances, setInstances] = useState(null);
	const [error, setError] = useState(null);
	const [reloadCounter, setReloadCounter] = useState(0);
	const reloadSelf = () => {
		console.log("reload tilecontainer called");
		setReloadCounter(reloadCounter + 1);
	};

	//const tileContent = item?.contents;

	const params = useParams();


    useEffect(() => {
        if (item){// && instances?.length > 0) {
            //console.log(`tilecontainer ${item.id} layoutsReady = ` + layoutsReady + ' of ' + item.contents?.length)
            ///if (layoutsReady == item.contents?.length) {
                //console.log(`tilecontainer ${item.id} layout is ready`);
                props.setLayoutReady?.(true);
            //}
        }
    }, [item]);

	// get content
	useEffect(() => {
		if (!study_uid) return;
		if (!id) return;

		var queryparams = [];
        if (props?.restore?.restoreMode) {
            queryparams.push("showDeleted=true");
        }
        if (props.edit?.editMode) {
            queryparams.push("editMode=true");
        }

		apiService
			.aGet(
				auth,
				`${globals.apiRoot}/study/${study_uid}/content3/${id}${queryparams.length > 0 ? "?" + queryparams.join("&") : ""
				}`,
			)
			.then((res) => res.json())
			.then((res) => setItem(res))
			.catch((err) => console.error(err));
	}, [id, reloadCounter]);

	useEffect(() => {
		if (!item) return;

		const getInstancesSyntax = item?.data?.instances;
		if (!getInstancesSyntax || getInstancesSyntax.trim() === "") {
			setInstances([{}]);
			return;
		}

		try {
			const getInstances = jsCompiler.compileGetInstances(getInstancesSyntax);
			const context = jsCompiler.getDefaultContext(study, auth, reduxStore);
			const lib = jsCompiler.defaultLib;
			getInstances(context, lib)
				.then((res) => setInstances(res))
				.catch((err) => {
					setError("error running getInstances: " + err);
					console.log("error running getInstances:", err);
				});
		} catch (err) {
			setError(err);
			console.log(err);
		}
	}, [item?.data?.instances]);

	if (error) {
		return error;
	}

	if (!instances) {
		return "loading...";
	}

	const wide = item?.data?.wide === "true";
	const style = {
		margin: item?.data?.margin || (wide ? "0 20px" : "0 auto 20px auto"),
	};
	//console.log('tilecontainer.wide', wide);

	const content = (
		<EditModeWrapper
			className="has-children"
			item={item}
			pageLevel={props.pageLevel}
			reloadItem={reloadSelf}
			reloadParentItem={props.reloadParentItem}
			edit={props.edit}
			restoredItem={props?.restoredItem}>
			<div
				key={reloadCounter}
				className={"content-section " + (wide ? "wide-width" : "page-width")}
				style={style}>
				{!props.asPage && item?.title ? (
					<div className="title">{item?.title}</div>
				) : null}
				<TileContainer
					edit={props.edit}
					restore={props.restore}
					item={item}
					tileSpacing={item?.data?.tileSpacing}
					tileSize={item?.data?.tileSize}>
					{instances?.map((instance, instance_idx) => {
						return item?.contents?.map((citem) => (
							<EditModeWrapper
								className="for-tile"
								key={instance_idx + ":" + citem.id}
								item={citem}
								pageLevel={props.pageLevel + 1}
								edit={props.edit}
								restore={props.restore}
								restoredItem={citem?.deleted}
								mini={true}
								reloadParentItem={reloadSelf}
								reloadItem={reloadSelf}>
								<Tile
									key={citem.id}
									label={citem.title}
									icon={citem.data?.icon}
									tileBackground={
										citem?.data?.tileBackground || item?.data?.tileBackground
									}
									tileBackgroundImage={citem?.data?.tileBackgroundImage}
									disable={citem?.data?.disable}
									tooltip={citem?.data?.tooltip}
									tileForeground={
										citem?.data?.tileForeground || item?.data?.tileForeground
									}
									tileRadius={citem?.data?.tileRadius || item?.data?.tileRadius}
									tileHeight={citem?.data?.tileHeight || item?.data?.tileHeight}
									tileWidth={citem?.data.tileSize || item?.data?.tileSize}
									tileMargin={item?.data?.tileMargin}
									contentMarkdown={citem?.data?.contentMarkdown}
									to={citem?.data?.hrefLink ? getStudyLink(citem.data.hrefLink, study_uid) : getContentLink(params, citem.id, study_uid)}
									commands={citem?.data?.commands}
									noDrag={!editMode}
									{...instance}
								/>
							</EditModeWrapper>
						));
					})}

					{item?.contents?.length ? null : (
						<span style={{ opacity: 0.01 }}>empty</span>
					)}
				</TileContainer>
			</div>
		</EditModeWrapper>
	);

	return props.asPage
		? wrapAsPage(
			props.item,
			content,
			null,
			study_uid,
			params,
			props.edit,
			props.restore,
			reloadSelf,
		)
		: content;
};
