import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { guid } from '../../utils/guid';
import './AddCardWidget.scss';

export class AddCardWidget extends React.Component{

    constructor(props){
        super(props);

        this.state = {
        }
    }

    static inheritOptions = [];

    addCard = () => {
        this.props.addTextCard( [guid()] );
    }

    
    render() {

        const studyId = this.props.match.params.studyId;

        const editMode = this.props.editMode;

        return <div className='widget add-card-widget'>
            <div className='widget-body'>

                <div className='instruction'>
                    Add new card from:
                </div>

                <div className='nav-buttons'>
                
                    <Link className='add-nav-button' to={`/study/${studyId}/explore`}>
                        <span className='glyph'>
                            <i className='fas fa-map'/>
                        </span>
                        <span className='label'>
                            Explore
                        </span>
                    </Link>

                    <div className='add-nav-button' onClick={this.addCard}>
                        <span className='glyph'>
                            <i className='fas fa-plus'/>
                        </span>
                        <span className='label'>
                            Add Text Card
                        </span>
                    </div>

                    {/* <Link className='add-nav-button' to={`/study/${studyId}/what-if`}>
                        <span className='glyph'>
                            <i className='fas fa-sliders-h'/>
                        </span>
                        <span className='label'>
                            What-if
                        </span>
                    </Link> */}

                    {/* <Link className='add-nav-button' to={`/study/${studyId}/optimize`}>
                        <span className='glyph'>
                            <i className='fas fa-running'/>
                        </span>
                        <span className='label'>
                            Optimize
                        </span>
                    </Link> */}

                    <a className='add-nav-button' href={`mailto:support@blueowlai.com`}>
                        <span className='glyph'>
                            <i className='fas fa-shipping-fast'/>
                        </span>
                        <span className='label'>
                            Request<br/>a card
                        </span>
                    </a>

                </div>
                
            </div>
        </div>;

    }

}


AddCardWidget = withRouter(AddCardWidget);
