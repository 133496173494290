import React from "react"
import {useSelector} from 'react-redux'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
	Line,
	ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts"

import { FilterChooser } from '../filter/FilterChooser'

const toyota = [
  { name: "Wave 1", date: "Wk Mar 15", actual: 3.162, },
  { name: "Wave 2", date: "Wk Mar 22", actual: 3.058, },
  { name: "Wave 3", date: "Wk Mar 29", actual: 3.14, },
  { name: "Wave 4", date: "Wk Apr 5", actual: 3.089, },
  { name: "Wave 5", date: "Wk Apr 12", actual: 3.181, },
  { name: "Wave 6", date: "Wk Apr 19", actual: 2.997, },
  { name: "Wave 7", date: "Wk Apr 26", actual: 2.909, predicted: 3.021 },
  { name: "Wave 8", date: "Wk May 3", actual: 3.011, predicted: 2.987 },
  { name: "Wave 9", date: "Wk May 10", actual: 3.062, predicted: 2.948 },
  { name: "Wave 10", date: "Wk May 17", actual: 2.84, predicted: 2.905 },
  { name: "Wave 11", date: "Wk May 24", predicted: 2.858 },
  { name: "Wave 12", date: "Wk May 31", predicted: 2.806 },
  { name: "Wave 13", date: "Wk Jun 7", predicted: 2.75 },
  { name: "Wave 14", date: "Wk Jun 14", predicted: 2.689 },
  { name: "Wave 15", date: "Wk Jun 21" },
  { name: "Wave 16", date: "Wk Jun 28" },
  { name: "Wave 17", date: "Wk Jul 5" },
  { name: "Wave 18", date: "Wk Jul 12" },
  { name: "Wave 19", date: "Wk Jul 19" },
  { name: "Wave 20", date: "Wk Jul 26" }
],
lexus = [
	{ name: "Wave 1", date: "Wk Mar 15", actual: 3.239, },
  { name: "Wave 2", date: "Wk Mar 22", actual: 3.096, },
  { name: "Wave 3", date: "Wk Mar 29", actual: 3.297, },
  { name: "Wave 4", date: "Wk Apr 5", actual: 3.230, },
  { name: "Wave 5", date: "Wk Apr 12", actual: 3.138, },
  { name: "Wave 6", date: "Wk Apr 19", actual: 3.052, },
  { name: "Wave 7", date: "Wk Apr 26", actual: 3.055, predicted: 3.065 },
  { name: "Wave 8", date: "Wk May 3", actual: 2.920, predicted: 3.027 },
  { name: "Wave 9", date: "Wk May 10", actual: 3.082, predicted: 2.985 },
  { name: "Wave 10", date: "Wk May 17", actual: 2.937, predicted: 2.940 },
  { name: "Wave 11", date: "Wk May 24", predicted: 2.891 },
  { name: "Wave 12", date: "Wk May 31", predicted: 2.840 },
  { name: "Wave 13", date: "Wk Jun 7", predicted: 2.785 },
  { name: "Wave 14", date: "Wk Jun 14", predicted: 2.727 },
  { name: "Wave 15", date: "Wk Jun 21" },
  { name: "Wave 16", date: "Wk Jun 28" },
  { name: "Wave 17", date: "Wk Jul 5" },
  { name: "Wave 18", date: "Wk Jul 12" },
  { name: "Wave 19", date: "Wk Jul 19" },
  { name: "Wave 20", date: "Wk Jul 26" }
],
general = [
	{ name: "Wave 1", date: "Wk Mar 15", actual: 3.115, },
  { name: "Wave 2", date: "Wk Mar 22", actual: 3.01, },
  { name: "Wave 3", date: "Wk Mar 29", actual: 3.216, },
  { name: "Wave 4", date: "Wk Apr 5", },
  { name: "Wave 5", date: "Wk Apr 12", actual: 3.078, },
  { name: "Wave 6", date: "Wk Apr 19", },
  { name: "Wave 7", date: "Wk Apr 26", actual: 3.053, predicted: 3.073 },
  { name: "Wave 8", date: "Wk May 3", predicted: 3.049 },
  { name: "Wave 9", date: "Wk May 10", actual: 3.267, predicted: 3.018 },
  { name: "Wave 10", date: "Wk May 17", predicted: 2.979 },
  { name: "Wave 11", date: "Wk May 24", predicted: 2.933 },
  { name: "Wave 12", date: "Wk May 31", predicted: 2.879 },
  { name: "Wave 13", date: "Wk Jun 7", predicted: 2.818 },
  { name: "Wave 14", date: "Wk Jun 14", predicted: 2.750 },
  { name: "Wave 15", date: "Wk Jun 21", },
  { name: "Wave 16", date: "Wk Jun 28", },
  { name: "Wave 17", date: "Wk Jul 5", },
  { name: "Wave 18", date: "Wk Jul 12", },
  { name: "Wave 19", date: "Wk Jul 19", },
  { name: "Wave 20", date: "Wk Jul 26", }
]

export const PredictTrendline = (props) => {

  const filter = props.filter || useSelector(state => state.filter.selectedFilter)
  
  props.stateContext.getSaveState = () => {
    return { filter };
  };

	var data = []
	if (props.product === 'toyota')
		data = toyota
	else if (props.product === 'lexus')
		data = lexus
	else if (props.product === 'general')
		data = general

  return (
		<div className='widget'>
      <div className='widget-header'>
        <div className='title'>{props.title}</div>
        <div className='filter-etc'>
          <FilterChooser selection={filter} disabled={props.inInsightCard} mini={true} />
        </div>
      </div>
      <div className='widget-body vscroll hscroll no-pad'>
				<ResponsiveContainer>
					<ComposedChart
						// width={1000}
						// height={450}
						data={data}
						margin={{
							top: 20,
							right: 20,
							bottom: 20,
							left: 20
						}}
					>
						<CartesianGrid stroke="#f5f5f5" vertical={false} />
						<XAxis dataKey="date" />
						<YAxis type="number" domain={[2, 'dataMax + 0.05']} minTickGap={2} tickCount={14} />
						<Tooltip />

						<Bar dataKey="actual" barSize={20} fill="#413ea0" />
						<Line type="basis" dataKey="predicted" stroke="#ff7300" />
					</ComposedChart>
				</ResponsiveContainer>
			</div>
		</div>
	)
}
