import './Auth.scss';
import { AuthOuter } from './AuthOuter';
import { useMsal } from '@azure/msal-react';

import React, { useEffect } from 'react';

export const SignOutPage = () => {

    const { instance } = useMsal()
     const myTimer = ms => new Promise(resolve => setTimeout(resolve, ms))   

    const signOff = async () => {
        localStorage.removeItem('auth')
        await myTimer(1000)
        console.log('SIGNING OUT!!!');
        instance.logout({
            postLogoutRedirectUri:'https://app.blueowlai.com'
        })
       
    }

    useEffect(() => {

        signOff()

    }, [])

    return (
        <AuthOuter title="Sign out">
            <div className="fetch-status">
                signing out<span className="blink_cursor">_</span>
            </div>
        </AuthOuter>
    )
}




// export class SignOutPage extends React.Component {
//     wait = ms => new Promise((r, j) => setTimeout(r, ms));
    

//     signOff() {
//         localStorage.removeItem('auth');

//         this.wait(1000).then(() => window.location.reload());
//     }

//     componentDidMount() {
//         this.signOff();
//     }

//     render() {
//         return (
//             <AuthOuter title="Sign out">
//                 <div className="fetch-status">
//                     signing out<span className="blink_cursor">_</span>
//                 </div>
//             </AuthOuter>
//         );
//     }
// }

//const mapStateToProps = (state) => {
//    return {
//        user: state.auth != null ? state.auth.user : null
//    }
//}

//const mapDispatchToProps = (dispatch) => ({
//})

//SignOutPage = withRouter(connect(mapStateToProps, mapDispatchToProps)(SignOutPage));

//SignOutPage = withRouter(connect(
//    state => state.auth,
//    dispatch => bindActionCreators(actionCreators, dispatch),
//)(SignOutPage));
