import './EditMetaPage.scss';
import React, { useEffect, useState } from 'react';
import { PageHeader2 } from '../common/PageHeader';
import { useSelector } from 'react-redux';
import * as apiService from '../../utils/apiService';
import * as globals from '../../globals';
import { SimpleTable } from '../vis/SimpleTable';
import ReactJson from 'react-json-view';
import Editor  from '@monaco-editor/react';
import { EditPalette } from './EditPalette';

export const EditMetaPage = (props) => {

    const study_uid = props.match?.params?.studyId;
    const [metaList, setMetaList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingEntry, setLoadingEntry] = useState(false);
    const [savingEntry, setSavingEntry] = useState(false);
    const auth = useSelector(state => state.auth);
    const [reloadCounter, setReloadCounter] = useState(0);
    const [reloadEntryCounter, setReloadEntryCounter] = useState(0);
    const [selectedKey, setSelectedKey] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [view, setView] = useState('value');
    const [rawType, setRawType] = useState(null);
    const [parentKey, setParentKey] = useState(null);
    const [rawUseMeta, setRawUseMeta] = useState(null);
    const [rawValue, setRawValue] = useState(null);
    const [rawLicValue, setRawLicValue] = useState(null);
    const [expandedEntries, setExpandedEntries] = useState([]);
    

    // get list of meta
    useEffect(() => {
        
        if (!study_uid) return;
        setLoading(true);
                
        apiService.aGet(auth, `${globals.apiRoot}/study/${study_uid}/meta/list`)
            .then(res => {
                return res.ok ? res.json() : null;
            })
            .then(item => {
                setMetaList(item);
                setLoading(false);
            }).catch(err => {
                console.error(err)
                setLoading(false);
            });

    }, [study_uid, reloadCounter]);

    // load selected meta
    useEffect(() => {
        if (!selectedKey) return;

        setLoadingEntry(true);

        const extra = view === 'usage' ? '/relationships'
            //: view === 'edit' ? '?getRaw=1'
            //: '';
            : '?getRaw=1';

        apiService.aGet(auth, `${globals.apiRoot}/study/${study_uid}/meta/key/${selectedKey}${extra}`)
            .then(res => {
                return res.ok ? res.json() : null;
            })
            .then(item => {
                setSelectedValue(item);

                setRawType(item?.rawType || item?.type);
                setRawUseMeta(item?.useMeta);
                setRawValue(item?.rawValue);
                setRawLicValue(item?.rawLicValue);
                setParentKey(item?.parentKey);

                setLoadingEntry(false);
            }).catch(err => {
                console.error(err)
                setLoadingEntry(false);
            });

    }, [selectedKey, reloadEntryCounter, view]);

    const selectEntry = (metaEntry) => {
        //console.log('selectEntry', metaEntry);
        setSelectedKey(metaEntry?.key);
    }

    const revert = (ev) => {
        setReloadEntryCounter(reloadEntryCounter + 1);
    }

    const addEntry = (obj) => {
        const body = {
            ...obj,
            overwrite: false
        }
        let newMetaKey = prompt("Please enter the new meta key", "newKey");
        if (newMetaKey != null) {
            saveBody(newMetaKey, body, () => {
                    setReloadCounter(reloadCounter + 1);
                    setSelectedKey(newMetaKey);
                    setView("edit");
            });
        }
    }

    const saveBody = (key, body, callback) => {

        setSavingEntry(true);
        apiService.aPost(auth, `${globals.apiRoot}/study/${study_uid}/meta/key/${key}`, body)
            .then(res => {
                if (res.ok) {
                    //alert("saved");
                    window.topBar.enqueueSnack({
                        id: 'saved_' + key,
                        message: 'Saved ' + key,
                        to: 3000,
                        success: false
                    })
                    setSavingEntry(false);
                    if (callback) {
                        callback();
                    }
                }
                else {
                    console.error(res.error);
                    setSavingEntry(false);
                }
            })
            .catch(err => {
                console.error(err)
                setSavingEntry(false);
            });

    }

    const save = (ev) => {
        // save selected meta

        const body = {
            type: rawType,
            parentKey: parentKey,
            useMetaString: rawUseMeta,
            valueString: rawValue,
            licValueString: rawLicValue
        };
        saveBody(selectedKey, body);

    }

    const save2 = (ev) => {
        // save selected meta
        
        const body = {
            type: rawType,
            parentKey: parentKey,
            useMetaString: rawUseMeta,
            valueString: rawValue,
            licValueString: rawLicValue
        };
        saveBody(selectedKey, body);

    }

    const toggleMetaEntryExpansion = (key) => {
        const isExpanded = expandedEntries.includes(key);
        if (isExpanded) {
            setExpandedEntries(expandedEntries.filter(f => f !== key));
        }
        else {
            setExpandedEntries([...expandedEntries, key]);
        }
    }

    const deleteEntry = () => {
        if (confirm(`Are you sure you want to delete [${selectedKey}]?`)) {
            apiService.aDelete(auth, `${globals.apiRoot}/study/${study_uid}/meta/key/${selectedKey}`)
            .then(res => {
                if (res.ok) {
                    //alert("deleted");
                    window.topBar.enqueueSnack({
                        id: 'deleted_' + selectedKey,
                        message: 'Deleted ' + selectedKey,
                        to: 3000,
                        success: false
                    })
                    setSelectedKey(null);
                    setSelectedValue(null);
                    setReloadCounter(reloadCounter + 1);
                }
                else {
                    console.error(res.error);
                }
            })
            .catch(err => {
                console.error(err)
            });
        }
    }

    const getIcon = (type, expanded) => {
        return <div className='icon' >{
            (type === 'json') ? <i className='fal fa-brackets-curly' />
                : (type === 'sql') ? <i className='fas fa-database' />
                    : (type === 'pal') ? <i className='fas fa-palette' />
                        : (type === 'folder' && expanded) ? <i className='fal fa-minus-circle' />
                            : (type === 'folder') ? <i className='fal fa-plus-circle' />
                            : <i className='fas fa-question-circle' />
        }</div>;
    }

    //const rawValue = selectedValue?.rawValue || selectedValue?.value;

    const tableRows = Array.isArray(selectedValue?.value) ? selectedValue?.value : null;
    let tableColumns = [];
    // grab cols from first row for now??
    if (view === 'table' && tableRows?.[0]) {
        let allkeys = [];
        tableRows.forEach(row => {
            Object.keys(row).forEach(key => {
                if (!allkeys.includes(key)) {
                    allkeys.push(key);
                    let objType = typeof row[key];
                    if (objType === 'number') {
                        tableColumns.push({ key, label: key, type: "number" });
                    }
                    else if (objType === 'boolean') {
                        tableColumns.push({ key, label: key, type: "boolean" });
                    }
                    else if (objType === 'string') {
                        tableColumns.push({ key, label: key, type: "string", width: 200 });
                    }
                    else if (objType === 'object') {
                        tableColumns.push({ key, label: key + " [obj]", type: "string", width: 300 });
                    }
                    else {
                        tableColumns.push({ key, label: key, type: "string" });
                    }
                }
            })
        })
    }

    //don't delete
    //<PageHeader2 title={<span className='page-title1'>Meta</span>} fullWidth={true} />

    return <div className='page edit-meta-page'>

        <div className='content'>

            <div className='search-section'>

                <span className='btn inline-block' onClick={() => setReloadCounter(reloadCounter+1)}>
                    <i className='fal fa-sync'/> Refresh List
                </span>

                <span className='btn inline-block' onClick={() => addEntry({ type: "folder", valueString: null })}>
                    <i className='fal fa-plus'/> Add Folder
                </span>

                <span className='btn inline-block' onClick={() => addEntry({ type: "json", valueString: "{\r\n}" })}>
                    <i className='fal fa-plus'/> Add JSON Entry
                </span>

                <span className='btn inline-block' onClick={() => addEntry({ type: "sql", valueString: "select * from something order by label"})}>
                    <i className='fal fa-plus'/> Add SQL Entry
                </span>

                <span className='btn inline-block' onClick={() => addEntry({ type: "pal", valueString: "{\r\n}"})}>
                    <i className='fas fa-palette'/> Add Color Palette
                </span>

                {selectedKey != null ? <span className='btn inline-block' onClick={deleteEntry}>
                    <i className='fal fa-skull' style={{ color: '#990000' }} /> Delete <b>{selectedKey}</b>
                </span> : null}

                {/* <span style={{ float: "right" }}>
                    <input type='text' className='search-box' placeholder='search' style={{ width: 200 }} />
                </span> */}
            </div>

            <div className='meta-list-section'>

                {loading ? <div className='loading'>loading...</div> :

                    <>
                    {/* <SimpleTable
                         data={metaList}
                         columns={[
                             { key: 'key', label: 'Key', type: 'string' },
                             { key: 'type', label: 'Type', type: 'string' },
                             { key: 'description', label: 'Description', type: 'string', width: 300 },
                         ]}
                    /> */}

                        {metaList?.map(metaEntry => {
                            const selected = metaEntry.key === selectedKey;
                            const isExpanded = expandedEntries.includes(metaEntry.key);
                            const system = ["selectionTypes"].includes(metaEntry.key);
                            return <div className={'meta-entry' + (selected ? ' sel' : '')} key={metaEntry.key} onClick={() => selectEntry(metaEntry)}>
                                <div className='title'>
                                    <div className='icon' onClick={() => {
                                        toggleMetaEntryExpansion(metaEntry.key);
                                    }}>{getIcon(metaEntry.type, isExpanded)}</div>
                                    <div className={'key ' + metaEntry.type + (system ? ' system' : '')}>{metaEntry.key}</div>
                                    <div className='type'>{metaEntry.type}</div>
                                </div>
                            </div>
                        })}
                        
                    </>

                }
                    
            </div>

            <div className='meta-value-section'>
                {selectedValue == null ? null :
                    <>
                    <div className='toolbar'>
                        <div className='section'>
                            <span className='btn inline-block' onClick={() => setReloadEntryCounter(reloadEntryCounter+1)}>
                                <i className='fal fa-sync'/> &nbsp;Refresh Entry
                            </span>
                        </div>
                        <div className={'section' + (view === 'value' ? ' sel' : '')}>
                            <span className='btn inline-block' onClick={() => setView('value')}>
                                Value
                            </span>
                        </div>
                        <div className={'section' + (view === 'table' ? ' sel' : '')}>
                        <span className='btn inline-block' onClick={() => setView('table')}>
                                Value (table)
                            </span>
                        </div>
                        <div className={'section' + (view === 'usage' ? ' sel' : '')}>
                            <span className='btn inline-block' onClick={() => setView('usage')}>
                                Usage
                            </span>
                        </div>
                        <div className={'section' + (view === 'edit' ? ' sel' : '')}>
                            <span className='btn inline-block' onClick={() => setView('edit')}>
                                Edit (global)
                            </span>
                            </div>
                        <div className={'section' + (view === 'edit2' ? ' sel' : '')}>
                            <span className='btn inline-block' onClick={() => setView('edit2')}>
                                Edit (license-specific)
                            </span>
                        </div>

                        {/* <div className='tab'>Json</div>
                        <div className='tab'>Table</div>
                        <div className='tab'>Usage</div> */}
                    </div>
                    {view === 'value' ?
                            <div className='tab-content'>
                            {loadingEntry ? "loading..." :
                                rawType === 'pal' ? 
                                    <EditPalette pal={selectedValue?.value} />
                                : selectedValue?.type === 'json' ?
                                    <div className='entry'>
                                        <ReactJson
                                            src={selectedValue?.value}
                                            name={null}
                                            theme={"rjv-default"}
                                            iconStyle="circle"
                                            displayDataTypes={false}
                                            //collapsed={1}
                                            enableClipboard={true}
                                        />
                                    </div>
                                    : null}
                        </div>
                    : view === 'table' ?
                            <div className='tab-content nopad'>
                            {loadingEntry ? "loading..." :
                                <div className='entry nopad'>
                                    {tableRows === null ? 'not an array' :
                                        <SimpleTable data={tableRows} columns={tableColumns} />}
                                </div>
                            }
                    </div>                            
                    : view === 'usage' ? <div className='tab-content'>
                        {loadingEntry ? "loading..." :
                            <div className='entry'>
                                <ReactJson
                                    src={selectedValue}
                                    name={null}
                                    theme={"rjv-default"}
                                    iconStyle="circle"
                                    displayDataTypes={false}
                                    //collapsed={1}
                                    enableClipboard={true}
                                />
                            </div>
                        }
                    </div>
                    : view === 'edit' ? <div className='tab-content'>
                            {loadingEntry ? "loading..." :
                                    <div className='entry fullheight'>
                                        <div className='toolbar'>
                                            <div>
                                                <span className='btn inline-block border left-space' onClick={save}>Save</span>
                                            </div>
                                            <div>
                                                <span className='label1'>
                                                    type: 
                                                </span>
                                                <input type='text' value={rawType || ""} onChange={ev => setRawType(ev.target.value)} className='box1' />
                                                    </div>
                                            <div>
                                                <span className='label1'>
                                                    parentKey: 
                                                </span>
                                                <input type='text' value={parentKey || ""} onChange={ev => setParentKey(ev.target.value)} className='box1' />
                                            </div>
                                            <div>
                                                <span className='label1'>
                                                    useMeta: 
                                                </span>
                                                <input type='text' value={rawUseMeta || ""} onChange={ev => setRawUseMeta(ev.target.value)} className='box1'/>
                                            </div>
                                            <div>
                                                <span className='label1'>
                                                    valueString: 
                                                </span>
                                            </div>
                                        </div>
                                            <div className='main'>
                                                {
                                                    rawType === 'sql' ?
                                                        <Editor height={'100%'}
                                                            defaultLanguage="sql" value={rawValue || ""}
                                                            theme='light'
                                                            onChange={(value, ev) => setRawValue(value)}
                                                        />
                                                    // : rawType === 'pal' ?
                                                    //     <EditPalette />
                                                    :
                                                        <Editor height={'100%'}
                                                            defaultLanguage="json" value={rawValue || ""}
                                                            theme='light'
                                                            onChange={(value, ev) => setRawValue(value)}
                                                        />
                                                }
                                            
                                        </div>
                                </div>
                            }
                        </div>
                    : view === 'edit2' ? <div className='tab-content'>
                            {loadingEntry ? "loading..." :
                                    <div className='entry fullheight'>
                                        <div className='toolbar'>
                                            <div>
                                                <span className='btn inline-block border left-space' onClick={save2}>Save</span>
                                            </div>
                                        </div>
                                        <div className='main'>
                                            {
                                                rawType === 'sql' ?
                                                    <Editor height={'100%'}
                                                        defaultLanguage="sql" value={rawLicValue || ""}
                                                        theme='light'
                                                        onChange={(value, ev) => setRawLicValue(value)}
                                                    />
                                                // : rawType === 'pal' ?
                                                //     <EditPalette />
                                                :
                                                    <Editor height={'100%'}
                                                        defaultLanguage="json" value={rawLicValue || ""}
                                                        theme='light'
                                                        onChange={(value, ev) => setRawLicValue(value)}
                                                    />
                                            }
                                        </div>
                                </div>
                            }
                        </div>       
                            
                            : <div className='tab-content'>
                                
                        </div>
                        }
                    </>
                }
            </div>

        </div>

    </div>;


}


