import * as React from 'react';
import { format as d3Format } from 'd3-format';
import * as globals from '../../globals';
import * as _ from 'lodash'

import './TableFred.scss';


export class TableFred extends React.PureComponent{

    constructor(props){
        super(props);
        this.state = {
            sortColIndex: -1,
            sortDir: 0, // {0, 1, 2} where 0 is unsorted
            whichCol: 'col1',
            col1: {
                sortIndex: -1,
                sortDir: 0
            },
            col2: {
                sortIndex: -1,
                sortDir: 0
            }
        };
    }

    printState = () => {
        //console.log(this.state);
    }

    handleColClick = (col, colIndex) => {
        if( colIndex === this.state[col].sortIndex ){
            let newSortDir = 
                this.state[col].sortDir == 0 ? 1 :
                this.state[col].sortDir == 1 ? 2 :
                0;
            this.setState({
                whichCol: col,
                [col]: {
                    ...this.state[col],
                    sortDir: newSortDir
                }
            }, this.printState);
        }
        else{
            this.setState({
                whichCol: col,
                [col]: {
                    sortIndex: colIndex,
                    sortDir: 1
                },
            }, this.printState);
        }
    }

    sort(col, _rows, columns) {
        const rows = [..._rows];

        if (this.state[col].sortIndex < 0) return rows;
        if (this.state[col].sortDir < 0) return rows;

        const expandedColumns = this.getExpandedColumns(columns);

        if (this.state[col].sortIndex >= expandedColumns.length) return rows;

        const column = expandedColumns[this.state[col].sortIndex];
        const dir = this.state[col].sortDir;

        if (col === 'col1') {
            const sortKey = column.sortKey || column.key;

            if (column.type === "number") {
                return dir === 1 ? rows.sort((a, b) => b[sortKey] - a[sortKey]) :
                    dir === 2 ? rows.sort((a, b) => a[sortKey] - b[sortKey]) :
                    rows;
            } else if (column.type === "cell") {
                return dir === 1 ? rows.sort((a, b) => b[sortKey]?.value - a[sortKey]?.value) :
                    dir === 2 ? rows.sort((a, b) => a[sortKey]?.value - b[sortKey]?.value) :
                    rows;
            } else if (column.type === "stacked-bar") {
            } else {
                // assume string
                return dir === 1 ? rows.sort((a, b) => a[sortKey] < b[sortKey] ? -1 : a[sortKey] > b[sortKey] ? 1 : 0 ) :
                    dir === 2 ? rows.sort((a, b) => a[sortKey] < b[sortKey] ? 1 : a[sortKey] > b[sortKey] ? -1 : 0) :
                    rows;
            }
        }

        if (col === 'col2') {
            return dir === 1
                ? _.sortBy(rows, [`composition[${this.state[col].sortIndex}]`]) // ascending order
                : dir === 2
                    ? [..._.sortBy(rows, [`composition[${this.state[col].sortIndex}]`])].reverse() // descending order
                    : rows
        }
    }

    immutableSort = (_rows) => {
        let rows = [..._rows]
        const columns = this.props.columns || []
        const scale = [0,1,2,3,4,5,6,7,8,9,10]

        if (this.state.whichCol === 'col1') {
            rows = this.sort('col1', rows, columns)
            rows = this.sort('col1', rows, scale)
        } else {
            rows = this.sort('col2', rows, columns)
            rows = this.sort('col2', rows, scale)
        }
        return rows
    }

    getSortIndicator = () => {
        return null;
    }

    getSortIndicator = (whichCol, col, colIndex) => {
        if( this.state[whichCol].sortIndex !== colIndex ) return null;
        if( this.state[whichCol].sortDir === 0 ) return null;
        let mult = col.type === 'number' || col.type === 'cell' ? 1 : 2;
        return <span className='sort-indicator'>
            {this.state[whichCol].sortDir === mult ? <i className='fas fa-caret-down' /> : <i className='fas fa-caret-up' />}
        </span>;
    }

    getExpandedColumns = (columns) => columns ? columns.reduce((accum, element) => {
        if( element.type === 'stacked-bar' || element.type === 'details' || element.type === 'nps-detail-vertical-bar' ){
            element.columns.forEach(col => {
                accum.push(col);
            });
        }
        else{
            accum.push(element);
        }
        return accum;
    }, []) : null;


    render() {

        const columns = this.props.columns || [];
        const showScale = this.props.showScale
        // type (string, number), label

        const headerColumns = this.getExpandedColumns(columns);
        // if (this.props.getJSONData && typeof this.props.getJSONData === 'function')
        //     this.props.getJSONData(this.getJSONData())
        // console.log(columns, this.props.data)

        const sortedRows = this.immutableSort(this.props.data || []);
        return (
            
                <table className='table-fred' style={this.props.style}>
                    <thead>
                        <tr className='header-row'>
                            {
                                headerColumns.map((col, colIndex) => {
                                    var extraStyle = {};
                                    if( col.fillHeader ){
                                        extraStyle.backgroundColor = col.fill;
                                        extraStyle.color = col.color;
                                    }

                                    return <th key={col.key || 'idx:' + colIndex} 
                                            rowSpan={showScale && (colIndex === 0 || colIndex === headerColumns.length - 1) ? 2 : 1} 
                                            className={'sortable ' + col.className + ' ' + (colIndex === 0 ? 'sticky-left' : '') + (` ${col.label}`)} 
                                            style={extraStyle} onClick={() => this.handleColClick('col1', colIndex)}>
                                        {col.label}
                                        {this.getSortIndicator('col1', col, colIndex)}
                                    </th>
                                }
                                )
                            }
                        </tr>
                        {showScale
                            ? <tr className='header-row'>
                                <th colSpan={columns.length} className="scale-span">
                                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((subCol, subColIndex) => 
                                        <span
                                            key={subColIndex}
                                            onClick={() => this.handleColClick('col2', subColIndex)}
                                            style={{ width: subColIndex === 10 ? '33px' : '28px' }}
                                        >
                                            {subCol}
                                            {this.getSortIndicator('col2', subCol, subColIndex)}
                                        </span>
                                    )}
                                </th>
                            </tr>
                            : null
                        }
                    </thead>
                    <tbody>
                        {sortedRows.map((row, rowIndex) => 
                            <tr key={row.uid || 'idx:' + rowIndex} className={'data-row' + ' ' + (row.className ? row.className : '')}>
                            {
                                columns.map((col, colIndex) => {
                                    if( col.type === 'stacked-bar'){

                                        

                                        return <td key={col.key || colIndex} colSpan={col.columns.length} className={'fred-stacked-bar ' + col.className} >
                                            
                                            {col.columns.map((subCol, subColIndex) => {
                                                const v0 = row[subCol.key].value;
                                                const v = v0 != null ? Math.max(0,v0) : null;
                                                const val = subCol.format ? subCol.format(v) : v;
                                                const w = (v * 100) + '%'
                                                return v == null || isNaN(v) ? null : <span key={subColIndex} style={{
                                                    display: 'inline-block',
                                                    backgroundColor: subCol.fill,
                                                    color: subCol.color,
                                                    width: w,
                                                    textAlign: 'center',
                                                    padding: '2px 0'
                                                }} title={w}>
                                                    {v >= 0.08 ? val: <span>&nbsp;</span>}
                                                </span>;
                                            })}
                                        </td>;
                                    } else if (col.type === 'details') {
                                        return (
                                            <td key={col.key || colIndex} colSpan={col.columns.length} className={'nps-vertical-bar ' + col.className}>
                                                {col.columns.map((subCol, subColIndex) => {
                                                    const val = row[subCol.key]?.composition;
                                                    return (val || []).map((v, idx) => 
                                                        <span
                                                            key={subColIndex+idx}
                                                            className='progress'
                                                            title={v}
                                                            style={{
                                                                backgroundColor: subCol.fill,
                                                                color: subCol.color,
                                                            }}
                                                        >
                                                            {subCol.format ? subCol.format(v) : v}
                                                        </span>
                                                    )
                                                })}
                                                {/* {row[col.columns[0].key]?.map((i, idx) => (
                                                    <span
                                                        key={idx}
                                                        className='progress'
                                                        style={{
                                                            backgroundColor: idx >= 0 && idx <= 5
                                                                ? col.columns[0].colors.detractor.fill : idx >= 6 && idx <= 8
                                                                    ? col.columns[0].colors.neutral.fill : col.columns[0].colors.promoter.fill,
                                                            color: idx >= 0 && idx <= 5
                                                                ? col.columns[0].colors.detractor.color : idx >= 6 && idx <= 8
                                                                    ? col.columns[0].colors.neutral.color : col.columns[0].colors.promoter.color,
                                                        }}
                                                        title={i}
                                                    >
                                                        {col.format ? col.format(i) : 'x'}
                                                    </span>
                                                ))} */}
                                            </td>
                                        )
                                    } else if (col.type === 'nps-detail-vertical-bar'){
                                        return (
                                            <td key={col.key || colIndex} colSpan={col.columns.length} className={'nps-vertical-bar ' + col.className}>
                                                <p className="vertical-bar-container">
                                                    {col.columns.map((subCol, subColIndex) => {
                                                        const val = row[subCol.key]?.composition;
                                                        if (row.composition)
                                                            var maxBarVal = Math.max(...row?.composition)
                                                        return (val || []).map((v, idx) => 
                                                            <span
                                                                key={subColIndex+idx}
                                                                className='progress'
                                                                title={`${v}`}
                                                                style={{
                                                                    backgroundColor: subCol.fill,
                                                                    color: subCol.color,
                                                                    height: `${(col.format(v)*16)/maxBarVal}px`
                                                                }}
                                                            >
                                                            </span>
                                                        )
                                                    })}
                                                </p>
                                            </td>
                                        )

                                    } else if(col.type === 'horizontal-nps-bar') {
                                        let val = row[col.key];
                                        let bgColors = globals?.npsColors;
                                        return (
                                            <td key={col.key || colIndex}  className={'nps-progress '+  (Math.sign(val) === 1 ? 'positive ' : Math.sign(val) === -1 ? 'negative ' : 'nuetral ') + col.className}>
                                                <p className="progress-bar">
                                                    <span title={val} style={{ width : `${Math.max(1, Math.abs(val/2))}%`, backgroundColor: Math.sign(val) === 1 ? bgColors?.promoter : Math.sign(val) === -1 ? bgColors?.detractor : 'transparent' }}>
                                                        <label className='datalabel1'>{col.format ? col.format(val) : val}</label>
                                                    </span>
                                                </p>                                                
                                            </td>
                                        )
                                    }
                                    else{                                                       
                                        //let val = col.type === 'cell' ? row[col.key]?.value ? row[col.key]?.value : row[col.key] : row[col.key]; // rob commented out, since zeros were being incorrectly interpreted as missing
                                        let val = col.type === 'cell' ? row[col.key]?.value : row[col.key];

                                        let n = row[col.key]?.n;
                                        let loading = row[col.key]?.loading;
                                        let cellClassName = col.type === 'cell' ? row[col.key]?.className : null;
                                        let tooltip = col.hideTooltip ? null : (col.type === 'cell' && n !== null) ? val + ' (n=' + (row[col.key]?.n||0) + ')' : val;
                                        return <td key={col.key || colIndex} className={' ' + col.className + ' ' + (colIndex === 0 && !col.preventSticky ? 'sticky' : '') + ' ' + (cellClassName || '')}
                                                style={{ backgroundColor: row[col.key]?.bgColor ? row[col.key]?.bgColor : cellClassName === 'max-cell' ? '#eafce6' : cellClassName === 'min-cell' ? '#f6e6e6' : '', color: row[col.key]?.fgColor ? row[col.key]?.fgColor : '' }} title={tooltip}>
                                            {loading ? "loading..." : val === '-' || val === null || val === undefined ? '-' : col.format ? col.format(val) : val}
                                        </td>
                                    }
                                })
                            }
                            </tr>
                        )}
                    </tbody>
                </table>
            
        );
    }

}


