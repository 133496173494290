import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import './ContentViewer.scss';
import { EditModeWrapper } from './EditModeWrapper';
import { ContentLoader } from './ContentLoader';
import { StudyLoader } from '../StudyLoader';

const isNull = (x) => x === null || x === undefined;

const ifNull = (x, fallback) => isNull(x) ? fallback : x;

export const ResponsiveRowElement = (props) => {

    const item = props.item;
    
    const [layoutsReady, setLayoutsReady] = useState({});

    const allLayoutsReady = () => !item.contents || item.contents.filter(c => layoutsReady[c.id]).length == item.contents.length;

    const setChildLayoutReady = (id, val) => {
        setLayoutsReady({
            ...layoutsReady,
            [id]: val
        })
    }

    useEffect(() => {
        if (item) {
            //console.log(`rrow ${item.id} layoutsReady`, layoutsReady, item.contents);
            props.setLayoutReady?.(allLayoutsReady())
        }
    }, [layoutsReady]);

    if (!item) return null;

    let style = {
        height: item.data?.height,
        margin: isNull(item.data?.margin) ? "0 auto 15px auto" : item.data?.margin,
        padding: item.data?.padding,
        gap: ifNull(item.data?.gap, '10px')
    }

    if (!isNull(item.data?.maxHeight)) {
        style.maxHeight = item.data.maxHeight;
        style.overflow = "hidden";
    }

    style = {
        ...style,
        ...item.data?.style,
    }

    const classes = ['responsive-row-element']
    if( !item.data?.wide ){
        classes.push('page-width');
    }

    const nbCols = item.data?.columns || 2
    classes.push(`cols_${nbCols}`)

    const grid_span = item.data?.columns_grid 
    if (grid_span) {
        style.gridTemplateColumns = grid_span.replaceAll(',', ' ')
        classes.push(`span${grid_span.split(',')?.length}`)
    }
    
    

    return <EditModeWrapper className='has-children'
        item={item} edit={props.edit} restoredItem={props?.restoredItem} reloadItem={props.reloadItem} reloadParent={props.reloadParentItem} reloadParentItem={props.reloadParentItem}
        pageLevel={props.pageLevel}
    >
        <div className={classes.join(' ')} style={style}>
            {!item.contents ? 'add item(s) to row' : null}
            {item.contents?.map(child => <ContentLoader
                key={child.id}
                id={child.id}
                restoredItem={child?.deleted}
                reloadParentItem={props.reloadItem}
                edit={props.edit}
                restore={props.restore}
                instanceContext={props.instanceContext}
                pageLevel={props.pageLevel}
                setLayoutReady={(val) => setChildLayoutReady(child.id, val)} />)}
        </div>
    </EditModeWrapper>
}

