import * as React from 'react';
import { useSelector } from 'react-redux';
import * as selectionHelper from '../../utils/selectionHelper';
import BoxPlot from '../vis/BoxPlot';
import { CoreWidget1 } from './CoreWidget1';


// const temp_q13_attitudes = [
//     { code: "Q13_1", label: "I like SUV’s that are higher off the ground than sedans or smaller SUVs" },
// ]

export const BoxPlotWidget2 = (props) => {

    const study = useSelector(state => state.study);
    const selections = useSelector(state => state.selections);
    const selection = selectionHelper.getSelection(props, study, selections);
    const stateCompare = useSelector(state => state.compare);
    const compare = props.inDataCard ? stateCompare?.selection : props.compare;
    const groups = compare?.groups?.map(g => g.syntax) || [];

    const minValue = props.min || 1;
    const maxValue = props.max || 7;

    const entries = props.entries || 'q13_attitudes';

    const statements = window.meta?.[entries];
    if( !statements ) return `meta entries for ${entries} is missing`;

    const entryCode = (entry) => 
    entry.getCode ? entry.getCode(selObj) : entry.code;
    

    const renderContent = (cache, getJSONData) => {
        if( !cache?.results ) return null;

        if( groups.length !== 2 ){
            return <center>{`Please select 2 groups. You have ${groups.length} selected.`}</center>;
        }

        const group1 = groups.length > 0 ? groups[0] : null;
        const group2 = groups.length > 1 ? groups[1] : null;

        if( !group1 || !group2 ) return "missing groups";


        //const randomNumber = (min, max) => Math.random() * (max - min) + min;
        const r = cache.results;
        let data = statements.map(s => {

            const scode = entryCode(s);

            //const min = Math.min(r[scode + ':min']?.[group1]?.value, r[scode + ':min']?.[group2]?.value);
            //const max = Math.min(r[scode + ':max']?.[group1]?.value, r[scode + ':max']?.[group2]?.value);
            return {
                label: s.label,
                min: minValue,
                max: maxValue,
                //whiskerMin: min,
                //whiskerMax: max,
                value: r[scode]?.[group1]?.value,
                value2: r[scode]?.[group2]?.value,
                valueLabel: compare.groups[0].label,
                value2Label: compare.groups[1].label
            }
        });

        const boxPlotProps = {
            height: data.length * 25,
            labelWidth: props.labelWidth || [200,500],
            inInsightCard: props.inInsightCard
        };

        const sort = props.sort || "none";
        if( sort === "asc" || sort === "desc" ){
            data = data.sort((a, b) => {
                const aValue = a?.value;
                const bValue = b?.value;
                const mult = sort === "desc" ? -1 : 1;

                if( aValue && bValue ) return (aValue - bValue) * mult;
                if( aValue ) return 1 * mult;
                if( bValue ) return -1 * mult;
                return 0;
            })
        }

        // sort by gap
        if( sort === "asc" || sort === "desc" ){
            data = data.sort((a, b) => {
                const aValue = a?.value;
                const bValue = b?.value;
                const aValue2 = a?.value2;
                const bValue2 = b?.value2;
                const mult = sort === "desc" ? -1 : 1;

                if( aValue && bValue && aValue2 && bValue2 ) return (Math.abs(aValue - aValue2) - Math.abs(bValue - bValue2)) * mult;
                return 0;
            })
        }
        if (getJSONData && typeof getJSONData === 'function')
            getJSONData(data)
        return cache ? <div className='vscroll-content'>
            <BoxPlot data={data} {...boxPlotProps} />
        </div> : null;
    }

    const selObj = selection ? window.meta.dcmToSurveyMap("" + selection?.[study?.explore?.selectionLevel]) : null;
    const sel = selObj?.survey_id;
    if( !sel && props.ownerFilter ) return 'selection missing or unavailable';


    let filters = [];
    //if( filter ){ filters.push(filter) }
    if( props.ownerFilter ){
        filters.push(`q1_${sel}==7 or q1_${sel}==8`); // todo: change this to a generic var like 'owner' :
    }
    // if( props.getFilter ){
    //     filters.push(props.getFilter(selObj));
    // }
                
    

    return <CoreWidget1 {...props}
        useSelection={false}
        useFilter={false}
        useCompare={true}
        render={renderContent}
        useYak={{
            query: {
                filters,
                measures: [
                    ...statements.map(entry => ({ id: entryCode(entry), syntax: `mean(${entryCode(entry)})` })),
                    ...statements.map(entry => ({ id: entryCode(entry) + ':min', syntax: `min(${entryCode(entry)})` })),
                    ...statements.map(entry => ({ id: entryCode(entry) + ':max', syntax: `max(${entryCode(entry)})` }))
                ],
                groups
            }
        }}
    />

}
