import * as React from "react";
import { connect } from "react-redux";
import {
	Bar,
	BarChart,
	LabelList,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { bindActionCreators } from "redux";
import * as globals from "../../globals";
import { actionCreators as forecastActionCreators } from "../../store/Forecast.ts";
import * as debounce from "../../utils/debounce";
import * as selectionHelper from "../../utils/selectionHelper";
import { CardLoading } from "../card/CardLoading";
import { FilterChooser } from "../filter/FilterChooser";
import { ProductBlockMini } from "../product-drawer/ProductBlockMini";
import CustomizedTick from "../common/CustomizedTick";
import "./DCMProductsWidget.scss";

const validMetrics = globals.metrics.filter((m) => m.key !== "consider");

export class DCMProductsWidget extends React.Component {
	constructor(props) {
		super(props);

		// local widget settings
		this.state = {
			metric: this.props.metric || validMetrics[1].key,
		};

		this.props.stateContext.getSaveState = this.getSaveState;
	}

	componentDidMount() {
		this.refreshIfNeeded();
	}

	componentDidUpdate() {
		if (!this.isLoading()) {
			if (this.props.loaded && typeof this.props.loaded === "function")
				this.props.loaded(!this.isLoading());
		}
		this.refreshIfNeeded();
	}

	refreshIfNeeded = debounce.debounce(() => {
		// cancel if insight card
		if (this.props.inInsightCard) return;

		// cancel if config isn't ready
		if (!this.props.etag) return;

		// cancel if cached data already exists, with matching filter and etag
		const cache = this.props.cache;
		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);
		const filterCache = (cache || {})[filter.syntax];
		if (filterCache?.etag === this.props?.etag) return;

		// cancel if cached data is loading
		if (filterCache && filterCache.loading) return;

		// refresh is needed. request it.
		this.props.requestForecast();
	}, debounce.forecastDelay);

	// these are needed from parent state
	static inheritOptions = ["configEtag"]; //, 'metric'];

	static scenarioDriven = true;

	// // returns widget's title for the card to display
	// static getTitle = (state) => {
	//     return `${state.title}`;
	// }

	// // returns widget's sources for the card to display
	// static getSources = (state) => (state.sources || []);

	// returns widget's local settings (for save state)
	getSaveState = () => {
		// get the cache
		const cache = this.props.cache;
		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);
		const filterCache = this.props.inInsightCard
			? this.props.filterCache
			: (cache || {})[filter.syntax];

		const retval = {
			// note: repo entry properties are added later

			// container state
			//selection: this.props.selection,  // might not need to be here, since inheritOptions includes it
			selectedProductId: this.props.selections?.product?.uid,
			// selectedItem: this.props.selectedItem,
			// selectedItemLabel: this.props.selectedItemLabel,
			filter: {
				label: filter.label,
				syntax: filter.syntax,
			},

			// local settings
			//agg: this.state.agg,
			metric: this.state.metric,

			// masterLevel: this.props.masterLevel,
			// groupLevel: this.props.groupLevel,

			// note: scenario is added later ?? or is it?

			// cached results
			filterCache,
		};
		return retval;
	};

	isLoading = () => {
		let fCache = (this.props.cache || {})[this.props.filter.syntax] || {};
		return fCache.loading; // || fCache.etag !== this.props.etag;
	};

	nulladd = (a, b) => {
		if (!a) return b;
		if (!b) return a;
		return a + b;
	};

	nullsubtract = (a, b) => {
		if (!a || !b) return null;
		return a - b;
	};

	getProductGroups = (selUid, config) => {
		if (this.props.groupBy === "product") {
			return this.getProductGroups2(selUid, config);
		} else if (this.props.groupBy === "hierarchy") {
			return this.getProductGroups1(selUid, config);
		} else {
			return [];
		}
	};

	getProductGroups1 = (selUid, config) => {
		//console.log("getProductGroups called: ", selUid, config);

		if (!config) return;

		const slots = config.productSlots;
		if (!slots) return;

		const masterLevel = this.props.masterLevel; // brand
		const groupLevel = this.props.groupLevel; // model

		// get current model
		const selSlot = slots.find((slot) => slot.uid === selUid);
		const selModel = selSlot.play[groupLevel];

		// TODO! pull hierarchy dynamically from study

		const modelAtt = config.productDefs[selSlot.def || "default"].atts.find(
			(att) => att.name === groupLevel,
		);
		const brandAtt = config.productDefs[selSlot.def || "default"].atts.find(
			(att) => att.name === masterLevel,
		);

		const selModelLabel = (modelAtt
			? modelAtt.levels.find((level) => level.value == selModel)
			: null || {}
		).label;
		const selBrand = selSlot.play[masterLevel];
		const selBrandLabel = (brandAtt
			? brandAtt.levels.find((level) => level.value == selBrand)
			: null || {}
		).label;

		return [
			// trims in current model -> MODEL trims
			{
				label: selModelLabel, // `model ${selModel}`,
				products: slots.filter((slot) => slot.play[groupLevel] === selModel),
			},
			// trims in current brand but not in current model -> OTHER brand
			{
				label: `Other ${selBrandLabel}`,
				products: slots.filter(
					(slot) =>
						slot.play[masterLevel] === selBrand && slot.play.model !== selModel,
				),
			},
			// trims not in current brand -> cOMPETITORS
			{
				label: `Competitors`,
				products: slots.filter((slot) => slot.play[masterLevel] !== selBrand),
			},
		];
	};

	getProductGroups2 = (selUid, config) => {
		if (!config) return;

		const slots = config.productSlots;
		if (!slots) return;

		// get current model
		const selSlot = slots.find((slot) => slot.uid === selUid);
		const selModel = selSlot.play.model;

		return slots
			.filter((slot) => slot.play.model === selModel)
			.map((slot) => {
				return {
					label: slot.label,
					products: [slot],
				};
			});
	};

	render() {
		const cache = this.props.cache;
		const filter =
			this.props.filter || globals.getDefaultFilter(this.props.study);
		let filterCache = (cache || {})[filter.syntax];

		// let productSlots = this.props.productSlots || [];
		// const expired = this.props.etag !== (filterCache || {}).etag;

		if (this.props.inInsightCard) {
			filterCache = this.props.filterCache;
		} else {
		}

		const selectedProductId = this.props.inInsightCard
			? this.props.selectedProductId
			: this.props.selections?.product?.uid;
		const selectedProduct = selectionHelper.getSelectionInfo(
			"product",
			selectedProductId,
			this.props.study,
			this.props.selections?.hierarchy,
		);

		const colors = [globals.configs2.play.color, globals.configs2.base.color];

		const productGroups = selectedProduct
			? this.getProductGroups(selectedProductId, this.props.config)
			: null || [];
		const selMetric = globals.metrics.find((m) => m.key === this.state?.metric) || 'share';

		// // create data
		let allData = [];
		productGroups.forEach((productGroup) => {
			const slots = productGroup.products;
			let slotData = { play: null, base: null, nbProducts: 0 };
			if (filterCache) {
				slots.forEach((slot) => {
					const productCache = filterCache[slot.uid] || { play: {}, base: {} };
					slotData.play = this.nulladd(
						slotData.play,
						productCache.play[selMetric?.key],
					);
					slotData.base = this.nulladd(
						slotData.base,
						productCache.base[selMetric?.key],
					);
					slotData.nbProducts++;
					//slotData.entries.push({ slot, productCache });
				});
			}
			allData.push({
				name: productGroup.label,
				base: slotData.base,
				play: slotData.play,
				slotData,
			});
		});

		//console.log(data);
		var yAxisData = [];
		productGroups.map((productGroup) => {
			const data = allData.filter((x) => x.name === productGroup.label);

			if (data[0].play && data[0].base) {
				yAxisData.push(data[0].play);
				yAxisData.push(data[0].base);
			}
		});
		var maxYAxis = Math.max(...yAxisData);

		const layout =
			this.props.layout === "hbars"
				? allData?.length > 8
					? "hbars-dynamic-height"
					: "hbars-fill"
				: layout;
		// console.log("layout", layout)
		const content = !filterCache ? (
			"no cache"
		) : layout === "hbars-fill" ? (
			<ResponsiveContainer>
				<BarChart
					key={selMetric.key}
					data={allData}
					layout="vertical"
					barGap={0}
					margin={{ right: 50 }}>
					<XAxis type="number" hide={true} padding={{ right: 20 }} />
					<YAxis
						type="category"
						dataKey="name"
						width={150}
						tick={<CustomizedTick wordBreakLimit="20" />}
					/>
					<Tooltip formatter={selMetric.dformat} />
					<Bar
						isAnimationActive={false}
						dataKey="base"
						name={globals.configs2.base.label}
						fill={colors[1]}
						animationDuration={200}>
						<LabelList
							dataKey="base"
							position="right"
							formatter={selMetric.fformat}></LabelList>
					</Bar>
					<Bar
						isAnimationActive={false}
						dataKey="play"
						name={globals.configs2.play.label}
						fill={colors[0]}
						animationDuration={200}>
						<LabelList
							dataKey="play"
							position="right"
							formatter={selMetric.fformat}></LabelList>
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		) : layout === "hbars-dynamic-height" ? (
			<div className="hbars-container">
				<ResponsiveContainer height={allData.length * 40}>
					<BarChart
						key={selMetric.key}
						data={allData}
						layout="vertical"
						barGap={0}
						margin={{ right: 40 }}>
						<XAxis type="number" hide={true} padding={{ right: 20 }} />
						<YAxis
							type="category"
							dataKey="name"
							width={150}
							tick={<CustomizedTick wordBreakLimit="20" />}
						/>
						<Tooltip formatter={selMetric.dformat} />
						<Bar
							isAnimationActive={false}
							dataKey="base"
							name={globals.configs2.base.label}
							fill={colors[1]}
							animationDuration={200}>
							<LabelList
								dataKey="base"
								position="right"
								formatter={selMetric.fformat}></LabelList>
						</Bar>
						<Bar
							isAnimationActive={false}
							dataKey="play"
							name={globals.configs2.play.label}
							fill={colors[0]}
							animationDuration={200}>
							<LabelList
								dataKey="play"
								position="right"
								formatter={selMetric.fformat}></LabelList>
						</Bar>
					</BarChart>
				</ResponsiveContainer>
			</div>
		) : (
			<div className="middle-content">
				{productGroups.map((productGroup, index) => {
					const data = allData.filter((x) => x.name === productGroup.label);
					const diffNum =
						data.length > 0
							? this.nullsubtract(data[0].play, data[0].base)
							: null;
					const diff = diffNum ? selMetric.fformat(Math.abs(diffNum)) : "";
					//return this.props.inInsightCard ? 'raw data: ' + JSON.stringify(data) :

					return (
						<div className="cell" key={index}>
							<div className="bar-chart">
								<ResponsiveContainer>
									<BarChart
										key={selMetric.key}
										data={data}
										style={{ display: "inline-block" }}
										width={100}
										height={150}>
										<XAxis dataKey="name" />
										<YAxis
											padding={{ top: 20 }}
											hide={true}
											domain={[0, maxYAxis]}
											tick={<CustomizedTick wordBreakLimit="20" />}
										/>
										<Tooltip formatter={selMetric.dformat} />
										<Bar
											isAnimationActive={false}
											dataKey="base"
											name={globals.configs2.base.label}
											fill={colors[1]}
											animationDuration={200}>
											<LabelList
												dataKey="base"
												position="top"
												formatter={selMetric.fformat}></LabelList>
										</Bar>
										<Bar
											isAnimationActive={false}
											dataKey="play"
											name={globals.configs2.play.label}
											fill={colors[0]}
											animationDuration={200}>
											<LabelList
												dataKey="play"
												position="top"
												formatter={selMetric.fformat}></LabelList>
										</Bar>
									</BarChart>
								</ResponsiveContainer>
							</div>
							<div className="diff">
								<i
									className={
										"far " +
										(diffNum < 0
											? "fa-minus red"
											: diffNum > 0
											? "fa-plus green"
											: null)
									}></i>
								&nbsp; {diff}
							</div>
						</div>
					);
				})}
			</div>
		);
		if (this.props.loaded && typeof this.props.loaded === "function")
			this.props.loaded(!this.isLoading());
		if (this.props.getJSONData && typeof this.props.getJSONData === "function")
			this.props.getJSONData(null, true);

		return (
			<div className="widget dcm-products-widget">
				<div className="widget-header">
					{this.props.title ? (
						<div className="title">{this.props.title}</div>
					) : null}
					<div className="selection">
						<ProductBlockMini
							{...selectedProduct}
							disabled={this.props.inInsightCard}
							allowChange={["product"]}
						/>
					</div>
					<div className="filter-etc">
						<FilterChooser
							mini={true}
							disabled={this.props.inInsightCard}
							selection={filter}
						/>
					</div>
					{this.props.inDataCard && (
						<select
							value={this.state.metric}
							onChange={(ev) => this.setState({ metric: ev.target.value })}>
							{validMetrics.map((m) => (
								<option key={m.key} value={m.key}>
									{m.label}
								</option>
							))}
						</select>
					)}
					{this.props.inInsightCard && (
						<label className="ml-1">
							{validMetrics.filter((i) => i.key === this.state?.metric)?.[0]?.label}
						</label>
					)}
				</div>
				<div className="widget-body">{content}</div>
				<CardLoading loading={this.isLoading()} />
			</div>
		);
	}
}

DCMProductsWidget = connect(
	(state, ownProps) => ({
		study: state.study,
		config: state.study?.config,
		productSlots: state.study?.config?.productSlots,
		selections: state.selections,
		masterLevel: ownProps.masterLevel || "brand",
		groupLevel: ownProps.groupLevel || "model",
		...(ownProps.inDataCard
			? {
					etag: ((state.study || {}).config || {}).etag,
					filter: (state.filter || {}).selectedFilter,
					cache: (state.cache || {}).forecast,
			  }
			: {}),
	}),
	(dispatch) => bindActionCreators(forecastActionCreators, dispatch),
)(DCMProductsWidget);
