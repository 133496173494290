import { format as d3Format } from 'd3-format';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import * as selectionHelper from '../../utils/selectionHelper';
import { TableFred } from '../vis/TableFred';
import './CoOccurringWordsTableViewer.scss';
import { CoreWidget1 } from './CoreWidget1';


export const CoOccurringWordsTableViewer = (props) => {

    const study = useSelector(state => state.study);
    const selections = useSelector(state => state.selections);

    const selection = selectionHelper.getSelection(props, study, selections);

    const [drillPath, setDrillPath] = useState(props.drillPath || []);
    const [showVerbatims, setShowVerbatims] = useState(props.showVerbatims || false);
    const [eTag, setETag] = useState(0);
        
    const wordPath = [selection?.label, ...drillPath];
    const wordPathStr = drillPath?.join(' + ');

    const allowDrill = !props.inInsightCard;
    //console.log('allowDrill', allowDrill);

    const clearCache = () => {
        setETag(eTag + 1);
    }

    const columns = showVerbatims ? [
        // {
        //     key: 'sourceLink',
        //     sortKey: 'source',
        //     type: 'string', 
        //     className: 'link',
        //     label: 'Source'
        // },
        { 
            key: 'text', 
            type: 'label', 
            className: 'text', 
            label: 'Verbatim text',
            hideTooltip: true,
            preventSticky: true
        },
        { 
            key: 'sentiment', 
            type: 'number', 
            className: 'data right-align', 
            label: 'Sentiment',
            format: (val) => val === null ? '.' : d3Format('.2f')(val),
            hideTooltip: true
        },
    ]
    : [
        { 
            key: 'wordLink',
            sortKey: 'word',
            type: 'string', 
            className: 'label sticky', 
            label: 'Co-occurring word(s)',
            hideTooltip: true,
            preventSticky: true
        },
        { 
            key: 'count', 
            type: 'number', 
            className: 'data right-align', 
            label: 'Count',
            format: (val) => val === null ? '.' : d3Format(',.0f')(val),
            hideTooltip: true
        },
        { 
            key: 'sentiment', 
            type: 'number', 
            className: 'data right-align', 
            label: 'Sentiment',
            format: (val) => val === null ? '.' : d3Format('.2f')(val),
            hideTooltip: true
        },
    ];

    
    
    
    

    const renderContent = (cache, getJSONData) => {

        const rows0 = (props.inInsightCard ? cache?.data : (eTag == cache?.eTag ? cache?.data : null)) || [];

        if (getJSONData && typeof getJSONData === 'function' && columns && rows0)
            getJSONData({ columns: [...columns.map(i => i.key === 'wordLink'? {...i, key: 'word'} : i)], data: [...rows0] })

        const rows = rows0.map(row => {
            return showVerbatims ? 
            // verbatims style
            {
                ...row,
                word: (wordPath || []).join(' + '),
                text: <span className='verbatim-text-entry'>
                    <span className='text'>
                        {row.text}
                    </span>
                    <span className='link'>
                        <a href={row.source} target="_blank">View source</a>
                    </span>
                </span>,
                sourceLink: <a href={row.source} target="_blank">{row.source}</a>
            } :
            // regular (not verbatims)
            {
                ...row,
                wordLink: <>
                    <span className={'word-link' + (allowDrill ? '' : ' disabled')} title="Drill-down"
                        onClick={(ev) => {
                            if( !allowDrill ) return;
                            clearCache();
                            setDrillPath([...drillPath, row.word])
                        }}
                    >
                        {wordPathStr ? wordPathStr + ' + ' + row.word : row.word}
                    </span>
                    <span className={'verb-link' + (allowDrill ? '' : ' disabled')} title="View Verbatims"
                        onClick={() => {
                            clearCache();
                            setDrillPath([...drillPath, row.word]);
                            setShowVerbatims(true)
                        }}
                    >
                        <i className='fal fa-comments'/>
                    </span>
                    </>,
            };
        })

        return <div>
            <TableFred getJSONData={getJSONData} columns={columns} data={rows} />
        </div>
    }

    const renderOptions = () => {
        return drillPath.length > 0 || showVerbatims ?
            <div className='path-space'>
                <span className={'path' + (allowDrill ? '' : ' disabled')}>
                    {drillPath.join(' + ')} {showVerbatims ? '(verbatims)' : ''}
                </span>
                {allowDrill ?
                <span className='clear-path'
                    onClick={() => {
                        clearCache();
                        setDrillPath([]);
                        setShowVerbatims(false);
                    }}
                >
                    <i className='fal fa-times'/>
                </span> : null}
            </div>
            : null
    }

    

    return <CoreWidget1 {...props} 
        class1='co-occurring-words-table-viewer'
        class2='vscroll hscroll'
        showSelection={true}
        options={renderOptions}
        render={renderContent}
        getSaveState={() => {
            return {
                showVerbatims: showVerbatims,
                drillPath: drillPath
            }
        }}
        useBigData={{
            eTag: eTag + "",
            path: selection ? `GET:co-occurring-words-pagination-suv?pagination_iterator=100&search_terms=${encodeURI(wordPath.join('|'))}&return_verbatims=${showVerbatims ? 'True' : 'False'}` : null
        }}
        />


}


