import * as globals from '../globals';
import * as apiService from '../utils/apiService';
import { loadOptRunSetSelectionHelper } from '../utils/optRunHelper';
import { 
    OPTIMIZE_OPTRUN_RECEIVE,
    OPTIMIZE_OPTRUN_REQUEST,
    OPTIMIZE_OPTRUN_SCENARIO_RECEIVE,
    OPTIMIZE_OPTRUN_SCENARIO_REQUEST,
    STUDY_RESET
    // @ts-ignore
} from './action-types';
// @ts-ignore
import { actionCreators as configActionCreators } from './Config';

export const researchScenariosActionType = 'RESEARCH_SCENARIOS_ACTION';

export interface ResearchScenariosState {
    optRuns: {
        [key: string]: OptRun
    }
}

export interface OptRun {
    loading: boolean;
    input: {
        goal: {
            targetMeasure: string;
            targetFilter: string;
            targetFilterLabel: string;
            targetProducts: string[];
        }
    }
    output: {
        scenarios: any[];
    };
}

const initialState: ResearchScenariosState = {
    optRuns: {}
    // runId: null,
    // targetProducts: [],
    // targetFilter: null,
    // scenarios: []
};

export const getSavedScenarioKey = (uid: string) => `saved:${uid}`;
export const getOptRunScenarioKey = (runId: string, index: any) => `optRun:${runId}:${index}`;
export const getFeedScenarioKey = (postId: string) => `post:${postId}`;


export const actionCreators = {

    loadOptRun: (runId: string, callback: any) => async (dispatch: any, getState: any) => {

        dispatch({ type: OPTIMIZE_OPTRUN_REQUEST, runId });

        const state = getState();

        try {
            const url = `${globals.apiRoot}/optimize/run/${runId}`;
            const response = await apiService.aGet(state.auth, url);
            if( response.ok ){
                const value = await response.json();
                dispatch({ type: OPTIMIZE_OPTRUN_RECEIVE, runId, value });

                if( callback ){
                    callback(value);
                }
            }
            else{
                dispatch({ type: OPTIMIZE_OPTRUN_RECEIVE, runId, error: response.statusText });
            }

        } catch (error) {
            console.error('loadOptRun error', error);
            dispatch({ type: OPTIMIZE_OPTRUN_RECEIVE, runId, error });
        }

    },

    loadOptRunScenario: (runId: string, scenarioKey: string, loadWhatIf: any, callback: any) => async (dispatch: any, getState: any) => {
        
        // for now, opening what-if and comparison

        dispatch({ type: OPTIMIZE_OPTRUN_SCENARIO_REQUEST, runId, key: scenarioKey });

        const state = getState();

        // get uid of study
        let studyId = state.study.uid;

        // get config
        // todo: separate play from base

        try {
            const response = await apiService.aGet(state.auth, `${globals.apiRoot}/study/${studyId}/optrun/${runId}/scenario/${scenarioKey}`);
            if( response.ok ){
                const data = await response.json();
            
                dispatch({ type: OPTIMIZE_OPTRUN_SCENARIO_RECEIVE, runId, key: scenarioKey, value: data });

                if( loadWhatIf ){

                    await loadOptRunSetSelectionHelper(data, state.study, (a: any, b: any) => {
                        configActionCreators.set(a, b)(dispatch, getState)
                    });

                    // hack?
                    dispatch({ type: `SCENARIO_ACTION:LOAD_SCENARIO_RESULT_SINGLECONFIG`, value: data });
                }

                if (callback) {
                    callback( data );
                }

            } 
            else {
                console.error(response);
                dispatch({ type: OPTIMIZE_OPTRUN_SCENARIO_RECEIVE, runId, key: scenarioKey, error: response.statusText });
            }

        } 
        catch (error) {
            console.error(error);
            dispatch({ type: OPTIMIZE_OPTRUN_SCENARIO_RECEIVE, runId, key: scenarioKey, error });
            
        }
        
    },

    

    // saveOptRun: (runId, callback) => async (dispatch, getState) => {

    //     const state = getState();

    //     try {
    //         const url = `${globals.apiRoot}/optimize/${runId}/save`;
    //         const response = await fetch(url, {
    //             method: 'get',
    //             headers: {
    //                 Accept: 'application/json',
    //                 'Content-Type': 'application/json',
    //                 Authorization: 'Bearer ' + state.auth.token
    //             }
    //         });

    //         const value = await response.json();

    //         alert('saved');
    //         // dispatch({
    //         //     type: `${researchScenariosActionType}:RECEIVE_SCENARIO_LIST`,
    //         //     value
    //         // });

    //         if( callback ){
    //             callback();
    //         }

    //     } catch (err) {
    //         console.error('saveOptRun error', err);
    //     }

    // },

    // setValue: (name, value) => async (dispatch) => dispatch({type: `${researchScenariosActionType}:SET_VALUE`, name, value }),

    // setValues: (map) => (dispatch) => dispatch({ type: `${researchScenariosActionType}:SET_VALUES`, map }),


};

export const reducer = (state: ResearchScenariosState = initialState, action: any): ResearchScenariosState => {

    if( action.type === STUDY_RESET ) return initialState;

    //var subType = action.type.substring(researchScenariosActionType.length + 1);

    switch( action.type ){

        case OPTIMIZE_OPTRUN_REQUEST : return {
            ...state,
            optRuns: {
                ...state.optRuns,
                [action.runId]: {
                    loading: true
                }
            }
        };

        case OPTIMIZE_OPTRUN_RECEIVE : return {
            ...state,
            optRuns: {
                ...state.optRuns,
                [action.runId]: {
                    loading: false,
                    error: action.error,
                    ...action.value
                }
            }
        };

        case OPTIMIZE_OPTRUN_SCENARIO_REQUEST: {
            console.log('OPTIMIZE_OPTRUN_SCENARIO_REQUEST', action);
            const optRuns = state.optRuns || {};
            const optRun: OptRun = optRuns[action.runId];
            const output: any = optRun?.output || {};
            let scenarios = [...(output?.scenarios || [])];

            // add scenario if needed
            if(!scenarios.find(s => s.key === action.key) ){
                scenarios.push({ key: action.key });
            }
            
            scenarios = scenarios.map(s => s.key === action.key ? {
                ...s,
                loading: true
            } : s);

            return {
                ...state,
                optRuns: {
                    ...state.optRuns,
                    [action.runId]: {
                        ...optRun,
                        output: {
                            ...output,
                            scenarios
                        }
                    }
                }
            };
        }

        case OPTIMIZE_OPTRUN_SCENARIO_RECEIVE: {
            
            console.log('OPTIMIZE_OPTRUN_SCENARIO_RECEIVE', action);
            const optRuns = state.optRuns || {};
            const optRun:OptRun = optRuns[action.runId];
            const output = optRun?.output;
            let scenarios = output?.scenarios.map((s: any) => s.key === action.key ? {
                ...s,
                loading: false,
                error: action.error,
                config: (action.value || {}).config
            } : s);
                        
            return {
                ...state,
                optRuns: {
                    ...state.optRuns,
                    [action.runId]: {
                        ...optRun,
                        output: {
                            ...output,
                            scenarios
                        }
                    }
                }
            };
        }

        // case 'RECEIVE_SCENARIO_LIST': return {
        //     ...state,
        //     scenarios: [...action.value]
        // }

        // case 'SET_VALUE': return {
        //     ...state,
        //     [action.name]: action.value
        // };

        // case 'SET_VALUES': {
        //     let retVal = { ...state };
        //     if( action.map ){
        //         Object.entries(action.map).forEach(entry =>
        //             retVal[entry[0]] = entry[1]
        //         )
        //     }
        //     return retVal;
        // };
        
    }

    return state;


};
