import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '../../store/Auth.ts';
import './Auth.scss';
import { AuthOuter } from './AuthOuter';
import { useMsal } from '@azure/msal-react';



export function SignIn(props) {
    
    const [showPassword, setShowPassword] = useState(false);
    // const [username, setUsername] = useState('');
    // const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { instance } = useMsal()

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth || {});

  
    const tryLogin = () => {
        dispatch(actionCreators.requestAuthToken( instance))
    }

    const clearMessages = () => {
          dispatch(actionCreators.clearMessages());
    //         this.setState({
    //             g_recaptcha_response: null
    //         });
    //     };
        
    };

    const handleLogout = () => {
         localStorage.removeItem('auth')
        instance.logout({
            postLogoutRedirectUri:'https://app.blueowlai.com'
        })
    }

    return (
        <AuthOuter title="Log in" xtitleLogo={<i className='fal fa-sign-in' />} boxHeight='400px' temp={errorMessage}>
                 {/* <div className="username">
                     <div className="field-label">Email address</div>
                     <input
                         type="text"
                         id="username"
                         name="username"
                         placeholder="&#xf007; email"
                         role='input'
                         aria-label='Login using Email Address'
                         value={username}
                         onChange={e => setUsername(e.target.value)}
                         style={{ fontFamily: "Arial, Roboto" }}
                         tabIndex="1"
                         autoFocus
                     />
                 </div>
                 <div className="password">
                     <div className="field-label">Password
                         <button
                             className="link-btn show-pwd"
                             onClick={toggleShowPassword}
                             type="button"
                             tabIndex="6"
                             role='button'
                             aria-label='Show Password'
                         >
                             <i
                                 className={
                                     showPassword ? 'far fa-eye-slash' : 'far fa-eye'
                                 }
                             />
                         </button>
                     </div>
                     <div className="pwd-container">
                         <input
                             type={showPassword ? 'text' : 'password'}
                             id="password"
                             name="password"
                             role='input'
                             aria-label='Enter Password'
                             placeholder="&#xf023; password"
                             value={password}
                             onChange={e => setPassword(e.target.value)}
                             style={{ fontFamily: "Arial, Roboto" }}
                             tabIndex="2"
                         />
                     </div>
                 </div> */}
                 <div className='eula-note'>
                     Use of the Blue Owl AI software is your consent to the <a href="https://www.blueowlai.com/software-user-agreement" target="_blank">Software User Agreement</a>. Welcome to Blue Owl.
                     </div>
                 <div className="loginContainer">
                     <div className="submit-section">
                         <button className="submit" tabIndex="3"
                             role='button'
                             aria-label='Log in'
                             onClick={tryLogin}
                         >
                             Log in
                         </button>
                     </div>
                     <div className="help-buttons" style={{display:'flex', justifyContent:'flex-end'}}>
                         <a href='#'
                            className="link-btn sign-up" 
                            style={{ marginRight:5, width:75}}
                             onClick={handleLogout}
                             tabIndex="4"
                             aria-label="Log out"
                         >
                            Log Out
                        </a>
                    </div>
                         {/* <Link
                             className="link-btn sign-up"
                             to="/sign-up"
                             onClick={clearMessages}
                             tabIndex="5"
                             aria-label="Sign Up: Click here to create a new account"
                         >
                             <i className="fal fa-user-plus" /> Create an account
                         </Link>
                     </div> */}
                 </div>
                 {/* </form> */}
             </AuthOuter>       
    )
}

