import * as React from 'react';
import ReactDOM from 'react-dom';
import { useState } from "react";
import { useSelector } from "react-redux";
import * as globals from '../../globals';
import * as apiService from '../../utils/apiService';
import { ComboBox } from '../common/ComboBox';
import { ComboBoxCustom } from '../common/ComboBoxCustom';
import { getRepo } from '../widgets/_widgetRepo';
import * as debounce from '../../utils/debounce';
import { DataCard } from '../card/DataCard';

const isMatch = (lowerSearchTerms, lowerText) => {
    for( let i in lowerSearchTerms ){
        const lowerSearchTerm = lowerSearchTerms[i];
        if( !lowerText.includes(lowerSearchTerm) ){
            return false;
        }
    }
    return true;
}

const updateListDebounced = debounce.debounce((val, repo, setCardList) => {
    
    if( !repo ) return;

    const lowerVal = val?.toLowerCase();
    const lowerSearchTerms = lowerVal?.split(' ');

    const items = repo.map(mapRepoElement)
        .filter(el => 
            !val
            || !(val.length > 0) 
            || val.trim() === ''
            || isMatch(lowerSearchTerms, el.searchText.toLowerCase())
        )

    setCardList(items);        

    
}, 750);

const mapRepoElement = (el) => {
    return {
        label: el.props?.title || el.uid,
        id: el.uid,
        el: el,
        searchText: `[${el.uid}] ${el.props?.title} ${el.tags?.map(tag => `#${tag}`)?.join(' ')}`
    }
}

const getCard = (item, study_uid) => {

    const entry = item.el;
    
    const WidgetComponent = entry.widget;

    // data card gets portion of state from page (inherited state) as props
    // data card gets props from repo entry if any
    // data card contains widget
    // data cards state is injected into widget via props
    // widget may modify its parent data card state via stateContext
    //      this would cause widget to receive new props and redraw
    //      it's the best method i could think of right now with short notice
    
    // grab relevant state items from the page
    // this is for the inherited state items that come from the page, that probably won't be adjusted in card
    //let pageState = this.props.state;
    // let inheritOptions = WidgetComponent.inheritOptions || [];
    // inheritOptions.forEach(stateKey => pageState[stateKey] = this.state[stateKey]);

    // //this.props.expandRoot}
    const pageState = {};

    return <div>
        <div>
            <DataCard
                key={entry.uid}
                studyId={study_uid}
                widgetId={entry.uid}
                repoEntry={entry}
                expandRoot={null}
                {...pageState}
            />
        </div>
        <div style={{ marginTop: 10 }}>
            {item.searchText}
        </div>
    </div> 
}

export const ChildCardAdder = (props) => {

    const {id, type, title, contents, data} = props.item;
    const [tileContent, setTileContent] = useState(contents || []);

    const study_uid = useSelector(state => state.study?.uid);
    const auth = useSelector(state => state.auth);

    const [selectedCard, setSelectedCard] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const repo = getRepo(study_uid);
    const [cardList, setCardList] = useState(repo?.map(mapRepoElement));

    

    const addCard = (newItem, callback) => {
        console.log('addCard', newItem);
        const card_id = newItem?.id;
        const body = {
            data: {
                cards: data?.cards ? [...data.cards, card_id] : [card_id]
            },
            mergeData: true
        };
        const response = apiService.aPatch(auth, `${globals.apiRoot}/study/${study_uid}/content/${id}`, body)
        .then(() => {
            console.log('updated');
            if( callback ){
                callback();
            }
        })
        .catch((err) => {
            console.error(response);
        })


        // apiService.aPost(auth, `${globals.apiRoot}/study/${study_uid}/content/${id}/child`, body)
        //     .then(res => res.json())
        //     .then(res => {
        //         if (cb)
        //             cb(res, body)
        //     })
        //     .catch((err) => console.error(err));
    }

    let child_types = cardList;

    const addSelectedCard = () => {
        addCard(selectedCard, () => {
            console.log('added', selectedCard);
            if( props.reloadParent ){
                console.log('reloading parent');
                props.reloadParent();
            }
        })
    }

    const searchChanged = (newValue) => {
        setSearchText(newValue);
        updateListDebounced(newValue, repo, setCardList);
    }

    
       



    const selected = (payload) => {
        addCard(payload, () => {
            // callback:
            console.log('added', payload);
            if( props.reloadParent ){
                console.log('reloading parent');
                props.reloadParent();
            }
        })
    }

    const modalRoot = document.getElementById('modal-root');

    const requestClose = () => {
        if( props.closeRequested ){
            props.closeRequested();
        }
        if( props.reloadItem ){
            props.reloadItem();
        }
    };

    return ReactDOM.createPortal(
        <div className='element-property-editor-modal-container'>
            <div className='element-property-editor-border'>
                <div className='child-card-adder'>
                    <div className='search'>
                        <input type='text' placeholder="search" value={searchText || ""} 
                        onChange={(ev) => searchChanged(ev.target.value)} />
                    </div>
                    <ul className='list'>
                        {child_types?.map(ct => 
                            <li key={ct.id} className={ct.id === selectedCard?.id ? 'sel' : ''} onClick={() => setSelectedCard(ct)}>
                                {ct.label}
                            </li>
                        )}
                    </ul>
                    <div className='preview'>
                        {selectedCard ? getCard(selectedCard, study_uid) : 'select card to preview'}
                        

                        
                    </div>
                </div>
                
                <div className='footer'>
                    <div className='btn0' onClick={addSelectedCard}>add</div>
                    &nbsp;&nbsp;
                    <div className='btn0' onClick={requestClose}>close</div>
                </div>
            </div>
        </div>,
        modalRoot
    )

    //return //<span className={props.className} style={{ position: "relative", ...props.style }} title="Add child element">
    return <ComboBoxCustom
            className={props.className}
            menuWidth={140}
            items={child_types}
            button={<i
                className={'fal fa-plus ' + props.className}
                style={{ padding: '4px 10px', ...props.style }}/>
            }
            selectionChanged={(x) => selected(x)}
    />


    

    //</span>

}

