import * as React from 'react';
import './CardFlipper.scss';


export class CardFlipper extends React.Component {

    notify = (selectedIndex) => {
        if( this.props.selectedIndexChanged ){
            this.props.selectedIndexChanged(selectedIndex);
        }
    }

    handleDotClick = (index) => {
        this.notify(index);
    }

    handleLeftClick = () => {
        const selectedIndex = this.props.selectedIndex || 0;
        const newIndex = Math.max(0, selectedIndex - 1);
        this.notify(newIndex);
    }

    handleRightClick = () => {
        const selectedIndex = this.props.selectedIndex || 0;
        const nbItems = this.props.nbItems || 5;
        const newIndex = Math.min(nbItems - 1, selectedIndex + 1);
        this.notify(newIndex);
    }

    renderDot = (i, selected) => {
        return <span className='dot' key={`dot${i}`} onClick={() => this.notify(i)}>
            {selected ?
                <i className='fas fa-circle'/>
                : <i className='fal fa-circle'/>
            }
        </span>
    }

    render() {

        const selectedIndex = this.props.selectedIndex || 0;
        const nbItems = this.props.nbItems || 5;

        let dots = [];
        for( let i = 0; i < nbItems; i++ ){
            dots.push(this.renderDot(i, i === selectedIndex));
        }

        return <div className={`${this.props.className} card-flipper`}>

            <div className={'left fbtn' + (selectedIndex === 0 ? ' disabled' : '')} onClick={this.handleLeftClick}>
                <i className='fal fa-arrow-left'/>
            </div>

            <div className='middle'>
                {dots}
            </div>

            <div className={'right fbtn' + (selectedIndex === nbItems - 1 ? ' disabled' : '')} onClick={this.handleRightClick}>
            <i className='fal fa-arrow-right'/>
            </div>

        </div>;

    }


}

