import React, { useEffect } from 'react'
import * as d3 from 'd3'
import ReactResizeDetector from 'react-resize-detector'
import ReactWordcloud from 'react-wordcloud'

let renderCounter = 0;

const DWordCloud = ({ compute, renderConfig }) => {
  
  const linearScale = d3.scaleLinear().domain(renderConfig?.domain).range(renderConfig?.color)
  const node = React.useRef()

  useEffect(() => {
    var xScale = d3
      .scaleLinear()
      .domain([-10, 40])
      .range([0, 600])

    var legendScale = d3
      .scaleLinear()
      .domain([-10, 0, 10])
      .range(renderConfig?.color)

    d3.select(node.current)
      .select('#wrapper')
      .selectAll("rect")
      .data([-10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
      .enter()
      .append("rect")
      .attr("width", 11)
      .attr("height", 11)
      .attr("x", d => xScale(d))
      .style("fill", d => legendScale(d))

    d3.select(node.current)
      .select('#axis')
      .selectAll('text')
      .data(['Negative', 'Neutral', 'Positive'])
      .enter()
      .append("text")
      .text(d => d)
      .attr('transform', (d, i) =>
        i == 0 ? 'translate(-13, 28)' : i == 1
          ? 'translate(105, 28)' : 'translate(220, 28)'
      )
      .style('font-size', '12px')
  })

  return (
    <div className='wordCloud-head'>
      <ReactResizeDetector handleWidth handleHeight refreshMode='debounce' refreshRate={1000}>
        {({width, height}) => {
          if( !width || !height ) return <span/>

          return (
            <ReactWordcloud
              words={compute?.data || []}
              key={'render' + renderCounter++}
              size={[width, `${height > 500 ? 500 : height - 30}`]}
              minSize={[140, 140]}
              options={renderConfig?.options}
              callbacks={{
                getWordColor: ({ color }) => linearScale(color),
                ...renderConfig?.callbacks
              }}
            />
          )
        }}
      </ReactResizeDetector>
      <div className='legend'>
        <svg width='290px' height='30' ref={node}>
            <g id='wrapper' transform='translate(20, 0)'></g>
            <g id='axis' transform='translate(20, -4)'></g>
        </svg>
      </div>
    </div>
  )
}

export default DWordCloud
