import * as React from 'react';
import { useState, useRef } from 'react';
import { format as d3Format } from 'd3-format';
import * as globals from '../../globals';
import './SimpleTable.scss';

const starPath13 = "M6.5,3.39421433181997E-08C6.65625143051147,1.39022390044374E-07,6.78385543823242,0.106769137084484,6.88281106948853,0.320307165384293L8.6406192779541,3.87500095367432 12.5624990463257,4.44531106948853C12.8541660308838,4.49218893051147,13,4.61197996139526,13,4.80468320846558L12.9999742507935,4.80468320846558C12.9999742507935,4.91926527023315,12.9322662353516,5.04426431655884,12.7968492507935,5.17968130111694L9.96091175079346,7.9453010559082 10.632791519165,11.8515539169312C10.6379995346069,11.888014793396 10.640604019165,11.9400978088379 10.640604019165,12.0078001022339 10.640604019165,12.1171741485596 10.6132612228394,12.2096214294434 10.5585746765137,12.2851438522339 10.5038871765137,12.3606643676758 10.4244613647461,12.3984251022339 10.3202972412109,12.3984251022339 10.2213411331177,12.3984251022339 10.1171770095825,12.3671731948853 10.0078039169312,12.3046684265137L6.49998664855957,10.4609174728394 2.99217104911804,12.3046684265137C2.87758898735046,12.3671731948853 2.77342438697815,12.3984251022339 2.6796772480011,12.3984251022339 2.57030367851257,12.3984251022339 2.48827362060547,12.3606643676758 2.43358707427979,12.2851438522339 2.37890028953552,12.2096214294434 2.35155701637268,12.1171741485596 2.35155701637268,12.0078001022339 2.35155701637268,11.9765501022339 2.35676574707031,11.924467086792 2.36718320846558,11.8515539169312L3.03906202316284,7.9453010559082 0.19531212747097,5.17968130111694C0.0651040449738503,5.03905582427979 5.00003807246685E-08,4.91405630111694 5.00003807246685E-08,4.80468320846558 5.00003807246685E-08,4.61197090148926 0.145834058523178,4.49218034744263 0.43750211596489,4.44531106948853L4.35938119888306,3.87500095367432 6.11718893051147,0.320307165384293C6.21614503860474,0.106769137084484,6.34374904632568,1.39022390044374E-07,6.5,3.39421433181997E-08z";

const autoFColor = (color) => {
    return null; //return '#ffffff';
}

const isNull = (x) => x === null || x === undefined;

export const SimpleTable = (props) => {

    const [sortCol, setSortCol] = useState(null); // dataKey of column to sort
    const [sortDir, setSortDir] = useState(0); // {0, 1, 2, (3, 4)} where 0 is unsorted

    const data = props.data || [];
    let columns = props.columns || [];
    const showTabular = props.showTabular || false;

    let colFormats = {};
    colFormats['default:stack'] = d3Format('.0%');

    const tableRef = useRef(null);
    
    const handleColClick = (col) => {
        
        const colObj = columns.find(c => c.key === col);
        //console.log('handleColClick', col, colObj);
        
        
        if( col === sortCol ){
            let newSortDir = colObj?.absToggle ?
                    sortDir == 0 ? 1 :
                    sortDir == 1 ? 3 :
                    sortDir == 3 ? 2 :
                    sortDir == 2 ? 4 :
                    0
                :
                    sortDir == 0 ? 1 :
                    sortDir == 1 ? 2 :
                    0;
            let newSortCol = newSortDir === 0 ? null : col;
            setSortCol(newSortCol);
            setSortDir(newSortDir);
        }
        else {
            setSortCol(col);
            setSortDir(1);
        }
    }
    

    const copyTable = () => {

        const tabletocopy = tableRef.current;
        const tableRange = document.createRange();
        tableRange.selectNode(tabletocopy);
        const tableSelection = window.getSelection();
        tableSelection.removeAllRanges();
        tableSelection.addRange(tableRange);

        try {
            // Copy the selected text to clipboard using Clipboard API
            document.execCommand('copy');
            window.topBar.enqueueSnack({
                id: 'copy',
                message: 'Copied to clipboard!',
                to: 3000,
                success: false
            })
            console.log('Table copied to clipboard');
        } catch (err) {
            window.topBar.enqueueSnack({
                id: 'error',
                message: 'Something went wrong',
                to: 3000,
                success: false
            })
            console.error('Unable to copy table to clipboard', err);
        }

        tableSelection.removeAllRanges();
  }

    const sortRows = (data) => {
        if (!data) return null;

        if (sortDir == 0) return data; // unsorted

        const col = columns?.find(entry => entry.key === sortCol) || { type: "number" };
        //if (!col) return data; // sort column not found

        let rows = [...data]; // for immutable sort
    
        const safeDiff = (a, b) => {
            if ((b === null || b === undefined) && (a === null || a === undefined)) {
                return 0;
            }
            if (b === null || b === undefined) {
                return 1;
            }
            if (a === null || a === undefined) {
                return -1;
            }
            return (a - b);
        }
    
        const safeAbsDiff = (a, b) => {
            if ((b === null || b === undefined) && (a === null || a === undefined)) {
                return 0;
            }
            if (b === null || b === undefined) {
                return 1;
            }
            if (a === null || a === undefined) {
                return -1;
            }
            return Math.abs(a) - Math.abs(b);
        }
    
        {
            const sortKey = sortCol;
            const dir = sortDir;

            // if (col.type === "number" || col.type === "bar") {
            //     return dir === 1 ? rows.sort((a, b) => b[sortKey] - a[sortKey]) :
            //         dir === 2 ? rows.sort((a, b) => a[sortKey] - b[sortKey]) :
            //         rows;
            // } else if (col.type === "cell") {
            //     return dir === 1 ? rows.sort((a, b) => b[sortKey]?.value - a[sortKey]?.value) :
            //         dir === 2 ? rows.sort((a, b) => a[sortKey]?.value - b[sortKey]?.value) :
            //         rows;
            // } else {
            //     // assume string
            //     return dir === 1 ? rows.sort((a, b) => a[sortKey] < b[sortKey] ? -1 : a[sortKey] > b[sortKey] ? 1 : 0 ) :
            //         dir === 2 ? rows.sort((a, b) => a[sortKey] < b[sortKey] ? 1 : a[sortKey] > b[sortKey] ? -1 : 0) :
            //         rows;
            // }

            const boolToValue = (val) =>
                val === true ? 1 :
                    val === false ? 0 :
                        null;

            if (col.type === "number" || col.type === "bar") {
                return dir === 1 ? rows.sort((a, b) => safeDiff(b[sortKey], a[sortKey])) :
                    dir === 2 ? rows.sort((a, b) => safeDiff(a[sortKey], b[sortKey])) :
                        dir === 3 ? rows.sort((a, b) => safeAbsDiff(b[sortKey], a[sortKey])) :
                            dir === 4 ? rows.sort((a, b) => safeAbsDiff(a[sortKey], b[sortKey])) :
                                rows;
            } else if (col.type === "cell") {
                return dir === 1 ? rows.sort((a, b) => safeDiff(b[sortKey]?.value, a[sortKey]?.value)) :
                    dir === 2 ? rows.sort((a, b) => safeDiff(a[sortKey]?.value, b[sortKey]?.value)) :
                        dir === 3 ? rows.sort((a, b) => safeAbsDiff(b[sortKey]?.value, a[sortKey]?.value)) :
                            dir === 4 ? rows.sort((a, b) => safeAbsDiff(a[sortKey]?.value, b[sortKey]?.value)) :
                                rows;
            } else if (col.type === "boolean") {
                return dir === 1 ? rows.sort((a, b) => safeDiff(boolToValue(b[sortKey]), boolToValue(a[sortKey]))) :
                    dir === 2 ? rows.sort((a, b) => safeDiff(boolToValue(a[sortKey]), boolToValue(b[sortKey]))) :
                        dir === 3 ? rows.sort((a, b) => safeAbsDiff(boolToValue(b[sortKey]), boolToValue(a[sortKey]))) :
                            dir === 4 ? rows.sort((a, b) => safeAbsDiff(boolToValue(a[sortKey]), boolToValue(b[sortKey]))) :
                                rows;
            } else {
                // assume string
                return dir === 1 ? rows.sort((a, b) =>
                    isNull(a[sortKey]) && isNull(b[sortKey]) ? 0 :
                        isNull(a[sortKey]) ? -1 :
                            isNull(b[sortKey]) ? 1 :
                                a[sortKey] < b[sortKey] ? -1
                        : a[sortKey] > b[sortKey] ? 1
                            : 0) :
                    dir === 2 ? rows.sort((a, b) =>
                        isNull(a[sortKey]) && isNull(b[sortKey]) ? 0 :
                            isNull(a[sortKey]) ? 1 :
                                isNull(b[sortKey]) ? -1 :
                                    a[sortKey] < b[sortKey] ? 1
                                        : a[sortKey] > b[sortKey] ? -1 : 0) :
                    rows;
            }

        }
    }

    const getSortIndicator = (col) => {
        if (sortDir === 0 || col?.key !== sortCol) return null;

        let sortDir2 = col.type === 'string' ? 3 - sortDir : sortDir;
        return <span className='sort-indicator'>
            {(sortDir2 === 1 || sortDir2 == 3) ? <i className='fas fa-caret-down' /> :
                <i className='fas fa-caret-up' />}
        </span>;
    }

    const unRollColumns = (cols) => {
        
        if (!cols || !cols?.length > 0) return [];
        var newCols = [];

        cols?.forEach(col => {
            if( col.type === "string" || col.type === "number" || col.type === "boolean" )
            {
                newCols.push(col);
            }
            else if( col.type === "bar")
            {

                let c = {
                    key: col?.key,
                    label: col?.label,
                    category: col?.category,
                    type: "number",
                    width: col?.exportWidth, // leave null to use default ??
                    formatString: col?.formatString,
                    sticky: false,
                    hAlign: col?.hAlign,
                    headerWrap: col.headerWrap
                }
                
                newCols.push(c);
            }
            else if( col.type === "stacked-bar" || col.type === "dots" )
            {

                col?.items.forEach(item => {

                    let c = {
                        key: item?.key,
                        label: item?.label,
                        category: col?.category,
                        type: "number",
                        width: item?.exportWidth, // don't use col width, only item width if specified, otherwise will use default
                        formatString: item?.formatString || col?.formatString,
                        sticky: false,
                        hAlign: item?.hAlign || col?.hAlign,
                        headerWrap: col.headerWrap
                    }
                    
                    newCols.push(c);                    
                })
            }
        })
        return newCols;
    }

    columns = showTabular ? unRollColumns(columns) : columns;
    columns.forEach((col, colIndex) => {
        if (col.formatString) {
            colFormats[colIndex] = d3Format(col.formatString);
        }
        if (col.items) {
            col.items.forEach((item, itemIndex) => {
                if (item.formatString) {
                    colFormats[colIndex + '/' + itemIndex] = d3Format(item.formatString);
                }
            })
        }
    });
    
    const sortedRows = sortRows(data);
    const fallbackWidth = 100;
    let tableWidth = 0;
    columns.forEach(col => tableWidth += (col.width || fallbackWidth));

    let columnCategories = null;
    let showColumnCategories = columns.find(col => col.category) ? true : false;
    if (showColumnCategories) {
        columnCategories = [];
        let currentCat = null;
        columns.forEach((col, colIndex) => {
            let catLabel = col.category || '';
            if (catLabel !== currentCat?.label) {
                currentCat = {
                    label: catLabel,
                    span: 0,
                    width: 0,
                    columns: []
                }
                columnCategories.push(currentCat);
            }
            currentCat.columns.push(col);
            currentCat.span += col.items?.length > 0 ? col.items.length : 1;
            currentCat.width += col.width || fallbackWidth;
            if (col.sticky) {
                currentCat.sticky = true;
            }
        });
    }

    return (
        <div style={{padding: '0px 20px'}}>
            {/* <div style={{width:60,marginBottom:5, marginTop:10, cursor:'pointer'}}>
                <span><i className='fal fa-clipboard' onClick={copyTable} style={{ fontSize: 14, color:'#0057df'}} /> copy</span>
            </div> */}
        <table className='simple-table' style={props.style} width={tableWidth} ref={tableRef}>
            <thead>
                {showColumnCategories ? <tr className='header-row'>
                    {
                        columnCategories.map((cat, catIndex) => {

                            let width = cat.width;

                            let extraStyle = {
                                width,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                display: 'table-cell'
                            };
                            // if (cat.fillHeader) { // i don't think this property would ever exist on a cat
                            //     extraStyle.backgroundColor = col.fill;
                            //     extraStyle.color = col.color;
                            // }

                            return <th key={cat.label + '|idx:' + catIndex}
                                colSpan={cat.span}
                                className={'' + (cat.sticky ? ' sticky-left' : '')}
                                style={extraStyle}>
                                {cat.label}
                            </th>
                            
                        })
                    }
                </tr> : null}
                    <tr className='header-row'>
                        {
                            columns.map((col, colIndex) => {
                                
                                const showAbs = col.key === sortCol && (sortDir == 3 || sortDir == 4);
                                
                                if (col.type === 'stacked-bar' || col.type === 'dots') {

                                    let width = col.width || fallbackWidth;

                                    return col.items?.map((item, itemIndex) => {

                                        let marker = item.marker || item.shape || (col.type === 'stacked-bar' ? 'box' : 'dot');

                                        let extraStyle = {
                                            width: item.width ? item.width : width / col.items?.length,
                                            display: 'table-cell'
                                        };
                                        if (!col.headerWrap) {
                                            extraStyle.whiteSpace = 'nowrap';
                                            extraStyle.overflow = 'hidden';
                                        }
                                        if (item.headerFill) {
                                            extraStyle.backgroundColor = item.headerFill;
                                        }
                                        if( item.headerColor){
                                            extraStyle.color = item.headerColor;
                                        }

                                        return <th key={item.key + '|idx:' + itemIndex + '_' + colIndex}
                                            className={'sortable '}
                                            title={item.label}
                                            style={extraStyle} onClick={() => handleColClick(item?.key)}
                                        >
                                            {marker === 'dot' ?
                                                <svg width={10} height={10} style={{ marginRight: 5 }}>
                                                    <ellipse cx={5} cy={6} rx={4} ry={4} style={{ fill: item.color || '#000000' }} />
                                                </svg>
                                                : marker === 'box' ?
                                                    <svg width={10} height={10} style={{ marginRight: 5 }}>
                                                        <rect x={0} y={0} width={10} height={10} style={{ fill: item.color || '#000000' }} />
                                                    </svg>
                                                : marker === 'star' ?
                                                        <svg width={13} height={13} style={{ marginRight: 5 }}>
                                                        <g transform={`translate(${0},${0})`}>
                                                            <path style={{ fill: item.color || '#000000' }} d={starPath13} />
                                                        </g>
                                                    </svg>
                                                : null}
                                            {item.label}
                                            {getSortIndicator(item)}
                                        </th>
                                    })
                                }
                                else {

                                    let width = col.width || fallbackWidth;

                                    let extraStyle = {
                                        width,
                                        display: 'table-cell'
                                    };
                                    if (!col.headerWrap) {
                                        extraStyle.whiteSpace = 'nowrap';
                                        extraStyle.overflow = 'hidden';
                                    }
                                    // if (col.fillHeader) { // deprecated! delete me soon
                                    //     extraStyle.backgroundColor = col.fill; // deprecated! delete me soon
                                    //     extraStyle.color = col.color; // deprecated! delete me soon
                                    // }
                                    if (col.headerFill) {
                                        extraStyle.backgroundColor = col.headerFill;
                                    }

                                    if (col.headerColor) {
                                        extraStyle.color = col.color;
                                    }

                                    if (col.type === 'string' && colIndex === 0) {
                                        extraStyle.textAlign = "left";
                                    }
                                        
                                    return <th key={col.key + '|idx:' + colIndex}
                                        title={col.label}
                                        className={'sortable ' + (col.className || "") + (col.sticky ? ' sticky-left' : '')}
                                        style={extraStyle} onClick={() => handleColClick(col?.key)}>
                                        {showAbs ? `|${col.label}|` : col.label}
                                        {getSortIndicator(col)}
                                    </th>

                                }
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                {sortedRows?.map((row, rowIndex) => {
                    let extraRowStyle = null;
                    if (row.htmlStyle?.tr) {
                        extraRowStyle = row.htmlStyle.tr;
                    }
                    return <tr key={row.uid + '|idx:' + rowIndex} className={'data-row' + ' ' + (row.className || '')} style={extraRowStyle}>
                        {
                            columns.map((col, colIndex) => {

                                let colProps = row?.columns?.[col.key];
                                
                                
                                const showAbs = col.key === sortCol && (sortDir == 3 || sortDir == 4);
                                
                                if (col.type === 'stacked-bar' || col.type === 'dots') {

                                    let colSpan = col.items?.length;

                                    let width = col.width || fallbackWidth;
                                    let extraStyle = {
                                        width,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden'
                                    };

                                    let glyph = null;
                                    if (col.type === 'stacked-bar') {
                                        
                                        let barContainerWidth = width - 20;
                                        glyph = <div className='bar-space'>
                                            <div className='bar-container' style={{ width: barContainerWidth }}>
                                                {
                                                    col.items?.map((item, itemIndex) => {
                                                        let cell = row[item.key];
                                                        let val = cell;
                                                        let barWidth = val ? barContainerWidth * val : 0;
                                                        let formattedValue = val === undefined ? "." :
                                                            val === null ? "." :
                                                                colFormats[colIndex + '/' + itemIndex] ? colFormats[colIndex + '/' + itemIndex](val) :
                                                                    val;
                                                        let formattedValue2 = val === undefined ? "." :
                                                            val === null ? "." :
                                                                colFormats['default:stack'](val);
                                                        let toolTip = item.label + ': ' + formattedValue;
                                                        let color = item.color || '#256ecd';
                                                        let fColor = item.fColor || autoFColor(color);
                                                        return <div className='bar stacked' key={itemIndex} style={{
                                                            width: barWidth,
                                                            backgroundColor: color
                                                        }}
                                                            title={toolTip}>
                                                            <span className='stacked-bar-value' style={{ color: fColor }}>{val >= 0.08 ? formattedValue2 : <>&nbsp;</>}</span>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    else if (col.type === 'dots') {

                                        let svgWidth = width - 20;
                                        let padding = 5;
                                        let lineWidth = svgWidth - (padding * 2)

                                        const min = col.min === null || col.min === undefined ? 0 : col.min;
                                        const max = col.max === null || col.max === undefined ? 1 : col.max;
                                        const range = max - min;

                                        let gap = null;
                                        if (col.items?.length == 2) {
                                            let val1 = row[col.items[0].key];
                                            let val2 = row[col.items[1].key];
                                            if (val1 !== null & val1 !== undefined && val2 !== null && val2 !== undefined) {
                                                gap = {
                                                    x1: lineWidth / range * (val1 - min) + padding,
                                                    x2: lineWidth / range * (val2 - min) + padding,
                                                    y1: 7,
                                                    y2: 9,
                                                    fill: col.items[1].color
                                                }
                                                if (gap.x2 < gap.x1) {
                                                    let temp = gap.x2;
                                                    gap.x2 = gap.x1;
                                                    gap.x1 = temp;
                                                    gap.fill = col.items[0].color;
                                                }
                                                gap.width = gap.x2 - gap.x1;
                                                gap.height = gap.y2 - gap.y1;
                                            }
                                        }

                                        glyph = <div className='dots-space' >
                                            <div className='dots-container' style={{ width: svgWidth }}>
                                                <svg width={svgWidth} height={17}>
                                                    <line x1={0 + padding} x2={svgWidth - padding} y1={8} y2={8} className='hline' />
                                                    {
                                                        gap ? <rect x={gap.x1} y={gap.y1} width={gap.width} height={gap.height} style={{ fill: gap.fill || 'red' }} /> : null
                                                    }
                                                    {
                                                        col.items?.map((item, itemIndex) => {
                                                            let cell = row[item.key];
                                                            let val = cell;
                                                            let formattedValue =
                                                                val === undefined ? "." :
                                                                    val === null ? "." :
                                                                        colFormats[colIndex + '/' + itemIndex] ? colFormats[colIndex + '/' + itemIndex](val) :
                                                                            val;
                                                            let toolTip = item.label + ': ' + formattedValue;
                                                            let xPos =
                                                                val === undefined ? null :
                                                                    val === null ? null :
                                                                        lineWidth / range * (val - min);
                                                            let dotStyle = {
                                                                fill: item.color || 'black'
                                                            }
                                                            const shape = item.shape || 'dot';
                                                            return (xPos !== null && xPos !== undefined) ? (
                                                                shape === 'star' ?
                                                                    <g transform={`translate(${xPos + padding - 7}, ${8 - 7})`}>
                                                                        <path style={dotStyle} d={starPath13}>
                                                                            <title>{toolTip}</title>
                                                                        </path>
                                                                    </g>
                                                                    :
                                                                    <ellipse key={itemIndex} cx={xPos + padding} cy={8} rx={4} ry={4} className='dot' style={dotStyle}>
                                                                        <title>{toolTip}</title>
                                                                    </ellipse>
                                                            )
                                                                : null; // missing value
                                                            
                                                        })
                                                    }
                                                    
                                                </svg>
                                            </div>
                                        </div>
                                    }

                                    const toolTipValue = null;

                                    return <td key={col.key + '|' + colIndex} colSpan={colSpan} className={''}
                                        style={extraStyle}
                                        title={toolTipValue}
                                    >
                                        {glyph}
                                    </td>;

                                    
                                }
                                else {
                                
                                    let cell = row[col.key];
                                    let val = cell;
                                    let n = null;
                                    let loading = false;

                                    let width = col.width || fallbackWidth;
                                    let extraStyle = {
                                        width,
                                    };

                                    if (!col.wrap) {
                                        extraStyle.whiteSpace = 'nowrap';
                                        extraStyle.overflow = 'hidden';
                                    }

                                    let hAlign = col.type === 'string' ? 'left' :
                                        col.type === 'number' ? 'right' :
                                            col.type === 'boolean' ? 'center' :
                                                null;

                                    if (col.hAlign) {
                                        hAlign = col.hAlign;
                                    }

                                    extraStyle.textAlign = hAlign;

                                

                                    if (col.type === 'cell') {
                                        val = cell?.value;
                                        loading = cell?.loading;
                                        n = cell?.n;
                                    }

                                    if (showAbs) {
                                        if (val !== undefined && val !== null)
                                            val = Math.abs(val);
                                    }
                                
                                    let formattedValue = loading ? "..." :
                                        val === undefined ? "." :
                                            val === null ? "." :
                                                colFormats[colIndex] ? colFormats[colIndex](val) :
                                                    val;
                                    
                                    if (col.type === 'boolean') {
                                        // formattedValue = (val === true || val === 1) ? "true" :
                                        //     (val === false || val === 0) ? "false" :
                                        //         null;
                                        
                                        formattedValue = (val === true || val === 1) ? <i className='fas fa-circle' /> :
                                            (val === false || val === 0) ? <i className='fal fa-circle' /> :
                                                null;

                                    }

                                    if (col.type === "string" && typeof val === 'object') {
                                        formattedValue = '[unknown type]';
                                        try {
                                            val = JSON.stringify(val);
                                            formattedValue = val;
                                        }
                                        catch { }
                                    }
                                    

                                    let toolTipValue = loading ? "..." :
                                        val === undefined ? "." :
                                            val === null ? "." :
                                                //col.toolTipFormat ? col.toolTipFormat(val) :
                                                colFormats[colIndex] ? colFormats[colIndex](val) :
                                                    val;

                                    let glyph = null;
                                    if (col.type === 'bar') {
                                        let width = col.width || 120;
                                        let barContainerWidth = width - (col.numberWidth || 70);
                                        const min = col.min !== null && col.min !== undefined ? col.min : 0;
                                        const max = col.max !== null && col.max !== undefined ? col.max : 1;
                                        const range = max - min;
                                        //let barWidth = val ? barContainerWidth * val : 0;
                                        let barWidth = val === null || val === undefined ? null :
                                            (val - min) / range * barContainerWidth;
                                        
                                        const border = col.border === null || col.border === undefined ? max == 1 : col.border;

                                        if (border) {
                                            glyph = <div className='bar-space'>
                                                <div className='bar-container' style={{ width: barContainerWidth }}>
                                                    {(barWidth !== null && barWidth !== undefined) ? <div className='bar' style={{
                                                        width: barWidth,
                                                        backgroundColor: colProps?.color || col.color || '#256ecd'
                                                    }}>
                                                    </div> : null}
                                                </div>
                                                <div className='bar-value'>
                                                    {formattedValue}
                                                </div>
                                            </div>
                                        }
                                        else {
                                            glyph = <div className='bar-space'>
                                                <div className='bar-container no-border' style={{ width: barWidth }}>
                                                    {(barWidth !== null && barWidth !== undefined) ? <div className='bar' style={{
                                                        width: barWidth,
                                                        backgroundColor: colProps?.color || col.color || '#256ecd'
                                                    }}>
                                                    </div> : null}
                                                </div>
                                                <div className='bar-value'>
                                                    {formattedValue}
                                                </div>
                                            </div>
                                        }
                                    }

                                    return <td key={col.key + '|' + colIndex} className={((col.sticky ? " sticky" : ""))}
                                        style={{ ...extraStyle, ...colProps?.htmlStyle }}
                                        title={toolTipValue}
                                    >
                                        {glyph || formattedValue}
                                    </td>;

                                }
                                
                            })
                        }
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    );

}


