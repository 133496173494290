import * as React from 'react';
import { useEffect } from 'react';
import './TileContainer.scss';
import { ReactSortable } from '../common/Sortable';
import { aPatch } from '../../utils/apiService';
import { useSelector } from 'react-redux';
import { apiRoot } from '../../globals';
import { FlexItem } from '../explore/FlexItem';

export function TileContainer(props) {

    const auth = useSelector(state => state.auth);
    const study_uid = useSelector(state => state.study?.uid);
    const { editMode } = props.edit || {}

    useEffect(() => {
            props?.setLayoutReady?.(true);
    }, []);

    const orderChildren = (parent_id, order) =>
        aPatch(auth, `${apiRoot}/study/${study_uid}/content/${parent_id}/order-children`, { order })
            .then(() => console.log('ordered'))
            .catch((err) => console.error(err));

    // let style = {

    // }
    // const tileSpacing = props.tileSpacing || '15px';



    //console.log(style);

    const item = props.item || props.items; // backward compat

    return <div className='tile-layout'>
        {props.children}
    </div>;

    /* return <div className='tile-container' style={style}>{props.children}</div>; */

    // return <>{
    //     !editMode
    //     ? <div className='tile-container' style={style}>{props.children}</div>
    //     : <ReactSortable
    //         list={item?.contents}
    //         reorder
    //         setList={newState => orderChildren(newState[0].parent_id, newState.map(i => i.id))}
    //         tag='div'
    //         className='tile-container'
    //         style={style}
    //     >
    //         {props.children}
    //     </ReactSortable>
    // }</>
}
