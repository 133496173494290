import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
//import { Tab, TabStrip, TabStrip2 } from "../common/TabStrip";
import { ContentLoader } from "./ContentLoader";
import "./ContentViewer.scss";
import { EditModeWrapper } from "./EditModeWrapper";
import { wrapAsPage } from "./PageContentViewer";
import { SelectionBlockElement } from '../explore/SelectionBlockElement'
import * as metaUtils from '../../utils/metaUtils';



// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}


export const TabControlElement = (props) => {
	
	const study_uid = useSelector((state) => state.study?.uid);
	const auth = useSelector(store => store.auth);
	const [meta, setMeta] = useState();

	let query = useQuery();
	const params = useParams();
	const history = useHistory();
	const loc = useLocation();

	// console.log('location', loc);
	
	const item = props.item;

	const idata = item?.data;
	const zIndex = idata?.zIndex || 101;
	//const [item, setItem] = useState(props.item);
	//const [error, setError] = useState(null);

	// const [reloadCounter, setReloadCounter] = useState(0);
	// const reloadSelf = () => {
	// 	console.log("reload tilecontainer called");
	// 	setReloadCounter(reloadCounter + 1);
	// };

	//const [selIndex, setSelIndex] = useState(0);

	//const tileContent = item?.contents;

	

	const wide = item?.data?.wide;
	const showSelector = idata.showSelector;
	const selectorType = idata.selectorType;
	// console.log('wide', wide)

	const stripStyle = {
		//margin: item?.data?.margin || (wide ? "0 20px" : "0 auto 20px auto"),
		position: "sticky",
		top: idata?.tabStripClass === 'subtabs' ? 50 : 0,
		zIndex,
		// backgroundColor: item?.data?.backgroundColor || "inherit"
	};
	const contentStyle = {
		margin: item?.data?.margin || (wide ? "0 20px" : "0 auto 20px auto"),
	};
	//console.log('tilecontainer.wide', wide);

	// const SelectedTabChanged = (newTab) => {
	// 	//setSelIndex(a);
	// 	history.push(`?${item.id}=${newTab}`);
	// }


	//const [myLayoutReady, setMyLayoutReady] = useState(false);

	const selTab = query.get(item.id);
	const selItem = item?.contents?.find(i => i.id === selTab) || item?.contents?.[0];

	

	const setLayoutReady = (val) => {
		//console.log(`tabControl ${item.id} setting layout ready to:${val}`);
		//setMyLayoutReady(val);
		props.setLayoutReady?.(val);
	}

	React.useEffect(() => {
		if (!selItem) {
			setLayoutReady(true);
		}
	}, [item, selItem])


	let tabContent = selItem ? <ContentLoader
		key={selItem.id}
		id={selItem.id}
		pageLevel={props.pageLevel}
		reloadParentItem={props.reloadItem}
		edit={props.edit}
		restore={props.restore}
		restoredItem={selItem?.deleted}
		setLayoutReady={setLayoutReady}
	/> : null;

	
	 React.useEffect(() => {

        const f = async () => {
            
            try {
				let sel = await metaUtils.getMeta(study_uid, auth, 'selectionTypes');
				if (sel) {
					setMeta(sel[selectorType])
				}
					
            }
            catch (err) {
                console.error(err);
            }
            
        }
        f();

	 }, [selectorType])
	

	let selectorData = {
		item: {
				data: {
					type: showSelector && selectorType ? selectorType : "", 
					imageProperty: 'image',
					imageFit: 'contain', 
					largeImageForm: false,
					largeImageHeight: 40,
					largeImagePadding: 0,
					imageStyle: {"border":"0px solid white",":hover":{"cursor":"pointer","transform":"scale(1.1)","transition":"all ease 500ms"}},
					hideLabel: true,
					hideType: true,
					style: {"border":"0px solid white"}

				}
			}
	}



	if (meta?.showImage === false) {
		selectorData = {
			item: {
				data: {
					type: showSelector && selectorType ? selectorType : "", 
					hideLabel: false,
					hideType: false,
					style: {"border":"0px solid white"}

				}
			}

		}
	}



	const content = (
		<EditModeWrapper
			pageLevel={props.pageLevel}
			key={item.id}
			className="has-children"
			item={item}
			reloadParentItem={props.reloadParentItem}
			edit={props.edit}
			restoredItem={props?.restoredItem}
		>
			
			<div className={"section-element " + (wide ? "wide-width" : "page-width")}	style={stripStyle}>
				{!props.asPage && item?.title ? (
					<div className="title">{item?.title}</div>
					) : null
				}
				<div style={{textAlign: idata?.tabsAlignment || 'left'}}>
					<div className={'tabstrip for-page ' + idata?.tabStripClass} style={props.style}>
						{							
							item?.contents?.map((citem, idx) =>
							{
								const sel = selTab == citem.id || (idx === 0 && !selTab);
								var q = new URLSearchParams(useLocation().search);
								q.set(item.id, citem.id);
								const to = "?" + q.toString()

								return <React.Fragment key={citem.id || idx}>
									{idx == 0 && showSelector ? <React.Fragment key={citem.id || idx}>
										<SelectionBlockElement key={idx} {...selectorData} />
										<Link key={citem.id || idx} className={"tab" + (sel ? " sel" : "")} value={citem.id} to={to} onClick={(ev) => { setLayoutReady(false) }}	>{citem.title}</Link>
									</React.Fragment>
										:
										<Link key={citem.id || idx} className={"tab" + (sel ? " sel" : "")} value={citem.id} to={to} onClick={(ev) => { setLayoutReady(false) }}	>{citem.title}</Link>
									}
								</React.Fragment>
							})
						}
						{item?.contents?.length ? null : (
							<span style={{ opacity: 0.01 }}>empty</span>
						)}
					</div>
				</div>

				
			</div>
			<div className={"content-section " + (wide ? "wide-width" : "page-width")}
				style={contentStyle}
			>
				{ tabContent }
			</div>
		</EditModeWrapper>
	);

	return props.asPage
		? wrapAsPage(
			props.item,
			content,
			null,
			study_uid,
			params,
			props.edit,
			props.restore,
			reloadSelf,
		)
		: content;
};
