import { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'  
import { bindActionCreators } from 'redux'
import { actionCreators as modelActionCreators } from '../../store/Model'
import { actionCreators as fordForecastActionCreators } from '../../store/FordForecast'
import { actionCreators as selectionsActionCreators } from '../../store/Selections'
import { actionCreators as GlobalStoreCreator } from "../../store/General"
import { Tab_buttons, TabStrip2 } from '../common/TabStrip.js'
import { ToggleSwitch } from '../common/ToggleSwitch.js'
import { EditConstraints } from '../optimize/EditConstraints.js'
import { EditSliderConstraints } from '../optimize/EditSliderConstraints.js'
import { ProductBlock } from '../product-drawer/ProductBlock.js'
import { EditAreaAtts2 } from './EditAreaAtts2.js'
import { EditAreaSliders } from './EditAreaSliders.js'
import './ScenarioEditor.scss'
import { ScenarioSummary } from './ScenarioSummary.js'
import { OpenSaveScenario } from '../scenarios/OpenSaveScenario.js'
import { SelectionBlockElement } from '../explore/SelectionBlockElement.js'
import { FilterChooser } from '../filter/FilterChooser'
import { debounce } from '../../utils/debounce'
import { computeFordModel } from '../../utils/forecastHelper'
import { active } from 'd3-transition'
import { guid } from '../../utils/guid.js'


export const ScenarioEditorMultiConfigsTAM = (props) => {

    const [selectedTabValue, setSelectedTabValue] = useState('')
    const [summaryChangesOnly, setSummaryChangesOnly] = useState(true)
    const [successForecast, setSuccessForecast] = useState(false)
    const [forecastRunning, setForecastRunning] = useState(false)
    // const [fordSelector, setFordSelector] = useState('false')
    // const [configModels, setConfigModels] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    // const history = useHistory()
    const dispatch = useDispatch()

    const { study, auth, model, filter, selection } = useSelector(state => ({
        study: state.study,
        auth: state.auth,
        model: state.model,
        selection: state.selection,
        filter: state.filter,
    }))
    
    const suvTruck = useSelector(state => state.selection.suvTruck)
    const suvTruckId = suvTruck?.uid || 1;
    
    const studyId = study.uid
    const fordSelector = suvTruck?.name === 'suv' ? selection?.fordsuv : selection?.fordtruck
    const attsToMonitor = ['totalmodels', 'availrefuel', 'servicecenter', 'degrade', 'warranty', 'assist', 'repaircost', 'insurecost', 'interest', 'timecharge', 'fastcharger']
    
    const filtersDatasetId = props.filtersDatasetId;

    useEffect(() => {
        if (props.models?.length) {
            let defaultTab = (props.models[0])
            setSelectedTabValue(defaultTab.label)
            dispatch(modelActionCreators.setActiveConfig(defaultTab.name))
        }
    
    }, [suvTruck])
    
    const activeConfig = model?.activeConfig
    
    if (!activeConfig || !selectedTabValue) return null

  
    
    // console.log('suvTruckId', suvTruckId);

    const dcard_models = props.models
    // console.log('dcard_models', dcard_models);

    
    let activeTabs = dcard_models?.map(cm => ({
        name: cm.name,
        label: cm.label,
        icon: "fa-truck-pickup"
    }))
    // console.log("dcard_models", dcard_models);
    // console.log("selectedTabValue", selectedTabValue);

    
    const available = fordSelector?.available ?? false // fordSelector ? fordSelector.available : false
    const beqLevel = (model[activeConfig]?.config?.brandEquitiesDef || {}).level || 'product'

    const productSelection = fordSelector
    const beqSelection = null// selections[beqLevel] //fix
    const summarySelectedItem = fordSelector

    const tab = selectedTabValue
    const format = props.format || 'config'

    const selTabIndex = activeTabs.findIndex(f => f.label === selectedTabValue)

    const truckFilter = {
        item: {
            data: {
                type: 'suvTruck',
                imageProperty: 'image',
                imageFit: 'contain',
                largeImageForm: false,
                largeImageHeight: 15,
                largeImagePadding: 0,
                imageStyle: { "border": "0px solid white", ":hover": { "cursor": "pointer", "transform": "scale(1.2)", "transition": "all ease 500ms" }, "height": "auto", "width": "30px", "marginLeft": "auto" },
                hideLabel: false,
                hideType: true,
                style: { "height": 35, "border": "0px solid white", "textTransform": "uppercase" }

            }
        }
    }
    
    const fordTruckSelector = {
        item: {
            data: {
                type: 'fordtruck',
                imageProperty: 'image',
                imageFit: 'contain',
                largeImageForm: false,
                largeImageHeight: 40,
                largeImagePadding: 0,
                imageStyle: { "border": "0px solid #ccc", ":hover": { "cursor": "pointer", "transform": "scale(1.1)", "transition": "all ease 500ms" } },
                hideLabel: false,
                hideType: false,
                style: { "border": "1px solid #ccc" }

            }
        }
    }
    
    const fordSuvSelector = {
        item: {
            data: {
                type: 'fordsuv',
                imageProperty: 'image',
                imageFit: 'contain',
                largeImageForm: false,
                largeImageHeight: 40,
                largeImagePadding: 0,
                imageStyle: { "border": "0px solid #ccc", ":hover": { "cursor": "pointer", "transform": "scale(1.1)", "transition": "all ease 500ms" } },
                hideLabel: false,
                hideType: false,
                style: { "border": "1px solid #ccc" }

            }
        }
    }
    
    
    const datasetFilter = (filtersDatasetId ? filter?.selected?.[filtersDatasetId] : filter?.selectedFilter) || {};

    const forecast = async () => {
        
        // setForecastRunning(true)
        dispatch(GlobalStoreCreator.setGlobalStore('forecast_needed', false))
        dispatch(GlobalStoreCreator.setGlobalStore('forecast_running', true))

        

        let selfilter = datasetFilter;// filter.selectedFilter
        let resultsCollection = {}

        if (activeConfig.includes('consideration')) {

            const play = model[activeConfig]?.config.play;

            let selFilters = [];
            for (let i = 0; i < selection?.suvTruck.atts.length; i++) {
            
                const fordfilter = {
                    ...selection?.suvTruck.atts[i],
                    type: selection.suvTruck.name
                }

                //const playHash = model[activeConfig]?.config.playHash
                //const modelInfo = model?.[activeConfig]?.modelInfo
                const filteredProducts = play?.filter(prod => 'model' + prod['model'] === selection?.suvTruck.atts[i].name)

                selfilter = {
                    ...selfilter,
                    syntax: selfilter.name === 'total' ? fordfilter.syntax : (selfilter.syntax + ' AND ' + fordfilter.syntax),
                    productIds: filteredProducts.map(p => p.uid)
                }
                selFilters.push(selfilter);

            }

            //console.log("selFilters", selFilters);
            await dispatch(fordForecastActionCreators.ford_forecast(selFilters, null, filtersDatasetId, activeConfig));

        }
        else {
            // shouldn't get here

        }

        dispatch(GlobalStoreCreator.setGlobalStore('forecast_running', false))
        dispatch(GlobalStoreCreator.setGlobalStore('forecast_needed', false))  

    }

    const debouncedForecast = debounce(forecast, debounce.forecastDelay)

    const ResetToBasecase = () => {
        dispatch(modelActionCreators.resetToBasecase())
        dispatch(GlobalStoreCreator.setGlobalStore('forecast_needed', true))        
    }   

    const HandleTabChange = (tabIndex, tabValue, tabKey) => {
        setSelectedTabValue(tabValue)
        dispatch(modelActionCreators.setActiveConfig(tabKey))
    }
  
    

    return (
        <div className="scenario-editor" style={props.style}> 
            <div className="tab-section">
                <div style={{ position: 'relative', display: 'flex', justifyContent:'space-between',  gap:5, marginBottom: 5,  }}>
                    <SelectionBlockElement key={'selblock1'} {...truckFilter} width={"32%"} />
                    <button className={`btn inline-block`} style={{width:'32%'}}  role="button" aria-label="RESET TO BASECASE" onClick={ResetToBasecase} >
                       <i className='fal fa-undo' style={{marginRight:5}}></i> {` RESET ${selectedTabValue} BASE`}
                    </button>
                    <button className={`btn inline-block`} style={{width:'32%'}}  role="button" aria-label="apply changes" onClick={debouncedForecast} disabled={forecastRunning}>
                        <i className={`fal fa-rocket-launch`} style={{marginRight:5}}></i> {forecastRunning ? ` RUNNING ${selectedTabValue} FORECAST` : ` FORECAST ${selectedTabValue}` } 
                    </button>
                </div>
               
                <TabStrip2 selectedIndex={selTabIndex} selectedIndexChanged={(tabIndex, tabValue) => HandleTabChange(tabIndex, tabValue, activeTabs[tabIndex]?.name)} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    {
                        activeTabs.map(t => {
                            if (format !== 'optimizer') {
                                return (
                                    <Tab_buttons key={t.name} value={t.label} icon={t.icon}>{t.label}</Tab_buttons>
                                )
                            } else {
                                return (
                                    <Tab_buttons key="summary" value="summary" icon={t.icon}>Summary</Tab_buttons>
                                )
                            }
                        })
                    }                    

                </TabStrip2>
            </div>
            {format === 'optimizer' ? null : true ? (
                <div className="product-selector" style={{ display: 'flex', flexDirection: 'column' }}> 
                    {
                        suvTruck.name === 'suv' ?
                        < SelectionBlockElement {...fordSuvSelector} />
                        :
                        <SelectionBlockElement {...fordTruckSelector} />
                    }
                    
                    <div style={{marginTop:15}}>
                        <span style={{display:'block', fontSize:12, fontWeight:500, height:15, textTransform:'uppercase'}}>Filters</span>
                        <FilterChooser mini={false} selection={datasetFilter} datasetId={filtersDatasetId} style={{ width: '100%', margin:0 }} /> 
                    </div>
                </div>
                
            ) : tab === 'message' || tab === 'make' ? (
                <div className="product-selector">
                    <ProductBlock
                        studyId={studyId}
                        {...beqSelection}
                        standalone={true}
                        allow={[beqLevel]}
                        style={{ width: '100%' }}
                        onClick={() =>
                            window.topBar.openProducts(beqSelection, (sel) => {
                                dispatch(selectionsActionCreators.setSelection(beqLevel, sel))
                                window.topBar.hideMenu()
                            }, {
                                allow: [beqLevel]
                            })
                        }
                    />
                </div>
            ) : tab === 'summary' ? (
                <div className="product-selector">
                    <ToggleSwitch
                        className="mini"
                        on={summaryChangesOnly}
                        onChange={(newVal) => setSummaryChangesOnly(newVal)}
                    >
                        Show Changes Only
                    </ToggleSwitch>
                    {!summaryChangesOnly ? (
                        <ProductBlock
                            studyId={studyId}
                            {...summarySelectedItem}
                            standalone={true}
                            allow={'product'} //[selections.hierarchy]
                            style={{ width: '100%', marginTop: 10 }}
                            onClick={() =>
                                window.topBar.openProducts(beqSelection, (sel) => {
                                    dispatch(modelActionCreators.set('summarySelectedItem', sel))
                                    window.topBar.hideMenu()
                                }, {
                                    allow: 'product'//[selections.hierarchy]
                                })
                            }
                        />
                    ) : null}
                </div>
                ) : null}
            {format === 'optimizer' ? (
                <div className="editor-content">
                    {tab === 'product' ? <EditConstraints /> : null}
                    {tab === 'price' ? <EditConstraints area="price" /> : null}
                    {tab === 'message' ? <EditSliderConstraints area="message" /> : null}
                    {tab === 'make' ? <EditSliderConstraints area="make" /> : null}
                    {tab === 'scenarios' ? <EditSliderConstraints area="make" /> : null}
                </div>
            ) : (   
                <div className="editor-content">
                    {tab === selectedTabValue ? <EditAreaAtts2 prodId={(fordSelector || {}).uid} key={(fordSelector || {}).uid} configModel={activeConfig} monitor_atts={attsToMonitor} /> : null}
                    {tab === 'price' ? <EditAreaAtts2 area="price" prodId={(fordSelector || {}).uid} key={(fordSelector || {}).uid} configModel={activeConfig} monitor_atts={attsToMonitor} /> : null}
                    {tab === 'message' ? <EditAreaSliders area="message" selection={(beqSelection || {}).uid} selectionLabel={(beqSelection || {}).label} /> : null}
                    {tab === 'make' ? <EditAreaSliders area="make" selection={(beqSelection || {}).uid} selectionLabel={(beqSelection || {}).label} configModel={activeConfig}  /> : null}
                    {/* {tab === 'summary' ? <ScenarioSummary filter={summarySelectedItem} changesOnly={summaryChangesOnly} /> : null} */}
                    {/* {tab === 'scenarios' ? <OpenSaveScenario configModels={configModels} /> : null} */}
                </div>
            )}
        </div>
    )

}