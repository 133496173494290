import * as React from 'react';
import { useState, useEffect } from 'react';
import './CardCarousel.scss';
import { EditModeWrapper } from './EditModeWrapper';
import { ContentLoader } from './ContentLoader';

export const CardCarousel = (props) => {

	const item = props.item;
	const [selIndex, setSelIndex] = useState(0);
	
	if (!item) return null;

    const style = {
        ...item.data?.style,
        height: item.data?.height || 540,
        margin: item.data?.margin,
        padding: item.data?.padding
	}

    const classes = ['card-carousel']
    if( !item.data?.wide ){
        classes.push('page-width');
	}

	const child = item.contents?.[selIndex];

	useEffect(() => {
		if (!child) {
			props.setLayoutReady?.(true);
		}
	}, [item])

	const setLayoutReady = (val) => {
		//console.log('carousel layout ready');
		props.setLayoutReady?.(val);
	}
	
	

	const flip = (adjust) => {		
		let newIndex = selIndex + adjust;
		if( newIndex > item.contents?.length ){
			newIndex = 0;
		}
		if( newIndex < 0 ){
			newIndex = item.contents?.length - 1;
		}
		if( newIndex >= 0 ){
			setSelIndex(newIndex);
		}
	}

	const arrowDirection = (adjust) => {

		let newIndex = selIndex + adjust;
		if( newIndex > item.contents?.length - 1 ){
			newIndex = 0;
		}
		else if( newIndex < 0 ){
			newIndex = item.contents?.length - 1;
		}
			
		setSelIndex(newIndex);
	}

	const carouselInfo = {
		nbItems: item.contents?.length || 0,
		flip,
		arrowDirection,
		selIndex,
		setSelIndex, 
		useArrowIcons: item.data?.useArrowIcons
	};

	return <EditModeWrapper className='has-children' item={item}
		edit={props.edit} restoredItem={props?.restoredItem}
		reloadItem={props.reloadItem} reloadParent={props.reloadParentItem} reloadParentItem={props.reloadParentItem}
		pageLevel={props.pageLevel}
	>
        <div className={classes.join(' ')} style={style}>
			{/* <div className='flipper'>
				<span className='btn border inline-block' onClick={() => flip(-1)}>prev</span>
				<span className='btn border inline-block' onClick={() => flip(1)}>next</span>
			</div> */}
            {!item.contents ? 'add item(s) to carousel' : null}
			{child ? <ContentLoader id={child.id} carouselInfo={carouselInfo} pageLevel={props.pageLevel}
				restoredItem={child?.deleted} reloadParentItem={props.reloadItem}
				edit={props.edit} restore={props.restore} instanceContext={props.instanceContext}
				setLayoutReady={setLayoutReady} classes={['animate_card']}
			/> : null}
        </div>
    </EditModeWrapper>;
}

