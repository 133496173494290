import * as React from 'react';


export class BlankWidget extends React.Component{

    static inheritOptions = ['selection'];


    render() {

        return <div className='widget'>
            <div className='widget-header'>
                <div className='title'>
                    {this.props.title}
                </div>
            </div>
            <div className='widget-body'>
                {this.props.content}
            </div>
        </div>;

    }

}

// BlankWidget = connect(
//     (state, ownProps) => ({
//         study: state.study,
//         ...(ownProps.inDataCard ? {
//             cache: state.cache.analytics[ownProps.path]
//             //fullCache: state.cache.analytics
//             } : {})
//     }),
//     dispatch => bindActionCreators(analyticsActionCreators, dispatch)
// )(BlankWidget)