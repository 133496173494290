import React, { useEffect, useState } from 'react';
import { CardLoading } from '../card/CardLoading';
import * as globals from '../../globals'

export function ChatGpt(props) {

  const [outputText, setOutputText] = useState('')
  const [btnClicked, setBtnClicked] = useState()
  const inputText = props.inputText

    
  const callChatGpt = async () => {
    try {
        const response = await fetch('https://api.openai.com/v1/chat/completions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + globals.chatapiKey,
          },
          body: JSON.stringify({
                messages: [
                    {"role": "system", "content": "You are a helpful assistant."},
                    {"role": "user", "content": `${props.inputText}`}
                ],
                model: 'gpt-3.5-turbo-16k',
                max_tokens: 14000,
                // frequency_penalty: 0.5,
                // presence_penalty: 0,
                temperature: 0.8
          })
        })
      //chat 4.0 - not enough tokens until gpt-4-32k is released
        // const response = await fetch('https://api.openai.com/v1/chat/completions', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //     Authorization: 'Bearer ' + globals.chatapiKey,
        //   },
        //   body: JSON.stringify({
        //         messages: [
        //             {"role": "system", "content": "You are a helpful assistant."},
        //             {"role": "user", "content": `${props.inputText}`}
        //         ],
        //         model: 'gpt-4',
        //         max_tokens: 7500,
        //         // frequency_penalty: 0.5,
        //         // presence_penalty: 0,
        //         temperature: 0.8
        //   })
        // })

        const data = await response.json();
        setBtnClicked(false)
        setOutputText(data.choices[0].message.content)//(data.choices[0].text);
          
      } catch (error) {
        setBtnClicked(false)  
        console.error('Error:', error);
      }
    }

  const handleClick = () => {
      setBtnClicked(true)      
      callChatGpt()
  }

  const handleCopyClick = () => {
      const theText = outputText.replace(/<\/?[^>]+(>|$)/g, "")
      navigator.clipboard.writeText(theText)
          .then(() => {
              topBar.enqueueSnack({
                    id: parseInt(+new Date().getMilliseconds() + Math.random().toFixed()),
                    message: `Messages have been copied.`,
                    to: 3000,
                    success: true
                })
          })
          .catch(err => {
              topBar.enqueueSnack({
                    id: parseInt(+new Date().getMilliseconds() + Math.random().toFixed()),
                    message: `Messages failed to copy.`,
                    to: 3000,
                    success: false
                })
          })
  }
  


  return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin:'10px 40px'}}>
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom:10, width: '100%', height: 380, overflowY: 'auto' }}>
            { btnClicked ? <p style={{justifyContent:'center', alignItems:'center', fontSize:14}}>loading...</p> : null}
            { outputText ? 
              <button className='btn-copy' onClick={handleCopyClick} style={{alignSelf:'flex-end', height:40, fontSize:14 }}><i className='fal fa-clipboard'></i> Copy</button>
              : null
            }
        
            <div style={{margin:'20px 0', fontSize:14}} dangerouslySetInnerHTML={{ __html: outputText }}></div>    
        </div>
        <div style={{height:40}}>
          <button className='btn drk borders' onClick={handleClick}>Generate Messages</button>
        </div>
  </div>
}


