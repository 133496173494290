import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { SelectionBlock } from '../product-drawer/SelectionBlock';
import './ContentViewer.scss';
import { EditModeWrapper } from './EditModeWrapper';
import { actionCreators as GlobalStoreCreator } from "../../store/General"


export const SelectionBlockElement = (props) => {


    const item = props.item;
    const dispatch = useDispatch()

    if (!item) return null
    
    useEffect(() => {
        if (props.item) {
            props.setLayoutReady?.(true)
        }
    }, [])

    const style = {
        "border": "1px solid #cfcfcf",
        ...item.data?.style
    }

    let name = item.data?.type;//title,franchise...
    let flavor = null;
    if (name?.includes('(')) {
        try {
            const idx1 = name.indexOf('(');
            const idx2 = name.indexOf(')', idx1);
            flavor = name.substring(idx1 + 1, idx2);
            name = name.substring(0, idx1);
        }
        catch (err) {
            console.error(err);
        }
    }

    const studyFolder = useSelector(state => state.study?.folder);
    const selectionTypes = useSelector(state => state.study?.selectionTypes);
    const selectionType = selectionTypes[name];
    const typeLabel = (nbSelect === '2' || nbSelect === '*') ? (selectionType?.labelMulti || selectionType?.label + 's') : selectionType?.label;
    const nbSelect = flavor === null ? 1 : flavor;
    let nameMulti = selectionTypes?.[name]?.nameMulti || name + 's';

    // selection repo
    const selection = useSelector(state => state.selection);
    
    let selItem = selection?.[name];
    let selItems = selection?.[nameMulti];    

    // console.log('nbSelect', nbSelect)
    
    let selItem2 = { ...selItem };
    if (nbSelect === '2'){
        selItem2.label = selItem.label + ` and ` + (selItems?.[0]?.label || '[select]');
    }
    else if (nbSelect === '*') {
        selItem2.label = selItem.label + ` + ${selItems?.length || 0} ${typeLabel}`;
    }
        

    const handleClick = () => {

        if( !props.disabled ){
            dispatch(GlobalStoreCreator.setGlobalStore('forecast_needed', true))
            
            const callback = (sel) => {
                console.log('seelctionblockelement callback - close menu!!!!!!!');
                window.topBar.hideMenu()
            };
            const options = {
                selectionType, 
                selectionName: name,
                nbSelect
            }

            if (flavor) {
                window.topBar.openMultiSelection(null, callback, options);
            }
            else {
                window.topBar.openSelection(null, callback, options);                
            }
                
        }
    }

    let gridStyle = {};
    if (item.data?.gridColumn) {
        gridStyle.gridColumn = item.data.gridColumn;
    }
    if (item.data?.gridRow) {
        gridStyle.gridRow = item.data.gridRow;
    }


    return <EditModeWrapper item={item} edit={props.edit} reloadItem={props.reloadItem} reloadParent={props.reloadParentItem} pageLevel={props.pageLevel} style={gridStyle} >
        <div className='selection-block-element' style={{ width: props.width }}>
            <SelectionBlock
                study_folder={studyFolder}
                typeLabel={typeLabel}
                selected={false}
                showImage={selectionType?.showImage === false ? false : true}
                hideLabel={item.data?.hideLabel}
                hideType={item.data?.hideType}
                typeLabelTemplate={item.data?.typeLabelTemplate}
                imageProperty={item.data?.imageProperty}
                imageFit={item.data?.imageFit}
                largeImageForm={item.data?.largeImageForm}
                largeImageHeight={item.data?.largeImageHeight}
                largeImagePadding={item.data?.largeImagePadding}
                labelStyle={item.data?.labelStyle}
                typeLabelStyle={item.data?.typeLabelStyle}
                imageStyle={item.data?.imageStyle}
                textSectionStyle={item.data?.textSectionStyle}
                {...selItem2}
                onClick={() => handleClick()}
                style={{ ...style, ...gridStyle }}
            />
        </div>
    </EditModeWrapper>;
}
