import './TasksPage.scss';
import React, { useEffect, useState } from 'react';
import { PageHeader2 } from '../common/PageHeader';
import { useSelector } from 'react-redux';
import * as apiService from '../../utils/apiService';
import * as globals from '../../globals';
import { SimpleTable } from '../vis/SimpleTable';
import ReactJson from 'react-json-view';
import { MarkdownElement } from '../explore/MarkdownElement';
import { Markdown } from '../common/Markdown';

export const TasksPage = (props) => {

    const study_uid = props.match?.params?.studyId;
    const [taskList, setTaskList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingEntry, setLoadingEntry] = useState(false);
    const auth = useSelector(state => state.auth);
    const [reloadCounter, setReloadCounter] = useState(0);
    const [reloadEntryCounter, setReloadEntryCounter] = useState(0);
    const [selectedKey, setSelectedKey] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [view, setView] = useState('value');
    
    

    // get list of tasks
    useEffect(() => {
        
        if (!study_uid) return;
        setLoading(true);
                
        apiService.aGet(auth, `${globals.apiRoot}/study/${study_uid}/tasks/list`)
            .then(res => {
                return res.ok ? res.json() : null;
            })
            .then(item => {
                setTaskList(item);
                setLoading(false);
            }).catch(err => {
                console.error(err)
                setLoading(false);
            });

    }, [study_uid, reloadCounter]);

    // load selected task
    useEffect(() => {
        if (!selectedKey) return;

        setLoadingEntry(true);

        const item = taskList?.find(e => e.id === selectedKey);
        setSelectedValue(item);
        setLoadingEntry(false);

    }, [selectedKey, reloadEntryCounter, view]);

    const selectEntry = (taskEntry) => {
        //console.log('selectEntry', metaEntry);
        setSelectedKey(taskEntry?.id);
    }

    //dont'delete
        //<PageHeader2 title={"Tasks"} fullWidth={true} />

    return <div className='page tasks-page'>

        <div className='content'>
            <div className='search-section'>
                <span className='btn inline-block' onClick={() => setReloadCounter(reloadCounter+1)}>
                    <i className='fal fa-sync'/> Refresh List
                </span>

            </div>

            <div className='task-list-section'>

                {loading ? <div className='loading'>loading...</div> :

                    <>
                        {taskList?.map(taskEntry => {
                            const selected = taskEntry.id === selectedKey;
                            return <div className={'task-entry' + (selected ? ' sel' : '')} key={taskEntry.id} onClick={() => selectEntry(taskEntry)}>
                                <div className='title'>
                                    {/* <div className='key'>{taskEntry.type}</div> */}
                                    <div className='type'>{taskEntry.title || "[task]"}</div>
                                </div>
                            </div>
                        })}
                        
                    </>

                }
                    
            </div>

            <div className='task-value-section'>
                {selectedValue == null ? null :
                    <>
                    <div className='toolbar'>
                        <div className={'section' + (view === 'edit' ? ' sel' : '')}>
                                <a className='btn inline-block' href={selectedValue?.url} target='_blank'>
                                Edit Item&nbsp; <i className='fal fa-external-link'/>
                            </a>
                        </div>
                    </div>
                    {view === 'value' ? <div className='tab-content'>
                        {loadingEntry ? "loading..." :
                                <div className='entry'>
                                    {["id", "area", "type", "devStatus"].map(k =>
                                        <div key={k} className='property-item'>
                                            <span className='name'>
                                                {k}
                                            </span>
                                            <span className='value'>
                                                {selectedValue?.[k]}
                                            </span>
                                        </div>
                                     )}
                                    <div className='notes-item'>
                                        <Markdown data={selectedValue?.notes || ''}/>
                                    </div>
                            </div>
                        }
                    </div>
                    : <div className='tab-content'>
                    </div>
                    }
                    </>
                }
            </div>

        </div>

    </div>;


}


