import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators as modelActionCreators } from '../../store/Model.ts'
import * as metaUtils from '../../utils/metaUtils'
import './CloneProduct.scss'


export const CloneProduct = ({ activeModel, selectionType }) => {

    // console.log('active_model', activeModel)
    // console.log('selectionType', selectionType)

    
    
    const [isDialogOpen, setIsDialogOpen] = useState(false) // Control dialog visibility
    const selectionTypes = useSelector(state => state.study?.selectionTypes)

   

    // Toggle dialog visibility
    const toggleDialog = () => {

        const callback = () => { window.topBar.hideMenu() }

        const options = {
            selectionType: selectionTypes?.[selectionType], 
            selectionName: selectionType,
            activeModel: activeModel,
            nbSelect: 1 // null??
        }
     
        window.topBar.openCloneDialog(null, callback, options)        
        // setIsDialogOpen(!isDialogOpen)
    }

    

    return (
        <div className='clone-product-container'>
            <button className='btn-clone' onClick={toggleDialog}>
                <i className='fas fa-plus-circle' /> Add Product
            </button>
        </div>
    )
}

