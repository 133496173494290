import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import packageInfo from '../../../package.json';
import { actionCreators } from '../../store/Auth.ts';
import './Auth.scss';
import './BackgroundAnimation.scss';


export class AuthOuter extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showPassword: false,
			username: '',
			password: '',
			timestamp: new Date()
		};
	}

	componentDidMount() {
		var ms = 1 * 1000; // 1 second
		this.timer = setInterval(this.updateTimestamp, ms);
	}

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	updateTimestamp = () => {
		this.setState({ timestamp: new Date() });
	}

	clearMessages() {
		this.setState({
			g_recaptcha_response: null
		});
	}

	renderBackgroundAnimation() {

		return <React.Fragment>

			<div id="background-animation">
				<img className="callouts" src="images/login/callouts.png" alt="spinning logo" />
				<img className="element e1 o2" src="images/login/1.png" alt="spinning logo" />
				<img className="element e2 o4" src="images/login/2.png" alt="spinning logo" />
				<img className="element e3 o1" src="images/login/3.png" alt="spinning logo" />
				<img className="element e5 o5" src="images/login/5.png" alt="spinning logo" />
				<img className="element e1 o2" src="images/login/6.png" alt="spinning logo" />
				<img className="element e2 o3" src="images/login/7.png" alt="spinning logo" />
				<img className="element e3 o1" src="images/login/8.png" alt="spinning logo" />
				<img className="element e5 o5" src="images/login/9.png" alt="spinning logo" />
				<img className="element e2 o2" src="images/login/11.png" alt="spinning logo" />
				<img className="element e3 o3" src="images/login/12.png" alt="spinning logo" />
				<img className="element e4 o4" src="images/login/13.png" alt="spinning logo" />
				<img className="element e5 o5" src="images/login/14.png" alt="spinning logo" />
				<img className="element e2 o2" src="images/login/15.png" alt="spinning logo" />
				<img className="element e2 o3" src="images/login/16.png" alt="spinning logo" />
				<img className="staticElement e10" src="images/login/10.png" alt="spinning logo" />

				<img className="logo" src="images/login/BO-Logo5.png" alt="spinning logo" />
				{/* <img className="logo" src="images/logo-concept2.png" alt="" style={{
                    borderRadius: '50%',
                    border: '10px solid white',
                    padding: '10px',
                    backgroundColor: 'white'
                }} /> */}


				{/* <h3 className="data dataLabel scenarioLabel">Analyzing Scenarios</h3> */}
				<div className="data dataItem scenarioItem" />
				{/* <h3 className="data dataLabel profitLabel">Profit</h3> */}
				<div className="data dataItem profitItem" />
				{/* <h3 className="data dataLabel reactionLabel">Competitive Reaction</h3> */}
				<div className="data dataItem reactionItem">
					{/* <img alt=""
                        src="images/login/logos/Brand002_LogoRev.png"
                        className="primaryLogo"
                    /> */}
					<div className="competitors">
						{/* <img src="images/login/logos/Brand001_LogoRev.png" alt="" />
                        <img src="images/login/logos/Brand003_LogoRev.png" alt="" />
                        <img src="images/login/logos/Brand004_LogoRev.png" alt="" />
                        <br />
                        <img src="images/login/logos/Brand005_LogoRev.png" alt="" />
                        <img src="images/login/logos/Brand006_LogoRev.png" alt="" />
                        <img src="images/login/logos/Brand007_LogoRev.png" alt="" /> */}
					</div>
				</div>

				{/* <h3 className="data dataLabel shareLabel">Preference Share</h3> */}
				<div className="data dataItem shareItem" />

				{/* <h3 className="data dataLabel areaLabel">Marketing Area</h3> */}
				<div className="data dataItem areaItem">
					{/* <i className="fa fa-star" aria-hidden="true" />{' '}
                    <i className="fa fa-comments" aria-hidden="true" />{' '}
                    <i className="fa fa-bar-chart" aria-hidden="true" />{' '}
                    <i className="fa fa-cloud-download" aria-hidden="true" />{' '}
                    <i className="fa fa-users" aria-hidden="true" /> */}
				</div>
			</div>

			<div id='background-animation-screen' />

		</React.Fragment>;

	}

	render() {
		let temp = this.props.temp || {};

		let fetching = temp.fetching;
		let fetchMessage = temp.fetchMessage;
		let successMessage = temp.successMessage;
		let errorMessage = temp.errorMessage;

		return (
			<div className={'App sign-in' + (fetching ? ' fetching' : '')}>

				{this.renderBackgroundAnimation()}

				<div className='sign-in-box-container' style={{
					gridTemplateRows: 'auto minmax(' + this.props.boxHeight + ', min-content) auto'
				}}>

					<div className="sign-in-box">

						<div className="header">

							<div className='logo'>
								<img src='images/blueowl whitebg 2.png' alt='company logo' />
							</div>

							<div className="date">
								{/* {this.state.timestamp.toLocaleString()} */}
								{this.state.timestamp.toLocaleString(window.navigator.language, {
									weekday: 'long',
									month: 'long',
									day: 'numeric',
									year: 'numeric',
									hour: 'numeric',
									minute: '2-digit',
									second: '2-digit'
								})}
								&nbsp;|  v{packageInfo.version}
							</div>
						</div>

						<div className="content">

							<div className='title'>
								{this.props.titleLogo ? this.props.titleLogo : null}
								{this.props.titleLogo ? ' ' : null}
								{this.props.title}
							</div>

							{successMessage ? (
								<div className="sign-in-message">{successMessage}</div>
							) : null}

							{errorMessage ? (
								<div className="sign-in-error">{errorMessage}</div>
							) : null}

							{fetching ? (
								<div className="fetch-status">
									{fetchMessage}
									<span className="blink_cursor">_</span>
								</div>
							) : (
									this.props.children
								)}
						</div>

					</div>

				</div>

			</div>
		);
	}
}

AuthOuter = withRouter(
	connect(
		state => state.auth || {},
		dispatch => bindActionCreators(actionCreators, dispatch)
	)(AuthOuter)
);

//export default SignIn;
